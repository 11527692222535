/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  This module knows how to get data from the Health Card
 *                                and offers an interface for accessing this data.
 *
 *  history:
 *  *.2021 tpe                    created
 */

import { Sad } from "./t1_host_interface";
import { Dad } from "./t1_host_interface";
import { Read_VSD_stm } from "./read_VSD_state_machine";
import { T1_host_if } from "./t1_host_interface";

export class CardReader_VSD {

  private read_VSD_stm:  Read_VSD_stm;

  // Storage for command execution (array of lambdas)
  private command_storage: { ( ): void }[] = [];

  constructor(dad: Dad, t1_host_if: T1_host_if) {

    this.read_VSD_stm = new Read_VSD_stm(
      this, t1_host_if, dad, Sad.host
    );

    console.log("Card_reader_VSD: constructor().");
  }

  // Read the 'Krankenversichungskarte' KVK
  public async read_VSD(): Promise<string>{
    console.log("read VSD.");

    let xmlText = "";

    await this.read_VSD_stm.request_icc();
    xmlText = await this.read_VSD_stm.read_Data();
    /*await*/ this.read_VSD_stm.eject_icc();

    return xmlText;  
  } 

  // Call next command from command_storage
  private callNextCmd ( ): void {
    const nextFunctionCall = this.command_storage.shift();
    if(nextFunctionCall){
      nextFunctionCall();
    }else{
      console.error("card_reader_VSD command_storage is empty.");
    }
  }

}
