/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField/InputField'
import { SelectField } from '../../../shared/components/SelectField/SelectField'
import CheckboxList from '../../CheckboxList/CheckboxList'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const deformitaetenItems = [
  { value: 'links', text: 'links' },
  { value: 'rechts', text: 'rechts' },
  { value: 'beide', text: 'beide' },
  { value: 'keiner', text: 'keiner' },
]

const deformitaetenItemsLang = [
  { value: 'R1', label: 'R1' },
  { value: 'R2', label: 'R2' },
  { value: 'R3', label: 'R3' },
  { value: 'R4', label: 'R4' },
  { value: 'R5', label: 'R5' },
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' },
  { value: 'L4', label: 'L4' },
  { value: 'L5', label: 'L5' },
]

const AF08 = ({
  fields: {
    deformitaeten,
    deformitaetenHalluxValgus,
    deformitaetenSenkspreizfuss,
    deformitaetenDigitusMalleus,
    deformitaetenDigitusFlexus,
    deformitaetenKommentar,
    patient,
  },
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/07`} iconDirection="up" />
            <CheckboxXOR
              {...deformitaeten}
              className="checkboxXORHead"
              label="Deformitäten?"
              labelNo="Nein"
              labelYes="Ja"
            />
          </div>
          <div className="column">
            {deformitaeten.value && (
              <FieldContainer label="Hallux Valgus (Ballenzeh)?">
                <SelectField {...deformitaetenHalluxValgus} options={deformitaetenItems} />
              </FieldContainer>
            )}
            {deformitaeten.value && (
              <FieldContainer label="Digitus malleus (Hammerzeh)?" className="fieldContainerVariableHeight">
                <CheckboxList
                  className="checkboxList"
                  items={deformitaetenItemsLang}
                  {...deformitaetenDigitusMalleus}
                />
              </FieldContainer>
            )}
          </div>
          <div className="column">
            {deformitaeten.value && (
              <FieldContainer label="Senkspreizfuss">
                <SelectField {...deformitaetenSenkspreizfuss} options={deformitaetenItems} />
              </FieldContainer>
            )}
            {deformitaeten.value && (
              <FieldContainer label="Digitus flexus (Krallenzeh)?" className="fieldContainerVariableHeight">
                <CheckboxList className="checkboxList" items={deformitaetenItemsLang} {...deformitaetenDigitusFlexus} />
              </FieldContainer>
            )}
          </div>
          <div className="columnFullWidth">
            <InputField {...deformitaetenKommentar} multiline rows={5} label="Kommentar" />
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/09`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF08)
