import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { RadioGroup } from '@material-ui/core'
import { ArrowDropDownCircleRounded as ArrowDropDownCircleRoundedIcon } from '@material-ui/icons'

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`

export const StyledAnamnesisForm = styled.form`
  display: flex;
  flex-grow: 1;

  .outer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .inner {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-content: flex-start;
  }

  .column {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    display: block;
    flex-direction: column;
    width: 45%;
    flex-wrap: wrap;
  }

  .columnFullWidth {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    display: block;
    flex-direction: column;
    width: 90%;
    flex-wrap: wrap;
  }

  .label {
    margin-top: 12px;
  }

  .divider {
    margin-top: 40px;
  }

  .datePicker {
    width: 100%;
  }

  .checkboxXOR {
    margin-top: 0;
  }

  .checkboxXORHead {
    width: 100%;
    margin-top: 20px;
  }

  .checkboxList {
    height: 210px;
    margin-top: 10px;
  }

  .headLabel {
    font-size: 22px;
  }

  .fieldRow {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  .fieldContainer {
    margin-left: auto;
    margin-right: 20px;
    min-width: 240px;
    height: 48px;
  }

  .fieldContainerVariableHeight {
    margin-left: auto;
    margin-right: 20px;
    min-width: 240px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .fieldContainerBeschwerden {
    margin-left: 50px;
    min-width: 280px;
    max-width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .fieldContainerBeschwerdenPullLeft {
    margin-left: 50px;
    min-width: 280px;
    max-width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .fieldContainerPullLeft {
    margin-left: auto;
    margin-right: 290px;
    min-width: 240px;
    height: 48px;
  }

  .radioButtonHorizontal {
    float: left;
    width: 50%;
    white-space: nowrap;
  }

  .radioButtonVertical {
    margin-bottom: 14px;
  }
`
export const Container = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
`

export const StyledHNBListe = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;

  .iconButton {
    margin-top: 15px;
  }
  .icon {
    font-size: 35px;
    line-height: 0;
    &.add {
      color: lightGreen;
    }
    &.remove {
      color: #666;
    }
  }
  .fieldContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .autoComplete {
  }
  .button {
    width: 120px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 45px;
  }
  .buttonLabel {
  }
  .buttonIcon {
    margin-left: -10px;
    margin-right: 8px;
    vertical-align: sub;
    color: #060;
  }
  .column {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-wrap: wrap;
    &.fullwidth {
      width: 100%;
    }
  }
`

export const StyledDividerLink = styled(Link)`
  margin-top: 50px;
  padding: 15px 0;
  display: flex;
  position: relative;

  & svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
  }
`

export const DividerLinkLine = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.palette.highlight.dark};
  height: 1px;
`

interface DividerLinkIconProps {
  direction: 'up' | 'down'
}

export const DividerLinkIcon = styled(ArrowDropDownCircleRoundedIcon)<DividerLinkIconProps>`
  transform: ${({ direction }) => direction === 'up' && 'rotate(180deg)'} translateX(-50%) !important;
  top: ${({ direction }) => (direction === 'up' ? '3px' : '5px')} !important;
`
export const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
`
