/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowRightAlt as ArrowRightAltIcon } from '@material-ui/icons';
import { FC } from 'react';
import { StyledButton, StyledLink } from './StyledAddNewButton';

interface Props {
  icon?: any;
  hoverText: string;
  label: string;
  to?: string;
  onClick?: () => void;
}

const AddNewButton: FC<Props> = ({ icon, hoverText, label, to, onClick }): JSX.Element => {

  return to ? (
    <StyledLink to={{ pathname: to }}>
      <div className="label">
        <div className="labelContent">{icon}</div>
        <div className="labelContent">{label}</div>
      </div>
      <div className="hoverText">
        <div className="hoverTextContent">
          <ArrowRightAltIcon />
        </div>
        <div className="hoverTextContent">{hoverText}</div>
      </div>
    </StyledLink>
  ) : (
    <StyledButton onClick={onClick}>
      <div className="label">
        <div className="labelContent">{icon}</div>
        <div className="labelContent">{label}</div>
      </div>
      <div className="hoverText">
        <div className="hoverTextContent">
          <ArrowRightAltIcon />
        </div>
        <div className="hoverTextContent">{hoverText}</div>
      </div>
    </StyledButton>
  );
};

export default AddNewButton;
