import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Root } from './containers/Root'
import BrowserCheck from './shared/components/BrowserCheck/BrowserCheck'

const app = document.getElementById('app')
app
  ? ReactDOM.render(
      <StrictMode>
        <BrowserCheck>
          <Root />
        </BrowserCheck>
      </StrictMode>,
      app
    )
  : null

  // test deploy
