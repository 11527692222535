import * as R from 'ramda'
import { Navigate } from 'react-router-dom'
import isPristine from 'redux-form/lib/isPristine'
import { closeDialog, CONTINUE_NAVIGATION, openDialog } from '../modules/formLeaveConfirmation'

// add form Names to add this feature to more forms
const formNames = ['praxisstammdatenForm']

export default ({ dispatch, getState }) => (next) => (action) => {
  const state = getState()

  // Both blocks were commented out for testing.
  // This file should be irrelevant either way if removing FormLeaveConfirmationDialog from redux

  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (
        dirtyFormPresent(state) &&
        action.payload.pathname !== state.routing.locationBeforeTransitions.pathname &&
        !(action.payload && action.payload.state && action.payload.state.continueNavigation === true)
      ) {
        dispatch(openDialog())
        return next(() => (
          <Navigate
            to={state.routing.locationBeforeTransitions.pathname}
            replace
            state={{ nextPathname: action.payload.pathname }}
          />
        ))
        // return next(
        //   routerActions.replace({
        //     pathname: state.routing.locationBeforeTransitions.pathname,
        //     state: { nextPathname: action.payload.pathname },
        //   }),
        // )
      }
      break
    case CONTINUE_NAVIGATION:
      dispatch(closeDialog())
      return (
        <Navigate
          to={state.routing.locationBeforeTransitions.state.nextPathname}
          state={{ continueNavigation: true }}
        />
      )
    /* dispatch(
        routerActions.push({
          pathname: state.routing.locationBeforeTransitions.state.nextPathname,
          state: { continueNavigation: true }, // continueNavigation is used to break the loop with routerActions.replace above
        }),
      ) */
    default:
  }

  return next(action)
}

// formIsDirty :: Form -> Boolean
const formIsDirty = R.compose(
  R.gt(R.__, 0),
  R.length,
  R.filter(R.compose(R.not, ({ initial, value }) => isPristine(initial, value))),
  R.filter(R.compose(R.equals('Object'), R.type)),
  R.flatten,
  R.values,
)

// dirtyForms :: State -> [DirtyForm]
const dirtyForms = R.compose(
  R.values,
  R.filter(formIsDirty),
  R.filter(R.propEq('_initialized', true)),
  R.fromPairs,
  R.filter(R.compose(R.contains(R.__, formNames), R.prop('0'))),
  R.toPairs,
  R.prop('form'),
)

// dirtyFormPresent :: State -> Boolean
const dirtyFormPresent = R.compose(R.gt(R.__, 0), R.length, dirtyForms)
