import { FC } from 'react'
import { StyledTableHeader } from './StyledTableHeader'

interface Props {
  cols?: Array<any>
}

const TableHeader: FC<Props> = ({
  cols = []
}): JSX.Element => {
  return (
    <StyledTableHeader>
      <tr>
        {cols.map((col, columnIndex) => (
          <th
            key={columnIndex}
            style={col?.style || {}}
            className={col?.className || ''}>
            {col?.label}
          </th>
        ))}
      </tr>
    </StyledTableHeader>
  )
}

export default TableHeader
