import { Drawer } from '@material-ui/core'
import styled from 'styled-components'

export const StyledMenu = styled(Drawer)`
  ${({ theme }) => `
    z-index: ${theme.zIndex.appBar - 1};
    width: 60px;
    overflow-x: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    // height: 100%;

    &.open {
      width: 300px;
      .avatar{
        width: 50px;
        height: 50px;
        transform: translateX(0);
      }
      .drawerPaper {
        border-right: 1px solid black;
      }
    }
    .drawerPaper {
      background-color: ${theme.palette.primary.main};
      border: none;
      // margin-top: 64px;
      // position: relative !important;
      position: static!important;
      // height:calc(100vh - 64px);
      overflow-x: hidden;
      // height: 100%;
    }

    .leftSideContainer {
      display: flex;
      flex-direction: column;
      background-color: ${theme.palette.primary.main};
    }

    .styledLink {
      height: 60px;
      background-color: ${theme.palette.primary.main};
      border-radius: 0;
      font-size: x-large;
      text-transform: none;
      padding: 13px;
      justify-content: flex-start;
      font-weight: normal;
      transition: background-color 0.5s ease-in-out;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    & .MuiButton-startIcon {
      margin-right: 25px;
      margin-left: 7px;
    }

    .styledMenuLink {
      height: 60px;
      background-color: ${theme.palette.primary.main};
      border-radius: 0;
      font-size: x-large;
      text-transform: none;
      padding: 13px;
      justify-content: flex-start;
      font-weight: normal;
      border-bottom: 1px solid ${theme.palette.text.disabled};
      transition: background-color 0.5s ease-in-out;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    .practiceOverviewLink {
      height: 60px;
      background-color: ${theme.palette.primary.main};
      border-radius: 0;
      font-size: x-large;
      text-transform: none;
      padding: 13px;
      justify-content: flex-start;
      font-weight: normal;
      border-bottom: 1px solid ${theme.palette.text.primary};
      border-top: 1px solid ${theme.palette.text.primary};
      transition: background-color 0.5s ease-in-out;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    .startbereichLogoutContainer {
      display: relative;
      margin-top: auto;
    }


    .avatar {
      border: solid 5px #6e7276;
      background-color: #fff;
      height:40px;
      width: 40px;
      transition: all  225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      transform: translateX(-10px);
    }

    .profileContainer{
      display: flex;
      align-items: center;
      min-height: 90px;
      padding: 0 20px;
    }


    .typography {
      padding-left: 16px;
      color: ${theme.palette.text.primary};
    }
    .personalInfo {
      display: flex;
      align-items: center;
    }
    .editBtn{
      // margin: 20px;
    }

    .link {
      margin-left: 16px;
      text-decoration: none;
      color: ${theme.palette.text.primary};
      font-size: medium;

      &:hover {
        text-decoration: underline;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      // height: 100%;
      border-right: solid 1px ${theme.palette.text.primary};
      width: 300px;
    }

    .moreLinksMenu {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      background-color: ${theme.palette.primary.main};
      margin-bottom: 100px;
    }
  `}
`

export const ContentPadder = styled.div`
  ${({ theme }) => theme.mixins.toolbar}
`
