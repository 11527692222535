import styled from 'styled-components';

export const StyledAnamnesis = styled.div`
  width: 100%;

  .root {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
  }
  .form{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
  }
  .sidebar{
    display: flex;
    padding: 0 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
  }
  .circularProgress{
    align-self: center;
  }
  .openCloseDialogButton{
    margin-top: 10px;
  }
  .neueAnamneseButton{
    margin-top: 10px;
  }
  .abschliessenButton{
    margin-left: 10px;
  }
  .paper{
    margin-top: 30px;
    padding: 20px;
  }
`;
