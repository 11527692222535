import * as types from '../constants/actionTypes'

export default function dialogs(
  state = {
    editDate: {
      open: false,
      date: null,
    },
  },
  action = undefined,
) {
  const { type } = action

  switch (type) {

    case types.OPEN_EDIT_TERMIN_DIALOG:
      return {
        ...state,
        editDate: {
          open: true,
          date: action.termin,
        },
      }
    case types.CLOSE_EDIT_TERMIN_DIALOG:
      return {
        ...state,
        editDate: {
          open: false,
          date: null,
        },
      }

    case types.UPDATE_DATE:
      return {
        ...state,
        editDate: {
          open: false,
          date: null,
        },
      }

    default:
      break
  }
  return state
}
