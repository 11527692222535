/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  Sate machine for KVK reading.
 *
 *  history:
 *  *.2021 tpe                    created
 */

import { convertTLVtoXML } from '../utils/tlv_to_xml/tlv_to_xml'
import { KVK_commands } from './KVK_commands'
import { CardReader_KVK } from './card_reader_KVK'
import { Dad, Sad, T1_Return_Code, T1_host_if, TerminalData } from './t1_host_interface'

// define stm states
export enum Read_KVK_state {
  start, // initial state
  select_masterfile, // select_masterfile was requested.
  masterfile_selected, // select_masterfile() was successfull
  read_Binray, // read_Binary() was requested
  read_Binary_done, // read_Binary() was done
}

// Function returns true if both arrays a and b are equal.
const equals = (a: Uint8Array, b: Uint8Array) => a.length === b.length && a.every((v, i) => v === b[i])

// define statemachine
export class Read_KVK_stm {
  private state: Read_KVK_state = Read_KVK_state.start // The actual state
  private cardReader_KVK: CardReader_KVK
  private t1_host_if: T1_host_if

  private dad: Dad // destination address of 'integrated circuit card' (ICC)
  private sad: Sad // source address of host

  constructor(cardReader_KVK: CardReader_KVK, t1_host_if: T1_host_if, dad: Dad, sad: Sad) {
    this.cardReader_KVK = cardReader_KVK
    this.t1_host_if = t1_host_if
    this.state = Read_KVK_state.start
    this.dad = dad
    this.sad = sad
    console.log('Read_KVK_stm created.')
  }

  public async select_masterfile(): Promise<void> {
    if (this.state === Read_KVK_state.start) {
      // Change state
      this.state = Read_KVK_state.select_masterfile

      // send command
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, KVK_commands.select_masterfile()),
        'select_masterfile',
      )

      this.evaluate_response_from_select_masterfile(rxData, return_code)
    } else {
      console.error('select_masterfile(): state != icc_ready')
      throw new Error('read_KVK_wrong_state ' + this.select_masterfile.name)
    }
  }

  public async read_Binary(): Promise<string> {
    let data = ''
    if (this.state === Read_KVK_state.masterfile_selected) {
      // change state
      this.state = Read_KVK_state.read_Binray

      // send command read ef.pd with short file identifier
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, KVK_commands.read_Binary()),
        'read_Binary',
      )

      data = this.evaluate_response_from_read_Binary(rxData, return_code)

      this.state = Read_KVK_state.read_Binary_done
    } else {
      console.error(this.read_Binary.name + ' state != masterfile_selected')
      throw new Error('read_KVK_wrong_state ' + this.read_Binary.name)
    }
    return data
  }

  evaluate_response_from_select_masterfile(rxData: Uint8Array, return_code: number): void {
    console.log('evaluate_response_from_select_masterfile()')

    if (return_code !== T1_Return_Code.OK) {
      console.error('select_masterfile return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_select_masterfile.name)
      return
    } else {
      /*no error from t1*/
    }

    const expected_len = 2
    if (rxData.length < expected_len) {
      console.error('select_masterfile rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_select_masterfile.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    if (equals(expected_response, rxData)) {
      this.state = Read_KVK_state.masterfile_selected
    } else {
      console.error('Error: Received response from select_masterfile was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_select_masterfile.name)
    }
  }

  evaluate_response_from_read_Binary(rxData: Uint8Array, return_code: number): string {
    console.log('evaluate_response_from_read_Binary()')

    let data = ''

    // change state
    this.state = Read_KVK_state.masterfile_selected

    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_PD return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_read_Binary.name)
    } else {
      /*no error from t1*/
    }

    const expected_len = 2
    if (rxData.length < expected_len) {
      console.error('read_Binary rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_read_Binary.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    const eof_warning_response = new Uint8Array([0x62, 0x82]) // "Weniger Daten vorhanden, als mittels Ne angefordert"
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])

    if (
      return_code === T1_Return_Code.OK &&
      rxData.length > status_code.length &&
      (equals(expected_response, status_code) || equals(eof_warning_response, status_code))
    ) {
      // Everthing seems to be fine

      data = convertTLVtoXML(rxData)
    } else {
      console.error('Error: Received response from read_Binary was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_read_Binary.name)
    }

    return data
  }
}
