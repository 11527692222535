import { Paper, Tabs, Typography } from '@material-ui/core';
import { EmojiEmotions as EmojiEmotionsIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { FC, memo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as dateActions from '../../actions/dates';
import { ISO8601DATE_FNS } from '../../constants/dateFormats';
import TodoStack from '../../containers/TodoStack/TodoStack';
import { DatePickerToolbar } from '../../shared/components/DatePickerToolbar';
import { StyledTab, tabA11yProps, TabPanel } from '../../shared/components/Tabs';
import { StyledCalendarSidebar } from './StyledCalendarSidebar';

const CalendarSidebar: FC<any> = ({
  selectDay,
  selectedDay,
  route,
  navigateOnCalendarTouchTap,
  currentUserIsAdmin,
  currentServer,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const navigate = useNavigate();
  const { serverHash } = useParams()
  const { pathname } = useLocation()

  const handleTabChange = useCallback((_, value: number): void => setSelectedTab(value), [])

  const handleDayClick = useCallback((date) => {
    const fDate = format(date, ISO8601DATE_FNS);
    if (navigateOnCalendarTouchTap || route?.navigateOnCalendarTouchTap) {
      navigate(`/${serverHash}/calendar/day/${fDate}`)
    } else {
      selectDay(date);
      pathname.includes("day") && navigate(`/${serverHash}/calendar/day/${fDate}`);
    }
  }, [pathname, serverHash, route, navigateOnCalendarTouchTap]);

  return (
    <StyledCalendarSidebar>
      <DatePicker
        variant="static"
        value={selectedDay}
        onChange={handleDayClick}
        ToolbarComponent={DatePickerToolbar} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="secondary"
        variant="fullWidth">
        <StyledTab label="To-Do" {...tabA11yProps(0)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <TodoStack />
      </TabPanel>
    </StyledCalendarSidebar>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.selectedDay,
});

const mapDispatchToProps = (dispatch) => ({
  selectDay: bindActionCreators(dateActions.selectDay, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalendarSidebar));
