import styled from 'styled-components';

export const StyledZeiten = styled.div`
  ${({ theme }) => `
    .zeitenContainer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: ${theme.spacing(8) * 2}px;
      grid-row-gap: ${theme.spacing(8)}px;
    }

    .zeitenSingleDay {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: ${theme.spacing(3)}px;
    }

    .timeGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(1)}px;
    }

    .addRemoveTimeSpaceButtonsContainer {
      margin-top: 15px;
    }

    .addRemoveDayButtonsContainer {
      display: grid;
      grid-template-columns: repeat(2, 25%);
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .addTimeSpaceButton {
      background-color: ${theme.palette.secondary.main};
      color: #fff;
    }

    .removeTimeSpaceButton {
      background-color: ${theme.palette.secondary.main};
      color: #fff;
    }
  `}
`;
