import { createSelector } from 'reselect'
import { sortByBeginnAscending } from '../../utils/dates'
import { sortByDateFieldDescending } from '../../shared/utils/dates'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import { parseISO, isSameDay } from 'date-fns'
import { format, isBefore } from 'date-fns/esm'

export const ersterTerminDesPatientenHeuteOderVergangenheitSelector = createSelector(
  (_, props) => props.patient,
  (state) => state.entities.termine,
  () => format(new Date(), ISO8601DATE_FNS),
  (patientIdStr, termine, heuteStr) => {
    const heute = parseISO(heuteStr)
    const patientId = parseInt(patientIdStr, 10)
    const termineVonPatient = Object.keys(termine)
      .map((key) => termine[key])
      .filter((termin) => termin.patient === patientId)
      .sort(sortByBeginnAscending)
    return !!(
      termineVonPatient[0] &&
      (isSameDay(termineVonPatient[0].beginn, heute) || isBefore(termineVonPatient[0].beginn, heute))
    )
  },
)

export const anamnesenDiesesPatientenSelector = createSelector(
  (_, props) => props.patient,
  (state) => state.entities.anamnesen,
  (patientIdStr, anamnesen) => {
    const patientId = parseInt(patientIdStr, 10)
    return Object.keys(anamnesen)
      .map((id) => anamnesen[id])
      .filter((anamnese) => anamnese.patient === patientId)
      .sort(sortByDateFieldDescending('createdAt'))
  },
)
