import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  getDayOfYear, getHours,
  getMinutes, getYear, set, setDayOfYear,
  setYear
} from 'date-fns'
import { FC, Fragment, useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import { asyncValidateDate } from '../../actions/dates'
import { GERMAN_DATE_LONG_DAY_FNS } from '../../constants/dateFormats'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { MultipleAutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import DateField from '../../shared/components/DateField/DateField'
import InputField from '../../shared/components/InputField/InputField'
import TimePickerField from '../../shared/components/TimePickerField/TimePickerField'
import { validateTerminConditions } from '../../utils/validation'
import { sTerminConditions } from './selectors'
import { StyledTerminOrganisatorischForm } from './StyledTerminOrganisatorischForm'

export const fields = [
  'id',
  'beginn',
  'ende',
  'patient',
  'hausbesuch',
  'heilmittelverordnung',
  'hvoWirdNachgereicht',
  'leistungen',
  'notizen',
  'therapeut',
  'absagegrund',
  'absagedatum',
  'begruendungFrequenzueberschreitung',
  'dokumentation',
  'modifiedAt',
  'istOrganisatorisch',
  'teilnehmerOrganisatorischerTermin',
  'titelOrganisatorischerTermin',
  'isAllDay',
  'dateShadesCalendar',
  'preparationNeeded',
]

interface Props {
  handleSubmit: () => void
  submitFailed: () => void
  error: string | undefined
  users: object
  fields: {
    beginn,
    ende,
    titelOrganisatorischerTermin,
    teilnehmerOrganisatorischerTermin,
    notizen,
    isAllDay,
    dateShadesCalendar,
  },
  praxisstammdaten: {
    standardBehandlungsdauer: number
  }
}

const TerminOrganisatorischForm: FC<Props> = ({
  handleSubmit,
  submitFailed,
  error: terminError,
  users,
  fields,
  fields: {
    beginn,
    ende,
    titelOrganisatorischerTermin,
    teilnehmerOrganisatorischerTermin,
    notizen,
    isAllDay,
    dateShadesCalendar,
  },
  praxisstammdaten,
}) => {
  const [dauerMinuten, setDauerMinuten] = useState<number | null>(null);
  const [dauerTage, setDauerTage] = useState<number | null>(null);

  useEffect(() => {
    const localMinutes = dauerMinuten || praxisstammdaten?.standardBehandlungsdauer || 5
    const localTage = dauerTage || 1
    if (!ende?.value && beginn?.value) {
      if (isAllDay?.value) {
        ende.onChange(addDays(beginn.value, localTage - 1))
      } else {
        ende.onChange(addMinutes(beginn.value, Number(localMinutes)))
      }
    }
  }, [beginn?.value, ende?.value, isAllDay?.value, praxisstammdaten?.standardBehandlungsdauer, dauerMinuten, dauerTage])

  useEffect(() => {
    if (dauerMinuten === null) {
      const { beginn, ende, isAllDay } = fields
      // standard treatment duration
      const standardBehandlungsdauer = praxisstammdaten.standardBehandlungsdauer

      let dauerMinutenLocal: number = 5
      if (standardBehandlungsdauer) {
        dauerMinutenLocal = Number(standardBehandlungsdauer)
      }

      if (beginn.initialValue && ende.initialValue) {
        dauerMinutenLocal = differenceInMinutes(ende.initialValue, beginn.initialValue)
      }
      if (beginn.initialValue && !ende.initialValue && !isAllDay.initialValue) {
        ende.onChange(addMinutes(beginn.initialValue, dauerMinutenLocal))
      }
      setDauerMinuten(dauerMinutenLocal)
    }
    if (dauerTage === null) {
      const { beginn, ende, isAllDay } = fields

      let dauerTageLocal = 1
      if (beginn.initialValue && ende.initialValue) {
        dauerTageLocal = differenceInDays(ende.initialValue, beginn.initialValue) + 1
      }
      if (dauerTageLocal < 1) dauerTageLocal = 1

      if (beginn.initialValue && !ende.initialValue && isAllDay.initialValue) {
        ende.onChange(addDays(beginn.initialValue, dauerTageLocal - 1))
      }
      setDauerTage(dauerTageLocal)
    }
  }, [fields?.beginn, fields?.ende, fields?.isAllDay, praxisstammdaten, dauerMinuten, dauerTage]);

  const therapistsList = Object.keys(users)
    .map((key) => users[key])
    .filter((user) => user.istTherapeut)
    .map(user => ({
      text: [user.vorname, user.nachname].filter(Boolean).join(' '),
      value: user.id
    }));

    return (
      <StyledTerminOrganisatorischForm autoComplete="off" onSubmit={handleSubmit}>
        <div className="row twoItems">
          <CheckboxField
            {...isAllDay}
            label="ganztägig" // all day
            onChange={(checked: boolean) => {
              let currentBegin =  beginn.value || new Date()
              if(isNaN(currentBegin.getTime())) {
                currentBegin = new Date()
                beginn.onChange(currentBegin)
              }
              if (!checked) {
                 // user has disabled isAllDay
                ende.onChange(addMinutes(currentBegin, dauerMinuten || praxisstammdaten?.standardBehandlungsdauer || 5))
              } else {
                // user has enabled isAllDay
                ende.onChange(addDays(currentBegin, (dauerTage || 1) - 1))
              }
              isAllDay.onChange(checked)
            }}
          />
          {isAllDay.value && (
            <CheckboxField
              label="Termin schattiert Kalender"
              {...dateShadesCalendar}
            />
          )}
        </div>
        {beginn.value && (
          <DateField
            className="field"
            disableToolbar
            format={GERMAN_DATE_LONG_DAY_FNS}
            value={beginn.value}
            label="Datum"
            error={!!terminError}
            helperText={!!terminError ? terminError: ''}
            onChange={(date) => {
              const dayOfYear = getDayOfYear(date as Date)
              const year = getYear(date as Date)

              let newBeginn = setDayOfYear(setYear(beginn.value || new Date(), year), dayOfYear)
              if(isNaN(newBeginn.getTime())) {
                newBeginn = new Date()
              }

              let newEnde = isAllDay.value
                ? addDays(newBeginn, (dauerTage || 1) - 1)
                : addMinutes(newBeginn, dauerMinuten || praxisstammdaten?.standardBehandlungsdauer || 5)

              if(isNaN(newEnde.getTime())) {
                newEnde = newBeginn
              }

              beginn.onChange(newBeginn)
              ende.onChange(newEnde)
              // async validation beginn
              beginn.onBlur(newBeginn)
            }}
          />
        )}

        <div className={!isAllDay.value ? 'row': ''}>
          {!isAllDay.value ? (<Fragment>
              {/* {beginn.value && ( */}
                <TimePickerField
                  className="field"
                  error={!!submitFailed && !!beginn.error}
                  helperText={!!submitFailed && !!beginn.error ? beginn.error : ''}
                  label="Beginn"
                  value={beginn.value}
                  onChange={(time: Date) => {
                    const beginnHours = getHours(time)
                    const beginnMinutes = getMinutes(time)
                    const result = set(new Date(beginn.value), { hours: beginnHours, minutes: beginnMinutes })

                    beginn.onChange(result)
                    ende.onChange(addMinutes(result, Number(dauerMinuten)))
                    beginn.onBlur(result)
                  }}
                />
              {/* )} */}
              <InputField
                className="field"
                label="Dauer in Minuten"
                value={dauerMinuten}
                type="number"
                onChange={(value: string | number | null) => {
                  // let duration = Number(value)
                  // setDauerMinuten(duration || '')
                  // let duration = Number(value)
                  // if(isNaN(duration) || duration < 5) {
                  //   duration = 5
                  // }
                  // this.setState({ dauerMinuten: duration })

                  // let currentBegin =  beginn.value || new Date()
                  // if(isNaN(currentBegin.getTime())) {
                  //   currentBegin = new Date()
                  //   beginn.onChange(currentBegin)
                  // }
                  // let newEnde = addMinutes(currentBegin, duration)
                  // ende.onChange(newEnde)
                  // // async validation ende
                  // ende.onBlur(newEnde)
                }}
                onBlur={(value: string | number | null) => {
                  let duration = Number(value)
                  if(isNaN(duration) || duration < 5) {
                    duration = 5
                  }
                  setDauerMinuten(duration)
                  let currentBegin =  beginn.value || new Date()
                  if(isNaN(currentBegin.getTime())) {
                    currentBegin = new Date()
                    beginn.onChange(currentBegin)
                  }
                  let newEnde = addMinutes(currentBegin, duration)
                  ende.onChange(newEnde)
                  // async validation ende
                  ende.onBlur(newEnde)
                }}
              />
              {/* { ende.value && ( */}
                <TimePickerField
                  className="field"
                  error={!!submitFailed && !!ende.error}
                  helperText={!!submitFailed && !!ende.error ? ende.error : ''}
                  label="Ende"
                  value={ende.value}
                  onChange={(time) => {
                    let currentBegin =  beginn.value || new Date()
                    let newEnde = time || new Date()
                    if(isNaN(currentBegin.getTime())) {
                      currentBegin = new Date()
                      beginn.onChange(currentBegin)
                    }
                    if(isNaN(newEnde.getTime())) {
                      newEnde = new Date()
                    }
                    let newDauer = differenceInMinutes(newEnde, currentBegin)
                    if(newDauer < 5) {
                      newDauer = 5
                      newEnde = addMinutes(currentBegin, 5)
                    }
                    ende.onChange(newEnde)
                    setDauerMinuten(newDauer)
                    // async validation ende
                    ende.onBlur(newEnde)
                  }}
                />
              {/* )} */}
            </Fragment>) : (<Fragment>
              <InputField
                className="field"
                label="Dauer in Tagen"
                value={dauerTage}
                type="number"
                onChange={(value: string | number | null) => {
                  let days = Number(value)
                  if(isNaN(days) || days < 1) {
                    days = 1
                  }
                  setDauerTage(days)
                  let currentBegin =  beginn.value || new Date()
                  if(isNaN(currentBegin.getTime())) {
                    currentBegin = new Date()
                    beginn.onChange(currentBegin)
                  }
                  if(days <= 1) {
                    ende.onChange(currentBegin)
                  } else if (days > 1) {
                    ende.onChange(addDays(currentBegin, days - 1))
                  }
                }}
              />
            </Fragment>)}
          </div>

          {isAllDay.value && (
            <DateField
              className="field"
              disableToolbar
              format={GERMAN_DATE_LONG_DAY_FNS}
              error={!!terminError}
              helperText={!!terminError ? terminError : ''}
              label="Ende"
              value={ende.value}
              minDate={beginn.value}

              onChange={(date) => {
                const dayOfYear = getDayOfYear(date as Date)
                const year = getYear(date as Date)

                let currentBegin =  beginn.value || new Date()
                let newEnde = setDayOfYear(setYear(ende.value  || new Date(), year), dayOfYear)

                if(isNaN(currentBegin.getTime())) {
                  currentBegin = new Date()
                  beginn.onChange(currentBegin)
                }
                if(isNaN(newEnde.getTime())) {
                  newEnde = new Date()
                }

                let days = differenceInDays(newEnde, currentBegin) + 1
                if(days < 1) {
                  days = 1
                  newEnde = currentBegin
                }
                setDauerTage(days)
                // this.setState({ dauerTageLocal: days })
                ende.onChange(newEnde)
                // async validation ende
                ende.onBlur(newEnde)
              }}
            />
          )}

          <MultipleAutocompleteField
            className="field"
            {...teilnehmerOrganisatorischerTermin}
            label="Therapeuten *"
            options={therapistsList}
            error={
              !!teilnehmerOrganisatorischerTermin.touched &&
              !!teilnehmerOrganisatorischerTermin.error
            }
            helperText={
              !!teilnehmerOrganisatorischerTermin.touched &&
              !!teilnehmerOrganisatorischerTermin.error
                ? teilnehmerOrganisatorischerTermin.error
                : ''
            }
          />
          <InputField
            className="field"
            {...titelOrganisatorischerTermin}
            error={!!titelOrganisatorischerTermin.touched && !!titelOrganisatorischerTermin.error}
            helperText={
              !!titelOrganisatorischerTermin.touched && !!titelOrganisatorischerTermin.error
                ? titelOrganisatorischerTermin.error
                : ''
            }
            label="Titel *"
            multiline
          />
          <InputField
            className="field"
            {...notizen}
            label="Notiz"
            placeholder="Notiz zu diesem Termin"
            multiline
            rows={2}
          />
      </StyledTerminOrganisatorischForm>
    )
  }

const validate = (values, props) => {
  const required = ['titelOrganisatorischerTermin']

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  if (!values.teilnehmerOrganisatorischerTermin || values.teilnehmerOrganisatorischerTermin.length === 0) {
    errors.teilnehmerOrganisatorischerTermin = 'Sie müssen mindestens einen Therapeut auswählen.'
  }

  return {
    ...errors,
    ...validateTerminConditions(values, props),
  }
}

async function asyncValidate(values, dispatch) {
  await dispatch(asyncValidateDate(values))
  return {}
}

const mapStateToProps = (state) => ({
  gridConfig: state.config.calendarGrid.default,
  termine: state.entities.termine,
  busy: state.notification.busy,
  users: state.entities.users,
  auth: state.auth,
  loading: state.loading,
  praxisstammdaten: praxisstammdatenSelector(state),
  terminConditions: sTerminConditions(state),
})

export default reduxForm(
  {
    form: 'terminOrganisatorischForm',
    returnRejectedSubmitPromise: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['beginn', 'ende'],
    fields,
  },
  mapStateToProps,
)(TerminOrganisatorischForm)
