import { differenceInMinutes, format } from 'date-fns'
import { default as de } from 'date-fns/locale/de'
import * as R from 'ramda'
import { FC, useState } from 'react'
import { connect } from 'react-redux'
import { ContextMenuTermin } from '../../components/MenuTermin'
import TableBody from '../../components/TableBody/TableBody'
import TableHeader from '../../components/TableHeader/TableHeader'
import TableRow from '../../components/TableRow/TableRow'
import { GERMAN_DATE_LONG_DAY_FNS, TIME } from '../../constants/dateFormats'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { sortByBeginnAscending, sortByBeginnDescending } from '../../utils/dates'
import { StyledDatesListView } from './StyledDatesListView'

interface Props {
  actions?: any
  termine?: any
  dokumentationen?: any
  dokumentationenIds?: number[]
  people?: any
  page?: any // pagination page ??????
  filter?: any
  columns?: string[]
  customCols?: Array<{
    header: JSX.Element | string
    data: any // oneOfType([PropTypes.node, PropTypes.func])
  }>
  limit?: number
  hvoView?: boolean
  highlightedDates?: any[]
  setHVOHighlight?: any

  dialogActions?: any
  praxisstammdaten?: any
  rooms?: any
}

const DatesListView: FC<Props> = ({
  actions,
  termine,
  dokumentationen,
  dokumentationenIds,
  people,
  page,
  filter = {},
  columns = ['datum', 'beginn', 'dauer', 'therapeut', 'dokumentiert'],
  customCols = [],
  limit = 6,
  hvoView = false,
  highlightedDates,
  setHVOHighlight,
  dialogActions,
  praxisstammdaten,
  rooms,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [pointerLocation, setPointerLocation] = useState<any>({ mouseX: null, mouseY: null })
  const [selectedTermin, setSelectedTermin] = useState<any | null>(null)

  const _handleTerminClick = (ev, { id }) => {
    const termin = termine[id]
    ev.persist()
    setPointerLocation({
      mouseX: ev.clientX,
      mouseY: ev.clientY,
    })
    setSelectedTermin(id)
  }

  const _toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleContextMenuClose = () => {
    setSelectedTermin(null)
    setPointerLocation({
      mouseX: null,
      mouseY: null,
    })
  }

  const tableColumnsWithoutRoom = [
    {
      label: 'Datum',
      className: 'columnHeader',
      name: 'datum',
    },
    {
      label: 'Beginn',
      className: 'columnHeader',
      name: 'beginn',
    },
    {
      label: 'Dauer (Min)',
      className: 'columnHeader',
      name: 'dauer',
    },
    {
      label: 'Therapeut',
      className: 'columnHeader',
      name: 'therapeut',
    },
    {
      label: 'Dokumentiert?',
      className: 'columnHeader',
      name: 'dokumentiert',
    },
  ]
    .filter((col) => columns?.indexOf(col.name) !== -1)
    .concat(
      customCols.map((col) => ({
        label: col?.header,
        className: 'columnHeader',
      })),
    )

  const tableColumnsWithRoom = [
    {
      label: 'Datum',
      className: 'columnHeader',
      name: 'datum',
    },
    {
      label: 'Beginn',
      className: 'columnHeader',
      name: 'beginn',
    },
    {
      label: 'Dauer (Min)',
      className: 'columnHeader',
      name: 'dauer',
    },
    {
      label: 'Therapeut',
      className: 'columnHeader',
      name: 'therapeut',
    },
    {
      label: 'Raum',
      className: 'columnHeader',
      name: 'room',
    },
    {
      label: 'Dokumentiert?',
      className: 'columnHeader',
      name: 'dokumentiert',
    },
  ]
    .filter((col) => columns?.indexOf(col.name) !== -1)
    .concat(
      customCols.map((col) => ({
        label: col?.header,
        className: 'columnHeader',
      })),
    )

  const termineFiltered = Object.keys(termine)
    .map((id) => termine[id])
    .filter((date) => {
      let result = !!date
      if (filter.patient) {
        result = result && filter.patient === date.patient
      }
      if (filter.hvo) {
        result = result && filter.hvo === date.heilmittelverordnung
      }
      if (filter.abgesagt) {
        result = result && typeof date.absagegrund === 'string' && date.absagegrund !== ''
      } else {
        result = result && (typeof date.absagegrund !== 'string' || date.absagegrund === '')
      }
      return result
    })

  const columnCount = columns.length + customCols.length
  let rowsRendered = 0

  return (
    <>
      <StyledDatesListView>
        <TableHeader cols={praxisstammdaten?.treatmentRoomFeature ? tableColumnsWithRoom : tableColumnsWithoutRoom} />
        <TableBody className="TableBody">
          {termineFiltered.sort(sortByBeginnDescending).map((date) => {
            const therapeut = Object.keys(people)
              .map((key) => people[key])
              .filter((person) => date.therapeut === person.id)[0]

            const room = date?.room
              ? Object.keys(rooms)
                  .map((key) => rooms[key])
                  .filter((r) => date.room === r.id)[0]
              : { abbreviation: '–' }

            //TODO:- CHeck the logic below. The documentation check is being done on the parents as well, and the parent sends the value as a customCol. Can be moved right here I guess
            let dokumentiert = ''
            if (dokumentationenIds) {
              if (
                dokumentationenIds.filter((key) => dokumentationen[key] && dokumentationen[key].termin === date.id)
                  .length === 0
              ) {
                dokumentiert = 'nein'
              } else {
                dokumentiert = 'ja'
              }
            }

            rowsRendered++
            if (!showAll && rowsRendered > limit) {
              return null
            }

            const rowClassName =
              highlightedDates && highlightedDates.includes(date.id) ? 'TableRow highlightRow' : 'TableRow'

            let mouseEnter, mouseLeave
            if (setHVOHighlight) {
              mouseEnter = R.partial(setHVOHighlight, [date.heilmittelverordnung])
              mouseLeave = R.partial(setHVOHighlight, [undefined])
            }

            const tableDataWithoutRoom = [
              {
                data: format(date.beginn, GERMAN_DATE_LONG_DAY_FNS, {
                  locale: de,
                }),
                className: 'columnContent',
                name: 'datum',
              },
              {
                data: format(date.beginn, TIME),
                className: 'columnContent',
                name: 'beginn',
              },
              {
                data: differenceInMinutes(date.ende, date.beginn),
                className: 'columnContent',
                name: 'dauer',
              },
              {
                data: [therapeut?.vorname, therapeut?.nachname].filter(Boolean).join(' '),
                className: 'columnContent',
                name: 'therapeut',
              },
              {
                data: dokumentiert || '',
                className: 'columnContent',
                name: 'dokumentiert',
              },
            ]

            const tableDataWithRoom = [
              {
                data: format(date.beginn, GERMAN_DATE_LONG_DAY_FNS, {
                  locale: de,
                }),
                className: 'columnContent',
                name: 'datum',
              },
              {
                data: format(date.beginn, TIME),
                className: 'columnContent',
                name: 'beginn',
              },
              {
                data: differenceInMinutes(date.ende, date.beginn),
                className: 'columnContent',
                name: 'dauer',
              },
              {
                data: [therapeut?.vorname, therapeut?.nachname].filter(Boolean).join(' '),
                className: 'columnContent',
                name: 'therapeut',
              },
              {
                data: [room?.abbreviation],
                className: 'columnContent',
                name: 'room',
              },
              {
                data: dokumentiert || '',
                className: 'columnContent',
                name: 'dokumentiert',
              },
            ]

            return (
              <TableRow
                onTdClick={_handleTerminClick}
                className={rowClassName}
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
                key={date.id}
                id={date.id}
                cols={
                  praxisstammdaten?.treatmentRoomFeature
                    ? tableDataWithRoom
                        .filter((col) => columns.indexOf(col.name) !== -1)
                        .concat(
                          customCols.map((col) => ({
                            data: typeof col.data === 'function' ? col.data(date) : col.data,
                            className: 'columnContent',
                          })),
                        )
                    : tableDataWithoutRoom
                        .filter((col) => columns.indexOf(col.name) !== -1)
                        .concat(
                          customCols.map((col) => ({
                            data: typeof col.data === 'function' ? col.data(date) : col.data,
                            className: 'columnContent',
                          })),
                        )
                }
              />
            )
          })}
          {termineFiltered.length == 0 && (
            <TableRow
              className="TableRow"
              cols={[
                {
                  data: 'bisher keine',
                  className: 'columnContent showAll empty',
                  colSpan: columnCount,
                },
              ]}
            />
          )}
          {termineFiltered.length > limit && (
            <TableRow
              className="TableRow"
              onTdClick={_toggleShowAll}
              cols={[
                {
                  data: showAll ? 'weniger anzeigen' : 'alle anzeigen',
                  className: 'columnContent showAll',
                  colSpan: columnCount,
                },
              ]}
            />
          )}
        </TableBody>
      </StyledDatesListView>
      <ContextMenuTermin
        calendarView={false}
        hvoView={hvoView}
        selectedTermin={selectedTermin}
        pointerLocation={pointerLocation}
        onCloseFunc={handleContextMenuClose}
        dialogActions={dialogActions}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  gridConfig: state.config.calendarGrid.default,
  termine: state.entities.termine,
  dokumentationen: state.entities.dokumentationen,
  dokumentationenIds: state.ids.dokumentationen,
  selectedDay: state.selectedDay,
  people: state.entities.users,
  rooms: state.entities.rooms,
  praxisstammdaten: praxisstammdatenSelector(state),
})

export default connect(mapStateToProps, null)(DatesListView)
