import {fetchSecure} from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import {RequestConfig} from './interface'

export function loadTodosData(type, todos): RequestConfig {
  const entityIds: any = {};
  todos.forEach((todo: any) => {
    if (!todo.referenceType || !todo.referenceId) {
      return;
    }

    if (todo.type === type) {
      if (!Array.isArray(entityIds[todo.referenceType])) {
        entityIds[todo.referenceType] = [];
      }
      entityIds[todo.referenceType].push(todo.referenceId);
    }
  });

  let actionTypes;
  switch (type){
    case 'dokumentation_erfassen':
      actionTypes = [types.LOAD_TODOS_DATA_DOCUMENTATION, types.LOAD_TODOS_DATA_DOCUMENTATION_SUCCESS, types.LOAD_TODOS_DATA_DOCUMENTATION_FAILURE]
      break;
    case 'anamnese_erstellen':
      actionTypes = [types.LOAD_TODOS_DATA_ANAMNESE, types.LOAD_TODOS_DATA_ANAMNESE_SUCCESS, types.LOAD_TODOS_DATA_ANAMNESE_FAILURE]
      break;
    case 'folgetermin_vereinbaren':
      actionTypes = [types.LOAD_TODOS_DATA_FOLGETERMIN, types.LOAD_TODOS_DATA_FOLGETERMIN_SUCCESS, types.LOAD_TODOS_DATA_FOLGETERMIN_FAILURE]
      break;
    case 'vervollstaendigung_patientenkartei':
      actionTypes = [types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG, types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_SUCCESS, types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_FAILURE]
      break;
    case 'rezept_abrechnen':
      actionTypes = [types.LOAD_TODOS_DATA_REZEPT, types.LOAD_TODOS_DATA_REZEPT_SUCCESS, types.LOAD_TODOS_DATA_REZEPT_FAILURE]
      break;
    case 'therapiebericht_erstellen':
      actionTypes = [types.LOAD_TODOS_DATA_THERAPIEBERICHT, types.LOAD_TODOS_DATA_THERAPIEBERICHT_SUCCESS, types.LOAD_TODOS_DATA_THERAPIEBERICHT_FAILURE]
      break;
  }

  return {
    types: actionTypes,
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({apiUrl, auth0Credentials}): void => {
      const URL = `${apiUrl}/todosData`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          body: JSON.stringify({entityIds}),
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export const clearTodos = makeActionCreator(types.CLEAR_TODOS)
