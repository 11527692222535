import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { RequestConfig } from './interface'

export function loadDokumentationsTexte(): RequestConfig {
  return {
    types: [
      types.LOAD_DOKUMENTATIONSTEXTE,
      types.LOAD_DOKUMENTATIONSTEXTE_SUCCESS,
      types.LOAD_DOKUMENTATIONSTEXTE_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dokumentationsTexte`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createDokumentationsText(data): RequestConfig {
  return {
    types: [
      types.CREATE_DOKUMENTATIONSTEXT,
      types.CREATE_DOKUMENTATIONSTEXT_SUCCESS,
      types.CREATE_DOKUMENTATIONSTEXT_FAILURE,
    ],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dokumentationsTexte`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}
