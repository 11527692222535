import * as R from 'ramda'
import { createSelector } from 'reselect'
import { createSelectorN } from '../../utils/functions'

const sBuchungId = (_, props) => props.buchung.id

const sTermine = (state) => state.entities.termine

const sZahlungen = (state) => state.entities.zahlungen
const sZahlungenArray = createSelector(sZahlungen, R.values)

const sZahlungspositionen = (state) => state.entities.zahlungspositionen
const sZahlungspositionenArray = createSelector(sZahlungspositionen, R.values)

export const sZahlungenZuBuchung = createSelectorN(
  sBuchungId,
  sZahlungenArray,
  R.compose(R.filter, R.propEq('buchung')),
)

const sZahlungZuBuchung = createSelector(sZahlungenZuBuchung, R.compose(R.defaultTo({}), R.head))

const sPositionenZuBuchung = createSelectorN(
  sZahlungZuBuchung,
  sZahlungspositionenArray,
  R.compose(R.filter, R.propEq('zahlung'), R.prop('id')),
)

const sTerminIdZuBuchung = createSelector(
  sPositionenZuBuchung,
  R.compose(R.prop('termin'), R.defaultTo({}), R.head, R.filter(R.compose(R.not, R.isNil, R.prop('termin')))),
)

export const sTerminZuBuchung = createSelectorN(sTerminIdZuBuchung, sTermine, R.prop)

export const sBuchungHatTermin = createSelector(sTerminIdZuBuchung, R.compose(R.not, R.isNil))
