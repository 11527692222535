import { getHours, getISODay, getMinutes, isValid, parseISO } from 'date-fns'
import { createSelector } from 'reselect'
import { praxisstammdatenSelector } from '../selectors/selectors'

const weekdays = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
  7: 'sun',
}

const minutesOfDay = (m) => {
  return getMinutes(m) + getHours(m) * 60
}

const isBetween = (m, a, b) => {
  const minutesM = minutesOfDay(m)
  const minutesA = minutesOfDay(a)
  const minutesB = minutesOfDay(b)
  return minutesA <= minutesM && minutesB >= minutesM
}

export const filterUngueltigeZeiten = (zeiten) => {
  if (!Array.isArray(zeiten)) {
    return []
  }
  return zeiten.filter((tag) => {
    return tag.zeiten.filter((zeit) => isValid(parseISO(zeit.von)) && isValid(parseISO(zeit.bis))).length !== 0
  })
}

export const getOeffnungsZeiten = createSelector(praxisstammdatenSelector, (praxisstammdaten) => {
  return praxisstammdaten && praxisstammdaten.oeffnungszeiten
    ? filterUngueltigeZeiten(praxisstammdaten.oeffnungszeiten)
    : []
})

export const istAusserhalbDerZeiten = (beginn, ende, zeiten) => {
  if (zeiten.length === 0 || !beginn || !ende) {
    return false
  }
  const tagDesTermins = weekdays[getISODay(beginn)]
  for (const tag of zeiten) {
    if (tag.tag === tagDesTermins) {
      for (const zeit of tag.zeiten) {
        if (!zeit.von == '' || !zeit.bis == '') {
          const von = parseISO(zeit.von)
          const bis = parseISO(zeit.bis)
          if (isBetween(beginn, von, bis) && isBetween(ende, von, bis)) {
            return false
          }
        }
      }
    }
  }
  return true
}
