import * as R from 'ramda'
import dokumentation_erfassen from './dokumentation_erfassen'
import anamnese_erstellen from './anamnese_erstellen'
import folgetermin_vereinbaren from './folgetermin_vereinbaren'
import vervollstaendigung_patientenkartei from './vervollstaendigung_patientenkartei'
import rezept_abrechnen from './rezept_abrechnen'
import therapiebericht_erstellen from './therapiebericht_erstellen'

// here the order of the todotypes in the todostack view is determined!
const types = {
  dokumentation_erfassen,
  anamnese_erstellen,
  folgetermin_vereinbaren,
  vervollstaendigung_patientenkartei,
  rezept_abrechnen,
  therapiebericht_erstellen,
}

// map over an object (with the key as the second parameter)
// mapWithKeys ::  (Object -> String -> Object) -> Object -> Object
const mapWithKeys = R.curry((fn, obj) => R.fromPairs(R.map((arr) => [arr[0], fn(arr[1], arr[0])], R.toPairs(obj))))

export const typesObj = mapWithKeys((data, key) => {
  return { ...data, name: key }
}, types)

export default R.values(typesObj)
