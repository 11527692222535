import type { User, UserManager } from 'oidc-client'
import { createContext } from 'react'

interface AuthContext {
  isAuthenticated: boolean
  isAuthLoading: boolean
  user: User | null
  signinRedirect: UserManager['signinRedirect']
  signout: () => Promise<void>
}

const initialAuthContext = {
  isAuthenticated: false,
  isAuthLoading: true,
  user: null,
  signinRedirect: () => Promise.resolve(),
  signout: () => Promise.resolve(),
}

export const AuthContext = createContext<AuthContext>(initialAuthContext)
