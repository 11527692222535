import platform from 'platform';
import PododeskLogo from '../PododeskLogo/PododeskLogo';
import { StyledBrowserCheck } from './StyledBrowserCheck';

const defaultSupportedBrowsers = {
  'Android Browser': {
    minVersion: 0,
    description: 'Android Browser',
  },
  Chrome: {
    minVersion: 49,
    description: 'Google Chrome',
  },
  'Chrome Mobile': {
    minVersion: 49,
    description: 'Google Chrome Mobile',
  },
  Firefox: {
    minVersion: 47,
    description: 'Mozilla Firefox',
  },
  'Firefox Mobile': {
    minVersion: 47,
    description: 'Mozilla Firefox Mobile',
  },
  Safari: {
    minVersion: 9,
    description: 'Apple Safari',
  },
  IE: {
    minVersion: 11,
    description: 'Microsoft Internet Explorer',
  },
  'Microsoft Edge': {
    minVersion: 13,
    description: 'Microsoft Edge',
  },
  Opera: {
    minVersion: 38,
    description: 'Opera',
  },
  'Opera Mobile': {
    minVersion: 37,
    description: 'Opera Mobile',
  },
};

const BrowserCheck = ({ children, supportedBrowsers = defaultSupportedBrowsers }) => {
  const browsers = Object.keys(supportedBrowsers);
  const browserSupported = browsers.indexOf(platform.name) !== -1;
  const browserVersion = parseInt(platform.version, 10);
  const browserName = platform.name;

  let browserVersionSupported = false;
  if (browserSupported) {
    browserVersionSupported = browserVersion >= supportedBrowsers[browserName].minVersion;
  }

  if (browserSupported && browserVersionSupported) {
    return children;
  }

  return (
    <StyledBrowserCheck>
      <div className="message">
        <div className="logo">
          <PododeskLogo />
          {!browserSupported ? (
            <div>
              {' '}
              <span className="heading">Falscher Webbrowser</span>
              <br />
              <span>
                Sie benutzen den Webbrowser &quot;{browserName}&quot;.
                <br />
                Wir empfehlen zur Nutzung von pododesk Google Chrome zu verwenden, für Hilfe mit der Installation von
                Chrome klicken sie bitte
                <a
                  className="link"
                  href="https://support.google.com/chrome/answer/95346?hl=de&ref_topic=3421641"
                  target="_blank"
                  rel="noreferrer"
                >
                  hier
                </a>
                .
                <br />
                <br />
                pododesk unterstützt folgende Browser:
                <ul className="ul">
                  {browsers.map((browser, idx) => (
                    <li key={idx}>
                      {supportedBrowsers[browser].description} ab Version {supportedBrowsers[browser].minVersion}
                    </li>
                  ))}
                </ul>
              </span>
            </div>
          ) : (
            <div>
              <span className="heading">Veraltete Webbrowser-Version</span>
              <br />
              <span>
                Sie verwenden eine zu alte Version ({browserVersion}) von &quot;
                {supportedBrowsers[browserName].description}
                &quot;.
                <br />
                <br />
                pododesk unterstützt folgende Browser-Versionen:
                <ul className="ul">
                  {browsers.map((browser, idx) => (
                    <li key={idx}>
                      {supportedBrowsers[browser].description} ab Version {supportedBrowsers[browser].minVersion}
                    </li>
                  ))}
                </ul>
              </span>
            </div>
          )}
        </div>
      </div>
    </StyledBrowserCheck>
  );
};

export default BrowserCheck;
