import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loadCustomer } from '../../actions/customers'
import DokumentationenForm from '../DokumentationenForm/DokumentationenForm'

const Dokumentationen = ({ actions }) => {
  const params = useParams();

  useEffect(() => {
    actions.loadCustomer(params.patient, true)
  }, [])

  const { patient: patientString, termin: terminString } = params
  const patientId = parseInt(patientString, 10)
  const terminId = parseInt(terminString, 10)

  return <DokumentationenForm patientId={patientId} terminId={terminId} />
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCustomer,
    },
    dispatch,
  ),
})

export default connect(null, mapDispatchToProps)(Dokumentationen)
