import styled from 'styled-components';
import { Theme } from '@material-ui/core';

export const StyledCalendarWeekView = styled.div`
  ${({ theme, computedHeight, isSnackbarOpen }: { theme: Theme, computedHeight: number, isSnackbarOpen: boolean }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    height:  ${isSnackbarOpen ? '82vh;' : '100vh;'}

    .calendarWeekViewMain {
      display: flex;
      flex-direction: row;
      margin-top: ${theme.spacing(3)}px;
      margin-left: ${theme.spacing(3)}px;
      /* The fixed margin comes from the width of the spacer (StyledCalendarColumnSpacer). */
      margin-right: 45px;
      flex-grow: 1;
      height: ${computedHeight}px;
    }

    .calendarWeekViewTopBar {
      display: flex;
      flex-direction: row;
      height: ${theme.spacing(5)}px;
    }
  `}
`;
