import numeral from 'numeral'
export const DEFAULT_CURRENCY_FORMAT = '0,0.[0000000000]' // show max 0-10 digits after '.' 

numeral.register('locale', 'de')

// Check that later. if need extend register and call numeral.locale('de');

// numeral.register('locale', 'de', 
// {
//   delimiters: {
//     thousands: ',',
//     decimal: '.'
//   },
// }
// )
// numeral.locale('de');

// Locale example:
// numeral.register('locale', 'fr', {
//   delimiters: {
//       thousands: ' ',
//       decimal: ','
//   },
//   abbreviations: {
//       thousand: 'k',
//       million: 'm',
//       billion: 'b',
//       trillion: 't'
//   },
//   ordinal : function (number) {
//       return number === 1 ? 'er' : 'ème';
//   },
//   currency: {
//     symbol: '€'
//   }
// });
