/*
“This software (code) is copyright by Worldline Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied,
including without limitation, any warranties of merchantability or fitness for a particular purpose.
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Worldline Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen.
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Worldline Healthcare GmbH
 *
 *  description:                  Command APDUs for the eGK.
 *
 *  history:
 *  *.2021 tpe                    created
 *  *.2023 tpe                    redesign
 */

import { Tools } from '../utils/general/tools'

export class EGK_commands {
  // Select file with application identifier (AID)
  public static select_file_with_AID(aid: Uint8Array): Uint8Array {
    const lengthAID = Tools.convertToOneByte(aid.length)
    return new Uint8Array([0x00, 0xa4, 0x04, 0x0c, lengthAID[0], ...aid])
  }

  // Read with short file identifier (FID)
  public static read_with_SFID(sfid: Uint8Array, length: number = 0, offset: number = 0): Uint8Array {
    const lengthArray = Tools.convertToTwoByte(length)
    const offsetArray = Tools.convertToOneByte(offset)
    const sfidArray = Tools.convertToOneByte(sfid[0])
    return new Uint8Array([0x00, 0xb0, 0x80 + sfidArray[0], offsetArray[0], 0x00, lengthArray[0], lengthArray[1]])
  }
}
