import styled from 'styled-components'

export const StyledArbeitszeiten = styled.form`
  .paper {
    padding: 30px;
    background-color: #fff;
  }

  .submitButton {
    margin-top: 20px;
    width: 100%;
  }

  h2 {
    font-weight: 500;
  }
`
