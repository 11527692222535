import { format } from 'date-fns'
import { FC } from 'react'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import PatientIcon from './PatientIcon'
import { StyledPatientText } from './StyledPatientText'

interface Props {
  showGeburtsdatum?: boolean, // show date of birth
  showGender?: boolean,
  patient?: any, // TODO: Fix type
  noShowAppointment?: boolean,
  iconColor?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary' | undefined,
  className?: string
  hvoTerminCountText?: string
}

const PatientText: FC<Props> = ({
  showGeburtsdatum,
  showGender,
  patient,
  noShowAppointment,
  iconColor = 'inherit',
  className,
  hvoTerminCountText,
}) => {
  if (!patient) {
    return null
  }

  const { titel, nachname, verstorben, geburtsdatum, geschlecht, vorname } = patient

  const vornameText = vorname ? `, ${vorname}` : ''
  const birthdate = geburtsdatum && showGeburtsdatum ? ` *${format(geburtsdatum, GERMAN_DATE_SHORT_YEAR_FNS)}` : ''
  const isTrans = geschlecht === "trans";
  const showIcon = !showGender && vornameText === '';

  return (
    <StyledPatientText className={showIcon ? className + " text-icon-wrapper" : className}>
      {noShowAppointment && <span> NICHT ERSCHIENEN: </span>}
      {showGender && <PatientIcon className={'icon ' + (isTrans ? 'xs-icon' : '')} gender={geschlecht} iconColor={iconColor} />}
      {titel ? <span>{titel}</span> : ''}
      <span>{nachname}</span> 
      {showIcon ? <PatientIcon className={'icon ' + (isTrans ? 'xs-icon' : '')} gender={geschlecht} iconColor={iconColor} /> : vornameText}
      {verstorben ? ' \u2020' : ''}
      {birthdate}
      {hvoTerminCountText}
    </StyledPatientText>
  )
}

export default PatientText
