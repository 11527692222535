import { KeyboardDatePicker } from '@material-ui/pickers'
import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { GERMAN_DATE_LONG_YEAR_FNS } from '../../../constants/dateFormats'

interface Props {
  onChange?: (date: Date | null) => void,
  onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void,
  defaultToToday?: boolean,
  label?: string | Element,
  value?: Date | null,
  format?: string,
  autoOk?: boolean,

  helperText?: string,
  readOnly?: boolean,
  touched?: boolean,
  error?: boolean,
  minDate?: Date,
  maxDate?: Date,

  disableToolbar?: boolean,
  disableFuture?: boolean,
  disablePast?: boolean,
  className?: string,
  disabled?: boolean,
}

const isDateValid = (date: Date | null): boolean => {
 return !!date && !isNaN(date.getTime())
}

const getValidDate = (date: Date): Date | null => {
  let newValue: Date | null = null;
  try {
    newValue = new Date(date)
    if(!isDateValid(newValue)) {
      newValue = null
    }
  } catch (error) {
    console.log(error)
    newValue = null
  }
  return newValue;
}

const DateField: FC<Props> = ({
  onChange,
  onKeyUp,
  defaultToToday,
  label,
  value,
  format = GERMAN_DATE_LONG_YEAR_FNS,
  autoOk = true,

  helperText,
  readOnly,
  error,
  minDate,
  maxDate,

  disableToolbar,
  disableFuture,
  disablePast,
  className = '',
  disabled = false,
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  useEffect(() => {
    let newValue: Date | null = null
    if(value) {
      newValue = getValidDate(value)
    } else if(defaultToToday) {
      newValue = new Date()
    }
    setSelectedDate(newValue)
  }, [value, defaultToToday])

  const handleDateChange = useCallback(
    (date) => {
      setSelectedDate(date)
      if (isDateValid(date)) {
        onChange && onChange(date)
      }
    },
    [setSelectedDate, onChange],
  )

  const handleDateBlur = useCallback(
    (event) => {
      let newValue: Date | null = null
      if (selectedDate) {
        newValue = getValidDate(selectedDate)
      } else if (defaultToToday) {
        newValue = new Date()
      }
      setSelectedDate(newValue)
      onChange && onChange(newValue)
    },
    [selectedDate, defaultToToday, setSelectedDate, onChange],
  )

  return (
    <KeyboardDatePicker
      // onClick={() => setOpen(true)}
      // onClose={() => setOpen(false)}
      // open={open}
      onBlur={(event) => handleDateBlur(event)}
      variant="inline"
      fullWidth
      disableToolbar={disableToolbar}
      label={label}
      value={selectedDate}
      onChange={(date) => handleDateChange(date)}
      onKeyUp={onKeyUp}
      minDate={minDate}
      maxDate={maxDate}
      format={format}
      autoOk={autoOk}

      error={error}
      helperText={helperText}
      readOnly={readOnly}

      disableFuture={disableFuture}
      disablePast={disablePast}
      className={className}
      disabled={disabled}
    />
  );
}

export default DateField;
