/* eslint guard-for-in:0 */
import * as R from 'ramda'

export default function autocomplete(state = {}, action = undefined) {
  if (action.json && action.json.autocomplete) {
    return R.mergeAll([{}, state, action.json.autocomplete])
  }

  return state
}
