import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Anamnesis from './Anamnesis';

interface Props {
  dialogActions?: any,
  sidebar?: JSX.Element,
  form?: JSX.Element,
}

export const AnamnesisContainer: FC<Props> = ({ form, sidebar, dialogActions }): JSX.Element => {
  const {patient, serverHash} = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  return <Anamnesis
    dialogActions={dialogActions}
    form={form}
    sidebar={sidebar}
    patient={patient}
    serverHash={serverHash}
    location={location}
    navigate={navigate} />;
};
