import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import {
  BluetoothDisabled as BluetoothDisabledIcon,
  BluetoothSearching as BluetoothSearchingIcon,
  SettingsRemote as SettingsRemoteIcon,
} from '@material-ui/icons'
import { useContext } from 'react'
import styled from 'styled-components'
import { CardReaderContext } from '../../containers/OverlayContainer/CardReaderContext'

interface Props {
  dialogActions: any
  onCloseFunc: () => any
}

const MenuItemLabel = styled(ListItemText)`
  margin-left: -20px;
`

export const MenuItemsCardReader = ({ dialogActions, onCloseFunc }: Props): JSX.Element => {
  const {
    isBluetoothConnected,
    connectBluetooth,
    disconnectBluetooth,
  } = useContext(CardReaderContext)

    const handleDisconnectBluetooth = (): void => {
        disconnectBluetooth()
        onCloseFunc()
    }

    const handleConnectBluetooth = (): void => {
        connectBluetooth()
        onCloseFunc()
    }

    const handleOpenReadCardDataDialog = (): void => {
        dialogActions?.openReadCardDataDialog()
        onCloseFunc()
    }
    
    return isBluetoothConnected ? (
        <>
          <MenuItem onClick={handleOpenReadCardDataDialog}>
            <ListItemIcon>
                <SettingsRemoteIcon color="secondary" />
            </ListItemIcon>
            <MenuItemLabel>eGK-Daten auslesen</MenuItemLabel>
          </MenuItem>
          <MenuItem onClick={handleDisconnectBluetooth}>
            <ListItemIcon>
              <BluetoothDisabledIcon color="secondary" />
            </ListItemIcon>
            <MenuItemLabel primary="Verbindung trennen" />
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={handleConnectBluetooth}>
            <ListItemIcon>
              <BluetoothSearchingIcon color="secondary" />
            </ListItemIcon>
            <MenuItemLabel primary="Verbindung herstellen" />
          </MenuItem>
        </>
      )
}
