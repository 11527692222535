import { sApiServerName } from '../../shared/utils/auth'

const { localStorage } = window

const initalState = {
  locked: null,
}

export const LOCK_SCREEN = 'pod001_frontend/lockscreen/LOCK_SCREEN'
export const UNLOCK_SCREEN = 'pod001_frontend/lockscreen/UNLOCK_SCREEN'
export const RESTORE_LOCK_STATE = 'pod001_frontend/lockscreen/RESTORE_LOCK_STATE'

export default function (state = initalState, action) {
  switch (action.type) {
    case LOCK_SCREEN:
      return {
        locked: true,
      }
    case UNLOCK_SCREEN:
      return {
        locked: false,
      }
    case RESTORE_LOCK_STATE:
      return {
        locked: action.locked,
      }
    default:
  }
  return state
}

export const lockScreen = () => (dispatch, getState) => {
  const serverName = sApiServerName(getState())
  persist(true, serverName)
  return dispatch({
    type: LOCK_SCREEN,
  })
}

export const unlockScreen = () => (dispatch, getState) => {
  const serverName = sApiServerName(getState())
  persist(false, serverName)
  return dispatch({
    type: UNLOCK_SCREEN,
  })
}

export const restoreLock = (state) => {
  const serverName = sApiServerName(state)
  let locked
  try {
    const str = localStorage['locked_' + serverName]
    locked = typeof str === 'string' && JSON.parse(str)
  } catch (error) {
    if (__DEV__) console.error(error)
    locked = false
  }

  return {
    type: RESTORE_LOCK_STATE,
    locked,
  }
}

const persist = (locked, serverName) => {
  localStorage['locked_' + serverName] = JSON.stringify(locked)
}
