import styled from 'styled-components';

export const StyledHVOForm = styled.form`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3)}px ${theme.spacing(6)}px;

    .report {
      margin-left: 0;

      label {
        margin: 0;
      }
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3)}px;
    }

    .patientInfo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: ${theme.spacing(6)}px;
    }

    .patientInfoGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .streetGroup {
      display: grid;
      grid-template-columns: 1fr 0.25fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .addressGroup {
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .checkboxArea {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(6)}px;
    }

    .checkboxGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
      grid-auto-rows: min-content;
    }

    .nagelkorrekturInfo {
      display: flex;
      flex-direction: row;
      margin-top: ${theme.spacing(3)}px;

      span:last-child {
        margin-left: ${theme.spacing(3)}px;
      }
    }

    .dateGroup {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${theme.spacing(6)}px;

      ${theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
        grid-gap: ${theme.spacing(3)}px;
      }
    }

    .hvoInfo {
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1.5fr 0.5fr 0.5fr;
      grid-column-gap: ${theme.spacing(6)}px;
    }

    .frequencyGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .icdGroup {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(6)}px;
      margin: ${theme.spacing(6)}px 0;
    }

    .icdInputGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .infoPaper {
      padding: ${theme.spacing(3)}px;

      > a {
        color: ${theme.palette.secondary.main};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .MuiInputBase-root.Mui-disabled {
      color: #000;
    }
    .MuiFormLabel-root.Mui-disabled {
      color: #5f6367;
    }

    .fieldRow {
      display: flex;
      align-items: center;

      .checkboxList {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      label {
      margin-right: 0;
      white-space: nowrap;
      }
    }
  `}
`

export const ErrorText = styled.span`
  color: #600;
  font-size: 12px;
  line-height: 12px;
  display: 'block';
  padding-top: '10px';
`

export const HeadingRed = styled.h3`
  display: flex;
  align-items: center;
  color: #ff0000;
`

export const HeadingRedInner = styled.span`
  margin-right: 10px;
`

export const LinkParagraph = styled.p`
  margin-top: 0;
`

export const LinkSeparator = styled.span`
  margin: 0 5px;
`

export const ButtonContainer = styled.div`
  margin-top: 15px;
`

export const labelStyle = {
  paddingTop: '2px',
  marginRight: '60px',
  width: 'auto',
}
