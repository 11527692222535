import styled from 'styled-components'

export const TableContainer = styled('table')({
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  width: 600,
})

export const TableBody = styled('tbody')({
  'tr > td:first-child': {
    fontWeight: 'bold',
    width: '300px',
  },

  fontSize: '1rem',
})

export const TableRow = styled('tr')(({ theme }) => ({
  borderBottom: '2px solid black',

  '&:last-child': {
    borderBottom: 'none',
  },

  '> td:first-child': {
    backgroundColor: theme.palette.primary.light,
  },
}))

export const TableCell = styled('td')({
  padding: '16px 8px',

})

export const TableHeadCell = styled('th')({
  padding: '16px 8px',
})

export const TableHeadCellContent = styled('div')({
  display: 'flex',
  gap: 16,
  justifyContent: 'center',
  alignItems: 'center',
})

export const InnerTableHeadCellContent = styled('div')({
  display: 'flex',
  gap: 4,
  flexDirection: 'column',
  alignItems: 'flex-start'
})

export const TableCellMultiline = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 8,
})
