import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

interface AutocompleteOption {
  label: string
  value: any
  undeleteable?: boolean
  unselectable?: boolean
  filterText?: string
}

interface Props {
  name: string
  control: any
  rules?: RegisterOptions
  shouldUnregister?: boolean
  defaultValue?: any
  options: AutocompleteOption[]
  className?: string
  inputClassName?: string
  InputProps?: any
  autoComplete?: string
  fullWidth?: boolean
  helperText?: string
  label: string
  disabled?: boolean
  allowedValues?: any[]
}

export const ControlledMultipleAutocompleteField = ({
  name,
  rules,
  control,
  shouldUnregister,
  defaultValue,
  options = [],
  className,
  inputClassName,
  autoComplete = 'new-password',
  fullWidth,
  helperText,
  label,
  InputProps,
  disabled,
  allowedValues,
}: Props): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>([])
  const [currentOptions, setCurrentOptions] = useState<AutocompleteOption[]>([])
  const { field, fieldState } = useController({ name, rules, control, shouldUnregister, defaultValue })

  console.log('field.value: ', field.value)
  console.log('options: ', options)
  console.log('currentOptions: ', currentOptions)
  console.log('selectedOptions: ', selectedOptions)

  useEffect(() => {
    setCurrentOptions(options ?? [])
  }, [options])


  useDeepCompareEffect(() => {
    const newSelectedOptions = []
    if (field?.value && Array.isArray(field?.value)) {
      field?.value?.forEach((val) => {
        const option = currentOptions.find((opt) => {
          return opt?.value?.istKKLeistung
          ? isEqual(opt?.value?.positionsnummer, val?.positionsnummer)
          : isEqual(opt?.value, val)
        }
        )

        if (option) {
          newSelectedOptions.push(option)
        }
      })
    }

    setSelectedOptions(newSelectedOptions)
  }, [field?.value, currentOptions])

  return (
    <Autocomplete
      {...field}
      disabled={disabled}
      fullWidth={fullWidth}
      multiple
      options={currentOptions}
      getOptionLabel={(option) => option?.label}
      getOptionSelected={(option, value) => isEqual(option?.value, value?.value)}
      getOptionDisabled={(option) => {
        if (!!allowedValues?.length && option?.value?.positionsnummer) {
          return !allowedValues.includes(option.value.positionsnummer)
        }

        return !!option?.unselectable
      }}
      className={className}
      ChipProps={{ size: 'small' }}
      value={selectedOptions}
      onChange={(_, selectedValues) => {
        const values = (selectedValues ?? []).map((val) => val?.value)
        field?.onChange(values)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          label={rules?.required ? `${label} *` : label}
          error={!!fieldState?.error}
          helperText={helperText ?? fieldState?.error?.message}
          className={inputClassName}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            autoComplete,
          }}
        />
      )}
    />
  )
}

