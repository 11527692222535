import styled from 'styled-components';

export const StyledTerminAbrechnenForm = styled.form`
  color: #666;
  
  .disabled{
    color: #666;
  }
  .positionenRow{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .summe{
    display: block;
    border-bottom: 4px double #666;
  }
`;
