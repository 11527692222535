import { Button } from '@material-ui/core'
import BufferModule from 'buffer'
import { addDays, format } from 'date-fns'
import fetch from 'isomorphic-fetch'
import { Navigate } from 'react-router-dom'
import { UPDATE_USER_SUCCESS } from '../../constants/actionTypes'
import { ISO8601DATETIME_FNS } from '../../constants/dateFormats'
import { apiServerHashSelector } from '../../selectors/selectors'
import { MessageError, MessageSuccess } from '../../shared/components/Message'
import { CHANGE_PIN_CHANGE_PIN_SUCCESS } from '../../shared/redux/modules/pin'
import makeActionCreator from '../../shared/redux/utils/makeActionCreator'
import { fetchSecure, sCredentials } from '../../shared/utils/auth'
import { trackError } from '../../shared/utils/trackjs'
import { sCurrentUser } from '../../shared/utils/users'
import { getApiUrl, getAuthUrl } from '../../utils/auth'
import { authenticateLocally, WebAuth } from '../../utils/auth0'
import { lockScreen } from './lockscreen'
import { datesToStrings } from '../../shared/utils/dates'

const Buffer = BufferModule.Buffer

export const AUTH0_LOGIN = 'pod001_frontend/auth/AUTH0_LOGIN'

export const ADD = 'POD001_management_frontend/instances/ADD'
export const ADD_SUCCESS = 'POD001_management_frontend/instances/ADD_SUCCESS'
export const ADD_FAILURE = 'POD001_management_frontend/instances/ADD_FAILURE'
export const ASSIGN_USER = 'POD001_management_frontend/instances/ASSIGN_USER'
export const ASSIGN_USER_SUCCESS = 'POD001_management_frontend/instances/ASSIGN_USER_SUCCESS'
export const ASSIGN_USER_FAILURE = 'POD001_management_frontend/instances/ASSIGN_USER_FAILURE'
export const ASSIGN_NEW_USER = 'POD001_management_frontend/instances/ASSIGN_NEW_USER'
export const ASSIGN_NEW_USER_SUCCESS = 'POD001_management_frontend/instances/ASSIGN_NEW_USER_SUCCESS'
export const ASSIGN_NEW_USER_FAILURE = 'POD001_management_frontend/instances/ASSIGN_NEW_USER_FAILURE'

export const PERSIST_AUTH = 'pod001_frontend/auth/PERSIST_AUTH'
export const RESTORE_AUTH = 'pod001_frontend/auth/RESTORE_AUTH'
export const CLEAR_AUTH = 'pod001_frontend/auth/CLEAR_AUTH'
export const REPLACE_AUTH = 'pod001_frontend/auth/REPLACE_AUTH'

export const REGISTER_USER = 'pod001_frontend_startbereich/auth/REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'pod001_frontend_startbereich/auth/REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILURE = 'pod001_frontend_startbereich/auth/REGISTER_USER_FAILURE'

export const DEV_ACQUIRE_AUTHENTICATION = 'pod001_frontend/auth/DEV_ACQUIRE_AUTHENTICATION'
export const DEV_ACQUIRE_AUTHENTICATION_SUCCESS = 'pod001_frontend/auth/DEV_ACQUIRE_AUTHENTICATION_SUCCESS'
export const DEV_ACQUIRE_AUTHENTICATION_FAILURE = 'pod001_frontend/auth/DEV_ACQUIRE_AUTHENTICATION_FAILURE'

export const LEGACY_ACQUIRE_AUTHENTICATION = 'pod001_frontend/auth/LEGACY_ACQUIRE_AUTHENTICATION'
export const LEGACY_ACQUIRE_AUTHENTICATION_SUCCESS = 'pod001_frontend/auth/LEGACY_ACQUIRE_AUTHENTICATION_SUCCESS'
export const LEGACY_ACQUIRE_AUTHENTICATION_FAILURE = 'pod001_frontend/auth/LEGACY_ACQUIRE_AUTHENTICATION_FAILURE'

export const ACQUIRE_PIN_AUTHENTICATION = 'pod001_frontend/auth/ACQUIRE_PIN_AUTHENTICATION'
export const ACQUIRE_PIN_AUTHENTICATION_SUCCESS = 'pod001_frontend/auth/ACQUIRE_PIN_AUTHENTICATION_SUCCESS'
export const ACQUIRE_PIN_AUTHENTICATION_FAILURE = 'pod001_frontend/auth/ACQUIRE_PIN_AUTHENTICATION_FAILURE'

export const REMOVE_AUTHENTICATION = 'pod001_frontend/auth/REMOVE_AUTHENTICATION'

export const LOAD_API_SERVERS = 'pod001_frontend/auth/LOAD_API_SERVERS'
export const LOAD_API_SERVERS_SUCCESS = 'pod001_frontend/auth/LOAD_API_SERVERS_SUCCESS'
export const LOAD_API_SERVERS_FAILURE = 'pod001_frontend/auth/LOAD_API_SERVERS_FAILURE'

export const CURRENT_USER_AUTH = 'pod001_frontend/auth/CURRENT_USER_AUTH'
export const CURRENT_USER_AUTH_SUCCESS = 'pod001_frontend/auth/CURRENT_USER_AUTH_SUCCESS'
export const CURRENT_USER_AUTH_FAILURE = 'pod001_frontend/auth/CURRENT_USER_AUTH_FAILURE'

export const SELECT_API_SERVER_FROM_URL = 'pod001_frontend/auth/SELECT_API_SERVER_FROM_URL'

export const DOWNGRADE_CREDENTIALS = 'pod001_frontend/auth/DOWNGRADE_CREDENTIALS'
export const DOWNGRADE_CREDENTIALS_SUCCESS = 'pod001_frontend/auth/DOWNGRADE_CREDENTIALS_SUCCESS'
export const DOWNGRADE_CREDENTIALS_FAILURE = 'pod001_frontend/auth/DOWNGRADE_CREDENTIALS_FAILURE'

export const UPGRADE_CREDENTIALS = 'pod001_frontend/auth/UPGRADE_CREDENTIALS'
export const UPGRADE_CREDENTIALS_SUCCESS = 'pod001_frontend/auth/UPGRADE_CREDENTIALS_SUCCESS'
export const UPGRADE_CREDENTIALS_FAILURE = 'pod001_frontend/auth/UPGRADE_CREDENTIALS_FAILURE'

const { localStorage } = window

const initalState = {
  apiServers: [],
  selectedApiServer: null,
  credentials: null,
  pinCredentials: {},
  currentUserId: undefined,
  registerSuccessful: undefined,
  users: {},
  auth0: {
    accessToken: undefined,
    idToken: undefined,
    expiresAt: undefined,
  },
}

export default function auth(state = initalState, action = undefined) {
  const { type, json } = action
  switch (type) {
    case AUTH0_LOGIN:
      return {
        ...state,
        auth0: action.auth0,
      }

    case CURRENT_USER_AUTH_SUCCESS:
      return {
        ...state,
        apiServers: json.apiServers,
        currentUserId: json.user.authUserId,
        users: {
          [json.user.authUserId]: json.user,
        },
      }

    case RESTORE_AUTH:
      return {
        ...action.auth,
        pinCredentials: {
          ...state.pinCredentials,
          ...action.auth.pinCredentials,
        },
        users: {
          ...state.users,
          ...action.auth.users,
        },
        selectedApiServer: state.selectedApiServer,
      }

    case DEV_ACQUIRE_AUTHENTICATION_SUCCESS:
      return {
        ...initalState,
        apiServers: json.apiServers,
        credentials: json.credentials,
        users: {
          ...state.users,
          [json.user.authUserId]: json.user,
        },
      }

    case UPGRADE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        apiServers: json.apiServers,
        credentials: json.credentials,
        users: {
          ...state.users,
          [json.user.authUserId]: json.user,
        },
      }

    case ACQUIRE_PIN_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        apiServers: json.apiServers,
        pinCredentials: {
          ...state.pinCredentials,
          ...json.pinCredentials,
        },
        credentials: null,
        users: {
          ...state.users,
          [json.user.authUserId]: json.user,
        },
      }

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerSuccessful: REGISTER_USER_SUCCESS,
      }

    case REGISTER_USER_FAILURE:
      return {
        ...state,
        registerSuccessful: REGISTER_USER_FAILURE,
      }

    case REMOVE_AUTHENTICATION:
      logoutUser(action.noRedirectBack)
      return {
        ...state,
      }

    case SELECT_API_SERVER_FROM_URL:
      const selectFromUrlState = { ...state, selectedApiServer: action.selectedApiServer }
      return selectFromUrlState

    case CHANGE_PIN_CHANGE_PIN_SUCCESS:
    case UPDATE_USER_SUCCESS:
      const { user } = action.json
      return user
        ? {
            ...state,
            users: {
              ...state.users,
              [user.authUserId]: user,
            },
          }
        : state

    case DOWNGRADE_CREDENTIALS_SUCCESS:
      return state

    default:
  }
  return state
}

export const clear = () => {
  localStorage.auth = JSON.stringify(initalState)

  return {
    type: CLEAR_AUTH,
  }
}

export function persist({ auth }) {
  const doPersist = (auth, lsAuth = {}) => {
    const { pinCredentials = {}, users = {} } = lsAuth

    const newAuth = {
      ...auth,
      pinCredentials: {
        ...pinCredentials,
        ...auth.pinCredentials,
      },
      users: {
        ...users,
        ...auth.users,
      },
    }

    localStorage.auth = JSON.stringify(newAuth)

    return {
      type: PERSIST_AUTH,
      auth: newAuth,
    }
  }

  try {
    const lsAuth = JSON.parse(localStorage.auth)
    return doPersist(auth, lsAuth)
  } catch (error) {
    if (__DEV__) console.error(error)
    return doPersist(auth)
  }
}

export const replace = ({ auth }) => {
  localStorage.auth = JSON.stringify(auth)

  return {
    type: REPLACE_AUTH,
    auth,
  }
}

export const restoreAuth = (authString) => (dispatch) => {
  try {
    const auth = authString && JSON.parse(authString)

    return dispatch({
      type: RESTORE_AUTH,
      auth: __DEV__ // temp test && __LOCAL_API__
        ? getDevAuth()
        : auth || initalState,
    })
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}

export function devLogin({ username, password }) {
  return {
    types: [DEV_ACQUIRE_AUTHENTICATION, DEV_ACQUIRE_AUTHENTICATION_SUCCESS, DEV_ACQUIRE_AUTHENTICATION_FAILURE],
    requiresAuth: false,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: async () => {
      /*
      const URL = `${getAuthUrl()}/public/login`

      if (loggedIn) {
        await dispatch(logout())
      }

      return await fetch(URL, {
        credentials: 'include',
        method: 'get',
        headers: {
          'X-Auth': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`
        }
      })
      */
    },
    payload: { username, password },
  }
}

export function registerUser(data) {
  console.log('register user data in callAPI: ', data)
  const { ...other } = data

  return {
    types: [REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE],
    requiresAuth: false,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl }) => {
      const URL = `${authUrl}/register`

      return fetch(URL, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(datesToStrings(other)),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    payload: { payload: data },
  }
}

// TODO this fails
export async function logoutUser(noRedirectBack) {
  console.log('logoutUser called')
  const localStorage = window.localStorage
  const accessToken = localStorage.getItem('access_token')
  if (noRedirectBack) {
    console.log('noRedirectBack')
    window.sessionStorage.setItem('login_redirect_path', '/startbereich')
  } else if (!window.sessionStorage.getItem('login_redirect_path')) {
    console.log('no login_redirect_path')
    // if multiple logouts were fired, respect the path from the first one
    window.sessionStorage.setItem('login_redirect_path', window.location.pathname)
  }
  console.log('login_redirect_path exists')
  localStorage.removeItem('access_token')
  localStorage.removeItem('expires_at')

  try {
    console.log('Auth0 logout called')
    await WebAuth.logout({
      returnTo: window.location.origin + '/login',
    })
  } catch (error) {
    console.log(error, 'Auth0 logout failed')
  }
}

export function legacyAuth({ username, password }) {
  return {
    types: [
      LEGACY_ACQUIRE_AUTHENTICATION,
      LEGACY_ACQUIRE_AUTHENTICATION_SUCCESS,
      LEGACY_ACQUIRE_AUTHENTICATION_FAILURE,
    ],
    requiresAuth: false,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: async ({ authUrl }) => {
      const URL = `${authUrl}/login`

      return await fetch(URL, {
        credentials: 'include',
        method: 'get',
        headers: {
          'X-Auth': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
        },
      })
    },
    payload: { username, password },
  }
}

export function addInstance(data) {
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAILURE],
    requiresAuth: true,
    authServer: true,
    callAPI: async ({ dispatch, authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/addInstance`
      try {
        const response = fetchSecure(
          URL,
          {
            credentials: 'include',
            method: 'post',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
            },
          },
          auth0Credentials,
        )
        await response
        await dispatch(currentUserAuth())
        return response
      } catch (e) {
        console.log(e)
      }
    },
  }
}

export function addUserToInstance(email, instanceId) {
  return {
    types: [ASSIGN_USER, ASSIGN_USER_SUCCESS, ASSIGN_USER_FAILURE],
    requiresAuth: true,
    authServer: true,
    callAPI: async ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/instanceAddUser`
      return fetchSecure(
        URL,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify({ email, instanceId }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
  }
}

export function addNewUser(email, instanceId) {
  return {
    types: [ASSIGN_NEW_USER, ASSIGN_NEW_USER_SUCCESS, ASSIGN_NEW_USER_FAILURE],
    requiresAuth: true,
    authServer: true,
    callAPI: async ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/new-email-webhook`
      return fetchSecure(
        URL,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify({ email, instanceId }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
  }
}

export function loadApiServers() {
  return {
    types: [LOAD_API_SERVERS, LOAD_API_SERVERS_SUCCESS, LOAD_API_SERVERS_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: async ({ getState, authUrl, apiUrl, auth0Credentials }) => {
      const URL = apiServerHashSelector(getState()) ? `${apiUrl}/apiServers` : `${authUrl}/apiServers`
      return await fetchSecure(
        URL,
        {
          credentials: 'include',
          method: 'get',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function currentUserAuth() {
  return {
    types: [CURRENT_USER_AUTH, CURRENT_USER_AUTH_SUCCESS, CURRENT_USER_AUTH_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: async ({ getState, authUrl, apiUrl, auth0Credentials }) => {
      // authenticate against api if possible - needed for pin login
      const URL = apiServerHashSelector(getState()) ? `${apiUrl}/users/auth` : `${authUrl}/users/auth`

      return await fetchSecure(
        URL,
        {
          credentials: 'include',
          method: 'get',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export const logout = makeActionCreator(REMOVE_AUTHENTICATION, 'noRedirectBack')
export const auth0Login = makeActionCreator(AUTH0_LOGIN, 'auth0')

export function selectApiServerFromUrl() {
  const apiServerNameHash = window.location.pathname.split('/')[1]
  return (dispatch, getState) => {
    const state = getState()
    const apiServers = state.auth.apiServers || []
    const apiServer = apiServers.find((apiServer) => apiServer.nameHash === apiServerNameHash)
    if (!apiServer) {
      // probably on startbereich and all is good
    } else {
      dispatch({ type: SELECT_API_SERVER_FROM_URL, selectedApiServer: apiServer.id })
    }
  }
}

export const authenticateUser = () => async (dispatch, getState) => {
  console.log('authenticateUser called')
  try {
    console.log('authenticateUser try block')
    const { lockedInstance, ...localAuth } = (await authenticateLocally((path) => <Navigate to={path} replace />)) || {}
    // this should be sync action by all rights, yet we must await ??
    await dispatch(auth0Login(localAuth))
    await dispatch(currentUserAuth())

    if ('__configure__global__feedback_widget__' in window) {
      window.__configure__global__feedback_widget__(sCurrentUser(getState()))
    }

    if (lockedInstance) {
      console.log('Locked instance exists')
      if (apiServerHashSelector(getState()) !== lockedInstance) {
        console.log('Locked instance does not match current instance')
        window.sessionStorage.setItem('login_redirect_path', window.location.pathname)
        return <Navigate to="/login" />
      }
      console.log('Locked instance matches current instance. Dispatching lockscreen.')
      dispatch(lockScreen())
    }
  } catch (e) {
    console.log('Caught during login')
    console.log(e)
    window.sessionStorage.setItem('login_redirect_path', window.location.pathname)
    window.localStorage.removeItem('access_token')
    return <Navigate to="/login" />
  }
}

export const pinLogin = ({ userId, pin }) => async (dispatch, getState) => {
  const state = getState()
  const auth0Credentials = sCredentials(state)
  const payload = { userId, pin }
  // use apiRouter endpoint if possible
  const serverHash = apiServerHashSelector(getState())
  const URL = serverHash ? `${getApiUrl(serverHash)}/pin-login` : `${getAuthUrl()}/pin-login`

  dispatch({
    type: ACQUIRE_PIN_AUTHENTICATION,
    payload,
  })

  try {
    const response = await fetchSecure(
      URL,
      {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({
          userId,
          pin,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      auth0Credentials,
    )

    if (response.status === 401) {
      dispatch({
        type: ACQUIRE_PIN_AUTHENTICATION_FAILURE,
        payload,
        error: {
          message: response.status,
        },
      })
      return
    }

    const json = await response.json()

    if (json.unauthorized) {
      dispatch({
        type: ACQUIRE_PIN_AUTHENTICATION_FAILURE,
        payload,
        error: {
          message: 'Unauthorized',
        },
      })
      return
    }

    if (!json.success) {
      dispatch({
        type: ACQUIRE_PIN_AUTHENTICATION_FAILURE,
        payload,
      })
      return
    }

    // force refresh and let the init process take care of the rest
    localStorage.setItem('access_token', json.auth.accessToken)
    localStorage.setItem('expires_at', json.auth.expiresAt)
    localStorage.removeItem('locked_instance')
    window.location.reload('false')
    // dispatch({
    //   type: ACQUIRE_PIN_AUTHENTICATION_SUCCESS,
    //   json,
    //   payload
    // })
  } catch (error) {
    console.log(error)
    trackError(error, {
      action: ACQUIRE_PIN_AUTHENTICATION,
      userId,
    })

    dispatch({
      type: ACQUIRE_PIN_AUTHENTICATION_FAILURE,
      payload,
      error,
    })
  }
}

export function downgradeCredentials() {
  return {
    types: [DOWNGRADE_CREDENTIALS, DOWNGRADE_CREDENTIALS_SUCCESS, DOWNGRADE_CREDENTIALS_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: async ({ dispatch, getState, auth0Credentials }) => {
      // shouldn't be called outside instance
      const serverHash = apiServerHashSelector(getState())
      const URL = `${getApiUrl(serverHash)}/downgrade-credentials`

      // TODO really should be get, clean up
      const response = await fetchSecure(
        URL,
        {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )

      if (response.status !== 200) {
        dispatch({
          type: DOWNGRADE_CREDENTIALS_FAILURE,
          error: {
            message: response.status,
          },
        })
        return
      }

      const json = await response.json()

      if (!json.auth) {
        dispatch({
          type: DOWNGRADE_CREDENTIALS_FAILURE,
        })
        return
      }

      localStorage.setItem('access_token', json.auth.accessToken)
      localStorage.setItem('expires_at', json.auth.expiresAt)
      localStorage.setItem('locked_instance', json.auth.serverHash)
      await dispatch(auth0Login(json.auth))
      return json
    },
    payload: {},
  }
}

export const upgradeCredentials = ({ username, password }) => async (dispatch, getState) => {
  const state = getState()
  const auth0Credentials = sCredentials(state)
  const currentUser = sCurrentUser(state)
  const payload = { username, password }
  const URL = `${getAuthUrl()}/private/upgrade-credentials`

  dispatch({
    type: UPGRADE_CREDENTIALS,
    payload,
  })

  try {
    const response = await fetchSecure(
      URL,
      {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({
          username,
          password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      auth0Credentials,
    )

    const json = await response.json()

    if (json.unauthorized) {
      dispatch({
        type: UPGRADE_CREDENTIALS_FAILURE,
        payload,
        error: {
          message: 'Unauthorized',
        },
      })
      return json
    }

    if (!json.success) {
      dispatch({
        type: UPGRADE_CREDENTIALS_FAILURE,
        payload,
      })
      return
    }

    dispatch({
      type: UPGRADE_CREDENTIALS_SUCCESS,
      json,
      payload,
    })
    return json
  } catch (error) {
    console.log(error)
    trackError(error, {
      action: UPGRADE_CREDENTIALS,
      username: currentUser.email,
    })

    dispatch({
      type: UPGRADE_CREDENTIALS_FAILURE,
      payload,
      error,
    })
  }
}

const HelpButton = <Button color="primary" onClick={() => window['fcWidget']?.open()}>Hilfe</Button>;

export const notifications = {
  [ADD]: { message: 'Neue Praxis wird erstellt...', busy: true, error: false },
  [ADD_SUCCESS]: {
    message: (
      <MessageSuccess message = 'Neue Praxis erfolgreich erstellt.'/>
    ),
    busy: false,
    error: false,
  },
  [ADD_FAILURE]: {
    message: (
      <MessageError message='Anlegen der neuen Praxis fehlgeschlagen!' />
    ),
    busy: false,
    error: true,
    action: HelpButton,
  },
  [ASSIGN_USER]: {
    message: 'Neuer Benutzer wird gespeichert...',
    busy: false,
    error: false,
  },
  [ASSIGN_USER_SUCCESS]: {
    message: (
      <MessageSuccess message = 'Mitarbeiter erfolgreich hinzugefügt.'/>
    ),
    busy: false,
    error: false,
  },
  [ASSIGN_USER_FAILURE]: {
    message: (
      <MessageError message='Anlegen des neuen Benutzers fehlgeschlagen!' />
    ),
    busy: false,
    error: true,
    action: HelpButton,
  },
  [ASSIGN_NEW_USER]: {
    message: 'Neuer Benutzer wird gespeichert...',
    busy: false,
    error: false,
  },
  [ASSIGN_NEW_USER_SUCCESS]: {
    message: (
      <MessageSuccess message = 'Einladung zur Registrierung gesendet.'/>
    ),
    busy: false,
    error: false,
    action: (
      <Button
        onClick={() =>
          (window.location =
            'https://pododesk.freshdesk.com/support/solutions/articles/17000086779-mitarbeiter-zu-einer-praxis-hinzuf%C3%BCgen')
        }
      >
        Und jetzt?
      </Button>
    ),
  },
  [ASSIGN_NEW_USER_FAILURE]: {
    message: (
      <MessageError message='Anlegen des neuen Benutzers fehlgeschlagen!' />
    ),
    busy: false,
    error: true,
    action: HelpButton,
  },
  [UPGRADE_CREDENTIALS]: { message: 'login...', busy: true, error: false },
  [UPGRADE_CREDENTIALS_SUCCESS]: {
    message: (
      <MessageSuccess message = 'Login erfolgreich!'/>
    ),
    busy: false,
    error: false,
  },
  [UPGRADE_CREDENTIALS_FAILURE]: (state, action) => {
    switch (action.error.message) {
      case 'Unauthorized':
        return {
          message: (
            <MessageError message='Passwort ungültig: Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      case 'Internal Server Error':
        return {
          message: (
            <MessageError message='Server-Fehler: Bitte versuchen Sie es in einigen Minuten erneut!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      case 'Failed to fetch':
        return {
          message: (
            <MessageError message='Verbindungsproblem: Keine/schlechte Internetverbindung!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      default:
        return {
          message: (
            <MessageError message='Unbekannter Fehler: Bitte wiederholen Sie den Vorgang! (801)' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
    }
  },
  [ACQUIRE_PIN_AUTHENTICATION]: { message: 'login...', busy: true, error: false },
  [ACQUIRE_PIN_AUTHENTICATION_SUCCESS]: {
    message: (
      <MessageSuccess message = 'PIN-Anmeldung erfolgreich.'/>
    ),
    busy: false,
    error: false,
  },
  [ACQUIRE_PIN_AUTHENTICATION_FAILURE]: (state, action) => {
    if (!action.error) {
      return {
        message: (
          <MessageError message='Fehler bei der PIN-Verifikation! (818)' />
        ),
        busy: false,
        error: true,
        action: HelpButton,
      }
    }
    switch (action.error.message) {
      case 'Unauthorized':
        return {
          message: (
            <MessageError message='Die eingegebene PIN ist ungültig. Bitte überprüfen Sie diese und versuchen es erneut!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      case 'Internal Server Error':
        return {
          message: (
            <MessageError message='Server-Fehler: Bitte versuchen Sie es in einigen Minuten erneut!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      case 'Failed to fetch':
        return {
          message: (
            <MessageError message='Verbindungsproblem: Keine/schlechte Internetverbindung!' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
      default:
        return {
          message: (
            <MessageError message='Fehler bei der PIN-Verifikation! (852)' />
          ),
          busy: false,
          error: true,
          action: HelpButton,
        }
    }
  },
}

function getDevAuth() {
  const issued = new Date()
  const expires = addDays(issued, 7)
  return {
    apiServers: [
      {
        id: 66,
        name: 'evil_euclid',
        nameHash: '6477b0d3',
        description: "Marion's Praxis für Podologie",
        praxisInhaber: 'Marion',
      },
      {
        id: 67,
        name: 'stupefied_fermi',
        nameHash: '6691efe4',
        description: "Bernd's Praxis für Podologie",
        praxisInhaber: 'Bernd',
      },
    ],
    selectedApiServer: 66,
    pinCredentials: null,
    credentials: {
      id: '53882a56587d',
      key: 'd501d92a6c46deb8290794353d3b7ebba1a50262',
      algorithm: 'sha256',
      issued: format(new Date(), ISO8601DATETIME_FNS),
      expires: format(expires, ISO8601DATETIME_FNS),
      authUserId: 55,
      vorname: 'Marion',
      nachname: 'Musterfrau',
      email: 'marion@podo.desk',
      strasse: 'Musterstraße',
      hausnummer: '22',
      postleitzahl: '97896',
      ort: 'Freudenberg',
      telefon: '0336 3434343',
      mobil: '0336 3434343',
      praxisbezeichnung: null,
      funktionInPraxis: null,
      geschlecht: 'female',
      wieVonPododeskErfahren: null,
      agbAngenommen: true,
      datenschutzbedingungenGelesen: true,
      avatar: null,
      avatarFallback:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2IiB2aWV3Qm94PSIwIDAgMjU2IDI1NiIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+PHBhdGggZmlsbD0iI2QxNzVhNCIgZD0iTTEyOCAyMEwxMjggNzRMNzQgNzRaTTE4MiA3NEwxMjggNzRMMTI4IDIwWk0xMjggMjM2TDEyOCAxODJMMTgyIDE4MlpNNzQgMTgyTDEyOCAxODJMMTI4IDIzNlpNNzQgNzRMNzQgMTI4TDIwIDEyOFpNMjM2IDEyOEwxODIgMTI4TDE4MiA3NFpNMTgyIDE4MkwxODIgMTI4TDIzNiAxMjhaTTIwIDEyOEw3NCAxMjhMNzQgMTgyWk03NCA3NEwxMjggNzRMMTI4IDExMS44TDk1LjYgOTUuNkwxMTEuOCAxMjhMNzQgMTI4Wk0xODIgNzRMMTgyIDEyOEwxNDQuMiAxMjhMMTYwLjQgOTUuNkwxMjggMTExLjhMMTI4IDc0Wk0xODIgMTgyTDEyOCAxODJMMTI4IDE0NC4yTDE2MC40IDE2MC40TDE0NC4yIDEyOEwxODIgMTI4Wk03NCAxODJMNzQgMTI4TDExMS44IDEyOEw5NS42IDE2MC40TDEyOCAxNDQuMkwxMjggMTgyWiIvPjxwYXRoIGZpbGw9IiM0YzRjNGMiIGQ9Ik0yMCA0N0w0NyAyMEw3NCA0N0w0NyA3NFpNMjA5IDIwTDIzNiA0N0wyMDkgNzRMMTgyIDQ3Wk0yMzYgMjA5TDIwOSAyMzZMMTgyIDIwOUwyMDkgMTgyWk00NyAyMzZMMjAgMjA5TDQ3IDE4Mkw3NCAyMDlaIi8+PC9zdmc+',
      pinDefined: true,
    },
    users: {
      55: {
        vorname: 'Marion',
        nachname: 'Musterfrau',
        email: 'marion@podo.desk',
        strasse: 'Musterstraße',
        hausnummer: '22',
        postleitzahl: '97896',
        ort: 'Freudenberg',
        telefon: '0336 3434343',
        mobil: '0336 3434343',
        praxisbezeichnung: null,
        funktionInPraxis: null,
        geschlecht: 'female',
        wieVonPododeskErfahren: null,
        agbAngenommen: true,
        datenschutzbedingungenGelesen: true,
        avatar: null,
        avatarFallback:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2IiB2aWV3Qm94PSIwIDAgMjU2IDI1NiIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+PHBhdGggZmlsbD0iI2QxNzVhNCIgZD0iTTEyOCAyMEwxMjggNzRMNzQgNzRaTTE4MiA3NEwxMjggNzRMMTI4IDIwWk0xMjggMjM2TDEyOCAxODJMMTgyIDE4MlpNNzQgMTgyTDEyOCAxODJMMTI4IDIzNlpNNzQgNzRMNzQgMTI4TDIwIDEyOFpNMjM2IDEyOEwxODIgMTI4TDE4MiA3NFpNMTgyIDE4MkwxODIgMTI4TDIzNiAxMjhaTTIwIDEyOEw3NCAxMjhMNzQgMTgyWk03NCA3NEwxMjggNzRMMTI4IDExMS44TDk1LjYgOTUuNkwxMTEuOCAxMjhMNzQgMTI4Wk0xODIgNzRMMTgyIDEyOEwxNDQuMiAxMjhMMTYwLjQgOTUuNkwxMjggMTExLjhMMTI4IDc0Wk0xODIgMTgyTDEyOCAxODJMMTI4IDE0NC4yTDE2MC40IDE2MC40TDE0NC4yIDEyOEwxODIgMTI4Wk03NCAxODJMNzQgMTI4TDExMS44IDEyOEw5NS42IDE2MC40TDEyOCAxNDQuMkwxMjggMTgyWiIvPjxwYXRoIGZpbGw9IiM0YzRjNGMiIGQ9Ik0yMCA0N0w0NyAyMEw3NCA0N0w0NyA3NFpNMjA5IDIwTDIzNiA0N0wyMDkgNzRMMTgyIDQ3Wk0yMzYgMjA5TDIwOSAyMzZMMTgyIDIwOUwyMDkgMTgyWk00NyAyMzZMMjAgMjA5TDQ3IDE4Mkw3NCAyMDlaIi8+PC9zdmc+',
        pinDefined: true,
        authUserId: 55,
      },
    },
  }
}
