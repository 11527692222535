import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const StyledChangePasswordLoggedIn = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px;

    .changePasswordForm {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .emailHeading {
      margin: 0;
      font-weight: normal;
      color: ${theme.palette.secondary.main};
    }
  `}
`
