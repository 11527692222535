import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { RegisterOptions, useController } from 'react-hook-form'

interface Props {
  name: string
  rules?: RegisterOptions
  control: any
  className?: string
  style?: React.CSSProperties
  helperText?: string
  error?: boolean
  label?: string
  readOnly?: boolean
  labelPlacement?: 'start' | 'end'
  disabled?: boolean
  onChange?: any
  shouldUnregister?: boolean
}

const ControlledCheckboxField = ({
  name,
  rules,
  control,
  className,
  style,
  helperText,
  error,
  label,
  readOnly,
  labelPlacement = 'end',
  disabled,
  onChange,
  shouldUnregister = false,
}: Props): JSX.Element => {
  const { field } = useController({ name, rules, control, shouldUnregister })

  const handleChange = (_, checked: boolean): void => {
    if (readOnly) return

    if (onChange) {
      onChange(checked)
    }
    field.onChange(checked)
  }

  return (
    <FormControl className={className} style={style} error={error}>
      <FormControlLabel
        control={<Checkbox {...field} checked={field.value} onChange={handleChange} />}
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
        style={ readOnly ? { pointerEvents: 'none' } : {}}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default ControlledCheckboxField
