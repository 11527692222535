import styled from 'styled-components';

export const StyledLockscreenForm = styled.form`
  ${({ theme }) => `
    display: flex;
    z-index: 2;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;

    .card{
      padding: 20px;
      padding-bottom: 0;
      max-width: 950px;
      text-align: center;
      position: relative;
    }
    .header{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logo {
      align-self: flex-start;
      width: 202px;
      height: 34px;
    }
    .heading{
      margin-top: 20px;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
    }
    .cardText{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .usersContainer{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .user{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 10px 10px;
      cursor: pointer;
      color: rgb(102,102,102);
      line-height: 16px;
      min-height: 152px;
      width: 160px;
      &.active{
        background-color: rgba(102,102,102,0.2);
        cursor: default;
        color: white;
      }
    }
    .avatar{
      width: 90px;
      height: 90px;
      background-color: white;
      margin-bottom: 10px;
    }
    .text{
      overflow: hidden;
      width: 130px;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.email{
        margin-top: 3px;
        font-size: 12px;
      }
    }
    .pin{
      width: 100px;
    }
    .logoutButton{
      align-self: flex-start;
      margin: 15px;
    }
    .IconButton{
      width: 41px;
      height: 41px;
    }
  `}
`;
