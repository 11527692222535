import { ListItemIcon, MenuItem } from '@material-ui/core'
import {
  Settings as SettingsIcon
} from '@material-ui/icons'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledListItemText } from './StyledListItemText'

const MenuItemsIcsItem: FC<any> = ({
  item, onCloseFunc
}) => {
  const { serverHash } = useParams()
  const navigate = useNavigate()

  const gotoKalendereinstellungen = () => {
    onCloseFunc()
    navigate(`/${serverHash}/settings/praxisstammdaten`)
  }

  if (!item) {
    return null
  }

  return (<div>
    <MenuItem onClick={() => gotoKalendereinstellungen()}>
      <ListItemIcon>
        <SettingsIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="Kalendereinstellungen anzeigen" />
    </MenuItem>
  </div>)
};

export default MenuItemsIcsItem
