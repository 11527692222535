import * as types from '../constants/actionTypes'

export default function selectedPerson(state = 0, action = undefined) {
  switch (action.type) {
    case types.SELECT_PERSON:
      if (action.person) {
        return parseInt(action.person, 10)
      }
      break
    default:
  }

  return state
}
