/* eslint camelcase: 0 */
export default function krankenkassen(
  state = {
    1: { id: 1, kuerzel: 'private KK', name: 'private KK', preisvereinbarung: null },
    2: { id: 2, kuerzel: 'AOK', name: 'Allgemeine Ortskrankenkasse', preisvereinbarung: 7123203 },
    3: { id: 3, kuerzel: 'BKK', name: 'Betriebskrankenkasse', preisvereinbarung: 7190605 },
    4: { id: 4, kuerzel: 'VIACTIV', name: 'VIACTIV (BKK vor Ort)', preisvereinbarung: 7190605 },
    5: { id: 5, kuerzel: 'BKN', name: 'Bundesknappschaft', preisvereinbarung: 7124004 },
    6: { id: 6, kuerzel: 'IKK', name: 'Innungskrankenkasse', preisvereinbarung: 7123904 },
    7: { id: 7, kuerzel: 'BIG', name: 'BundesInnungskrankenkasse Gesundheit (IKK)', preisvereinbarung: 7123904 },
    8: {
      id: 8,
      kuerzel: 'LKK-SVLFG',
      name: 'Landwirtschaftliche Krankenkasse der Sozialversicherung für Landwirtschaft, Forsten und Gartenbau',
      preisvereinbarung: 7100000,
    },
    9: { id: 9, kuerzel: 'Barmer GEK', name: 'Barmer GEK (Vdek)', preisvereinbarung: 7124000 },
    10: { id: 10, kuerzel: 'DAK', name: 'DAK-Gesundheit (Vdek)', preisvereinbarung: 7124000 },
    11: { id: 11, kuerzel: 'HEK', name: 'Hanseatische Krankenkasse (Vdek)', preisvereinbarung: 7124000 },
    12: { id: 12, kuerzel: 'HKK', name: 'Handelskrankenkasse (Vdek)', preisvereinbarung: 7124000 },
    13: { id: 13, kuerzel: 'KKH', name: 'Kaufmännische Krankenkasse (Vdek)', preisvereinbarung: 7124000 },
    14: { id: 14, kuerzel: 'TKK', name: 'Techniker Krankenkasse (Vdek)', preisvereinbarung: 7124000 },
    15: { id: 15, kuerzel: 'PBeaKK', name: 'Postbeamtenkrankenkasse (Vdek)', preisvereinbarung: 7124000 },
    16: { id: 16, kuerzel: 'Sonstige', name: 'Sonstige Kostenträger', preisvereinbarung: null },
  },
) {
  return state
}
