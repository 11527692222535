import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { useEffect, useMemo } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'
import styled from 'styled-components'
import { theme } from '../../../assets/theme'

interface RadioGroupOption {
  value: string | number
  label: React.ReactNode
}

interface Props {
  name: string
  control: any
  options: RadioGroupOption[]

  onChange?: (value: any) => any
  error?: boolean
  helperText?: string
  label?: string
  rules?: RegisterOptions
  shouldUnregister?: boolean
  maxPerRow?: number
  disabled?: boolean
  readOnly?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${theme.breakpoints.down('lg')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const GroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`

export const ControlledRadioButtonGroup = ({
  name,
  control,
  options = [],
  label,
  error,
  helperText,
  maxPerRow = 5,
  onChange,

  rules = {},
  shouldUnregister = false,
  disabled = false,
  readOnly = false,
}: Props): JSX.Element => {
  const { field } = useController({ name, rules, control, shouldUnregister })

  useEffect(() => {
    if (!Array.isArray(options)) {
      console.warn("Property 'options' must be an array.")
    } else if (!options?.length) {
      console.warn('No options passed to SelectField. Please pass an array of options.')
    }
  }, [options])

  const handleChange = (_, value) => {
    if (readOnly) return

    field.onChange(value)

    if (onChange) {
      onChange(value)
    }
  }

  const numOfRows = useMemo(() => Math.ceil(options.length / maxPerRow), [options, maxPerRow])
  const rows = useMemo(() => Array.from(Array(numOfRows).keys()), [numOfRows])

  return (
    <FormControl disabled={disabled} error={error}>
      <Container>
        {label && <FormLabel>{rules?.required ? `${label} *` : label}</FormLabel>}
        <RadioGroup {...field} style={readOnly ? { pointerEvents: 'none' } : {}} onChange={handleChange}>
          {rows.map((row) => (
            <div style={{ display: 'grid', 'grid-template-columns': `repeat(${maxPerRow}, 1fr)` }}>
              {options.slice(row * maxPerRow, (row + 1) * maxPerRow).map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </div>
          ))}
        </RadioGroup>
      </Container>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
