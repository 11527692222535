import { createSelector } from 'reselect'
import { checkTerminConditions } from '../../utils/validation'

const sTerminOrganisatorischForm = (state) => state.form.terminOrganisatorischForm
const sTerminBeginn = createSelector(sTerminOrganisatorischForm, (form) =>
  form.beginn ? form.beginn.value : undefined,
)
const sTerminEnde = createSelector(sTerminOrganisatorischForm, (form) => (form.ende ? form.ende.value : undefined))
const sGridConfig = (state) => state.config.calendarGrid.default
const sTerminIsAllDay = createSelector(sTerminOrganisatorischForm, (form) =>
  form.isAllDay ? form.isAllDay.value : undefined,
)

export const sTerminConditions = createSelector(
  sTerminBeginn,
  sTerminEnde,
  sGridConfig,
  sTerminIsAllDay,
  (beginn, ende, gridConfig, isAllDay) => {
    return checkTerminConditions(beginn, ende, gridConfig, isAllDay)
  },
)
