import { FC, useState } from 'react'
import { connect } from 'react-redux'
import { ContextMenuTherapiebericht } from '../../components/MenuTherapiebericht'
import TableBody from '../../components/TableBody/TableBody'
import TableHeader from '../../components/TableHeader/TableHeader'
import TableRow from '../../components/TableRow/TableRow'

import { format } from 'date-fns'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'

import { sortBycreatedAtDescending } from '../../utils/therapieberichte'
import { StyledTherapyReportsListView } from './StyledTherapyReportsListView'

interface Props {
  actions?: any,
  customer?: any,
  doctors?: any,
  heilmittelverordnungen?: any,
  people?: any,
  therapieberichte?: any,
  therapieberichteIds?: number[],
  limit?: number,
}

interface State {
  showAll: boolean,
  selectedTherapiebericht: any,
  pointerLocation: {
    mouseX: null | number,
    mouseY: null | number
  }
}

const TherapyReportsListView: FC<Props> = ({
  actions, customer, doctors, heilmittelverordnungen,
  people, therapieberichte,therapieberichteIds = [], limit = 3,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [pointerLocation, setPointerLocation] = useState<any>({ mouseX: null, mouseY: null })
  const [selectedTherapiebericht, setSelectedTherapiebericht] = useState<any | null>(null)

  const _closeContextMenu = () => {
    setPointerLocation({
      mouseX: null,
      mouseY: null,
    });
    setSelectedTherapiebericht(null);
  }

  const _toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const _handleItemClick = (e, { id }) => {
    e.persist()
    const therapiebericht = therapieberichte[id]
    setSelectedTherapiebericht(therapiebericht);
    setPointerLocation({
      mouseX: e.clientX,
      mouseY: e.clientY,
    })
  }

  const tableColumns = [
    {
      label: 'Datum',
      className: 'columnHeader'
    },
    {
      label: 'HVO vom',
      className: 'columnHeader'
    },
    {
      label: 'Therapeut',
      className: 'columnHeader'
    },
    {
      label: 'Arzt',
      className: 'columnHeader'
    },
  ]

  const filteredTherapieberichteIds = therapieberichteIds.filter(
    (key) => therapieberichte[key] && therapieberichte[key].patient === customer.id,
  )
  let rowsRendered = 0

  return (
    <>
      <StyledTherapyReportsListView>
        <TableHeader cols={tableColumns} />
        <TableBody className="TableBody">
          {filteredTherapieberichteIds
            .map((id) => therapieberichte[id])
            .sort(sortBycreatedAtDescending)
            .map((therapyReport) => {
              rowsRendered++
              if (!showAll && rowsRendered > limit) {
                return null
              }

              return (
                <TableRow
                  onTdClick={_handleItemClick}
                  className='TableRow'
                  key={therapyReport.id}
                  id={therapyReport.id}
                  cols={[
                    {
                      data: format(therapyReport.createdAt, GERMAN_DATE_SHORT_YEAR_FNS),
                      className: 'columnContent',
                    },
                    {
                      data:
                        heilmittelverordnungen[therapyReport.heilmittelverordnung] &&
                        format(
                          heilmittelverordnungen[therapyReport.heilmittelverordnung].ausstellungsdatum,
                          GERMAN_DATE_SHORT_YEAR_FNS,
                        ),
                      className: 'columnContent',
                    },
                    {
                      data:
                        people[therapyReport.therapeut] && [
                          people[therapyReport.therapeut].vorname, people[therapyReport.therapeut].nachname
                        ].filter(Boolean).join(' '),
                      className: 'columnContent',
                    },
                    {
                      data:
                        doctors[therapyReport.arzt] && [
                          doctors[therapyReport.arzt].titel, doctors[therapyReport.arzt].nachname
                        ].filter(Boolean).join(' '),
                      className: 'columnContent',
                    },
                  ]}
                />
              )
            })}
          {filteredTherapieberichteIds.length == 0 && (
            <TableRow
              className="TableRow"
              cols={[
                {
                  data: 'bisher keine',
                  className: 'columnContent showAll empty',
                  colSpan: 4,
                },
              ]}
            />
          )}
          {filteredTherapieberichteIds.length > limit && (
            <TableRow
              className="TableRow"
              onTdClick={_toggleShowAll}
              cols={[
                {
                  data: showAll ? 'weniger anzeigen' : 'alle anzeigen',
                  className: 'columnContent showAll',
                  colSpan: 4,
                },
              ]}
            />
          )}
        </TableBody>
      </StyledTherapyReportsListView>
      <ContextMenuTherapiebericht
        therapiebericht = {selectedTherapiebericht}
        pointerLocation = {pointerLocation}
        handleClose= {_closeContextMenu}
      />
    </>
  )
};

const mapStateToProps = (state) => ({
  therapieberichteIds: state.ids.therapieberichte,
})

export default connect(mapStateToProps, null)(TherapyReportsListView)
