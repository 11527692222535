import { getAuthUrl, getApiUrl } from '../../utils/auth'
import { trackError } from '../../shared/utils/trackjs'
import { fetchSecure, sCredentials } from '../../shared/utils/auth'
import { apiServerHashSelector } from '../../selectors/selectors'

const VALIDATE_EMAIL = 'pod001_frontend/auth/VALIDATE_EMAIL'
const VALIDATE_EMAIL_SUCCESS = 'pod001_frontend/auth/VALIDATE_EMAIL_SUCCESS'
const VALIDATE_EMAIL_FAILURE = 'pod001_frontend/auth/VALIDATE_EMAIL_FAILURE'

export const validate = (email) => async (dispatch, getState) => {
  const state = getState()
  const auth0Credentials = sCredentials(state)
  const URL = apiServerHashSelector(state)
    ? `${getApiUrl(apiServerHashSelector(state))}/mailgun/validate`
    : `${getAuthUrl()}/mailgun/validate`

  dispatch({
    type: VALIDATE_EMAIL,
  })

  try {
    const response = await fetchSecure(
      URL,
      {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({
          email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      auth0Credentials,
    )

    if (response.status !== 200) {
      const error = new Error({
        status: response.status,
        statusText: response.statusText,
      })
      dispatch({
        type: VALIDATE_EMAIL_FAILURE,
        error,
      })
      throw error
    }

    const json = await response.json()

    dispatch({
      type: VALIDATE_EMAIL_SUCCESS,
    })

    return json
  } catch (error) {
    console.log(error)
    trackError(error, {
      action: VALIDATE_EMAIL_FAILURE,
    })

    dispatch({
      type: VALIDATE_EMAIL_FAILURE,
      error,
    })
    throw error
  }
}
