import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FC, useRef } from 'react'
import { MessageError, MessageSuccess } from '../../shared/components/Message'
import absagegruende from './absagegruende'
import TerminAbsageForm from './TerminAbsageForm'

const terminAbsageNotifications = {
  request: 'Terminabsage wird gespeichert...',
  success: (
    <MessageSuccess message={'Terminabsage erfolgreich gespeichert.'} />
  ),
  failure: (
    <MessageError message={'Speichern der Terminabsage fehlgeschlagen!'} />
  ),
}

interface Props {
  termin?: any,
  open?: boolean,
  patienten?: any,
  closeDialog?: () => void,
  terminActions?: any,
  patientenActions?: any,
}

const TerminAbsageDialog: FC<Props> = ({
  termin,
  open = false,
  patienten,
  closeDialog = () => {},
  terminActions,
  patientenActions
}) => {

  const terminAbsageForm = useRef<any>()

  const _handleTerminAbsage = async (data) => {
    let findAbsagegruende = absagegruende.find((reason) => reason.id === data.absagegrund);
    const absagegrundStr = findAbsagegruende ? findAbsagegruende.text: null;
    const terminPatient = patienten[data.termin.patient]

    closeDialog()

    switch (data.absagegrund) {
      // 'Neuvereinbarung folgt' / 'Sonstiges'
      case 1:
      case 4:
        await terminActions.updateDate(
          {
            ...data.termin,
            absagedatum: data.absagedatum,
            absagegrund: absagegrundStr,
            notizen: [data.termin.notizen, data.notizen].join('\n'),
          },
          terminAbsageNotifications,
        )
        return

      // 'Praxiswechsel'
      case 2:
        await patientenActions.updateCustomer(
          {
            ...terminPatient,
            abgewandertZu: data.abgewandertZu,
          },
          {
            request: 'Patient wird als abgewandert markiert...',
            success: (
              <MessageSuccess message={'Patient erfolgreich als abgewandert markiert. Alle zukünftigen Termine wurden abgesagt.'} />
            ),
            failure: (
              <MessageError message={'Patient als abgewandert markieren fehlgeschlagen!'} />
            ),
          },
        )
        await terminActions.updateDate(
          {
            ...data.termin,
            absagedatum: data.absagedatum,
            absagegrund: absagegrundStr,
          },
          {
            request: 'Patient wird als abgewandert markiert...',
            success: (
              <MessageSuccess message={'Patient erfolgreich als abgewandert markiert. Alle zukünftigen Termine wurden abgesagt.'} />
            ),
            failure: (
              <MessageError message={'Patient als abgewandert markieren fehlgeschlagen!'} />
            ),
          },
        )
        return

      // 'Patient verstorben'
      case 3:
        await terminActions.cancelDateDeceased(
          {
            ...data.termin,
            patient: terminPatient.id,
            absagegrund: 'Patient verstorben',
          },
          {
            request: 'Patient wird als verstorben markiert...',
            success: (
              <MessageSuccess message={'Patient erfolgreich als verstorben markiert. Alle zukünftigen Termine wurden abgesagt.'} />
            ),
            failure: (
              <MessageError message={'Patient als verstorben markieren fehlgeschlagen!'} />
            )
          },
        )
        return

      default:
    }
  }
  return (
    <Dialog onClose={closeDialog} open={open} disableBackdropClick>
        <DialogTitle>Termin absagen</DialogTitle>
        <DialogContent>
          {termin && termin.patient && (
            <TerminAbsageForm
              ref={terminAbsageForm}
              initialValues={{
                absagegrund: null,
                absagedatum: new Date(),
                abgewandertZu: null,
                termin: termin,
              }}
              onSubmit={_handleTerminAbsage}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeDialog}>
            Abbrechen
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => terminAbsageForm?.current?.submit()}>
            Termin absagen
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default TerminAbsageDialog
