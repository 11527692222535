import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

interface Props {
  onChange?: (value: boolean) => void,
  label?: string | React.ReactNode,
  style?: object,
  disabled?: boolean,
  checked?: boolean,
  value?: boolean,
  className?: string,
  readOnly?: boolean,
  error?: boolean,
  helperText?: string,
  reversedOrder?: boolean,
}

const CheckboxField: FC<Props> = ({
  onChange,
  label,
  style,
  disabled,
  checked,
  value,
  className,
  readOnly,
  error,
  helperText,
  reversedOrder = false,
}) => {
  const [state, setState] = useState<boolean>(false)

  useEffect(() => {
    const newValue = value || checked || false
    setState(newValue)
  }, [value, checked])

  const handleStateChange = () => {
    const newValue = !Boolean(state)
    setState(newValue)
    onChange && onChange(newValue)
  }

  return (<div 
    className={className}
    style={style}>
    <FormControlLabel
      control={<Checkbox readOnly={readOnly}/>}
      onChange={!readOnly 
        ? () => handleStateChange()
        : undefined
      }
      label={label}
      disabled={disabled}
      checked={state == true}
      labelPlacement={reversedOrder ? "start" : "end"}
    />
    {helperText 
      ? <FormHelperText error={error}>{helperText}</FormHelperText> 
      : null
    }
  </div>)
};

export default CheckboxField
