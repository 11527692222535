import { FC } from 'react'

interface Props {
  label: string
  className?: string
  pullLeft?: boolean
}

const FieldContainer: FC<Props> = ({ label, children, className, pullLeft = false }) => {
  return (
    <div className="fieldRow">
      <label className="label">{label}</label>
      <div className={`${className || 'fieldContainer'} ${pullLeft ? 'fieldContainerPullLeft' : ''}`}>{children}</div>
    </div>
  )
}

export default FieldContainer
