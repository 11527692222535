/*
 * Which API Entities is currently beeing written to?
 * */
import * as types from '../constants/actionTypes'

export default function updating(
  state = {
    abbreviations: false,
    aerzte: false,
    anamnesen: false,
    dateien: false,
    dokumentationen: false,
    leistungenPrivat: false,
    heilmittelverordnungen: false,
    patienten: false,
    praxisstammdaten: false,
    termine: false,
    therapieberichte: false,
    users: false,
    rooms: false,
    equipment: false,
  },
  action = undefined,
) {
  switch (action.type) {
    case types.LOAD_CUSTOMER:
    case types.LOAD_CUSTOMERS:
    case types.LOAD_CUSTOMERS_ALPHABETICALLY:
    case types.FIND_CUSTOMERS:
      return {
        ...state,
        patienten: true,
      }
    case types.LOAD_CUSTOMER_SUCCESS:
      return {
        ...state,
        patienten: false,
      }
    case types.LOAD_CUSTOMER_FAILURE:
    case types.LOAD_CUSTOMERS_SUCCESS:
    case types.LOAD_CUSTOMERS_FAILURE:
    case types.LOAD_CUSTOMERS_ALPHABETICALLY_SUCCESS:
    case types.LOAD_CUSTOMERS_ALPHABETICALLY_FAILURE:
    case types.FIND_CUSTOMERS_SUCCESS:
    case types.FIND_CUSTOMERS_FAILURE:
      return {
        ...state,
        patienten: false,
      }

    case types.LOAD_DOCTORS:
      return {
        ...state,
        aerzte: true,
      }
    case types.LOAD_DOCTORS_SUCCESS:
    case types.LOAD_DOCTORS_FAILURE:
      return {
        ...state,
        aerzte: false,
      }
    case types.LOAD_DATES:
      return {
        ...state,
        dateien: true,
      }
    case types.LOAD_DATES_FAILURE:
    case types.LOAD_DATES_SUCCESS: {
        return {
          ...state,
          dateien: false,
        }
      }
    case types.LOAD_ANAMNESIS: { 
        return {
          ...state,
          anamnesen: true,
        }
      }
    case types.LOAD_ANAMNESIS_SUCCESS: 
    case types.LOAD_ANAMNESIS_FAILURE: { 
      return {
        ...state,
        anamnesen: false,
      }
    }
    case types.LOAD_ROOMS: {
      return {
        ...state,
        rooms: true,
      }
    }
    case types.LOAD_ROOMS_SUCCESS:
    case types.LOAD_ROOMS_FAILURE: {
      return {
        ...state,
        rooms: false,
      }
    }

    case types.LOAD_ABBREVIATIONS: {
      return {
        ...state,
        abbreviations: true,
      }
    }

    case types.LOAD_ABBREVIATIONS_SUCCESS:
    case types.LOAD_ABBREVIATIONS_FAILURE: {
      return {
        ...state,
        abbreviations: false,
      }
    }

    case types.LOAD_EQUIPMENT: {
      return {
        ...state,
        equipment: true,
      }
    }
    case types.LOAD_EQUIPMENT_SUCCESS:
    case types.LOAD_EQUIPMENT_FAILURE: {
      return {
        ...state,
        equipment: false,
      }
    }

    default:
  }

  return state
}
