/**
 Will be used as wrapper for MUI TextField
 */
import { TextField } from '@material-ui/core';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

interface Props {
  className?: string,
  onChange?: (value: string | number | null) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  onKeyDown?: (e: any) => void,
  onKeyUp?: (e: any) => void,
  onClick?: (e: any) => void,

  type?: 'number' | 'string',
  value?: string | number | null,
  fullWidth?: boolean,
  label?: string | JSX.Element,
  placeholder?: string,

  error?: boolean,
  helperText?: string | JSX.Element,
  redHelperText?: string | JSX.Element,
  disabled?: boolean,
  InputProps?: any,
  inputProps?: any,
  readOnly?: boolean,
  autoComplete?: string,

  variant?: "standard" | "filled" | "outlined" | undefined,
  autoFocus?: boolean,
  multiline?: boolean,
  rows?: number | "",

  textExpand?: boolean
  abbreviations: Record<string, string>
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({
    className = '',
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    onClick,
    type = 'string',
    value = null,
    fullWidth = true,
    label = '',
    placeholder = '',

    error,
    helperText,
    redHelperText,
    disabled,
    InputProps = {},
    inputProps = {},
    readOnly = false,

    variant = "standard",
    autoComplete = '',
    autoFocus,
    multiline = false,
    rows = 1,

    textExpand = false,
    abbreviations = {},

    style,
  }, ref) => {
    const [focused, setFocused] = useState<boolean>(false)
    const [stateValue, setStateValue] = useState<string | number | null>(value)

    const getNumber = (value) => {
      const parsedNumber = Number(value)
      let newValue = isNaN(parsedNumber) ? "" : parsedNumber
      newValue = String(newValue !== "" ? newValue : "")
      return newValue;
    }

    useEffect(() => {
      let newValue: string | number | null = value
      if(value === '' || value == null) {
        newValue = ""
      }
      if(type === 'number' && newValue !== "") {
        newValue = getNumber(newValue)
      }
      setStateValue(newValue)
    }, [value])

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

      e?.persist();
      let newValue: string | number | null = e.target.value

      if(newValue === '' || newValue == null) {
        newValue = ""
      }
      if(type === 'number' && newValue !== "") {
        newValue = getNumber(newValue)
      }
      if(textExpand && abbreviations && newValue !== '') {
        console.log('--- START TEXTEXPAND ---')
        const lines = newValue.split('\n')
        console.log('lines: ', lines)
        const lastLine = lines[lines.length - 1]
        console.log('lastLine: ', lastLine)
        const splitLastLine = lastLine.split(' ')
        console.log('splitLastLine: ', splitLastLine)
        const lastValue = splitLastLine[splitLastLine.length - 1]
        console.log('lastValue: ', lastValue)

        console.log('abbreviations[lastValue]: ', abbreviations[lastValue])
        if (abbreviations[lastValue]) {
          newValue = newValue.replace(RegExp(`${lastValue}$`, 'gm'), abbreviations[lastValue])
        }

        console.log('newValue: ', newValue)

        console.log('--- END TEXTEXPAND ---')
      }
      setStateValue(newValue)
      onChange && onChange(newValue)
    }

    const handleOnBlur = (e) => {
      e?.persist();
      setFocused(false)
      let newValue: string | number | null = stateValue;
      if(type === 'number') {
        newValue = getNumber(newValue) || ""
      }
      setStateValue(newValue)
      onBlur && onBlur(newValue)
    }

    const handleOnFocus = (e) => {
      e?.persist();
      setFocused(true);
      onFocus && onFocus(e);
    }

    return (
      <TextField
        inputRef={ref}
        className = {className}
        onClick={onClick}
        onChange = {!readOnly
          ? handleChange
          : undefined
        }
        onBlur = {!readOnly
          ? handleOnBlur
          : undefined
        }
        onFocus = {handleOnFocus}
        onKeyDown = {onKeyDown}
        onKeyUp = {onKeyUp}

        type = {type}
        value = {stateValue ?? ''}
        fullWidth = {fullWidth}
        label = {label}
        placeholder = {placeholder}

        error = {error || !!redHelperText}
        helperText = {!!helperText ? helperText : !!redHelperText ? redHelperText : ''}
        disabled = {disabled}

        InputProps = {{
          ...InputProps,
          autoComplete,
          readOnly
        }}
        inputProps = {inputProps}

        variant = {variant}
        autoFocus = {autoFocus}
        multiline = {multiline}
        rows = {rows}
        style={style}


        InputLabelProps={{ shrink: (stateValue != null && stateValue !== '') || focused }}
      />
    )
})

InputField.displayName = 'ControlledInputField'

export default InputField;
