import { Card } from '@material-ui/core'
import styled from 'styled-components'

export const StyledUserCard = styled(Card)`
  ${({ theme }) => `
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.4);
    font-size: x-large;
    color: ${theme.palette.secondary.contrastText};

    .content {
      padding: 20px 20px 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .actions {
      margin-top: auto;
      margin-left: 10px;
    }

    .button {
      color: ${theme.palette.secondary.contrastText};
    }
  `}
  `
