import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ChooseCustomerForm from '../../containers/ChooseCustomerForm/ChooseCustomerForm';
import { StyledChooseCustomerDialogPaper } from './StyledChooseCustomerDialogPaper';

const ChooseCustomerDialog = ({ onRequestClose, customerChosen, open }) => (
  <Dialog disableBackdropClick={false} open={open} onClose={onRequestClose} PaperComponent={StyledChooseCustomerDialogPaper}>
    <DialogTitle>Patient auswählen</DialogTitle>{/* Select patient */}
    <DialogContent>
      <ChooseCustomerForm customerChosen={customerChosen} />
    </DialogContent>
  </Dialog>
);

export default ChooseCustomerDialog;
