import { Button } from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, CalendarToday as CalendarTodayIcon
} from '@material-ui/icons'
import { format } from 'date-fns'
import * as R from 'ramda'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { allDayItemsToggleShowAll } from '../../redux/modules/config'
import { mkBrTags } from '../../utils/string'
import Item, { DnDItem } from './item'
import { sIcsSourcesMap } from './selectors'
import { StyledAllDayItems } from './StyledAllDayItems'

class AllDayItems extends Component {
  // static propTypes = {
  //   items: PropTypes.array, // can be events or termine entities
  //   icsSources: PropTypes.object,
  //   initialLoadComplete: PropTypes.bool,
  //   hideText: PropTypes.bool,
  //   onIcsItemClick: PropTypes.func,
  //   onDateClick: PropTypes.func,
  // }

  static defaultProps = {
    items: [],
    icsSources: {},
    gridConfig: {}
  }

  renderItemIcs(item, key) {
    const { name, description, icsSourceId } = item
    const { icsSources, gridConfig, hideText, onIcsItemClick } = this.props
    const { bezeichnung, farbe } = icsSources[icsSourceId]

    return (
      <Item
        gridConfig={gridConfig}
        key={key}
        backgroundColor={farbe}
        text={hideText ? '' : name}
        onClick={onIcsItemClick ? (e) => {onIcsItemClick(e, item)} : () => {}}
        tooltipText={
          <span>
            {`${bezeichnung}: ${name}`}
            {description ? (
              <span>
                <br />
                {description}
              </span>
            ) : null}
          </span>
        }
      />
    )
  }

  renderItemAllDayTermin(item, key) {
    const { gridConfig, hideText, onDateClick } = this.props
    const { titelOrganisatorischerTermin, notizen } = item

    const beginn = format(item.beginn, GERMAN_DATE_SHORT_YEAR_FNS)
    const ende = format(item.ende, GERMAN_DATE_SHORT_YEAR_FNS)
    const differs = ende !== beginn

    return (
      <DnDItem
        gridConfig={gridConfig}
        key={key}
        text={hideText ? '' : titelOrganisatorischerTermin}
        onClick={onDateClick ? (e) => {onDateClick(e, item)} : () => {}}
        tooltipText={
          <>
            <CalendarTodayIcon fontSize="inherit" />&nbsp;
            <span>
              {differs ? `${beginn} - ${ende}`: beginn}
              <br />
              {titelOrganisatorischerTermin}
              <br />
              {mkBrTags(notizen)}
            </span>
          </>
        }
      />
    )
  }

  render() {
    const { items, gridConfig, actions, initialLoadComplete } = this.props

    const { allDayItemsLimit, allDayItemsShowAll } = gridConfig

    if (!initialLoadComplete) {
      return null
    }

    const displayShowMoreOption = items.length > allDayItemsLimit

    return (
      <StyledAllDayItems gridConfig={gridConfig}>
        {R.unless(
          () => allDayItemsShowAll,
          R.take(allDayItemsLimit - (displayShowMoreOption ? 1 : 0)),
        )(items) // - 1 takes space for 'show more' button
          .map((item, key) => {
            if (item.icsSourceId) {
              return this.renderItemIcs(item, key)
            } else if (item.isAllDay) {
              return this.renderItemAllDayTermin(item, key)
            }
            // add all day termin rendering here
          })}
        {displayShowMoreOption ? (
          <Button
            type="button"
            className="button"
            variant="contained"
            color="secondary"
            key="show-more"
            onClick={(e) => {
              e.preventDefault()
              actions.allDayItemsToggleShowAll()
            }}
          >
            {allDayItemsShowAll 
              ? <ArrowDropUpIcon />
              : <ArrowDropDownIcon />}
            &nbsp;
            <span>{allDayItemsShowAll ? 'weniger anzeigen' : 'alle anzeigen'}</span>
          </Button>
        ) : null}
      </StyledAllDayItems>
    )
  }
}

export default connect(
  (state) => ({
    icsSources: sIcsSourcesMap(state),
    gridConfig: state.config.calendarGrid.default,
    initialLoadComplete: state.initialLoadComplete.praxisstammdaten,
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        allDayItemsToggleShowAll,
      },
      dispatch,
    ),
  }),
)(AllDayItems)
