import * as R from 'ramda'

export default function ids(
  state = {
    aerzte: [],
    anamnesen: [],
    dateien: [],
    dokumentationen: [],
    leistungenPrivat: [],
    heilmittelverordnungen: [],
    patienten: [],
    praxisstammdaten: [],
    termine: [],
    therapieberichte: [],
    users: [],
  },
  action = undefined,
) {
  if (!(action.json && action.json.entities)) {
    return state
  }
  const newState = {}

  Object.keys(state).forEach((key) => {
    const entities = action.json.entities[key]
    const entitiesIds = entities
      ? Object.keys(entities)
          .map((string) => parseInt(string, 10))
          .filter((id) => !!id)
      : false
    newState[key] = entitiesIds.length ? R.union(state[key], entitiesIds) : state[key]
  })

  return newState
}
