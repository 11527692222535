import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { RequestConfig } from './interface'

export function loadLeistungenPrivat(): RequestConfig {
  return {
    types: [types.LOAD_LEISTUNGENPRIVAT, types.LOAD_LEISTUNGENPRIVAT_SUCCESS, types.LOAD_LEISTUNGENPRIVAT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/leistungenPrivat`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createLeistungPrivat(data): RequestConfig {
  return {
    types: [types.CREATE_LEISTUNGPRIVAT, types.CREATE_LEISTUNGPRIVAT_SUCCESS, types.CREATE_LEISTUNGPRIVAT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/leistungenPrivat`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}

export function updateLeistungPrivat(data): RequestConfig {
  return {
    types: [types.UPDATE_LEISTUNGPRIVAT, types.UPDATE_LEISTUNGPRIVAT_SUCCESS, types.UPDATE_LEISTUNGPRIVAT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      const URL = `${apiUrl}/leistungenPrivat/${id}`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(other),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}

export function deleteLeistungPrivat(id): RequestConfig {
  return {
    types: [types.DELETE_LEISTUNGPRIVAT, types.DELETE_LEISTUNGPRIVAT_SUCCESS, types.DELETE_LEISTUNGPRIVAT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/leistungenPrivat/${id}`
      return fetchSecure(
        URL,
        {
          method: 'DELETE',
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { id },
  }
}
