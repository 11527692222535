import { Button, Paper } from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as equipmentActions from '../../actions/equipment'
import { ControlledMultipleAutocompleteField } from '../../shared/components/AutocompleteField/ControlledMultipleAutocompleteField'
import ControlledCheckboxField from '../../shared/components/CheckboxField/ControlledCheckboxField'
import ControlledInputField from '../../shared/components/InputField/ControlledInputField'
import ControlledSelectField from '../../shared/components/SelectField/ControlledSelectField'
import { getErrorMessage } from '../../shared/utils/errorMessages'
import {
  EquipmentSettingsFormButtonContainer,
  EquipmentSettingsFormContainer,
  EquipmentSettingsFormHeader,
  EquipmentSettingsFormInner,
} from './StyledEquipmentSettings'

interface FormData {
  abbreviation: string
  name: string
}

const defaultValues = {
  abbreviation: '',
  name: '',
}

interface Props {
  actions: any
  isOpen: boolean
  selectedEquipment: number | null
  equipment: any
  onCancelChanges: () => void
}

const EquipmentSettingsForm = ({
  actions,
  isOpen = false,
  selectedEquipment,
  equipment = {},
  onCancelChanges,
}: Props): JSX.Element => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    console.log(`
      ----- PROPS CHANGED -----
      isOpen: ${isOpen}
      selectedEquipment: ${selectedEquipment}
      equipment: ${JSON.stringify(equipment)}
      -------------------------
    `)
    if (isOpen) {
      if (!selectedEquipment) {
        resetForm(defaultValues)
      } else {
        const equipmentArray = Object.keys(equipment).map((key) => equipment[key])
        const selectedEquipmentData = equipmentArray.find((e) => e.id === selectedEquipment)
        resetForm(selectedEquipmentData)
      }
    }
  }, [isOpen, selectedEquipment, equipment])

  const onSubmit = async (data: FormData) => {
    console.log('data submitted: ', data)

    if (!!selectedEquipment) {
      await actions.equipment.updateEquipment(data)
    } else {
      await actions.equipment.createEquipment(data)
    }
  }

  const abbreviation = watch('abbreviation')
  const name = watch('name')

  return (
    <EquipmentSettingsFormContainer elevation={2} square isOpen={isOpen}>
      <EquipmentSettingsFormHeader>
        {!abbreviation && !name ? 'Neue Ausstattung' : `${abbreviation} | ${name}`}
      </EquipmentSettingsFormHeader>
      <EquipmentSettingsFormInner autoComplete="off" lang="de" onSubmit={handleSubmit(onSubmit)}>
        <ControlledInputField fullWidth control={control} name="abbreviation" label="Abkürzung" rules={{required: getErrorMessage('required')}} error={!!errors?.abbreviation} helperText={errors?.abbreviation?.message} />
        <ControlledInputField fullWidth control={control} name="name" label="Name" rules={{required: getErrorMessage('required')}} error={!!errors?.name} helperText={errors?.name?.message} />
        <EquipmentSettingsFormButtonContainer>
          <Button variant="contained" color="secondary" type="submit" disabled={!isDirty}>
            {!selectedEquipment ? 'Ausstattung erstellen' : 'Ausstattung aktualisieren'}
          </Button>
          {!!selectedEquipment && isDirty && (
            <Button variant="contained" type="button" onClick={onCancelChanges}>
              Änderungen verwerfen
            </Button>
          )}
        </EquipmentSettingsFormButtonContainer>
      </EquipmentSettingsFormInner>
    </EquipmentSettingsFormContainer>
  )
}

const mapStateToProps = (state) => ({
  equipment: state.entities.equipment,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    equipment: bindActionCreators(equipmentActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSettingsForm)
