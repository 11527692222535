import { TextField } from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import { getCurrencyInEuros, getFixedCurrencyString } from "../../utils/form";

interface Props {
  label?: string,
  error?: boolean,
  helperText?: string,
  fullWidth?: boolean,
  value?: string,
  format?: string,
  min?: number,
  max?: number,
  onChange?: (e?) => void,
  onBlur?: (e?) => void,
  onFocus?: (e?) => void,
  disabled?: boolean,
  selectOnFocus?: boolean,
  className?: string,
  InputProps?: any,
  endAdornment?: JSX.Element,
  readOnly?: boolean,
}

const CurrencyField: FC<Props> = ({
  label,
  error,
  helperText,
  fullWidth,
  value = '',
  onChange = (e?) => {},
  onBlur = (e?) => {},
  onFocus = (e?) => {},
  disabled,
  selectOnFocus = false,
  className,
  InputProps = {},
  endAdornment = <>€</>,
  readOnly = false,
})  => {
  const [focused, setFocused] = useState<boolean>(false)
  const [stateValue, setStateValue] = useState<string | null>(null)
  const inputRef = useRef<any>()

  useEffect(() => {
    let newValue = "";
    if (value != null && value !== "") {
      newValue = getCurrencyInEuros(value);
    }
    setStateValue(newValue);
  }, [value])

  const onChangeFunc = (e) => {
    e.persist()
    let targetValue = e.target.value;
    const filteredValue = _filterInput(targetValue);
    setStateValue(filteredValue)
  }

  //allow only numbers and a comma input, 2 digits only after decimal
  const _filterInput = (value: string): string => {
    return value.replace(/[^-0-9,]/g, '').replace(/(,[0-9]{2})([0-9,]*)/, '$1').replace(/[\,\%]/g, function (match, offset, all) {
      if (match !== ',' || all.indexOf(match) === 0) {
        return '';
      }

      return (all.indexOf(match) === offset ? ',' : '');
    }).replace(/[\-\%]/g, function (match, offset, all) {
      return match === "-" ? (offset === 0 ? '-' : '') : '';
    });
  }

  const onBlurFunc = (e) => {
    e?.persist()
    const targetValue = stateValue;

    setFocused(false)
    if (onBlur) {
      const stateValue = getFixedCurrencyString(targetValue);
      const newValue = stateValue.replace(",", "");
      setStateValue(stateValue)
      onBlur(newValue)
    }
  }

  const onFocusFunc = (e) => {
    e.persist()

    setFocused(true)
    if (onFocus) {
      onFocus(e.target.value)
    }
    selectOnFocus &&
      inputRef?.current?.select()
  }

  return (
    <TextField
      InputLabelProps={{ shrink: (stateValue != null && stateValue !== '') || focused }}
      inputRef={inputRef}
      onChange = {!readOnly
        ? onChangeFunc
        : undefined
      }
      onBlur = {!readOnly
        ? onBlurFunc
        : undefined
      }
      onFocus={onFocusFunc}
      value={ stateValue }
      disabled={disabled}
      label={label}
      helperText={helperText}
      error={error}
      fullWidth={fullWidth}
      className={className}
      InputProps={{
        ...InputProps,
        readOnly,
        endAdornment
      }}
    />
  )
}

export default CurrencyField
