/**
 * Reusable implementation of MUI Select component.
 * For easier usage this already includes
 * the necessary Label and HelperText components,
 * which can be assigned a value via
 * the "label" and "helperText" props respectively.
 */
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FC } from 'react';
import { ISelectFieldOption } from './select-field-option';

interface Props {
  fullWidth?: boolean;
  label?: string | Element;
  error?: boolean;
  helperText?: string | Element;
  options: ISelectFieldOption[];
  onChange: (data: any) => void;
  onBlur?: (data: any) => void;
  value?: any;
  className?: string;
  disabled?: boolean;
}

export const SelectField: FC<Props> = ({
  fullWidth,
  label,
  error,
  helperText,
  options = [],
  onChange,
  onBlur,
  value,
  className = '',
  disabled,
}): JSX.Element => (
  <FormControl
    className={className}
    style={{display: 'flex'}}>
    <InputLabel error={error}>{label}</InputLabel>
    <Select
      displayEmpty={true}
      disabled={disabled}
      fullWidth={fullWidth}
      value={value === undefined || value === null ? '' : value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}>
      {options
        && options.map((item, index) => (
            <MenuItem key={item.keyIndex || index} value={item.value ?? ''}>
              {item.text ?? ''}
            </MenuItem>
          ))}
    </Select>
    { helperText
      ? <FormHelperText error={error}>{helperText}</FormHelperText>
      : null
    }
  </FormControl>
);
