import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import {
  KeyboardBackspace as KeyboardBackspaceIcon
} from '@material-ui/icons'
import { differenceInYears, format } from 'date-fns'
import { remove as unaccent } from 'diacritics'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as termineActions from '../../actions/dates'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { FemaleIcon, MaleIcon, TransgenderIcon } from '../../shared/components/Gender'
import * as datesUtils from '../../utils/dates'
import { buildHvoStringVerordnungsmengeVerbraucht } from '../../utils/heilmittelverordnungen'
import { sortByNachnameVorname } from '../../utils/persons'
import { ContextMenuPatient } from '../MenuPatient'
import { StyledCustomersListView } from './StyledCustomersListView'
import { leistungenKK } from '../../shared/static-data/leistungenKK'

const genderIcons = {
  male: <MaleIcon />,
  female: <FemaleIcon />,
  trans: <TransgenderIcon />,
}

const tableColumns = [
  '',
  'Name',
  'Alter',
  'Adresse',
  'PLZ',
  'Ort',
  'Telefon',
  <span className="appointmentSpan" key="lastAppointmentent">
    <KeyboardBackspaceIcon fontSize="inherit" />
    Termin
  </span>,
  <span className="appointmentSpan" key="nextAppointment">
    Termin
    <KeyboardBackspaceIcon fontSize="inherit" className="nextArrow" />
  </span>,
  'HVO',
  'Diab.',
  'KK',
  'Hausarzt',
]

const now = new Date()

const CustomersListView = ({
  anamnesis,
  customers,
  customerIds,
  customersFilter,
  dates,
  doctors,
  heilmittelverordnungen,
  krankenkassen,
  termine,
  actions,
}) => {
  const [pointerLocation, setPointerLocation] = useState<{ mouseX: null | number; mouseY: null | number }>({
    mouseX: null,
    mouseY: null,
  })
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null)

  const [customersList, setCustomersList] = useState<any[]>([])

  useEffect(() => {
    let newCustomersList: any[] = []

    if(!!customerIds && Array.isArray(customerIds) && customers) {
      newCustomersList = customerIds
        .map((id) => customers[id])
        .filter((customer) => {
          if (customersFilter?.lastNameBeginsWith) {
            return unaccent(customer.nachname.charAt(0).toLowerCase()) === customersFilter.lastNameBeginsWith
          }
          return true
        })
        .filter((customer) => {
          if (customersFilter?.skipDeceased) {
            return !customer.verstorben
          }
          return true
        })
        .filter((customer) => {
          if (customersFilter?.skipLeavers) {
            return !customer.abgewandertZu
          }
          return true
        })
        .sort(sortByNachnameVorname);
    }
    setCustomersList(newCustomersList)
  }, [customerIds, customers, customersFilter])

  const handleRowClick = (e, patientId) => {
    e.persist()
    setSelectedPatient(patientId)
    setPointerLocation({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    })
  }

  const closeContextMenu = () => {
    setPointerLocation({ mouseX: null, mouseY: null })
    setSelectedPatient(null)
  }

  const datesArrayMitAbgesagten = Object.keys(dates).map((key) => dates[key])
  const datesArray = datesUtils.abgesagteTermineEntfernen(datesArrayMitAbgesagten)

  return (
    <>
      <StyledCustomersListView>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell className="tableHead" size="small" key={index} style={index === 0 ? { textAlign: 'center' } : {}}>
                  {index === 0 
                    ? customersList?.length 
                    : column
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customersList
              .map((customer) => {
                const customersDates = datesArray.filter((date) => date.patient === customer.id)
                const previousDate = datesUtils.getDateBefore(now, customersDates)
                const nextDate = datesUtils.getDateAfter(now, customersDates)
                const age = customer.geburtsdatum ? differenceInYears(now, customer.geburtsdatum) : null

                const hvoIdsPatient = Object.keys(heilmittelverordnungen).filter(
                  (key) => heilmittelverordnungen[key].patient === customer.id,
                ).map(item => Number(item))

                const neuesteHvoId = isFinite(Math.max(...hvoIdsPatient)) ? Math.max(...hvoIdsPatient) : null
                const neuesteHvo = neuesteHvoId ? heilmittelverordnungen[neuesteHvoId]: null
                const hvoString = neuesteHvo ? buildHvoStringVerordnungsmengeVerbraucht(neuesteHvo, dates, leistungenKK) : ''

                const patientAnamnesenIds = Object.keys(anamnesis).filter(
                  (key) => anamnesis[key].patient === customer.id,
                ).map(item => Number(item))

                let diabetes = null
                if (patientAnamnesenIds.length > 0) {
                  diabetes = anamnesis[Math.max(...patientAnamnesenIds)].dfDiabetiker
                }
                
                let diabetesText = ''
                if (diabetes === true) {
                  diabetesText = 'JA'
                } else if (diabetes === false) {
                  diabetesText = 'NEIN'
                }

                const hausarzt = doctors[customer.hausarzt]

                return (
                  <TableRow
                    className="customersTableRow"
                    key={customer.id}
                    hover
                    onClick={(e) => handleRowClick(e, customer.id)}
                  >
                    <TableCell className="bodyTableCell" size="small" align="center">
                      {genderIcons[customer.geschlecht]}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {[customer.nachname, customer.vorname].filter(Boolean).join(', ')}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {customer.geburtsdatum
                        ? `${age} (${format(customer.geburtsdatum, GERMAN_DATE_SHORT_YEAR_FNS)})`
                        : null}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {[customer.strasse, customer.hausnummer].filter(Boolean).join(' ')}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">{customer.postleitzahl ?? ''}</TableCell>
                    <TableCell className="bodyTableCell" size="small">{customer.ort ?? ''}</TableCell>
                    <TableCell className="bodyTableCell" size="small">{customer.telefon ?? ''}</TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {(previousDate && format(previousDate.beginn, GERMAN_DATE_SHORT_YEAR_FNS)) || ''}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {(nextDate && format(nextDate.beginn, GERMAN_DATE_SHORT_YEAR_FNS)) || ''}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">{hvoString ?? 'NEIN'}</TableCell>
                    <TableCell className="bodyTableCell" size="small">{diabetesText}</TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {customer.krankenkasse !== null ? krankenkassen[customer.krankenkasse].kuerzel : ''}
                    </TableCell>
                    <TableCell className="bodyTableCell" size="small">
                      {[hausarzt?.vorname, hausarzt?.nachname].filter(Boolean).join(' ')}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </StyledCustomersListView>

      <ContextMenuPatient
        pointerLocation={pointerLocation}
        handleClose={closeContextMenu}
        patientId={selectedPatient}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  anamnesis: state.entities.anamnesen,
  gridConfig: state.config.calendarGrid.default,
  dates: state.entities.termine,
  customerIds: state.ids.patienten,
  customersFilter: state.customersFilter,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  krankenkassen: state.krankenkassen,
  people: state.entities.users,
  customers: state.entities.patienten,
  doctors: state.entities.aerzte,
  selectedDay: state.selectedDay,
  termine: state.entities.termine,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    termine: bindActionCreators(termineActions, dispatch)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomersListView)
