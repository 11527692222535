import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { ISO8601DATE_FNS } from '../constants/dateFormats'
import { format } from 'date-fns'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { RequestConfig } from './interface'

export function loadBuchungen(start, end): RequestConfig {
  return {
    types: [types.LOAD_BUCHUNGEN, types.LOAD_BUCHUNGEN_SUCCESS, types.LOAD_BUCHUNGEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/kassenbuch/dateRange/${format(start, ISO8601DATE_FNS)}/${format(end, ISO8601DATE_FNS)}`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { start, end },
  }
}

export function createBuchung(data): RequestConfig {
  return {
    types: [types.CREATE_BUCHUNG, types.CREATE_BUCHUNG_SUCCESS, types.CREATE_BUCHUNG_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/kassenbuch`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export const selectDay = makeActionCreator(types.KASSENBUCH_SELECT_DAY, 'date')
