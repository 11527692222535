import { Subject as SubjectIcon } from '@material-ui/icons'
import { useState } from 'react'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import AbbreviationSettingsSidebar from './AbbreviationSettingsSidebar'
import AbbreviationSettingsForm from './AbbreviationSettingsForm'
import { AbbreviationSettingsContainer } from './StyledAbbreviationSettings'

export const AbbreviationSettings = (): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const [selectedAbbreviation, setSelectedAbbreviation] = useState<number | null>(null)

  const handleAddNewClick = (): void => {
    setSelectedAbbreviation(null)
    setShowForm(true)
  }

  const handleExistingClick = (abbreviationId): void => {
    console.log('exisiting clicked: ', abbreviationId)
    setSelectedAbbreviation(abbreviationId)
    setShowForm(true)
  }

  const handleCancelChanges = (): void => {
    setShowForm(false)
    setSelectedAbbreviation(null)
  }

  return (
    <>
      <IconHeading icon={<SubjectIcon />} text="Textbausteine" />
      <AbbreviationSettingsContainer>
        <AbbreviationSettingsSidebar
          selectedAbbreviation={selectedAbbreviation}
          onExistingClick={handleExistingClick}
          onAddNewClick={handleAddNewClick}
        />
        <AbbreviationSettingsForm
          isOpen={showForm}
          selectedAbbreviation={selectedAbbreviation}
          onCancelChanges={handleCancelChanges}
        />
      </AbbreviationSettingsContainer>
    </>
  )
}
