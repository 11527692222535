import * as types from '../constants/actionTypes'
import { FILTER_PATIENTEN_DEFAULT } from '../constants/misc'

export default function customersFilter(
  state = {
    lastNameBeginsWith: FILTER_PATIENTEN_DEFAULT,
    skipDeceased: true,
    skipLeavers: true,
  },
  action = undefined,
) {
  switch (action.type) {
    case types.FILTER_CUSTOMERS_LASTNAME_BEGINS_WITH:
      return {
        ...state,
        lastNameBeginsWith: action.char,
      }
    case types.FILTER_DECEASED:
      return {
        ...state,
        skipDeceased: action.deceased,
      }
    case types.FILTER_LEAVERS:
      return {
        ...state,
        skipLeavers: action.leavers,
      }

    default:
  }

  return state
}
