import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { RequestConfig } from './interface'

export function loadTodos(): RequestConfig {
  return {
    types: [types.LOAD_TODOS, types.LOAD_TODOS_SUCCESS, types.LOAD_TODOS_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/todos`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export const clearTodos = makeActionCreator(types.CLEAR_TODOS)
