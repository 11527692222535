import styled from 'styled-components';

export const StyledColorField = styled.div`
  ${({ color }) => `
    flex-grow: 1;
    float: left;
    width: 160px;

    .swatch {
      padding: 5px;
      background-color: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: inline-block;
      cursor: pointer;
    }

    .swatchContent {
      width: 36px;
      height: 14px;
      border-radius: 2px;
      background-color: ${color};
    }

    .popover {
      position: absolute;
      z-index: 2;
    }

    .cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .labelText {
      display: block;
      padding-bottom: 6px;
    }
  `}
`;
