/* eslint camelcase: 0 */
export default function aerzteFachgruppen(
  state: {
    [key: number]: AerzteFachgruppe
  } = {
    1: { id: 1, schluessel: '01', name: 'Allgemeinmediziner (Hausarzt)' },
    2: { id: 2, schluessel: '02', name: 'Arzt/Praktischer Arzt (Hausarzt)' },
    3: { id: 3, schluessel: '03', name: 'Internist (Hausarzt)' },
    4: { id: 4, schluessel: '04', name: 'Anästhesiologie' },
    5: { id: 5, schluessel: '05', name: 'Augenheilkunde' },
    6: { id: 6, schluessel: '06', name: 'Chirurgie' },
    7: { id: 7, schluessel: '07', name: 'Gefäßchirurgie' },
    8: { id: 8, schluessel: '08', name: 'Viszeralchirurgie' },
    9: { id: 9, schluessel: '09', name: 'Kinderchirurgie' },
    10: { id: 10, schluessel: '10', name: 'Orthopädie' },
    11: { id: 11, schluessel: '11', name: 'Unfallchirurgie' },
    12: { id: 12, schluessel: '12', name: 'Chirurgie/Rheumatologie' },
    13: { id: 13, schluessel: '13', name: 'Plastische Chirurgie' },
    14: { id: 14, schluessel: '14', name: 'Thoraxchirurgie' },
    15: { id: 15, schluessel: '15', name: 'Frauenheilkunde' },
    16: { id: 16, schluessel: '16', name: 'Gynäkologische Endokrinologie und Reproduktionsmedizin' },
    17: { id: 17, schluessel: '17', name: 'Gynäkologische Onkologie' },
    18: { id: 18, schluessel: '18', name: 'Spezielle Geburtshilfe und Perinatalmedizin' },
    19: { id: 19, schluessel: '19', name: 'Hals-Nasen-Ohrenheilkunde' },
    20: { id: 20, schluessel: '20', name: 'Phoniatrie' },
    21: { id: 21, schluessel: '21', name: 'Geschlechtskrankheiten' },
    22: { id: 22, schluessel: '22', name: 'Humangenetik' },
    23: { id: 23, schluessel: '23', name: 'Internist' },
    24: { id: 24, schluessel: '24', name: 'Angiologie' },
    25: { id: 25, schluessel: '25', name: 'Endokrinologie und Diabetologie' },
    26: { id: 26, schluessel: '26', name: 'Gastroenterologie' },
    27: { id: 27, schluessel: '27', name: 'Hämatologie und Onkologie' },
    28: { id: 28, schluessel: '28', name: 'Kardiologie' },
    29: { id: 29, schluessel: '29', name: 'Nephrologie' },
    30: { id: 30, schluessel: '30', name: 'Pneumologie' },
    31: { id: 30, schluessel: '31', name: 'Innere Medizin/Rheumatologie' },
    32: { id: 30, schluessel: '32', name: 'Geriatrie' },
    33: { id: 30, schluessel: '33', name: 'Infektologie' },
    34: { id: 30, schluessel: '34', name: 'Kinderarzt (Hausarzt)' },
    35: { id: 30, schluessel: '35', name: 'Kinder-Hämatologie und -Onkologie (Hausarzt)' },
    36: { id: 30, schluessel: '36', name: 'Kinder-Kardiologie (Hausarzt)' },
    37: { id: 30, schluessel: '37', name: 'Neonatologie (Hausarzt)' },
    38: { id: 30, schluessel: '38', name: 'Neuropädiatrie (Hausarzt)' },
    39: { id: 30, schluessel: '39', name: 'Kinder-Pneumologie (Hausarzt)' },
    40: { id: 40, schluessel: '40', name: 'Kinderarzt (Facharzt)' },
    41: { id: 41, schluessel: '41', name: 'Kinder-Hämatologie und -Onkologie (Facharzt)' },
    42: { id: 42, schluessel: '42', name: 'Kinder-Kardiologie (Facharzt)' },
    43: { id: 43, schluessel: '43', name: 'Neonatologie (Facharzt)' },
    44: { id: 44, schluessel: '44', name: 'Neuropädiatrie (Facharzt)' },
    45: { id: 45, schluessel: '45', name: 'Kinder-Pneumologie (Facharzt)' },
    46: {
      id: 46,
      schluessel: '46',
      name: 'Kinder- und Jugendmedizin mit Schwerpunkt und Teilnahme an haus- und fachärztlicher Versorgung',
    },
    47: { id: 47, schluessel: '47', name: 'Kinder- und Jugendpsychiatrie und -psychotherapie' },
    48: { id: 48, schluessel: '48', name: 'Laboratoriumsmedizin' },
    49: { id: 49, schluessel: '49', name: 'Mikrobiologie' },
    50: { id: 50, schluessel: '50', name: 'Mund-Kiefer-Gesichtschirurgie' },
    51: { id: 51, schluessel: '51', name: 'Nervenheilkunde/Neurologie und Psychiatrie' },
    52: { id: 52, schluessel: '52', name: 'Neurochirurgie' },
    53: { id: 53, schluessel: '53', name: 'Neurologie' },
    54: { id: 54, schluessel: '54', name: 'Nuklearmedizin' },
    55: { id: 55, schluessel: '55', name: 'Neuropathologie' },
    56: { id: 56, schluessel: '56', name: 'Pathologie' },
    57: { id: 57, schluessel: '57', name: 'Physikalische und Rehabilitative Medizin' },
    58: { id: 58, schluessel: '58', name: 'Psychiatrie und Psychotherapie' },
    59: { id: 59, schluessel: '59', name: 'Forensische Psychiatrie' },
    60: { id: 60, schluessel: '60', name: 'Psychosomatische Medizin und Psychotherapie' },
    61: { id: 61, schluessel: '61', name: 'Psychotherapeutisch tätiger Arzt' },
    62: { id: 62, schluessel: '62', name: 'Radiologie' },
    63: { id: 63, schluessel: '63', name: 'Kinderradiologie' },
    64: { id: 64, schluessel: '64', name: 'Neuroradiologie' },
    65: { id: 65, schluessel: '65', name: 'Strahlentherapie' },
    66: { id: 66, schluessel: '66', name: 'Transfusionsmedizin' },
    67: { id: 67, schluessel: '67', name: 'Urologie' },
    68: { id: 68, schluessel: '68', name: 'Psychologischer Psychotherapeut' },
    69: { id: 69, schluessel: '69', name: 'Kinder- und Jugendlichen-Psychotherapeut' },
    99: { id: 99, schluessel: '99', name: 'sonstige Fachgruppen' },
  },
) {
  return state
}
