import { ListItemIcon, MenuItem } from '@material-ui/core'
import { Visibility as VisibilityIcon } from '@material-ui/icons'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledListItemText } from './StyledListItemText'

const MenuItemsArzt: FC<any> = ({ 
    doctorId, onCloseFunc, 
  }) => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const viewArzt = (event, id) => {
    event?.stopPropagation()
    navigate(`/${serverHash}/contacts/doctors/${id}`)
    onCloseFunc()
  }

  return (<div>
    <MenuItem onClick={(event) => viewArzt(event, doctorId)}>
      <ListItemIcon>
        <VisibilityIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="Ärztekartei anzeigen" />
    </MenuItem>
  </div>)
}


export default MenuItemsArzt
