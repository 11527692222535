import { MeetingRoom as MeetingRoomIcon } from '@material-ui/icons'
import { useState } from 'react'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import RoomSettingsForm from './RoomSettingsForm'
import RoomSettingsSidebar from './RoomSettingsSidebar'
import { RoomSettingsContainer } from './StyledRoomSettings'

export const RoomSettings = ({ actions }): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const [selectedRoom, setSelectedRoom] = useState<number | null>(null)

  const handleAddNewClick = (): void => {
    setSelectedRoom(null)
    setShowForm(true)
  }

  const handleExistingClick = (roomId): void => {
    console.log('exisiting clicked: ', roomId)
    setSelectedRoom(roomId)
    setShowForm(true)
  }

  const handleCancelChanges = (): void => {
    setShowForm(false)
    setSelectedRoom(null)
  }

  return (
    <>
      <IconHeading icon={<MeetingRoomIcon />} text="Behandlungsräume der Praxis" />
      <RoomSettingsContainer>
        <RoomSettingsSidebar
          selectedRoom={selectedRoom}
          onExistingClick={handleExistingClick}
          onAddNewClick={handleAddNewClick}
        />
        <RoomSettingsForm isOpen={showForm} selectedRoom={selectedRoom} onCancelChanges={handleCancelChanges} />
      </RoomSettingsContainer>
    </>
  )
}
