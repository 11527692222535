/*
* Middleware to forward when certain actions happen.
* remember that the state has not yet been updated by the reducer!
* */
import * as types from '../../constants/actionTypes'
import { REQUEST_PASSWORD_CHANGE_SUCCESS } from '../../shared/redux/modules/password'
import { CHANGE_PIN_CHANGE_PIN_SUCCESS } from '../../shared/redux/modules/pin'
import { apiServerHashSelector } from '../../shared/utils/auth'
import { sCurrentUser, sUserShouldSetPin } from '../../shared/utils/users'
import {
  ACQUIRE_PIN_AUTHENTICATION_FAILURE,
  ACQUIRE_PIN_AUTHENTICATION_SUCCESS,
  DEV_ACQUIRE_AUTHENTICATION_SUCCESS,
  REMOVE_AUTHENTICATION,
  UPGRADE_CREDENTIALS_SUCCESS,
} from '../modules/auth'
import globalNavigation from '../../utils/navigation'

/*
  FYI.
  'action.pdCallRedirect'
  This is additional param for Action, that will cover next case:
  'If we use same API in different places and in some of them don't want redirect'.
  If no checking of pdCallRedirect in some of cases below - alway redirect after success action.
*/

const redirectMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  const state = getState()
  const apiServerHash = apiServerHashSelector(state);
  const changePinPath = `/${apiServerHash}/settings/passwort`

  switch (action.type) {
    case DEV_ACQUIRE_AUTHENTICATION_SUCCESS:
      if (action.json) {
        globalNavigation.navigate
          && globalNavigation.navigate(`/${apiServerHash}/calendar`)
        break
      }
      break

    case ACQUIRE_PIN_AUTHENTICATION_FAILURE:
      break

    case types.CREATE_DOCTOR_SUCCESS:
    case types.UPDATE_DOCTOR_SUCCESS:
      const createUpdateDoctorRedirectPath = `/${apiServerHash}/contacts/doctors/${
        action.json.result[0]
      }`
      globalNavigation.navigate
        && globalNavigation.navigate(createUpdateDoctorRedirectPath, {replace: true})
      break

    case types.CREATE_CUSTOMER_SUCCESS:
      if(!action.pdCallRedirect) {
        // don't redirect after success request
        break;
      }
      const createUpdateCustomerRedirectPath = `/${apiServerHash}/contacts/customers/${
        action.json.result[0]
      }`
      globalNavigation.navigate
        && globalNavigation.navigate(createUpdateCustomerRedirectPath, {replace: true})
      break

    case types.CREATE_HEILMITTELVERORDNUNG_SUCCESS:
    case types.UPDATE_HEILMITTELVERORDNUNG_SUCCESS:
      const createHeilmittelverordnungRedirectPath = `/${apiServerHash}/hvo/${
        action.json.result[0]
      }`
      globalNavigation.navigate
        && globalNavigation.navigate(createHeilmittelverordnungRedirectPath, {replace: true})
      break

    case types.SUBMIT_HVO_SUCCESS:
      const submitHeilmittelverordnungRedirectPath = `/${apiServerHash}/hvo/${action.json.result[0]}`
      globalNavigation.navigate
        && globalNavigation.navigate(submitHeilmittelverordnungRedirectPath, {replace: true})
      break

    case types.CREATE_THERAPIEBERICHT_SUCCESS:
      const createTherapieberichtRedirectPath = `/${apiServerHash}/contacts/customers/${
        action.patient
      }`
      globalNavigation.navigate
        && globalNavigation.navigate(createTherapieberichtRedirectPath, {replace: true})
      break

    case types.CREATE_PRAXIS_SUCCESS:
      globalNavigation.navigate
        && globalNavigation.navigate(`/${apiServerHash}/praxen/add/success`, {replace: true})
      break

    case CHANGE_PIN_CHANGE_PIN_SUCCESS:
      if (sUserShouldSetPin(state)) {
        globalNavigation.navigate
          && globalNavigation.navigate(`/${apiServerHash}/calendar`)
      }
      break
    case REQUEST_PASSWORD_CHANGE_SUCCESS:
      console.log('REQUEST_PASSWORD_CHANGE_SUCCESS case')
      globalNavigation.navigate
        && globalNavigation.navigate(`/login`, {replace: true})
      break

    default:
  }

  await next(action) // eslint-disable-line

  const nextState = getState()

  const redirectToCalendarIfUserChanged = () => {
    const { authUserId } = sCurrentUser(state)
    const { authUserId: nextAuthUserId } = sCurrentUser(nextState)
    const { locked } = state.lockscreen
    if (authUserId !== nextAuthUserId && !locked) {
      globalNavigation.navigate
        && globalNavigation.navigate(`/${apiServerHash}/calendar`, {replace: true})
    }
  }

  const redirectToPinChangeIfPinShouldBeSet = () => {
    if (sUserShouldSetPin(nextState)) {
      globalNavigation.navigate
        && globalNavigation.navigate(changePinPath, {replace: true})
    }
  }

  switch (action.type) {
    case REMOVE_AUTHENTICATION:
      break

    case types.LOAD_PRAXISSTAMMDATEN_SUCCESS:
      redirectToPinChangeIfPinShouldBeSet()
      break

    case UPGRADE_CREDENTIALS_SUCCESS:
      const { pinDefined } = sCurrentUser(nextState)
      if (!pinDefined) {
        return next(() => {
          globalNavigation.navigate
            && globalNavigation.navigate(changePinPath, {replace: true})
        })
      }
      redirectToPinChangeIfPinShouldBeSet()
      redirectToCalendarIfUserChanged()
      break

    case ACQUIRE_PIN_AUTHENTICATION_SUCCESS:
      redirectToCalendarIfUserChanged()
      break

    default:
  }
}

export default redirectMiddleware
