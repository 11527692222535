import styled from 'styled-components';

export const StyledCustomersList = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    .main {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding-bottom: 106px;
    }

    .filterBar {
      position: fixed;
      bottom: 0;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 10px;
      border-top: 1px solid ${theme.palette.primary.main};
    }

    .toggle {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .topBarAlphabetFilter {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      height: unset !important;
    }

    .topBarIconButton {
      width: 60px;
      height: 46px;
      z-index: 1;
      border-radius: 0;
    }

    .topBarButtonWrapper {
      background-color: ${theme.palette.primary.main};
      transition: background-color 0.3s ease-in-out;
      border: 1px solid #fff;
      border-top: none;
      border-bottom: none;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    .paperWrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    .paper {
      padding: 20px;
      max-width: 800px;
    }

    .circularProgress {
      align-self: center;
    }
  `}
`;
