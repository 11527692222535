import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export function loadRooms(): RequestConfig {
  return {
    types: [types.LOAD_ROOMS, types.LOAD_ROOMS_SUCCESS, types.LOAD_ROOMS_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.anamnesiss.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/rooms`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createRoom(data): RequestConfig {
  return {
    types: [types.CREATE_ROOM, types.CREATE_ROOM_SUCCESS, types.CREATE_ROOM_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.rooms,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/rooms`
      const { ...other } = data

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function updateRoom(data): RequestConfig {
  return {
    types: [types.UPDATE_ROOM, types.UPDATE_ROOM_SUCCESS, types.UPDATE_ROOM_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.rooms,

    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      const URL = `${apiUrl}/rooms/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}
