import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { format } from 'date-fns'
import { FC, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openEditTerminDialog } from '../../actions/dialogs'
import { clearNotification, showNotification } from '../../actions/notification'
import { zahlungErfassen } from '../../actions/zahlungen'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { apiServerHashSelector } from '../../selectors/selectors'
import { fetchSecure, sCredentials } from '../../shared/utils/auth'
import { getApiUrl } from '../../utils/auth'
import { sMaybeTerminPatient, sTerminHatZahlung, sZahlungsDatum } from './selectors'
import TerminAbrechnenForm, { ViewTerminAbrechnenForm } from './TerminAbrechnenForm'

import { Cancel as CancelIcon, Receipt as ReceiptIcon, Visibility as VisibilityIcon } from '@material-ui/icons'

interface Api {
  apiUrl: string
  auth0Credentials: string
}

interface Props {
  actions: any
  terminAbrechnenForm: any
  closeDialog: () => void
  open: boolean
  termin: Appointment
  patient: Patient
  terminIstBereitsAbgerechnet: boolean
  zahlungsDatum: Date
  api: Api
}

const TerminAbrechnenDialog: FC<Props> = ({
  actions,
  terminAbrechnenForm,
  closeDialog,
  open,
  termin,
  patient,
  terminIstBereitsAbgerechnet = false,
  zahlungsDatum,
  api,
}) => {
  const formRef = useRef<HTMLInputElement>(null)

  const openEditTerminDialog = () => {
    actions.openEditTerminDialog(termin)
  }

  const generateReceipt = async (terminId) => {
    const URL = `${api.apiUrl}/zahlungen/getReceiptPDF/${terminId}`
    actions.showNotification('Quittung wird in neuem Tab geöffnet. Bitte Pop-up-Blocker beachten!')
    const response = await fetchSecure(URL, { credentials: 'include' }, api.auth0Credentials)
    const pdfBlob = await response.blob()
    const fileUrl = window.URL.createObjectURL(pdfBlob)
    window.open(fileUrl)
  }

  const _onSubmit = async (formData) => {
    await actions.zahlungErfassen({ formData, termin })

    closeDialog()

    if (formData.withReceipt) {
      generateReceipt(termin.id)
    }
  }

  const datum = terminIstBereitsAbgerechnet && zahlungsDatum ? zahlungsDatum : new Date()
  const title = `Barzahlung von "${[patient?.vorname, patient?.nachname].filter(Boolean).join(' ')}" am ${format(
    datum,
    GERMAN_DATE_SHORT_YEAR_FNS,
  )} ${terminIstBereitsAbgerechnet ? '' : 'erfassen'}`
  const { _submitting: submitting, _error: error, _submitFailed: submitFailed } = terminAbrechnenForm

  return (
    <Dialog disableBackdropClick open={open} onClose={closeDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {termin && !terminIstBereitsAbgerechnet ? (
          <TerminAbrechnenForm ref={formRef} onSubmit={_onSubmit} termin={termin} addMode />
        ) : (
          termin && <ViewTerminAbrechnenForm termin={termin} addMode={false} />
        )}
      </DialogContent>
      <DialogActions>
        {terminIstBereitsAbgerechnet ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 5 }}
              onClick={() => generateReceipt(termin.id)}
              startIcon={
                <ReceiptIcon color="inherit" /> // {/* ion-ios-paper */}
              }
            >
              Quittung erstellen {/* Create receipt */}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 5 }}
              onClick={openEditTerminDialog}
              startIcon={
                <VisibilityIcon color="inherit" /> // {/* ion-eye */}
              }
            >
              Termin anzeigen {/* Show appointment */}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={closeDialog}
              startIcon={<CancelIcon color="inherit" />}
            >
              Schließen {/* Conclude */}
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" color="secondary" style={{ marginRight: 5 }} onClick={closeDialog}>
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => formRef.current?.submit()}
              disabled={submitting || (!!error && submitFailed)}
            >
              Speichern
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default connect(
  (state: StoreState, props) => ({
    patient: sMaybeTerminPatient(state, props),
    terminIstBereitsAbgerechnet: sTerminHatZahlung(state, props),
    zahlungsDatum: sZahlungsDatum(state, props),
    terminAbrechnenForm: state.form.terminAbrechnenForm,
    api: {
      apiUrl: getApiUrl(apiServerHashSelector(state)),
      auth0Credentials: sCredentials(state),
    },
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        openEditTerminDialog,
        zahlungErfassen,
        showNotification,
        clearNotification,
      },
      dispatch,
    ),
  }),
)(TerminAbrechnenDialog)
