import { Snackbar } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { selectTerminProps, undoCancelDate } from '../../actions/dates'
import { clearNotification, showNotification } from '../../actions/notification'
import { MessageSuccess } from '../../shared/components/Message'

interface BufferNotification {
  open?: boolean;
  message?: JSX.Element;
  busy?: boolean;
  error?: boolean;
  action?: JSX.Element;
  timer?: number;
  hidesOnLocationChange?: boolean;
  redirectOnSuccess?: string;
}


interface Props {
  selectedTerminProps?: any,
  notification?: any,
  onRequestClose?: any,
  showNotificationAction?: any,
  selectTerminPropAction?: any,
  undoCancelDateAction?: any,
}

const Notification: FC<Props> = ({
  selectedTerminProps,
  notification,
  onRequestClose,
  showNotificationAction,
  selectTerminPropAction,
  undoCancelDateAction,
}) => {
  const [delayingNextMessage, setDelayingNextMessage] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const currentMessageNotNull = !!notification?.message
    let timer: any = null
    if (currentMessageNotNull) {
      setDelayingNextMessage(true)
      timer = setTimeout(() => {
        setDelayingNextMessage(false)
      }, 1000)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [notification?.message])

  const termiNotiClick = () => {
    const url = selectedTerminProps.url
    selectTerminPropAction({})
    onRequestClose()
    navigate(url)

    if (selectedTerminProps.absagegrund === null) {
      const cancelledDate = Object.assign({}, selectedTerminProps)
      delete cancelledDate.url
      undoCancelDateAction(cancelledDate)
    }

    setTimeout(() => {
      showNotificationAction({
        message: (
          <MessageSuccess message = 'Terminvereinbarung abgebrochen.' />
        ),
        busy: false,
        error: false,
        timer: 3000,
      })
    }, 0)
  }

  const openSnackbar = !!notification?.message && !delayingNextMessage
  const timer = notification?.timer || 15000
  const bufferNotification: BufferNotification = {}


  // move show notification to MenuItemsTermin because it was not synced with redux
  // if (selectedTerminProps && !(notification?.message === 'Termin wird angelegt...')) {
  //   if (!(Object.keys(selectedTerminProps).length === 0 && selectedTerminProps.constructor === Object)) {
  //     bufferNotification.open = true
  //     bufferNotification.message = (
  //       <MessageSuccess message = 'Daten für Folgetermin gespeichert. Bitte Termin wählen.&nbsp;' />
  //     )
  //     bufferNotification.busy = false
  //     bufferNotification.error = false
  //     bufferNotification.action = <Button variant="contained" color="primary" onClick={termiNotiClick}>Abbrechen</Button>// Abort
  //     bufferNotification.timer = 0
  //     bufferNotification.hidesOnLocationChange = true
  //   }
  // }

  return (
    <Snackbar
      open={bufferNotification.open || openSnackbar}
      style={{ zIndex: 99999, whiteSpace: 'nowrap'}}
      ContentProps={{
        style: {
         flexWrap: 'nowrap'
        }
      }}
      // pointerEvents: 'none'
      message={bufferNotification.message || notification?.message}
      action={bufferNotification.action || notification?.action}
      onClose={notification.redirectOnSuccess ? () => { navigate(notification.redirectOnSuccess); onRequestClose() } : notification?.hidesOnLocationChange ? () => {} : onRequestClose}
      autoHideDuration={notification?.hidesOnLocationChange ? null : bufferNotification.timer || timer}
    />
  )
}

const mapStateToProps = (state) => ({
  notification: state.notification,
  selectedTerminProps: state.selectedTerminProps,
})

const mapDispatchToProps = (dispatch) => ({
  onRequestClose: bindActionCreators(clearNotification, dispatch),
  selectTerminPropAction: bindActionCreators(selectTerminProps, dispatch),
  showNotificationAction: bindActionCreators(showNotification, dispatch),
  undoCancelDateAction: bindActionCreators(undoCancelDate, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
