import styled from 'styled-components';

export const StyledEditUsersForm = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: ${theme.spacing(4)}px;
    grid-column-gap: ${theme.spacing(4)}px;

    .form {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
      padding: ${theme.spacing(4)}px;
    }

    .formGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
      &__link {
        margin: 0 15px;
      }
    }

    .usersListContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(4)}px;
      grid-auto-rows: min-content;
    }

    .userList {
      width: 400px;
    }

    .usersListPaper {
      padding: ${theme.spacing(3)}px;
    }

    .listItem {
      &:hover {
        background-color: ${theme.palette.primary.dark};
        cursor: pointer;
      }
    }

    .listItemActive {
      background-color: ${theme.palette.primary.main};
      &:hover {
        background-color: ${theme.palette.primary.dark};
        cursor: pointer;
      }
    }

    .checkboxGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(1)}px;
    }

    .iCalendarButton {
      background-color: #fff;
      margin: 10px;
    }

    .listHeader {
      font-size: large;
    }
  `}
`;
