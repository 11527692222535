import { CreditCard as CreditCardIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../../constants/dateFormats';

export default {
  displayName: (todo, props) => {
    const hvo = props.heilmittelverordnungen[todo.referenceId]
    if (!hvo) return ''
    const patient = props.patienten[hvo.patient]
    if (!patient) return ''
    return `Rezept abrechnen: ${[patient.vorname, patient.nachname].filter(Boolean).join(' ')}, vom ${format(
      hvo.ausstellungsdatum,
      GERMAN_DATE_SHORT_YEAR_FNS,
    )}`
  },
  displayNameAggregate: () => <span>Rezepte abzurechnen</span>,
  selectState: (state) => ({
    heilmittelverordnungen: state.entities.heilmittelverordnungen,
    patienten: state.entities.patienten,
  }),
  action: (todo, props) => () => {
    props.navigate(`/${props.serverHash}/hvo/${todo.referenceId}`)
  },
  icon: <CreditCardIcon /> //'ion-card',
}
