import { CircularProgress } from '@material-ui/core';
import { LocalHospital as LocalHospitalIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ViewArztForm } from '../../components/DoctorForm/DoctorForm';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledViewArzt } from './StylesViewArzt';

const ViewArzt = ({ aerzte }) => {
  const {arzt, serverHash} = useParams()

  if (!aerzte[arzt]) {
    return (
      <StyledViewArzt>
        <IconHeading icon={<LocalHospitalIcon />} text="Ärztekartei" />
        <CircularProgress className="circularProgress" size={100} thickness={7} />
      </StyledViewArzt>
    );
  }

  return (
    <StyledViewArzt>
      <IconHeading
        icon={<LocalHospitalIcon />}
        text={
          <span>
            Ärztekartei (
            <Link to={`/${serverHash}/contacts/doctors/${arzt}/edit`} className="editLink">
              zur Bearbeitung {/* for processing */}
            </Link>
            )
          </span>
        }
      />
      <ViewArztForm initialValues={aerzte[arzt]} viewOnly />
    </StyledViewArzt>
  );
};

const mapStateToProps = (state) => ({
  aerzte: state.entities.aerzte,
});

export default connect(mapStateToProps)(ViewArzt);
