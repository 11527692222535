import { Card } from '@material-ui/core';
import styled from 'styled-components';

export const StyledInfo = styled(Card)`
  ${({ theme }) => `
    width: 300px;
    background-color: ${theme.palette.primary.main};
    height: 100%;
    border-radius: 12px;

    .accordion {
      width: 100%;
    }

    .accordionSummary {
      background-color: ${theme.palette.primary.main};
    }

    .accordionSummaryText {
      margin-left: ${theme.spacing(2)}px;
    }

    .contentBlock {
      padding: ${theme.spacing(4)}px;
      display: flex;
      flex-direction: column;
      justify-items: space-around;
    }

    .link {
      color: ${theme.palette.secondary.main};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;
