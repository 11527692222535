// check UpgradeCredentialsForm.back if restore some logic.
// don't remove UpgradeCredentialsForm.back file

import { Button, CardActions, CardContent, Popover, Typography } from '@material-ui/core'
/* eslint no-use-before-define:0 */
import { FC } from 'react'
import { redirectAuth } from '../../../utils/auth0'
import { StyledUpgradeCredentialsForm } from './StyledUpgradeCredentialsForm'

interface Props {
  open?: boolean,
  anchorEl?: any,
  onRequestClose?: () => void,

  userToSwitchTo?: any,
  forwardToStartbereich?: boolean,
  practiceToOpen?: any,
  currentUser?: any,
}

const UpgradeCredentialsForm: FC<Props> = ({
  open = false,
  anchorEl,
  onRequestClose,
}) => {

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      onClose={onRequestClose || undefined}
      onExiting={onRequestClose || undefined}
    >
      <StyledUpgradeCredentialsForm>
        <CardContent className="cardText">
          <Typography variant="body1">
            Login mit E-Mail und Passwort erforderlich!
          </Typography>
        </CardContent>
        <CardActions className="cardActions">
          <Button 
            variant="contained" 
            color="secondary"
            onClick={redirectAuth} 
            className="button">
            Login
          </Button>
        </CardActions>
      </StyledUpgradeCredentialsForm>
    </Popover>
  )
}

export default UpgradeCredentialsForm
