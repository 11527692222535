import { ListItemIcon, MenuItem } from '@material-ui/core'
import {
  Edit as EditIcon
} from '@material-ui/icons'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledListItemText } from './StyledListItemText'

const MenuItemsLeistungPrivat: FC<any> = ({ 
  leistungPrivatId, onCloseFunc, 
  }) => {
  const { serverHash } = useParams()
  const navigate = useNavigate()

  const handleMenuEditClick = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/settings/leistungen/${id}`)
  }

  return (<div>
    <MenuItem onClick={(event) => handleMenuEditClick(event, leistungPrivatId)}>
      <ListItemIcon>
        <EditIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="Leistung bearbeiten" /> {/* Edit performance */}
    </MenuItem>
  </div>)
}

export default MenuItemsLeistungPrivat
