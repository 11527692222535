import styled from 'styled-components';

export const StyledSicherheitForm = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(4)}px ${theme.spacing(8)}px;

    .paper {
      padding: ${theme.spacing(5)}px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-items: space-around;
    }
  `}
`;
