import { DeveloperBoard as DeveloperBoardIcon } from '@material-ui/icons';

export default {
  displayName: (todo, props) => {
    const patient = props.patienten[todo.referenceId]
    if (!patient) return ''
    return `Anamnese für ${[patient.vorname, patient.nachname].filter(Boolean).join(' ')} erfassen`
  },
  displayNameAggregate: () => <span>Anamnesen erfassen</span>,
  selectState: (state) => ({
    patienten: state.entities.patienten,
  }),
  action: (todo, props) => () => {
    props.navigate(`/${props.serverHash}/anamnesis/${todo.referenceId}/01`)
  },
  icon: <DeveloperBoardIcon /> //'ion-clipboard',
}
