import * as R from 'ramda'

const mapIndexed = R.addIndex(R.map)

export const mkBrTags = R.compose(
  (items) =>
    mapIndexed(
      (item, key) => (
        <span key={key}>
          {item}
          {key < items.length - 1 ? <br /> : null}
        </span>
      ),
      items,
    ),
  R.split('\n'),
  R.defaultTo(''),
)
