import { Tooltip } from '@material-ui/core'
import * as R from 'ramda'
import { FC, ReactNode } from 'react'
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showNotification } from '../../actions/notification'
import * as itemTypes from '../../constants/itemTypes'
import { StyledItem } from './StyledItem'

interface Props {
  text?: ReactNode,
  tooltipText?: ReactNode,
  gridConfig: Object,
  onClick?: (e?) => void,
  backgroundColor?: string,
  isDragging?: boolean,
  connectDragSource?: any,
}

const Item: FC<Props> = ({
  text = null,
  tooltipText = null,
  gridConfig = {},
  onClick,
  backgroundColor,
  isDragging,
  connectDragSource,
}) => {
  const _connectDnD = (nodes) => {
    if (!connectDragSource) {
      return nodes
    }
    return connectDragSource(nodes)
  }

  return (
    <StyledItem
      gridConfig={gridConfig}
      backgroundColor={backgroundColor}
      key="root"
      onClick={onClick || undefined}>

      <Tooltip
        enterDelay={300}
        // Tooltip will not be displayed, if title empty string
        title={
          (tooltipText && !isDragging)
            ? (
              <div className="wrapTooltipText">
                {tooltipText}
              </div>
            )
            : ''
        }
        placement="top"
      >
        {_connectDnD(
          <div key="inner" className="inner">
            <span>{text}</span>
          </div>
        )}
      </Tooltip>
    </StyledItem>
  )
}

const connectRedux = connect(
  () => ({}),
  (d) => bindActionCreators({ showNotification }, d),
)

export default R.compose(connectRedux)(Item)

const itemSource = {
  beginDrag(props) {
    return {
      props
    }
  },
  endDrag(props) {
    props.showNotification('Änderungen an ganztägigen Terminen über "anzeigen & bearbeiten" vornehmen!')
  },
}

const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})

export const DnDItem = R.compose(
  connectRedux,
  DragSource(itemTypes.ALLDAY_APPOINTMENT, itemSource, collectSource),
)(Item)
