import { Button } from '@material-ui/core'
import { useCallback, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as patientenActions from '../../actions/customers'
import { AutocompleteField } from '../../shared/components/AutocompleteField'
import { buildPatientString } from '../../utils/patienten'
import { sortByNachnameVorname } from '../../utils/persons'
import { StyledChooseCustomerForm } from './StyledChooseCustomerForm'

export const fields = ['customer']

function ChooseCustomerForm({
  actions,
  customers,
  customerChosen,
  handleSubmit,
  invalid,
  busy,
  submitFailed,
  fields: { customer },
}) {
  useEffect(() => {
    actions.patienten.loadCustomers()
  }, [])

  const onSubmit = useCallback((data) => {
    console.log('data: ', data)
    if (customerChosen) customerChosen(data)
  }, [customerChosen, customerChosen])

  const autocompleteItems: Array<AutocompleteOption> = useMemo(() => Object.keys(customers)
    .map((key) => customers[key])
    .filter((customer) => !customer.verstorben)
    .sort(sortByNachnameVorname)
    .map((patient) => ({ value: patient.id, text: buildPatientString(patient) })), [customers])

  return (
    <StyledChooseCustomerForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <AutocompleteField
        {...customer}
        options={autocompleteItems}
        error={customer.touched && !!customer.error}
        helperText={customer.touched && !!customer.error && customer.error}
        autoFocus
      />
      <Button
        className="submitButton"
        variant="contained"
        color="secondary"
        type="submit"
        disabled={busy || (invalid && submitFailed)}
      >
        Patient wählen
      </Button>
    </StyledChooseCustomerForm>
  )
}

const validate = (values) => {
  const errors: KeyValue<string> = {}

  if (!values.customer) {
    errors.customer = 'Bitte wählen Sie einen Patienten.'
  }

  return errors
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  customers: state.entities.patienten,
  loading: state.loading,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    patienten: bindActionCreators(patientenActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'chooseCustomerForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(ChooseCustomerForm)
