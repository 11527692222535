import { Button, CardContent, ClickAwayListener } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useState } from 'react';
import InputField from '../../../shared/components/InputField/InputField';
import { StyledNewPraxis } from './StyledNewPraxis';

const NewPraxisCard = ({ onSubmit, className }): JSX.Element => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const isTextInvalid = () => {
    const charLimit = 70;
    const errorMessage = `Max. ${charLimit} Zeichen erlaubt!`;

    if (inputValue && inputValue.length > charLimit) 
      return errorMessage;

    return '';
  };

  const reset = () => {
    setTouched(false);
    setInputValue('');
    setShowInput(false);
  };

  const submit = () => {
    onSubmit(inputValue.trim());
    reset();
  };

  const onKeyDown = (e) => {
    const ENTER_KEY_CODE = 13;
    if (e.which === ENTER_KEY_CODE && inputValue.trim() !== '') {
      submit();
    }
  };

  const handleClickAway = () => {
    setInputValue('');
    setShowInput(false);
    setTouched(false);
  };

  const unhideInput = () => {
    setShowInput(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledNewPraxis className={className} square onClick={unhideInput}>
        <CardContent className="cardContent">
          <AddIcon className="addIcon" />
          <div className="title">Neue Praxis erstellen</div>
          <div className="subtitle">(deren Inhaber ich bin)</div>
          {showInput && (
            <div className="inputArea">
              <InputField 
                onChange={(value) => setInputValue(String(value || ''))}
                value={inputValue}
                onKeyDown={onKeyDown}
                autoFocus
                onFocus={() => setTouched(true)}
                error={touched && !!isTextInvalid()}
                helperText={touched && !!isTextInvalid() ? isTextInvalid() : ''}
                placeholder="Firmenbezeichnung Ihrer Praxis"
                InputProps={{ className: 'textFieldInput' }}
              />
              {inputValue.trim() && (
                <Button
                  type="button"
                  className="button"
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={!!isTextInvalid()}
                >
                  Erstellen
                </Button>
              )}
            </div>
          )}
        </CardContent>
      </StyledNewPraxis>
    </ClickAwayListener>
  );
};

export default NewPraxisCard;
