import { FormControl, FormGroup, FormHelperText, FormLabel } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import CheckboxField from '../CheckboxField/CheckboxField'

const StyledFormControl = styled(FormControl)`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;

    .label {
      margin: 0 !important;
      color: ${theme.palette.text.primary};
    }

    .formGroup {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  `}
`

interface Props {
  className?: string, 
  label?: string | JSX.Element, 
  labelYes?: string,  
  labelNo?: string,
  onChange?: (value: null | boolean) => void, 
  value?: boolean | null,
  error?: boolean,
  helperText?: string,
  disabled?: boolean,
}

const CheckboxXOR: FC<Props> = ({ 
  className, 
  label, 
  labelYes, 
  labelNo, 
  onChange, 
  value,
  error,
  helperText,
  disabled = false,
}): JSX.Element => {
  const [xorValue, setXorValue] = useState<boolean | null>(null)

  useEffect(() => {
    const newValue = value != null ? Boolean(value) : null
    setXorValue(newValue)
  }, [value])

  const handleChange = (checked: boolean, name: string) => {
    if (name === 'yes' && checked === true) {
      setXorValue(true)
      onChange && onChange(true)
    } else if (name === 'no' && checked === true) {
      setXorValue(false)
      onChange && onChange(false)
    } else {
      setXorValue(null)
      onChange && onChange(null)
    }
  }

  return (
    <div className={className}>
      <StyledFormControl>
        <div>
          <FormLabel className="label">{label}</FormLabel>
          { helperText 
            ? <FormHelperText error={error}>{helperText}</FormHelperText> 
            : null
          }
        </div>
        <FormGroup className="formGroup" row>
          <CheckboxField
            disabled={disabled}
            className="checkboxYes"
            label={labelYes}
            checked={xorValue === true}
            onChange={(checked: boolean) => handleChange(checked, 'yes')}
          />
          <CheckboxField
            disabled={disabled}
            className="checkbox"
            label={labelNo}
            checked={xorValue === false}
            onChange={(checked: boolean) => handleChange(checked, 'no')}
          />
        </FormGroup>
      </StyledFormControl>
    </div>
  )
}

export default CheckboxXOR
