import fetch from 'isomorphic-fetch'
import { getAuthUrl, getApiUrl } from '../../../utils/auth'
import { fetchSecure, sCredentials } from '../../utils/auth'
import makeActionCreator from '../utils/makeActionCreator'
import { apiServerHashSelector } from '../../../selectors/selectors'

export const REQUEST_PASSWORD_CHANGE = 'pod001_frontend_startbereich/password/REQUEST_PASSWORD_CHANGE'
export const REQUEST_PASSWORD_CHANGE_SUCCESS = 'pod001_frontend_startbereich/password/REQUEST_PASSWORD_CHANGE_SUCCESS'
export const REQUEST_PASSWORD_CHANGE_FAILURE = 'pod001_frontend_startbereich/password/REQUEST_PASSWORD_CHANGE_FAILURE'

export const CHANGE_PASSWORD_LOGGED_IN = 'pod001/password/CHANGE_PASSWORD_LOGGED_IN'
export const CHANGE_PASSWORD_LOGGED_IN_SUCCESS = 'pod001/password/CHANGE_PASSWORD_LOGGED_IN_SUCCESS'
export const CHANGE_PASSWORD_LOGGED_IN_FAILURE = 'pod001/password/CHANGE_PASSWORD_LOGGED_IN_FAILURE'
export const CHANGE_PASSWORD_LOGGED_IN_UNAUTHORIZED = 'pod001/password/CHANGE_PASSWORD_LOGGED_IN_UNAUTHORIZED'
export const CHANGE_PASSWORD_LOGGED_IN_PASSWORD_TOO_WEAK = 'pod001/password/CHANGE_PASSWORD_LOGGED_IN_PASSWORD_TOO_WEAK'
export const CHANGE_PASSWORD_LOGGED_IN_NEW_PASSWORD_MISMATCH =
  'pod001/password/CHANGE_PASSWORD_LOGGED_IN_NEW_PASSWORD_MISMATCH'
export const CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS =
  'pod001/password/CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS'

export function requestPasswordChange({ username, captcha }) {
  return {
    types: [REQUEST_PASSWORD_CHANGE, REQUEST_PASSWORD_CHANGE_SUCCESS, REQUEST_PASSWORD_CHANGE_FAILURE],
    requiresAuth: false,
    shouldCallAPI: () => true,
    callAPI: () => {
      const URL = `${getAuthUrl()}/request-password-change`

      return fetch(URL, {
        method: 'POST',
        credentials: 'include', // send credentials like cookies & basic auth
        body: JSON.stringify({ username, captcha }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    payload: { username, captcha },
  }
}

export function changePasswordLoggedIn({ oldPassword, newPassword, newPasswordConfirmation }) {
  return async (dispatch, getState) => {
    const state = getState()
    const credentials = sCredentials(state)

    await dispatch({
      type: CHANGE_PASSWORD_LOGGED_IN,
      oldPassword,
      newPassword,
      newPasswordConfirmation,
    })

    const URL = apiServerHashSelector(getState())
      ? `${getApiUrl(apiServerHashSelector(getState()))}/change-password`
      : `${getAuthUrl()}/change-password`

    const response = await fetchSecure(
      URL,
      {
        method: 'POST',
        credentials: 'include', // send credentials like cookies & basic auth
        body: JSON.stringify({ oldPassword, newPassword, newPasswordConfirmation }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      credentials,
    )

    if (!response.ok) {
      await dispatch({
        type: CHANGE_PASSWORD_LOGGED_IN_FAILURE,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
        response,
      })
      return { success: false }
    }
    await dispatch({
      type: CHANGE_PASSWORD_LOGGED_IN_SUCCESS,
      oldPassword,
      newPassword,
      newPasswordConfirmation,
      response,
    })
    if (response.status === 200) {
      await dispatch({
        type: CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      })
    } else if (response.status === 401) {
      await dispatch({
        type: CHANGE_PASSWORD_LOGGED_IN_UNAUTHORIZED,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      })
    }
    return { success: true }
  }
}

export const changePasswordWrongPassword = makeActionCreator(CHANGE_PASSWORD_LOGGED_IN_UNAUTHORIZED)

export const notifications = {
  [REQUEST_PASSWORD_CHANGE_SUCCESS]: {
    message: 'Sie haben eine E-Mail zum Passwort-Reset erhalten.',
    busy: false,
    error: true,
  },
  [REQUEST_PASSWORD_CHANGE_FAILURE]: {
    message: 'Diese E-Mail-Adresse ist uns nicht bekannt. Bitte erneut versuchen.',
    busy: false,
    error: true,
  },
  [CHANGE_PASSWORD_LOGGED_IN]: { message: 'Neues Passwort wird gespeichert...', busy: true, error: false },
  [CHANGE_PASSWORD_LOGGED_IN_FAILURE]: {
    message: 'Speichern des Passworts fehlgeschlagen, bitte versuchen Sie es später nocheinmal!',
    busy: false,
    error: true,
  },
  [CHANGE_PASSWORD_LOGGED_IN_UNAUTHORIZED]: {
    message: 'Die Eingabe Ihres aktuellen Passworts war nicht korrekt!',
    busy: false,
    error: true,
  },
  [CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS]: {
    message: 'Neues Passwort erfolgreich gespeichert!',
    busy: false,
    error: false,
  },
}
