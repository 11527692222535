import { Assignment as AssignmentIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../../constants/dateFormats';

export default {
  displayName: (todo, props) => {
    const termin = props.termine[todo.referenceId]
    if (!termin) return ''
    const patient = props.patienten[termin.patient]
    if (!patient) return ''
    return `Dokumentation zu Termin ${format(termin.beginn, GERMAN_DATE_SHORT_YEAR_FNS)} ${
      patient.vorname ? patient.vorname[0] + '. ' : ''
    }${patient.nachname} erfassen`
  },
  displayNameAggregate: () => <span>Dokumentationen erfassen</span>,
  selectState: (state) => ({
    patienten: state.entities.patienten,
    termine: state.entities.termine,
  }),
  action: (todo, props) => () => {
    props.navigate(`/${props.serverHash}/documentation/${
        props.termine[todo.referenceId].patient
      }/${
        todo.referenceId
      }`)
  },
  icon: <AssignmentIcon />, //'ion-document'
}
