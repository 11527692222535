import { isDate, isEqual } from 'date-fns'
import * as R from 'ramda'
import isPristine from 'redux-form/lib/isPristine'
import { showNotification } from '../../actions/notification'
import { SOCKET_RECEIVE_DATA } from '../../constants/actionTypes'
import { terminFields } from '../../containers/DokumentationenForm/DokumentationenForm'
import { momentFields } from '../../reducers/entities'
import { MessageError } from '../../shared/components/Message'
import { stringsToDatesSingleEntity } from '../../shared/utils/dates'

export default ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const notification = {
    message: (
      <MessageError message='Daten wurden an anderer Stelle bearbeitet. Bitte vor dem speichern neu laden!'/>
    ),
    busy: false,
    error: false,
    hidesOnLocationChange: true,
  }

  const state = getState()

  switch (action.type) {
    case SOCKET_RECEIVE_DATA:
      const forms = [
        [
          state.form.dokumentationenForm,
          action,
          'termine',
          R.filter((value) => value !== 'terminId', terminFields),
          'terminId',
        ],
        [state.form.terminForm, action, 'termine'],
        [state.form.terminOrganisatorischForm, action, 'termine'],
      ]
      const notify = forms.reduce((acc, key) => {
        if (acc === true) return true
        return shouldNotifyFormDataChanged(...key)
      }, false)
      if (notify === true) dispatch(showNotification(notification))
      break
    default:
  }
}

function shouldNotifyFormDataChanged(state, action, entityType, fields = null, idField = 'id') {
  console.log('entityType: ', entityType)
  if (action.json.entity !== entityType) return false
  const entityInstance = action.json.entities[entityType][action.json.result[0]]
  const entityInstanceWithMoments = stringsToDatesSingleEntity(entityInstance, momentFields.entityType)
  if (state._initialized === true && entityInstanceWithMoments.id === state[idField].initial) {
    return [...(fields || Object.keys(state))].reduce((acc, key) => {
      if (acc === true) return true
      if (typeof state[key] === 'object' && !isPristine(state[key].initial, state[key].value)) {
        if (isDate(state[key].initial) && !isEqual(state[key].initial, entityInstanceWithMoments[key])) {
          return true
        } else if (
          ['string', 'number', 'boolean', 'object'].indexOf(typeof state[key].initial) !== -1 &&
          !R.equals(state[key].initial, entityInstanceWithMoments[key])
        ) {
          return true
        }
      }
      return acc
    }, false)
  }
  return false
}
