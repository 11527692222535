export const errorMessages = {
    required: 'Bitte ausfüllen',
    isInteger: 'Bitte eine ganze Zahl eingeben',
    minNum: 'Bitte eine Zahl größer {{1}} eingeben',
    maxNum: 'Bitte eine Zahl kleiner {{1}} eingeben',
  }

type ErrorMessageSnippet = string | number

export const getErrorMessage = (error: keyof typeof errorMessages, ...snippets: ErrorMessageSnippet[]) => {
    const message = errorMessages[error]
    if (!message) {
        console.warn('No error message for error:', error)
        return ''
    }

    let result = message;
    snippets.forEach((snippet, idx) => {
        result = result.replace(`{{${idx + 1}}}`, `${snippet}`)
    })

    return result
}