import styled from 'styled-components';

export const StyledCalendarFreeDatesView = styled.div`
  ${({ calendarHeight, isSnackbarOpen }: { calendarHeight: number, isSnackbarOpen: boolean}) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;

    height:  ${isSnackbarOpen ? '80vh;' : '100vh;'}

    .freeDatesViewMain {
      display: flex;
      flex-direction: row;
      margin-top: 1px;
      padding-top: 16px;
      margin-left: 32px;
      height: ${calendarHeight}px;
    }

    .freeDatesViewTopBar {
      display: flex;
      flex-direction: row;
      height: 32px;
    }

    .freeDatesViewColumnWrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
    }

    .dayColumn {
      display: flex;
    }

    .dayColumn > div {
      min-width: 45px;
      flex-basis: max-content;
    }

    .dayColumnWide {
      display: flex;
    }

    .dayColumnWide > div {
      min-width: 100px;
    }
  `}
`;
