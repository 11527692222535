import { FC } from 'react'

interface Props {
  style?: any, // override component styles
  className?: string,
  cols?: Array<any>,
  onClick?: any,
  onTdClick?: any,
  mouseEnter?: any,
  mouseLeave?: any,
  id?: number
}

const TableRow: FC<Props> = ({
  style = {},
  className = '',
  cols = [],
  onClick,
  onTdClick,
  mouseEnter,
  mouseLeave,
  id,
  children
}) => {

  const _handleClick = (event) => {
    onClick && onClick(event, {id})
  }

  const _handleTdClick = (event) => {
    onTdClick && onTdClick(event, {id})
  }

  return (
    <tr
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={_handleClick}
      style={style}
      className={className}
    >
      {cols?.map((col, columnIndex) => (
        <td
          key={columnIndex}
          onClick={!col?.preventTdClick ? _handleTdClick : undefined}
          style={col?.style || {}}
          colSpan={col?.colSpan || null}
          className={col?.className || ''}
        >
          {col.data}
        </td>
      ))}
      {children}
    </tr>
  )
}

export default TableRow
