import { Typography } from '@material-ui/core'
import { FC, Fragment, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { loadLeistungenPrivat } from '../../actions/leistungenPrivat'
import CurrencyField from '../../components/CurrencyField/CurrencyField'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import InputField from '../../shared/components/InputField/InputField'
import { getCurrencyInEuros } from "../../utils/form"
import { StyledTerminAbrechnenForm } from './StyledTerminAbrechnenForm'
import { sBehandlungskosten, sTerminLeistungen, sViewTerminAbrechnenFormInitialValues } from './selectors'

export const fields = [
  'rezeptgebuehr',
  'leistungen[]',
  'produkte_und_sonstiges',
  'rabatt',
  'summe',
  'belegnummer',
  'withReceipt',
]

interface Props {
  actions: any,
  termin: any,
  initialLoadComplete: any,
  fields: any,
  addMode: any,
}

const TerminAbrechnenForm: FC<Props> = ({
  actions,
  termin,
  initialLoadComplete,
  fields: { rezeptgebuehr, leistungen, produkte_und_sonstiges, rabatt, summe, belegnummer, withReceipt },
  addMode,
  praxisstammdaten,
}) => {
  const [rezeptgebuehrDisabled, setRezeptgebuehrDisabled] = useState<boolean>(false);

  useEffect(() => {
    let newRezeptgebuehrDisabled: boolean = false
    if(termin) {
      newRezeptgebuehrDisabled = !termin.heilmittelverordnung
    }
    setRezeptgebuehrDisabled(newRezeptgebuehrDisabled);
  }, [termin])

  useEffect(() => {
    if(!initialLoadComplete) {
      actions.loadLeistungenPrivat();
    }
  }, [initialLoadComplete])

  if (!termin || !initialLoadComplete) {
    return null
  }

  return (
    <StyledTerminAbrechnenForm autoComplete="off">
      <div className="positionenRow">
        <Typography variant="body1" className={`${rezeptgebuehrDisabled ? "disabled" : ""}`}>
          Gesamt-Zuzahlung bei zugeordneter HVO
        </Typography>
        <CurrencyField
          {...rezeptgebuehr}
          disabled={rezeptgebuehrDisabled || !addMode}
          error={rezeptgebuehr.touched && !!rezeptgebuehr.error}
          helperText={rezeptgebuehr.touched && !!rezeptgebuehr.error ? rezeptgebuehr.error : ''}
          fullWidth
          endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
        />
        <br/>
        {/* --- */}
        <Typography variant="body1" paragraph>
          Preis zugeordneter Leistungen
        </Typography>
        {leistungen.map((leistung, idx) => <Fragment key={idx}>
          <Typography variant="body2">
            {
              (
              leistung?.value?.bezeichnung ||
              `${leistung.value.kuerzel} | ${leistung.value.beschreibung} | ${leistung.value.mehrwertsteuer} %`
              )
            }
          </Typography>
          {isFinite(leistung.value.verguetung) ? (
            <CurrencyField
              fullWidth
              error={leistung.touched && !!leistung.error}
              helperText={leistung.touched && !!leistung.error? leistung.error : ''}
              value={leistung.value.verguetung}
              onBlur={(verguetung) => leistung.onChange({ ...leistung.value, verguetung })}
              disabled={!addMode}
              endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
            />
          ) : (
            <Typography variant="body2" paragraph>
              0,00
            </Typography>
          )}
        </Fragment>
        )}
        <br/>
        {/* --- */}
        <Typography variant="body1">
          Verkauf Produkte und Sonstiges
        </Typography>
        <CurrencyField
          {...produkte_und_sonstiges}
          error={produkte_und_sonstiges.touched && !!produkte_und_sonstiges.error}
          helperText={
            produkte_und_sonstiges.touched && !!produkte_und_sonstiges.error ? produkte_und_sonstiges.error : ''
          }
          fullWidth
          selectOnFocus
          disabled={!addMode}
          endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
        />
        <br/>
        {/* --- */}
        <Typography variant="body1">
          Rabatt
        </Typography>
        <CurrencyField
          {...rabatt}
          error={rabatt.touched && !!rabatt.error}
          helperText={rabatt.touched && !!rabatt.error? rabatt.error : ''}
          fullWidth
          disabled={!addMode}
          endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
        />
        <br/>
        {/* --- */}
        <Typography variant="body1">
          Summe Brutto
        </Typography>
        <Typography variant="body2" className="summe">
          {summe.value != null && summe.value !== '' ? getCurrencyInEuros(summe.value) : ''}
        </Typography>
        <br/>
        {/* --- */}
        <Typography variant="body1">
          Belegnummer zur Buchung (<a
            href="https://pododesk.freshdesk.com/support/solutions/articles/17000086738-belegnummer-f%C3%BCr-kassenbuch-eintr%C3%A4ge"
            target="_blank"
            rel="noreferrer">
            Was ist das?
          </a>)
        </Typography>
        <br/>
        {/* --- */}
        <InputField
          // commented as the belegnummer is not to be mandatory untif further clarification
          disabled={!addMode}
          {...belegnummer}
          placeholder="Nr. des Beleges"
          inputProps={{ maxLength: 22 }}
        />
        <br/>
        {addMode && (
          <CheckboxField
            fullWidth
            label="Quittung erstellen"
            {...withReceipt} />
        )}
        {/* --- */}
      </div>
    </StyledTerminAbrechnenForm>
  )
};

const validate = (values) => {
  const errors: KeyValue<string> = {}
  if (values.produkte_und_sonstiges > 2147483647) {
    errors.produkte_und_sonstiges = 'Der Betrag ist zu hoch!'
  } else if (values.produkte_und_sonstiges < -2147483648) {
    errors.produkte_und_sonstiges = 'Der Betrag ist zu niedrig!'
  }

  // commented as the belegnummer is not to be mandatory untif further clarification
  // if (!values.belegnummer) {
  //  errors.belegnummer = 'Bitte Belegnummer angeben!'
  // }

  return errors
}

const mapStateToProps = (state, props) => ({
  busy: state.notification.busy,
  initialLoadComplete: state.initialLoadComplete.leistungenPrivat,
  praxisstammdaten: praxisstammdatenSelector(state),
  initialValues: {
    behandlung: sBehandlungskosten(state, props),
    summe: sBehandlungskosten(state, props),
    leistungen: sTerminLeistungen(state, props),
    withReceipt: false,
  }
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadLeistungenPrivat,
    },
    dispatch,
  )
})

export default reduxForm(
  {
    form: 'terminAbrechnenForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(TerminAbrechnenForm)

export const ViewTerminAbrechnenForm = reduxForm(
  {
    form: 'viewTerminAbrechnenForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  (state, props) => ({
    ...mapStateToProps(state, props),
    initialValues: sViewTerminAbrechnenFormInitialValues(state, props),
  }),
  mapDispatchToProps,
)(TerminAbrechnenForm)
