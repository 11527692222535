import { List, Paper } from '@material-ui/core'
import styled from 'styled-components'
import { theme } from '../../assets/theme'

export const AbbreviationSettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 0.75fr;
  padding: ${({ theme }) => theme.spacing(3)}px;
  grid-gap: ${({ theme }) => theme.spacing(3)}px;
  height: 100%;
`

export const AbbreviationSettingsSidebarContainer = styled(Paper)`
  width: 300px;
  padding: ${({ theme }) => theme.spacing(2)}px;

  .listItem {
    &:hover {
      background-color: ${theme.palette.primary.dark};
      cursor: pointer;
    }
  }

  .listItemActive {
    background-color: ${theme.palette.primary.main};
    &:hover {
      background-color: ${theme.palette.primary.dark};
      cursor: pointer;
    }
  }

  .att-import {
    display: none;
  }
`

export const AbbreviationList = styled(List)`
  .AbbreviationListHeader {
    font-size: large;
  }
`

export const AbbreviationSettingsFormContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`

export const AbbreviationSettingsFormInner = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(3)}px;
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const AbbreviationSettingsFormHeader = styled.h1`
  margin: 0;
`

export const AbbreviationSettingsFormButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(3)}px;
`
