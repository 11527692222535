import { createSelector } from 'reselect'
import { startbereichRoutes } from '../shared/utils/constants'
import { sortByNachnameVorname } from '../utils/persons'

export const sortedDoctorsSelector = createSelector(
  (state: StoreState) => state.entities.aerzte,
  (doctor) =>
    Object.values<any>(doctor)
      .sort(sortByNachnameVorname)
      .map((d: Doctor) => ({
        value: d.id,
        text: [d.vorname, d.nachname].filter(Boolean).join(' '),
      })),
)

export const kuerzelKrankenkassenSelector = createSelector(
  (state: StoreState) => state.krankenkassen,
  (krankenkassen) =>
    Array.prototype.concat(
      { value: null, text: '-' },
      Object.values(krankenkassen).map((k: Insurance) => ({
        value: k.id,
        text: k.kuerzel,
      })),
    ),
)

export const heilmittelSelector = createSelector(
  (state: StoreState) => state.leistungenKK,
  (heilmittel: LeistungenKK) =>
    Array.prototype.concat(
      { value: null, text: '-', indikationsschluessel: null },
      Object.values(heilmittel)
        .filter((e: InsuranceService) => e.istHeilmittel)
        .map((h: InsuranceService) => ({
          value: h.positionsnummer,
          text: h.beschreibung,
          indikationsschluessel: h.indikationsschluessel,
        })),
    ),
)

export const apiServerHashSelector = createSelector(
  () => window.location.pathname.split('/')[1],
  (hashSegment) => {
    if (startbereichRoutes.indexOf(hashSegment) === -1) {
      return hashSegment
    } else {
      return undefined
    }
  },
)

// returns the number of loaded entities
// TODO differently ?
export const dataLoadedSelector = createSelector(
  (state: StoreState) => state.initialLoadComplete,
  (initialLoadComplete) => {
    return Object.values(initialLoadComplete).reduce((prev, next) => (next ? ++prev : prev), 0)
  },
)

export const praxisstammdatenSelector = createSelector(
  (state: StoreState) => state.entities.praxisstammdaten,
  apiServerHashSelector,
  (ps, hash) => (hash ? ps[hash] : ps),
)

export const isSMSFeatureEnabled = createSelector(
  (state: StoreState) => state.auth.apiServers,
  apiServerHashSelector,
  (apiServers, hash) => apiServers.find(({ nameHash }) => nameHash === hash)?.smsFeature ?? false,
)

export const isCallFeatureEnabled = createSelector(
  (state: StoreState) => state.auth.apiServers,
  apiServerHashSelector,
  (apiServers, hash) => apiServers.find(({ nameHash }) => nameHash === hash)?.callFeature ?? false,
)
