import * as R from 'ramda'
import { createSelector } from 'reselect'
import { createSelectorN, uncurry } from '../../utils/functions'

const sTermin = (state, props) => props.termin
const sPatienten = (state) => state.entities.patienten

const sLeistungenKK = (state) => state.leistungenKK
const sLeistungenPrivat = (state) => state.entities.leistungenPrivat

export const sMaybeTerminPatient = createSelector(sTermin, sPatienten, (termin, patienten) =>
  termin && patienten ? patienten[termin.patient] : null,
)

const sTerminLeistungenProp = createSelector(sTermin, R.compose(R.defaultTo([]), R.prop('leistungen'), R.defaultTo({})))

export const sTerminLeistungen = createSelector(
  sTerminLeistungenProp,
  sLeistungenPrivat,
  sLeistungenKK,
  (terminLeistungen, leistungenPrivat, leistungenKK) => {
    return R.map(({ istKKLeistung, id, positionsnummer }) =>
      istKKLeistung ? leistungenKK[positionsnummer] : leistungenPrivat[id],
    )(terminLeistungen)
  },
)

export const sBehandlungskosten = createSelector(
  sTerminLeistungen,
  R.compose(R.sum, R.map(R.compose(R.defaultTo(0), R.prop('verguetung')))),
)

// :: State -> Object
const sZahlungspositionen = (state) => state.entities.zahlungspositionen
const sKassenbuchEintraege = (state) => state.entities.kassenbuch

// :: State -> [Zahlungsposition]
const sZahlungspositionenArray = createSelector(sZahlungspositionen, (zahlungspositionen) =>
  typeof zahlungspositionen === 'object' ? Object.values(zahlungspositionen) : [],
)
const sZahlungen = (state) => state.entities.zahlungen

const sTerminId = createSelector(sTermin, (termin) => (termin && termin.id ? termin.id : 0))

// :: State -> [Zahlungsposition]
const sZahlungenspositionenZuTermin = createSelector(
  sTerminId,
  sZahlungspositionenArray,
  (terminId, zahlungspositionen) =>
    Array.isArray(zahlungspositionen)
      ? zahlungspositionen.filter((zahlungsposition) => zahlungsposition.termin === terminId)
      : [],
)

// :: State -> Zahlung
const sZahlungZuTermin = createSelectorN(
  sZahlungenspositionenZuTermin,
  sZahlungen,
  R.compose(R.prop, R.prop('zahlung'), R.defaultTo({}), R.head),
)

const sKassenbuchEintragZuTermin = createSelector(sZahlungZuTermin, sKassenbuchEintraege, (zahlung, eintraege) => {
  const eintraegeArray = Object.values(eintraege)

  return eintraegeArray.filter((eintrag: FixMeLater) => eintrag.id === zahlung.buchung)[0]
})

// :: State -> Date
export const sZahlungsDatum = createSelector(sZahlungZuTermin, R.compose(R.prop('createdAt'), R.defaultTo({})))

// :: State -> Boolean
export const sTerminHatZahlung = createSelector(
  sZahlungenspositionenZuTermin,
  (zahlungspositionenZuTermin) => zahlungspositionenZuTermin.length !== 0,
)

// :: String -> ([Zahlungsposition] -> [Zahlungsposition])
const positionenVonTyp = R.compose(R.filter, R.propEq('positionstyp'))

// :: String -> [Zahlungsposition] -> Integer
const betragErstePositionVonTypOderNull = R.compose(
  R.defaultTo(0),
  R.prop('betrag'),
  R.defaultTo({}),
  R.head,
  uncurry(positionenVonTyp),
)

// :: State -> Object
export const sViewTerminAbrechnenFormInitialValues = createSelector(
  sTermin,
  sZahlungenspositionenZuTermin,
  sKassenbuchEintragZuTermin,
  (termin, zahlungspositionen, kassenbucheintrag: FixMeLater) => {
    // TODO: implementieren!

    return {
      rezeptgebuehr: betragErstePositionVonTypOderNull('rezeptgebuehr', zahlungspositionen),
      leistungen: R.compose(
        R.map(({ betrag, bezeichnung }) => ({
          bezeichnung,
          verguetung: betrag,
        })),
        positionenVonTyp('leistung'),
      )(zahlungspositionen),
      produkte_und_sonstiges: betragErstePositionVonTypOderNull('produkte_und_sonstiges', zahlungspositionen),
      rabatt: betragErstePositionVonTypOderNull('rabatt', zahlungspositionen),
      belegnummer: kassenbucheintrag.belegnummer,
    }
  },
)
