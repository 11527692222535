import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.text.primary};
    cursor: pointer;
    transition: background-color .5s;

    .label {
      font-size: calc(12px + 1vw);
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .labelContent {
      margin: 10px;
    }

    .hoverText {
      visibility: hidden;
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .hoverTextContent {
      margin: 5px;
    }

    &:hover {
      background-color: ${theme.palette.primary.dark};
      .hoverText {
        visibility: visible;
      }
    }
  `}
`;

export const StyledButton = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.text.primary};
    cursor: pointer;
    transition: background-color .5s;

    .label {
      font-size: calc(12px + 1vw);
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .labelContent {
      margin: 10px;
    }

    .hoverText {
      visibility: hidden;
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .hoverTextContent {
      margin: 5px;
    }

    &:hover {
      background-color: ${theme.palette.primary.dark};
      .hoverText {
        visibility: visible;
      }
    }
  `}
`;
