import styled from 'styled-components';

export const StyledCalendarColumnHeaderPerson = styled.div`
  ${({ person }: { person: {farbe: string} }) => `
    height: 100%;
    padding-bottom: 9px;
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;

    .flavorPersonName {
      position: relative;
      text-transform: uppercase;
      display: block;
      height: 100%;
      box-sizing: border-box;
      border-bottom: 5px solid ${person?.farbe ?? 'grey'};
      color: ${person?.farbe ?? 'grey'};

      &.avatar {
        padding-left: 48px;
      }
    }

    .flavorPersonAvatar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  `}
`;

export const StyledCalendarColumnHeaderPersonSmall = styled.div`
  ${({ person }: { person: {farbe: string} }) => `
    height: 32px;
    align-self: flex-end;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 5px;
    box-sizing: border-box;

    .flavorPersonSmallName {
      text-transform: uppercase;
      display: block;
      height: 100%;
      box-sizing: border-box;
      border-bottom: 5px solid ${person?.farbe ?? 'grey'};
      color: ${person?.farbe ?? 'grey'};
      text-align: center;
      font-size: 20px;
    }
  `}
`;

export const StyledCalendarColumnHeaderRoom = styled.div`
  ${({ room }: { room: {farbe: string} }) => `
    height: 32px;
    align-self: flex-end;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 5px;
    box-sizing: border-box;

    .flavorRoomName {
      text-transform: uppercase;
      display: block;
      height: 100%;
      box-sizing: border-box;
      border-bottom: 5px solid ${room?.farbe ?? 'grey'};
      color: ${room?.farbe ?? 'grey'};
      text-align: center;
      font-size: 20px;
    }
  `}
`;

export const StyledCalendarColumnHeaderDate = styled.div`
  height: 100%;
  padding-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  position: relative;

  .flavorDateInfo {
    display: flex;
    flex-direction: column;
  }
  .flavorDateDayName {
    font-size: 9px;
    text-align: center;
  }
  .flavorDateDayNum {
    font-size: 35px;
    line-height: 35px;
    text-align: center;
  }
  .flavorArrowIcon {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 20px;
    &--left {
      left: -15px;
    }
    &--right {
      right: -15px;
    }
  }
`;

export const StyledCalendarColumnHeaderWeek = styled.div`
  font-size: 14px;
  box-sizing: border-box;
`;

export const StyledCalendarColumnHeaderDay = styled.div`
  box-sizing: border-box;
  font-size: 11px;
  width: 100%;
  z-index: 1;

  .flavorDayText {
    display: block;
  }
`;

export const StyledCalendarColumnHeaderDaySmall = styled.div`
  box-sizing: border-box;
`;

export const StyledCalendarColumnHeaderDayFreeDates = styled.div`
  ${({ computedWidth }: { computedWidth: number }) => `
    box-sizing: border-box;
    font-size: 11px;
    width: ${computedWidth * 100}%;
    z-index: 1;
    position: absolute;
    top: 0;

    .flavorDayFreeDatesText {
      display: block;
      white-space: nowrap;
    }
  `}
`
