/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  T1 interface wrapper.
 *
 *  history:
 *  *.2021 bwe                    created
 *  *.2021 tpe                    resync for card terminal added
 */

import "../c.js";// eslint-disable-line 

export enum Sad {
  host = 0x02,
  remote_host = 0x05
}

export enum Dad {
  icc1 = 0x00,
  ct = 0x01,
  icc2 = 0x02,
  icc3 = 0x03,
  icc4 = 0x04,
  icc5 = 0x05,
  icc6 = 0x06
}

export class TerminalData {
  dad: Dad;
  sad: Sad;
  command: Uint8Array;

  constructor(dad: Dad, sad: Sad, command: Uint8Array) {
    this.dad = dad;
    this.sad = sad;
    this.command = command;
  }
}

/**
 * Print a buffer to console as hex values in format "0x01:02:03:..."
 */
export function printBufferHex(printArray: Uint8Array): string
{
  const a = [];
  // Convert raw data bytes to hex values just for the sake of showing something.
  // In the "real" world, you'd use data.getUint8, data.getUint16 or even
  // TextDecoder to process raw data bytes.
  for (let i = 0; i < printArray.byteLength; i++) {
    a.push(':' + ('00' + printArray[i].toString(16)).slice(-2));
  }
  const hexString = '0x' + a.join('');
  return hexString;
}

/**
 * The actual Transmit Interface for sending/receiving data. This needs to be implemented somewhere else, because it is hardware dependent.
 */
export interface T1_transmission_interface {
  send: (data: Uint8Array) => boolean;
  is_data_available: () => boolean;
  getMsgByte: () => number;
}

class T1_Buffer{
  static tx_buf_len = 4096;  
  static rx_buf_len = 4096;

  // Allocate memory for ccall('send_message')
  readonly p_dad = (Module as any)._malloc(1); // pointer to dad. Length 1 Byte
  readonly p_sad = (Module as any)._malloc(1); // pointer to sad. Length 1 Byte
  readonly p_cmd = (Module as any)._malloc(T1_Buffer.tx_buf_len); // pointer to command buffer. Length see 'cmd_len'
  readonly rsp_len = 1024; // Length of the response buffer
  readonly p_rsp = (Module as any)._malloc(T1_Buffer.rx_buf_len); // pointer to response buffer. Length see 'rsp_len'
  readonly p_rsp_len = (Module as any)._malloc(2);  // Pointer to response length. Length is 2 Bytes.

  cmd_len = 0;


  set_tx_buf(data: TerminalData): void{
    this.cmd_len = data.command.length;

    this.init_rx_buf();

    // Fill the transmit buffer with a T1 command
    Module.setValue(this.p_dad, data.dad);
    Module.setValue(this.p_sad, data.sad);
    Module.writeArrayToMemory(data.command, this.p_cmd);
  }

  private init_rx_buf (): void {
    // Fill buffer 'response length'
    const rsp_len_array = [(T1_Buffer.rx_buf_len & 0xFF), (T1_Buffer.rx_buf_len & 0xFF00) >> 8];
    (Module as any).writeArrayToMemory(rsp_len_array, this.p_rsp_len);
  }
}

export enum T1_Return_Code{
  OK = 0,             // No error
  ERR_CT = -8,        // Card terminal error
  ERR_TRANS_1 = -10 , /* Communication error. error because of mechanical, electrical or protocol
                         specific fault. New ct initialization has to  be done. */
  ERR_TRANS_2 = -120 ,/* A try to resync / reset the ct has been done but the ct did not       
                         answer correctly. Perhaps a second try will be successful. */
  ERR_HTSI = -128,    // 'HTSI'-error. 
}

export class T1_host_if {
  public data_transmission: T1_transmission_interface;         // This functions gets called if we want to transmit data (over the air)
  private t1_buffer: T1_Buffer;
  private transmitBuffer: Uint8Array| null = null;

  constructor(transmission_interface: T1_transmission_interface) {
    console.log("t1_host_interface.ts: constructor()");
    this.data_transmission = transmission_interface;

    this.t1_buffer = new T1_Buffer();

    // Init t1 stack
    Module.ccall('init_t1', 'number', "null", "null"); 
  }

  public async resync(): Promise<void> {
    Module.ccall('resynch_ct', 'number', [], [], {async: true}).then((result: number) => { 
      console.log("resynch_ct: " + result);
    });
  }

  public async send_async(
    data: TerminalData, 
    commandName?: string
  ): Promise<[rxData: Uint8Array, return_code: number]> {

    if(commandName)
    {
      console.log("%cT1 command " +commandName, 'color:DodgerBlue'); 
    }else{/* Do not print the command name */}
    console.log("[sad=" +data.sad.toString(16) +"] [dad="  + data.dad.toString(16) +'] [len=' + this.t1_buffer.cmd_len.toString(16) +'] ['+ printBufferHex(data.command) +']');

    // Initialize buffer with the command that will be send
    this.t1_buffer.set_tx_buf(data);

    // Send the command
    const send_result = await Module.ccall('send_message',   // Name of the c function
      'number',     // return type int8_t = 'number'
      [             // Function parameter types:
        'number',   // parameter 'uint8_t  * dad'
        'number',   // parameter 'uint8_t  * sad'
        'number',   // parameter 'uint16_t   lc'
        'number',   // parameter 'uint8_t  * cmd'
        'number',   // parameter 'uint16_t * lr'
        'number',   // parameter 'uint8_t  * rsp'
      ],
      [this.t1_buffer.p_dad, this.t1_buffer.p_sad, this.t1_buffer.cmd_len, this.t1_buffer.p_cmd, this.t1_buffer.p_rsp_len, this.t1_buffer.p_rsp], // function parameters
      {async: true} 
    );
    
    console.log("send_message(): " + send_result);

    let response = null;
    if(send_result == T1_Return_Code.OK)
    {
      // Get received data
      response = T1_host_if.buildResponse(this.t1_buffer.p_rsp, this.t1_buffer.p_rsp_len, T1_Buffer.rx_buf_len);

      // Debug message
      if(commandName)
      {
        console.log("%cRespone from " +commandName +" = " + printBufferHex(response), 'color:DodgerBlue');
      }
    }else{// Error
      throw new Error('t1_send_return_code ' + this.send_async.name);
    }

    // call callback
    console.log("t1_host_interface.send() has finished. Call callback with received response.");
    
    return [response, send_result];
  }

  /**
   * Build array with response data received from t1 interface.
   * @param p_rsp       [in] Pointer to buffer with the response data
   * @param p_rsp_len   [in] Pointer to buffer with the length of the response
   * @param buf_len     [in] Max. length of the response data.
   * @returns           Array with the response
   */
  private static buildResponse(p_rsp: number, p_rsp_len: number, buf_len: number): Uint8Array
  {
    // Get length data bytes
    const length_byte_1 = Module.HEAPU8.at(p_rsp_len + 1) as number;
    const length_byte_2 = Module.HEAPU8.at(p_rsp_len) as number;

    // Build length
    let length = (length_byte_1 << 8) + length_byte_2;


    // Clamp length value
    if(length > buf_len){
      length = buf_len;
      console.error("Length error in t1_buffer.p_rsp_len");
    }else{/* Calculated length is in range of buffer lenght. */}

    // Auslesen der empfangenen nachricht in ein array schieben
    const response_array = Module.HEAPU8.subarray(p_rsp, p_rsp + length) as Uint8Array;
    return response_array;
  }

  /** ===============================================================================================
   * Callbacks from WebAssembly (t1.c)
   * 
   *@note This functions are called from t1.c => index.html => t1_thost_interface.ts
   *      In the feature this functions could be called directly from t1.c using function pointers.
   *      Then the wrapper functions in index.html are obsolete.
   */
  public t1_init_tx_buffer (size: number): void
  {
    this.transmitBuffer = new Uint8Array(size);
  }

  // Send function to be called from c code
  public t1_set_tx_buffer ( index: number, value: number): void {

    if(this.transmitBuffer)
    {
      this.transmitBuffer[index] = value;
    }else{console.error("Call t1_init_tx_buffer() before t1_set_tx_buffer()!")}
  }

  public t1_transmit_data(): boolean{

    if(this.transmitBuffer)
    {
      return this.data_transmission.send(this.transmitBuffer);
    }else{
      console.error("Call t1_init_tx_buffer() before t1_transmit_data()!");
      return false;
    }
    
  }

  public t1_isDataAvailable(): boolean
  {
    return this.data_transmission.is_data_available();
  }

  public t1_getReceivedData(): number{
    return this.data_transmission.getMsgByte();
  }

}// class T1_host_if
  