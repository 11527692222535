import { cloneDeep } from 'lodash'
import { CardReader_VSD } from './card_reader/card_reader_VSD'
import { CardTerminal } from './card_reader/card_terminal'
import { Dad, Sad, T1_host_if, T1_transmission_interface } from './card_reader/t1_host_interface'
import { BT_connection_state, Orga_care_WebBluetooth_interface } from './orga_930_care/web_bluetooth_interface'
import { parse, startOfDay } from 'date-fns'
import { de } from 'date-fns/locale'


interface UnsortedCardData {
    personalData: {
        Versicherter: {
          Versicherten_ID: string,
          Person: {
            Geburtsdatum: string,
            Vorname: string,
            Nachname: string,
            Geschlecht: string,
            StrassenAdresse: {
              Postleitzahl: string,
              Ort: string,
              Land: {
                Wohnsitzlaendercode: string,
              },
              Strasse: string,
              Hausnummer: string,
            },
          },
        },
      },
      generalData: {
        Versicherter: {
          Versicherungsschutz: {
            Beginn: string,
            Kostentraeger: {
              Kostentraegerkennung: string,
              Kostentraegerlaendercode: string,
              Name: string,
              AbrechnenderKostentraeger: {
                Kostentraegerkennung: string,
                Kostentraegerlaendercode: string,
                Name: string,
              },
            },
          },
          Zusatzinfos: {
            ZusatzinfosGKV: {
              Versichertenart: string,
              Zusatzinfos_Abrechnung_GKV: {
                WOP: string,
              },
            },
          },
        },
      },
      securedData: {
        Zuzahlungsstatus: {
          Status: string,
        },
        Selektivvertraege: {
          Aerztlich: string,
          Zahnaerztlich: string,
        },
      },
}

export interface SortedCardData {
    versichertennummer: string
    geburtsdatum: Date
    vorname: string
    nachname: string
    geschlecht: 'female' | 'male' | 'divers'
    strasse: string
    hausnummer: string
    postleitzahl: string
    ort: string
    land: string
    krankenkasse: string
    ikNummerKK: string
}

// ** readCardData example response
const testData = {
    personalData: {
      Versicherter: {
        Versicherten_ID: 'Z941673908',
        Person: {
          Geburtsdatum: '19911023',
          Vorname: 'David',
          Nachname: 'Getto',
          Geschlecht: 'M',
          StrassenAdresse: {
            Postleitzahl: '67071',
            Ort: 'Ludwigshafen am Rhein',
            Land: {
              Wohnsitzlaendercode: 'D',
            },
            Strasse: 'In den Weiherg�rten',
            Hausnummer: '24',
          },
        },
      },
    },
    generalData: {
      Versicherter: {
        Versicherungsschutz: {
          Beginn: '20120101',
          Kostentraeger: {
            Kostentraegerkennung: '106492393',
            Kostentraegerlaendercode: 'D',
            Name: 'Pronova BKK',
            AbrechnenderKostentraeger: {
              Kostentraegerkennung: '106492393',
              Kostentraegerlaendercode: 'D',
              Name: 'Pronova BKK',
            },
          },
        },
        Zusatzinfos: {
          ZusatzinfosGKV: {
            Versichertenart: '1',
            Zusatzinfos_Abrechnung_GKV: {
              WOP: '51',
            },
          },
        },
      },
    },
    securedData: {
      Zuzahlungsstatus: {
        Status: '0',
      },
      Selektivvertraege: {
        Aerztlich: '9',
        Zahnaerztlich: '9',
      },
    },
  }

enum ConnectionState {
  disconnected = 0,
  connected = 1,
  readingVSD = 2,
  showData = 3,
}

type OnDisconnectFunc = (...params) => any
type OnConnectFunc = (...params) => any
type OnReadStartFunc = (...params) => any
type OnReadEndFunc = (...params) => any

interface CardReaderInstanceParams {
  onDisconnected?: OnDisconnectFunc
  onConnected?: OnConnectFunc
  onReadStart?: OnReadStartFunc
  onReadEnd?: OnReadEndFunc
}

const nodeNameMapping = {
  UC_PersoenlicheVersichertendatenXML: 'personalData',
  UC_AllgemeineVersicherungsdatenXML: 'generalData',
  UC_GeschuetzteVersichertendatenXML: 'securedData',
}

const xmlToObject = (src: Document | Element) => {
  let children = Array.from(src.children)

  if (!children?.length) {
    return (src as Element).innerHTML
  }

  let result = {}

  for (let child of children) {
    let isChildArray = children.filter((eachChild) => eachChild.nodeName === child.nodeName).length > 1

    if (isChildArray) {
      if (result[child.nodeName] === undefined) {
        result[child.nodeName] = [xmlToObject(child)]
      } else {
        result[child.nodeName].push(xmlToObject(child))
      }
    } else {
      result[child.nodeName] = xmlToObject(child)
    }
  }

  return result
}

const cleanUpPropertyNames = (data: { card_data: Object }) => {
  const clonedData = cloneDeep(data.card_data)
  const keys = Object.keys(clonedData)

  const result = {}

  keys.forEach((key) => {
    result[nodeNameMapping[key]] = clonedData[key]
  })

  return result
}

const mapCardData = (data: UnsortedCardData): SortedCardData => ({
    versichertennummer: data.personalData.Versicherter.Versicherten_ID,
    geschlecht: data.personalData.Versicherter.Person.Geschlecht === 'M' ? 'male' : 'female',
    vorname: data.personalData.Versicherter.Person.Vorname,
    nachname: data.personalData.Versicherter.Person.Nachname,
    geburtsdatum: parse(data.personalData.Versicherter.Person.Geburtsdatum, 'yyyyMMdd', new Date()),
    strasse: data.personalData.Versicherter.Person.StrassenAdresse.Strasse,
    hausnummer: data.personalData.Versicherter.Person.StrassenAdresse.Hausnummer ?? null,
    postleitzahl: data.personalData.Versicherter.Person.StrassenAdresse.Postleitzahl,
    ort: data.personalData.Versicherter.Person.StrassenAdresse.Ort,
    land: data.personalData.Versicherter.Person.StrassenAdresse.Land.Wohnsitzlaendercode,
    krankenkasse: data.generalData.Versicherter.Versicherungsschutz.Kostentraeger.Name,
    ikNummerKK: data.generalData.Versicherter.Versicherungsschutz.Kostentraeger.Kostentraegerkennung,
})

export class CardReaderInstance {
  connectionState: number
  bluetoothInterface: Orga_care_WebBluetooth_interface
  hostInterface: T1_host_if
  onDisconnected?: OnDisconnectFunc
  onConnected?: OnConnectFunc
  onReadStart?: OnReadStartFunc
  onReadEnd?: OnReadEndFunc

  constructor({ onDisconnected, onConnected, onReadStart, onReadEnd }: CardReaderInstanceParams) {
    this.bluetoothInterface = new Orga_care_WebBluetooth_interface(this.onDisconnect)
    this.onDisconnected = onDisconnected
    this.onConnected = onConnected
    this.onReadStart = onReadStart
    this.onReadEnd = onReadEnd

    const bluetoothConnectionToOrgaCare: T1_transmission_interface = {
      send: (data: Uint8Array): boolean => {
        this.bluetoothInterface.send(data)
        return true
      },
      is_data_available: (): boolean => this.bluetoothInterface.is_data_available(),
      getMsgByte: (): number => this.bluetoothInterface.getMsgByte(),
    }

    this.hostInterface = new T1_host_if(bluetoothConnectionToOrgaCare)
    this.connectionState = ConnectionState.disconnected

    console.log('CardReaderInstance constructor done')
  }

  private updateConnectionState = (newConnectionState: ConnectionState) => {
    this.connectionState = newConnectionState
  }

  private onDisconnect = (): void => {
    console.log('CardReaderInstance onDisconnected')

    if (this.connectionState !== ConnectionState.disconnected) {
      this.updateConnectionState(ConnectionState.disconnected)
    }

    if (this.onDisconnected) {
      this.onDisconnected()
    }
  }

  public connectToCardReader = async (): Promise<void> => {
    console.log('CardReaderInstance connectToCardReader')

    if (this.connectionState !== ConnectionState.connected) {
      const cState = await this.bluetoothInterface.connect((careIsReady: boolean) => {
        if (!careIsReady) {
          alert('Das Care hat im angegebenen Zeitraum nicht geantwortet.')
          return
        }

        this.hostInterface.resync()
      })

      switch (cState) {
        case BT_connection_state.connected:
          this.updateConnectionState(ConnectionState.connected)

          if (this.onConnected) {
            this.onConnected()
          }

          console.log('Successfully connected to card reader')
          break

        case BT_connection_state.bluetooth_adapter_not_available:
          alert('Bluetooth adapter not available')
          console.log('Bluetooth adapter not available')
          break

        case BT_connection_state.web_bluetooth_not_available:
          alert('Web Bluetooth not available')
          console.log('Web Bluetooth not available')
          break

        case BT_connection_state.canceled_by_user:
          alert('Connection cancelled by user')
          console.log('Connection cancelled by user')
          break

        default:
          alert('Unknown error while connecting to card reader')
          console.log('Unknown error while connecting to card reader')
          break
      }
    } else {
      console.log('Already connected to card reader')
    }
  }

  public disconnectFromCardReader = (): void => {
    console.log('CardReaderInstance disconnectFromCardReader')

    if (this.connectionState !== ConnectionState.disconnected) {
      this.bluetoothInterface.disconnect()
      console.log('Successfully disconnected from card reader')
    } else {
      console.log('Already disconnected from card reader')
    }
  }

  public readCardData = async (): Promise<SortedCardData | void> => {
    console.log('public readCardData called')
    console.log('this.connectionState: ', this.connectionState)
    switch (this.connectionState) {
      case ConnectionState.connected:
      case ConnectionState.showData:
        console.log('connectionState is showData')
        this.updateConnectionState(ConnectionState.readingVSD)
        
        if (this.onReadStart) this.onReadStart()

        const maxReadingTime = 30 * 1000 // 30 seconds
        window.setTimeout(() => {}, maxReadingTime)

        const cardTerminal = new CardTerminal(Sad.host, this.hostInterface)
        const cardReaderVSD = new CardReader_VSD(Dad.icc1, this.hostInterface)

        try {
          console.log('before cardTerminal.init')
          await cardTerminal.init()
          console.log('after cardTerminal.init')

          const data = await cardReaderVSD.read_VSD()
          const cleanedData = `<?xml version="1.0" encoding="ISO-8859-15" standalone="yes"?>\n<card_data>${data.replace(
            /<\?xml version="1\.0" encoding="ISO-8859-15" standalone="yes"\?>/gm,
            '',
          )}</card_data>`.replace(/vsdp:/gm, '').replace(/vsda:/gm, '')

          console.log('DEBUG data: ', data)
          console.log('DEBUG cleanedData: ', cleanedData)

          const dom = new DOMParser().parseFromString(cleanedData, 'application/xml')

          console.log('DEBUG dom: ', dom)

          const xmlDataAsObject = xmlToObject(dom)
          console.log('DEBUG xmlDataAsObject: ', JSON.stringify(xmlDataAsObject))
          const result = mapCardData(cleanUpPropertyNames(xmlDataAsObject) as UnsortedCardData)
          console.log('DEBUG result: ', result)

          this.updateConnectionState(ConnectionState.showData)

          if (this.onReadEnd) this.onReadEnd()

          return result
        } catch (err) {
          if (err instanceof Error) {
            const errorMessage = err.toString()
            if (errorMessage.includes('card_reader_betriebsart')) {
              alert(
                `Das Orga 930 care befindet sich in der falschen Betriebsart. Bitte auf Betriebsart stationär wechseln. \n\n Error code = {${errorMessage}}`,
              )
            } else if (
              errorMessage.includes('t1_send_return_code') ||
              errorMessage.includes('t1_response_length') ||
              errorMessage.includes('t1_unknown_response_code')
            ) {
              alert(`Kommunikationsfehler. Bitte erneut versuchen. \n\n Error code = {${errorMessage}}`)
            } else if (errorMessage.includes('request_icc_timeout')) {
              alert(
                `Fehler: Die Karte wurde nicht eingesteckt. Bitt Karte in den Kartenleser einstecken. \n\n Error code = {${errorMessage}}`,
              )
            } else {
              alert(`Unbekannter Fehler. Bitte erneut versuchen. \n\n Error = ${err}`)
            }
          } else {
            alert(`Unbekannter Fehler. Bitte erneut versuchen. \n\n Error = ${err}`)
          }

          if (this.onReadEnd) this.onReadEnd()
        }

        break

      case ConnectionState.disconnected:
        alert('Bitte zuerst mit dem Kartenleser verbinden')
        console.log('Please connect to card reader first')
        break
    }
  }

  public getT1Interface = (): T1_host_if => this.hostInterface

  public getBluetoothInterface = (): Orga_care_WebBluetooth_interface => this.bluetoothInterface
}
