import * as R from 'ramda'
import { createSelector } from 'reselect'
import { praxisstammdatenSelector } from '../../selectors/selectors'

// select ics sources as map
// id -> data
export const sIcsSourcesMap = createSelector(
  praxisstammdatenSelector,
  R.compose(
    R.reduce(
      (prev, source) => ({
        ...prev,
        [source.id]: source,
      }),
      {},
    ),
    R.defaultTo([]),
    R.path(['icsSources']),
  ),
)
