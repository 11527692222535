import { LocalHospital as LocalHospitalIcon } from '@material-ui/icons'
import { memo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { loadCustomer } from '../../actions/customers'
import { createTherapiebericht } from '../../actions/therapieberichte'
import PatientText from '../../components/PatientText/PatientText'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import TherapieberichtForm from '../TherapieberichtForm/TherapieberichtForm'

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const AddTherapiebericht = ({
  actions,
  patienten,
  people,
  doctors,
  heilmittelverordnungen,
  heilmittelverordnungsIds,
}) => {
  const params = useParams();

  const loadCustomer = useCallback(async () => {
    await actions.loadCustomer(params.patient, true)
  }, [actions.loadCustomer, params.patient])

  useEffect(() => {
    loadCustomer()
  }, [])

  const saveForm = useCallback((data) => {
    data.heilmittelverordnung = !data.heilmittelverordnung || data.heilmittelverordnung === 'null'
      ? null
      : data.heilmittelverordnung;
    actions.createTherapiebericht(data)
  }, [actions.createTherapiebericht])

  if (!params.patient || !patienten || !patienten[params.patient] || !people || !doctors || !heilmittelverordnungen) {
    return null
  }

  const patient = patienten[params.patient]

  return (
    <StyledDiv>
      <IconHeading
        icon={<LocalHospitalIcon />}
        text={
          <span>
            Therapiebericht neu anlegen | <PatientText patient={patient} showGender />
          </span>
        }
      />
      <TherapieberichtForm
        customer={patient}
        people={people}
        doctors={doctors}
        heilmittelverordnungen={heilmittelverordnungen}
        heilmittelverordnungsIds={heilmittelverordnungsIds}
        initialValues={{
          patient: patient.id,
          heilmittelverordnung: null,
        }}
        onSubmit={saveForm}
      />
    </StyledDiv>
  )
}

const mapStateToProps = (state) => ({
  patienten: state.entities.patienten,
  doctors: state.entities.aerzte,
  people: state.entities.users,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  heilmittelverordnungsIds: state.ids.heilmittelverordnungen,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCustomer: bindActionCreators(loadCustomer, dispatch),
    createTherapiebericht: bindActionCreators(createTherapiebericht, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddTherapiebericht))
