import MenuItemsIcsItem from './MenuItemsIcsItem';
import { StyledContextMenuIcsItem } from './StyledContextMenuIcsItem';

const ContextMenuIcsItem = ({
  pointerLocation: { mouseX, mouseY },
  handleClose,
  icsItem
}) => {

  return (
    <StyledContextMenuIcsItem
      keepMounted
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <div>
        <MenuItemsIcsItem
          item={icsItem}
          onCloseFunc={handleClose}/>
      </div>
    </StyledContextMenuIcsItem>
  )
};

export default ContextMenuIcsItem
