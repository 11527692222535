import * as types from '../constants/actionTypes'

export default function totalSearchResults(
  state = {
    aerzte: null,
    anamnesen: null,
    dateien: null,
    dokumentationen: null,
    leistungenPrivat: null,
    heilmittelverordnungen: null,
    patienten: null,
    praxisstammdaten: null,
    termine: null,
    therapieberichte: null,
    users: null,
  },
  action = undefined,
) {
  if (action.json) {
    switch (action.type) {
      case types.FIND_CUSTOMERS_SUCCESS:
        return {
          ...state,
          patienten: action.json.patienten,
        }

      default:
    }
  }
  return state
}
