import styled from 'styled-components';

export const StyledDataLoading = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  .circularProgress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
  }

  .dataLoadingText {
    margin-top: 15px;
    font-weight: lighter;
    font-size: 26px;
  }
`;
