import * as types from '../constants/actionTypes'

export default function todosData(
  state = {
    isTodosDataLoading: {
      dokumentation_erfassen: false,
      anamnese_erstellen: false,
      folgetermin_vereinbaren: false,
      vervollstaendigung_patientenkartei: false,
      rezept_abrechnen: false,
      therapiebericht_erstellen: false,
    },
  },
  action = undefined,
) {
  switch (action.type) {
    case types.LOAD_TODOS_DATA_DOCUMENTATION:
      return {
        ...state,
        isTodosDataLoading:  {
          dokumentation_erfassen: true,
        },
      }

    case types.LOAD_TODOS_DATA_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          dokumentation_erfassen: false,
        },
      }

    case types.LOAD_TODOS_DATA_DOCUMENTATION_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          dokumentation_erfassen: false,
        },
      }

    case types.LOAD_TODOS_DATA_ANAMNESE:
      return {
        ...state,
        isTodosDataLoading:  {
          anamnese_erstellen: true,
        },
      }

    case types.LOAD_TODOS_DATA_ANAMNESE_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          anamnese_erstellen: false,
        },
      }

    case types.LOAD_TODOS_DATA_ANAMNESE_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          anamnese_erstellen: false,
        },
      }

    case types.LOAD_TODOS_DATA_FOLGETERMIN:
      return {
        ...state,
        isTodosDataLoading:  {
          folgetermin_vereinbaren: true,
        },
      }

    case types.LOAD_TODOS_DATA_FOLGETERMIN_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          folgetermin_vereinbaren: false,
        },
      }

    case types.LOAD_TODOS_DATA_FOLGETERMIN_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          folgetermin_vereinbaren: false,
        },
      }

    case types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG:
      return {
        ...state,
        isTodosDataLoading:  {
          vervollstaendigung_patientenkartei: true,
        },
      }

    case types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          vervollstaendigung_patientenkartei: false,
        },
      }

    case types.LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          vervollstaendigung_patientenkartei: false,
        },
      }

    case types.LOAD_TODOS_DATA_REZEPT:
      return {
        ...state,
        isTodosDataLoading:  {
          rezept_abrechnen: true,
        },
      }

    case types.LOAD_TODOS_DATA_REZEPT_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          rezept_abrechnen: false,
        },
      }

    case types.LOAD_TODOS_DATA_REZEPT_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          rezept_abrechnen: false,
        },
      }

    case types.LOAD_TODOS_DATA_THERAPIEBERICHT:
      return {
        ...state,
        isTodosDataLoading:  {
          therapiebericht_erstellen: true,
        },
      }

    case types.LOAD_TODOS_DATA_THERAPIEBERICHT_SUCCESS:
      return {
        ...state,
        isTodosDataLoading:  {
          therapiebericht_erstellen: false,
        },
      }

    case types.LOAD_TODOS_DATA_THERAPIEBERICHT_FAILURE:
      return {
        ...state,
        isTodosDataLoading: {
          therapiebericht_erstellen: false,
        },
      }

    default:
  }

  return state
}
