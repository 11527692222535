import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import lodash from 'lodash'
import { FC, useEffect, useState } from 'react'

interface Props {
  options: Array<AutocompleteOption>
  onChange?: (value: Array<any>) => void
  value?: Array<any>
  initialValue?: Array<any>
  className?: string
  label?: string
  fullWidth?: boolean
  error?: boolean
  helperText?: string | JSX.Element
  InputProps?: any
  autoComplete?: string
  disableCloseOnSelect?: boolean
}

const MultipleAutocompleteField: FC<Props> = ({
  options = [],
  onChange,
  value = [],
  initialValue = [],
  className = '',
  label = null,
  fullWidth = true,
  error,
  helperText,
  InputProps = {},
  autoComplete = 'new-password',
  disableCloseOnSelect = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<AutocompleteOption>>([])

  // set selectedOptions based on value array.
  useEffect(() => {
    const newSelectedOptions = new Array<AutocompleteOption>()
    if (value && Array.isArray(value)) {
      value.forEach((item) => {
        const option = options.find((option) =>
          option?.value?.istKKLeistung
            ? lodash.isEqual(option?.value?.positionsnummer, item.positionsnummer)
            : lodash.isEqual(option?.value, item),
        )

        if (option) {
          newSelectedOptions.push(option)
        }
      })
    } else if (value && typeof value === 'string') {
      onChange && onChange([value])
      const option = options.find((option) => lodash.isEqual(option?.value, value))
      if (option) {
        newSelectedOptions.push(option)
      }
    }
    setSelectedOptions(newSelectedOptions)
  }, [value, options])

  return (
    <Autocomplete
      disableCloseOnSelect={disableCloseOnSelect}
      fullWidth={fullWidth}
      multiple
      className={className}
      value={selectedOptions}
      options={options}
      getOptionLabel={(option) => option.text}
      getOptionSelected={(option, item) => {
        return lodash.isEqual(option?.value, item?.value)
      }}
      getOptionDisabled={(option) => {
        console.log('option: ', option)
        console.log('option disabled: ', option.unselectable)
        return !!option.unselectable //&& !!option.undeleteable
      }}
      ChipProps={{ size: 'small' }}
      onChange={(_, selectedValues) => {
        const values = (selectedValues || []).map((item) => item.value)
        onChange && onChange(values)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...InputProps,
            ...params.InputProps,
            autoComplete,
          }}
        />
      )}
    />
  )
}
export default MultipleAutocompleteField
