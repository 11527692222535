import { createSelector } from 'reselect'
import * as R from 'ramda'
import { praxisstammdatenSelector } from '../../selectors/selectors'

const sUsers = (state) => state.entities.users
// TODO find out where used & if ok rewrite into simpler
export const sCurrentUserApi = createSelector(
  (state) => Object.values(state.auth.users)[0] && Object.values(state.auth.users)[0].authUserId,
  sUsers,
  (authUserId, users) => {
    return R.compose(R.defaultTo(false), R.head, R.filter(R.propEq('authUserId', authUserId)), R.values)(users)
  },
)

export const sCurrentUser = createSelector(
  (state) => state.auth.currentUserId,
  (state) => state.auth.users,
  (authUserId, users) => users[authUserId],
)

export const sActiveUsers = createSelector(
  sUsers,
  R.filter((user) => !user.disabled),
)

export const isCurrentUserAdmin = createSelector(sCurrentUserApi, ({ istAdmin }) => istAdmin || false)

const sPracticeAutoLockActive = createSelector(praxisstammdatenSelector, (praxisstammdaten) =>
  praxisstammdaten ? praxisstammdaten.autoLockActive : false,
)

export const sUserShouldSetPin = createSelector(
  sCurrentUser,
  sPracticeAutoLockActive,
  (currentUser, practiceAutoLockActive) =>
    practiceAutoLockActive &&
    currentUser &&
    typeof currentUser.pinDefined === 'boolean' &&
    currentUser.pinDefined === false,
)
