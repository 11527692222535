/* eslint camelcase: 0 */
import * as R from 'ramda'
import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { RequestConfig } from './interface'

export function zahlungErfassen({ formData, termin }): RequestConfig {
  return {
    types: [types.ZAHLUNG_ERFASSEN, types.ZAHLUNG_ERFASSEN_SUCCESS, types.ZAHLUNG_ERFASSEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials, getState }): void => {
      const URL = `${apiUrl}/zahlungen`

      const state = getState()

      const { rezeptgebuehr, produkte_und_sonstiges, rabatt, leistungen, belegnummer } = formData

      const patient = state.entities.patienten[termin.patient]

      // definition of texts for cash report description according to cash entries
      const betreffZusaetze = [
        ...(isFinite(rezeptgebuehr) && rezeptgebuehr > 0 ? ['Eigenanteil'] : []),
        ...(R.reduce(R.add, 0, R.map(R.compose(R.defaultTo(0), R.prop('verguetung')), leistungen)) > 0
          ? ['Behandlung']
          : []),
        ...(isFinite(produkte_und_sonstiges) && produkte_und_sonstiges > 0 ? ['Produkte & Sonstiges'] : []),
      ]

      const betreff = `Barzahlung ${patient.nachname}, ${patient.vorname ? patient.vorname : ''} | ${R.join(
        ', ',
        betreffZusaetze,
      )}`

      const zahlungspositionen = [
        ...(isFinite(rezeptgebuehr)
          ? [
              {
                bezeichnung: 'Gesetzliche Zuzahlung',
                positionstyp: 'rezeptgebuehr',
                betrag: rezeptgebuehr || 0,
                mehrwertsteuer: null,
                termin: termin.id,
              },
            ]
          : []),

        ...(isFinite(produkte_und_sonstiges)
          ? [
              {
                bezeichnung: 'Produkte und Sonstiges',
                positionstyp: 'produkte_und_sonstiges',
                betrag: produkte_und_sonstiges || 0,
                mehrwertsteuer: null,
                termin: termin.id,
              },
            ]
          : []),

        ...(isFinite(rabatt)
          ? [
              {
                bezeichnung: 'Rabatt',
                positionstyp: 'rabatt',
                betrag: rabatt || 0,
                mehrwertsteuer: null,
                termin: termin.id,
              },
            ]
          : []),

        ...(leistungen
          ? leistungen.map(({ verguetung, beschreibung, kuerzel, mehrwertsteuer }) => ({
              bezeichnung: `${kuerzel} | ${beschreibung}`,
              positionstyp: 'leistung',
              betrag: verguetung || 0,
              mehrwertsteuer: mehrwertsteuer || null,
              termin: termin.id,
            }))
          : []),
      ]

      const postData = {
        belegnummer,
        betreff,
        zahlung: {
          zahlungstyp: 'kasse',
          patient: termin.patient,
        },
        zahlungspositionen,
      }

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { formData },
  }
}
