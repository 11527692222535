import { createSelector } from 'reselect'
import { sAuth, sCredentials } from '../shared/utils/auth'

const isPinOrLockToken = (token: string) => token && (token.indexOf('pin:') === 0 || token.indexOf('lock:') === 0)

export const sCredentialsDowngraded = createSelector(sAuth, (auth) => isPinOrLockToken(auth.auth0.accessToken))

/*
 * @param
 * als parameter muss state.auth übergeben werden
 * */
// TODO scar fix - either enum all possible or do in a smarter way
export function isApiServerSelected() {
  return !!sCredentials() && window.location.href.indexOf('startbereich') === -1
}

export function getServerURL() {
  const { protocol, host } = window.location

  if (__DEV__ && __LOCAL_API__) {
    return 'http://localhost:8080'
  }
  if (__DEV__) {
    return `${__API_PROTOCOL__}://${__API_HOST__}:8080`
  }
  return `${protocol}//${host}`
}

export function getApiUrl(nameHash) {
  const serverURL = getServerURL()
  return `${serverURL}/${nameHash}/api`
}

export function getAuthUrl() {
  const serverURL = getServerURL()
  return `${serverURL}/auth`
}
