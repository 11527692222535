import styled from 'styled-components';

export const StyledTherapieberichtForm = styled.form`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px ${theme.spacing(6)}px;
    flex-grow: 1;

    .fieldContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(6)}px;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .divider {
      margin-top: 20px 0;
    }

    .submitButton {
      margin-bottom: 20px;
    }
  `}
`;
