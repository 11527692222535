import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const StyledReadCardDataDialog = styled(Dialog)`
  ${({ theme }) => `
    .dialogActions {
      justify-content: center;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .dialogContent {
      margin-bottom: ${theme.spacing(2)}px;
    }

    .link {
      color: ${theme.palette.secondary.main};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;
