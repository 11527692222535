import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import type { RequestConfig } from './interface'

export function loadChangelogs(): RequestConfig {
  return {
    types: [types.LOAD_CHANGELOGS, types.LOAD_CHANGELOGS_SUCCESS, types.LOAD_CHANGELOGS_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/changelog`

      return fetchSecure(
        URL,
        {
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadChangelog(id: number): RequestConfig {
  return {
    types: [types.LOAD_CHANGELOG, types.LOAD_CHANGELOG_SUCCESS, types.LOAD_CHANGELOG_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/changelog/${id}`

      return fetchSecure(
        URL,
        {
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadLatestChangelog(): RequestConfig {
  return {
    types: [types.LOAD_LATEST_CHANGELOG, types.LOAD_LATEST_CHANGELOG_SUCCESS, types.LOAD_LATEST_CHANGELOG_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/changelog/latest`

      return fetchSecure(
        URL,
        {
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadLastSeen(): RequestConfig {
  return {
    types: [
      types.LOAD_CHANGELOG_LAST_SEEN,
      types.LOAD_CHANGELOG_LAST_SEEN_SUCCESS,
      types.LOAD_CHANGELOG_LAST_SEEN_FAILURE,
    ],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/changelog/read`

      return fetchSecure(
        URL,
        {
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function updateLastSeen(): RequestConfig {
  return {
    types: [
      types.UPDATE_CHANGELOG_LAST_SEEN,
      types.UPDATE_CHANGELOG_LAST_SEEN_SUCCESS,
      types.UPDATE_CHANGELOG_LAST_SEEN_FAILURE,
    ],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: () => true,
    callAPI: ({ authUrl, auth0Credentials }) => {
      const URL = `${authUrl}/changelog/read`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}
