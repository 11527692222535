import { FC } from 'react'
import { FemaleIcon, MaleIcon, TransgenderIcon } from '../../shared/components/Gender'

interface Props {
  iconColor?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary' | undefined
  gender?: 'male' | 'female'
  className?: string
}

const PatientIcon: FC<Props> = ({ iconColor, gender, className }) => {
  switch (gender) {
    case 'male':
      return <MaleIcon className={className} fontSize="small" color={iconColor} />
    case 'female':
      return <FemaleIcon className={className} fontSize="small" color={iconColor} />
    default:
      return <TransgenderIcon className={className} fontSize="small" color={iconColor} />
  }
}

export default PatientIcon
