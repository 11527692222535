import * as R from 'ramda'
import { ignoreActions } from 'redux-ignore'

// Beispiel:
// ignoriert die redux-form/* Actions in allen reducern, bis auf den 'form' reducer.
// const combineWithIgnore = R.compose(
//   combineReducers,
//   reduxLimitActionsToReducers( [ {
//     actionPredicate: ( type ) => /^redux-form/.test( type ),
//     reducerKeys: [ 'form' ],
//   } ] )
// );

// :: [ConfigObject] -> ReducersMap -> ReducersMap
export const reduxLimitActionsToReducers = R.curry((config, reducerMap) => {
  if (!config || !Array.isArray(config)) {
    return reducerMap
  }

  return R.compose(
    R.fromPairs,
    R.map((pair) => {
      const key = pair[0]
      const reducer = pair[1]

      const predicates = R.compose(
        R.chain(R.prop('actionPredicate')),
        R.filter(R.compose(R.not, R.contains(key), R.prop('reducerKeys'))),
      )(config)

      return [key, predicates.length ? ignoreActions(reducer, R.anyPass(predicates)) : reducer]
    }),
    R.toPairs,
  )(reducerMap)
})
