import { CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS } from './password'
import { CHANGE_PIN_CHANGE_PIN_SUCCESS } from './pin'

export default {
  changePasswordLoggedInForm: (state, action) => {
    switch (action.type) {
      case CHANGE_PASSWORD_LOGGED_IN_PASSWORD_CHANGE_SUCCESS:
        return {}

      default:
        return state
    }
  },

  changePinForm: (state, action) => {
    switch (action.type) {
      case CHANGE_PIN_CHANGE_PIN_SUCCESS:
        return {}

      default:
        return state
    }
  },
}
