export const START_TICKS = 'START_TICKS'
export const TICK = 'TICK'

export default (
  state = {
    currentTime: new Date(),
  },
  action,
) => {
  switch (action.type) {
    case TICK:
      return {
        ...state,
        currentTime: action.time,
      }
    default:
  }

  return state
}

const intervals = [
  {
    name: 'minute',
    duration: 60 * 1000,
  },
]

export const startTicks = () => (dispatch) => {
  dispatch({
    type: START_TICKS,
    time: new Date(),
  })

  intervals.forEach((interval) =>
    setInterval(
      () =>
        dispatch({
          type: TICK,
          interval: interval.name,
          time: new Date(),
        }),
      interval.duration,
    ),
  )
}
