import { LocalHospital as LocalHospitalIcon } from '@material-ui/icons';
import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as doctorsActions from '../../actions/doctors';
import DoctorForm from '../../components/DoctorForm/DoctorForm';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledAddDoctor } from './StyledAddDoctor';

const AddDoctor = ({ actions }) => {
  const saveForm = useCallback(
    async (data) => {
      console.log('saveForm called! ', data)
      actions.createDoctor(data)
    },
    [actions.createDoctor],
  )

  return (
    <StyledAddDoctor>
      <IconHeading icon={<LocalHospitalIcon />} text="Arzt neu anlegen" />
      <DoctorForm
        onSubmitFunc={saveForm}
        initialValues={{
          vorname: null,
          nachname: null,
          titel: null,
          strasse: null,
          hausnummer: null,
          postleitzahl: null,
          ort: null,
          telefon: null,
          fax: null,
          mobil: null,
          email: null,
          notizen: null,
          praxisbezeichnung: null,
          lanr: null,
          bsnr: null,
          website: null,
        }}
      />
    </StyledAddDoctor>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(doctorsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(memo(AddDoctor));
