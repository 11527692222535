/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  This module knows how to get data from the eGK
 *                                and offers an interface for accessing this data.
 *
 *  history:
 *  *.2021 bwe                    created
 */

import { Sad } from "./t1_host_interface";
import { Dad } from "./t1_host_interface";
import { Read_eGK_stm } from "./read_eGK_state_machine";
import { T1_host_if } from "./t1_host_interface";

export class CardReader_eGK {

  private read_eGK_stm:  Read_eGK_stm;

  // Storage for command execution (array of lambdas)
  private command_storage: { ( ): void }[] = [];

  private eGK_data_EF_PD = "";  // deGK data EF_PD
  private eGK_data_EF_VD = "";  // deGK data EF_VD

  constructor(dad: Dad, t1_host_if: T1_host_if) {

    this.read_eGK_stm = new Read_eGK_stm(
      this, t1_host_if, dad, Sad.host
    );

    console.log("Card_reader_eGK: constructor().");
  }

  // Read the 'Gesundheitskarte' eGK
  public async read_eGK(): Promise<string>{
    console.log("read_eGK().");

    let eGK_data = "";

    await this.read_eGK_stm.select_masterfile();
    await this.read_eGK_stm.select_DF_HCA();
    await this.read_eGK_stm.read_EF_StatusVD();
    this.eGK_data_EF_PD = await this.read_eGK_stm.read_EF_PD(); 
    this.eGK_data_EF_VD = await this.read_eGK_stm.read_EF_VD();
    
    eGK_data = this.eGK_data_EF_PD + this.eGK_data_EF_VD;
    return eGK_data;  
  } 

  // Call next command from command_storage
  private callNextCmd ( ): void {
    const nextFunctionCall = this.command_storage.shift();
    if(nextFunctionCall){
      nextFunctionCall();
    }else{
      console.error("card_reader_eGK command_storage is empty.");
    }
  }
}
