import styled from 'styled-components';

export const StyledAlphabetFilter = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .character {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      background-color: ${theme.palette.primary.main};
      height: 48px;
      flex-grow: 1;
      font-size: 22px;
      color: ${theme.palette.text.primary};
      vertical-align: bottom;
      flex-basis: 0;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      line-height: 48px;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    .characterSelected {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      background-color: ${theme.palette.primary.dark};
      height: 48px;
      flex-grow: 1;
      font-size: 22px;
      color: ${theme.palette.text.primary};
      vertical-align: bottom;
      justify-items: center;
      flex-basis: 0;
      cursor: pointer;
    }
  `}
`;
