import { Person as PersonIcon } from '@material-ui/icons';
import { memo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCustomer } from '../../actions/customers';
import CustomerForm from '../../components/CustomerForm';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledAddCustomer } from './StyledAddCustomer';

const AddCustomer = ({ actions, doctors }) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const saveForm = async (data) => {
    data.krankenkasse = !data.krankenkasse || data.krankenkasse === 'null' 
      ? null 
      : data.krankenkasse;
    data.titel = !data.titel || data.titel === 'null' 
      ? null 
      : data.titel;
      
    actions.createCustomer(data, true);
  };

  if (!doctors) return null;

  return (
    <StyledAddCustomer>
      <IconHeading icon={<PersonIcon />} text="Patient neu anlegen" />
      <CustomerForm
        onSubmitFunc={saveForm}
        doctors={doctors}
        initialValues={{
          patientSeit: currentDate,
          vorname: null,
          nachname: null,
          geschlecht: null,
          titel: null,
          geburtsdatum: null,
          strasse: null,
          hausnummer: null,
          postleitzahl: null,
          ort: null,
          telefon: null,
          mobil: null,
          email: null,
          emailErinnerung: null,
          emailNewsletter: null,
          krankenkasse: null,
          versichertennummer: null,
          hausarzt: null,
          facharzt: null,
          frequenz: null,
          maxFrequenz: null,
          empfehlung: null,
          bisherigePodologie: null,
          abgewandertZu: null,
          bild: null,
          verstorben: null,
          notizen: null,
          dateien: null,
          land: "DE",
          sozialeGemeinschaft: null,
          patientennummer: null,
          regularTherapist: null,
          versichertenstatus: null,
          ikNummerKK: null,
          mobilCountry: 49
        }}
      />
    </StyledAddCustomer>
  );
};

const mapStateToProps = (state) => ({
  doctors: state.entities.aerzte,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createCustomer: bindActionCreators(createCustomer, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddCustomer));
