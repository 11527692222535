import { Button, Paper } from '@material-ui/core'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as roomActions from '../../actions/rooms'
import { ControlledMultipleAutocompleteField } from '../../shared/components/AutocompleteField/ControlledMultipleAutocompleteField'
import ControlledCheckboxField from '../../shared/components/CheckboxField/ControlledCheckboxField'
import ControlledInputField from '../../shared/components/InputField/ControlledInputField'
import ControlledSelectField from '../../shared/components/SelectField/ControlledSelectField'
import { errorMessages, getErrorMessage } from '../../shared/utils/errorMessages'
import {
  RoomSettingsFormButtonContainer,
  RoomSettingsFormContainer,
  RoomSettingsFormHeader,
  RoomSettingsFormInner,
} from './StyledRoomSettings'

interface FormData {
  abbreviation: string
  name: string
  floor: number | null
  equipment: number[]
  disabled: boolean
}

const defaultValues = {
  abbreviation: '',
  name: '',
  floor: null,
  equipment: [],
  disabled: false,
}

const floorValues = [
  { value: 0, label: 'EG' },
  { value: 1, label: '1. OG' },
  { value: 2, label: '2. OG' },
  { value: 3, label: '3. OG' },
  { value: 4, label: '4. OG' },
  { value: 5, label: '5. OG' },
  { value: 6, label: '6. OG' },
  { value: 7, label: '7. OG' },
  { value: 8, label: '8. OG' },
  { value: 9, label: '9. OG' },
  { value: 10, label: '10. OG' },
]
interface Props {
  actions: any
  isOpen: boolean
  selectedRoom: number | null
  rooms: any
  equipment: any
  onCancelChanges: () => void
}

const RoomSettingsForm = ({
  actions,
  isOpen = false,
  selectedRoom,
  rooms = {},
  equipment = {},
  onCancelChanges,
}: Props): JSX.Element => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    console.log(`
      ----- PROPS CHANGED -----
      isOpen: ${isOpen}
      selectedRoom: ${selectedRoom}
      rooms: ${JSON.stringify(rooms)}
      -------------------------
    `)
    if (isOpen) {
      if (!selectedRoom) {
        resetForm(defaultValues)
      } else {
        const roomsArray = Object.keys(rooms).map((key) => rooms[key])
        const selectedRoomData = roomsArray.find((room) => room.id === selectedRoom)
        resetForm(selectedRoomData)
      }
    }
  }, [isOpen, selectedRoom, rooms])

  const equipmentArray = useMemo(() => {
    return Object.keys(equipment).map((key) => equipment[key])
  }, [equipment])

  const onSubmit = async (data: FormData) => {
    console.log('data submitted: ', data)

    if (!!selectedRoom) {
      await actions.rooms.updateRoom(data)
    } else {
      await actions.rooms.createRoom(data)
    }
  }

  const abbreviation = watch('abbreviation')
  const name = watch('name')

  return (
    <RoomSettingsFormContainer elevation={2} square isOpen={isOpen}>
      <RoomSettingsFormHeader>
        {!abbreviation && !name ? 'Neuer Raum' : `${abbreviation} | ${name}`}
      </RoomSettingsFormHeader>
      <RoomSettingsFormInner autoComplete="off" lang="de" onSubmit={handleSubmit(onSubmit)}>
        <ControlledInputField
          fullWidth
          control={control}
          name="abbreviation"
          label="Abkürzung"
          rules={{
            required: getErrorMessage('required'),
            validate: {
              isUnique: (value) => {
                let result: string | true = true;
                const roomsArray = Object.keys(rooms).map((key) => rooms[key])

                if (selectedRoom) {
                  result = roomsArray.filter((room) => room.id !== selectedRoom).some((room) => room.abbreviation === value) ? 'Diese Abkürzung ist bereits vergeben' : true
                } else {
                  result = roomsArray.some((room) => room.abbreviation === value) ? 'Diese Abkürzung ist bereits vergeben' : true
                }

                return result
              },
            },
          }}
          error={!!errors.abbreviation}
          helperText={errors?.abbreviation?.message}
        />
        <ControlledInputField
          fullWidth
          control={control}
          name="name"
          label="Name"
          rules={{
            required: getErrorMessage('required'),
            validate: {
              isUnique: (value) => {
                let result: string | true = true;
                const roomsArray = Object.keys(rooms).map((key) => rooms[key])

                if (selectedRoom) {
                  result = roomsArray.filter((room) => room.id !== selectedRoom).some((room) => room.name === value) ? 'Dieser Name ist bereits vergeben' : true
                } else {
                  result = roomsArray.some((room) => room.name === value) ? 'Dieser Name ist bereits vergeben' : true
                }

                return result
              },
            },
          }}
          error={!!errors.name}
          helperText={errors?.name?.message}
        />
        <ControlledSelectField fullWidth control={control} name="floor" label="Etage" options={floorValues} />
        <ControlledMultipleAutocompleteField
          fullWidth
          control={control}
          name="equipment"
          label="Ausstattung"
          options={equipmentArray.map((e) => ({ value: e.id, label: `${e.abbreviation} | ${e.name}` }))}
        />
        <ControlledCheckboxField control={control} name="disabled" label="Raum deaktiviert" />
        <RoomSettingsFormButtonContainer>
          <Button variant="contained" color="secondary" type="submit" disabled={!isDirty}>
            {!selectedRoom ? 'Raum erstellen' : 'Raum aktualisieren'}
          </Button>
          {!!selectedRoom && isDirty && (
            <Button variant="contained" type="button" onClick={onCancelChanges}>
              Änderungen verwerfen
            </Button>
          )}
        </RoomSettingsFormButtonContainer>
      </RoomSettingsFormInner>
    </RoomSettingsFormContainer>
  )
}

const mapStateToProps = (state) => ({
  rooms: state.entities.rooms,
  equipment: state.entities.equipment,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    rooms: bindActionCreators(roomActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RoomSettingsForm)
