import styled from 'styled-components'
import { Theme } from '@material-ui/core';

export const StyledCalendarRoomsView = styled.div`
  ${({ theme, isSnackbarOpen }: { theme: Theme, isSnackbarOpen: boolean }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    height:  ${isSnackbarOpen ? '82vh;' : '100vh;'}

    .dayViewMain {
      display: flex;
      flex-direction: row;
      margin-top: ${theme.spacing(3)}px;
      margin-left: ${theme.spacing(3)}px;
      /* The fixed margin comes from the width of the spacer (StyledCalendarColumnSpacer). */
      margin-right: 45px;
      flex-grow: 1;
    }

    .dayViewTopBar {
      display: flex;
      flex-direction: row;
      height: 32px;
    }

    .button {
      min-width: 60px;
    }
  `}
`;
