import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const StyledChangePin = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px;

    .emailHeading {
      margin: 0;
      margin-top: ${theme.spacing(3)}px;
      font-weight: normal;
      color: ${theme.palette.secondary.main};
    }

    .noPinDefinedText {
      color: red;
    }

    .pinHelpLink {
      cursor: pointer;
      color: ${theme.palette.secondary.main};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .changePinForm {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }
  `}
`
