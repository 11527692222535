import { FC } from 'react'
import { LinkProps } from 'react-router-dom'
import { DividerLinkIcon, DividerLinkLine, StyledDividerLink } from '../styles'

interface Props extends LinkProps {
  iconDirection: 'up' | 'down'
}

const DividerLink: FC<Props> = ({ to, iconDirection }) => {
  return (
    <StyledDividerLink to={to}>
      <DividerLinkLine />
      <DividerLinkIcon direction={iconDirection} />
    </StyledDividerLink>
  )
}

export default DividerLink
