import { FC } from 'react'
import { useParams } from 'react-router-dom'
import CalendarWeekView from './CalendarWeekView'

interface Props {
  dialogActions: any,
}

export const CalendarWeekViewContainer: FC<Props> = (props) => {
  const params = useParams()
  return <CalendarWeekView person={params.person} {...props} />
}
