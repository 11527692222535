import * as types from '../constants/actionTypes'

export default function selectedTerminProps(state = {}, action = undefined) {
  switch (action.type) {
    case types.SELECT_TERMIN_PROPS:
      if (action.terminProps) {
        if (!(Object.keys(action.terminProps).length === 0 && action.terminProps.constructor === Object)) {
          action.terminProps.url = location.pathname
        }
        return action.terminProps
      }
      break
    case types.CREATE_DATE_SUCCESS:
      return {}
    default:
  }
  return state
}
