/* eslint-disable @typescript-eslint/no-var-requires */
import { applyMiddleware, compose, createStore } from 'redux'
import CatchPromise from 'redux-catch-promise'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'
import callAPIMiddleware from './middleware/callAPIMiddleware'
import crossTabMiddleware from './middleware/crossTabMiddleware'
import formLeaveConfirmationMiddleware from './middleware/formLeaveConfirmationMiddleware'
import formMiddleware from './middleware/formMiddleware'
import lockscreenMiddleware from './middleware/lockscreenMiddleware'
import redirectMiddleware from './middleware/redirectMiddleware'
import socketMiddleware from './middleware/socketMiddleware'

export function configureStore(): ReturnType<typeof rootReducer> {
  const middlewares = [
    CatchPromise(),
    callAPIMiddleware,
    crossTabMiddleware,
    redirectMiddleware,
    formLeaveConfirmationMiddleware,
    socketMiddleware,
    // lockscreenMiddleware,
    formMiddleware,
  ]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  let composedEnhancers
  if (process.env.NODE_ENV !== 'production') {
    composedEnhancers = composeWithDevTools(middlewareEnhancer)
  } else {
    composedEnhancers = compose(middlewareEnhancer)
  }

  const store = createStore(rootReducer, composedEnhancers)

  return store
}
