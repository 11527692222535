/*
“This software (code) is copyright by Worldline Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied,
including without limitation, any warranties of merchantability or fitness for a particular purpose.
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Worldline Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen.
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Worldline Healthcare GmbH
 *
 *  description:                  eGK identifier collection
 *
 *  history:
 *  *.2023 tpe                    created
 */

export class EGK_AID {
  // Masterfile (MF)
  public static get mf(): Uint8Array {
    return new Uint8Array([0xd2, 0x76, 0x00, 0x01, 0x44, 0x80, 0x00])
  }

  // Healthcard Application (HCA)
  public static get hca(): Uint8Array {
    return new Uint8Array([0xd2, 0x76, 0x00, 0x00, 0x01, 0x02])
  }
}

export class EGK_SFID {
  // Persönliche Versicherungsdaten (PD)
  public static get pd(): Uint8Array {
    return new Uint8Array([0x01])
  }

  // Allgemeine Versicherungsdaten (VD) / Geschütze Versicherungsdaten (GVD)
  public static get vd_gvd(): Uint8Array {
    return new Uint8Array([0x02])
  }

  // Status Versicherungsdaten (StatusVD)
  public static get statusVD(): Uint8Array {
    return new Uint8Array([0x0c])
  }
}
