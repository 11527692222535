import styled from 'styled-components';

export const StyledTerminForm = styled.div `
  ${({ theme }) => `
    .hide {
      display: none;
    }

    .hausbesuchWarning {
      display: block;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .iconButton {
      background: ${theme.palette.secondary.main};
      color: white;
    }

    .patientField {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .field {
      margin-top: ${theme.spacing(2)}px;
    }

    .row {
      display: grid;
      align-items: center;
      grid-column-gap: ${theme.spacing(3)}px;
      grid-template-columns: 1fr 1fr 1fr;

      &.twoItems {
        grid-template-columns: 1fr 1fr;
      }
      &.oneItem {
        grid-template-columns: 1fr;
      }
      ${theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
      }
    }

    .MuiInputBase-inputMultiline {
      min-height: 5em;
      max-height: 50vh;
    }
  `}
`;
