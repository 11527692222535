import { Children, FC } from 'react';

interface Props {
  index: number,
  value: number
}

const TabPanel: FC<Props> = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`pododesk-tabpanel-${index}`}
    aria-labelledby={`pododesk-tab-${index}`}>
    {value === index ? (
      Children.toArray(children)
    ): null}
  </div>
)
export default TabPanel;

export const tabA11yProps = (index: number) => ({
  id: `pododesk-tab-${index}`,
  'aria-controls': `pododesk-tabpanel-${index}`,
});
