/*
  THIS IS KEPT FOR REFERENCE WHEN REFACTORING OLD STYLING SOLUTION

  export const spacingOld = {
  iconSize: 24,
  desktopGutter: 24,
  desktopGutterMore: 32,
  desktopGutterLess: 16,
  desktopGutterMini: 8,
  desktopKeylineIncrement: 64,
  desktopDropDownMenuItemHeight: 32,
  desktopDropDownMenuFontSize: 15,
  desktopLeftNavMenuItemHeight: 48,
  desktopSubheaderHeight: 48,
  desktopToolbarHeight: 56
} */

export const spacing = [0, 4, 8, 16, 24, 32, 48, 56, 64]
