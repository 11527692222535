export const trackError = (error, metadata?) => {
  if (window['trackJs']) {
    if (metadata) {
      window['trackJs'].addMetadata('metadata', metadata)
    }
    window['trackJs'].track(error)
    if (metadata) {
      window['trackJs'].removeMetadata('metadata')
    }
  } else if (process.env.NODE_ENV !== 'production') {
    console.log('trackError():', error, metadata)
  }
}
