import {
  Button, Divider, InputAdornment,
  List,
  ListItem, ListItemText,
  ListSubheader,
  Menu
} from '@material-ui/core'
import { Edit as EditIcon, Search as SearchIcon, Visibility as VisibilityIcon } from '@material-ui/icons'
import { isAfter } from 'date-fns'
import debounce from 'lodash/debounce'
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as patientenActions from '../../actions/customers'
import * as heilmittelverordnungenActions from '../../actions/heilmittelverordnungen'
import { IconMenuArzt } from '../../components/MenuArzt'
import { IconMenuHeilmittelverordnung } from '../../components/MenuHeilmittelverordnung'
import { IconMenuLeistungPrivat } from '../../components/MenuLeistungPrivat'
import { IconMenuPatient } from '../../components/MenuPatient'
import { IconMenuTermin } from '../../components/MenuTermin'
import { IconMenuTherapeut } from '../../components/MenuTherapeut'
import PatientText from '../../components/PatientText/PatientText'
import InputField from '../../shared/components/InputField'
import { sUserShouldSetPin } from '../../shared/utils/users'
import { buildArztString } from '../../utils/aerzte'
import { buildTerminString, sortByBeginnAscending, sortByBeginnDescending } from '../../utils/dates'
import { buildHeilmittelverordnungString, sortByAusstellungsdatumDescending } from '../../utils/heilmittelverordnungen'
import { sortByNachnameVorname } from '../../utils/persons'
import { filterText, getMatchingSearchFields } from '../../utils/search'
import { StyledDisabledQuickSearch, StyledQuickSearch } from './StyledQuickSearch'

/* added sozialeGemeinschaft for searchFieldsPatient */
const searchFieldsPatient = [
  'vorname',
  'nachname',
  'vorname',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'mobil',
  'email',
  'sozialeGemeinschaft',
]
const searchFieldsToHidePatient = ['vorname', 'nachname']
const searchFieldsArzt = [
  'titel',
  'vorname',
  'nachname',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'mobil',
  'praxisbezeichnung',
  'fax',
  'lanr'
]
const searchFieldsToHideArzt = ['titel', 'nachname', 'ort']
const searchFieldsTherapeut = ['vorname', 'nachname']
const searchFieldsLeistung = ['kuerzel', 'beschreibung']
const maxResultsPatienten = 8
const maxResultsAerzte = 3
const maxResultsTherapeuten = 3
const maxResultsLeistungen = 3

const patientMaxHeilmittelverordnungen = 3
const patientMaxTermine = 3
const arztMaxHeilmittelverordnungen = 8
const therapeutMaxTermine = 8

interface Props {
  actions?: any,
  aerzte?: any,
  aerzteIds?: Array<any>,
  heilmittelverordnungen?: any,
  heilmittelverordnungenIds?: Array<any>,
  krankenkassen?: any,
  leistungenKK?: any,
  leistungenPrivat?: any,
  patienten?: any,
  patientenIds?: Array<any>,
  termine?: any,
  termineIds?: Array<any>,
  users?: any,
  userIds?: Array<any>,

  totalSearchResults?: any,
  disabled?: boolean,

  dialogActions?: any
}

const defaultEntity = {
  id: null,
  type: null
}

const QuickSearch: FC<Props> = ({
  actions,
  aerzte,
  aerzteIds = [],
  heilmittelverordnungen = {},
  heilmittelverordnungenIds = [],
  krankenkassen,
  leistungenKK,
  leistungenPrivat,
  patienten,
  patientenIds = [],
  termine,
  termineIds = [],
  users,
  userIds = [],

  totalSearchResults,
  disabled,

  dialogActions = {},
}) => {
  const navigate = useNavigate()
  const params = useParams()

  const searchFieldRef = useRef<HTMLInputElement | null>(null)
  const popupAnchor = useRef<HTMLDivElement | null>(null)

  const [open, setOpen] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')
  const [chosenEntity, setChosenEntity] = useState<{
    id: number | null,
    type: string | null
  }>(defaultEntity)

  // Patient
  const [chosenPatient, setChosenPatient] = useState<any>(null)
  const [chosenPatientHeilmittelverordnungen, setChosenPatientHeilmittelverordnungen] = useState<any>(null)
  const [chosenPatientTermine, setChosenPatientTermine] = useState<any>(null)
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'patient' && chosenEntity.id !== null && patienten) {
      setChosenPatient(patienten[chosenEntity.id])
    } else {
      setChosenPatient(null)
    }
  }, [chosenEntity, patienten])
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'patient') {
      const data = heilmittelverordnungenIds
        .map((key) => heilmittelverordnungen[key])
        .filter((hvo) => hvo.patient === chosenEntity.id)
        .sort(sortByAusstellungsdatumDescending);
      setChosenPatientHeilmittelverordnungen(data)
    } else {
      setChosenPatientHeilmittelverordnungen(null)
    }
  }, [chosenEntity, heilmittelverordnungenIds])
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'patient') {
      const data = termineIds
        .map((key) => termine[key])
        .filter(
          (termin) =>
            termin.patient === chosenEntity.id && (typeof termin.absagegrund !== 'string' || termin.absagegrund === ''),
        )
        .sort(sortByBeginnDescending);
      setChosenPatientTermine(data)
    } else {
      setChosenPatientTermine(null)
    }
  }, [chosenEntity, termineIds])

  // Patient Search
  const [searchResultsPatienten, setSearchResultsPatienten] = useState<any>([])
  const [chosenPatientInResults, setChosenPatientInResults] = useState<boolean>(false)
  const [totalNumberOfSearchResultsPatienten, setTotalNumberOfSearchResultsPatienten] = useState<any>(null)
  // ---
  useEffect(() => {
    const searchData = filterText(
      patientenIds.map((patientenId) => patienten[patientenId]),
      searchText,
      searchFieldsPatient,
    ).sort(sortByNachnameVorname) || [];

    setSearchResultsPatienten(searchData)
  }, [patientenIds, patienten, searchText])
  // ---
  useEffect(() => {
    if(searchResultsPatienten) {
      const isChosen = searchResultsPatienten
        .slice(0, maxResultsPatienten)
        .filter(
          (patient) => chosenEntity.type === 'patient' && patient.id === chosenEntity.id
        ).length !== 0;
      setChosenPatientInResults(isChosen)
    } else {
      setChosenPatientInResults(false)
    }
  }, [chosenEntity, searchResultsPatienten])
  // ---
  useEffect(() => {
    let newTotal = null;
    if (totalSearchResults.patienten && totalSearchResults.patienten.searchText === searchText) {
      newTotal= totalSearchResults.patienten.results
    }
    setTotalNumberOfSearchResultsPatienten(newTotal)
  }, [totalSearchResults, searchText])


  // Arzt
  const [chosenArzt, setChosenArzt] = useState<any>(null)
  const [chosenArztHeilmittelverordnungen, setChosenArztHeilmittelverordnungen] = useState<any>(null)
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'arzt' && chosenEntity.id !== null && aerzte) {
      setChosenArzt(aerzte[chosenEntity.id])
    } else {
      setChosenArzt(null)
    }
  }, [chosenEntity, aerzte])
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'arzt') {
      const data = heilmittelverordnungenIds
        .map((key) => heilmittelverordnungen[key])
        .filter((hvo) => hvo.arzt === chosenEntity.id)
        .sort(sortByAusstellungsdatumDescending)
      setChosenArztHeilmittelverordnungen(data)
    } else {
      setChosenArztHeilmittelverordnungen(null)
    }
  }, [chosenEntity, heilmittelverordnungenIds])

  // Arzt Search
  const [searchResultsAerzte, setSearchResultsAerzte] = useState<any>([])
  const [chosenArztInResults, setChosenArztInResults] = useState<boolean>(false)
  // ---
  useEffect(() => {
    const searchData = filterText(
      aerzteIds.map((arztId) => aerzte[arztId]),
      searchText,
      searchFieldsArzt,
    ).sort(sortByNachnameVorname) || [];

    setSearchResultsAerzte(searchData)
  }, [aerzteIds, aerzte, searchText])
  // ---
  useEffect(() => {
    if(searchResultsAerzte) {
      const isChosen = searchResultsAerzte
        .slice(0, maxResultsAerzte)
        .filter(
          (arzt) => chosenEntity.type === 'arzt' && arzt.id === chosenEntity.id
        ).length !== 0;
      setChosenArztInResults(isChosen)
    } else {
      setChosenArztInResults(false)
    }
  }, [chosenEntity, searchResultsAerzte])

  // Therapeut
  const [chosenTherapeutTermine, setChosenTherapeutTermine] = useState<any>(null)
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'therapeut') {
      const now = new Date()

      const data = termineIds
        .map((key) => termine[key])
        .filter((termin) => {
          return (
            termin.therapeut === chosenEntity.id &&
            (typeof termin.absagegrund !== 'string' || termin.absagegrund === '') &&
            isAfter(termin.ende, now)
          )
        })
        .sort(sortByBeginnAscending)
      setChosenTherapeutTermine(data)
    } else {
      setChosenTherapeutTermine(null)
    }
  }, [chosenEntity, termineIds])

  // Therapeut Search
  const [searchResultsTherapeuten, setSearchResultsTherapeuten] = useState<any>([])
  const [chosenTherapeutInResults, setChosenTherapeutInResults] = useState<boolean>(false)
  // ---
  useEffect(() => {
    const searchData = filterText(
      userIds.map((userId) => users[userId]),
      searchText,
      searchFieldsTherapeut,
    )
    .filter((user) => user.istTherapeut)
    .sort(sortByNachnameVorname)|| [];

    setSearchResultsTherapeuten(searchData)
  }, [userIds, users, searchText])
  // ---
  useEffect(() => {
    if(searchResultsTherapeuten) {
      const isChosen =  searchResultsTherapeuten
        .slice(0, maxResultsTherapeuten)
        .filter(
          (therapeut) => chosenEntity.type === 'therapeut' && therapeut.id === chosenEntity.id
        ).length !== 0;
      setChosenTherapeutInResults(isChosen)
    } else {
      setChosenTherapeutInResults(false)
    }
  }, [chosenEntity, searchResultsTherapeuten])


  // Leistung
  const [chosenLeistung, setChosenLeistung] = useState<any>(null)
  // ---
  useEffect(() => {
    if (chosenEntity.type === 'leistung' && chosenEntity.id !== null) {
      let data = null;
      if (leistungenPrivat && leistungenPrivat[chosenEntity.id]) {
        data = leistungenPrivat[chosenEntity.id]
      } else if(leistungenKK){
        data = leistungenKK[chosenEntity.id]
      }
      setChosenLeistung(data)
    } else {
      setChosenLeistung(null)
    }
  }, [chosenEntity, leistungenPrivat, leistungenKK])


// Leistung Search
const [searchResultsLeistungen, setSearchResultsLeistungen] = useState<any>([])
const [chosenLeistungInResults, setChosenLeistungInResults] = useState<boolean>(false)
// ---
useEffect(() => {
  if(!leistungenKK || !leistungenPrivat) {
    return;
  }

  const leistungen = Object.keys(leistungenKK)
    .map((key) => leistungenKK[key])
    .filter((leistungKK) => leistungKK.istHeilmittel)
    .concat(Object.keys(leistungenPrivat).map((key) => leistungenPrivat[key]))

  const searchData = filterText(
      leistungen,
      searchText,
      searchFieldsLeistung
    ).sort((a, b) => {
      if (a.beschreibung > b.beschreibung) {
        return 1
      }
      if (a.beschreibung < b.beschreibung) {
        return -1
      }
      return 0
    }) || [];

    setSearchResultsLeistungen(searchData)
}, [leistungenKK, leistungenPrivat, searchText])
// ---
useEffect(() => {
  if(searchResultsLeistungen) {
    const isChosen =  searchResultsLeistungen
      .slice(0, maxResultsLeistungen)
      .filter(
        (leistung) =>
          chosenEntity.type === 'leistung' &&
          (leistung.id === chosenEntity.id || leistung.positionsnummer === chosenEntity.id),
      ).length !== 0
    setChosenLeistungInResults(isChosen)
  } else {
    setChosenLeistungInResults(false)
  }
}, [chosenEntity, searchResultsLeistungen])


  const findCustomersDebounced = useCallback((text: string, fields: Array<string>) => {
    return debounce(actions.patienten.findCustomers, 300)(text, fields)
  }, [actions])

  useEffect(() => {
    if(searchText?.length > 0) {
      findCustomersDebounced(searchText, searchFieldsPatient)

      if(!open) {
        setOpen(true)
      }
    }
  }, [searchText])


  const handleChange = useCallback((value) => {
    let inputValue = value;
    setSearchText((inputValue || '').replace(',', ''))
  }, [setSearchText])

  const handleTextFieldClick = useCallback((event) => {
    event.preventDefault()
    setOpen(true)
  }, [setOpen])

  const handleEntityMenuClose = useCallback(() => {
    setChosenEntity(defaultEntity)
  }, [setChosenEntity])

  const handleMenuClose = useCallback(() => {
    setOpen(false)
    handleEntityMenuClose()
  }, [setOpen, handleEntityMenuClose])

  const handleListTouchTap = (id, entityType) => () => {
    if (entityType === 'patient') {
      actions.patienten.loadCustomer(id, true)
    }
    if (entityType === 'arzt') {
      actions.heilmittelverordnungen.loadHeilmittelverordnungenByArzt(id, arztMaxHeilmittelverordnungen)
    }
    if (chosenEntity.id !== id || chosenEntity.type !== entityType) {
      setChosenEntity({ id, type: entityType })
    }
  }

  const handleViewPatientClick = useCallback((id) => () => {
    handleMenuClose()
    navigate(`/${params.serverHash}/contacts/customers/${id}`)
  }, [handleMenuClose, navigate, params.serverHash])

  const handleViewArztClick = useCallback((id) => () => {
    handleMenuClose()
    navigate(`/${params.serverHash}/contacts/doctors/${id}`)
  }, [handleMenuClose, navigate, params.serverHash])

  const handleTherapeutClick = useCallback((id) => () => {
    handleMenuClose()
    navigate(`/${params.serverHash}/calendar/week/${id}`)
  }, [handleMenuClose, navigate, params.serverHash])

  const handleLeistungClick = useCallback((id) => () => {
    handleMenuClose()
    navigate(`/${params.serverHash}/settings/leistungen/${id}`)
  }, [handleMenuClose, navigate, params.serverHash])

  if (disabled) {
    return (
      <StyledDisabledQuickSearch>
        <InputField
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" color="secondary"/>
              </InputAdornment>
            ),
          }}/>
      </StyledDisabledQuickSearch>
    )
  }

  return (
    <StyledQuickSearch>
        <InputField
          placeholder="Suchen"
          ref={searchFieldRef}
          onChange={handleChange}
          onClick={handleTextFieldClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{cursor: 'pointer'}}
                  onClick={() => searchFieldRef?.current?.focus()}
                  fontSize="small"
                  color="secondary"/>
              </InputAdornment>
            )
          }}
        />

        <div ref={popupAnchor}>
          <Menu
            className="popover"
            PaperProps={{ className: 'popover' }}
            keepMounted
            open={!!searchText && open}
            onClose={handleMenuClose}
            anchorReference="anchorEl"
            anchorEl={searchFieldRef.current || undefined}

            role={undefined}
            disablePortal
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

            disableAutoFocus
            disableEnforceFocus
          >
            {/* Patienten  */}
            <List className="list">
              <ListSubheader className="subHeader">
                Patienten {`(${totalNumberOfSearchResultsPatienten || 0})`}
              </ListSubheader>
              <Divider />
              {searchResultsPatienten.slice(0, maxResultsPatienten).map((patient) => {
                return (
                  <ListItem
                    onClick={handleListTouchTap(patient.id, 'patient')}
                    className={
                      [
                        'listItem',
                        chosenEntity.type === 'patient' && chosenEntity.id === patient.id ? 'listItem--active' : ''
                      ].filter(Boolean).join(' ')
                    }
                    key={patient.id}
                  >
                    <ListItemText
                      primary={<PatientText patient={patient} showGeburtsdatum />}
                      secondary={getMatchingSearchFields(
                        patient,
                        searchText,
                        searchFieldsPatient,
                        searchFieldsToHidePatient,
                      )}
                    />
                    <IconMenuPatient
                      handleMenuClose={handleMenuClose}
                      patientId={patient.id}
                    />
                  </ListItem>
                )
              })}
            </List>

            {/* Ärzte */}
            <List className="list">
              <ListSubheader className="subHeader">Ärzte ({searchResultsAerzte.length})</ListSubheader>
              <Divider />
              {searchResultsAerzte.slice(0, maxResultsAerzte).map((arzt) => (
                <ListItem
                  onClick={handleListTouchTap(arzt.id, 'arzt')}
                  className={
                    [
                      'listItem',
                      chosenEntity.type === 'arzt' && chosenEntity.id === arzt.id ? 'listItem--active' : ''
                    ].filter(Boolean).join(' ')
                  }
                  key={arzt.id}
                >
                  <ListItemText
                    primary={buildArztString(arzt)}
                    secondary={getMatchingSearchFields(arzt, searchText, searchFieldsArzt, searchFieldsToHideArzt)}
                  />
                  <IconMenuArzt
                    handleMenuClose={handleMenuClose}
                    arztId={arzt.id}
                  />
                </ListItem>
              ))}
            </List>

            {/* Therapeuten */}
            <List className="list">
              <ListSubheader className="subHeader">Therapeuten ({searchResultsTherapeuten.length})</ListSubheader>
              <Divider />
              {searchResultsTherapeuten.slice(0, maxResultsTherapeuten).map((therapeut) => (
                <ListItem
                  onClick={handleListTouchTap(therapeut.id, 'therapeut')}
                  className={
                    [
                      'listItem',
                      chosenEntity.type === 'therapeut' && chosenEntity.id === therapeut.id ? 'listItem--active' : ''
                    ].filter(Boolean).join(' ')
                  }
                  key={therapeut.id}
                >
                  <ListItemText
                    primary={
                      [therapeut.nachname, therapeut.vorname].filter(Boolean).join(', ')
                    }
                  />
                  <IconMenuTherapeut
                    handleMenuClose={handleMenuClose}
                    therapeutId={therapeut.id}
                  />
                </ListItem>
              ))}
            </List>

            {/* Leistungen */}
            <List className="list">
              <ListSubheader className="subHeader">Leistungen ({searchResultsLeistungen.length})</ListSubheader>
              <Divider />
              {searchResultsLeistungen.slice(0, maxResultsLeistungen).map((leistung) => (
                <ListItem
                  onClick={handleListTouchTap(leistung.positionsnummer || leistung.id, 'leistung')}
                  className={
                    [
                      'listItem',
                        chosenEntity.type === 'leistung' &&
                        (chosenEntity.id === leistung.positionsnummer || chosenEntity.id === leistung.id)
                      ? 'listItem--active' : ''
                    ].filter(Boolean).join(' ')
                  }
                  key={leistung.positionsnummer || leistung.id}
                >
                  <ListItemText primary={`${leistung.beschreibung}, ${leistung.kuerzel}`} />

                  {leistung.id && <IconMenuLeistungPrivat
                    handleMenuClose={handleMenuClose}
                    leistungPrivatId={leistung.id}
                  />}
                </ListItem>
              ))}
            </List>
          </Menu>

          <Menu
            className="popover"
            PaperProps={{ className: 'popover' }}
            keepMounted
            open={
              chosenPatientInResults ||
              chosenArztInResults ||
              chosenTherapeutInResults ||
              chosenLeistungInResults ||
              false
            }
            onClose={handleEntityMenuClose}
            anchorReference="anchorEl"
            anchorEl={searchFieldRef.current || undefined}

            role={undefined}
            disablePortal
            getContentAnchorEl={null}

            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}

            disableAutoFocus
            disableEnforceFocus
          >
            {chosenPatientInResults && (
              <div className="listContainer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleViewPatientClick(chosenEntity.id)}
                  startIcon={
                    <VisibilityIcon /> // ion-eye
                  }
                >
                  Patientenkartei anzeigen
                </Button>
                {(chosenPatient.telefon || chosenPatient.mobil) ? (
                  <div>
                    <ListSubheader className="subHeader">Tel. | Mobil</ListSubheader>
                    <Divider />
                    <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                      {chosenPatient.telefon} {chosenPatient.telefon && chosenPatient.mobil && '|'}&nbsp;
                      {chosenPatient.mobil}
                    </span>
                  </div>
                ): null}
                <List className="list">
                  <ListSubheader className="subHeader">
                    Heilmittelverordnungen ({chosenPatientHeilmittelverordnungen.length})
                  </ListSubheader>
                  <Divider />
                  {chosenPatientHeilmittelverordnungen.slice(0, patientMaxHeilmittelverordnungen).map((hvo) => (
                    <ListItem key={hvo.id}>
                      <ListItemText primary={buildHeilmittelverordnungString(hvo, termine, leistungenKK)} />
                      <IconMenuHeilmittelverordnung
                        selectedHeilmittelverordnung={heilmittelverordnungen[hvo.id]}
                        handleMenuClose={handleMenuClose}
                      />
                    </ListItem>
                  ))}
                </List>
                <List className="list">
                  <ListSubheader className="subHeader">Termine ({chosenPatientTermine.length})</ListSubheader>
                  <Divider />
                  {chosenPatientTermine.slice(0, patientMaxTermine).map((termin) => (
                    <ListItem key={termin.id}>
                      <ListItemText primary={buildTerminString(termin, leistungenKK, leistungenPrivat)} />
                      <IconMenuTermin
                        dialogActions={dialogActions}
                        handleMenuClose={handleMenuClose}
                        terminId={termin.id}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {chosenArztInResults && (
              <div className="listContainer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleViewArztClick(chosenEntity.id)}
                  startIcon={
                    <VisibilityIcon /> // ion-eye
                  }
                >
                  Arztkartei aufrufen
                </Button>
                {chosenArzt.telefon ? (
                  <div>
                    <ListSubheader className="subHeader">Tel.</ListSubheader>
                    <Divider />
                    <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>{chosenArzt.telefon}</span>
                  </div>
                ): null}
                <List className="list">
                  <ListSubheader className="subHeader">
                    Heilmittelverordnungen ({chosenArztHeilmittelverordnungen.length})
                  </ListSubheader>
                  <Divider />
                  {chosenArztHeilmittelverordnungen.slice(0, arztMaxHeilmittelverordnungen).map((hvo) => (
                    <ListItem key={hvo.id}>
                      <ListItemText
                        primary={buildHeilmittelverordnungString(hvo, termine, leistungenKK, patienten, true)}
                      />
                      <IconMenuHeilmittelverordnung
                        selectedHeilmittelverordnung={heilmittelverordnungen[hvo.id]}
                        handleMenuClose={handleMenuClose}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {chosenTherapeutInResults && (
              <div className="listContainer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleTherapeutClick(chosenEntity.id)}
                  startIcon={
                    <VisibilityIcon /> // ion-eye
                  }
                >
                  Wochenansicht aufrufen
                </Button>
                <List className="list">
                  <ListSubheader className="subHeader">Termine ({chosenTherapeutTermine.length})</ListSubheader>
                  <Divider />
                  {chosenTherapeutTermine.slice(0, therapeutMaxTermine).map((termin) => (
                    <ListItem key={termin.id}>
                      <ListItemText primary={buildTerminString(termin, leistungenKK, leistungenPrivat)} />
                      <IconMenuTermin
                        dialogActions={dialogActions}
                        handleMenuClose={handleMenuClose}
                        terminId={termin.id}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {chosenLeistungInResults && (
              <div className="listContainer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleLeistungClick(chosenEntity.id)}
                  disabled={!chosenLeistung.id}
                  startIcon={
                    <EditIcon />  // ion-compose
                  }
                >
                  Leistung bearbeiten
                </Button>
                <List className="list">
                  {chosenLeistung.kuerzel ? (
                    <div>
                      <ListSubheader className="subHeader">Kürzel</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>{chosenLeistung.kuerzel}</span>
                    </div>
                  ): null}
                  {chosenLeistung.beschreibung ? (
                    <div>
                      <ListSubheader className="subHeader">Beschreibung</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>{chosenLeistung.beschreibung}</span>
                    </div>
                  ): null}
                  {chosenLeistung.regelbehandlungszeitMin ? (
                    <div>
                      <ListSubheader className="subHeader">minimale Regelbehandlungszeit</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                        {chosenLeistung.regelbehandlungszeitMin} min
                      </span>
                    </div>
                  ): null}
                  {chosenLeistung.regelbehandlungszeitMax ? (
                    <div>
                      <ListSubheader className="subHeader">maximale Regelbehandlungszeit</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                        {chosenLeistung.regelbehandlungszeitMax} min
                      </span>
                    </div>
                  ): null}
                  {chosenLeistung.verguetung ? (
                    <div>
                      <ListSubheader className="subHeader">Vergütung</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                        {(chosenLeistung.verguetung / 100).toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + ' €'}
                      </span>
                    </div>
                  ): null}
                  {chosenLeistung.mehrwertsteuer ? (
                    <div>
                      <ListSubheader className="subHeader">Mehrwertsteuer</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                        {chosenLeistung.mehrwertsteuer + ' %'}
                      </span>
                    </div>
                  ): null}
                  {chosenLeistung.positionsnummer ? (
                    <div>
                      <ListSubheader className="subHeader">Positionsnummer</ListSubheader>
                      <Divider />
                      <span style={{ paddingLeft: '16px', lineHeight: '48px' }}>
                        {chosenLeistung.positionsnummer}
                      </span>
                    </div>
                  ): null}
                </List>
              </div>
            )}
          </Menu>
        </div>
    </StyledQuickSearch>
  )
}

const mapStateToProps = (state) => ({
  aerzte: state.entities.aerzte,
  aerzteIds: state.ids.aerzte,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  heilmittelverordnungenIds: state.ids.heilmittelverordnungen,
  krankenkassen: state.krankenkassen,
  leistungenKK: state.leistungenKK,
  leistungenPrivat: state.entities.leistungenPrivat,
  patienten: state.entities.patienten,
  patientenIds: state.ids.patienten,
  termine: state.entities.termine,
  termineIds: state.ids.termine,
  users: state.entities.users,
  userIds: state.ids.users,
  // loading: state.loading.patienten,
  totalSearchResults: state.totalSearchResults,
  disabled: sUserShouldSetPin(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    heilmittelverordnungen: bindActionCreators(heilmittelverordnungenActions, dispatch),
    patienten: bindActionCreators(patientenActions, dispatch),
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(QuickSearch))
