import { Person as PersonIcon } from '@material-ui/icons';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as customersActions from '../../actions/customers';
import CustomerForm from '../../components/CustomerForm';
import PatientText from '../../components/PatientText/PatientText';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledEditCustomer } from './StyledEditCustomer';

const EditCustomer = ({ actions, customers, doctors }) => {
  const {serverHash, customer} = useParams();

  useEffect(() => {
    actions.customers.loadCustomer(customer, true);
  }, []);

  const saveForm = useCallback(async (data) => {
    data.krankenkasse = !data.krankenkasse || data.krankenkasse === 'null' 
      ? null 
      : data.krankenkasse;
    data.titel = !data.titel || data.titel === 'null' 
      ? null 
      : data.titel;
    await actions.customers.updateCustomer(data);
  }, [actions.customers.updateCustomer]);

  const patient = customers[customer];

  return (
    <StyledEditCustomer>
      <IconHeading
        icon={<PersonIcon />}
        text={
          <span>
            Patient bearbeiten | <PatientText patient={patient} showGender /> (
            <Link
              className="link"
              to={`/${serverHash}/contacts/customers/${patient && patient.id}`}
            >
              zur Patientenkartei
            </Link>
            )
          </span>
        }
      />
      <CustomerForm
        doctors={doctors}
        onSubmitFunc={saveForm}
        initialValues={patient}
        submitText="Patientenkartei aktualisieren"
        editMode
      />
    </StyledEditCustomer>
  );
};

const mapStateToProps = (state) => ({
  customers: state.entities.patienten,
  doctors: state.entities.aerzte,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    customers: bindActionCreators(customersActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
