import { KeyboardTimePicker } from '@material-ui/pickers'
import { getDate, getHours, getMinutes, setDate, setHours, setMinutes } from 'date-fns'
import { FC, useCallback, useEffect, useState } from 'react'
import { TIME } from '../../../constants/dateFormats'

interface Props {
  value?: Date | null,
  onChange?: (time: Date | null) => void,
  error?: boolean,
  helperText?: string,
  label?: string | Element,
  autoOk?: boolean,
  ampm?: boolean,
  format?: string,
  fullWidth?: boolean,
  className?: string,
  minutesStep?: number,
}

const isDateValid = (date: Date | null): boolean => {
  return !!date && !isNaN(date.getTime())
 }

const getValidDate = (date: Date): Date | null => {
  let newValue: Date | null = null;
  try {
    newValue = new Date(date)
    if(!isDateValid(newValue)) {
      newValue = null
    }
  } catch (error) {
    newValue = null
  }
  return newValue;
}

const getFullDate = (value) => {
  const day = getDate(new Date(value));
  const hours = getHours(new Date(value));
  const minutes = getMinutes(new Date(value));

  const currentHours = setHours(new Date(), hours);
  const currentMins = setMinutes(currentHours, minutes);
  const currentDate = setDate(currentMins, day);

  return currentDate;
}

const TimePickerField: FC<Props> = ({
  value,
  onChange,
  error,
  helperText,
  label,

  autoOk = true,
  ampm = false,
  format = TIME,
  fullWidth = true,
  className = '',
  minutesStep = 1,
}): JSX.Element => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null)

  useEffect(() => {
    let newValue: Date | null = null
    if(value) {
      try {
        newValue = new Date(value)
        if(isNaN(newValue.getTime())) {
          newValue = null
        }
      } catch (error) {
        console.log(error)
        newValue = null
      }
    }
    setSelectedTime(newValue)
  }, [value])

  // const handlTimeChange = (time: Date | null) => {
  //   setSelectedTime(time)
  //   onChange && onChange(time)
  // }

  const handleDateBlur = useCallback(
    (event) => {
      let newValue: Date | null = null
      if (selectedTime) {
        newValue = getValidDate(selectedTime)
      }
      setSelectedTime(newValue);

      if (!isDateValid(selectedTime)) {
        const currentFullDate = value ? getFullDate(value) : null;
        onChange && onChange(currentFullDate)
      } else {
        onChange && onChange(newValue)
      }
    },
    [selectedTime, setSelectedTime, onChange],
  )

  const handleDateChange = useCallback(
    (date) => {
      setSelectedTime(date)
      if (isDateValid(date)) {
        // preserve current date, because time picker gives today date on keyboard change
        const currentDate = getDate(new Date(value));
        onChange && onChange(setDate(date, currentDate)) // replace today day on current date
      }
    },
    [setSelectedTime, onChange],
  )


  return (
    <KeyboardTimePicker
      minutesStep={minutesStep}
      variant="inline"

      autoOk = {autoOk}
      fullWidth = {fullWidth}
      label={label}
      value={selectedTime}
      // onChange={(time: Date | null) => handlTimeChange(time)}
      format={format}
      onChange={(date) => handleDateChange(date)}
      onBlur={(event) => handleDateBlur(event)}
      ampm={ampm}
      error={error}
      helperText={helperText}
      className={className}
    />
  );
}

export default TimePickerField;
