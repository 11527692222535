import { Button, Typography } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
// import zxcvbn from 'zxcvbn'
import { changePin } from '../../redux/modules/pin'
import { sCurrentUser, sUserShouldSetPin } from '../../utils/users'
import PasswordInput from '../PasswordInput/PasswordInput'
import { StyledChangePin } from './StyledChangePin'

export const fields = ['oldPin', 'newPin', 'newPinConfirmation']

const ChangePin = ({ error, currentUser, fields, handleSubmit }) => {
  //TODO: Check why form fields values always empty string
  const { oldPin, newPin, newPinConfirmation } = fields

  const email = currentUser.email ?? 'lade...'
  const { pinDefined = false } = currentUser

  return (
    <StyledChangePin square>
      {!pinDefined && (
        <div className="noPinDefinedText">
          <Typography variant="body2" gutterBottom>
            Für den schnellen Wechsel zwischen verschiedenen Benutzern der Praxis ist zusätzlich zu Ihrem bestehenden
            Passwort eine vierstellige PIN erforderlich.
          </Typography>
          <Typography variant="body2">
            Bitte legen Sie diese jetzt fest, Ihr Passwort müssen Sie nicht ändern!
          </Typography>
        </div>
      )}
      <form className="changePinForm" onSubmit={handleSubmit} autoComplete="off">
        <h2 className="emailHeading">{email}</h2>
        <div>
          <Typography variant="body2">
            Mittels PIN ist der schnelle Userwechsel zwischen zwei Mitarbeitern einer Praxis ohne Passworteingabe
            möglich!
          </Typography>
          <Typography variant="body2">
            <a
              href="https://pododesk.freshdesk.com/support/solutions/articles/17000086750-schneller-praxis-und-benutzerwechsel-mit-pin"
              target="_blank"
              rel="noreferrer"
              className="pinHelpLink"
            >
              Hilfe dazu...
            </a>
          </Typography>
        </div>
        {pinDefined && (
          <div>
            <Typography variant="body2" gutterBottom>
              Bitte geben Sie Ihre aktuelle PIN ein:
            </Typography>
            <PasswordInput
              {...oldPin}
              fullWidth
              autoComplete="off"
              placeholder="Ihre PIN"
              maxLength={4}
              onlyAllowDigits={true}
            />
          </div>
        )}
        <div>
          {pinDefined ? (
            <Typography variant="body2" gutterBottom>
              Geben Sie eine neue PIN ein:
            </Typography>
          ) : (
            <Typography variant="body2" gutterBottom>
              Bitte geben Sie Ihre neue PIN ein:
            </Typography>
          )}
          <PasswordInput
            {...newPin}
            fullWidth
            autoComplete="off"
            placeholder="PIN mit 4 Ziffern eingeben"
            maxLength={4}
            onlyAllowDigits={true}
          />
        </div>
        <div>
          <Typography variant="body2" gutterBottom>
            Wiederholen Sie Ihre neue PIN:
          </Typography>
          <PasswordInput
            {...newPinConfirmation}
            fullWidth
            autoComplete="off"
            placeholder="PIN mit 4 Ziffern eingeben"
            maxLength={4}
            onlyAllowDigits={true}
          />
        </div>

        <div>{newPin.touched && newPinConfirmation.touched && error}</div>
        <Button fullWidth variant="contained" color="secondary" type="submit">
          Neue PIN speichern
        </Button>
        <div>
          <Typography variant="body2" gutterBottom>
            Bitte wählen Sie eine PIN bei der der Balken grün und der Status mindestens &quot;gut&quot; ist.
          </Typography>
          <Typography variant="body2">
            Sie haben Ihre PIN vergessen? Wenden Sie sich zwecks Rücksetzung bitte über{' '}
            <a
              onClick={() => window['fcWidget']?.open()}
              className="pinHelpLink">
              die Hilfefunktion
            </a>{' '}
            an den Support!
          </Typography>
        </div>
      </form>
    </StyledChangePin>
  )
}

const validate = (values, props) => {
  const errors: KeyValue<string> = {}

  const {
    currentUser,
    currentUser: { pinDefined },
  } = props

  // const excludeUserInputs = getExcludeWords(currentUser)

  if (pinDefined) {
    if (!values.oldPin) {
      errors._error = 'Bitte geben Sie Ihr PIN ein!'
    }
    if (typeof values.oldPin === 'string' && !values.oldPin.match(/^[0-9]{4}$/)) {
      errors._error = 'Die PIN muss genau aus 4 Ziffern bestehen!'
    }
  }

  if (!values.newPin) {
    errors._error = 'Bitte geben Sie Ihre neue PIN ein!'
  }
  if (typeof values.newPin === 'string' && !values.newPin.match(/^[0-9]{4}$/)) {
    errors._error = 'Die PIN muss genau aus 4 Ziffern bestehen!'
  }

  if (!values.newPinConfirmation) {
    errors._error = 'Bitte geben Sie Ihre neue PIN ein!'
  }
  if (typeof values.newPinConfirmation === 'string' && !values.newPinConfirmation.match(/^[0-9]{4}$/)) {
    errors._error = 'Die PIN muss genau aus 4 Ziffern bestehen!'
  }

  if (values.newPin !== values.newPinConfirmation) {
    errors._error = `Die${pinDefined ? ' letzten' : ''} beiden PIN Eingaben müssen miteinander übereinstimmen!`
  }

  // const { score } = zxcvbn(values.newPin || '', excludeUserInputs)
  // if (values.newPin && score < 1) {
  //   errors._error = 'Die neue PIN ist zu schwach!'
  // }

  return errors
}

// const getExcludeWords = (user) => {
//   const { ...other } = user
//   return Object.keys(other).map((key) => other[key])
// }

const mapStateToProps = (state) => ({
  currentUser: sCurrentUser(state),
  userShouldSetPin: sUserShouldSetPin(state),
  initialValues: {
    password: '',
    newPin: '',
    newPinConfirmation: '',
  },
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: bindActionCreators(changePin, dispatch),
})

export default reduxForm(
  {
    form: 'changePinForm',
    validate,
    fields,
  },
  mapStateToProps,
  mapDispatchToProps,
)(ChangePin)
