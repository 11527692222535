import * as types from '../constants/actionTypes'

export default function totalReferences(
  state = {
    aerzte: null,
  },
  action = undefined,
) {
  if (action.json && action.json.totalReferences) {
    switch (action.type) {
      case types.LOAD_HEILMITTELVERORDNUNGEN_SUCCESS:
        return {
          ...state,
          aerzte: {
            ...state.aerzte,
            ...action.json.totalReferences.aerzte,
          },
        }

      default:
    }
  }
  return state
}
