import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { RequestConfig } from './interface'

export function lookup(recipient): RequestConfig {
  return {
    types: [types.VERIFY_NUMBER, types.VERIFY_NUMBER_SUCCESS, types.VERIFY_NUMBER_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/sms/lookup`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          body: JSON.stringify({ recipient }),
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
    payload: { recipient },
  }
}
