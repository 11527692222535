import * as types from '../constants/actionTypes'

export default function selectedHeilmittelverordnung(state = null, action = undefined) {
  switch (action.type) {
    case types.SELECT_HEILMITTELVERORDNUNG:
      if (action.heilmittelverordnung) {
        return action.heilmittelverordnung
      }
      break
    case types.CREATE_DATE_SUCCESS:
      return null
    default:
  }
  return state
}
