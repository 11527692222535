import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton
} from '@material-ui/core'
import { FC, useEffect, useRef, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as fileActions from '../../actions/files'
import Cropper from '../../components/Cropper/Cropper'
import { IMAGE_MIME_TYPES } from '../../constants/misc'
import { apiServerHashSelector } from '../../selectors/selectors'
import { fetchSecure, sCredentials } from '../../shared/utils/auth'
import { hexStringFromArrayBuffer } from '../../shared/utils/dataFormats'
import { getApiUrl } from '../../utils/auth'
import FileUpload from '../FileUpload/FileUpload'

import {
  Edit as EditIcon, GetApp as GetAppIcon
} from '@material-ui/icons'

const otherMimeTypes = ['application/pdf']

interface LightboxImages {
  fileFromStore?: any,
  src?: string,
  imageTitle?: string
}

interface Props {
  style?: any,
  actions?: any,
  mimetypes?: string [],
  imageSize?: { width: number, height: number },
  maxImageFileSize?: number,
  maxOtherFileSize?: number,
  apiUrl?: string,
  auth0Credentials?: string,
  value?: Array<number | string>,
  dateien?: any,
  onChange?: (e?) => void,
  onFinish?: (e?) => void,
  buttonPrimaryText?: string,
  buttonSecondaryText?: string,
  assignTo: any,
  readOnly: boolean,
  isHVOFormChanged: boolean
}

const FileUploadWithLightbox: FC<Props> = ({
  style = {},
  actions,
  mimetypes = [...IMAGE_MIME_TYPES, ...otherMimeTypes],
  imageSize = { width: 1600, height: 1200 },
  maxImageFileSize = 10240,
  maxOtherFileSize = 1024,
  apiUrl,
  auth0Credentials,
  value = [],
  dateien,
  onChange,
  onFinish,
  buttonPrimaryText = 'Bis zu 6 Dateien wählen',
  buttonSecondaryText = 'Fotos max. 10mb | pdf max. 1mb',
  assignTo,
  readOnly = false,
  isHVOFormChanged = false
}) => {
  const cropperRef = useRef<Cropper>(null)

  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [lightboxIndex, setLightboxIndex] = useState<number>(0)


  const [imageSizeString, setImageSizeString] = useState<string>('');
  useEffect(() => {
    let newImageSizeString = '';
    if(imageSize) {
      newImageSizeString = `${imageSize.width}x${imageSize.height}`
    }
    setImageSizeString(newImageSizeString)
  }, [imageSize])


  const [lightboxImages, setLightboxImages] = useState<Array<LightboxImages>>([])

  useEffect(() => {
    const dateiIds = value;
    let newLightboxImages: Array<LightboxImages> = []

    if (Array.isArray(dateiIds)) {
      newLightboxImages = dateiIds
        .map((key) => dateien[key])
        .map((datei) => {
          return {
            fileFromStore: datei,
            src: datei && datei.resized && `data:image/png;base64,${datei.resized[imageSizeString]}`,
            imageTitle: datei?.name || '',
          }
        })
    }
    setLightboxImages(newLightboxImages)
  }, [value, dateien, imageSizeString])


  const [maxFileSizes, setMaxFileSizes] = useState<any>({})

  useEffect(() => {
    const newMaxFileSizes = {}
    IMAGE_MIME_TYPES.forEach((mimeType) => {
      newMaxFileSizes[mimeType] = maxImageFileSize
    })
    otherMimeTypes.forEach((mimeType) => {
      newMaxFileSizes[mimeType] = maxOtherFileSize
    })
    setMaxFileSizes(newMaxFileSizes)
  }, [])



  const _openLightbox = (idx) => {
    setLightboxOpen(true)
    setLightboxIndex(idx)
  }
  const _closeLightbox = () => {
    setLightboxOpen(false)
  }

  const _lightboxMoveNext = () => {
    const dateiIds = value;
    setLightboxIndex(prevIndex => {
      return (prevIndex + 1) % dateiIds.length
    })
  }
  const _lightboxMovePrev = () => {
    const dateiIds = value;
    setLightboxIndex(prevIndex => {
      return (prevIndex + dateiIds.length - 1) % dateiIds.length
    })
  }

  const _downloadFile = (datei) => async (event) => {
    event.stopPropagation()
    const URL = `${apiUrl}/dateien/${datei.id}`
    const response = await fetchSecure(URL, { credentials: 'include' }, auth0Credentials)
    const blob = await response.blob()
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.style = 'visibility:hidden'
    link.download = datei.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const _openCropper = (idx) => (event) => {
    event.stopPropagation()
    setCropperOpen(true)
    setLightboxIndex(idx)
  }
  const _closeCropper = () => {
    setCropperOpen(false)
  }


  const _saveCropped = () => {
    const { files: { updateFile } } = actions;
    cropperRef.current?.cropper?.getCroppedCanvas()
      .toBlob((blob) => {
        const reader = new window.FileReader()
        blob && reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
          const hexString = hexStringFromArrayBuffer(reader.result)
          const { fileFromStore } = lightboxImages[lightboxIndex]
          const image = {
            id: fileFromStore.id,
            size: blob?.size,
            type: 'image/jpeg',
            name: fileFromStore.name,
            data: hexString,
            bezeichnung: null,
          }
          updateFile({ file: image, options: { sizes: [imageSize] } })
        }
      });
    _closeCropper()
  }

  return <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      margin: '25px 0px 25px 0px',
      ...style
    }}>
    {/* files grid */}
    <div
      style= {{
        margin: '0 10px',
        width: '100%'
      }}>
      {<GridList cols={2} spacing={1} cellHeight={260}>
      {Array.isArray(value)
        ? value
            .map(key => dateien[key])
            .map((foto, idx) => {
              if (!foto || !foto.resized || !foto.resized[imageSizeString]) {
                return null
              }
              const isImageFormat = IMAGE_MIME_TYPES.indexOf(foto.contentType) !== -1
              return (
                <GridListTile
                  key={foto.id}>
                  <img
                    onClick={() => isImageFormat ? _openLightbox(idx) : {}}
                    style={{ cursor: isImageFormat ? 'pointer' : 'auto' }}
                    alt={foto.name}
                    src={`data:image/png;base64,${foto.resized[imageSizeString]}`} />
                  <GridListTileBar
                    title={foto.name}
                    actionIcon={
                      <div style={{ display: 'flex' }}>
                        <IconButton onClick={_downloadFile(foto)}>
                          <GetAppIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={_openCropper(idx)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </div>
                    }
                  />
                </GridListTile>
              )
            })
        : <div></div>}
    </GridList>}

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxImages[lightboxIndex].src}
          nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length].src}
          prevSrc={
            lightboxImages[(lightboxIndex + lightboxImages.length - 1) % lightboxImages.length].src
          }
          imageTitle={lightboxImages[lightboxIndex].imageTitle}
          onCloseRequest={_closeLightbox}
          onMovePrevRequest={_lightboxMovePrev}
          onMoveNextRequest={_lightboxMoveNext}
          reactModalStyle={{ overlay: { zIndex: '1000000' } }}
          toolbarButtons={[
            <IconButton
              style={{
                display: 'block',
                padding: 0,
                width: '32px',
                height: '32px',
                marginBottom: '5px',
              }}
              onClick={() => {
                setLightboxOpen(false)
                setCropperOpen(true)
              }}>
              <EditIcon color="primary" />
            </IconButton>
          ]}
        />
      )}
      {cropperOpen && (
        <Dialog
          disableBackdropClick
          open={cropperOpen}
          onClose={_closeCropper}>
          <DialogContent>
            <Cropper
              ref={cropperRef}
              src={lightboxImages[lightboxIndex].src}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              key="cancel"
              color="secondary"
              onClick={_closeCropper}>
              Abbrechen
            </Button>
            <Button
              variant="text"
              key="save"
              color="secondary"
              onClick={_saveCropped}>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
    {/* file upload */}
    <div
      style= {{
        margin: '0 10px',
        width: '100%'
      }}>
      <FileUpload
        value={value}
        mimetypes={mimetypes}
        multiple
        imageSizes={[imageSize]}
        maxFiles={6}
        onFinish={onFinish}
        maxFileSizes={maxFileSizes}
        onChange={onChange}
        buttonPrimaryText={buttonPrimaryText}
        buttonSecondaryText={buttonSecondaryText}
        assignTo={assignTo}
        readOnly={readOnly}
        isHVOFormChanged={isHVOFormChanged}
      />
    </div>
  </div>
};

const mapStateToProps = (state) => ({
  apiUrl: getApiUrl(apiServerHashSelector(state)),
  auth0Credentials: sCredentials(state),
  dateien: state.entities.dateien
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    files: bindActionCreators(fileActions, dispatch),
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadWithLightbox)
