import { PickerView, ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { format } from 'date-fns';
import { default as de } from 'date-fns/locale/de';
import { FC } from 'react';
import { StyledDatePickerToolbar } from './StyledDatePickerToolbar';
import { StyledToolbarButton } from './StyledToolbarButton';

const DatePickerToolbar: FC<ToolbarComponentProps> = ({
  date,
  isLandscape,
  setOpenView,
  openView,
}): JSX.Element => {
  const handleChangeViewClick = (view: PickerView) => {
    setOpenView && setOpenView(view);
  };

  return (
    <StyledDatePickerToolbar isLandscape={isLandscape}>
      <StyledToolbarButton
        onClick={() => handleChangeViewClick('date')}
        variant="h5"
        selected={openView === 'date'}
        label={format(date as Date, 'EEEEEE., dd. MMMM', { locale: de })}
      />
      <StyledToolbarButton
        onClick={() => handleChangeViewClick('year')}
        variant="h5"
        label={format(date as Date, 'yyyy')}
        selected={openView === 'year'}
      />
    </StyledDatePickerToolbar>
  );
};

export default DatePickerToolbar;
