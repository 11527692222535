import styled from 'styled-components';

export const StyledAllDayItems = styled.div`
  ${({ theme, gridConfig: {allDayItemHeight} }) => `
    z-index: 11;
    position: relative;

    .button{
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: unset;

      width: 100%;
      height: ${allDayItemHeight}px;
      line-height: 11px;
      text-align: left;
      min-width: none;
      margin: 0;
      padding: 0;
    }
  `
}`;
