/**
 ** MUI TextField wrapped in Controller from react-hook-form.
 ** Supports all commonly used props and has no control over
 ** any of them. All props are controlled in the form where
 ** the field is implemented in.
 */

import { InputBaseComponentProps, InputLabelProps, InputProps, TextField } from '@material-ui/core'
import { useEffect } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'

interface Props {
  control: any
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  type?: HTMLInputElement['type']
  name: string
  label: string
  inputProps?: InputBaseComponentProps
  InputProps?: InputProps
  rules?: RegisterOptions
  error?: boolean
  helperText?: React.ReactNode
  defaultValue?: string | number | null
  multiline?: boolean
  minRows?: number | string
  maxRows?: number | string
  InputLabelProps?: InputLabelProps
  placeholder?: string
  autoFocus?: boolean
  transform?: 'number'
  disabled?: boolean
  readOnly?: boolean
  shouldUnregister?: boolean
  onlyInteger?: boolean
  notNegative?: boolean
  onKeyDown?: (e?: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any
}

const ControlledInputField = ({
  control,
  fullWidth,
  variant,
  type,
  name,
  label,
  inputProps,
  InputProps,
  rules,
  error,
  helperText,
  multiline,
  minRows,
  maxRows,
  InputLabelProps,
  placeholder,
  autoFocus,
  transform,
  disabled,
  readOnly,
  shouldUnregister = false,
  onlyInteger,
  notNegative,
  onKeyDown,
}: Props): JSX.Element => {
  const { field } = useController({ name, rules, control, shouldUnregister: shouldUnregister })
  
  const transformToNumber = (value: string): number | string => {
    if (value) {
      const result = Number(value)

      return isNaN(result) ? 0 : result
    }

    return value
  }

  const transformFromNumber = (value: number): string => {
    return isNaN(value) ? '' : value.toString()
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (transform) {
      case 'number':
        return field.onChange(transformToNumber(e.target.value))

      default:
        return field.onChange(e.target.value)
    }
  }

  const getFieldValue = (): string => {
    switch (transform) {
      case 'number':
        return transformFromNumber(field.value)

      default:
        return field.value
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!onKeyDown) onKeyDown(e)

    if ((e.key === '-' || e.key === 'Subtract' || e.key === '+' || e.key === 'Add') && notNegative) e.preventDefault()
    if ((e.key === '.' || e.key === ',' || e.key === 'Separator') && onlyInteger) e.preventDefault()
  }

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    if (type === 'number') e.target.blur()
  }

  const InputPropsMerged = {
    ...InputProps,
    disabled,
    readOnly,
    style: {},
  }

  if (readOnly) {
    InputPropsMerged.style = { pointerEvents: 'none' }
  }

  return (
    <TextField
      {...field}
      onChange={handleChange}
      value={getFieldValue()}
      fullWidth={fullWidth}
      variant={variant}
      type={type}
      label={rules?.required ? `${label} *` : label}
      inputProps={inputProps}
      InputProps={InputPropsMerged}
      onKeyDown={handleKeyDown}
      error={error}
      helperText={helperText}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      InputLabelProps={InputLabelProps}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onWheel={handleWheel}
    />
  )
}

export default ControlledInputField
