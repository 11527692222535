import { Divider } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import styled from 'styled-components'

const StyledDivider = styled(Divider)`
  margin: ${({ sideMargin }: {sideMargin: number | string }) => `30px ${sideMargin}px`};
  background-color: ${fade('#a1ccd1', 0.3)};
`

interface Props {
  sideMargin?: number | string
}

const FormDivider = ({ sideMargin = 0 }: Props) => <StyledDivider sideMargin={sideMargin} />

export default FormDivider
