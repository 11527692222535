import { Avatar, IconButton, Tooltip, Typography } from '@material-ui/core'
import {
  CalendarToday as CalendarTodayIcon,
  Edit as EditIcon,
  ExitToApp as ExitToAppIcon,
  LocalHospital as LocalHospitalIcon,
  Lock as LockIcon,
  People as PeopleIcon,
  PersonAdd as PersonAddIcon,
  SwapHoriz as SwapHorizIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import CalendarPlusIcon from '../../assets/icons/calendar-plus.svg'
import { downgradeCredentials, logout } from '../../redux/modules/auth'
import { lockScreen } from '../../redux/modules/lockscreen'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { sApiServer } from '../../shared/utils/auth'
import { sActiveUsers, sCurrentUser } from '../../shared/utils/users'
import { isApiServerSelected, sCredentialsDowngraded } from '../../utils/auth'
import PracticesList from './PracticesList/PracticesList'
import { StyledMenu } from './StyledMenu'
import UpgradeCredentialsForm from './UpgradeCredentialsForm/UpgradeCredentialsForm'
import UsersList from './UsersList/UsersList'
import { useAuth } from '../AuthProvider/useAuth'


const BurgerMenu = ({
  actions,
  apiServerSelected,
  currentUser,
  currentApiServer,
  users,
  practices,
  credentialsDowngraded,
  open,
  onClose,
  practiceView,
  loading,
  isStartPage,
  isPaymentPage,
}) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [avatar, setAvatar] = useState<string>('')
  // const [scrollHeight, setScrollHeight] = useState(0);

  const navigate = useNavigate()
  const { serverHash } = useParams()
  const location = useLocation()
  const { signout } = useAuth()

  useEffect(() => {
    let newAvatar = ''
    if (currentUser) {
      newAvatar = currentUser.avatar ?? currentUser.avatarFallback ?? ''
    }
    setAvatar(newAvatar)
  }, [currentUser])

  const lockScreenClicked = useCallback(() => {
    // navigate(`/${serverHash}/calendar`);
    actions.downgradeCredentials()
    actions.lockScreen()
  }, [actions.downgradeCredentials, actions.lockScreen])

  const changePracticeClicked = useCallback(
    (e) => {
      if (!credentialsDowngraded) {
        navigate('/startbereich')
      } else {
        e.preventDefault()
        setPopoverOpen(true)
        setAnchorEl(e.currentTarget)
      }
    },
    [credentialsDowngraded, setPopoverOpen, setAnchorEl],
  )

  const handleRequestClose = useCallback(() => {
    setPopoverOpen(false)
  }, [setPopoverOpen])

  // useEffect(() => {
  //   const wrapper = document.querySelector('.main-content-wrapper');

  //   if(wrapper) {
  //     if(wrapper.scrollHeight < 800) {
  //       setScrollHeight(wrapper.scrollHeight + 300);
  //     }
  //     else {
  //       setScrollHeight(wrapper.scrollHeight);
  //     }
  //   }
  // }, [location, loading])

  return (
    <StyledMenu
      BackdropProps={{ style: { top: 64 }, onClick: onClose }}
      PaperProps={{
        className: 'drawerPaper',
        // style: {height: `${scrollHeight}px` || 0}
      }}
      className={clsx({ open })}
      open={open}
      variant="permanent"
    >
      <div className={'container'}>
        {practiceView && <StyledLink
          disabled
          onClickFunc={lockScreenClicked}
          startIcon={<LockIcon />}
          className="styledLink"
          fullWidth
        >
          pododesk sperren {/* lock pododesk */}
        </StyledLink>}
        <div className="leftSideContainer">
          <div className="profileContainer">
            <Avatar src={avatar} className="avatar" />
            <div className="typography">
              <div className="personalInfo">
                <Typography variant="body1">
                  {[currentUser?.vorname, currentUser?.nachname].filter(Boolean).join(' ')}
                </Typography>
                <div className="editBtn">
                  <Tooltip title="Profil bearbeiten">
                    <IconButton component={Link} to={
                      serverHash
                        ? `/${serverHash}/settings/passwort`
                        : '/startbereich/profil/passwort'
                    }>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Typography variant="body2">
                {currentUser?.email ?? ''}
              </Typography>
            </div>
          </div>
        </div>
        {practiceView && <div className="leftSideContainer">
          {popoverOpen && (
            <UpgradeCredentialsForm
              open={popoverOpen}
              anchorEl={anchorEl}
              onRequestClose={handleRequestClose}
              forwardToStartbereich
            />
          )}
          {/* <UsersList users={users} currentUserEmail={currentUser ? currentUser.email : null} /> */}
          <PracticesList practices={practices} currentPracticeId={currentApiServer?.id} />
        </div>}
        <div className="moreLinksMenu">
          <StyledLink
            to={`/${serverHash}/contacts/customers`}
            isRelative
            startIcon={<PeopleIcon />}
            disabled={!apiServerSelected || !practiceView}
            className="styledMenuLink"
            fullWidth
          >
            Patientenkartei
          </StyledLink>
          <StyledLink
            to={`/${serverHash}/contacts/customers/add`}
            isRelative
            startIcon={<PersonAddIcon />}
            disabled={!apiServerSelected || !practiceView}
            className="styledMenuLink"
            fullWidth
          >
            Patient neu anlegen
          </StyledLink>
          <StyledLink
            to={`/${serverHash}/calendar`}
            isRelative
            startIcon={<CalendarPlusIcon fill={!apiServerSelected || !practiceView ? 'rgba(0, 0, 0, 0.26)' : 'black'} />}
            disabled={!apiServerSelected || !practiceView}
            className="styledMenuLink plus-calendar"
            fullWidth
          >
            Kalender
          </StyledLink>
          <StyledLink
            to={`/${serverHash}/calendar/free`}
            isRelative
            startIcon={<CalendarTodayIcon />}
            disabled={!apiServerSelected || !practiceView}
            className="styledMenuLink"
            fullWidth
          >
            Termin vereinbaren
          </StyledLink>
          <StyledLink
            to={`/${serverHash}/contacts/doctors`}
            isRelative
            startIcon={<LocalHospitalIcon />}
            disabled={!apiServerSelected || !practiceView}
            className="styledMenuLink"
            fullWidth
          >
            Ärzte
          </StyledLink>
        </div>
        <div className="startbereichLogoutContainer">
          {(practiceView || isPaymentPage) && <StyledLink
            onClickFunc={changePracticeClicked}
            startIcon={<SwapHorizIcon fontSize="inherit" />}
            className="practiceOverviewLink"
            iconSize="large"
            fullWidth
          >
            zum Startbereich
          </StyledLink>}
          <StyledLink
            onClickFunc={signout}
            startIcon={<ExitToAppIcon fontSize="inherit" />}
            className="styledLink"
            iconSize="large"
            fullWidth
          >
            pododesk logout
          </StyledLink>
        </div>
      </div>
    </StyledMenu>
  )
}

const mapStateToProps = (state) => ({
  notification: state.notification,
  apiServerSelected: isApiServerSelected(state.auth),
  currentUser: sCurrentUser(state),
  currentApiServer: sApiServer(state),
  users: sActiveUsers(state),
  practices: state.auth.apiServers,
  credentialsDowngraded: sCredentialsDowngraded(state),
  loading: state.loading,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logout, lockScreen, downgradeCredentials }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(BurgerMenu))
