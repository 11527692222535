/*
 * Middleware zum Verwalten der Websocket Verbindung
 * */

import { REMOVE_AUTHENTICATION, CURRENT_USER_AUTH_SUCCESS } from '../../redux/modules/auth'
import { initializeSocket, destroySocket } from '../../actions/socket'
import { apiServerHashSelector } from '../../selectors/selectors'

const socketMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { type } = action
    if (type === REMOVE_AUTHENTICATION) {
      dispatch(destroySocket())
    }

    next(action)

    if (type === CURRENT_USER_AUTH_SUCCESS && apiServerHashSelector(getState())) {
      dispatch(initializeSocket())
    }
  }

export default socketMiddleware
