import {
  IconButton, Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip
} from '@material-ui/core'
import {
  Add as AddIcon, EuroSymbol as EuroSymbolIcon
} from '@material-ui/icons'
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loadBuchungen, selectDay } from '../../actions/kassenbuch'
import { clearNotification, showNotification } from '../../actions/notification'
// import AddDateDialog from '../../components/AddDateDialog/AddDateDialog'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ContextMenuBuchung } from '../../components/MenuBuchung'
import { GERMAN_DATE_SHORT_YEAR_FNS, ISO8601DATE_FNS } from '../../constants/dateFormats'
import { apiServerHashSelector, praxisstammdatenSelector } from '../../selectors/selectors'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { fetchSecure, sCredentials } from '../../shared/utils/auth'
import { getApiUrl } from '../../utils/auth'
import { sleep } from '../../utils/misc'
import AddKassenbucheintragDialog from '../AddKassenbucheintragDialog/AddKassenbucheintragDialog'
import KassenbuchSidebar from './KassenbuchSidebar'
import {
  sAeltesteBuchung,
  sBuchungIdsMitTermin,
  sJuengsteBuchung,
  sKassenbuchOhneKorrekturbuchungen,
  sKorrekturbuchungen,
  sLetzteVorherigeBuchung,
  sSelectedDay,
  zeitraumMapping
} from './selectors'

const StyledDiv = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;

    .contentKassenbuch {
      flex: 1;
    }

    .topBar {
      display: flex;
      flex-direction: row;
      height: 32px;
    }

    .sideBar {
      width: 310px;
    }

    .emptyMessage {
      padding: 20px;
    }

    .startEnd {
      display: flex;
      flex-direction: row;
      font-size: small;
      padding: 15px;
      align-items: center;
    }

    .downloadFile {
      color: ${theme.palette.secondary.main};
      text-decoration: none;
      margin-right: 15px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .addNewButton {
      background-color: ${theme.palette.secondary.main};
      color: #fff;
    }

    .downloadAddNewContainer {
      margin-left: auto;
    }

    .tableHead {
      background-color: ${theme.palette.primary.dark};
      text-transform: uppercase;
    }
  `}
`

const columns = [
  {
    label: '#',
    name: 'buchungsnummer',
  },
  {
    label: 'Belegnummer',
    name: 'belegnummer',
  },
  {
    label: 'Datum',
    name: 'datum',
  },
  {
    label: 'erfasst von',
    name: 'createdBy',
  },
  {
    label: 'Betreff',
    name: 'betreff',
  },
  {
    label: 'Betrag',
    name: 'betrag',
  },
  {
    label: 'Kassenbestand',
    name: 'kassenbestand',
  },
]

const Kassenbuch = ({
  buchungen = [],
  korrekturbuchungen,
  zeitraum,
  selectedDay,
  actions,
  juengsteBuchung,
  users,
  buchungenIdsMitTermin,
  api,
  firmenbezeichnung,
  letzteVorherigeBuchung,
  praxisstammdaten,

  dialogActions,
}) => {

  const [buchungenPointerLocation, setBuchungenPointerLocation] = useState<{ mouseX: null | number; mouseY: null | number }>({
    mouseX: null,
    mouseY: null,
  })
  const [selectedBuchung, setSelectedBuchung] = useState<CashbookRecord | null>(null)

  const [kassenbucheintragDialogOpen, setKassenbucheintragDialogOpen] = useState<boolean>(false)

  const handleKassenbucheintragDialogOpen = useCallback(() => {
    setKassenbucheintragDialogOpen(true)
  }, [setKassenbucheintragDialogOpen])

  const handleKassenbucheintragDialogClose = useCallback(() => {
    setKassenbucheintragDialogOpen(false)
  }, [setKassenbucheintragDialogOpen])

  const loadBuchungen = () => {
    const unitOfTime = zeitraumMapping[zeitraum];

    if (!unitOfTime || !selectedDay) return;

    if (unitOfTime === 'day') {
      const start = startOfDay(selectedDay)
      const end = endOfDay(selectedDay)

      actions.loadBuchungen(start, end)
    }
    if (unitOfTime === 'week') {
      const start = startOfWeek(selectedDay, { weekStartsOn: 1 })
      const end = endOfWeek(selectedDay, { weekStartsOn: 1 })

      actions.loadBuchungen(start, end)
    }
    if (unitOfTime === 'month') {
      const start = startOfMonth(selectedDay)
      const end = endOfMonth(selectedDay)

      actions.loadBuchungen(start, end)
    }
  }

  useEffect(() => {
    loadBuchungen()
    actions.selectDay(new Date())
  }, [])

  useEffect(() => {
    loadBuchungen()
  }, [selectedDay, zeitraum])

  const openContextMenu = useCallback((e, buchung) => {
    e.persist()
    setBuchungenPointerLocation({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    })
    setSelectedBuchung(buchung)
  }, [setBuchungenPointerLocation, setSelectedBuchung])

  const closeContextMenu = useCallback(() => {
    setBuchungenPointerLocation({ mouseX: null, mouseY: null })
    setSelectedBuchung(null)
  }, [setBuchungenPointerLocation, setSelectedBuchung])

  const downloadFile = useCallback(async (tag) => {
    await actions.showNotification('Download wird gestartet...')
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/kassenbuch/pdf/${format(tag, ISO8601DATE_FNS)}`
    const response = await fetchSecure(URL, { credentials: 'include' }, auth0Credentials)
    const blob = await response.blob()
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.style = 'visibility:hidden'
    const now = new Date()
    link.download = `Kassenbericht ${firmenbezeichnung} vom ${format(tag, GERMAN_DATE_SHORT_YEAR_FNS)} - ${format(
      now,
      'dd.MM.yy HH-mm',
    )}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    await sleep(3)
    await actions.clearNotification()
  }, [actions.showNotification, api, firmenbezeichnung, actions.clearNotification])

  const formatToCurrency = (value) =>
    Intl.NumberFormat('de-DE', { style: 'currency', currency: praxisstammdaten.currency, useGrouping: false }).format(value)

  const printAnfangsbestand = () =>
    letzteVorherigeBuchung ? formatToCurrency(letzteVorherigeBuchung.kassenbestand / 100) : '-'

  return (
    <>
      <StyledDiv>
        <div className="contentKassenbuch">
          <IconHeading text="Kassenbuch" icon={<EuroSymbolIcon />} />
          <div className="topBar">
            <StyledLink to="../kassenbuch/tag" isRelative fullWidth borderEnd>
              Tagesansicht
            </StyledLink>
            <StyledLink to="../kassenbuch/woche" isRelative fullWidth borderEnd>
              Wochenansicht
            </StyledLink>
            <StyledLink to="../kassenbuch/monat" isRelative fullWidth>
              Monatsansicht
            </StyledLink>
          </div>
          <div className="startEnd">
            Anfangsbestand: {printAnfangsbestand()}&nbsp;| Endbestand:&nbsp;
            {juengsteBuchung ? formatToCurrency(juengsteBuchung.kassenbestand / 100) : printAnfangsbestand()}
            <div className="downloadAddNewContainer">
              {zeitraum === 'tag' && (
                <a className="downloadFile" onClick={() => downloadFile(selectedDay)}>
                  als PDF herunterladen
                </a>
              )}
              <Tooltip enterDelay={500} title="Neuer Kassenbucheintrag" placement="bottom">
                <IconButton onClick={handleKassenbucheintragDialogOpen} size="medium" className="addNewButton">
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((col) => (
                    <TableCell size="small" className="tableHead" key={col.name}>
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {buchungen.map((buchung: any) => {
                  const buchungHatTermin = buchungenIdsMitTermin.indexOf(buchung.id) !== -1

                  return (
                    <TableRow 
                      key={buchung.id} 
                      style={{
                        cursor: buchungHatTermin ? 'pointer': 'default'
                      }}
                      onClick={buchungHatTermin ? (e) => openContextMenu(e, buchung) : () => {}}>
                      <TableCell>{buchung.buchungsnummer}</TableCell>
                      <TableCell>{buchung.belegnummer}</TableCell>
                      <TableCell>{format(buchung.createdAt, GERMAN_DATE_SHORT_YEAR_FNS)}</TableCell>
                      <TableCell>{`${users[buchung.createdBy].vorname} ${
                        users[buchung.createdBy].nachname
                      }`}</TableCell>
                      <TableCell>{buchung.betreff}</TableCell>
                      <TableCell>{formatToCurrency(buchung.betrag / 100)}</TableCell>
                      <TableCell>{formatToCurrency(buchung.kassenbestand / 100)}</TableCell>
                    </TableRow>
                  )
                })}
                {korrekturbuchungen.map((buchung) => (
                  <TableRow key={buchung.id}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{format(buchung.createdAt, GERMAN_DATE_SHORT_YEAR_FNS)}</TableCell>
                    <TableCell>{`${users[buchung.createdBy].vorname} ${users[buchung.createdBy].nachname}`}</TableCell>
                    <TableCell>{buchung.betreff}</TableCell>
                    <TableCell>{formatToCurrency(buchung.betrag / 100)}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {buchungen.length === 0 && (
            <div className="emptyMessage">
              Für den gewählten Zeitraum sind keine Einträge im Kassenbuch vorhanden.
              <br />
              Sie können Barzahlungen an jedem Termin erfassen oder einen manuellen Kassenbucheintrag über das Menü
              anlegen.
            </div>
          )}
        </div>
        <div className="sideBar">
          <KassenbuchSidebar />
        </div>
        {/* <AddDateDialog /> */}

        {selectedBuchung && (
          <ContextMenuBuchung
            pointerLocation={buchungenPointerLocation}
            handleClose={closeContextMenu}
            buchung={selectedBuchung}
            dialogActions={dialogActions}
          />
        )}
      </StyledDiv>
      <AddKassenbucheintragDialog
        onRequestClose={handleKassenbucheintragDialogClose}
        open={kassenbucheintragDialogOpen}
      />
    </>
  )
}

const mapStateToProps = (state, props: { dialogActions?: Object; zeitraum?: string;}) => ({
  dialogs: state.dialogs,
  buchungen: sKassenbuchOhneKorrekturbuchungen(state, props),
  korrekturbuchungen: sKorrekturbuchungen(state, props),
  selectedDay: sSelectedDay(state),
  aeltesteBuchung: sAeltesteBuchung(state, props),
  juengsteBuchung: sJuengsteBuchung(state, props),
  buchungenIdsMitTermin: sBuchungIdsMitTermin(state, props),
  users: state.entities.users,
  api: {
    apiUrl: getApiUrl(apiServerHashSelector(state)),
    auth0Credentials: sCredentials(state),
  },
  firmenbezeichnung: praxisstammdatenSelector(state) ? praxisstammdatenSelector(state).firmenbezeichnung : '',
  letzteVorherigeBuchung: sLetzteVorherigeBuchung(state, props),
  praxisstammdaten: praxisstammdatenSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadBuchungen,
      showNotification,
      clearNotification,
      selectDay,
    },
    dispatch,
  ),
})

const KassenbuchWithRedux = connect(mapStateToProps, mapDispatchToProps)(memo(Kassenbuch))

export default (props): JSX.Element  => {
  const params = useParams();

  return <KassenbuchWithRedux zeitraum={params.zeitraum} {...props}/>;
}
