import { Button, ListItem, ListItemText } from '@material-ui/core'
import { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { AbbreviationList, AbbreviationSettingsSidebarContainer } from './StyledAbbreviationSettings'
import { bindActionCreators } from 'redux'
import * as abbreviationsActions from '../../actions/abbreviations'

interface Props {
  onAddNewClick: () => void
  onExistingClick: (roomId: number) => void
  selectedAbbreviation: number | null
  abbreviations: any
  actions: any
}

const AbbreviationSettingsSidebar = ({
  onAddNewClick,
  onExistingClick,
  selectedAbbreviation,
  abbreviations,
  actions,
}: Props): JSX.Element => {
  const abbreviationsArray = useMemo(() => {
    console.log('abbreviations: ', abbreviations)
    if (!abbreviations) return []

    return Object.keys(abbreviations).map((key) => abbreviations[key])
  }, [abbreviations])

  // const fileInput = useRef<HTMLInputElement>(null)

  // const handleAutoTextExpanderImport = (): void => {
  //   fileInput?.current?.click()
  // }

  // const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
  //   if (!!e?.target?.files?.length) {
  //     const uploadedFile = e.target.files[0]
  //     const convertedToJson = JSON.parse(new TextDecoder().decode(await uploadedFile.arrayBuffer()))

  //     let isValid = true

  //     for (const key in convertedToJson) {
  //       if (typeof convertedToJson[key] !== 'string') {
  //         isValid = false
  //         break
  //       }
  //     }

  //     if (!isValid) return

  //     await actions.abbreviations.createMultipleAbbreviations(convertedToJson)
  //   }
  // }

  return (
    <AbbreviationSettingsSidebarContainer square elevation={2}>
      {/* {!abbreviationsArray?.length && (
        <>
        <input className="att-import" name="att-import" type="file" accept='application/json' aria-hidden="true" ref={fileInput} onChange={handleFileInputChange} />
          <Button
            color="secondary"
            variant="text"
            style={{ marginBottom: 12 }}
            onClick={handleAutoTextExpanderImport}
            fullWidth
          >
            Import Auto Text Expander
          </Button>
        </>
      )} */}
      <Button color="secondary" variant="contained" style={{ marginBottom: 12 }} onClick={onAddNewClick} fullWidth>
        Neuen Textbaustein hinzufügen
      </Button>
      <AbbreviationList dense>
        {abbreviationsArray.map((a) => (
          <ListItem
            onClick={() => onExistingClick(a.id)}
            key={a.id}
            className={selectedAbbreviation === a.id ? 'listItemActive' : 'listItem'}
          >
            <ListItemText
              primary={a.abbreviation}
              primaryTypographyProps={{ variant: 'body1' }}
              secondary={a.fullText}
              secondaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        ))}
      </AbbreviationList>
    </AbbreviationSettingsSidebarContainer>
  )
}

const mapStateToProps = (state) => ({
  abbreviations: state.entities.abbreviations,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    abbreviations: bindActionCreators(abbreviationsActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AbbreviationSettingsSidebar)
