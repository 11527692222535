/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  Bluetooth handling.
 *
 *  history:
 *  *.2021 bwe                    created
 */

import { Tools } from '../utils/general/tools'

export enum BT_connection_state {
  connected, // Bluetooth is connected
  not_connected, // Bluetooth is not connected
  bluetooth_adapter_not_available, // There was no bluetooth adapter found.
  web_bluetooth_not_available, // Web Bluetooth is not supported by the browser.
  canceled_by_user, // Connect-Error: NotFoundError: User cancelled the requestDevice() chooser
  unknown_error,
}

class ReceiveBuffer {
  aData: Uint8Array | null
  msgIsReceived: boolean
  readIndex: number

  constructor() {
    this.aData = null // The received data. Type: Uint8Array
    this.msgIsReceived = false // True = message was received. Type boolean
    this.readIndex = 0 // Index with the recieved data.
  }

  newMsg(data: DataView) {
    this.aData = new Uint8Array(data.byteLength)

    for (let i = 0; i < data.byteLength; i++) {
      this.aData[i] = data.getUint8(i)
    }

    this.msgIsReceived = true
  }

  getMsgByte() {
    let msgByte = 0
    if (this.msgIsReceived && this.aData) {
      msgByte = this.aData[this.readIndex]
      this.readIndex = this.readIndex + 1

      // Check buffer overflow
      if (this.readIndex >= this.aData.length) {
        this.readIndex = 0
        this.msgIsReceived = false
      } else {
        /* no overflow */
      }
    } else {
      /* no message received */
    }

    return msgByte
  }
}

export class Orga_care_WebBluetooth_interface {
  public static readonly characteristicUuid: string = '69489a3a-e217-41e3-bfeb-7f035e457460' // UUID for SppOverBle Characteristic of Orga 930 care
  public static readonly serviceUuid: string = '7186cc4e-6837-4a3d-8670-4f85be0e2849' // UUID for SppOverBle Service of Orga 930 care
  private myGattServer: BluetoothRemoteGATTServer | null = null
  private myBluetoothDevice: BluetoothDevice | null = null
  private myCharacteristic: BluetoothRemoteGATTCharacteristic | null = null // Gatt characterisic 'SppOverBle'
  public connectionState: BT_connection_state = BT_connection_state.not_connected // Our Bluetooth connection state.
  receiveBuffer: ReceiveBuffer // Receive buffer for Bluetooth messages.
  public name_of_remote: string | undefined // Name of the remote device, e.g. 'Orga 930 care123'.
  public no_of_received_msgs = 0 // Total number of received Bluetooth messages.
  public no_of_transmitted_msgs = 0 // Total number of transmitted Bluetooth messages.
  private careIsReady = false

  disonnectCallback: () => void

  constructor(disonnectCallback: () => void) {
    this.myGattServer = null
    this.myBluetoothDevice = null
    this.myCharacteristic = null
    this.connectionState = BT_connection_state.not_connected
    this.receiveBuffer = new ReceiveBuffer()
    this.disonnectCallback = disonnectCallback

    // If WebBluetooth is not enabled this interface should not be created.
    if (Orga_care_WebBluetooth_interface.isWebBluetoothEnabled() == false) {
      throw new Error(
        'Web Bluetooth API is not available.\n Please make sure the "Experimental Web Platform features" flag is enabled.',
      )
    } else {
      /*no error*/
    }
  }

  public async connect(callback: (careIsReady: boolean) => void): Promise<BT_connection_state> {
    if (this.connectionState != BT_connection_state.connected) {
      console.log('Requesting Bluetooth Device ORGA 930 care')
      await navigator.bluetooth
        .requestDevice({
          filters: [{ services: [Orga_care_WebBluetooth_interface.serviceUuid] }],
        })
        .then((device) => {
          this.myBluetoothDevice = device
          this.myBluetoothDevice.addEventListener('gattserverdisconnected', (ev) => {
            this.onDisconnected(ev)
          })

          console.log('Connecting to GATT Server...')

          if (typeof this.myBluetoothDevice !== 'undefined' && typeof this.myBluetoothDevice.gatt !== 'undefined') {
            return this.myBluetoothDevice.gatt
              .connect()
              .then((server) => {
                this.myGattServer = server

                console.log('Getting Service...')
                return server.getPrimaryService(Orga_care_WebBluetooth_interface.serviceUuid)
              })
              .then((service) => {
                console.log('Getting Characteristic...')
                return service.getCharacteristic(Orga_care_WebBluetooth_interface.characteristicUuid)
              })
              .then((characteristic) => {
                this.myCharacteristic = characteristic // Save SppOverBle characterisic

                // If the characteristic supports notity and writeWithoutResponse commands can be send to the bluetooth device
                if (characteristic.properties.notify && characteristic.properties.writeWithoutResponse) {
                  console.log('Start reading characteristic...')
                  this.start_receiving()

                  this.askIfCareIsReady(callback)

                  this.connectionState = BT_connection_state.connected
                  if (this.myBluetoothDevice && this.myBluetoothDevice.gatt) {
                    this.name_of_remote = this.myBluetoothDevice.gatt.device.name
                  }
                } else {
                  this.connectionState = BT_connection_state.not_connected
                }
              })
          }
        })
        .catch((error) => {
          console.log('Connect-Error: ' + error.toString())
          if (error.toString().includes('Bluetooth adapter not available')) {
            this.connectionState = BT_connection_state.bluetooth_adapter_not_available
          } else if (error.toString().includes('Web Bluetooth API is not available.')) {
            this.connectionState = BT_connection_state.web_bluetooth_not_available
          } else if (error.toString().includes('User cancelled the requestDevice')) {
            this.connectionState = BT_connection_state.canceled_by_user
          } else {
            this.connectionState = BT_connection_state.unknown_error
          }
        })
    } else {
      console.log('Bluetooth device is already connected.')
    }

    return this.connectionState
  }

  private async askIfCareIsReady(callback: (error: boolean) => void) {
    this.careIsReady = false

    const conreq = new Uint8Array([0x43, 0x6f, 0x6e, 0x52, 0x65, 0x71, 0x23])
    const maxLoops = 30

    for (let loops = 0; loops < maxLoops; loops++) {
      await new Promise((resolve) => setTimeout(resolve, 1000))

      if (this.careIsReady) {
        break
      }

      this.myCharacteristic?.writeValue(conreq)
    }

    callback(this.careIsReady)
  }

  private checkIfCareIsReady(buffer: Uint8Array) {
    const conalo = new Uint8Array([0x43, 0x6f, 0x6e, 0x41, 0x6c, 0x6f])

    if (Tools.equals(buffer, conalo)) {
      this.careIsReady = true
    }
  }

  // Function to read the bluetooth 'device_information' service
  private getDeviceInformation(): void {
    if (this.myBluetoothDevice && this.myCharacteristic && this.myGattServer) {
      this.myGattServer
        .getPrimaryService('device_information')
        .then((service) => {
          console.log('Getting Device Information Characteristics...')
          return service.getCharacteristics()
        })
        .then((characteristics) => {
          let queue = Promise.resolve()
          const decoder = new TextDecoder('utf-8')
          characteristics.forEach((characteristic) => {
            queue = queue
              .then((_) => characteristic.readValue())
              .then((value) => {
                console.log('Characteristic-value: ' + decoder.decode(value))
              })
          })
          return queue
        })
        .catch((error) => {
          console.log('Bluetooth error' + error)
        })
    }
  }

  public disconnect(): BT_connection_state {
    if (this.connectionState === BT_connection_state.connected) {
      if (this.myBluetoothDevice && this.myBluetoothDevice.gatt) {
        if (this.myBluetoothDevice.gatt.connected) {
          this.myBluetoothDevice.gatt.disconnect()
        } else {
          console.log('> Bluetooth Device is already disconnected')
        }
        this.connectionState = BT_connection_state.not_connected
      } else {
        throw new Error('myBluetoothDevice is undefined.')
      }
    }
    return this.connectionState
  }

  public static isWebBluetoothEnabled(): boolean {
    if (navigator.bluetooth) {
      return true
    } else {
      console.log(
        'Web Bluetooth API is not available.\n' +
          'Please make sure the "Experimental Web Platform features" flag is enabled.',
      )
      return false
    }
  }

  public send(data: Uint8Array): void {
    if (this.myCharacteristic) {
      // send data
      this.myCharacteristic
        .writeValue(data)
        .then(() => {
          console.log('>> Transmit to terminal (over BLE): ' + Tools.printBufferHex(data))
          this.no_of_transmitted_msgs += 1
        })
        .catch((error) => {
          console.log('Error ' + error)
        })
    } else {
      console.log('myCharacterisitc is undefined.')
    }
  }

  private onDisconnected(_event: Event): void {
    // Object event.target is Bluetooth Device getting disconnected.
    console.log('> Bluetooth Device disconnected')

    this.connectionState = BT_connection_state.not_connected

    this.disonnectCallback()
  }

  private start_receiving(): void {
    console.log('Start notify...')

    if (this.myCharacteristic) {
      // Start receiving (notification)
      this.myCharacteristic
        .startNotifications()
        .then(() => {
          console.log('> Notifications started')

          if (this.myCharacteristic) {
            this.myCharacteristic.addEventListener('characteristicvaluechanged', (ev) => this.handleNotifications(ev))
          }
        })
        .catch((error) => {
          console.log('Error ' + error)
          throw new Error(error.toString())
        })
    }
  }

  // Function to stop receiving notifications
  private stop_receiving() {
    if (this.myCharacteristic) {
      this.myCharacteristic
        .stopNotifications()
        .then((_) => {
          console.log('> Notifications stopped')

          if (this.myCharacteristic) {
            this.myCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications)

            /*
             * The Removing of the EventListener may not have been implemented correctly.
             * See: https://stackoverflow.com/questions/43727516/javascript-how-adding-event-handler-inside-a-class-with-a-class-method-as-the-c
             */
            throw new Error('stop_receiving() ist not tested!')
          }
        })
        .catch((error) => {
          console.log('Error ' + error)
        })
    }
  }

  // Handle received data (notification)
  handleNotifications(event: any): void {
    if (event && event.target && event.target.value) {
      const value = event.target.value // Type: DataView

      const buffer = new Uint8Array(value.buffer)
      console.log('<< Received from card terminal (over BLE): ' + Tools.printBufferHex(buffer))

      this.checkIfCareIsReady(buffer)

      // Store received data
      this.receiveBuffer.newMsg(value)

      this.no_of_received_msgs += 1
    } else {
      console.log('handleNotifications(): Error with event.')
    }
  }

  public is_data_available(): boolean {
    return this.receiveBuffer.msgIsReceived
  }

  public getMsgByte(): number {
    return this.receiveBuffer.getMsgByte()
  }

  public is_receiving_stopped(): boolean {
    return !this.myGattServer?.connected ?? true
  }
}
