import { Button } from '@material-ui/core'
import { memo, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
import * as praxisstammdatenActions from '../../actions/praxisstammdaten'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import CheckboxXOR from '../../shared/components/CheckboxXOR/CheckboxXOR'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { sCurrentUserApi } from '../../shared/utils/users'
import { fields } from '../PraxisstammdatenForm/PraxisstammdatenForm'
import terminFindenBeispiel from './Termin finden Showcase.png'

const StyledForm = styled.form`
  ${({ theme }) => `
    flex-grow: 1;

    .topBar {
      display: flex;
      flex-direction: row;
      height: 32px;
    }

    .container {
      padding: 0 ${theme.spacing(6)}px;
    }

    .submitButton {
      margin-bottom: 15px;
      margin-top: 20px;
    }
  `}
`

const InfoseiteOneManPraxen = ({dirty, initialValues, actions, userWhosCalendarToOpen, fields, handleSubmit, initializeForm, praxisstammdaten }) => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  useEffect(() => {
    actions.praxisstammdaten.loadPraxisstammdaten()
  }, [])

  const onSubmit = useCallback((data) => {
    const {
      terminFindenDeaktiviert: { initialValue: oldValue },
    } = fields
    const { terminFindenDeaktiviert: newValue } = data


    if(newValue) {
      navigate(`/${serverHash}/calendar/week/${userWhosCalendarToOpen.id}`, { replace: true })
    }

    actions.praxisstammdaten.updatePraxisstammdaten(data)

    // navigate(`/${serverHash}/calendar/week/${userWhosCalendarToOpen.id}`, { replace: true })
    
    // if (!oldValue && newValue) {
    //   actions.praxisstammdaten.updatePraxisstammdaten(data)
    // }
  }, [fields, navigate, serverHash, userWhosCalendarToOpen, actions.praxisstammdaten.updatePraxisstammdaten])

  const { terminFindenDeaktiviert } = fields

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="topBar">
        <StyledLink fullWidth disabled borderEnd>
          Heute
        </StyledLink>
        <StyledLink fullWidth disabled borderStart borderEnd>
          +1 Woche
        </StyledLink>
        <StyledLink fullWidth disabled borderStart borderEnd>
          +4 Wochen
        </StyledLink>
        <StyledLink fullWidth disabled borderStart>
          +6 Wochen
        </StyledLink>
      </div>
      <div className="container">
        <div>
          <p>
            Die &quot;Termin finden&quot; Ansicht vereint in Praxen mit mehr als einem Therapeuten die Kalenderspalten
            aller Therapeuten für alle Tage einer Arbeitswoche.
            <br />
            Da in Ihrer Praxis bisher nur ein Therapeut existiert, entspricht die Darstellung der Ansicht
            &quot;Therapeut / Arbeitswoche&quot;.
          </p>
        </div>
        <div>
          {/* <CheckboxField
            label='Verstanden. "Termin finden"-Ansicht zukünftig sofort überspringen.'
            {...terminFindenDeaktiviert}
          /> */}
          <CheckboxXOR
            label='Verstanden. "Termin finden"-Ansicht zukünftig sofort überspringen.'
            {...terminFindenDeaktiviert}
            className="checkboxXOR"
            labelNo="Nein"
            labelYes="Ja"
            />
        </div>
        <div>
          <Button className="submitButton" variant="contained" color="secondary" type="submit" fullWidth>
            Zur Ansicht &quot;Therapeut / Arbeitswoche&quot;
          </Button>
        </div>
        <div>
          <p>
            Beispiel der &quot;Termin finden&quot; Ansicht in Praxen mit mehreren Therapeuten.
            <br />
            Sie ermöglicht das Finden eines freien Termins über alle Therapeuten hinweg.
          </p>
          <img src={terminFindenBeispiel} style={{ width: '100%' }} />
        </div>
      </div>
    </StyledForm>
  )
}

const mapStateToProps = (state) => {
  const initialValues = praxisstammdatenSelector(state)
  const currentUser = sCurrentUserApi(state)
  const {
    selectedTerminProps,
    entities: { users },
  } = state
  const userWhosCalendarToOpen = currentUser.istTherapeut
    ? currentUser
    : Object.keys(users)
        .map((key) => users[key])
        .filter((user) =>
          selectedTerminProps.therapeut ? user.id === selectedTerminProps.therapeut : user.istTherapeut,
        )[0]

  return {
    busy: state.notification.busy,
    praxisstammdaten: praxisstammdatenSelector(state),
    initialValues,
    userWhosCalendarToOpen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    praxisstammdaten: bindActionCreators(praxisstammdatenActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'infoseiteOneManPraxen',
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(InfoseiteOneManPraxen))
