import { getAuthUrl, getApiUrl } from '../../../utils/auth'
import { fetchSecure, sCredentials } from '../../utils/auth'
import { apiServerHashSelector } from '../../../selectors/selectors'

import { store }  from '../../../containers/Root'

export const CHANGE_PIN = 'pod001_frontend/pin/CHANGE_PIN'
export const CHANGE_PIN_FAILURE = 'pod001_frontend/pin/CHANGE_PIN_FAILURE'
export const CHANGE_PIN_SUCCESS = 'pod001_frontend/pin/CHANGE_PIN_SUCCESS'
export const CHANGE_PIN_CHANGE_PIN_SUCCESS = 'pod001_frontend/pin/CHANGE_PIN_CHANGE_PIN_SUCCESS'
export const CHANGE_PIN_UNAUTHORIZED = 'pod001_frontend/pin/CHANGE_PIN_UNAUTHORIZED'
export const CHANGE_PIN_NEW_PIN_MISMATCH = 'pod001_frontend/pin/CHANGE_PIN_NEW_PIN_MISMATCH'
export const CHANGE_PIN_PIN_TOO_WEAK = 'pod001_frontend/pin/CHANGE_PIN_PIN_TOO_WEAK'
export const CHANGE_PIN_WRONG_FORMAT = 'pod001_frontend/pin/CHANGE_PIN_WRONG_FORMAT'

export function changePin({ oldPin, newPin, newPinConfirmation }) {
  return async (dispatch: typeof store.dispatch, getState: ReturnType<typeof store.getState>): Promise<void> => {
    await dispatch({
      type: CHANGE_PIN,
      oldPin,
      newPin,
      newPinConfirmation,
    })

    const serverHash = apiServerHashSelector(getState())
    const URL = serverHash ? `${getApiUrl(serverHash)}/change-pin` : `${getAuthUrl()}/change-pin`

    const auth0Credentials = sCredentials()

    const response = await fetchSecure(
      URL,
      {
        method: 'POST',
        credentials: 'include', // send credentials like cookies & basic auth
        body: JSON.stringify({ oldPin, newPin, newPinConfirmation }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      auth0Credentials,
    )

    if (!response.ok) {
      await dispatch({
        type: CHANGE_PIN_FAILURE,
        oldPin,
        newPin,
        newPinConfirmation,
        response,
      })
      return { success: false }
    }

    const json = await response.json()
    const { success, unauthorized, newPinMismatch, pinTooWeak, pinWrongFormat } = json

    await dispatch({
      type: CHANGE_PIN_SUCCESS,
      oldPin,
      newPin,
      newPinConfirmation,
      response,
      json,
    })

    if (success) {
      await dispatch({
        type: CHANGE_PIN_CHANGE_PIN_SUCCESS,
        oldPin,
        newPin,
        newPinConfirmation,
        json,
      })
    } else if (unauthorized) {
      await dispatch({
        type: CHANGE_PIN_UNAUTHORIZED,
        oldPin,
        newPin,
        newPinConfirmation,
      })
    } else if (newPinMismatch) {
      await dispatch({
        type: CHANGE_PIN_NEW_PIN_MISMATCH,
        oldPin,
        newPin,
        newPinConfirmation,
      })
    } else if (pinTooWeak) {
      await dispatch({
        type: CHANGE_PIN_PIN_TOO_WEAK,
        oldPin,
        newPin,
        newPinConfirmation,
      })
    } else if (pinWrongFormat) {
      await dispatch({
        type: CHANGE_PIN_WRONG_FORMAT,
        oldPin,
        newPin,
        newPinConfirmation,
      })
    }

    return json
  }
}

export const notifications = {
  [CHANGE_PIN_CHANGE_PIN_SUCCESS]: { message: 'Neue PIN wird gespeichert...', busy: true, error: false },
  [CHANGE_PIN_FAILURE]: {
    message: 'Speichern der PIN fehlgeschlagen, bitte versuchen Sie es später nocheinmal!',
    busy: false,
    error: true,
  },
  [CHANGE_PIN_NEW_PIN_MISMATCH]: {
    message: 'Die beiden neuen PINs müssen übereinstimmen!',
    busy: false,
    error: true,
  },
  [CHANGE_PIN_UNAUTHORIZED]: {
    message: 'Die Eingabe Ihrer aktuellen PIN war nicht korrekt!',
    busy: false,
    error: true,
  },
  [CHANGE_PIN_PIN_TOO_WEAK]: {
    message: 'Die neue PIN ist zu schwach!',
    busy: false,
    error: true,
  },
  [CHANGE_PIN_WRONG_FORMAT]: {
    message: 'Die PIN muss aus genau 4 Ziffern bestehen!',
    busy: false,
    error: true,
  },
  [CHANGE_PIN_CHANGE_PIN_SUCCESS]: {
    message: 'Neue PIN erfolgreich gespeichert!',
    busy: false,
    error: false,
  },
}
