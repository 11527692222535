/*
 * Which API Entities is currently beeing written to?
 * */
import * as types from '../constants/actionTypes'

export default function updating(
  state = {
    aerzte: false,
    anamnesen: false,
    dateien: false,
    dokumentationen: false,
    leistungenPrivat: false,
    heilmittelverordnungen: false,
    patienten: false,
    praxisstammdaten: false,
    termine: false,
    therapieberichte: false,
    users: false,
  },
  action = undefined,
) {
  switch (action.type) {
    case types.CREATE_ANAMNESIS:
    case types.UPDATE_ANAMNESIS:
      return {
        ...state,
        anamnesen: true,
      }
    case types.CREATE_ANAMNESIS_SUCCESS:
    case types.CREATE_ANAMNESIS_FAILURE:
    case types.UPDATE_ANAMNESIS_SUCCESS:
    case types.UPDATE_ANAMNESIS_FAILURE:
      return {
        ...state,
        anamnesen: false,
      }

    case types.CREATE_HEILMITTELVERORDNUNG:
    case types.SUBMIT_HVO:
      return {
        ...state,
        heilmittelverordnungen: true
      }      
    case types.UPDATE_HEILMITTELVERORDNUNG:
      return {
        ...state,
        heilmittelverordnungen: true,
      }
    case types.CREATE_HEILMITTELVERORDNUNG_SUCCESS:
    case types.SUBMIT_HVO_SUCCESS:
    case types.CREATE_HEILMITTELVERORDNUNG_FAILURE:
    case types.UPDATE_HEILMITTELVERORDNUNG_SUCCESS:
    case types.UPDATE_HEILMITTELVERORDNUNG_FAILURE:
      return {
        ...state,
        heilmittelverordnungen: false,
      }
    case types.SUBMIT_HVO_FAILURE:
      return {
        ...state,
        heilmittelverordnungen: false
      }

    default:
  }

  return state
}
