import { notifications as apiNotifications } from './api'
import auth, { notifications as authNotifications } from './auth'
import config from './config'
import formLeaveConfirmation from './formLeaveConfirmation'
import lockscreen from './lockscreen'
import time from './time'
import userActivity from './userActivity'

export default {
  auth,
  config,
  formLeaveConfirmation,
  lockscreen,
  time,
  userActivity,
}

export const notifications = {
  ...apiNotifications,
  ...authNotifications,
}
