import { ListItemIcon } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { FC, useRef, useState } from 'react'
import MenuItemsPatient from './MenuItemsPatient'
import { StyledMenuPatient } from './StyledMenuPatient'

interface Props {
  patientId?: number,
  handleMenuClose?: () => void,
}

const IconMenuPatient: FC<Props> = ({
  patientId,
  handleMenuClose
}) => {
  const iconRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (event) => {
    event?.stopPropagation()
    setOpen(true)
  }

  return (<>
    <ListItemIcon
      style={{
        minWidth: 'unset',
        cursor: 'pointer'
      }}
      onClick={handleOpen}
      ref={iconRef}>
      <MoreVertIcon />
    </ListItemIcon>
    <StyledMenuPatient
      PaperProps={{ className: 'paper' }}
      open={open}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorEl={iconRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableAutoFocus>
      <MenuItemsPatient
        patientId={patientId}
        onCloseFunc={() => {
          handleClose()
          handleMenuClose && handleMenuClose()
        }} />
    </StyledMenuPatient>
  </>)
}


export default IconMenuPatient
