import { Button } from '@material-ui/core';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { StyledLinkWrapper } from './StyledLinkWrapper';

interface Props {
  fullWidth?: boolean;
  children?: any;
  startIcon?: React.ReactNode;
  to?: string;
  active?: boolean;
  onClickFunc?: (e?: any) => void;
  disabled?: boolean;
  className?: string;
  iconSize?: 'small' | 'medium' | 'large';
  borderStart?: boolean;
  borderEnd?: boolean;
  isRelative?: boolean;
}

export const StyledLink: FC<Props> = ({
  fullWidth,
  children,
  startIcon,
  to,
  active,
  onClickFunc,
  disabled,
  className,
  iconSize,
  borderStart,
  borderEnd,
  isRelative,
}): JSX.Element => {
  return to ? (
    <StyledLinkWrapper borderStart={borderStart} borderEnd={borderEnd}>
      <Button
        component={NavLink}
        className={className ? className : 'button'}
        activeClassName={className ? className : 'button active'}
        to={isRelative ? to : { pathname: `/${to}` }}
        size={iconSize ?? 'small'}
        startIcon={startIcon}
        fullWidth={fullWidth}
        disabled={disabled}
        disableRipple
      >{children}</Button>

    </StyledLinkWrapper>
  ) : (
    <StyledLinkWrapper borderStart={borderStart} borderEnd={borderEnd}>
      <Button
        variant="text"
        size={iconSize ?? 'small'}
        startIcon={startIcon}
        fullWidth={fullWidth}
        onClick={onClickFunc}
        disabled={disabled}
        className={className ? className : active ? 'button active' : 'button'}
        disableRipple
        >{children}</Button>
    </StyledLinkWrapper>
  );
};

export default StyledLink;
