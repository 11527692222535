import styled from 'styled-components';

export const StyledCalendarSidebar = styled.div`
  ${({ theme }) => `
    width: 350px;
    border-left: 1px solid ${theme.palette.primary.main};

    .MuiPickersBasePicker-pickerView {
      align-self: center;
    }
    .MuiPickersToolbar-toolbarLandscape {
      max-width: unset;
    }
    .MuiPickersBasePicker-containerLandscape {
      flex-direction: column;
    }
    .MuiPickersToolbarText-toolbarTxt {
      color: ${theme.palette.text.primary};
    }
    .payWhatYouWant {
      margin: 10px;
      padding: 10px;
      &__title {
        display: flex;
        align-items: center;
      }
    }
  `}
`;
