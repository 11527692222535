import { Button, ListItem, ListItemText } from '@material-ui/core'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { EquipmentList, EquipmentSettingsSidebarContainer } from './StyledEquipmentSettings'

interface Props {
  onAddNewClick: () => void
  onExistingClick: (roomId: number) => void
  selectedEquipment: number | null
  equipment: any
}

const EquipmentSettingsSidebar = ({
  onAddNewClick,
  onExistingClick,
  selectedEquipment,
  equipment,
}: Props): JSX.Element => {
  const equipmentArray = useMemo(() => {
    if (!equipment) return []

    return Object.keys(equipment).map((key) => equipment[key])
  }, [equipment])

  return (
    <EquipmentSettingsSidebarContainer square elevation={2}>
      <Button color="secondary" variant="contained" style={{ marginBottom: 12 }} onClick={onAddNewClick} fullWidth>
        Neue Ausstattung hinzufügen
      </Button>
      <EquipmentList dense>
        {equipmentArray.map((e) => (
          <ListItem
            onClick={() => onExistingClick(e.id)}
            key={e.id}
            className={selectedEquipment === e.id ? 'listItemActive' : 'listItem'}
          >
            <ListItemText primary={`${e.abbreviation} | ${e.name}`} primaryTypographyProps={{ variant: 'body1' }} />
          </ListItem>
        ))}
      </EquipmentList>
    </EquipmentSettingsSidebarContainer>
  )
}

const mapStateToProps = (state) => ({
  equipment: state.entities.equipment,
})

export default connect(mapStateToProps)(EquipmentSettingsSidebar)
