import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export function loadDokumentationen(): RequestConfig {
  return {
    types: [types.LOAD_DOKUMENTATIONEN, types.LOAD_DOKUMENTATIONEN_SUCCESS, types.LOAD_DOKUMENTATIONEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dokumentationen`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createDokumentation(data): RequestConfig {
  return {
    types: [types.CREATE_DOKUMENTATION, types.CREATE_DOKUMENTATION_SUCCESS, types.CREATE_DOKUMENTATION_FAILURE],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dokumentationen`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}

export function updateDokumentation(data, notification): RequestConfig {
  return {
    types: [types.UPDATE_DOKUMENTATION, types.UPDATE_DOKUMENTATION_SUCCESS, types.UPDATE_DOKUMENTATION_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      // TODO: leistungen, dokumentation & medizinischeEmpfehlung hier entfernen, wenn die Felder nicht mehr in der Datenbank sind.
      const { id, ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/dokumentationen/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { ...data, notification },
  }
}
