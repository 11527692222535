import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { RequestConfig } from './interface'

/**
 * fieldList Struktur: { table: [ 'field1', 'field2', 'field3 ] }
 */
export function loadAutocomplete(fieldList): RequestConfig {
  return {
    types: [types.LOAD_AUTOCOMPLETE, types.LOAD_AUTOCOMPLETE_SUCCESS, types.LOAD_AUTOCOMPLETE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/autocomplete`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(fieldList),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: fieldList,
  }
}
