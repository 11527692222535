import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { apiServerHashSelector } from '../selectors/selectors'
import { RequestConfig } from './interface'

export function loadPraxisstammdaten(): RequestConfig {
  return {
    types: [types.LOAD_PRAXISSTAMMDATEN, types.LOAD_PRAXISSTAMMDATEN_SUCCESS, types.LOAD_PRAXISSTAMMDATEN_FAILURE],
    requiresAuth: true,
    authServer: true,
    // shouldCallAPI: ( state ) => state.ids.praxisstammdaten.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ authUrl, apiUrl, auth0Credentials, getState }): void => {
      const URL = apiServerHashSelector(getState()) ? `${apiUrl}/praxisstammdaten` : `${authUrl}/praxisstammdaten`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
  }
}

export function updatePraxisstammdaten(data): RequestConfig {
  return {
    types: [
      types.UPDATE_PRAXISSTAMMDATEN,
      types.UPDATE_PRAXISSTAMMDATEN_SUCCESS,
      types.UPDATE_PRAXISSTAMMDATEN_FAILURE,
    ],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/praxisstammdaten`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}
