import { Button, Divider } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { AutocompleteField } from '../../shared/components/AutocompleteField';
import InputField from '../../shared/components/InputField/InputField';
import { SelectField, SelectFieldOption } from '../../shared/components/SelectField';
import { buildHeilmittelverordnungString } from '../../utils/heilmittelverordnungen';
import { sortByNachnameVorname } from '../../utils/persons';
import FileUpload from '../FileUpload/FileUpload';
import { StyledTherapieberichtForm } from './StyledTherapieberichtForm';

const fields = ['patient', 'heilmittelverordnung', 'arzt', 'therapeut', 'datei'];

const TherapieberichtForm = ({
  customer,
  people,
  doctors,
  heilmittelverordnungen,
  heilmittelverordnungsIds,
  fields: { heilmittelverordnung, arzt, therapeut, datei },
  termine,
  leistungenKK,
  handleSubmit,
}) => {
  const [patientName, setPatientName] = useState<string>('')

  const [doctorsAutocompleteItems, setDoctorsAutocompleteItems] = useState<AutocompleteOption []>([])
  const [patientHvoItems, setPatientHvoItems] = useState<SelectFieldOption[]>([])
  const [therapeutItems, setTherapeutItems] = useState<SelectFieldOption[]>([])

  // setPatientName
  useEffect(() => {
    setPatientName(
      [customer?.vorname, customer?.nachname].filter(Boolean).join(' ')
    )
  }, [customer]);

  // set doctorsAutocompleteItems
  useEffect(() => {
    const newDoctors: AutocompleteOption[] = Object.keys(doctors)
      .map((key) => doctors[key])
      .sort(sortByNachnameVorname)
      .map((doctor) => ({
        value: doctor.id,
        text: [doctor?.vorname, doctor?.nachname].filter(Boolean).join(' ')
      }));
    setDoctorsAutocompleteItems(newDoctors)
  }, [doctors])

  // setPatientHvoItems
  useEffect(() => {
    const heilmittelverordnungenArray = heilmittelverordnungsIds.map((id) => {
      return heilmittelverordnungen[id];
    }) || [];
    const hvosForPatient = heilmittelverordnungenArray.filter((item) => {
      return item.patient === customer.id;
    }) || [];

    const items: SelectFieldOption[] = [
      {text: 'keine/privat', value: 'null'},
      ...hvosForPatient.map(item => ({
        text: buildHeilmittelverordnungString(item, termine, leistungenKK),
        value: item.id
      }))
    ];
    setPatientHvoItems(items)
  }, [heilmittelverordnungsIds, heilmittelverordnungen, customer, termine, leistungenKK])

  // set TherapeutItems
  useEffect(() => {
    const newTherapeutList: SelectFieldOption[] = Object.keys(people || {})
      .filter((key) => people[key].istTherapeut || key === therapeut.value)
      .map((item) => ({
        value: people[item].id,
        text: [
          people[item].vorname, people[item].nachname
        ].filter(Boolean).join(' ')
      }));
    setTherapeutItems(newTherapeutList)
  }, [people, therapeut])

  return (
    <StyledTherapieberichtForm onSubmit={handleSubmit} autoComplete="off">
      <div className="fieldContainer">
        <InputField
          readOnly={true}
          label="Patient"
          value={patientName}
        />

        <SelectField
          {...heilmittelverordnung}
          label="Verordnung"
          onChange={(e) => {
            const selectedValue = e.target.value || 'null';
            heilmittelverordnung.onChange(selectedValue);
            if(selectedValue > 0) {
              arzt.onChange(heilmittelverordnungen[selectedValue].arzt)
            }
          }}
          options={patientHvoItems}
        />

        <AutocompleteField
          {...arzt}
          label="Arzt"
          autoComplete={'new-password'}
          options={doctorsAutocompleteItems}
          error={!!arzt.touched && !!arzt.error}
          helperText={!!arzt.touched && !!arzt.error ? arzt.error : ''}
        />

        <SelectField
          {...therapeut}
          label="Therapeut *"
          error={!!therapeut.touched && !!therapeut.error}
          helperText={!!therapeut.touched && !!therapeut.error ? therapeut.error: ''}
          options={therapeutItems}
        />
      </div>

      <FileUpload
        onChange={datei.onChange}
        value={datei.value}
        multiple={false}
        errorText={!!datei.touched && !!datei.error ? datei.error : null}
      />
      <Divider className="divider" />
      <Button
        className="submitButton"
        variant="contained"
        type="submit"
        fullWidth
        color="secondary">
        Bericht speichern
      </Button>
    </StyledTherapieberichtForm>
  );
};

const validate = (values) => {
  const required = ['arzt', 'therapeut', 'datei'];
  const errors: KeyValue<string> = {};

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!';
    }
  });
  return errors;
};

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  termine: state.entities.termine,
  leistungenKK: state.leistungenKK,
});

export default reduxForm(
  {
    form: 'therapieberichtForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
)(memo(TherapieberichtForm));
