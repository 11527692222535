const CountryCodes: Array<Country> = [
  {
    name: 'Afghanistan',
    code: 'AF',
    callingCode: 93,
  },
  {
    name: 'Ãland Inseln',
    code: 'AX',
    callingCode: 358,
  },
  {
    name: 'Albanien',
    code: 'AL',
    callingCode: 355,
  },
  {
    name: 'Amerikanisch-Samoa',
    code: 'AS',
    callingCode: 1684,
  },
  {
    name: 'Andorra',
    code: 'AD',
    callingCode: 376,
  },
  {
    name: 'Angola',
    code: 'AO',
    callingCode: 244,
  },
  {
    name: 'Anguilla',
    code: 'AI',
    callingCode: 1264,
  },
  {
    name: 'Antigua und Barbuda',
    code: 'AG',
    callingCode: 1268,
  },
  {
    name: 'Argentinien',
    code: 'AR',
    callingCode: 54,
  },
  {
    name: 'Armenien',
    code: 'AM',
    callingCode: 374,
  },
  {
    name: 'Aruba',
    code: 'AW',
    callingCode: 297,
  },
  {
    name: 'Australien',
    code: 'AU',
    callingCode: 61,
  },
  {
    name: 'Österreich',
    code: 'AT',
    callingCode: 43,
  },
  {
    name: 'Aserbaidschan',
    code: 'AZ',
    callingCode: 994,
  },
  {
    name: 'Bahamas',
    code: 'BS',
    callingCode: 1242,
  },
  {
    name: 'Bahrain',
    code: 'BH',
    callingCode: 973,
  },
  {
    name: 'Bangladesch',
    code: 'BD',
    callingCode: 880,
  },
  {
    name: 'Barbados',
    code: 'BB',
    callingCode: 1246,
  },
  {
    name: 'Weißrußland',
    code: 'BY',
    callingCode: 375,
  },
  {
    name: 'Belgien',
    code: 'BE',
    callingCode: 32,
  },
  {
    name: 'Belize',
    code: 'BZ',
    callingCode: 501,
  },
  {
    name: 'Benin',
    code: 'BJ',
    callingCode: 229,
  },
  {
    name: 'Bermuda',
    code: 'BM',
    callingCode: 1441,
  },
  {
    name: 'Bhutan',
    code: 'BT',
    callingCode: 975,
  },
  {
    name: 'Bolivien',
    code: 'BO',
    callingCode: 591,
  },
  {
    name: 'Bosnien und Herzegovina',
    code: 'BA',
    callingCode: 387,
  },
  {
    name: 'Botswana',
    code: 'BW',
    callingCode: 267,
  },
  {
    name: 'Brasilien',
    code: 'BR',
    callingCode: 55,
  },
  {
    name: 'Brunei',
    code: 'BN',
    callingCode: 673,
  },
  {
    name: 'Bulgarien',
    code: 'BG',
    callingCode: 359,
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    callingCode: 226,
  },
  {
    name: 'Burundi',
    code: 'BI',
    callingCode: 257,
  },
  {
    name: 'Kamerun',
    code: 'CM',
    callingCode: 237,
  },
  {
    name: 'Kanada',
    code: 'CA',
    callingCode: 1,
  },
  {
    name: 'Zentralafrikanische Republik',
    code: 'CF',
    callingCode: 236,
  },
  {
    name: 'Chile',
    code: 'CL',
    callingCode: 56,
  },
  {
    name: 'China',
    code: 'CN',
    callingCode: 86,
  },
  {
    name: 'Kokosinseln',
    code: 'CC',
    callingCode: 61,
  },
  {
    name: 'Kongo',
    code: 'CD',
    callingCode: 243,
  },
  {
    name: 'Elfenbeinküste',
    code: 'CI',
    callingCode: 225,
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    callingCode: 590,
  },
  {
    name: 'Schweiz',
    code: 'CH',
    callingCode: 41,
  },
  {
    name: 'Vereinigte Arabische Emirate',
    code: 'AE',
    callingCode: 971,
  },
  {
    name: 'Cookinseln',
    code: 'CK',
    callingCode: 682,
  },
  {
    name: 'Kolumbien',
    code: 'CO',
    callingCode: 57,
  },
  {
    name: 'Komoren',
    code: 'KM',
    callingCode: 269,
  },
  {
    name: 'Kap Verde',
    code: 'CV',
    callingCode: 238,
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    callingCode: 506,
  },
  {
    name: 'Kuba',
    code: 'CU',
    callingCode: 53,
  },
  {
    name: 'Curacao',
    code: 'CW',
    callingCode: 5999,
  },
  {
    name: 'Weihnachtsinsel',
    code: 'CX',
    callingCode: 61,
  },
  {
    name: 'Kaimaninseln',
    code: 'KY',
    callingCode: 1345,
  },
  {
    name: 'Zypern',
    code: 'CY',
    callingCode: 357,
  },
  {
    name: 'Tschechien',
    code: 'CZ',
    callingCode: 420,
  },
  {
    name: 'Deutschland',
    code: 'DE',
    callingCode: 49,
  },
  {
    name: 'Dschibuti',
    code: 'DJ',
    callingCode: 253,
  },
  {
    name: 'Dominica',
    code: 'DM',
    callingCode: 1767,
  },
  {
    name: 'Dänemark',
    code: 'DK',
    callingCode: 45,
  },
  {
    name: 'Dominikanische Republik',
    code: 'DO',
    callingCode: 1809,
  },
  {
    name: 'Algerien',
    code: 'DZ',
    callingCode: 213,
  },
  {
    name: 'Ecuador',
    code: 'EC',
    callingCode: 593,
  },
  {
    name: 'Ägypten',
    code: 'EG',
    callingCode: 20,
  },
  {
    name: 'Eritrea',
    code: 'ER',
    callingCode: 291,
  },
  {
    name: 'Westsahara',
    code: 'EH',
    callingCode: 212,
  },
  {
    name: 'Spanien',
    code: 'ES',
    callingCode: 34,
  },
  {
    name: 'Estland',
    code: 'EE',
    callingCode: 372,
  },
  {
    name: 'Äthiopien',
    code: 'ET',
    callingCode: 251,
  },
  {
    name: 'Finnland',
    code: 'FI',
    callingCode: 358,
  },
  {
    name: 'Fidschi',
    code: 'FJ',
    callingCode: 679,
  },
  {
    name: 'Falklandinseln',
    code: 'FK',
    callingCode: 500,
  },
  {
    name: 'Frankreich',
    code: 'FR',
    callingCode: 33,
  },
  {
    name: 'Färöer-Inseln',
    code: 'FO',
    callingCode: 298,
  },
  {
    name: 'Micronesien',
    code: 'FM',
    callingCode: 691,
  },
  {
    name: 'Gabun',
    code: 'GA',
    callingCode: 241,
  },
  {
    name: 'Großbritannien',
    code: 'GB',
    callingCode: 44,
  },
  {
    name: 'Georgien',
    code: 'GE',
    callingCode: 995,
  },
  {
    name: 'Guernsey',
    code: 'GG',
    callingCode: 44,
  },
  {
    name: 'Ghana',
    code: 'GH',
    callingCode: 233,
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    callingCode: 350,
  },
  {
    name: 'Guinea',
    code: 'GN',
    callingCode: 224,
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    callingCode: 590,
  },
  {
    name: 'Gambia',
    code: 'GM',
    callingCode: 220,
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    callingCode: 245,
  },
  {
    name: 'Äquatorialguinea',
    code: 'GQ',
    callingCode: 240,
  },
  {
    name: 'Griechenland',
    code: 'GR',
    callingCode: 30,
  },
  {
    name: 'Grenada',
    code: 'GD',
    callingCode: 1473,
  },
  {
    name: 'Grönland',
    code: 'GL',
    callingCode: 299,
  },
  {
    name: 'Guatemala',
    code: 'GT',
    callingCode: 502,
  },
  {
    name: 'Französisch-Guayana',
    code: 'GF',
    callingCode: 594,
  },
  {
    name: 'Guam',
    code: 'GU',
    callingCode: 1671,
  },
  {
    name: 'Guyana',
    code: 'GY',
    callingCode: 592,
  },
  {
    name: 'Hongkong',
    code: 'HK',
    callingCode: 852,
  },
  {
    name: 'Honduras',
    code: 'HN',
    callingCode: 504,
  },
  {
    name: 'Kroatien',
    code: 'HR',
    callingCode: 385,
  },
  {
    name: 'Haiti',
    code: 'HT',
    callingCode: 509,
  },
  {
    name: 'Ungarn',
    code: 'HU',
    callingCode: 36,
  },
  {
    name: 'Indonesien',
    code: 'ID',
    callingCode: 62,
  },
  {
    name: 'Insel Man',
    code: 'IM',
    callingCode: 44,
  },
  {
    name: 'Indien',
    code: 'IN',
    callingCode: 91,
  },
  {
    name: 'Britisches Territorium im Indischen Ozean',
    code: 'IO',
    callingCode: 246,
  },
  {
    name: 'Irland',
    code: 'IE',
    callingCode: 353,
  },
  {
    name: 'Iran',
    code: 'IR',
    callingCode: 98,
  },
  {
    name: 'Irak',
    code: 'IQ',
    callingCode: 964,
  },
  {
    name: 'Island',
    code: 'IS',
    callingCode: 354,
  },
  {
    name: 'Israel',
    code: 'IL',
    callingCode: 972,
  },
  {
    name: 'Italien',
    code: 'IT',
    callingCode: 39,
  },
  {
    name: 'Jamaika',
    code: 'JM',
    callingCode: 1876,
  },
  {
    name: 'Jersey',
    code: 'JE',
    callingCode: 44,
  },
  {
    name: 'Jordanien',
    code: 'JO',
    callingCode: 962,
  },
  {
    name: 'Japan',
    code: 'JP',
    callingCode: 81,
  },
  {
    name: 'Kasachstan',
    code: 'KZ',
    callingCode: 76,
  },
  {
    name: 'Kasachstan',
    code: 'KZ',
    callingCode: 77,
  },
  {
    name: 'Kenya',
    code: 'KE',
    callingCode: 254,
  },
  {
    name: 'Kirgisistan',
    code: 'KG',
    callingCode: 996,
  },
  {
    name: 'Kambodscha',
    code: 'KH',
    callingCode: 855,
  },
  {
    name: 'Kiribati',
    code: 'KI',
    callingCode: 686,
  },
  {
    name: 'St. Kitts und Nevis',
    code: 'KN',
    callingCode: 1869,
  },
  {
    name: 'Süd Korea',
    code: 'KR',
    callingCode: 82,
  },
  {
    name: 'Kosovo',
    code: 'XK',
    callingCode: 383,
  },
  {
    name: 'Kuwait',
    code: 'KW',
    callingCode: 965,
  },
  {
    name: 'Laos',
    code: 'LA',
    callingCode: 856,
  },
  {
    name: 'Libanon',
    code: 'LB',
    callingCode: 961,
  },
  {
    name: 'Liberia',
    code: 'LR',
    callingCode: 231,
  },
  {
    name: 'Libyen',
    code: 'LY',
    callingCode: 218,
  },
  {
    name: 'St. Lucia',
    code: 'LC',
    callingCode: 1758,
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    callingCode: 423,
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    callingCode: 94,
  },
  {
    name: 'Lesotho',
    code: 'LS',
    callingCode: 266,
  },
  {
    name: 'Litauen',
    code: 'LT',
    callingCode: 370,
  },
  {
    name: 'Luxemburg',
    code: 'LU',
    callingCode: 352,
  },
  {
    name: 'Lettland',
    code: 'LV',
    callingCode: 371,
  },
  {
    name: 'Macau',
    code: 'MO',
    callingCode: 853,
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    callingCode: 590,
  },
  {
    name: 'Marokko',
    code: 'MA',
    callingCode: 212,
  },
  {
    name: 'Monaco',
    code: 'MC',
    callingCode: 377,
  },
  {
    name: 'Moldavien',
    code: 'MD',
    callingCode: 373,
  },
  {
    name: 'Madagaskar',
    code: 'MG',
    callingCode: 261,
  },
  {
    name: 'Malediven',
    code: 'MV',
    callingCode: 960,
  },
  {
    name: 'Mexiko',
    code: 'MX',
    callingCode: 52,
  },
  {
    name: 'Marshallinseln',
    code: 'MH',
    callingCode: 692,
  },
  {
    name: 'Mazedonien',
    code: 'MK',
    callingCode: 389,
  },
  {
    name: 'Mali',
    code: 'ML',
    callingCode: 223,
  },
  {
    name: 'Malta',
    code: 'MT',
    callingCode: 356,
  },
  {
    name: 'Myanmar',
    code: 'MM',
    callingCode: 95,
  },
  {
    name: 'Montenegro',
    code: 'ME',
    callingCode: 382,
  },
  {
    name: 'Mongolei',
    code: 'MN',
    callingCode: 976,
  },
  {
    name: 'Nördliche Marianen',
    code: 'MP',
    callingCode: 1670,
  },
  {
    name: 'Mosambik',
    code: 'MZ',
    callingCode: 258,
  },
  {
    name: 'Mauretanien',
    code: 'MR',
    callingCode: 222,
  },
  {
    name: 'Montserrat',
    code: 'MS',
    callingCode: 1664,
  },
  {
    name: 'Martinique',
    code: 'MQ',
    callingCode: 596,
  },
  {
    name: 'Mauritius',
    code: 'MU',
    callingCode: 230,
  },
  {
    name: 'Malawi',
    code: 'MW',
    callingCode: 265,
  },
  {
    name: 'Malaysia',
    code: 'MY',
    callingCode: 60,
  },
  {
    name: 'Mayotte',
    code: 'YT',
    callingCode: 262,
  },
  {
    name: 'Namibia',
    code: 'NA',
    callingCode: 264,
  },
  {
    name: 'Neukaledonien',
    code: 'NC',
    callingCode: 687,
  },
  {
    name: 'Niger',
    code: 'NE',
    callingCode: 227,
  },
  {
    name: 'Norfolkinsel',
    code: 'NF',
    callingCode: 672,
  },
  {
    name: 'Nigeria',
    code: 'NG',
    callingCode: 234,
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    callingCode: 505,
  },
  {
    name: 'Niue',
    code: 'NU',
    callingCode: 683,
  },
  {
    name: 'Niederlande',
    code: 'NL',
    callingCode: 31,
  },
  {
    name: 'Norwegen',
    code: 'NO',
    callingCode: 47,
  },
  {
    name: 'Nepal',
    code: 'NP',
    callingCode: 977,
  },
  {
    name: 'Nauru',
    code: 'NR',
    callingCode: 674,
  },
  {
    name: 'Neuseeland',
    code: 'NZ',
    callingCode: 64,
  },
  {
    name: 'Oman',
    code: 'OM',
    callingCode: 968,
  },
  {
    name: 'Pakistan',
    code: 'PK',
    callingCode: 92,
  },
  {
    name: 'Panama',
    code: 'PA',
    callingCode: 507,
  },
  {
    name: 'Pitcairninseln',
    code: 'PN',
    callingCode: 64,
  },
  {
    name: 'Peru',
    code: 'PE',
    callingCode: 51,
  },
  {
    name: 'Philippinen',
    code: 'PH',
    callingCode: 63,
  },
  {
    name: 'Palau',
    code: 'PW',
    callingCode: 680,
  },
  {
    name: 'Papua-Neuguinea',
    code: 'PG',
    callingCode: 675,
  },
  {
    name: 'Polen',
    code: 'PL',
    callingCode: 48,
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    callingCode: 1787,
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    callingCode: 1939,
  },
  {
    name: 'Nord Korea',
    code: 'KP',
    callingCode: 850,
  },
  {
    name: 'Portugal',
    code: 'PT',
    callingCode: 351,
  },
  {
    name: 'Paraguay',
    code: 'PY',
    callingCode: 595,
  },
  {
    name: 'Palestina',
    code: 'PS',
    callingCode: 970,
  },
  {
    name: 'Französisch-Polynesien',
    code: 'PF',
    callingCode: 689,
  },
  {
    name: 'Katar',
    code: 'QA',
    callingCode: 974,
  },
  {
    name: 'Rumänien',
    code: 'RO',
    callingCode: 40,
  },
  {
    name: 'Russland',
    code: 'RU',
    callingCode: 7,
  },
  {
    name: 'Ruanda',
    code: 'RW',
    callingCode: 250,
  },
  {
    name: 'Saudi-Arabien',
    code: 'SA',
    callingCode: 966,
  },
  {
    name: 'Sudan',
    code: 'SD',
    callingCode: 249,
  },
  {
    name: 'Senegal',
    code: 'SN',
    callingCode: 221,
  },
  {
    name: 'Singapur',
    code: 'SG',
    callingCode: 65,
  },
  {
    name: 'Süd Georgien',
    code: 'GS',
    callingCode: 500,
  },
  {
    name: 'Salomonen',
    code: 'SB',
    callingCode: 677,
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    callingCode: 232,
  },
  {
    name: 'El Salvador',
    code: 'SV',
    callingCode: 503,
  },
  {
    name: 'San Marino',
    code: 'SM',
    callingCode: 378,
  },
  {
    name: 'Somalia',
    code: 'SO',
    callingCode: 252,
  },
  {
    name: 'Serbien',
    code: 'RS',
    callingCode: 381,
  },
  {
    name: 'Süd Sudan',
    code: 'SS',
    callingCode: 211,
  },
  {
    name: 'Suriname',
    code: 'SR',
    callingCode: 597,
  },
  {
    name: 'Slovakei',
    code: 'SK',
    callingCode: 421,
  },
  {
    name: 'Slowenien',
    code: 'SI',
    callingCode: 386,
  },
  {
    name: 'Schweden',
    code: 'SE',
    callingCode: 46,
  },
  {
    name: 'Swasiland',
    code: 'SZ',
    callingCode: 268,
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    callingCode: 1721,
  },
  {
    name: 'Seychellen',
    code: 'SC',
    callingCode: 248,
  },
  {
    name: 'Syrien',
    code: 'SY',
    callingCode: 963,
  },
  {
    name: 'Turks- und Caicosinseln',
    code: 'TC',
    callingCode: 1649,
  },
  {
    name: 'Tschad',
    code: 'TD',
    callingCode: 235,
  },
  {
    name: 'Togo',
    code: 'TG',
    callingCode: 228,
  },
  {
    name: 'Thailand',
    code: 'TH',
    callingCode: 66,
  },
  {
    name: 'Tadschikistan',
    code: 'TJ',
    callingCode: 992,
  },
  {
    name: 'Tokelau',
    code: 'TK',
    callingCode: 690,
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    callingCode: 993,
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    callingCode: 670,
  },
  {
    name: 'Tonga',
    code: 'TO',
    callingCode: 676,
  },
  {
    name: 'Trinidad und Tobago',
    code: 'TT',
    callingCode: 1868,
  },
  {
    name: 'Tunesien',
    code: 'TN',
    callingCode: 216,
  },
  {
    name: 'Türkei',
    code: 'TR',
    callingCode: 90,
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    callingCode: 688,
  },
  {
    name: 'Taiwan',
    code: 'TW',
    callingCode: 886,
  },
  {
    name: 'Tansania, Vereinigte Republik',
    code: 'TZ',
    callingCode: 255,
  },
  {
    name: 'Uganda',
    code: 'UG',
    callingCode: 256,
  },
  {
    name: 'Ukraine',
    code: 'UA',
    callingCode: 380,
  },
  {
    name: 'Uruguay',
    code: 'UY',
    callingCode: 598,
  },
  {
    name: 'Vereinigte Staaten von Amerika',
    code: 'US',
    callingCode: 1,
  },
  {
    name: 'Usbekistan',
    code: 'UZ',
    callingCode: 998,
  },
  {
    name: 'Vatikan Stadt',
    code: 'VA',
    callingCode: 379,
  },
  {
    name: 'St. Vincent und die Grenadinen',
    code: 'VC',
    callingCode: 1784,
  },
  {
    name: 'Venezuela',
    code: 'VE',
    callingCode: 58,
  },
  {
    name: 'Britischen Jungferninseln',
    code: 'VG',
    callingCode: 1284,
  },
  {
    name: 'Vietnam',
    code: 'VN',
    callingCode: 84,
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    callingCode: 678,
  },
  {
    name: 'Samoa',
    code: 'WS',
    callingCode: 685,
  },
  {
    name: 'Jemen',
    code: 'YE',
    callingCode: 967,
  },
  {
    name: 'Südafrika',
    code: 'ZA',
    callingCode: 27,
  },
  {
    name: 'Sambia',
    code: 'ZM',
    callingCode: 260,
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    callingCode: 263,
  },
].map(item => ({...item, callingCode: String(item.callingCode)}));

export default CountryCodes
