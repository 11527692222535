import io from 'socket.io-client'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { bindActionCreators } from 'redux'
import { getServerURL } from '../utils/auth'
import { sCredentials } from '../shared/utils/auth'

let socket: any = false

export function initializeSocket() {
  return (dispatch): void => {
    const actions = bindActionCreators(
      {
        processSocketConnect,
        socketConnectError,
        socketConnectTimeout,
        socketDisconnect,
        socketReconnect,
        socketReconnectAttempt,
        socketReconnecting,
        socketReconnectError,
        socketReconnectFailed,
        socketPing,
        socketPong,
        socketReceiveData,
      },
      dispatch,
    )

    const url = getServerURL()

    if (!socket) {
      socket = io(url, { path: '/socket.io', transports: ['websocket'] })
      socket.on('connect', actions.processSocketConnect)
      socket.on('connect_error', actions.socketConnectError)
      socket.on('connect_timeout', actions.socketConnectTimeout)
      socket.on('disconnect', actions.socketDisconnect)
      socket.on('reconnect', actions.socketReconnect)
      socket.on('reconnect_attempt', actions.socketReconnectAttempt)
      socket.on('reconnecting', actions.socketReconnecting)
      socket.on('reconnect_error', actions.socketReconnectError)
      socket.on('reconnect_failed', actions.socketReconnectFailed)
      socket.on('ping', actions.socketPing)
      socket.on('pong', actions.socketPong)
      socket.on('data', actions.socketReceiveData)
    }
    dispatch({ type: types.SOCKET_INITIALIZE })
  }
}

export function destroySocket() {
  return (dispatch): void => {
    if (socket && socket.disconnect) socket.disconnect(true)
    socket = false
    dispatch({ type: types.SOCKET_DESTROY })
  }
}

export const socketConnect = makeActionCreator(types.SOCKET_CONNECT)

export function processSocketConnect() {
  return (dispatch, getState): void => {
    const authorization = sCredentials(getState())

    dispatch(socketConnect())
    const message = 'authenticate'
    // TODO nicer server hash
    socket.emit('authenticate', {
      authorization: authorization,
      message: message,
      serverHash: window.location.pathname.split('/')[1],
    })
  }
}

export const socketConnectError = makeActionCreator(types.SOCKET_CONNECT_ERROR, 'error')
export const socketConnectTimeout = makeActionCreator(types.SOCKET_CONNECT_TIMEOUT)
export const socketDisconnect = makeActionCreator(types.SOCKET_DISCONNECT)
export const socketReconnect = makeActionCreator(types.SOCKET_RECONNECT, 'attempt')
export const socketReconnectAttempt = makeActionCreator(types.SOCKET_RECONNECT_ATTEMPT)
export const socketReconnecting = makeActionCreator(types.SOCKET_RECONNECTING, 'attempt')
export const socketReconnectError = makeActionCreator(types.SOCKET_RECONNECT_ERROR, 'error')
export const socketReconnectFailed = makeActionCreator(types.SOCKET_RECONNECT_FAILED)
export const socketPing = makeActionCreator(types.SOCKET_PING)
export const socketPong = makeActionCreator(types.SOCKET_PONG, 'latency')
export const socketReceiveData = makeActionCreator(types.SOCKET_RECEIVE_DATA, 'json')
