import { useEffect } from 'react'
import { useAuth } from '../containers/AuthProvider/useAuth'
import { useNavigate } from 'react-router-dom'

export const LoginCallback = (): JSX.Element => {
  const { isAuthLoading, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    console.log('isAuthLoading in LoginCallback: ', isAuthLoading)
    console.log('isAuthenticated in LoginCallback: ', isAuthenticated)
    console.log('loginRedirectUrl in LoginCallback: ', window.localStorage.getItem('login_redirect_url'))
    if (!isAuthLoading && isAuthenticated) {
      console.log('IF Block in LoginCallback')
      const loginRedirectUrl = window.localStorage.getItem('login_redirect_url')
      navigate(loginRedirectUrl || '/startbereich')
    }
    console.log('End of LoginCallback. IF Condition not met.')
  }, [isAuthLoading, isAuthenticated])

  return <div>You should not see this page</div>
}

