import { StyledError404 } from './StyledError404';

const Error404 = (): JSX.Element => (
  <StyledError404>
    <h2 className="h2">Fehler 404</h2>
    <p>Die angeforderte Seite konnte nicht gefunden werden.</p>
  </StyledError404>
);

export default Error404;
