import { FC } from 'react'

interface Props {
  style?: any
  className?: string
}

const RuckFlag: FC<Props> = ({ style, className }) => {
  return (
    <svg
      className={className}
      style={style}
      id="RuckFlag"
      data-name="RuckFlag 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 157.3 69.3"
    >
      <path fill="#009fc0" strokeWidth={0} d="M0,69.3V0h138.9c10.2,0,18.4,8.2,18.4,18.4v50.9H0Z" />
      <g>
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M39.7,42.3l-7.7-12.2c1.8-.8,3.2-2.2,4.1-3.9,1.2-2.4,1.4-5.3.3-7.8-2-3.9-5.7-5.2-10.3-5.2h-12.6c1,3.4,2.1,7.1,4,10.5,4.4,8,11.9,16.3,18,18.3,1.2.6,2.6,0,2.6,1.3-.7,2-3.4.4-5,1.4-2.9,1.4-5.4-.3-7.9-1.3-2.3-1.1-5.3.9-7.2-1-.5-.5-.5-1.3-.5-2,.6-4.5-1.6-7.4-4.1-10.3v12.9c0,2.1,1.7,3.9,3.9,3.9h20s1.7,0,2.7-1.3c1.1-1.4-.3-3.3-.3-3.3"
        />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M99.6,40.5h-11.2c-1.7,0-3.2-1.4-3.2-3.2v-13.9c0-1.7,1.4-3.2,3.2-3.2h11.2c2.3,0,3.4-1.7,3.4-3.4s-1.1-3.4-3.4-3.4h-11.2c-5.5,0-9.9,4.5-9.9,9.9v13.9c0,5.5,4.5,9.9,9.9,9.9h11.2c2.3,0,3.4-1.7,3.4-3.4,0-1.5-1.2-3.2-3.4-3.2"
        />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M65,37.3c0,1.7-1.4,3.2-3.2,3.2h-8.2c-1.7,0-3.1-1.4-3.1-3.2V13.4h-6.8v23.9c0,5.5,4.5,9.9,9.9,9.9h8.2c5.5,0,9.9-4.5,9.9-9.9V13.4h-6.7v23.9Z"
        />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M122.6,30.9l14-11.7c.7-.7,1.1-1.6,1.1-2.4,0-1.8-1.7-3.5-3.5-3.5s-1.6.3-2.3,1l-15.4,13.1v-14h-6.8v30.4c0,2.3,1.7,3.4,3.4,3.4s3.4-1.1,3.4-3.4v-9.5l15.4,11.8c.7.7,1.5,1,2.3,1,1.8,0,3.5-1.7,3.5-3.5s-.3-1.6-1.1-2.4l-14-10.3Z"
        />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M143.3,40.6c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3-1.5-3.3-3.3-3.3ZM143.3,46.7c-1.5,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8,2.8,1.3,2.8,2.8-1.3,2.8-2.8,2.8Z"
        />
        <path
          fill="#ffffff"
          strokeWidth={0}
          d="M143.5,42.2c.3,0,.6,0,.8.2.3.2.4.5.4.8s-.3.8-.7.9c.2,0,.3.1.5.3.1.2.1.2.2.9,0,.4,0,.5.1.6h-.4c0,0,0-.2,0-.5,0-.6,0-.8-.2-.9-.1-.1-.4-.2-.8-.2h-1.1v1.6h-.3v-3.6h1.6ZM142.2,43.9h1c.4,0,.6,0,.7-.1.2-.1.3-.3.3-.6s-.1-.5-.3-.6c-.1,0-.3-.1-.6-.1h-1.1v1.4Z"
        />
      </g>
    </svg>
  )
}

export default RuckFlag
