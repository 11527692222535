/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  Sate machine for eGK reading.
 *
 *  history:
 *  *.2021 bwe                    created
 *  *.2021 tpe                    insert card moved to VSD state machine
 */

import { EGK_commands } from './EGK_commands'
import { CardReader_eGK } from './card_reader_eGK'
import { Dad, Sad, T1_Return_Code, T1_host_if, TerminalData } from './t1_host_interface'
import { EGK_AID, EGK_SFID } from './EGK_identifier'
import { Tools } from '../utils/general/tools'

// define stm states
export enum Read_eGK_state {
  start, // initial state
  select_masterfile, // select_masterfile was requested.
  masterfile_selected, // select_masterfile() was successfull
  select_df_hca, // select_df_hca() was requested.
  df_hca_selected, // select_DF_HCA()  was successfull
  reading_ef_statusVD, // read_EF_StatusVD() was requested
  select_EF_PD, // select_ef_pd() was requested.
  ef_pd_selected, // select_EF_PD()  was successfull
  reading_ef_pd, // after read_EF_PD() was requested
  reading_ef_vd, // after read_EF_VD() was requested
  reading_vsd_done, // reading VSD was done
}

// define statemachine
export class Read_eGK_stm {
  private state: Read_eGK_state = Read_eGK_state.start // The actual state
  private cardReader_eGK: CardReader_eGK
  private t1_host_if: T1_host_if

  private dad: Dad // destination address of 'integrated circuit card' (ICC)
  private sad: Sad // source address of host

  constructor(cardReader_eGK: CardReader_eGK, t1_host_if: T1_host_if, dad: Dad, sad: Sad) {
    this.cardReader_eGK = cardReader_eGK
    this.t1_host_if = t1_host_if
    this.state = Read_eGK_state.start
    this.dad = dad
    this.sad = sad
    console.log('Read_eGK_stm created.')
  }

  public async select_masterfile(): Promise<void> {
    if (this.state === Read_eGK_state.start) {
      // Change state
      this.state = Read_eGK_state.select_masterfile

      // send command
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, EGK_commands.select_file_with_AID(EGK_AID.mf)),
        'select_masterfile',
      )

      this.evaluate_response_from_select_masterfile(rxData, return_code)
    } else {
      console.error('select_masterfile(): state != icc_ready')
      throw new Error('read_eGK_wrong_state ' + this.select_masterfile.name)
    }
  }

  public async select_DF_HCA(): Promise<void> {
    if (this.state === Read_eGK_state.masterfile_selected) {
      // change state
      this.state = Read_eGK_state.select_df_hca

      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, EGK_commands.select_file_with_AID(EGK_AID.hca)),
        'select_DF_HCA',
      )

      this.evaluate_response_from_select_DF_HCA(rxData, return_code)
    } else {
      console.error('select_DF_HCA(): state != masterfile_selected')
      throw new Error('read_eGK_wrong_state ' + this.select_DF_HCA.name)
    }
  }

  public async read_EF_StatusVD(): Promise<void> {
    if (this.state === Read_eGK_state.df_hca_selected) {
      // change state
      this.state = Read_eGK_state.reading_ef_statusVD

      // send command read ef.statusVD
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, EGK_commands.read_with_SFID(EGK_SFID.statusVD)),
        'read_EF_StatusVD',
      )

      this.evaluate_response_from_read_EF_StatusVD(rxData, return_code)
    } else {
      console.error(this.read_EF_StatusVD.name + ' state != df_hca_selected')
      throw new Error('read_eGK_wrong_state ' + this.read_EF_StatusVD.name)
    }
  }

  private async read_EF_PD_length(): Promise<number> {
    // read the length information of the file
    const length = 2
    const [rxData, return_code] = await this.t1_host_if.send_async(
      new TerminalData(this.dad, this.sad, EGK_commands.read_with_SFID(EGK_SFID.pd, length)),
      'read_EF_PD_length',
    )
    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_PD length return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.read_EF_PD_length.name)
    }
    const expected_length = 4
    if (rxData.length < expected_length) {
      console.error('read_EF_PD length rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.read_EF_PD_length.name)
    }
    const expected_response = new Uint8Array([0x90, 0x00])
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])
    if (!Tools.equals(expected_response, status_code)) {
      console.error('Error: Received response from read_EF_PD length was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.read_EF_PD_length.name)
    }
    const length_information = new Uint8Array([rxData[0], rxData[1]])
    const length_file_size_info = 2
    return Tools.convertToLength(length_information) + length_file_size_info
  }

  public async read_EF_PD(): Promise<string> {
    let data_EF_PD = ''
    if (this.state === Read_eGK_state.df_hca_selected) {
      // change state
      this.state = Read_eGK_state.reading_ef_pd

      // read the length information of the file
      const length_data = await this.read_EF_PD_length()

      // send command read ef.pd with short file identifier
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, EGK_commands.read_with_SFID(EGK_SFID.pd, length_data)),
        'read_EF_PD',
      )

      data_EF_PD = this.evaluate_response_from_read_EF_PD(rxData, return_code)
    } else {
      console.error(this.read_EF_PD.name + ' state != df_hca_selected')
      throw new Error('read_eGK_wrong_state ' + this.read_EF_PD.name)
    }
    return data_EF_PD
  }

  private async read_EF_VD_length(): Promise<number> {
    // read the length information of the file
    const length = 8
    const [rxData, return_code] = await this.t1_host_if.send_async(
      new TerminalData(this.dad, this.sad, EGK_commands.read_with_SFID(EGK_SFID.vd_gvd, length)),
      'read_EF_VD_GVD_length',
    )
    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_VD_GVD length return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.read_EF_VD_length.name)
    }
    const expected_length = 10
    if (rxData.length < expected_length) {
      console.error('read_EF_VD_GVD length rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.read_EF_VD_length.name)
    }
    const expected_response = new Uint8Array([0x90, 0x00])
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])
    if (!Tools.equals(expected_response, status_code)) {
      console.error('Error: Received response from read_EF_VD_GVD length was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.read_EF_VD_length.name)
    }
    const length_information = new Uint8Array([rxData[6], rxData[7]])
    return Tools.convertToLength(length_information) + 1
  }

  public async read_EF_VD(): Promise<string> {
    let data_EF_VD = ''

    if (this.state === Read_eGK_state.df_hca_selected || this.state === Read_eGK_state.ef_pd_selected) {
      // change state
      this.state = Read_eGK_state.reading_ef_vd

      // read the length information of the file
      const length_data = await this.read_EF_VD_length()

      // send command read ef.pd
      const [rxData, return_code] = await this.t1_host_if.send_async(
        new TerminalData(this.dad, this.sad, EGK_commands.read_with_SFID(EGK_SFID.vd_gvd, length_data)),
        'read_EF_VD',
      )

      data_EF_VD = this.evaluate_response_from_read_EF_VD(rxData, return_code)

      this.state = Read_eGK_state.reading_vsd_done
    } else {
      console.error(this.read_EF_VD.name + ' state != df_hca_selected || state != ef_pd_selected')
      throw new Error('read_eGK_wrong_state ' + this.read_EF_VD.name)
    }

    return data_EF_VD
  }

  evaluate_response_from_select_masterfile(rxData: Uint8Array, return_code: number): void {
    console.log('evaluate_response_from_select_masterfile()')

    if (return_code !== T1_Return_Code.OK) {
      console.error('select_masterfile return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_select_masterfile.name)
      return
    } else {
      /*no error from t1*/
    }

    const expected_len = 2
    if (rxData.length < expected_len) {
      console.error('select_masterfile rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_select_masterfile.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    if (Tools.equals(expected_response, rxData)) {
      this.state = Read_eGK_state.masterfile_selected
    } else {
      console.error('Error: Received response from select_masterfile was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_select_masterfile.name)
    }
  }

  evaluate_response_from_select_DF_HCA(rxData: Uint8Array, return_code: number): void {
    console.log('evaluate_response_from_select_DF_HCA()')

    if (return_code !== T1_Return_Code.OK) {
      console.error('select_DF_HCA return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_select_DF_HCA.name)
    } else {
      /*no error from t1*/
    }

    const expected_len = 2
    if (rxData.length < expected_len) {
      console.error('select_DF_HCA rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_select_DF_HCA.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    if (Tools.equals(expected_response, rxData)) {
      this.state = Read_eGK_state.df_hca_selected
    } else {
      console.error('Error: Received response from select_DF_HCA was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_select_DF_HCA.name)
    }
  }

  evaluate_response_from_read_EF_StatusVD(rxData: Uint8Array, return_code: number): void {
    console.log('evaluate_response_from_read_EF_StatusVD()')

    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_StatusVD return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_read_EF_StatusVD.name)
    } else {
      /*no error from t1*/
    }

    const expected_len = 2
    if (rxData.length < expected_len) {
      console.error('read_EF_StatusVD rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_read_EF_StatusVD.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])
    if (Tools.equals(expected_response, status_code)) {
      this.state = Read_eGK_state.df_hca_selected
    } else {
      console.error('Error: Received response from read_EF_StatusVD was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_read_EF_StatusVD.name)
    }
  }

  evaluate_response_from_read_EF_PD(rxData: Uint8Array, return_code: number): string {
    console.log('evaluate_response_from_read_EF_PD()')

    // change state
    this.state = Read_eGK_state.df_hca_selected

    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_PD return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_read_EF_PD.name)
    } else {
      /*no error from t1*/
    }

    const minimum_expected_response_length = 2
    if (rxData.length < minimum_expected_response_length) {
      console.error('read_EF_PD rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_read_EF_PD.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])

    if (return_code === T1_Return_Code.OK && Tools.equals(expected_response, status_code)) {
      // Everthing seems to be fine

      if (rxData.length < 4) {
        console.error('Error: Unexpected response length for EF.PD')
        throw new Error('unexpected response length ' + this.evaluate_response_from_read_EF_PD.name)
      }

      // gunzip the data
      const dataset_length = Tools.convertToLength(new Uint8Array([rxData[0], rxData[1]]))
      const offset_start = 2
      const offset_end = dataset_length + offset_start

      if (offset_start >= offset_end || offset_end > rxData.length - 1) {
        console.error('Error: Unexpected file length for EF.PD')
        throw new Error('unexpected file length ' + this.evaluate_response_from_read_EF_PD.name)
      }

      const gzip_data = rxData.slice(offset_start, offset_end)
      return Tools.inflateGzip(gzip_data)
    } else {
      console.error('Error: Received response from read_EF_PD was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_read_EF_PD.name)
    }
  }

  evaluate_response_from_read_EF_VD(rxData: Uint8Array, return_code: number): string {
    console.log('evaluate_response_from_read_EF_VD()')

    // change state
    this.state = Read_eGK_state.df_hca_selected

    if (return_code !== T1_Return_Code.OK) {
      console.error('read_EF_VD return_code=', return_code)
      throw new Error('t1_send_return_code ' + this.evaluate_response_from_read_EF_VD.name)
    } else {
      /*no error from t1*/
    }

    const minimum_expected_response_length = 2
    if (rxData.length < minimum_expected_response_length) {
      console.error('read_EF_VD rxData.length=', rxData.length)
      throw new Error('t1_response_length ' + this.evaluate_response_from_read_EF_VD.name)
    } else {
      /*no length error*/
    }

    const expected_response = new Uint8Array([0x90, 0x00])
    const status_code = new Uint8Array([rxData[rxData.length - 2], rxData[rxData.length - 1]])

    // Evaluate received data
    if (return_code === T1_Return_Code.OK && Tools.equals(expected_response, status_code)) {
      // Everthing seems to be fine

      if (rxData.length < 10) {
        console.error('Error: Unexpected response length for EF.VD')
        throw new Error('unexpected response length ' + this.evaluate_response_from_read_EF_VD.name)
      }

      // gunzip the data
      const offsetStartVD = Tools.convertToLength(new Uint8Array([rxData[0], rxData[1]]))
      const offsetEndVD = Tools.convertToLength(new Uint8Array([rxData[2], rxData[3]])) + 1
      //  const offsetStartGVD = Tools.convertToLength(new Uint8Array([rxData[4], rxData[5]]));
      //  const offsetEndGVD = Tools.convertToLength(new Uint8Array([rxData[6], rxData[7]])) + 1;

      if (offsetStartVD >= offsetEndVD || offsetEndVD > rxData.length - 1) {
        console.error('Error: Unexpected file length for EF.VD')
        throw new Error('unexpected file length for VD' + this.evaluate_response_from_read_EF_VD.name)
      }

      const gzip_data_vd = rxData.slice(offsetStartVD, offsetEndVD)

      //  if  (offsetStartGVD >= offsetEndGVD
      //   || offsetEndGVD > rxData.length-1) {
      //   console.error("Error: Unexpected file length for EF.GVD")
      //   throw new Error("unexpected file length for GVD" + this.evaluate_response_from_read_EF_VD.name);
      // }

      // const gzip_data_gvd = rxData.slice(offsetStartGVD, offsetEndGVD);

      return Tools.inflateGzip(gzip_data_vd)
    } else {
      console.error('Error: Received response from read_EF_VD was not the expected one.')
      throw new Error('t1_unknown_response_code ' + this.evaluate_response_from_read_EF_VD.name)
    }
  }
}
