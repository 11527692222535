import styled from 'styled-components';

export const StyledKassenbuchSidebar = styled.div`
  ${({ theme }) => `
    border-left: 1px solid ${theme.palette.primary.main};
    height: 100%;
    .MuiPickersBasePicker-pickerView {
      align-self: center;
    }
    .MuiPickersToolbar-toolbarLandscape {
      max-width: unset;
    }
    .MuiPickersBasePicker-containerLandscape {
      flex-direction: column;
    }
  `}
`;
