import styled from 'styled-components';
import platform from 'platform'

// use css -webkit-backdrop-filter property on supported browsers
// http://caniuse.com/#feat=css-backdrop-filter
const useFilterBackdrop =
  platform.name === 'Safari' &&
  (parseFloat(platform.version) >= 10 ||
    (parseFloat(platform.version) >= 9.3 && platform.os.family.indexOf('iOS') === 0))

const backdropFilter = 'blur(10px) grayscale(75%)'

export const StyledLockscreen = styled.div`
  ${({ theme, locked }: {theme, locked}) => `
    height: 100%;

    .appContainer {
      height: 100%;
    }

    .overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9999;
      left: 0;
      top: 0;
      opacity: 1;
      display: ${locked ? 'block': 'none'};

      .backgroundContainer {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        transition: -webkit-backdrop-filter 0.3s ease-in-out;
        -webkit-backdrop-filter: ${backdropFilter};
        backdrop-filter: ${backdropFilter};

        canvas {
          width: 100%;
          height: 100%;
        }
      }
    }
  `}
`;
