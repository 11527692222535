import { ListItemIcon } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { FC, useRef, useState } from 'react'
import MenuItemsLeistungPrivat from './MenuItemsLeistungPrivat'
import { StyledMenuLeistungPrivat } from './StyledMenuLeistungPrivat'

interface Props {
  leistungPrivatId?: number,
  handleMenuClose?: () => void,
}

const IconMenuLeistungPrivat: FC<Props> = ({
  leistungPrivatId,
  handleMenuClose
}) => {

  const iconRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (event) => {
    event?.stopPropagation()
    setOpen(true)
  }

  return (<>
    <ListItemIcon
      style={{
        minWidth: 'unset',
        cursor: 'pointer'
      }}
      onClick={handleOpen}
      ref={iconRef}>
      <MoreVertIcon />
    </ListItemIcon>
    <StyledMenuLeistungPrivat
      PaperProps={{ className: 'paper' }}
      open={open}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorEl={iconRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableAutoFocus>
        <MenuItemsLeistungPrivat
          leistungPrivatId={leistungPrivatId}
          onCloseFunc={() => {
            handleClose()
            handleMenuClose && handleMenuClose()
          }} />
    </StyledMenuLeistungPrivat>
  </>)
}

export default IconMenuLeistungPrivat
