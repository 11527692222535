import { FC, useEffect } from 'react'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import { StyledCheckboxList } from './StyledCheckboxList'

interface Props {
  value?: Array<string>,
  onChange: (data?: Array<string>) => void,
  items: Array<{
    label: string,
    value: string
  }>,
  className?: string,
  selectOnlyOne?: boolean,
  disabled?: boolean,
  editMode?: boolean | undefined,
}

const getIsChecked = (list: Array<string> | string, value: string, selectOnlyOne?: boolean, disabled?: boolean, editMode?: boolean | undefined): boolean => {
  if (selectOnlyOne && (disabled || editMode)) {
    return list === value || (Array.isArray(list) && list[0] === value);
  }

  return Array.isArray(list) && list.indexOf(value) !== -1;
}

const CheckboxList: FC<Props> =
  ({
     value = [],
     onChange,
     items = [],
     className,
     selectOnlyOne,
     disabled,
     editMode,
   }) => {
    useEffect(() => {
      if (!value && !Array.isArray(value)) {
        onChange && onChange([])
      }
    }, [value])


    const handleCheck = (checked: boolean, itemValue: string, selectOnlyOne: boolean = false) => {
      if (Array.isArray(value) && !selectOnlyOne) {
        checked
          ? onChange([...value, itemValue])
          : onChange(value.filter((item: string) => item !== itemValue))
      } else {
        checked
          ? onChange([itemValue])
          : onChange([])
      }
    }

    return (
      <StyledCheckboxList className={className}>
        {items.map((item, index) => (
          <CheckboxField
            key={index}
            className="checkbox"
            label={item.label}
            checked={getIsChecked(value, item.value, selectOnlyOne, disabled, editMode)}
            onChange={(checked: boolean) => handleCheck(checked, item.value, selectOnlyOne)}
            disabled={disabled}
          />
        ))}
      </StyledCheckboxList>
    )
  }

export default CheckboxList
