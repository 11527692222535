import { FC, useCallback } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import StyledLink from '../../shared/components/StyledLink/StyledLink'

import { Container, MenuItem } from './styles'
import { anamneseQuestionsMap, IAnswer, IQuestionCount, IQuestionCountItem, sidebarListItems } from './utils'

const AnamnesisSidebar: FC<any> = ({ formValues }): JSX.Element => {
  const { patient, serverHash } = useParams()

  let questionCount: IQuestionCount = {
    '01': {
      name: '01',
      unanswered: 0,
      answered: 0,
    },
    '02': {
      name: '02',
      unanswered: 0,
      answered: 0,
    },
    '03': {
      name: '03',
      unanswered: 0,
      answered: 0,
    },
    '04': {
      name: '04',
      unanswered: 0,
      answered: 0,
    },
    '05': {
      name: '05',
      unanswered: 0,
      answered: 0,
    },
    '06': {
      name: '06',
      unanswered: 0,
      answered: 0,
    },
    '07': {
      name: '07',
      unanswered: 0,
      answered: 0,
    },
    '08': {
      name: '08',
      unanswered: 0,
      answered: 0,
    },
    '09': {
      name: '09',
      unanswered: 0,
      answered: 0,
    },
  }

  const countQuestions = useCallback((object, formValues): IAnswer => {
    let answered = 0
    let unanswered = 0
    unanswered++
    if (formValues[object.name]) {
      if (formValues[object.name].value || formValues[object.name].value === false) {
        if (!Array.isArray(formValues[object.name].value) || formValues[object.name].value.length > 0) {
          answered++
        }
      }
      if (formValues[object.name].value === true) {
        for (const childKey in object.children) {
          const nextcount = countQuestions(object.children[childKey], formValues)
          unanswered += nextcount.unanswered
          answered += nextcount.answered
        }
      }
    }
    return { unanswered, answered }
  }, [])

  const updateCategory = useCallback((category: IQuestionCountItem, answer: IAnswer): IQuestionCountItem => {
    return {
      ...category,
      answered: (category.answered += answer.answered),
      unanswered: (category.unanswered += answer.unanswered),
    }
  }, [])

  const updateQuestionCount = useCallback(
    (questionCount: IQuestionCount): IQuestionCount => {
      for (const category in questionCount) {
        const questions = anamneseQuestionsMap[category]

        let currentCategory = questionCount[category]
        // eslint-disable-next-line no-prototype-builtins
        if (questions.hasOwnProperty('a') && questions.hasOwnProperty('b')) {
          const answersA = countQuestions(questions.a, formValues)
          const answersB = countQuestions(questions.b, formValues)
          currentCategory = updateCategory(currentCategory, answersA)
          currentCategory = updateCategory(currentCategory, answersB)
        } else {
          const answers = countQuestions(questions, formValues)
          currentCategory = updateCategory(currentCategory, answers)
        }
      }

      return questionCount
    },
    [formValues],
  )

  questionCount = updateQuestionCount(questionCount)

  return (
    <Container>
      {sidebarListItems.map(({ key, name }) => (
        <StyledLink fullWidth to={`../${key}`} isRelative key={key}>
          <MenuItem>
            <span>{name}</span>
            <span>{`${questionCount[key].answered}/${questionCount[key].unanswered}`}</span>
          </MenuItem>
        </StyledLink>
      ))}
    </Container>
  )
}

const mapStateToProps = (state): any => ({
  formValues: state.form.anamneseForm,
})

export default connect(mapStateToProps)(AnamnesisSidebar)
