/*
  Sort arrays of persons (patients, doctors, therapists) with Array.prototype.sort ()
*/
interface IPerson {
  nachname: string;
  vorname: string;
}

export function sortByNachnameVorname(a: IPerson, b: IPerson) {
  const compareNachname = a?.nachname?.localeCompare(b?.nachname, 'de');
  if(compareNachname !== 0) {
    return compareNachname;
  }
  const compareVorname = a?.vorname?.localeCompare(b?.vorname, 'de');
  return compareVorname;
}
