import styled from 'styled-components'

export const StyledHVOListView = styled.table`
  ${({ theme }) => `
    flex-grow: 1;
    width: 100%;
    border-collapse: collapse;

    .TableBody{
      // border-top: 20px solid #fff;
    }
    .TableRow{
      background-color: #fff;
      :hover{
        background-color: ${theme.palette.primary.main};
      }
    }
    .highlightRow{
      background-color: ${theme.palette.primary.main};
      color: #000;
    }
    .columnHeader{
      text-transform: uppercase;
      padding: 5px 10px;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.bold{
        font-family: rubik-medium, sans-serif;
      }
    }
    .headerFontIcon{
      margin: 0px 10px;
      vertical-align: sub;
    }
    .columnContent{
      padding: 5px 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      border-bottom: 1px dotted #006;
      border-right: 1px dotted #006;
      &.name{
        font-family: rubik-medium, sans-serif;
        min-width: 200px;
      }
      &.editButton{
        overflow: visible;
        padding: 0 0;
      }
    }
    .showAll{
      border-top: 1px solid #006;
      text-align: center;
      &.empty {
        cursor: default;
      }
    }
    .editButton{
      text-transform: none;
    }
  `}
`
