import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { forwardRef, MouseEvent, MouseEventHandler } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'
import { GERMAN_DATE_LONG_YEAR_FNS } from '../../../constants/dateFormats'

interface Props {
  name: string
  control: any
  label: string

  disableToolbar?: boolean
  disableFuture?: boolean
  disablePast?: boolean
  className?: string
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  error?: boolean
  helperText?: string
  autoOk?: boolean
  format?: string
  rules?: RegisterOptions
  shouldUnregister?: boolean
  readOnly?: boolean
  onChange?: any
  onClick?: any
  hideCalendarIcon?: boolean
  disableDefault?: boolean
  defaultValue?: Date
}

export const ControlledDateField = forwardRef(
  (
    {
      name,
      control,
      label,
      disableToolbar = false,
      disableFuture = false,
      disablePast = false,
      disabled = false,
      className = '',
      minDate,
      maxDate,
      error = false,
      helperText = '',
      autoOk = true,
      format = GERMAN_DATE_LONG_YEAR_FNS,
      rules = {},
      shouldUnregister = false,
      readOnly = false,
      onChange,
      onClick,
      hideCalendarIcon,
      disableDefault,
      defaultValue,
    }: Props,
    ref,
  ): JSX.Element => {
    const { field } = useController({ name, rules, control, shouldUnregister, defaultValue: disableDefault ? null : defaultValue ? defaultValue : new Date() })

    const handleChange = (date: MaterialUiPickersDate) => {
      if (onChange) {
        onChange(date)
      }
      field.onChange(date)
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement, React.MouseEvent>) => {
      if (!!onClick) {
        e.preventDefault()
        e.currentTarget.blur()
        onClick()
      }
      
      if (readOnly || disabled) return
    }

    const InputProps = {
      id: `dateinput-${name}`,
      readOnly,
      inputProps: !!onClick ? { style: { cursor: 'pointer' } } : {},
      style: {},
    }

    if (readOnly && !onClick) {
      InputProps.style = { pointerEvents: 'none' }
    }

    if (readOnly && !!onClick) {
      InputProps.style = { cursor: 'pointer' }
    }

    const InputAdornmentProps = {
      disablePointerEvents: hideCalendarIcon,
      onClick: handleClick,
      id: `dateicon-${name}`,
      style: hideCalendarIcon ? { visibility: 'hidden' } : {},
    }

    return (
      <KeyboardDatePicker
        {...field}
        ref={ref}
        variant="inline"
        fullWidth
        disableToolbar={disableToolbar}
        label={rules?.required ? `${label} *` : label}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        autoOk={autoOk}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        readOnly={readOnly}
        disableFuture={disableFuture}
        disablePast={disablePast}
        className={className}
        disabled={disabled}
        InputAdornmentProps={{ ...InputAdornmentProps }}
        InputProps={InputProps}
        onClick={handleClick}
        style={(readOnly && !onClick) || disabled ? { pointerEvents: 'none' } : {}}
        onFocusCapture={(e) => {
          if (readOnly) {
            e.stopPropagation()
          }
        }}
      />
    )
  },
)
