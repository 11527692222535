/* eslint camelcase: 0 */
import * as types from '../../constants/actionTypes'
import gridBackground_12px_5min from '../../assets/img/background_grid_12px_5minuten.png'
import gridBackground_12px_10min from '../../assets/img/background_grid_12px_10minuten.png'
import gridBackground_18px_15min from '../../assets/img/background_grid_18px_15minuten.png'
import gridBackground_18px_20min from '../../assets/img/background_grid_18px_20minuten.png'
import gridBackground_27px_30min from '../../assets/img/background_grid_27px_30minuten.png'
import gridBackground_54px_60min from '../../assets/img/background_grid_54px_60minuten.png'
import { filterUngueltigeZeiten } from '../../utils/zeiten'
import { parseISO, isValid, getMinutes, getHours } from 'date-fns'

const ALL_DAY_ITEMS_TOGGLE_SHOW_ALL = 'pod001_frontend/config/ALL_DAY_ITEMS_TOGGLE_SHOW_ALL'

export default function config(
  state = {
    calendarGrid: {
      default: {
        dateMinDuration: 5, // minimum date duration in minutes
        datesInterval: 15, // interval at which free dates are created ( always starting at the full hour. should be a fraction of 60 )
        firstHour: 6, // start time of each day
        gridRowMinutes: 15, // interval for each row in minutes
        gridRowHeight: 18, // height of each row in pixels
        gridBackground: gridBackground_18px_15min, // repeatable background image with grid lines, interdependent with gridRowHeight
        headerHeight: 57, // height of column headers in pixels
        isoWeekDays: [1, 2, 3, 4, 5], // Days of week that should be displayed in calendar ( 1 = Monday )
        numHours: 16, // hours to display
        allDayItemsLimit: 2, // how many all day items to show by default
        allDayItemsShowAll: false, // show all all day items?
        allDayItemHeight: 20,
        allDayItemMargin: 4,
      },
      freeDatesView: {
        gridRowMinutes: 15,
        gridBackground: gridBackground_18px_15min,
      },
      weekView: {
        headerHeight: 28,
      },
    },
  },
  action = undefined,
) {
  switch (action.type) {
    case types.LOAD_PRAXISSTAMMDATEN_SUCCESS:
    case types.UPDATE_PRAXISSTAMMDATEN_SUCCESS:
      const { praxisstammdaten } = action.json.entities
      // const terminraster = 5
      // const oeffnungszeiten = undefined
      const maybeHash = window.location.pathname.split('/')[1]
      const maybePs = praxisstammdaten[maybeHash] || {}
      const { terminraster = 20, oeffnungszeiten = undefined } = maybePs

      const tage = filterUngueltigeZeiten(oeffnungszeiten)
      const zeiten = tage.reduce((previous, current) => {
        return previous.concat(current.zeiten)
      }, [])

      const fruehesterBeginn = zeiten.reduce(
        (previous, current) => {
          const mPrev = parseISO(previous.von)
          const mCurr = parseISO(current.von)
          const a = isValid(mPrev) ? getMinutes(mPrev) + getHours(mPrev) * 60 : null
          const b = isValid(mCurr) ? getMinutes(mCurr) + getHours(mCurr) * 60 : null
          return b < a || !previous.von ? current : previous
        },
        { von: null, bis: null },
      ).von

      const spaetestesEnde = zeiten.reduce(
        (previous, current) => {
          const mPrev = parseISO(previous.bis)
          const mCurr = parseISO(current.bis)
          const a = isValid(mPrev) ? getMinutes(mPrev) + getHours(mPrev) * 60 : null
          const b = isValid(mCurr) ? getMinutes(mCurr) + getHours(mCurr) * 60 : null
          return b > a || !previous.bis ? current : previous
        },
        { von: null, bis: null },
      ).bis

      const firstHour = getHours(parseISO(fruehesterBeginn))
      const closingHour = getHours(parseISO(spaetestesEnde))
      const closingMinutes = getMinutes(parseISO(spaetestesEnde))
      const numHours = closingHour - firstHour + (closingMinutes > 0 ? 1 : 0)

      const weekdays = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7,
      }

      const isoWeekDays = tage.map((tag) => weekdays[tag.tag]).sort()

      let background
      let rowHeight
      let duration
      switch (terminraster) {
        case 5:
          background = gridBackground_12px_5min
          rowHeight = 12
          duration = 5
          break
        case 10:
          background = gridBackground_12px_10min
          rowHeight = 12
          duration = 10
          break
        case 15:
          background = gridBackground_18px_15min
          rowHeight = 18
          duration = 15
          break
        case 20:
          background = gridBackground_18px_20min
          rowHeight = 18
          duration = 20
          break
        case 30:
          background = gridBackground_27px_30min
          rowHeight = 27
          duration = 30
          break
        case 60:
          background = gridBackground_54px_60min
          rowHeight = 54
          duration = 60
          break
        default:
      }
      return {
        ...state,
        calendarGrid: {
          ...state.calendarGrid,
          default: {
            ...state.calendarGrid.default,
            firstHour: firstHour || state.calendarGrid.default.firstHour,
            numHours: numHours || state.calendarGrid.default.numHours,
            datesInterval: duration || state.calendarGrid.default.datesInterval,
            gridRowMinutes: duration || state.calendarGrid.default.gridRowMinutes,
            gridRowHeight: rowHeight || state.calendarGrid.default.gridRowHeight,
            gridBackground: background || state.calendarGrid.default.gridBackground,
            isoWeekDays: isoWeekDays.length > 0 ? isoWeekDays : state.calendarGrid.default.isoWeekDays,
          },
          freeDatesView: {
            ...state.calendarGrid.freeDatesView,
            gridRowMinutes: duration || state.calendarGrid.freeDatesView.gridRowMinutes,
            gridBackground: background || state.calendarGrid.freeDatesView.gridBackground,
          },
        },
      }

    case ALL_DAY_ITEMS_TOGGLE_SHOW_ALL:
      return {
        ...state,
        calendarGrid: {
          ...state.calendarGrid,
          default: {
            ...state.calendarGrid.default,
            allDayItemsShowAll: !state.calendarGrid.default.allDayItemsShowAll,
          },
        },
      }

    default:
  }

  return state
}

export const allDayItemsToggleShowAll = () => ({ type: ALL_DAY_ITEMS_TOGGLE_SHOW_ALL })
