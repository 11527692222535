import { Info as InfoIcon } from '@material-ui/icons';
import { FC } from 'react';

interface Props {
  className?: string;
  whitelisted?: boolean;
  disabled?: boolean;
}

const DisabledInformation: FC<Props> = ({ className, whitelisted, disabled }) => (
  <div className={className} style={{ marginTop: 10 }}>
    {!whitelisted && !disabled && (
      <span>
        <InfoIcon style={{verticalAlign: 'middle'}} color="secondary" />&nbsp;
        Der Zugriff auf diese Praxis ist eingeschränkt.
        <br />
        Bei Fragen wenden Sie sich bitte an den Inhaber oder den&nbsp;
        <a onClick={() => window['fcWidget']?.open()}>
          pododesk Support
        </a>
        .
      </span>
    )}
    {disabled && (
      <span>
        <InfoIcon color="secondary" style={{verticalAlign: 'middle'}} />&nbsp;
        Diese Praxis wurde deaktiviert.
        <br />
        Bei Fragen zur Reaktivierung wenden Sie sich bitten an den&nbsp;
        <a onClick={() => window['fcWidget']?.open()}>
          pododesk Support
        </a>.
      </span>
    )}
  </div>
)

export default DisabledInformation
