import { Button, CardActions, CardContent } from '@material-ui/core'
import { Https as HttpsIcon } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { StyledUserCard } from './StyledUserCard'

const UserCard = ({ title, className }) => {
  const navigate = useNavigate()

  return (
    <StyledUserCard square className={className}>
      <CardContent className="content">
        {title}
      </CardContent>
    </StyledUserCard>
  )
}

export default UserCard
