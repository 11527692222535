import { ListItemIcon, MenuItem } from '@material-ui/core'
import { addWeeks } from 'date-fns'
import { FC } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as datesActions from '../../actions/dates'
import * as heilmittelverordnungenActions from '../../actions/heilmittelverordnungen'
import { abgesagteTermineEntfernen, sortByBeginnDescending } from '../../utils/dates'
import { StyledListItemText } from './StyledListItemText'

import {
  Eco as EcoIcon, Event as EventIcon,
  LocalHospital as LocalHospitalIcon, Visibility as VisibilityIcon
} from '@material-ui/icons'

const MenuItemsHeilmittelverordnung: FC<any> = ({
  actions,
  heilmittelverordnungen,
  heilmittelverordnung,
  termine,
  onCloseFunc
}) => {

  const { serverHash } = useParams()
  const navigate = useNavigate()

  const viewHVO = (id) => {
    onCloseFunc()
    navigate(`/${serverHash}/hvo/${id}`)
  }

  const folgeTermin = (id) => {
    onCloseFunc()
    const heilmittelverordnung = heilmittelverordnungen[id]
    const frequenz = heilmittelverordnung.minFrequenz
    const hvoTermine = heilmittelverordnung.termine.map((key) => termine[key])
    const nichtAbgesagteTermine = abgesagteTermineEntfernen(hvoTermine).sort(sortByBeginnDescending)
    let dateFolgeTermin
    let letzterTermin: any = {}
    if (nichtAbgesagteTermine.length > 0) {
      letzterTermin = nichtAbgesagteTermine[0]
      dateFolgeTermin = addWeeks(letzterTermin.beginn, frequenz)
    } else {
      dateFolgeTermin = new Date()
    }

    // Unselect "Eingangsbefundung" if it was selected in the previous appointment
    if (letzterTermin?.leistungen?.some((l) => l.positionsnummer === 78040)) {
      letzterTermin = {
        ...letzterTermin,
        leistungen: letzterTermin.leistungen.filter((l) => l.positionsnummer !== 78040)
      }
    }

    actions.termine.selectTerminProps(letzterTermin)
    actions.termine.selectDay(dateFolgeTermin)
    navigate(`/${serverHash}/calendar/free`)
  }

  const newTherapieBericht = (patientId) => {
    onCloseFunc()
    navigate(`/${serverHash}/reports/add/${patientId}`)
  }

  const newHeilmittelverordnung = (patientId) => {
    onCloseFunc()
    navigate(`/${serverHash}/hvo/add/${patientId}`)
  }

  if (!heilmittelverordnung) {
    return null
  }

  const termineOhneAbgesagte = heilmittelverordnung.termine
    .map((key) => termine[key])
    .filter((termin) => termin !== undefined)
  const verordnungsmengeErreicht =
    abgesagteTermineEntfernen(termineOhneAbgesagte).length >= heilmittelverordnung.verordnungsmenge
  const showFolgeterminVereinbaren =
    !verordnungsmengeErreicht && !heilmittelverordnung.abgerechnet && !heilmittelverordnung.behandlungAbgebrochen


  return (<div>
    <MenuItem onClick={() => viewHVO(heilmittelverordnung.id)}>
      <ListItemIcon>
        <VisibilityIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="HVO anzeigen" />
      {/* Show HVO */}
    </MenuItem>

    { showFolgeterminVereinbaren ? (
      <MenuItem onClick={() => folgeTermin(heilmittelverordnung.id)}>
        <ListItemIcon>
          <EventIcon color="secondary" />{/* ion-android-calendar */}
        </ListItemIcon>
        <StyledListItemText primary="Folgetermin vereinbaren" />
       {/* Arrange a follow-up appointment */}
      </MenuItem>
    ) : null }

    <MenuItem onClick={() => newTherapieBericht(heilmittelverordnung.patient)}>
      <ListItemIcon>
        <LocalHospitalIcon color="secondary" /> {/* ion-medkit */}
      </ListItemIcon>
      <StyledListItemText primary="Therapiebericht erfassen" />
      {/* Record therapy report */}
    </MenuItem>

    <MenuItem onClick={() => newHeilmittelverordnung(heilmittelverordnung.patient)}>
      <ListItemIcon>
        <EcoIcon color="secondary" /> {/* ion-leaf */}
      </ListItemIcon>
      <StyledListItemText primary="neue HVO" />
      {/* new HVO */}
    </MenuItem>
  </div>)
};

const mapStateToProps = (state) => ({
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  termine: state.entities.termine,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    termine: bindActionCreators(datesActions, dispatch),
    heilmittelverordnungen: bindActionCreators(heilmittelverordnungenActions, dispatch),
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsHeilmittelverordnung)
