import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { StyledIconHeading } from './StyledIconHeading';

interface Props {
  text: any;
  icon: any;
  className?: string;
}

const IconHeading: FC<Props> = ({ text, icon, className }): JSX.Element => (
  <StyledIconHeading className={className}>
    <div className="icon">{icon}</div>
    <Typography variant="body1" className="text">
      {text}
    </Typography>
  </StyledIconHeading>
);

export default IconHeading;
