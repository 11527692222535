import { SvgIcon, type SvgIconProps } from '@material-ui/core'

export const InstagramIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 31.5 31.5"
    enableBackground="new 0 0 31.5 31.5"
    xmlSpace="preserve"
  >
    <g>
      <path d="M15.7,2.8c4.2,0,4.7,0,6.4,0.1C23.6,3,24.5,3.3,25,3.5c0.7,0.3,1.3,0.6,1.8,1.2c0.6,0.6,0.9,1.1,1.2,1.8  c0.2,0.6,0.5,1.4,0.5,2.9c0.1,1.7,0.1,2.2,0.1,6.4s0,4.7-0.1,6.4c-0.1,1.5-0.3,2.4-0.5,2.9c-0.3,0.7-0.6,1.3-1.2,1.8  c-0.6,0.6-1.1,0.9-1.8,1.2c-0.6,0.2-1.4,0.5-2.9,0.5c-1.7,0.1-2.2,0.1-6.4,0.1s-4.7,0-6.4-0.1C7.8,28.5,7,28.2,6.5,28  c-0.7-0.3-1.3-0.6-1.8-1.2c-0.6-0.6-0.9-1.1-1.2-1.8c-0.2-0.6-0.5-1.4-0.5-2.9c-0.1-1.7-0.1-2.2-0.1-6.4s0-4.7,0.1-6.4  C3,7.8,3.3,7,3.5,6.5c0.3-0.7,0.6-1.3,1.2-1.8c0.6-0.6,1.1-0.9,1.8-1.2C7,3.3,7.8,3,9.4,2.9C11,2.9,11.5,2.8,15.7,2.8 M15.7,0  c-4.3,0-4.8,0-6.5,0.1C7.6,0.2,6.4,0.4,5.4,0.8c-1,0.4-1.9,0.9-2.8,1.8C1.8,3.5,1.2,4.4,0.8,5.4c-0.4,1-0.7,2.1-0.7,3.8  C0,10.9,0,11.5,0,15.7c0,4.3,0,4.8,0.1,6.5c0.1,1.7,0.3,2.8,0.7,3.8c0.4,1,0.9,1.9,1.8,2.8c0.9,0.9,1.8,1.4,2.8,1.8  c1,0.4,2.1,0.7,3.8,0.7c1.7,0.1,2.2,0.1,6.5,0.1s4.8,0,6.5-0.1c1.7-0.1,2.8-0.3,3.8-0.7c1-0.4,1.9-0.9,2.8-1.8  c0.9-0.9,1.4-1.8,1.8-2.8c0.4-1,0.7-2.1,0.7-3.8c0.1-1.7,0.1-2.2,0.1-6.5s0-4.8-0.1-6.5c-0.1-1.7-0.3-2.8-0.7-3.8  c-0.4-1-0.9-1.9-1.8-2.8c-0.9-0.9-1.8-1.4-2.8-1.8c-1-0.4-2.1-0.7-3.8-0.7C20.6,0,20,0,15.7,0L15.7,0z"></path>
      <path d="M15.7,7.7c-4.5,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1S20.2,7.7,15.7,7.7z M15.7,21c-2.9,0-5.2-2.4-5.2-5.2  s2.4-5.2,5.2-5.2c2.9,0,5.2,2.4,5.2,5.2S18.6,21,15.7,21z"></path>
      <circle cx="24.2" cy="7.3" r="1.9"></circle>
    </g>
  </SvgIcon>
)
