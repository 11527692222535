/* eslint guard-for-in:0 */
import { format, isAfter, isBefore, isValid, parseISO } from 'date-fns'
import { isDate } from 'date-fns/esm'
import { ISO8601DATETIME_FNS } from '../../constants/dateFormats'

export function datesToStrings(data) {
  if (typeof data !== 'object') {
    return data
  }

  const result = {}
  for (const key in data) {
    if (isDate(data[key])) {
      if (isValid(data[key])) {
        result[key] = format(data[key], ISO8601DATETIME_FNS)
      } else {
        result[key] = null
      }
    } else {
      result[key] = data[key]
    }
  }
  return result
}

export function stringsToDates(data, fields) {
  if (typeof data !== 'object' || !Array.isArray(fields)) {
    return data
  }

  const result = {}
  for (const key in data) {
    result[key] = stringsToDatesSingleEntity(data[key], fields)
  }

  return result
}

export function stringsToDatesSingleEntity(data, fields) {
  if (typeof data !== 'object' || !Array.isArray(fields)) {
    return data
  }

  const result = {}
  for (const key in data) {
    if (fields.indexOf(key) !== -1) {
      const date = parseISO(data[key])
      if (isValid(date) && isDate(date)) {
        result[key] = date
      } else {
        result[key] = null
      }
    } else {
      result[key] = data[key]
    }
  }

  return result
}

export function sortByDateFieldAscending(field) {
  return (a, b) => {
    if (isBefore(a[field], b[field])) {
      return -1
    }
    if (isBefore(b[field], a[field])) {
      return 1
    }
    return 0
  }
}

export function sortByDateFieldDescending(field) {
  return (a, b) => {
    if (isAfter(a[field], b[field])) {
      return -1
    }
    if (isAfter(b[field], a[field])) {
      return 1
    }
    return 0
  }
}
