import { Button, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import zxcvbn from 'zxcvbn'
import { changePasswordLoggedIn } from '../../redux/modules/password'
import { sCurrentUser } from '../../utils/users'
import PasswordInput from '../PasswordInput/PasswordInput'
import { StyledChangePasswordLoggedIn } from './StyledChangePasswordLoggedIn'

export const fields = ['oldPassword', 'newPassword', 'newPasswordConfirmation']

const ChangePasswordLoggedIn = ({ error, fields, handleSubmit, currentUser, initializeForm }) => {
  useEffect(() => {
    initializeForm({
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    })
  }, [currentUser])

  //TODO: Check why form fields values always empty string
  const { oldPassword, newPassword, newPasswordConfirmation } = fields
  const email = currentUser.email ?? 'lade...' // loading;

  return (
    <StyledChangePasswordLoggedIn square>
      <form className="changePasswordForm" onSubmit={handleSubmit} autoComplete="off">
        <h2 className="emailHeading">{email}</h2>
        <div>
          <Typography variant="body2" gutterBottom>
            Bitte geben Sie Ihr aktuelles Passwort ein:
            {/* Please enter your current password: */}
          </Typography>
          <PasswordInput
            {...oldPassword}
            fullWidth
            autoComplete="off"
            type="password"
            placeholder="Aktuelles Passwort" // current password
          />
        </div>
        <div>
          <Typography variant="body2" gutterBottom>
            Geben Sie ein neues Passwort ein:
            {/* Enter a new password: */}
          </Typography>
          <PasswordInput
            {...newPassword}
            fullWidth
            autoComplete="off"
            type="password"
            placeholder="Neues Passwort"// new password
            minScore={2}
          />
        </div>
        <div>
          <Typography variant="body2" gutterBottom>
            Wiederholen Sie Ihr neues Passwort:
            {/* Repeat your new password: */}
          </Typography>
          <PasswordInput
            {...newPasswordConfirmation}
            fullWidth
            autoComplete="off"
            type="password"
            placeholder="Neues Passwort wiederholen" // Repeat new password
            minScore={2}
          />
        </div>
        <div>{newPassword.touched && newPasswordConfirmation.touched && error}</div>
        <Button variant="contained" color="secondary" type="submit" fullWidth>
          Neues Passwort speichern
          {/* Save new password */}
        </Button>
        <div>
          <Typography variant="body2" gutterBottom>
            Bitte wählen Sie ein Passwort bei dem der Balken grün und der Status mindestens &quot;gut&quot; ist.
            {/* Please choose a password in which the bar is green and the status is at least & quot; good & quot; is. */}
          </Typography>
          <Typography variant="body2">
            Die Verwendung von Sonderzeichen oder längeren Passwörtern erhöht dabei die Sicherheit.
            {/* The use of special characters or longer passwords increases security. */}
          </Typography>
        </div>
      </form>
    </StyledChangePasswordLoggedIn>
  )
}

const validate = (values, props) => {
  const errors: KeyValue<string> = {};

  const { currentUser } = props

  const excludeUserInputs = getExcludeWords(currentUser)

  if (!values.newPassword) {
    errors._error = 'Bitte geben Sie Ihre neues Passwort ein!'
    // Please enter your new password!
  }

  if (values.newPassword !== values.newPasswordConfirmation) {
    errors._error = 'Die letzten beiden Passworteingaben müssen miteinander übereinstimmen!'
    // The last two password entries must match each other!
  }

  const passwordData = zxcvbn(values.newPassword || '', excludeUserInputs)
  const { score } = passwordData

  if (values.newPassword && score < 2) {
    errors._error = 'Das neue Passwort ist zu schwach!'
    // The new password is too weak!
  }

  if (!values.oldPassword) {
    errors._error = 'Bitte geben Sie Ihre aktuelles Passwort ein!'
    // Please enter your current password
  }

  return errors
}

const getExcludeWords = (user) => {
  const { ...other } = user
  return Object.keys(other).map((key) => other[key])
}

const mapStateToProps = (state) => ({
  currentUser: sCurrentUser(state),
})
const mapDispatchToProps = (dispatch) => ({
  onSubmit: bindActionCreators(changePasswordLoggedIn, dispatch),
})

export default reduxForm(
  {
    form: 'changePasswordLoggedInForm',
    validate,
    fields,
  },
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordLoggedIn)
