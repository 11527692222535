import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper, Typography
} from '@material-ui/core';
import { People as PeopleIcon } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { memo, useCallback, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { showNotification } from '../../actions/notification';
import * as usersActions from '../../actions/people';
import Zeiten from '../../components/Zeiten/Zeiten';
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import InputField from '../../shared/components/InputField';
import { apiServerHashSelector } from '../../shared/utils/auth';
import { sCurrentUserApi } from '../../shared/utils/users';
import { getApiUrl } from '../../utils/auth';
import { StyledEditUsersForm } from './StyledEditUsersForm';

const fields = [
  'id',
  'authUserId',
  'vorname',
  'nachname',
  'email',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'mobil',
  'praxisbezeichnung',
  'funktionInPraxis',
  'geschlecht',
  'wieVonPododeskErfahren',
  'agbAngenommen',
  'datenschutzbedingungenGelesen',
  'farbe',
  'istTherapeut',
  'disabled',
  'arbeitszeiten',
];

const EditUsersForm = ({ actions, users, usersIds, apiUrl, currentUser, fields, initializeForm, handleSubmit }) => {
  const [currentICalendarToken, setCurrentICalendarToken] = useState<any>(null);
  const [confirmDeleteICalendarToken, setConfirmDeleteICalendarToken] = useState<boolean>(false);
  const [confirmResetICalendarToken, setConfirmResetICalendarToken] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleInitalizeFormWithUser = (id) => async () => {
    const { iCalendarToken } = await actions.users.getICalendarToken(id);
    const user = users[id];
    setCurrentICalendarToken(iCalendarToken);
    setSelectedUser(user.id);
    await initializeForm({});
    await initializeForm({
      ...user,
      arbeitszeiten: user.arbeitszeiten
        ? user.arbeitszeiten
        : [
            {
              tag: 'mon',
              zeiten: [
                {
                  von: '',
                  bis: '',
                },
              ],
            },
          ],
    });
  };

  const onSubmit = useCallback(async (data) => {
    console.log('submitting', data);
    await actions.users.updateInstanceUser(data);
  }, [actions.users.updateInstanceUse]);

  const openConfirmResetICalendarTokenDialog = useCallback(() => {
    setConfirmResetICalendarToken(true);
  }, [setConfirmResetICalendarToken]);

  const closeConfirmResetICalendarTokenDialog = useCallback(() => {
    setConfirmResetICalendarToken(false);
  }, []);

  const resetICalendarToken = (userId) => async () => {
    const { iCalendarToken } = await actions.users.resetICalendarToken(userId);
    setCurrentICalendarToken(iCalendarToken);
    closeConfirmResetICalendarTokenDialog();
  };

  const openConfirmDeleteICalendarTokenDialog = useCallback(() => {
    setConfirmDeleteICalendarToken(true);
  }, [setConfirmDeleteICalendarToken]);

  const closeConfirmDeleteICalendarTokenDialog = useCallback(() => {
    setConfirmDeleteICalendarToken(false);
  }, [setConfirmDeleteICalendarToken]);

  const deleteICalendarToken = (userId) => async () => {
    const { iCalendarToken } = await actions.users.deleteICalendarToken(userId);
    setCurrentICalendarToken(iCalendarToken);
    closeConfirmDeleteICalendarTokenDialog();
  };

  const { id, istTherapeut, disabled, arbeitszeiten } = fields;

  const calendarUrl = `${apiUrl}/public/kalender/${currentICalendarToken}.ics`;
  const calendarUrlFieldRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <IconHeading icon={<PeopleIcon />} text="Mitarbeiter der Praxis" />
      <StyledEditUsersForm>
        <div className="usersListContainer">
          <Paper square elevation={2} className="usersListPaper">
            <List
              dense
              subheader={
                <Typography variant="h6" className="listHeader" paragraph>
                  Aktive Mitarbeiter
                </Typography>
              }
              className="userList"
            >
              {usersIds
                .map((key) => users[key])
                .map(
                  (user) =>
                    !user.disabled && (
                      <ListItem
                        onClick={handleInitalizeFormWithUser(user.id)}
                        key={user.id}
                        className={selectedUser === user.id ? 'listItemActive' : 'listItem'}
                      >
                        <ListItemText
                          primary={`${user.vorname} ${user.nachname}`}
                          primaryTypographyProps={{ variant: 'body1' }}
                          secondary={
                            user.disabled
                              ? 'Praxiszugriff deaktiviert'
                              : user.istTherapeut
                              ? 'Therapeut mit Kalenderspalte'
                              : 'kein Therapeut'
                          }
                        />
                      </ListItem>
                    )
                )}
            </List>
          </Paper>
          <Paper square elevation={2} className="usersListPaper">
            <List
              dense
              subheader={
                <Typography className="listHeader" variant="h6" paragraph>
                  Deaktivierte Mitarbeiter
                </Typography>
              }
              className="userList"
            >
              {usersIds
                .map((key) => users[key])
                .map(
                  (user) =>
                    !!user.disabled && (
                      <ListItem
                        onClick={handleInitalizeFormWithUser(user.id)}
                        key={user.id}
                        className={selectedUser === user.id ? 'listItemActive' : 'listItem'}
                      >
                        <ListItemText
                          primary={`${user.vorname} ${user.nachname}`}
                          secondary={
                            user.disabled
                              ? 'Praxiszugriff deaktiviert'
                              : user.istTherapeut
                              ? 'Therapeut mit Kalenderspalte'
                              : 'kein Therapeut'
                          }
                        />
                      </ListItem>
                    )
                )}
            </List>
          </Paper>
        </div>
        <Paper square elevation={2}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form">
            {!id.value && <Typography variant="body1">Bitte wählen Sie einen Mitarbeiter aus.</Typography>}
            {id.value && (
              <>
                <div className="checkboxGroup">
                  <CheckboxField
                    {...disabled}
                    label="Praxiszugriff deaktiviert"
                    disabled={id.value === currentUser.id}
                  />
                  <CheckboxField
                    label="Mitarbeiter ist Therapeut"
                    {...istTherapeut} />
                </div>
                <div className="formGroup">
                  {currentICalendarToken ? (
                    <div>
                      <Button
                        className="iCalendarButton"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          actions.showNotification('Kalender-URL in Zwischenablage kopiert.');
                          copy(calendarUrl);
                        }}
                      >
                        in Zwischenablage kopieren
                      </Button>
                      <Button
                        className="iCalendarButton"
                        variant="contained"
                        onClick={openConfirmResetICalendarTokenDialog}
                      >
                        URL neu erzeugen
                      </Button>
                      <Button
                        className="iCalendarButton"
                        variant="contained"
                        onClick={openConfirmDeleteICalendarTokenDialog}
                      >
                        URL löschen
                      </Button>
                      <a
                        className="formGroup__link"
                        href="https://pododesk.freshdesk.com/support/solutions/articles/17000086722-pododesk-kalender-synchronisation-einrichten"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Hilfe zu dieser Funktion
                      </a>
                      <InputField
                        ref={calendarUrlFieldRef}
                        onFocus={() => calendarUrlFieldRef?.current?.select()}
                        value={calendarUrl}
                      />
                    </div>
                  ) : (
                    <div>
                      <Button variant="contained" onClick={openConfirmResetICalendarTokenDialog}>
                        Kalender-URL erzeugen
                      </Button>
                      <a
                        className="formGroup__link"
                        href="https://pododesk.freshdesk.com/support/solutions/articles/17000086722-pododesk-kalender-synchronisation-einrichten"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Hilfe zu dieser Funktion
                      </a>
                    </div>
                  )}
                  <Zeiten {...arbeitszeiten} />
                </div>
                <div>
                  <Button 
                    variant="contained"
                    type='submit'>
                    Mitarbeiter speichern
                  </Button>
                </div>
              </>
            )}
          </form>
        </Paper>
      </StyledEditUsersForm>
      <Dialog disableBackdropClick open={confirmResetICalendarToken} onClose={closeConfirmResetICalendarTokenDialog}>
        <DialogTitle>Kalender URL erzeugen</DialogTitle>
        <DialogContent>
          Durch das Erzeugen einer Kalender URL werden die Termine dieses Mitarbeiters zum synchronisieren auf externen
          Geräten freigegeben.
          <br />
          Vorherige URLs (falls vorhanden) werden hierdurch ungültig. Geräte, auf denen diese verwendet wird, erhalten
          dann keine Terminaktualisierungen mehr.
          <br />
          <br />
          Für Hilfe zur Einrichtung der Synchronisation auf einem externen Gerät (z.B. Handy oder Tablet) besuchen Sie
          bitte&nbsp;
          <a
            href="https://pododesk.freshdesk.com/support/solutions/articles/17000086722-pododesk-kalender-synchronisation-einrichten/"
            target="_blank"
            rel="noreferrer"
          >
            unser Hilfe-Portal
          </a>&nbsp;
          <br />
          <br />
          Sie können die URL jederzeit neu erzeugen oder löschen, um den Zugang zu den Terminen zu entziehen.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={closeConfirmResetICalendarTokenDialog}>
            Abbrechen
          </Button>
          <Button variant="contained" color="secondary" onClick={resetICalendarToken(id.value)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick open={confirmDeleteICalendarToken} onClose={closeConfirmDeleteICalendarTokenDialog}>
        <DialogTitle>Kalender URL löschen</DialogTitle>
        <DialogContent>
          Durch das Löschen der URL für diesen Mitarbeiter wird die bisherige Kalender URL ungültig.
          <br />
          Geräte, auf denen diese verwendet wird, erhalten dann keine Terminaktualisierungen mehr.
          <br />
          Es steht außerdem keine URL zum Einbinden auf externen Geräten mehr zur Verfügung.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={closeConfirmDeleteICalendarTokenDialog}>
            Abbrechen
          </Button>
          <Button variant="contained" color="secondary" onClick={deleteICalendarToken(id.value)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const validate = (values) => {
  const errors: KeyValue<string> = {};

  const required = [];

  required.forEach((fieldName) => {
    if (!values[fieldName] && values[fieldName] !== 0) {
      errors[fieldName] = 'Bitte ausfüllen.';
    }
  });

  return errors;
};

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  users: state.entities.users,
  usersIds: state.ids.users,
  apiUrl: getApiUrl(apiServerHashSelector(state)),
  currentUser: sCurrentUserApi(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    users: bindActionCreators(usersActions, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
  },
});

export default reduxForm(
  {
    form: 'editUsersForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(EditUsersForm));
