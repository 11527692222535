import styled from 'styled-components';

export const StyledLeistungenPrivatForm = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${theme.spacing(4)}px;
    padding: ${theme.spacing(3)}px;

    .listContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(6)}px;
    }

    .formContainer {
      padding: ${theme.spacing(3)}px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .buttonContainer {
      padding: ${theme.spacing(4)}px;
      display: grid;
      grid-template-columns: repeat(2, 0.25fr);
      grid-column-gap: ${theme.spacing(4)}px;
    }

    .button {
      background-color: #fff;
    }

    .listHeader {
      margin-left: ${theme.spacing(3)}px;
      margin-top: ${theme.spacing(3)}px;
    }
  `}
`;
