import { Button, IconButton } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import lodash from 'lodash';
import { useCallback } from 'react';
import FormDivider from '../../shared/components/FormDivider/FormDivider';
import { SelectField } from '../../shared/components/SelectField/SelectField';
import TimePickerField from '../../shared/components/TimePickerField/TimePickerField';
import { StyledZeiten } from './StyledZeiten';

const tage = [
  { value: 'mon', text: 'Montag' },
  { value: 'tue', text: 'Dienstag' },
  { value: 'wed', text: 'Mittwoch' },
  { value: 'thu', text: 'Donnerstag' },
  { value: 'fri', text: 'Freitag' },
  { value: 'sat', text: 'Samstag' },
  { value: 'sun', text: 'Sonntag' },
];

const Zeiten = ({ value, onChange }): JSX.Element => {
  const getAvailableDays = useCallback((addThisDay?: string) => {
    const availableDays = tage.filter((oneOfAllDays) => {
      if (oneOfAllDays.value === addThisDay) {
        return true;
      }
      return !value.some((oneOfUsedDays) => oneOfAllDays.value === oneOfUsedDays.tag);
    });
    return availableDays;
  }, [value]);

  const handleAddDayClick = useCallback(() => {
    const availableDays = getAvailableDays();
    if (!value) {
      onChange([
        {
          tag: 'mon',
          zeiten: [
            {
              von: '',
              bis: '',
            },
          ],
        },
      ]);
    } else if (Array.isArray(value) && value.length < 7) {
      onChange([
        ...value,
        {
          tag: availableDays[0].value,
          zeiten: [
            {
              von: '',
              bis: '',
            },
          ],
        },
      ]);
    }
  }, [getAvailableDays, value, onChange]);

  const handleRemoveDayClick = useCallback(() => {
    if (value.length > 1) {
      onChange(value.slice(0, value.length - 1));
    }
  }, [value, onChange ]);

  const handleSelectDay = useCallback((tagIndex) => (e) => {
    e.preventDefault();
    const newValue = lodash.cloneDeep(value);
    const currentItem = lodash.cloneDeep(newValue[tagIndex]);

    currentItem.tag = e.target.value;

    newValue[tagIndex] = currentItem;
    onChange(newValue);
  }, [value, onChange]);

  const handleAddTimeSpaceClick = useCallback((tagIndex) => {
    const newValue = lodash.cloneDeep(value);
    const currentItem = lodash.cloneDeep(newValue[tagIndex]);
    const currentItemNewZeiten = lodash.cloneDeep(currentItem.zeiten);

    currentItemNewZeiten.push({
      von: '',
      bis: '',
    })

    currentItem.zeiten = currentItemNewZeiten;
    newValue[tagIndex] = currentItem;
    onChange(newValue);
  }, [value, onChange]);

  const handleRemoveTimeSpaceClick = useCallback((tagIndex) => {
    const newValue = lodash.cloneDeep(value);
    const currentItem = lodash.cloneDeep(newValue[tagIndex]);
    const currentItemNewZeiten = lodash.cloneDeep(currentItem.zeiten);

    currentItemNewZeiten.pop()

    currentItem.zeiten = currentItemNewZeiten;
    newValue[tagIndex] = currentItem;
    onChange(newValue);
  }, [value, onChange ]);

  const handleSelectTime = useCallback((tagIndex, zeitenIndex, fieldName) => (time: Date | null) => {
    const newValue = lodash.cloneDeep(value);
    const currentItem = lodash.cloneDeep(newValue[tagIndex]);
    const currentItemNewZeiten = lodash.cloneDeep(currentItem.zeiten);
    const currentItemNewZeitenItem = lodash.cloneDeep(currentItemNewZeiten[zeitenIndex]);

    currentItemNewZeitenItem[fieldName] = time;

    currentItemNewZeiten[zeitenIndex] = currentItemNewZeitenItem;
    currentItem.zeiten = currentItemNewZeiten;
    newValue[tagIndex] = currentItem;
    onChange(newValue);
  }, [value, onChange]);

  return (
    <StyledZeiten>
      <div className="zeitenContainer">
        {Array.isArray(value) &&
          value.map((element, tagIndex) => (
            <div className="zeitenSingleDay" key={tagIndex}>
              <SelectField
                fullWidth value={element.tag}
                onChange={handleSelectDay(tagIndex)}
                options={getAvailableDays(element.tag)}
              />
              {Array.isArray(element.zeiten) &&
                element.zeiten.map((zeitenElement, zeitenIndex) => (
                  <div key={zeitenIndex} className="timeGrid">
                    <TimePickerField
                      label="von"
                      value={zeitenElement.von || null}
                      onChange={handleSelectTime(tagIndex, zeitenIndex, 'von')}
                    />
                    <TimePickerField
                      label="bis"
                      value={zeitenElement.bis || null}
                      onChange={handleSelectTime(tagIndex, zeitenIndex, 'bis')}
                    />
                  </div>
                ))}
              <div className="addRemoveTimeSpaceButtonsContainer">
                <IconButton
                  size="small"
                  className="addTimeSpaceButton"
                  onClick={() => handleAddTimeSpaceClick(tagIndex)}>
                  <AddIcon />
                </IconButton>
                <IconButton
                  style={{ marginLeft: 15 }}
                  size="small"
                  className="removeTimeSpaceButton"
                  onClick={() => handleRemoveTimeSpaceClick(tagIndex)}>
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          ))}
      </div>
      <div>
        <FormDivider />
      </div>
      <div className="addRemoveDayButtonsContainer">
        <Button variant="contained" color="secondary" onClick={handleAddDayClick}>
          Einen neuen Tag hinzufügen
        </Button>
        <Button variant="contained" color="secondary" onClick={handleRemoveDayClick}>
          Den letzten Tag entfernen
        </Button>
      </div>
    </StyledZeiten>
  );
};

export default Zeiten;
