import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { setHours, setMinutes, setSeconds } from 'date-fns'
import { FC, RefObject, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import TerminForm from '../TerminForm/TerminForm'
import TerminOrganisatorischForm from '../TerminOrganisatorischForm/TerminOrganisatorischForm'

const EditDateDialog: FC<any> = ({
  date, // zu bearbeitender Termin
  open,
  loading,
  closeDialog,
  terminActions,
  patientenActions,
}) => {
  const terminForm = useRef<RefObject<HTMLFormElement> | null>(null)
  const terminOrganisatorischForm = useRef<RefObject<HTMLFormElement> | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const editDate = (data) => {
    terminActions.updateDate({
      ...data,
      patient: parseInt(data.patient, 10),
      ...(data.isAllDay
        ? {
            beginn: setHours(setMinutes(setSeconds(data.beginn, 0), 0), 0),
            ende: setHours(setMinutes(setSeconds(data.ende, 59), 59), 23),
          }
        : {}),
    })
  }

  const doSubmit = async () => {
    try {
      setIsSubmitting(true)
      if (date.istOrganisatorisch) {
        await terminOrganisatorischForm?.current?.submit()
      } else {
        await terminForm?.current?.submit()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (date) {
      patientenActions.loadCustomer(date.patient, true)
    }
  }, [date, patientenActions])

  return date ? (
    <Dialog disableBackdropClick onClose={closeDialog} open={open}>
      <DialogTitle>Termin bearbeiten</DialogTitle>
      <DialogContent>
        {!date.istOrganisatorisch ? (
          <TerminForm
            ref={terminForm}
            referenceDate={date}
            initialValues={{
              ...date,
              beginn: date ? date.beginn : null,
              ende: date ? date.ende : null,
              heilmittelverordnung: date.hvoWirdNachgereicht 
                ? 'hvoWirdNachgereicht' 
                : date.heilmittelverordnung || null,
            }}
            onSubmit={editDate}
          />
        ) : (
          <TerminOrganisatorischForm
            ref={terminOrganisatorischForm}
            referenceDate={date}
            initialValues={{
              ...date,
              beginn: date ? date.beginn : null,
              ende: date ? date.ende : null,
            }}
            onSubmit={editDate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={doSubmit}
          disabled={!!loading?.patienten || isSubmitting}
        >
          Termin speichern
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

const mapStateToProps = (state) => ({
  loading: state.loading,
})

export default connect(mapStateToProps)(EditDateDialog)
