import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export function loadHeilmittelverordnung(id, force): RequestConfig {
  return {
    types: [
      types.LOAD_HEILMITTELVERORDNUNG,
      types.LOAD_HEILMITTELVERORDNUNG_SUCCESS,
      types.LOAD_HEILMITTELVERORDNUNG_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.entities.heilmittelverordnungen[id] || force,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/heilmittelverordnungen/${id}`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadHeilmittelverordnungen(): RequestConfig {
  return {
    types: [
      types.LOAD_HEILMITTELVERORDNUNGEN,
      types.LOAD_HEILMITTELVERORDNUNGEN_SUCCESS,
      types.LOAD_HEILMITTELVERORDNUNGEN_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => state.ids.heilmittelverordnungen.length <= 0,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/heilmittelverordnungen`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadHeilmittelverordnungenByArzt(arztId, limit = null): RequestConfig {
  return {
    types: [
      types.LOAD_HEILMITTELVERORDNUNGEN,
      types.LOAD_HEILMITTELVERORDNUNGEN_SUCCESS,
      types.LOAD_HEILMITTELVERORDNUNGEN_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/heilmittelverordnungen/arzt/${arztId}/${limit}`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createHeilmittelverordnung(data): RequestConfig {
  return {
    types: [
      types.CREATE_HEILMITTELVERORDNUNG,
      types.CREATE_HEILMITTELVERORDNUNG_SUCCESS,
      types.CREATE_HEILMITTELVERORDNUNG_FAILURE,
    ],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/heilmittelverordnungen`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(datesToStrings(other)),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}

export function updateHeilmittelverordnung(data): RequestConfig {
  return {
    types: [
      types.UPDATE_HEILMITTELVERORDNUNG,
      types.UPDATE_HEILMITTELVERORDNUNG_SUCCESS,
      types.UPDATE_HEILMITTELVERORDNUNG_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.heilmittelverordnungen,

    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/heilmittelverordnungen/${id}`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function submitHvo (data) {
  return {
    types: [
      types.SUBMIT_HVO,
      types.SUBMIT_HVO_SUCCESS,
      types.SUBMIT_HVO_FAILURE
    ],
    requiresAuth: true,
    shouldCallAPI: (state) => !state.updating.heilmittelverordnungen,

    callAPI: ({ apiUrl, auth0Credentials }) => {
      const { id, termine, ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/heilmittelverordnungen/${id}/submit`
      return fetchSecure(URL, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(datesToStrings(other)),
        headers: {
          'Content-Type': 'application/json'
        }
      }, auth0Credentials)
    },
    payload: { data }
  }
}

export const selectHeilmittelverordnung = makeActionCreator(types.SELECT_HEILMITTELVERORDNUNG, 'heilmittelverordnung')
