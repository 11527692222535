import { addWeeks, endOfWeek, isSameDay, isSameWeek, startOfWeek } from 'date-fns'
import { useEffect, useState } from 'react'
import { DragDropContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as datesActions from '../../actions/dates'
import { sTerminFindenDeaktiviert } from '../../components/Calendar/selectors'
import InfoseiteOneManPraxen from '../../containers/InfoseiteOneManPraxen'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import {
  getComputedHeight,
  getDatesByWeekDay,
  getSelectedWeek,
  getTherapeuten,
  getWeekDaysM,
  sAllDayGapHeightWeek,
  sEinzelnerTherapeut,
  sGridConfigFreeDates,
  sICSSources,
  sTermineGeladenWoche
} from '../Calendar/selectors'
import CalendarColumn from '../CalendarColumn/CalendarColumn'
import CalendarColumnHeader from '../CalendarColumnHeader/CalendarColumnHeader'
import CalendarColumnSpacer from '../CalendarColumnSpacer/CalendarColumnSpacer'
import DataLoading from '../DataLoading/DataLoading'
import { StyledCalendarFreeDatesView } from './StyledCalendarFreeDatesView'

const CalendarFreeDatesView = ({
                                 actions,
                                 allDayGapHeight,
                                 calendarHeight,
                                 datesByWeekDay,
                                 gridConfig,
                                 onDateClick,
                                 onFreeDateClick,
                                 selectedDay,
                                 therapeuten,
                                 weekDaysM,
                                 termineGeladen,
                                 einzelnerTherapeut,
                                 onIcsItemClick,
                                 dialogActions,
                                 terminFindenDeaktiviert,
                                 notification
                               }) => {
  useEffect(() => {
    actions.loadDates({
      startDate: startOfWeek(selectedDay, {weekStartsOn: 1}),
      endDate: endOfWeek(selectedDay, {weekStartsOn: 1}),
    })
  }, [selectedDay]); //datesByWeekDay

  const isSnackbarOpen = !!notification?.message;

  const today = new Date();
  const plus1w = selectedDay ? addWeeks(selectedDay, 1) : addWeeks(today, 1);
  const plus4w = selectedDay ? addWeeks(selectedDay, 4) : addWeeks(today, 4);
  const plus6w = selectedDay ? addWeeks(selectedDay, 6) : addWeeks(today, 6);
  if (einzelnerTherapeut && terminFindenDeaktiviert === null) {
    return <InfoseiteOneManPraxen/>;
  }

  return (
    <StyledCalendarFreeDatesView calendarHeight={calendarHeight} isSnackbarOpen={isSnackbarOpen}>
      <div className="freeDatesViewTopBar">
        <StyledLink
          onClickFunc={() => {
            actions.selectDay(today);
          }}
          active={isSameWeek(selectedDay, today, {weekStartsOn: 1})}
          fullWidth
          borderEnd
        >
          Heute
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            actions.selectDay(plus1w);
          }}
          fullWidth
          borderStart
          borderEnd
        >
          +1 Woche
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            actions.selectDay(plus4w);
          }}
          fullWidth
          borderStart
          borderEnd
        >
          +4 Wochen
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            actions.selectDay(plus6w);
          }}
          fullWidth
          borderStart
        >
          +6 Wochen
        </StyledLink>
      </div>
      <div className="mainContainer">
        <div className="freeDatesViewMain">
          <CalendarColumnSpacer flavor="timesBig" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}>
            <CalendarColumnHeader flavor="week" date={selectedDay}/>
          </CalendarColumnSpacer>
          <CalendarColumnSpacer flavor="line" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}/>
          {termineGeladen ? (
            weekDaysM.map((weekDay, iW) => [
                  therapeuten.map((person, iP) => (
                    <CalendarColumn
                      locked={false}
                      gridConfig={gridConfig}
                      columnDay={weekDay}
                      smallPadding
                      disableDragNDrop
                      onDateClick={onDateClick}
                      onFreeDateClick={onFreeDateClick}
                      person={person}
                      allDayGapHeight={allDayGapHeight}
                      allDayHideText
                      markCurrentTime={isSameDay(weekDay, new Date())}
                      dates={datesByWeekDay[iW].filter((date) => {
                        return (
                          date.therapeut === person.id ||
                          (date.teilnehmerOrganisatorischerTermin &&
                            date.teilnehmerOrganisatorischerTermin.indexOf(
                              person.id
                            ) !== -1)
                        );
                      })}
                      onIcsItemClick={onIcsItemClick}
                      dialogActions={dialogActions}
                    >
                      {iP === 0 ? <CalendarColumnHeader flavor="day_freedates" date={weekDay}/> : false}
                      <CalendarColumnHeader flavor="person_small" person={person}/>
                    </CalendarColumn>
                  )),
              iW < weekDaysM.length - 1 ? (
                <CalendarColumnSpacer flavor="times" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}/>
              ) : (
                false
              ),
            ])
          ) : (
            <DataLoading text="lade Termine..."/>
          )}
        </div>
      </div>
    </StyledCalendarFreeDatesView>
  )
}

const mapStateToProps = (state) => ({
  events: state.entities.events,
  icsSources: sICSSources(state),
  gridConfig: sGridConfigFreeDates(state),
  datesByWeekDay: getDatesByWeekDay(state),
  therapeuten: getTherapeuten(state),
  selectedDay: state.selectedDay,
  selectedWeek: getSelectedWeek(state),
  calendarHeight: getComputedHeight(state),
  weekDaysM: getWeekDaysM(state),
  termineGeladen: sTermineGeladenWoche(state),
  einzelnerTherapeut: sEinzelnerTherapeut(state),
  allDayGapHeight: sAllDayGapHeightWeek(state),
  terminFindenDeaktiviert: sTerminFindenDeaktiviert(state),
  notification: state.notification,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(datesActions, dispatch),
})

const ConnectedFreeDatesView = connect(mapStateToProps, mapDispatchToProps)(CalendarFreeDatesView)

export default DragDropContext(HTML5Backend)(ConnectedFreeDatesView)
