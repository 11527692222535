import { isElementInViewport, getDistanceToTop } from './dom'

// export const scrollToTopmostFieldWithError = (requiredFields, reduxFormFields, fieldRefs) => {
//   console.log(fieldRefs);

//   const fieldsWithErrorOutsideOfViewport = requiredFields
//     .filter((field) => reduxFormFields[field].error)
//     // .filter((field) => !isElementInViewport(ReactDOM.findDOMNode(fieldRefs[field])))
//     .filter((field) => !isElementInViewport(fieldRefs[field]))
//     .sort((a, b) => {
//       // const distanceTopA = getDistanceToTop(ReactDOM.findDOMNode(fieldRefs[a]))
//       // const distanceTopB = getDistanceToTop(ReactDOM.findDOMNode(fieldRefs[b]))
//       const distanceTopA = getDistanceToTop(fieldRefs[a]);
//       const distanceTopB = getDistanceToTop(fieldRefs[b]);
//       if (distanceTopA < distanceTopB) {
//         return -1
//       }
//       if (distanceTopB < distanceTopA) {
//         return 1
//       }
//       return 0
//     })

//   if (fieldsWithErrorOutsideOfViewport.length) {
//     const topmostField = fieldsWithErrorOutsideOfViewport[0]
//     // const elementToScroll = ReactDOM.findDOMNode(fieldRefs[topmostField])
//     const elementToScroll = fieldRefs[topmostField]
//     elementToScroll?.scrollIntoView()
//   }
// }


export const handleScrollToInvalidInput = (refMap, errors, HEADER_HEIGHT = 118) => {
  const errorsKeys = errors ? Object.keys(errors) : [];

  if(errorsKeys?.length > 0 && refMap) {
    const currentRef = refMap[errorsKeys[0]]
    if(!!currentRef) {
      window.scrollTo({
        left: 0,
        top: currentRef?.current?.offsetTop - HEADER_HEIGHT,
        behavior: 'smooth'
      })
    }
  }
}

export const getFixedCurrencyString = (value): string => {

  value = value ? value.toString() : '0,00';

  if (!value.includes(',')) {
    return (`${value},00`);
  }

  const decValue = value.split(',')[1];

  if (decValue === "") {
    return `${value}00`;
  }

  if (decValue.length === 1) {
    return `${value}0`;
  }

  return value;
}

export const getCurrencyInCents = (value) => {
  return parseInt(getFixedCurrencyString(value).replace(',', ''));
}

export const getCurrencyInEuros = (value) => {
  const sign = value < 0? "-" : "";

  value = Math.abs(value).toString();
  let decimal;

  if (value.length < 3) {
    // if 1 -> decimal 01. if 11 -> 11.
    decimal = value.length === 1 ? `0${value}`: value;
    value = 0;
  } else {
    decimal = value.slice(-2);
    value = value.slice(0, -2);
  }
  return getFixedCurrencyString(`${sign}${value},${decimal}`);
}
