export function hexStringFromArrayBuffer(arrayBuffer) {
  const bytes = new Uint8Array(arrayBuffer)
  let hexString = '\\x'

  for (const byte of bytes) {
    if (byte < 16) {
      hexString += '0'
    }
    hexString += byte.toString(16)
  }

  return hexString
}

export function arrayBufferFromBase64(base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export function dataURLfromArrayBuffer(arrayBuffer, mimeType) {
  const base64string = window.btoa(
    [].reduce.call(
      new Uint8Array(arrayBuffer),
      (p, c) => {
        return p + String.fromCharCode(c)
      },
      '',
    ),
  )

  return `data:${mimeType};base64,${base64string}`
}
