import { createSelector } from 'reselect'
import { sEventsAndAllDayTermine } from '../Calendar/selectors'
import * as R from 'ramda'
import { isWithinInterval, isValid, parseISO, startOfDay, endOfDay, toDate } from 'date-fns'

interface Props {
  person?: InstanceUser,
  columnDay: Date,
  id: number,
}

const sColumnDay = (_, props): Props => props.columnDay
const sTherapeut = (_, props): Props => props.person

// :: _ -> state -> Boolean
export const mkSelectorShadeColumn = (): Entities['events'] =>
  createSelector(sEventsAndAllDayTermine, sColumnDay, sTherapeut, (eventsAndAllDayTermine, columnDay, therapeut) =>
    eventsAndAllDayTermine.reduce((prev, event) => {
      if (prev) return prev
      if (
        event.isAllDay &&
        event.dateShadesCalendar &&
        (therapeut && therapeut.id
          ? event.teilnehmerOrganisatorischerTermin &&
            event.teilnehmerOrganisatorischerTermin.indexOf(therapeut.id) !== -1
          : true) &&
        isWithinInterval(columnDay, {
          start: event.beginn,
          end: event.ende,
        })
      )
        return true
      if (
        event.icsSource &&
        event.icsSource.termineErzeugenFarbschleier &&
        isWithinInterval(columnDay, {
          start: startOfDay(parseISO(event.startDate)),
          end: endOfDay(parseISO(event.endDate)),
        })
      )
        return true
      return false
    }, false),
  )

// :: state -> Array
export const sEventsAndAllDayTermineDay = createSelector(
  sEventsAndAllDayTermine,
  sColumnDay,
  (eventsAndAllDayTermine, day) =>
    R.filter(
      ({ startDate, endDate, beginn, ende }) =>
        isValid(day) &&
        isWithinInterval(day, {
          start: startOfDay(startDate ? parseISO(startDate) : toDate(beginn)),
          end: endOfDay(endDate ? parseISO(endDate) : toDate(ende)),
        }),
    )(eventsAndAllDayTermine),
)

// :: state -> Array
export const sEventsAndAllDayTermineDayAndTherapist = createSelector(
  sEventsAndAllDayTermineDay,
  sTherapeut,
  (eventsAndAllDayTermine, therapeut) =>
    (therapeut && therapeut.id
      ? R.filter(({ teilnehmerOrganisatorischerTermin }) =>
          Array.isArray(teilnehmerOrganisatorischerTermin)
            ? teilnehmerOrganisatorischerTermin.indexOf(therapeut.id) !== -1
            : true,
        )
      : R.identity)(eventsAndAllDayTermine),
)

// :: state -> Array
export const sEventsForColumn = createSelector(
  (state) => state.entities.events,
  sColumnDay,
  (events, day) =>
    R.compose(
      R.filter(
        ({ startDate, endDate }) =>
          isValid(day) &&
          isWithinInterval(day, {
            start: parseISO(startDate),
            end: parseISO(endDate),
          }),
      ),
      R.values,
    )(events),
)
