import * as types from '../constants/actionTypes'

export default function selectedPatient(state = 0, action = undefined) {
  switch (action.type) {
    case types.SELECT_CUSTOMER:
      if (action.patient) {
        return parseInt(action.patient, 10)
      }
      break
    case types.CREATE_DATE_SUCCESS:
      return parseInt(action.data.patient, 10) === state ? 0 : state
    default:
  }
  return state
}
