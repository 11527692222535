import styled from 'styled-components';

export const StyledTerminOrganisatorischForm = styled.form `
  ${({ theme }) => `
    .field {
      margin-top: ${theme.spacing(2)}px;
    }
    .row {
      display: grid;
      align-items: center;
      grid-column-gap: ${theme.spacing(3)}px;
      grid-template-columns: 1fr 1fr 1fr;

      &.twoItems {
        grid-template-columns: 1fr 1fr;
      }
      ${theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
      }
    }
  `}
`;
