import * as types from '../constants/actionTypes'

export default function doctorsFilter(
  state = {
    lastNameBeginsWith: '',
  },
  action = undefined,
) {
  switch (action.type) {
    case types.FILTER_DOCTORS_LASTNAME_BEGINS_WITH:
      return {
        ...state,
        lastNameBeginsWith: action.char,
      }
    default:
  }

  return state
}
