import { createSelector } from 'reselect'
import { filterUngueltigeZeiten, getOeffnungsZeiten, istAusserhalbDerZeiten } from '../../utils/zeiten'

const sTerminBeginn = (_, props) => props.termin.beginn
const sTerminEnde = (_, props) => props.termin.ende

export const istTerminAusserhalbDerOeffnungszeiten = () =>
  createSelector(sTerminBeginn, sTerminEnde, getOeffnungsZeiten, istAusserhalbDerZeiten)

const getArbeitsZeiten = () =>
  createSelector(
    (_, props) => props.termin.therapeut || props.therapeutId,
    (state) => state.entities.users,
    (userId, users) => {
      const user = users[userId]
      return user && user.arbeitszeiten ? filterUngueltigeZeiten(user.arbeitszeiten) : []
    },
  )

export const istTerminAusserhalbDerArbeitszeiten = () =>
  createSelector(sTerminBeginn, sTerminEnde, getArbeitsZeiten(), istAusserhalbDerZeiten)

export const istTerminAusserhalbDerZeiten = (
  sIstTerminAusserhalbDerOeffnungszeiten,
  sIstTerminAusserhalbDerArbeitszeiten,
) => createSelector(sIstTerminAusserhalbDerOeffnungszeiten, sIstTerminAusserhalbDerArbeitszeiten, (a, b) => a || b)
