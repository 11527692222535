export const verguetungKK = [
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 24,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: 24,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 0,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: 0,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 0,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 920,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 531,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 2195,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 2069,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 3778,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 1568,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 2645,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1483,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 775,
    datenstand: '01.07.2019',
    krankenkasse: 'Knappschaft',
    km: null,
  },

  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 2020,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1883,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 3310,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1362,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1310,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 2022,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1071,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 590,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2019',
    krankenkasse: 'BKK',
    km: 14,
  },

  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1565,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1408,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2845,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 939,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 782,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1565,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 971.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 484.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BW',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 0,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1600,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1500,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2840,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 1150,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 1150,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1600,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1100,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1565,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1408,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2845,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 939,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 782,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1565,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 971.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 484.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BW',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BW',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1580,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1425,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2830,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 750,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 450,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BY',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 0,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1588,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1429,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2858,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 952.9999999999999,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 795,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 1587,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 767,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 382,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BY',
    positionsnummer: 79907,
    verguetung: 31,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BY',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BE',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.02.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1518,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2754,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 913.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 821.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1542,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 907,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 453,
    datenstand: '01.02.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.02.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BE',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.02.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'BB',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1210,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1092,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2375,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 758,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 634,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1265,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 850,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 350,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1489,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1340,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2680,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 893,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 745,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1489,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 719,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 360,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1301,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2542,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 827,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78001,
    verguetung: 1390,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78002,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78003,
    verguetung: 2470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78004,
    verguetung: 825,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78005,
    verguetung: 720,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 78006,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79933,
    verguetung: 860,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'BB',
    positionsnummer: 79934,
    verguetung: 420,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HB',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1602,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1441,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2882,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 963.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 801,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1603,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 989,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 494.00000000000006,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HB',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HH',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1521,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1369,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2779,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 913.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 760,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1521,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 957,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 472,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HH',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1520,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1387,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2833,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 762,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 691,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1382,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 774,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 332,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 0,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1520,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1387,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2833,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 762,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 691,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1382,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 774,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 332,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: 0,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1520,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1387,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2833,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 762,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 691,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1382,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 774,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 332,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'HE',
    positionsnummer: 79907,
    verguetung: 35,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'HE',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'MV',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1210,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1092,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2375,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 758,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 634,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1265,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 850,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 350,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1388,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1251,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2500,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 875,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 725,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1450,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 625,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 313,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'MV',
    positionsnummer: 79907,
    verguetung: 33,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: 14,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1301,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2542,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 827,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78001,
    verguetung: 1390,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78002,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78003,
    verguetung: 2470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78004,
    verguetung: 825,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78005,
    verguetung: 720,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 78006,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79933,
    verguetung: 860,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'MV',
    positionsnummer: 79934,
    verguetung: 420,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NI',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1588,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1429,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2858,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 952.9999999999999,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 795,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1587,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 767,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 382,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NI',
    positionsnummer: 79907,
    verguetung: 31,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NI',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'NW',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1588,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1429,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2858,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 952.9999999999999,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 795,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1587,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 767,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 382,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'NW',
    positionsnummer: 79907,
    verguetung: 31,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'NW',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'RP',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1450,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1305,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2753,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 869.9999999999999,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 725,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1450,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 761,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 380,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'RP',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'RP',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SL',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.10.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1520,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1377,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2770,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 917,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 764,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1517,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 960,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 482,
    datenstand: '01.10.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1450,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1305,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2753,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 869.9999999999999,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 725,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1450,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 761,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 380,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SL',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.10.2015',
    krankenkasse: 'IKK-BIG',
    km: 0,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.10.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SL',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.10.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SN',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1210,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1092,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2375,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 758,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 634,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1265,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 850,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 350,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1291,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1165,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2436,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 808,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 675,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1350,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 811.9999999999999,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 347,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1301,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2542,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 827,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78001,
    verguetung: 1390,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78002,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78003,
    verguetung: 2470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78004,
    verguetung: 825,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78005,
    verguetung: 720,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 78006,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79933,
    verguetung: 860,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SN',
    positionsnummer: 79934,
    verguetung: 420,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'ST',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1228,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1107,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2239,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 769,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 641,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 774,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 332,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1110,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1000,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2469,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 700,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 580,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1215,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 788,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 357,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1301,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2542,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 827,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78001,
    verguetung: 1390,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78002,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78003,
    verguetung: 2470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78004,
    verguetung: 825,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78005,
    verguetung: 720,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 78006,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79933,
    verguetung: 860,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'ST',
    positionsnummer: 79934,
    verguetung: 420,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'SH',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.01.2016',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1739.9999999999998,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2890,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 1315,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1850,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1125,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 650,
    datenstand: '01.01.2016',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1570,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1413,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2825,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 942,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 785,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1570,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 758,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 379,
    datenstand: '01.01.2016',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.01.2016',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1485,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2815,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 849,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1592,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 955.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78001,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78002,
    verguetung: 1400,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78003,
    verguetung: 2800,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78004,
    verguetung: 930.0000000000001,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78005,
    verguetung: 790,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 78006,
    verguetung: 1540,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79933,
    verguetung: 950,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'SH',
    positionsnummer: 79934,
    verguetung: 470,
    datenstand: '01.01.2016',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1535,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1415,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2805,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 900,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 765,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1550,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 925,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 509.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: null,
  },
  {
    preisvereinbarung: 7190605,
    bundesland: 'TH',
    positionsnummer: 79907,
    verguetung: 30,
    datenstand: '01.09.2015',
    krankenkasse: 'BKK',
    km: 14,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1210,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1092,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2375,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 758,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 634,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1265,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 850,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123203,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 350,
    datenstand: '01.09.2015',
    krankenkasse: 'AOK',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1291,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1165,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2436,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 808,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 675,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1350,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 811.9999999999999,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7123904,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 347,
    datenstand: '01.09.2015',
    krankenkasse: 'IKK-BIG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1625,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1466,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2958,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 974,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 810,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1628,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 1025,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7100000,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 501.99999999999994,
    datenstand: '01.09.2015',
    krankenkasse: 'LKK-SVLFG',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1301,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2542,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 827,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1419,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 946.0000000000001,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124000,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'VdeK-PBeaKK',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78001,
    verguetung: 1390,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78002,
    verguetung: 1300,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78003,
    verguetung: 2470,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78004,
    verguetung: 825,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78005,
    verguetung: 720,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 78006,
    verguetung: 1400,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79901,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79902,
    verguetung: 0,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79933,
    verguetung: 860,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
  {
    preisvereinbarung: 7124004,
    bundesland: 'TH',
    positionsnummer: 79934,
    verguetung: 420,
    datenstand: '01.09.2015',
    krankenkasse: 'Knappschaft',
    km: null,
  },
]
