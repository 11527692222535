import styled from 'styled-components';

export const StyledMessage = styled.span`
  ${({ theme }) => `
    display: flex;
    align-items: center;

    .error {
      color: red;
    }
    .success {
      color: #00cc00;
    }
  `}
`;
