import { EuroSymbol } from '@material-ui/icons'
import { connect } from 'react-redux'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { sCurrentUser } from '../../shared/utils/users'
import { PlanTable } from '../PlanTable/PlanTable'
import { useMemo } from 'react'
import { plan } from '../../shared/utils/constants'
import { Link, Typography } from '@material-ui/core'
import useDeepCompareEffect, { useDeepCompareMemoize } from 'use-deep-compare-effect'

const test = {
  articleNo: '7890101',
  id: '018fa55aa18775e694cb34d0ca585e91',
  kkInterfaceIncluded: false,
  maxNumberOfPatients: 20,
  maxNumberOfPatientsPrefix: 'bis',
  maxNumberOfPractices: 1,
  name: 'free',
  smsPrice: null,
  supportReachability: 'Chat, Ticket, E-Mail',
  supportTime: 0,
}

const PlanSettings = ({ auth, currentUser }): JSX.Element => {
  const planData = useMemo(() => {
    if (currentUser?.planId) {
      return plan.find((p) => p.id === currentUser.planId)
    }

    return null
  }, [currentUser?.planId])

  // Filter auth.apiServers by createdBy
  const apiServers = useMemo(() => {
    if (!auth?.apiServers?.length || !currentUser?.authUserId) {
      return []
    }

    return auth.apiServers.filter((server) => server.createdBy === currentUser.authUserId)
  }, [auth?.apiServers, currentUser?.authUserId])

  return (
    <>
      <IconHeading icon={<EuroSymbol />} text="Ihr aktuelles Planmodell" />
      <div style={{ padding: 8 }}>
        {!planData && (
          <Typography variant="h6">
            Sie haben noch kein Planmodell.{' '}
            <Link
              href={
                process.env.NODE_ENV === 'production'
                  ? 'https://pododesk.de/fuexc_unidy/start'
                  : 'https://staging.pododesk.de/fuexc_unidy/start'
              }
              color="secondary"
            >
              Klicken Sie hier
            </Link>
            , um ein Planmodell auszuwählen.
          </Typography>
        )}
        {!!planData && (
          <>
            <PlanTable plan={planData} apiServers={apiServers} />
            <div style={{ marginTop: 16 }}>
              <Typography variant="h6">
                Sie möchten Ihr Planmodell upgraden oder kündigen?{' '}
                <Link
                  href={
                    process.env.NODE_ENV === 'production'
                      ? 'https://pododesk.de/fuexc_unidy/start'
                      : 'https://staging.pododesk.de/fuexc_unidy/start'
                  }
                  color="secondary"
                >
                  Hier klicken!
                </Link>{' '}
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUser: sCurrentUser(state),
  auth: state.auth,
})

export default connect(mapStateToProps)(PlanSettings)
