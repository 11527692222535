export function readFile(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const { target } = ev;
      resolve(target?.result);
    };
    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      console.error(reader.error);
      reader.abort();
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
}
    