import { Button, Paper, Slider, Typography } from '@material-ui/core'
import { Lock as LockIcon } from '@material-ui/icons'
import { memo } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as praxisstammdatenActions from '../../actions/praxisstammdaten'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { fields } from '../PraxisstammdatenForm/PraxisstammdatenForm'
import { StyledSicherheitForm } from './StyledSicherheitForm'

const initialAutoLockTime = "1";

const SicherheitForm = ({ actions, praxisstammdaten, fields, handleSubmit }) => {
  const onSubmit = async (data) => {
    const newData = { ...praxisstammdaten, autoLockTimeout: data.autoLockTimeout, autoLockActive: data.autoLockActive }
    console.log('submitting', newData);
    await actions.praxisstammdaten.updatePraxisstammdaten(newData);
    praxisstammdaten.loadPraxisstammdaten()
  }
  const { autoLockActive, autoLockTimeout } = fields

  return (
    <>
      <IconHeading icon={<LockIcon />} text="Sicherheitseinstellungen für diese Praxis" />
      <StyledSicherheitForm>
        <Paper elevation={4} square className="paper">
          <Typography variant="h6">
            Diese Funktion ist vorübergehend nicht verfügbar
          </Typography>
          {/* <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form">
            <CheckboxField
              label="Automatisches Sperren aktivieren"
              {...autoLockActive} />
            <label>Sperren nach: {!autoLockActive.value ? 'nie' : `${autoLockTimeout.value} Min.`}</label>
            <Slider
              {...autoLockTimeout}
              min={1}
              max={90}
              step={1}
              disabled={!autoLockActive.value}
              onChange={(_, value) => {
                autoLockTimeout.onChange(value)
              }}
              color="secondary"
            />
            <Button variant="contained" color="secondary" type="submit" fullWidth>
              Einstellungen speichern
            </Button>
          </form> */}
        </Paper>
      </StyledSicherheitForm>
    </>
  );
};

const mapStateToProps = (state) => {
  const praxisstammdaten = praxisstammdatenSelector(state)
  return {
    praxisstammdaten: praxisstammdaten,
    initialValues: {
      autoLockActive: praxisstammdaten.autoLockActive,
      autoLockTimeout: praxisstammdaten.autoLockActive ? praxisstammdaten.autoLockTimeout : initialAutoLockTime,
    },
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    praxisstammdaten: bindActionCreators(praxisstammdatenActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'sicherheitForm',
    fields,
    destroyOnUnmount: true,
    enableReinitialize: true
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(SicherheitForm))
