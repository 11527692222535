import { getApiUrl } from '../utils/auth'
import { fetchSecure, sCredentials } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { ISO8601DATE_FNS } from '../constants/dateFormats'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { datesToStrings } from '../shared/utils/dates'
import { trackError } from '../shared/utils/trackjs'
import { openEditTerminDialog } from './dialogs'
import { apiServerHashSelector } from '../selectors/selectors'
import { format } from 'date-fns'
import { RequestConfig } from './interface'

export function loadDate(dateId): RequestConfig {
  return {
    types: [types.LOAD_DATE, types.LOAD_DATE_SUCCESS, types.LOAD_DATE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      return fetchSecure(
        `${apiUrl}/termine/${dateId}`,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadAllDates(): RequestConfig {
  return {
    types: [types.LOAD_DATES, types.LOAD_DATES_SUCCESS, types.LOAD_DATES_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/termine`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadDates({ startDate, endDate }): RequestConfig {
  return {
    types: [types.LOAD_DATES, types.LOAD_DATES_SUCCESS, types.LOAD_DATES_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean =>
      !state.termineVonBisGeladen[`${format(startDate, ISO8601DATE_FNS)}${format(endDate, ISO8601DATE_FNS)}`],
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/termine/${format(startDate, ISO8601DATE_FNS)}/${format(endDate, ISO8601DATE_FNS)}`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { startDate, endDate },
  }
}

export const selectDay = makeActionCreator(types.SELECT_DAY, 'date')

export function locationChange(data): any {
  const action = {
    type: '@@router/LOCATION_CHANGE',
    payload: { pathname: data },
  }

  return action
}

export function createDate(data): RequestConfig {
  return {
    types: [types.CREATE_DATE, types.CREATE_DATE_SUCCESS, types.CREATE_DATE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/termine`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(data)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

/*
notifications struktur: { request: 'bla', success: 'bladfgf', error: 'hahahfgf' }
 */

export function updateDate(data, notification): RequestConfig {
  return {
    types: [types.UPDATE_DATE, types.UPDATE_DATE_SUCCESS, types.UPDATE_DATE_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/termine/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: {
      ...data,
      notification,
      entity: data,
      entityType: 'termine',
    },
  }
}

export function cancelDateDeceased(data, notification): RequestConfig {
  return {
    types: [types.CANCEL_DATE_DECEASED, types.CANCEL_DATE_DECEASED_SUCCESS, types.CANCEL_DATE_DECEASED_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/termine/${id}/deceased`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: {
      ...data,
      notification,
      entity: data,
      entityType: 'termine',
    },
  }
}

export function undoCancelDate(data): RequestConfig {
  const updatedDate = {
    ...data,
    absagegrund: null,
    absagedatum: null,
  }
  return {
    types: [types.UNDO_CANCEL_DATE, types.UNDO_CANCEL_DATE_SUCCESS, types.UNDO_CANCEL_DATE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials, getState }): void => {
      const { modifiedAt } = getState().entities.termine[data.id]

      updatedDate.modifiedAt = modifiedAt

      const { id, ...other } = updatedDate // eslint-disable-line no-unused-vars
      const URL = `${apiUrl}/termine/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { ...updatedDate },
  }
}

export function asyncValidateDate(termin): (_: any, getState: any) => Promise<void> {
  return async (_, getState): Promise<void> => {
    const { id, ...other } = termin // eslint-disable-line no-unused-vars
    const state = getState()
    const apiUrl = getApiUrl(apiServerHashSelector(state))
    const credentials = sCredentials(state)

    let errors: KeyValue<string> | null = null
    const URL = `${apiUrl}/termine/validate${id ? '/' + id : ''}`

    try {
      const response = await fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        credentials,
      )

      if (!response.ok) throw new Error(response.statusText)

      const json = await response.json()
      if (!json.valid) {
        errors = {}
        if (json.overlaps) {
          if (json.overlaps === 'room') {
            errors._error = 'Überschneidung mit bestehendem Termin in diesem Raum.'
          } else if (json.overlaps === 'user') {
            errors._error = 'Überschneidung mit bestehendem Termin für diesen Therapeuten.'
          } else {
            errors._error = 'Überschneidung mit bestehendem Termin.'
          }
        } else {
          // Dieser Fall sollte eigentlich nicht eintreten
          errors._error = 'Der Termin ist nicht valide.'
        }
      }
    } catch (networkError) {
      console.log('error while validating appointment: ', networkError)
      errors = {}
      errors._error = 'Es gab ein Problem während der Kommunikation mit dem Server. Bitte versuchen Sie es noch einmal.'
      trackError(networkError, {
        errors,
        termin,
      })
    }
    if (errors) {
      throw errors
    }
  }
}

export const selectTerminProps = makeActionCreator(types.SELECT_TERMIN_PROPS, 'terminProps')

export function loadTerminAndOpenEditTerminDialog(terminId): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch, getState): Promise<void> => {
    await dispatch(loadDate(terminId))
    const state = getState()
    dispatch(openEditTerminDialog(state.entities.termine[terminId]))
  }
}
