import MenuItemsPatient from './MenuItemsPatient'
import { StyledMenuPatient } from './StyledMenuPatient'

const ContextMenuPatient = ({ 
  pointerLocation: { mouseX, mouseY }, 
  handleClose, 
  patientId 
}) => {

  return (
    <StyledMenuPatient
      keepMounted
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <MenuItemsPatient 
        patientId={patientId} 
        onCloseFunc={handleClose} />
    </StyledMenuPatient>
  )
}

export default ContextMenuPatient
