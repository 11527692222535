import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { default as de } from 'date-fns/locale/de'
import { FC } from 'react'
import 'react-image-lightbox/style.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../assets/theme'
import * as types from '../../constants/actionTypes'
import { configureStore } from '../../redux/create'
import { startTicks } from '../../redux/modules/time'
import { startUpdateUserActivity } from '../../redux/modules/userActivity'
import { AppStart } from '../AppStart/AppStart'
import { AuthProvider } from '../AuthProvider/AuthProvider'

export const store = configureStore()

if (__DEV__) window['store'] = store

store.dispatch({ type: types.APP_INITIALIZE })
store.dispatch({ type: types.MIDDLEWARES_INITIALIZE })
store.dispatch(startTicks())
store.dispatch(startUpdateUserActivity())

export const Root: FC<{}> = (): JSX.Element => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <AuthProvider>
              <Router>
                <AppStart />
              </Router>
            </AuthProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  )
}
