import * as R from 'ramda'
import { createSelector } from 'reselect'
import { filterUngueltigeZeiten, getOeffnungsZeiten, istAusserhalbDerZeiten } from '../../utils/zeiten'
import { checkHVOConditions, checkTerminConditions } from '../../utils/validation'
import { sAllDayTermineOhneAbgesagte } from '../Calendar/selectors'
import { isDate, isWithinInterval } from 'date-fns'

const getArbeitsZeiten = createSelector(
  (state: StoreState) => state.form.terminForm.therapeut && state.form.terminForm.therapeut.value,
  (state) => state.entities.users,
  (userId, users) => {
    const user = users[userId]
    return user && user.arbeitszeiten ? filterUngueltigeZeiten(user.arbeitszeiten) : []
  },
)

export const istTerminAusserhalbDerOeffnungszeiten = createSelector(
  (state: StoreState) => state.form.terminForm.beginn && state.form.terminForm.beginn.value,
  (state: StoreState) => state.form.terminForm.ende && state.form.terminForm.ende.value,
  getOeffnungsZeiten,
  istAusserhalbDerZeiten,
)

export const istTerminAusserhalbDerArbeitszeiten = createSelector(
  (state: StoreState) => state.form.terminForm.beginn && state.form.terminForm.beginn.value,
  (state: StoreState) => state.form.terminForm.ende && state.form.terminForm.ende.value,
  getArbeitsZeiten,
  istAusserhalbDerZeiten,
)

const sHeilmittelverordnungen = (state: StoreState) => state.entities.heilmittelverordnungen
const sTermine = (state: StoreState) => state.entities.termine

const sTerminForm = (state: StoreState) => state.form.terminForm

const sTerminHVOId = createSelector(sTerminForm, (form) =>
  form.heilmittelverordnung ? form.heilmittelverordnung.value : undefined,
)
const sTerminBeginn = createSelector(sTerminForm, (form) => (form.beginn ? form.beginn.value : undefined))
const sTerminId = createSelector(sTerminForm, (form) => (form.id ? form.id.value : undefined))
const sTerminLeistungen = createSelector(sTerminForm, (form) => form.leistungen ? form.leistungen.value : undefined)

export const sHVOConditions = createSelector(
  sHeilmittelverordnungen,
  sTermine,
  sTerminHVOId,
  sTerminBeginn,
  sTerminId,
  sTerminLeistungen,
  (hvos, termine, terminHVOId, terminBeginn, terminId, terminLeistungen) =>
    checkHVOConditions({ hvos, termine, terminHVOId, terminBeginn, terminId, terminLeistungen }),
)

const sGridConfig = (state) => state.config.calendarGrid.default
const sTerminEnde = createSelector(sTerminForm, (form) => (form.ende ? form.ende.value : undefined))

export const sTerminConditions = createSelector(sTerminBeginn, sTerminEnde, sGridConfig, (beginn, ende, gridConfig) =>
  checkTerminConditions(beginn, ende, gridConfig),
)

const terminFromForm = createSelector(
  (state: StoreState) => state.form.terminForm,
  R.compose(
    R.map((obj) => obj.value),
    R.filter(R.compose(R.equals('Object'), R.type)),
    R.defaultTo({}),
  ),
)

export const getIntersectedAllDayAppointments = (termin, termine) => {
  const mBeginn = termin.beginn
  const therapeut = termin.therapeut

  if (!isDate(mBeginn)) {
    return []
  }

  return R.compose(
    R.filter(
      R.allPass([
        R.propEq('isAllDay', true),
        R.compose(R.contains(therapeut), R.prop('teilnehmerOrganisatorischerTermin')),
        ({ beginn, ende }) => isWithinInterval(mBeginn, { start: beginn, end: ende }),
      ]),
    ),
    R.values,
  )(termine)
}

export const sIntersectsAllDayAppointment = createSelector(
  terminFromForm,
  sAllDayTermineOhneAbgesagte,
  R.compose(R.gt(R.__, 0), R.length, getIntersectedAllDayAppointments),
)
