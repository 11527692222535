import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export function loadAnamnesen(): RequestConfig {
  return {
    types: [types.LOAD_ANAMNESIS, types.LOAD_ANAMNESIS_SUCCESS, types.LOAD_ANAMNESIS_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.anamnesiss.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/anamnesen`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createAnamnese(data): RequestConfig {
  return {
    types: [types.CREATE_ANAMNESIS, types.CREATE_ANAMNESIS_SUCCESS, types.CREATE_ANAMNESIS_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.anamnesen,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/anamnesen`
      const { ...other } = data

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function updateAnamnese(data): RequestConfig {
  return {
    types: [types.UPDATE_ANAMNESIS, types.UPDATE_ANAMNESIS_SUCCESS, types.UPDATE_ANAMNESIS_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.anamnesen,

    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      const URL = `${apiUrl}/anamnesen/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function finishAnamnese(data): RequestConfig {
  return {
    types: [types.FINISH_ANAMNESIS, types.FINISH_ANAMNESIS_SUCCESS, types.FINISH_ANAMNESIS_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,

    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...anamnesis } = data
      const URL = `${apiUrl}/anamnesen/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings({ ...anamnesis, abgeschlossen: true })),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}
