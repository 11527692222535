/* eslint-disable @typescript-eslint/no-unused-vars */
import { CalendarToday as CalendarTodayIcon, MeetingRoom as MeetingRoomIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as datesActions from '../../actions/dates'
import AddDateDialog from '../../components/AddDateDialog/AddDateDialog'
import { sTreatmentRoomFeatureEnabled } from '../../components/Calendar/selectors'
import CalendarDayView from '../../components/CalendarDayView/CalendarDayView'
import CalendarFreeDatesView from '../../components/CalendarFreeDatesView/CalendarFreeDatesView'
import CalendarRoomsView from '../../components/CalendarRoomsView/CalendarRoomsView'
import CalendarSidebar from '../../components/CalendarSidebar/CalendarSidebar'
import { CalendarWeekViewContainer } from '../../components/CalendarWeekView/CalendarWeekViewContainer'
import { ContextMenuIcsItem } from '../../components/MenuIcsItem'
import { ContextMenuTermin } from '../../components/MenuTermin'
import MoveDateDialog from '../../components/MoveDateDialog/MoveDateDialog'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { sApiServer } from '../../shared/utils/auth'
import { isCurrentUserAdmin, sCurrentUserApi } from '../../shared/utils/users'
import { StyledCalendar } from './StyledCalendar'

const defaultMoveDateData: MoveDate = {
  date: null,
  target: null,
  shorterDuration: false,
}

const Calendar = ({
  dialogActions,
  actions,
  people,
  personIds,
  selectedTherapeut,
  currentServer,
  selectedDay,
  currentUser,
  currentUserIsAdmin,
  treatmentRoomFeatureEnabled,
}) => {
  const { serverHash } = useParams()

  const [contextMenuTerminPointerLocation, setContextMenuTerminPointerLocation] = useState<{
    mouseX: number | null
    mouseY: number | null
  }>({ mouseX: null, mouseY: null })
  const [selectedTermin, setSelectedTermin] = useState<number | null>(null)

  const [contextMenuIcsItemPointerLocation, setContextMenuIcsItemPointerLocation] = useState<{
    mouseX: number | null
    mouseY: number | null
  }>({ mouseX: null, mouseY: null })
  const [selectedIcsItem, setSelectedIcsItem] = useState<number | null>(null)

  const [addDateDialogOpen, setAddDateDialogOpen] = useState<boolean>(false)

  const openAddDateDialog = useCallback((_, termin) => {
    setSelectedTermin(termin)
    setAddDateDialogOpen(true)
  }, [])

  const closeAddDateDialog = () => {
    setSelectedTermin(null)
    setAddDateDialogOpen(false)
  }

  const handleDateClick = useCallback((e, date) => {
    setContextMenuTerminPointerLocation({
      mouseX: e.clientX,
      mouseY: e.clientY,
    })
    setSelectedTermin(date.id)
  }, [])

  const handleContextMenuTerminClose = useCallback(() => {
    setContextMenuTerminPointerLocation({
      mouseX: null,
      mouseY: null,
    })
    setSelectedTermin(null)
  }, [])

  const openContextMenuIcsItem = useCallback((e, item) => {
    setContextMenuIcsItemPointerLocation({
      mouseX: e.clientX,
      mouseY: e.clientY,
    })
    setSelectedIcsItem(item)
  }, [])

  const closeContextMenuIcsItem = useCallback(() => {
    setContextMenuIcsItemPointerLocation({ mouseX: null, mouseY: null })
    setSelectedIcsItem(null)
  }, [])

  const [selectedMoveDateData, setSelectedMoveDateData] = useState<MoveDate>(defaultMoveDateData)
  const [moveDateDialogOpen, setMoveDateDialogOpen] = useState<boolean>(false)

  const openMoveDateDialog = useCallback((data: MoveDate) => {
    setSelectedMoveDateData(data)
    setMoveDateDialogOpen(true)
  }, [])

  const closeMoveDateDialog = useCallback(() => {
    setSelectedMoveDateData(defaultMoveDateData)
    setMoveDateDialogOpen(false)
  }, [])

  return (
    <StyledCalendar>
      <div className="main">
        <div className="topBar">
          <StyledLink
            to={`${serverHash}/calendar/day/${format(selectedDay, ISO8601DATE_FNS)}`}
            fullWidth
            startIcon={<CalendarTodayIcon fontSize="inherit" />}
            borderEnd
          >
            Tag
          </StyledLink>
          <StyledLink
            to={`${serverHash}/calendar/week/${
              selectedTherapeut ||
              (currentUser.istTherapeut ? currentUser.id : personIds.find((id) => people[id].istTherapeut))
            }`}
            fullWidth
            startIcon={<CalendarTodayIcon fontSize="inherit" />}
            borderEnd
            className="long-link button"
          >
            Therapeut / Arbeitswoche
          </StyledLink>
          <StyledLink
            to={`${serverHash}/calendar/free`}
            fullWidth
            startIcon={<CalendarTodayIcon fontSize="inherit" />}
            borderEnd={treatmentRoomFeatureEnabled}
          >
            Termin finden
          </StyledLink>
          {treatmentRoomFeatureEnabled && (
            <StyledLink
              to={`${serverHash}/calendar/rooms/${format(selectedDay, ISO8601DATE_FNS)}`}
              fullWidth
              startIcon={<MeetingRoomIcon fontSize="inherit" />}
            >
              Behandlungsräume
            </StyledLink>
          )}
        </div>
        <div className="inner">
          <Routes>
            <Route
              path="day/:date"
              element={
                <CalendarDayView
                  onDateClick={handleDateClick}
                  onFreeDateClick={openAddDateDialog}
                  onIcsItemClick={openContextMenuIcsItem}
                  dialogActions={{
                    ...dialogActions,
                    openMoveDateDialog,
                  }}
                />
              }
            />
            <Route
              path="free"
              element={
                <CalendarFreeDatesView
                  onDateClick={handleDateClick}
                  onFreeDateClick={openAddDateDialog}
                  onIcsItemClick={openContextMenuIcsItem}
                  dialogActions={{
                    ...dialogActions,
                    openMoveDateDialog,
                  }}
                />
              }
            />
            <Route
              path="week/:person"
              element={
                <CalendarWeekViewContainer
                  onDateClick={handleDateClick}
                  onFreeDateClick={openAddDateDialog}
                  onIcsItemClick={openContextMenuIcsItem}
                  dialogActions={{
                    ...dialogActions,
                    openMoveDateDialog,
                  }}
                />
              }
            />
            <Route
              path="rooms/:date"
              element={
                <CalendarRoomsView
                  onDateClick={handleDateClick}
                  onFreeDateClick={openAddDateDialog}
                  onIcsItemClick={openContextMenuIcsItem}
                  dialogActions={{
                    ...dialogActions,
                    openMoveDateDialog,
                  }}
                />
              }
            />
            <Route path="/rooms" element={<Navigate replace to={`../rooms/${format(selectedDay, ISO8601DATE_FNS)}`} />} />
            <Route path="*" element={<Navigate replace to={`./day/${format(selectedDay, ISO8601DATE_FNS)}`} />} />
          </Routes>
        </div>
      </div>
      <CalendarSidebar className="sideBar" currentUserIsAdmin={currentUserIsAdmin} currentServer={currentServer} />

      <AddDateDialog date={selectedTermin} closeDialog={closeAddDateDialog} open={addDateDialogOpen} />

      <MoveDateDialog
        {...selectedMoveDateData}
        open={moveDateDialogOpen}
        people={people}
        updateDate={actions.dates.updateDate}
        closeDialog={closeMoveDateDialog}
      />

      <ContextMenuIcsItem
        pointerLocation={contextMenuIcsItemPointerLocation}
        handleClose={closeContextMenuIcsItem}
        icsItem={selectedIcsItem}
      />

      <ContextMenuTermin
        dialogActions={dialogActions}
        hvoView={false}
        selectedTermin={selectedTermin}
        onCloseFunc={handleContextMenuTerminClose}
        pointerLocation={contextMenuTerminPointerLocation}
        calendarView
      />
    </StyledCalendar>
  )
}

const mapStateToProps = (state) => ({
  people: state.entities.users,
  personIds: state.ids.users,
  selectedDay: state.selectedDay,
  selectedTherapeut: state.selectedTherapeut,
  currentUser: sCurrentUserApi(state),
  currentUserIsAdmin: isCurrentUserAdmin(state),
  currentServer: sApiServer(state),
  treatmentRoomFeatureEnabled: sTreatmentRoomFeatureEnabled(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dates: bindActionCreators(datesActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Calendar))
