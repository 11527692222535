import { Eco as EcoIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { loadCustomer } from '../../actions/customers';
import * as hvoActions from '../../actions/heilmittelverordnungen';
import HVOForm from '../../components/HVOForm/HVOForm';
import NewHVOForm from '../../components/HVOForm/NewHVOForm';
import PatientText from '../../components/PatientText/PatientText';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledHVOControl } from './StyledHVOControl';

const HVOControl = ({ actions, params, heilmittelverordnungen, patienten }) => {
  const { serverHash } = useParams()

  useEffect(() => {
    if (params.patient) {
      actions.loadCustomer(params.patient, true);
    }
    if (params.heilmittelverordnung) {
      actions.heilmittelverordnungen.loadHeilmittelverordnung(params.heilmittelverordnung);
    }
  }, [params.patient, params.heilmittelverordnung]);

  let patient;
  const hvo = heilmittelverordnungen[params.heilmittelverordnung];
  if (hvo) {
    if (!patienten[hvo.patient]) return null;
    patient = patienten[hvo.patient];
  } else if (params.patient && patienten && patienten[params.patient]) {
    patient = patienten[params.patient];
  } else {
    return null;
  }

  return (
    <StyledHVOControl>
      <IconHeading
        icon={<EcoIcon />}
        text={
          <span>
            Heilmittelverordnung neu anlegen | <PatientText patient={patient} showGender /> (
            <Link
              to={`/${serverHash}/contacts/customers/${patient && patient.id}`}
              className="editLink"
            >
              zur Patientenkartei
            </Link>
            )
          </span>
        }
      />
      <NewHVOForm
        customer={patient}
        onSubmitFunc={
          hvo
            ? actions.heilmittelverordnungen.updateHeilmittelverordnung
            : actions.heilmittelverordnungen.createHeilmittelverordnung
        }
        initialValues={hvo}
        hvoId={hvo?.id}
        submitText={hvo && 'Heilmittelverordnung aktualisieren'}
        editMode={!!hvo}
      />
    </StyledHVOControl>
  );
};

const mapStateToProps = (state) => ({
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  patienten: state.entities.patienten,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCustomer: bindActionCreators(loadCustomer, dispatch),
    heilmittelverordnungen: bindActionCreators(hvoActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HVOControl);
