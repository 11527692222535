import { InputProps, TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions, type CloseReason, type AutocompleteCloseReason } from '@material-ui/lab'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

interface AutocompleteOption {
  label: string
  value: any
  undeleteable?: boolean
  unselectable?: boolean
  filterText?: string
}

interface Props {
  control: any
  name: string
  options: Array<AutocompleteOption>
  className?: string
  label: string
  helperText?: string
  error?: boolean
  rules?: RegisterOptions
  InputProps?: InputProps
  freeSolo?: boolean
  autoFocus?: boolean
  placeholder?: string
  disabled?: boolean
  blurOnSelect?: boolean
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  inputClassName?: string
  shouldUnregister?: boolean
  readOnly?: boolean
  allowedValues?: any[]
  defaultValue?: any
  autoSelect?: boolean
  onChange?: (e: React.ChangeEvent<{}>, value: string | AutocompleteOption | null, reason) => unknown
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: AutocompleteOption) => `${option.filterText || option.label}`,
})

const getOptionLabel = (options, option) => {
  if (option?.label) return option?.label

  if (option === '') return option

  const opt = options.find((o) => isEqual(o.value, option))
  return opt?.label ?? ''
}

const ControlledAutocompleteField = ({
  control,
  name,
  options = [],
  className,
  label,
  helperText,
  error,
  rules = {},
  InputProps = {},
  freeSolo = false,
  autoFocus = false,
  placeholder,
  disabled = false,
  blurOnSelect = false,
  fullWidth,
  variant = 'standard',
  inputClassName,
  shouldUnregister = false,
  readOnly = false,
  allowedValues = [],
  defaultValue,
  autoSelect,
  onChange,
}: Props): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption | null>(null)
  const [currentOptions, setCurrentOptions] = useState<Array<AutocompleteOption>>([])
  const { field } = useController({ name, rules, control, shouldUnregister, defaultValue })

  useEffect(() => {
    setCurrentOptions(options ?? [])
  }, [options])

  useDeepCompareEffect(() => {
    let newSelectedOption: AutocompleteOption | null = null

    if (field?.value) {
      const option = currentOptions.find((opt) => isEqual(opt?.value, field?.value)) || null

      if (!!option) {
        newSelectedOption = option
      } else {
        if (freeSolo && field?.value !== null && typeof field?.value === 'string') {
          const newOption: AutocompleteOption = { label: field?.value, value: field?.value }
          const newOptions = [...currentOptions, newOption]
          setCurrentOptions(newOptions)
          return
        }
      }
    }
    setSelectedOption(newSelectedOption)
  }, [freeSolo, field.value, currentOptions])

  return (
    <Autocomplete
      {...field}
      className={className}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      filterOptions={filterOptions}
      options={currentOptions}
      getOptionLabel={(option) => getOptionLabel(currentOptions, option)}
      getOptionDisabled={(option) => {
        return !!option.unselectable || (allowedValues.length > 0 && !allowedValues.includes(option.value))
      }}
      value={selectedOption}
      getOptionSelected={(option, value) => isEqual(option.value, value?.value)}
      disabled={disabled}
      style={readOnly ? { pointerEvents: 'none' } : {}}
      placeholder={placeholder}
      blurOnSelect={blurOnSelect}
      onChange={(e, value, reason) => {
        const val = (value as AutocompleteOption)?.value ?? value ?? null
        field?.onChange(val)

        if (onChange) onChange(e, value, reason)
      }}
      autoSelect={autoSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label={rules?.required ? `${label} *` : label}
          autoFocus={autoFocus}
          className={inputClassName}
          variant={variant}
          InputProps={{ ...params.InputProps, ...InputProps }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  )
}

export default ControlledAutocompleteField
