import { LinearProgress, TextField } from '@material-ui/core';
import { forwardRef, useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { StyledPasswordInput } from './StyledPasswordInput';

interface Props {
  min?: number
  max?: number
  required?: boolean
  minScore?: number
  fullWidth?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  error?: boolean
  helperText?: string
  type?: string
  placeholder?: string
  onlyAllowDigits?: boolean
  maxLength?: number

  onChange?: (data?: string) => void
  autoFocus?: boolean
  className?: string
  style?: any
}

const scoreMappings = {
  0: 'schwach',
  1: 'okay',
  2: 'gut',
  3: 'sehr gut',
  4: 'super!',
}

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  ({
    min,
    max,
    required,
    minScore = 0,
    fullWidth = true,
    variant,
    type = 'password',
    placeholder,
    onlyAllowDigits,
    maxLength,

    onChange,
    autoFocus = false,
    error,
    helperText,
    className = '',
    style = {}
  }, ref) => {
  const [stateValue, setStateValue] = useState('')
  const [stateError, setStateError] = useState<string>('')
  const [passwordScore, setPasswordScore] = useState<number | null>(null)

  useEffect(() => {
    onChange && onChange(stateValue)
  }, [stateValue])

  const getPasswordScore = (value) => {
    const { score } = zxcvbn(value)
    setPasswordScore(score)
    return score
  }

  const checkOnlyDigits = (value: string): string => {
    let stringValue = String(value).trim()
    const number = Number(stringValue)
    if (isNaN(number)) {
      stringValue = ''
    }
    return stringValue
  }
  const checkMaxLength = (value: string): string => {
    let stringValue = String(value)
    if(maxLength && stringValue.length > maxLength) {
      return stringValue.substring(0, maxLength)
    }
    return stringValue
  }

  const handleBlur = () => {
    if(maxLength && maxLength > 0) {
      const value = checkMaxLength(stateValue)
      setStateValue(value)
    }
    if (onlyAllowDigits) {
      const value = checkOnlyDigits(stateValue)
      setStateValue(value)
    }

      validate()
  }

  const handleChange = (e) => {
    let value = e.target.value;

    if(maxLength && maxLength > 0) {
      value = checkMaxLength(value)
    }
    if (onlyAllowDigits) {
      value = checkOnlyDigits(value)
    }
    validate()

    setStateValue(value)
  }

  const validate = () => {
    if (required && !stateValue) {
      // console.log('required?')
      setStateError('Bitte ausfüllen!')
      return
    }
    if (stateValue && max && stateValue.length > max) {
      // console.log('too long?')
      setStateError(`Max. ${max} Zeichen erlaubt!`)
      return
    }
    if (stateValue && min && stateValue.length < min) {
      // console.log('too short?')
      setStateError(`Min. ${min} Zeichen erforderlich!`)
      return
    }

    if(minScore) {
      const score = getPasswordScore(stateValue)

      if (score < minScore) {
        // console.log('too weak!')
        setStateError('Passwort zu schwach!')
        return
      }
    }
    setStateError('')
  }

  return (
    <StyledPasswordInput className={className} style={style}>
      <TextField
        inputRef={ref}
        fullWidth={fullWidth}
        variant={variant}
        error={!!stateError || error}
        helperText={(!!stateError && stateError) || helperText}
        value={stateValue}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {!!minScore && passwordScore != null && passwordScore >= 0 && (
        <>
          <LinearProgress variant="determinate" value={passwordScore * 25} className="linearProgress" />
          <div>{scoreMappings[passwordScore]}</div>
        </>
      )}
    </StyledPasswordInput>
  )
})

export default PasswordInput
