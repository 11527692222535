export const leistungenKK = {
  78001: {
    positionsnummer: 78001,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'DFa',
    mehrwertsteuer: 0,
    kuerzel: 'DFa',
  },
  78002: {
    positionsnummer: 78002,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'NFa',
    mehrwertsteuer: 0,
    kuerzel: 'NFa',
  },
  78003: {
    positionsnummer: 78003,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'QFa',
    mehrwertsteuer: 0,
    kuerzel: 'QFa',
  },
  78004: {
    positionsnummer: 78004,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'DFb',
    mehrwertsteuer: 0,
    kuerzel: 'DFb',
  },
  78005: {
    positionsnummer: 78005,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'NFb',
    mehrwertsteuer: 0,
    kuerzel: 'NFb',
  },
  78006: {
    positionsnummer: 78006,
    beschreibung: 'Podologische Behandlung (klein)',
    regelbehandlungszeitMin: 10,
    regelbehandlungszeitMax: 20,
    istHeilmittel: true,
    indikationsschluessel: 'QFb',
    mehrwertsteuer: 0,
    kuerzel: 'QFb',
  },
  78007: {
    positionsnummer: 78007,
    beschreibung: 'Podologische Behandlung (groß)',
    regelbehandlungszeitMin: 20,
    regelbehandlungszeitMax: 50,
    istHeilmittel: true,
    indikationsschluessel: 'DFc',
    mehrwertsteuer: 0,
    kuerzel: 'DFc',
  },
  78008: {
    positionsnummer: 78008,
    beschreibung: 'Podologische Behandlung (groß)',
    regelbehandlungszeitMin: 20,
    regelbehandlungszeitMax: 50,
    istHeilmittel: true,
    indikationsschluessel: 'NFc',
    mehrwertsteuer: 0,
    kuerzel: 'NFc',
  },
  78009: {
    positionsnummer: 78009,
    beschreibung: 'Podologische Behandlung (groß)',
    regelbehandlungszeitMin: 20,
    regelbehandlungszeitMax: 50,
    istHeilmittel: true,
    indikationsschluessel: 'QFc',
    mehrwertsteuer: 0,
    kuerzel: 'QFc',
  },
  78040: {
    positionsnummer: 78040,
    beschreibung: 'Eingangsbefundung',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: true,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
  78100: {
    nagelkorrektur: true,
    positionsnummer: 78100,
    beschreibung: 'Erstbefundung groß',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 45,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  78110: {
    nagelkorrektur: true,
    positionsnummer: 78110,
    beschreibung: 'Erstbefundung klein',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 45,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  78210: {
    nagelkorrektur: true,
    positionsnummer: 78210,
    beschreibung: 'Anpassung einer einteiligen und bilateralen Nagelkorrekturspange (z. B. nach Ross-Fraser)',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 90,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: 2,
  },
  78220: {
    nagelkorrektur: true,
    positionsnummer: 78220,
    beschreibung: 'Fertigung einer einteiligen und bilateralen Nagelkorrekturspange (z. B. nach Ross-Fraser)',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 45,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: false,
    multiAppointment: null,
  },
  78230: {
    nagelkorrektur: true,
    positionsnummer: 78230,
    beschreibung: 'Nachregulierung einer einteiligen und bilateralen Nagelkorrekturspange (z. B. nach Ross-Fraser)',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 45,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  78300: {
    nagelkorrektur: true,
    positionsnummer: 78300,
    beschreibung:
      'Vorbereitung des Nagels, Anpassung und Aufsetzen einer mehrteiligen bilateralen Nagelkorrekturspange',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 75,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  78510: {
    nagelkorrektur: true,
    positionsnummer: 78510,
    beschreibung: 'Kontrolle auf Sitz- und Passgenauigkeit',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 15,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: false,
    multiAppointment: null,
  },
  78520: {
    nagelkorrektur: true,
    positionsnummer: 78520,
    beschreibung: 'Behandlungsabschluss',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 25,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  '78400': {
    nagelkorrektur: true,
    positionsnummer: '78400',
    beschreibung:
      'Vorbereitung des Nagels, Anpassung und Aufsetzen einer einteiligen Kunststoff- oder Metall-Nagelkorrekturspange',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    regelbehandlungszeitRecommended: 45,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
    prescribedService: true,
    multiAppointment: null,
  },
  79901: {
    positionsnummer: 79901,
    beschreibung: 'Zuschlag bei ärztlich verordnetem Hausbesuch',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
  79902: {
    positionsnummer: 79902,
    beschreibung: 'Hausbesuch mehrerer Patienten in sozialer Gemeinschaft in unmittelbarem zeitlichen Zusammenhang',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
  79933: {
    positionsnummer: 79933,
    beschreibung: 'Hausbesuch inklusive Wegegeld',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
  79934: {
    positionsnummer: 79934,
    beschreibung: 'Hausbesuch mehrerer Patienten in sozialer Gemeinschaft in unmittelbarem zeitlichen Zusammenhang',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
  79907: {
    positionsnummer: 79907,
    beschreibung: 'Wegegeld je Kilometer',
    regelbehandlungszeitMin: null,
    regelbehandlungszeitMax: null,
    istHeilmittel: false,
    indikationsschluessel: null,
    mehrwertsteuer: 0,
    kuerzel: null,
  },
}
