import { format, isDate, isValid } from 'date-fns';
import { FC } from 'react';
import { StyledPrettyTime } from './StyledPrettyTime';

const PrettyTime: FC<any> = ({time, flavor = 'HH:mm', className}): JSX.Element => {

  if (!isDate(time) || !isValid(time))
    return <></>;

  switch (flavor) {
    default:
    case 'HH:mm':
      return (
        <StyledPrettyTime className={className}>
          <span className="hours">{format(time, 'HH')}</span>
          <span className="minutes">{format(time, 'mm')}</span>
        </StyledPrettyTime>
      );
  }
};

export default PrettyTime;
