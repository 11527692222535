import { FC } from 'react';
import MenuItemsTherapiebericht from './MenuItemsTherapiebericht';
import { StyledContextMenuTherapiebericht } from './StyledContextMenuTherapiebericht';

interface Props {
  therapiebericht: any,
  handleClose: any,
  pointerLocation: any
}

const ContextMenuTherapiebericht: FC<Props> = ({
  therapiebericht,
  handleClose,
  pointerLocation: { mouseX, mouseY }, 
}: Props) => {

  return (
    <StyledContextMenuTherapiebericht
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <MenuItemsTherapiebericht
        therapiebericht={therapiebericht}
        onCloseFunc={handleClose} />
    </StyledContextMenuTherapiebericht>
  );
};


export default ContextMenuTherapiebericht;
