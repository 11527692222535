import { Button, Card, CardContent, IconButton, Typography } from '@material-ui/core'
import { ArrowRightAlt as ArrowRightAltIcon } from '@material-ui/icons'
import * as R from 'ramda'
import { FC, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { change, reduxForm } from 'redux-form'
import { logout, pinLogin } from '../../redux/modules/auth'
import PasswordInput from '../../shared/components/PasswordInput/PasswordInput'
import PododeskLogo from '../../shared/components/PododeskLogo/PododeskLogo'
import { redirectAuth, WebAuth } from '../../utils/auth0'
import { selectAuthUserId, selectExtendedActiveUsers, selectPinDefined, selectUsername } from './selectors'
import { StyledLockscreenForm } from './StyledLockscreenForm'
import { useAuth } from '../AuthProvider/useAuth'

export const fields = ['userId', 'username', 'pin', 'password', 'pinDefined']

interface Props {
  users?
  onSubmit?
  actions?
  fields?
  untouchAll?
  handleSubmit?
}

const LockscreenForm: FC<Props> = ({
  users,
  onSubmit,
  actions,
  fields: {
    userId, username, pinDefined, pin, password
  },
  untouchAll,
  handleSubmit
}) => {
  const { signout } = useAuth()

  const selectUser = (user) => {
    if (userId.value === user.authUserId) {
      return
    }
    username.onChange(user.email)
    userId.onChange(user.authUserId)
    pinDefined.onChange(user.pinDefined)
    pin.onChange('')
    password.onChange('')
    untouchAll()
  }

  const keycontrols = useCallback((e) => {
    if (['ArrowRight', 'ArrowLeft'].indexOf(e.key) === -1) return

    const curUserIndex = R.findIndex(({ authUserId }) => userId.value === authUserId, users)

    e.preventDefault()

    switch (e.key) {
      case 'ArrowRight':
        const rightUser = users[curUserIndex + 1]
        if (rightUser) {
          selectUser(rightUser)
        }
        break
      case 'ArrowLeft':
        const leftUser = users[curUserIndex - 1]
        if (leftUser) {
          selectUser(leftUser)
        }
        break
      default:
    }
  }, [userId, users, selectUser])

  useEffect(() => {
    window.addEventListener('keydown', keycontrols)
    return () => {
      window.removeEventListener('keydown', keycontrols)
    }
  }, [keycontrols])

  return (
    <StyledLockscreenForm onSubmit={handleSubmit} autoComplete="off">
      <span></span>
      <Card className="card">
        <div className="header">
          <PododeskLogo className="logo" />
          <div className="heading">
            <Typography variant="body1">
              pododesk ist gesperrt.
            </Typography>
            <Typography variant="body1">
              {!!pinDefined.value ? (
                'Bitte geben Sie Ihre PIN ein.'
              ): (
                'Bitte melden Sie sich erneut an.'
              )}
            </Typography>
          </div>
        </div>
        <CardContent className="cardText">
          <div className="usersContainer">
            {users.map((user, index) => {
              const active = userId.value === user.authUserId
              return (
                <div
                  className={`user ${active ? 'active': ''}`}
                  onClick={active
                    ? undefined
                    : () => selectUser(user)
                  }
                  key={index}>
                  <img className='avatar' src={user.avatar || user.avatarFallback} />
                  {active ? (
                    user.pinDefined ? (<>
                      <PasswordInput
                        {...pin}
                        className="pin"
                        autoComplete="off"
                        placeholder={'PIN'}
                        maxLength={4}
                        onlyAllowDigits={true}
                        error={pin.touched && !!pin.error}
                        helperText={pin.touched && !!pin.error ? pin.error : ''}
                        autoFocus
                      />
                      <IconButton className="IconButton" type="submit" disabled={pin?.value?.length <= 0}>
                        <ArrowRightAltIcon/>
                      </IconButton>
                    </>) : (
                      <Button variant="contained" color="secondary" onClick={redirectAuth}>
                        Login
                      </Button>
                    )
                  ) : (
                    <div>
                      <div className="text">{`${user.vorname} ${user.nachname.charAt(0)}.`}</div>
                      <div className="text email">{user.email}</div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </CardContent>
      </Card>
      <Button
        variant="contained"
        color="secondary"
        className="logoutButton"
        onClick={signout}>
        pododesk logout
      </Button>
    </StyledLockscreenForm>
  )
}

const validate = (values) => {
  const errors: KeyValue<string> = {}

  if (!values.pinDefined) {
    if (!values.password) {
      errors.password = 'Bitte geben Sie Ihr Passwort ein!'
    }
  } else {
    if (!values.pin) {
      errors.pin = 'Bitte geben Sie Ihre PIN ein!'
    } else if (typeof values.pin === 'string' && !values.pin.match(/^[0-9]{4}$/)) {
      errors.pin = 'Bitte geben Sie genau 4 Ziffern ein!'
    }
  }

  return errors
}

const mapStateToProps = (state) => ({
  users: selectExtendedActiveUsers(state),
  initialValues: {
    userId: selectAuthUserId(state),
    username: selectUsername(state),
    pinDefined: selectPinDefined(state),
    pin: '',
    password: '',
  },
})

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: async (data) => {
      if (!data.pinDefined) {
        await WebAuth.redirect.loginWithCredentials(
          {
            connection: __AUTH_CONNECTION__,
            username: data.username,
            password: data.password,
            scope: 'openid',
          },
          () => {
            // TODO notify of errors
          },
        )
        // await dispatch(upgradeCredentials(data))
        // return dispatch(
        //   routerActions.push(changePinPath)
        // )
      } else {
        return dispatch(pinLogin(data))
      }
    },
    actions: bindActionCreators({ logout, change }, dispatch),
  }
}

const WithReduxForm = reduxForm({
  form: 'lockscreenForm',
  validate,
  fields,
  touchOnBlur: false,
})(LockscreenForm)

// this enables us to override onSubmit in our tests
const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign({}, stateProps, dispatchProps, ownProps)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WithReduxForm)

export const Undecorated = LockscreenForm
