import { FC } from 'react'
import { StyledAlphabetFilter } from './StyledAlphabetFilter'

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

interface Props {
  onCharClick?: (char: string) => void
  selected?: boolean
}

const AlphabetFilter: FC<Props> = ({ onCharClick, selected }): JSX.Element => (
  <StyledAlphabetFilter>
    {alphabet.map((char, index) => (
      <div
        key={index}
        onClick={() => onCharClick && onCharClick(char)}
        className={char === selected ? 'characterSelected' : 'character'}
      >
        {char}
      </div>
    ))}
  </StyledAlphabetFilter>
)

export default AlphabetFilter
