import { IconButton } from '@material-ui/core'
import {
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon
} from '@material-ui/icons'
import CropperJS from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { PureComponent } from 'react'

interface Props {
  src?: string;
  onChange?: (event?) => void;
  aspectRatio?: number;
}

export default class Cropper extends PureComponent<Props> {

  public image: HTMLImageElement | null;
  public cropper: CropperJS | null;

  static defaultProps = {
    src: '',
    onChange: () => {},
    aspectRatio: 1,
  }

  constructor(props: Props) {
    super(props)

    this.image = null;
    this.cropper = null;
    // this.swapX = -1
    // this.swapY = -1
  }

  componentWillUnmount() {
    this.cropper?.destroy()
  }

  componentDidMount() {
    if(!this.image) {
      return;
    }

    this.cropper = new CropperJS(this.image, {
      autoCrop: true,
      zoomOnWheel: false,
      aspectRatio: this.props.aspectRatio,
      autoCropArea: 0.8,
      toggleDragModeOnDblclick: false,
      minCropBoxWidth: 100,
      minCropBoxHeight: 100,
      dragMode: 'move',
      crop: (event: CropperJS.CropEvent) => {
        this.props.onChange && this.props.onChange(event)
      },
    })
  }


  render() {
    const { src } = this.props
    /**
     ** Removed the following props from IconButton: tooltip (='vergrößern' etc.), tooltipPosition (='top-center')
     */

    return (
      <div style={{
        position: 'relative',
        backgroundColor: 'white',
        zIndex: 1000000,
        minHeight: '256px'
        }}>
        <div style={{ maxWidth: '100%', maxHeight: '500px' }}>
          <img
            style={{ width: '100%', height: 'auto'}}
            ref={image => this.image = image}
            src={src} />
        </div>
        <div style={{marginTop: '10px'}}>
          <IconButton onClick={() => this.cropper?.zoom(0.1)}>
            <ZoomInIcon color="secondary" />
          </IconButton>
          <IconButton onClick={() => this.cropper?.zoom(-0.1)}>
            <ZoomOutIcon color="secondary" />
          </IconButton>
          <IconButton onClick={() => this.cropper?.rotate(-45)}>
            <RotateLeftIcon color="secondary" />
          </IconButton>
          <IconButton onClick={() => this.cropper?.rotate(45)}>
            <RotateRightIcon color="secondary" />
          </IconButton>
        </div>
      </div>
    )
  }
}
