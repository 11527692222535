import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { remove as unaccent } from 'diacritics'
import { FC, memo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { sortByNachnameVorname } from '../../utils/persons'
import { ContextMenuArzt } from '../MenuArzt'
import { StyledDoctorsListView } from './StyledDoctorsListView'

const tableColumns = ['Name', 'Fachrichtung', 'Adresse', 'PLZ', 'Ort', 'Telefon', 'Fax', 'E-Mail']

interface Props {
  doctors,
  doctorIds,
  doctorsFilter,
  aerzteFachgruppen
}

const DoctorsListView: FC<Props> = ({ doctors, doctorIds, doctorsFilter, aerzteFachgruppen = {} }) => {
  const [pointerLocation, setPointerLocation] = useState<{ mouseX: null | number; mouseY: null | number }>({
    mouseX: null,
    mouseY: null,
  })
  const [selectedDoctor, setSelectedDoctor] = useState<number | null>(null)

  const handleRowClick = useCallback((e, doctorId) => {
    e.persist()
    setSelectedDoctor(doctorId)
    setPointerLocation({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    })
  }, [setSelectedDoctor, setPointerLocation])

  const closeContextMenu = useCallback(() => {
    setPointerLocation({ mouseX: null, mouseY: null })
    setSelectedDoctor(null)
  }, [setSelectedDoctor, setPointerLocation])

  return (
    <>
      <StyledDoctorsListView>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell size="small" className="tableHead" key={index}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {doctorIds &&
              doctorIds
                .map((id) => doctors[id])
                .filter((doctor) => {
                  if (doctorsFilter.lastNameBeginsWith) {
                    return unaccent(doctor.nachname.charAt(0).toLowerCase()) === doctorsFilter.lastNameBeginsWith
                  }
                  return true
                })
                .sort(sortByNachnameVorname)
                .map((doctor) => {
                  let aerzteFachgruppe: AerzteFachgruppe | null = null
                  if (doctor.lanr && doctor.lanr.length === 9) {
                    aerzteFachgruppe = aerzteFachgruppen[parseInt(doctor.lanr.slice(7, 9), 10)] || null
                  }

                  return (
                    <TableRow
                      className="doctorsTableRow"
                      hover
                      key={doctor?.id}
                      onClick={(e) => handleRowClick(e, doctor?.id)}
                    >
                      <TableCell className="bodyTableCell" size="small">
                        {[doctor.titel, doctor.nachname].filter(Boolean).join(' ')}
                      </TableCell>
                      <TableCell className="bodyTableCell" size="small">{aerzteFachgruppe?.name ?? ''}</TableCell>
                      <TableCell className="bodyTableCell" size="small">
                        {[doctor?.strasse, doctor?.hausnummer].filter(Boolean).join(' ')}
                      </TableCell>
                      <TableCell className="bodyTableCell" size="small">{doctor?.postleitzahl ?? ''}</TableCell>
                      <TableCell className="bodyTableCell" size="small">{doctor?.ort ?? ''}</TableCell>
                      <TableCell className="bodyTableCell" size="small">{doctor?.telefon ?? ''}</TableCell>
                      <TableCell className="bodyTableCell" size="small">{doctor?.fax ?? ''}</TableCell>
                      <TableCell className="bodyTableCell" size="small">{doctor?.email ?? ''}</TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>
      </StyledDoctorsListView>
      <ContextMenuArzt
        pointerLocation={pointerLocation}
        handleClose={closeContextMenu}
        doctorId={selectedDoctor}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  doctorIds: state.ids.aerzte,
  doctorsFilter: state.doctorsFilter,
  doctors: state.entities.aerzte,
  aerzteFachgruppen: state.aerzteFachgruppen,
})

export default connect(mapStateToProps)(memo(DoctorsListView))
