import * as types from '../constants/actionTypes'
import sharedFormsReducers from '../shared/redux/modules/forms'
import isPristine from 'redux-form/lib/isPristine'
import { stringsToDatesSingleEntity } from '../shared/utils/dates'
import { momentFields } from './entities'
import { terminFields, dokumentationenFields } from '../containers/DokumentationenForm/DokumentationenForm'
import * as R from 'ramda'

export default {
  anamneseForm: (state, action) => {
    switch (action.type) {
      /*
       * nach erfolgreichem Speichern die vom Server gesendete id in form state übernehmen,
       * damit danach updates möglich sind
       * */
      case types.CREATE_ANAMNESIS_SUCCESS:
        const neueAnamnese =
          action.json && action.json.entities && action.json.entities.anamnesen
            ? action.json.entities.anamnesen[action.json.result[0]]
            : false
        if (neueAnamnese && !state.id.value && state.patient.value === neueAnamnese.patient) {
          return {
            ...state,
            id: {
              value: neueAnamnese.id,
              initial: neueAnamnese.id,
            },
          }
        }
        return state

      case types.FINISH_ANAMNESIS_SUCCESS:
        if (state.id.value === action.data.id) {
          return {
            ...state,
            abgeschlossen: {
              ...state.abgeschlossen,
              value: true,
            },
          }
        }
        return state

      /*
       * Ändern von Feldern in abgeschlossener Anamnese verhindern.
       * */
      case 'redux-form/CHANGE':
      case 'redux-form/BLUR':
        if (state && state.abgeschlossen && state.abgeschlossen.value === true && action.field !== 'abgeschlossen') {
          return revertFieldChange(state, action)
        }
        return state

      default:
        return state
    }
  },

  customerForm: (state, action) => {
    switch (action.type) {
      case types.CREATE_CUSTOMER_SUCCESS:
        return {}

      default:
        return state
    }
  },

  doctorForm: (state, action) => {
    switch (action.type) {
      case types.CREATE_DOCTOR_SUCCESS:
        return {}

      default:
        return state
    }
  },

  dokumentationenForm: (state, action) => {
    switch (action.type) {
      case types.SOCKET_RECEIVE_DATA:
        return {
          ...state,
          ...updateFormFromAction(
            state,
            action,
            'termine',
            R.filter((value) => value !== 'terminId', terminFields),
            'terminId',
          ),
          ...updateFormFromAction(state, action, 'dokumentationen', dokumentationenFields, 'terminId'),
        }
      case types.CREATE_DOKUMENTATIONSTEXT_SUCCESS:
        return {
          ...state,
          behandlungsDokumentation: '',
          medizinischeEmpfehlung: '',
        }

      default:
        return state
    }
  },

  hvoForm: (state, action) => {
    switch (action.type) {
      case types.CREATE_HEILMITTELVERORDNUNG_SUCCESS:
        return {}

      default:
        return state
    }
  },

  terminForm: (state, action) => {
    switch (action.type) {
      case types.CREATE_DATE_SUCCESS:
        return {}

      case types.SOCKET_RECEIVE_DATA:
        return {
          ...state,
          ...updateFormFromAction(state, action, 'termine'),
        }

      default:
        return state
    }
  },

  terminOrganisatorischForm: (state, action) => {
    switch (action.type) {
      case types.CREATE_DATE_SUCCESS:
        return {}

      case types.SOCKET_RECEIVE_DATA:
        return {
          ...state,
          ...updateFormFromAction(state, action, 'termine'),
        }

      default:
        return state
    }
  },

  viewArztForm: revertFormChange,
  viewHVOForm: revertFormChange,
  viewDokumentationForm: revertFormChange,
  viewTerminAbrechnenForm: revertFormChange,
  ...sharedFormsReducers,
}

function revertFormChange(state, action) {
  switch (action.type) {
    case 'redux-form/CHANGE':
    case 'redux-form/BLUR':
      return revertFieldChange(state, action)
    default:
      return state
  }
}

function revertFieldChange(state, action) {
  const { field: fieldKey } = action
  const field = state[fieldKey]
  if (field && field.initial !== undefined) {
    return {
      ...state,
      [fieldKey]: {
        ...field,
        value: field.value,
      },
    }
  }
  return state
}

function updateFormFromAction(state, action, entityType, fields = null, idField = 'id') {
  if (action.json.entity !== entityType) return {}
  const entityInstance = action.json.entities[entityType][action.json.result[0]]
  if (
    state._initialized === true &&
    (entityType === 'dokumentationen'
      ? entityInstance.termin === state[idField].initial
      : entityInstance.id === state[idField].initial)
  ) {
    const entityInstanceWithMoments = stringsToDatesSingleEntity(entityInstance, momentFields[entityType])
    return R.compose(
      R.mapObjIndexed((value, key) => ({
        initial: entityInstanceWithMoments[key],
        value: entityInstanceWithMoments[key],
      })),
      R.filter((value) => isPristine(value.initial, value.value)),
      R.mapObjIndexed((value, key) => (typeof value === 'object' ? value : state[key])),
    )(fields ? R.invertObj({ ...fields }) : R.filter((value) => typeof value === 'object', state))
  }
  return {}
}
