import { useRef, useEffect} from 'react'
/*
 * hier kommen Helferfunktionen für funktionale Programmierung rein.
 * */
import * as R from 'ramda'
import { createSelector } from 'reselect'
import _ from 'lodash'

export const debug = (x) => {
  debugger // eslint-disable-line no-debugger
  return x
}

export const log = (x) => {
  console.log(x)
  return x
}

export const uncurry = (curriedFn) => (...args) => args.reduce((left, right) => left(right), curriedFn)

// eine Variante von createSelector, bei der die Argumente der letzten Funktion
// eins nach dem anderen angewandt werden, damit man currying nutzen kann.
// https://github.com/reactjs/reselect/issues/159#issuecomment-238724788
export const createSelectorN = (...args) => createSelector(...R.init(args), uncurry(R.last(args)))

// toInteger :: String -> Integer
export const toInteger = (string) => parseInt(string, 10)

// alle argumente in array umwandeln
export const list = R.unapply(R.identity)

export const isPropsEqual = (prevProps, nextProps, propKeysToCmp) => {

  return propKeysToCmp.every(function (key) {
    if (key.includes('.')) {
      return _.isEqual(_.get(prevProps, key), _.get(nextProps, key));
    }

    if (_(prevProps[key]).isArray()) {
      return _(prevProps[key]).xorWith(nextProps[key], _.isEqual).isEmpty();
    }

    return _.isEqual(prevProps[key], nextProps[key]);
  });
}

export const usePrevious = <T>(value: T): T => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
