import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCustomerDetails = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;

    .form {
      padding: ${theme.spacing(3)}px;
    }

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${theme.spacing(6)}px;
    }

    .fieldContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${theme.spacing(6)}px;
    }

    .fieldGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: min-content;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .MuiInputBase-root.Mui-disabled {
      color: #000;
    }

    .MuiFormLabel-root.Mui-disabled {
      color: #5f6367;
    }
  `}
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    text-decoration: underline;
  }
`

export const AddNewLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    text-decoration: underline;
  }

  &:visited {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
