import { FC, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NEW_PAYMENT_PAGE_PATH, START_PAGE_PATH } from '../../shared/utils/constants'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { StyledLayout } from './StyledLayout'

interface Props {
  children: React.ReactNode
  practiceView?: boolean
  dialogActions?: any
  isBluetoothConnected?: boolean
}

const Layout: FC<Props> = ({ children, practiceView, dialogActions, isBluetoothConnected }): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false)
  const { pathname } = useLocation()

  const isStartPage = pathname.includes(START_PAGE_PATH)
  const isPaymentPage = pathname.includes(NEW_PAYMENT_PAGE_PATH)

  const handleToggleMenu = useCallback(() => setShowMenu((open) => !open), [open])
  const handleCloseMenu = useCallback(() => setShowMenu(false), [])

  return (
    <StyledLayout>
      <Header
        isPaymentPage={isPaymentPage}
        isStartPage={isStartPage}
        onMenuToggle={handleToggleMenu}
        showToolBar={practiceView}
        dialogActions={dialogActions}
        isBluetoothConnected={isBluetoothConnected}
      />
      <div className="container layout-container">
        <Menu isPaymentPage={isPaymentPage} isStartPage={isStartPage} open={showMenu} onClose={handleCloseMenu} practiceView={practiceView} />
        <div className="content main-content-wrapper">{children}</div>
      </div>
    </StyledLayout>
  )
}

export default Layout
