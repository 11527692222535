import {
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  Eco as EcoIcon,
  EuroSymbol as EuroSymbolIcon,
  LocalHospital as LocalHospitalIcon,
  People as PeopleIcon,
  Person as PersonIcon, Receipt as ReceiptIcon, ShoppingCart as ShoppingCartIcon
} from '@material-ui/icons'
import { FC, memo, useCallback, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from "redux"
import { getDebitoorAPI } from "../../actions/debitoorAPI"
import ChooseCustomerDialog from '../../components/ChooseCustomerDialog/ChooseCustomerDialog'
import AddNewButton from '../../shared/components/AddNewButton/AddNewButton'
import AddKassenbucheintragDialog from '../AddKassenbucheintragDialog/AddKassenbucheintragDialog'
import { StyledAddNewList } from './StyledAddNewList'


interface IAddNewButton {
  to?: string,
  onClick?: () => void,
  icon: JSX.Element,
  label: string,
  hoverText: string,
}

const AddNewListUnbound: FC<{actions: any}> = ({actions}) => {
  const [chooseCustomerDialogOpen, setChooseCustomerDialogOpen] = useState<boolean>(false)
  const [addKassenbucheintragDialogOpen, setAddKassenbucheintragDialogOpen] = useState<boolean>(false)
  const [redirectAfterChooseCustomer, setRedirectAfterChooseCustomer] = useState<(number) => string>(() => () => '/')
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const _closeChooseCustomersDialog = useCallback(() => {
    setChooseCustomerDialogOpen(false)
  }, [setChooseCustomerDialogOpen])

  const _closeAddKassenbucheintragDialog = useCallback(() => {
    setAddKassenbucheintragDialogOpen(false)
  }, [setAddKassenbucheintragDialogOpen])

  const _customerChosen = useCallback(({ customer: customerId }) => {
    const url = redirectAfterChooseCustomer(customerId);
    navigate(url)
  }, [redirectAfterChooseCustomer])

  const _openChooseCustomerDialog = useCallback((redirectAfterChooseCustomerFunc) => {
    setRedirectAfterChooseCustomer(() => redirectAfterChooseCustomerFunc)
    setChooseCustomerDialogOpen(true)
  }, [setRedirectAfterChooseCustomer, setChooseCustomerDialogOpen]);

  const _openAddKassenbucheintragDialog = useCallback(() => {
    setAddKassenbucheintragDialogOpen(true)
  }, [setAddKassenbucheintragDialogOpen])

  const _handleAddInvoice = async () => {
    const res = await actions.getDebitoorAPI();
    window?.open(res.url);
  }
  
  const addNewButtonList: Array<IAddNewButton> = [
    {
      to: `/${serverHash}/contacts/customers/add`,
      icon: <PeopleIcon fontSize="inherit" />,
      label: 'Patient',
      hoverText: 'Patient hinzufügen',
    },
    {
      to: `/${serverHash}/calendar/free`,
      icon: <CalendarTodayIcon fontSize="inherit" />,
      label: 'Termin',
      hoverText: 'Termin finden',
    },
    {
      onClick: () =>
        _openChooseCustomerDialog(
          (customerId) => `/${serverHash}/anamnesis/${customerId}/01`
        ),
      icon: <AssignmentIcon fontSize="inherit" />,
      label: 'Anamnese',
      hoverText: 'Anamnese erstellen',
    },
    {
      onClick: () =>
        _openChooseCustomerDialog(
          (customerId) => `/${serverHash}/hvo/add/${customerId}`
        ),
      icon: <EcoIcon fontSize="inherit" />,
      label: 'HVO',
      hoverText: 'Verordnung hinzufügen',
    },
    {
      to: `/${serverHash}/contacts/doctors/add`,
      icon: <PersonIcon fontSize="inherit" />,
      label: 'Arzt',
      hoverText: 'Arzt hinzufügen',
    },
    {
      onClick: () =>
        _openChooseCustomerDialog(
          (customerId) => `/${serverHash}/reports/add/${customerId}`
        ),
      icon: <LocalHospitalIcon fontSize="inherit" />,
      label: 'Therapiebericht',
      hoverText: 'Therapiebericht hinzufügen',
    },
    {
      onClick: () => _openAddKassenbucheintragDialog(),
      icon: <EuroSymbolIcon fontSize="inherit" />,
      label: 'Kassenbucheintrag',
      hoverText: 'manuellen Kassenbucheintrag erstellen',
    },
    {
      to: `/${serverHash}/settings/leistungen`,
      icon: <ShoppingCartIcon fontSize="inherit" />,
      label: 'Leistung',
      hoverText: 'Leistung in Einstellungen hinzufügen',
    },
    {
      onClick: () => _handleAddInvoice(),
      icon: <ReceiptIcon fontSize="inherit" />,
      label: 'Rechnung',
      hoverText: 'Rechnung erstellen',
    }
  ]

  return (
    <>
      <StyledAddNewList>
        {addNewButtonList.map((btn: IAddNewButton, idx) => (
          <AddNewButton
            key={idx}
            to={btn.to}
            onClick={btn.onClick}
            icon={btn.icon}
            label={btn.label}
            hoverText={btn.hoverText}
          />
        ))}
      </StyledAddNewList>
      <ChooseCustomerDialog
        customerChosen={_customerChosen}
        onRequestClose={_closeChooseCustomersDialog}
        open={chooseCustomerDialogOpen}
      />
      <AddKassenbucheintragDialog
        onRequestClose={_closeAddKassenbucheintragDialog}
        open={addKassenbucheintragDialogOpen}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getDebitoorAPI: bindActionCreators(getDebitoorAPI, dispatch),
  }
})

export const AddNewList = connect(null, mapDispatchToProps)(memo(AddNewListUnbound))
