import { Fragment, memo, useEffect } from 'react';
import { DragDropContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as datesActions from '../../actions/dates';

import StyledLink from '../../shared/components/StyledLink';
import CalendarColumn from '../CalendarColumn';
import CalendarColumnHeader from '../CalendarColumnHeader';
import CalendarColumnSpacer from '../CalendarColumnSpacer';

import { Person as PersonIcon } from '@material-ui/icons';
import { addWeeks, endOfWeek, isSameDay, isSameWeek, startOfWeek } from 'date-fns';
import {
  getTherapeuten,
  sAllDayGapHeightWeekSelectedPerson,
  sDatesByWeekDay,
  sGridConfigWeek,
  sSelectedTherapist,
  sTermineGeladenWoche,
  sWeekDaysM
} from '../Calendar/selectors';
import DataLoading from '../DataLoading/DataLoading';
import { StyledCalendarWeekView } from './StyledCalendarWeekView';

const CalendarWeekView = ({
  allDayGapHeight,
  actions,
  therapists,
  datesByWeekDay,
  gridConfig,
  onDateClick,
  onFreeDateClick,
  selectedDay,
  termineGeladen,
  weekDaysM,
  selectedTherapist,
  onIcsItemClick,
  dialogActions,
  selectedTerminProps,
  notification
}) => {
  const loadDates = () => {
    actions.loadDates({
      startDate: startOfWeek(selectedDay, { weekStartsOn: 1 }),
      endDate: endOfWeek(selectedDay, { weekStartsOn: 1 }),
    });
  };

  useEffect(() => {
    loadDates();
  }, [selectedDay]);

  const { headerHeight, numHours, gridRowMinutes, gridRowHeight } = gridConfig;

  const rowsPerHour = 60 / gridRowMinutes;
  const pixelsPerHour = rowsPerHour * gridRowHeight;
  const computedHeight = numHours * pixelsPerHour + headerHeight + allDayGapHeight;

  const today = new Date()
  const plus1w = selectedDay ? addWeeks(selectedDay, 1) : addWeeks(today, 1)
  const plus4w = selectedDay ? addWeeks(selectedDay, 4) : addWeeks(today, 4)
  const plus6w = selectedDay ? addWeeks(selectedDay, 6) : addWeeks(today, 6)

  const isSnackbarOpen = !!notification?.message;

  return (
    <StyledCalendarWeekView isSnackbarOpen={isSnackbarOpen} computedHeight={computedHeight}>
      <div className="calendarWeekViewTopBar">
        <StyledLink
        fullWidth
          onClickFunc={() => {
            actions.selectDay(today)
          }}
          active={isSameWeek(selectedDay, today, { weekStartsOn: 1 })}
          borderEnd
        >
          Heute
        </StyledLink>
        <StyledLink
        fullWidth
          onClickFunc={() => {
            actions.selectDay(plus1w)
          }}
          active={isSameWeek(selectedDay, plus1w, { weekStartsOn: 1 })}
          borderStart
          borderEnd
        >
          +1 Woche
        </StyledLink>
        <StyledLink
        fullWidth
          onClickFunc={() => {
            actions.selectDay(plus4w)
          }}
          active={isSameWeek(selectedDay, plus4w, { weekStartsOn: 1 })}
          borderStart
          borderEnd
        >
          +4 Wochen
        </StyledLink>
        <StyledLink
        fullWidth
          onClickFunc={() => {
            actions.selectDay(plus6w)
          }}
          active={isSameWeek(selectedDay, plus6w, { weekStartsOn: 1 })}
          borderStart
        >
          +6 Wochen
        </StyledLink>
      </div>
      <div className="mainContainer">
      <div className="calendarWeekViewTopBar">
        {therapists.map((pe) => (
          <StyledLink fullWidth to={`../week/${pe.id}`} isRelative startIcon={<PersonIcon />} key={pe.id} borderTop borderRight>
            <span>
              {[pe?.vorname, pe?.nachname].filter(Boolean).join(' ')}
            </span>
          </StyledLink>
        ))}
      </div>
      <div className="calendarWeekViewMain">
        <CalendarColumnSpacer flavor='timesBig' gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}>
          <CalendarColumnHeader flavor='week' date={selectedDay} />
        </CalendarColumnSpacer>
        <CalendarColumnSpacer flavor='line' gridConfig={gridConfig} allDayGapHeight={allDayGapHeight} />
        { termineGeladen
          ? weekDaysM.map((weekDay, index) => (
            <Fragment key={index}>
              <CalendarColumn
                gridConfig={gridConfig}
                locked={false}
                columnDay={weekDay}
                onDateClick={onDateClick}
                onFreeDateClick={onFreeDateClick}
                person={selectedTherapist}
                markCurrentTime={isSameDay(weekDay, new Date())}
                dates={datesByWeekDay[ index ]}
                allDayGapHeight={allDayGapHeight}
                onIcsItemClick={onIcsItemClick}
                dialogActions={dialogActions}
                >
                <CalendarColumnHeader flavor='day' date={weekDay} />
              </CalendarColumn>

              {(index < (weekDaysM.length - 1))
                ? <CalendarColumnSpacer
                  flavor='times'
                  gridConfig={gridConfig}
                  allDayGapHeight={allDayGapHeight}
                />
                : null
              }
            </Fragment>
          ))
          : (
          <DataLoading text='lade Termine...'/>
        ) }
      </div>
      </div>
    </StyledCalendarWeekView>
  )
}

const mapStateToProps = (state, props) => ({
  gridConfig: sGridConfigWeek(state, props),
  datesByWeekDay: sDatesByWeekDay(state, props),
  therapists: getTherapeuten(state),
  selectedTherapist: sSelectedTherapist(state, props),
  customers: state.entities.patienten,
  selectedDay: state.selectedDay,
  termineGeladen: sTermineGeladenWoche(state),
  weekDaysM: sWeekDaysM(state),
  allDayGapHeight: sAllDayGapHeightWeekSelectedPerson(state, props),
  selectedTerminProps: state.selectedTerminProps,
  notification: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(datesActions, dispatch),
});

const ConnectedCalendarWeekView = connect(mapStateToProps, mapDispatchToProps)(CalendarWeekView);

export default DragDropContext(HTML5Backend)(memo(ConnectedCalendarWeekView));
