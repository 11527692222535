import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import { RequestConfig } from './interface'

export function loadTherapieberichte(): RequestConfig {
  return {
    types: [types.LOAD_THERAPIEBERICHTE, types.LOAD_THERAPIEBERICHTE_SUCCESS, types.LOAD_THERAPIEBERICHTE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => state.ids.therapieberichte.length <= 0,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/therapieberichte`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createTherapiebericht(data): RequestConfig {
  return {
    types: [types.CREATE_THERAPIEBERICHT, types.CREATE_THERAPIEBERICHT_SUCCESS, types.CREATE_THERAPIEBERICHT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/therapieberichte`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}
