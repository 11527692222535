import styled from 'styled-components';

export const StyledPracticesList = styled.div`
  ${({ theme }) => `
    .practicesListStyledLink {
      box-sizing: border-box;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      width: 100%;
      text-align: left;
      padding: 6px 6px 6px 13px;
      border-bottom: 1px solid ${theme.palette.text.primary};
      flex-grow: 0;
      position: relative;
      line-height: auto;
      height: auto;

      display: flex;
      align-items: center;

      &:first-child {
        border-top: 1px solid ${theme.palette.text.primary};
        margin-top: 30px;
      }

      &.left {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.text.primary};
        &:hover {
          background-color: ${theme.palette.primary.dark};
        }
      }

      &--more-less {
        display: flex;
        align-items: center;
      }
      &__icon {
        display: flex;
        margin-left: 5px;
        margin-right: 22px;
      }

      .praxisListAvatar {
        display: flex;
        width: 25px;
        height: 25px;
        margin-right: 25px;
        margin-left: 5px;
        background-color: #fff;
      }

      .praxisListText {
        display: flex;
        flex-direction: column;
      }
    }
  `}
`;
