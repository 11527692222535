import GloberBoldWoff2 from './fonts/glober/Glober-Bold.woff2'
import GloberLightWoff2 from './fonts/glober/Glober-Light.woff2'
import GloberMediumWoff2 from './fonts/glober/Glober-Medium.woff2'
import GloberRegularWoff2 from './fonts/glober/Glober-Regular.woff2'
import RubikLightWoff2 from './fonts/rubik/Rubik-Light.woff2'
import RubikRegularWoff2 from './fonts/rubik/Rubik-Regular.woff2'
import RubikMediumWoff2 from './fonts/rubik/Rubik-Medium.woff2'

export const rubikLight = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Rubik-Light'),
    url(${RubikLightWoff2}) format('woff2')
  `,
}

export const rubikRegular = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Rubik-Regular'),
    url(${RubikRegularWoff2}) format('woff2')
  `,
}

export const rubikMedium = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Rubik-Medium'),
    url(${RubikMediumWoff2}) format('woff2')
  `,
}

export const globerRegular = {
  fontFamily: 'Glober',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Glober'),
    local('Glober-Regular'),
    url(${GloberRegularWoff2}) format('woff2')
  `,
}

export const globerLight = {
  fontFamily: 'Glober',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Glober'),
    local('Glober-Light'),
    url(${GloberLightWoff2}) format('woff2')
  `,
}

export const globerMedium = {
  fontFamily: 'Glober',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Glober'),
    local('Glober-Medium'),
    url(${GloberMediumWoff2}) format('woff2')
  `,
}

export const globerBold = {
  fontFamily: 'Glober',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Glober'),
    local('Glober-Bold'),
    url(${GloberBoldWoff2}) format('woff2')
  `,
}
