import * as R from 'ramda'
import { createSelector } from 'reselect'
import { sCurrentUser } from '../../shared/utils/users'

export const selectExtendedActiveUsers = createSelector(
  (state: StoreState) => state.entities.users || {},
  (state: StoreState) => state.auth.users || {},
  (stateUsers, authUsers) =>
    R.pipe(
      R.values,
      R.filter((v) => !v.disabled),
      R.map((u) => ({ ...u, ...(authUsers[u.authUserId] || {}) })),
    )(stateUsers),
)

export const selectAuthUserId = (state) => state.auth.currentUserId

export const selectUsername = createSelector(sCurrentUser, (user) => (user ? user.email : ''))

export const selectPinDefined = createSelector(sCurrentUser, (user) => (user ? user.pinDefined : false))
