import { UserManager, WebStorageStateStore } from 'oidc-client'

export const userManager = new UserManager({
  authority: process.env.AUTH_AUTHORITY,
  client_id: process.env.AUTH_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'id_token',
  scope: 'openid email',
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  monitorSession: true,
  automaticSilentRenew: true,
})

export const hasAuthParams = (): boolean => {
  const searchParams = new URLSearchParams(location.hash.replace('#', '?'))

  if ((searchParams.get('id_token') || searchParams.get('error')) && searchParams.get('state')) {
    return true
  }

  return false
}
