import { Button } from '@material-ui/core'
import { memo, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
import { createBuchung } from '../../actions/kassenbuch'
import CurrencyField from '../../components/CurrencyField'
import InputField from '../../shared/components/InputField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import { praxisstammdatenSelector } from '../../selectors/selectors'

const fields = [
  'testbetrag',
  'betreff',
  'betrag',
  'buchungstyp',
  'belegnummer',
  'mehrwertsteuer'
]

const buchungstypen = [
  'warenverkauf_laufkundschaft',
  'sonstiges',
  'privateinlage',
  'privatentnahme',
  'bankeinzahlung',
  'bankabhebung',
  'korrekturbuchung',
]

const betreffTexte = {
  warenverkauf_laufkundschaft: 'Warenverkauf Laufkundschaft',
  sonstiges: 'Sonstige Einnahmen  & Ausgaben',
  privateinlage: 'Privateinlage',
  privatentnahme: 'Privatentnahme',
  bankeinzahlung: 'Bankeinzahlung',
  bankabhebung: 'Bankabhebung',
  korrekturbuchung: 'Korrekturbuchung wegen Zähldifferenz',
}

const StyledForm = styled.form`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: ${theme.spacing(3)}px;

    .buttonContainer {
      margin-left: auto;
    }

    .button {
      border-radius: 0;
    }

    > a {
      text-decoration: none;
      color: ${theme.palette.secondary.main};
      width: max-content;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`

const AddKassenbucheintragForm = ({
  actions,
  onRequestClose,
  handleSubmit,
  invalid,
  busy,
  submitFailed,
  fields: { betreff, betrag, buchungstyp, belegnummer, mehrwertsteuer },
  praxisstammdaten,
}) => {
  const onSubmit = useCallback(async (data) => {
    //TODO:- use normalizer instead
    await actions.createBuchung({
      ...data,
      betreff: data.buchungstyp === 'sonstiges' ? data.betreff : betreffTexte[data.buchungstyp],
    })

    onRequestClose()
  }, [actions.createBuchung, onRequestClose])

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">

      <SelectField
        {...buchungstyp}
        label="Buchungstyp"
        options={buchungstypen.map((item) => {
          return {value: item, text: betreffTexte[item], keyIndex: item}
        })}
      />

      {buchungstyp.value === 'sonstiges' && (
        <InputField
          {...betreff}
          helperText={betreff.touched && betreff.error ? betreff.error : 'Beschreibung des Geschäftvorfalls'}
          error={betreff.touched && betreff.error}
          label="Betreff"
        />
      )}
      <CurrencyField
        {...betrag}
        label="Betrag"
        error={betrag.touched && !!betrag.error}
        helperText={betrag.touched && !!betrag.error ? betrag.error : ''}
        endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
      />
      {(buchungstyp.value === 'sonstiges' || buchungstyp.value === 'warenverkauf_laufkundschaft') && (
        <InputField
          {...mehrwertsteuer}
          type="number"
          helperText={mehrwertsteuer.touched && !!mehrwertsteuer.error ? mehrwertsteuer.error :"evtl. MwSt eingeben"}
          label="MwSt (in %)"
          error={mehrwertsteuer.touched && !!mehrwertsteuer.error}
        />
      )}
      <InputField
        {...belegnummer}
        helperText="Nr. des Beleges für diese Buchung"
        label="Belegnummer"
        //commented as the belegnummer is not to be mandatory untif further clarification
        //errorText={belegnummer.touched && belegnummer.error}
        inputProps={{ maxLength: 22 }}
      />
      <a
        href="https://pododesk.freshdesk.com/support/solutions/articles/17000086738-belegnummer-f%C3%BCr-kassenbuch-eintr%C3%A4ge"
        target="_blank"
        rel="noreferrer"
      >
        Was ist das?
      </a>
      <div className="buttonContainer">
        <Button
          className="button"
          variant="text"
          color="secondary"
          type="button"
          style={{ marginRight: 5 }}
          onClick={onRequestClose}
        >
          Abbrechen
        </Button>
        <Button
          className="button"
          variant="contained"
          color="secondary"
          type="submit"
          disabled={busy || (invalid && submitFailed)}
        >
          Speichern
        </Button>
      </div>
    </StyledForm>
  )
}

const validate = (values) => {
  const errors: KeyValue<string> = {}

  if (!values.betrag) {
    errors.betrag = 'Bitte geben Sie einen Betrag ein!'
  } else if (values.buchungstyp === 'warenverkauf_laufkundschaft' && values.betrag < 0) {
    errors.betrag = 'Bitte geben Sie einen positiven Betrag ein!'
  } else if (values.buchungstyp === 'privateinlage' && values.betrag < 0) {
    errors.betrag = 'Bitte geben Sie einen positiven Betrag ein!'
  } else if (values.buchungstyp === 'privatentnahme' && values.betrag > 0) {
    errors.betrag = 'Bitte geben Sie einen negativen Betrag ein!'
  } else if (values.buchungstyp === 'bankeinzahlung' && values.betrag > 0) {
    errors.betrag = 'Bitte geben Sie einen negativen Betrag ein!'
  } else if (values.buchungstyp === 'bankabhebung' && values.betrag < 0) {
    errors.betrag = 'Bitte geben Sie einen positiven Betrag ein!'
  }

  if (values.betrag > 2147483647) {
    errors.betrag = 'Der Betrag ist zu groß!'
  } else if (values.betrag < -2147483648) {
    errors.betrag = 'Der Betrag ist zu klein!'
  }

  if (values.buchungstyp === 'sonstiges' && !values.betreff) {
    errors.betreff = 'Bitte Beschreibung angeben!'
  }

  if (values.mehrwertsteuer) {
    if (values.mehrwertsteuer > 100) {
      errors.mehrwertsteuer = 'Max. 100% möglich!'
    } else if (values.mehrwertsteuer < 0) {
      errors.mehrwertsteuer = 'Kein negativer Wert möglich!'
    }
  }

  //commented as the belegnummer is not to be mandatory untif further clarification
  //if (!values.belegnummer) {
  //  errors.belegnummer = 'Bitte Belegnummer angeben!'
  //}

  return errors
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  loading: state.loading,
  praxisstammdaten: praxisstammdatenSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createBuchung,
    },
    dispatch,
  ),
})

export default reduxForm(
  {
    form: 'addKassenbucheintragForm',
    validate,
    fields,
    destroyOnUnmount: true,
    initialValues: {
      buchungstyp: 'warenverkauf_laufkundschaft',
      betrag: '',
    },
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(AddKassenbucheintragForm))
