import { format } from 'date-fns'
import * as R from 'ramda'
import { FC, useState } from 'react'
import { connect } from 'react-redux'
import { ContextMenuHeilmittelverordnung } from '../../components/MenuHeilmittelverordnung'
import TableBody from '../../components/TableBody/TableBody'
import TableHeader from '../../components/TableHeader/TableHeader'
import TableRow from '../../components/TableRow/TableRow'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { sortByAusstellungsdatumDescending } from '../../utils/heilmittelverordnungen'

import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'
import { StyledHVOListView } from './StyledHVOListView'

interface Props {
  customer?: any
  doctors?: any
  leistungenKK?: any
  heilmittelverordnungenIds?: number[]
  heilmittelverordnungen?: any
  termine?: any
  limit?: number
  setTermineHighlight?: any
  highlightedHVO?: any
}

const HVOListView: FC<Props> = ({
  customer,
  doctors,
  leistungenKK,
  heilmittelverordnungenIds,
  heilmittelverordnungen,
  termine,
  limit = 3,
  setTermineHighlight,
  highlightedHVO,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [pointerLocation, setPointerLocation] = useState<any>({ mouseX: null, mouseY: null })
  const [selectedHeilmittelverordnung, setSelectedHeilmittelverordnung] = useState<any | null>(null)

  const _handleItemClick = (ev, { id }) => {
    const heilmittelverordnung = heilmittelverordnungen[id]
    ev.persist()
    setSelectedHeilmittelverordnung(heilmittelverordnung)
    setPointerLocation({
      mouseX: ev.clientX,
      mouseY: ev.clientY,
    })
  }

  const handleContextMenuClose = () => {
    setSelectedHeilmittelverordnung(null)
    setPointerLocation({
      mouseX: null,
      mouseY: null,
    })
  }

  const _toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const tableColumns = [
    {
      label: 'Datum',
      className: 'columnHeader',
    },
    {
      label: 'Indikation',
      className: 'columnHeader',
    },
    {
      label: 'Folgeverordnung',
      className: 'columnHeader',
    },
    {
      label: 'terminiert/verordnet',
      className: 'columnHeader',
    },
    {
      label: 'Arzt',
      className: 'columnHeader',
    },
    {
      label: 'Abgerechnet',
      className: 'columnHeader',
    },
  ]

  if (!customer || !doctors || !heilmittelverordnungen || !heilmittelverordnungenIds) {
    return null
  }

  const filteredHeilmittelverordnungenIds = heilmittelverordnungenIds.filter(
    (key) => heilmittelverordnungen[key] && heilmittelverordnungen[key].patient === customer.id,
  )
  let rowsRendered = 0

  return (
    <>
      <StyledHVOListView>
        <TableHeader cols={tableColumns} />
        <TableBody className="TableBody">
          {filteredHeilmittelverordnungenIds
            .map((id) => heilmittelverordnungen[id])
            .sort(sortByAusstellungsdatumDescending)
            .map((hvoSingular) => {
              const arzt = doctors[hvoSingular.arzt]
              let countTermineNichtAbgesagt = 0
              let addAnpassungBilateralCount = false

              const kkLeistungenArray = Object.keys(leistungenKK).map((k) => leistungenKK[k])

              for (const terminId of hvoSingular.termine) {
                if (termine[terminId] && termine[terminId].absagegrund === null) {
                  if (hvoSingular?.nagelkorrektur) {
                    const leistungenForTermin = termine[terminId].leistungen.filter((l) => l?.istKKLeistung)
                    let foundKKLeistungen = []

                    leistungenForTermin.forEach((l) => {
                      const foundKKLeistung = kkLeistungenArray.find((k) => k?.positionsnummer === l?.positionsnummer)

                      if (foundKKLeistung) {
                        foundKKLeistungen.push(foundKKLeistung)
                      }

                      if (foundKKLeistung?.prescribedService) {
                        if (foundKKLeistung?.positionsnummer === 78210) {
                          if (!addAnpassungBilateralCount) {
                            addAnpassungBilateralCount = true
                            countTermineNichtAbgesagt++
                          }
                        } else {
                          countTermineNichtAbgesagt++
                        }
                      }
                    })
                  } else {
                    countTermineNichtAbgesagt++
                  }
                }
                
              }

              // temporary fix for old HVOs
              if (!hvoSingular.diagnosegruppe && !hvoSingular.leitsymptomatik) {
                hvoSingular.heilmittel = 78007
              }

              rowsRendered++
              if (!showAll && rowsRendered > limit) {
                return null
              }

              const rowClassName = highlightedHVO === hvoSingular.id ? 'TableRow highlightRow' : 'TableRow'

              let mouseEnter, mouseLeave
              if (setTermineHighlight) {
                mouseEnter = R.partial(setTermineHighlight, [hvoSingular.termine])
                mouseLeave = R.partial(setTermineHighlight, [undefined])
              }

              return (
                <TableRow
                  onTdClick={_handleItemClick}
                  className={rowClassName}
                  mouseEnter={mouseEnter}
                  mouseLeave={mouseLeave}
                  key={hvoSingular.id}
                  id={hvoSingular.id}
                  cols={[
                    {
                      data: format(hvoSingular.ausstellungsdatum, GERMAN_DATE_SHORT_YEAR_FNS),
                      className: 'columnContent',
                    },
                    {
                      data:
                        (leistungenKK[hvoSingular.heilmittel] &&
                          leistungenKK[hvoSingular.heilmittel].indikationsschluessel) ||
                        (!!hvoSingular.nagelkorrektur &&
                          `${hvoSingular.nagelkorrekturIndikation} (${hvoSingular.nagelkorrekturLokalisierung})`),
                      className: 'columnContent',
                    },
                    {
                      data: hvoSingular.folgeverordnung ? 'X' : '',
                      className: 'columnContent',
                    },
                    {
                      data: `${countTermineNichtAbgesagt}/${hvoSingular.verordnungsmenge}`,
                      className: 'columnContent',
                    },
                    {
                      data: [arzt?.titel, arzt?.nachname].filter(Boolean).join(' '),
                      className: 'columnContent',
                    },
                    {
                      data: hvoSingular.abgerechnet ? <CheckCircleIcon color="secondary" /> : '',
                      className: 'columnContent',
                    },
                  ]}
                />
              )
            })}
          {filteredHeilmittelverordnungenIds.length == 0 && (
            <TableRow
              className="TableRow"
              cols={[
                {
                  data: 'bisher keine',
                  className: 'columnContent showAll empty',
                  colSpan: 5,
                },
              ]}
            />
          )}
          {filteredHeilmittelverordnungenIds.length > limit && (
            <TableRow
              className="TableRow"
              onTdClick={_toggleShowAll}
              cols={[
                {
                  data: showAll ? 'weniger anzeigen' : 'alle anzeigen',
                  className: 'columnContent showAll',
                  colSpan: 6,
                },
              ]}
            />
          )}
        </TableBody>
      </StyledHVOListView>
      <ContextMenuHeilmittelverordnung
        selectedHeilmittelverordnung={selectedHeilmittelverordnung}
        pointerLocation={pointerLocation}
        onCloseFunc={handleContextMenuClose}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  heilmittelverordnungenIds: state.ids.heilmittelverordnungen,
  leistungenKK: state.leistungenKK,
  termine: state.entities.termine,
})

export default connect(mapStateToProps, null)(HVOListView)
