import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useEffect } from 'react'
import { RegisterOptions, useController } from 'react-hook-form'

interface SelectFieldOption<T = number | string> {
  label: string | React.ReactNode
  value: T
}

interface Props {
  control: any
  name: string
  label: string
  options: SelectFieldOption[]
  helperText?: string
  error?: boolean
  rules?: RegisterOptions
  className?: string
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  shouldUnregister?: boolean
  readOnly?: boolean
  onChange?: any
  onBlur?: any
}

const ControlledSelectField = ({
  control,
  name,
  rules = {},
  label,
  helperText,
  error,
  className,
  options = [],
  disabled,
  placeholder,
  fullWidth,
  defaultValue,
  shouldUnregister = false,
  readOnly = false,
  onChange,
  onBlur,
}: Props): JSX.Element => {
  const { field } = useController({ name, rules, control, shouldUnregister, defaultValue })

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    field.onChange(event.target.value)
    onChange && onChange(event.target.value)
  }

  const handleBlur = (event: React.FocusEvent<{ value: unknown }>) => {
    field.onBlur(event.target.value)
    onBlur && onBlur(event.target.value)
  }

  useEffect(() => {
    if (!Array.isArray(options)) {
      console.warn('Property "options" must be an array.')
    } else if (!options?.length) {
      console.warn('No options passed to SelectField. Please pass an array of options.')
    }
  }, [options])

  return (
    <FormControl disabled={disabled} className={className} error={error} fullWidth>
      <InputLabel>{rules?.required ? `${label} *` : label}</InputLabel>
      <Select
        {...field}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly}
        value={field.value}
        placeholder={placeholder}
        style={readOnly ? { pointerEvents: 'none' } : {}}
      >
        <MenuItem value="" key="no-value"></MenuItem>
        {options?.map((opt) => (
          <MenuItem value={opt.value} key={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default ControlledSelectField
