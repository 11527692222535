import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, stopAsyncValidation } from 'redux-form'
import isEmail from 'validator/lib/isEmail'
import * as patientenActions from '../../actions/customers'
import * as smsActions from '../../actions/phoneNumber'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { AutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import DateField from '../../shared/components/DateField/DateField'
import InputField from '../../shared/components/InputField/InputField'
import countryCodes from '../../shared/static-data/countryCodes'
import { trackError } from '../../shared/utils/trackjs'
import { asyncValidateCustomer, validateDateFieldNotInFuture } from '../../utils/validation'
import { fields } from '../CustomerForm/CustomerForm'
import { StyledCustomerFormSmall, StyledDialogPaper } from './StyledCustomerFormSmall'

const findCountryCallingCode = (countryCode: string): string | null => {
  const selected = countryCodes.find((country: Country) => country.code === countryCode);
  if (selected) {
    return selected.callingCode;
  }
  return null;
};

const CustomerFormSmall: FC<any> = ({
  open,
  actions,
  onChange,
  onRequestClose,
  submitting,
  asyncValidating,
  fields: {
    geschlecht,
    vorname,
    nachname,
    geburtsdatum,
    telefon,
    mobil,
    email,
    emailErinnerung,
    smsReminder,
    mobilCountry,
    callReminder,
  },
  busy,
  handleSubmit,
  praxisstammdaten,
}) => {
  useEffect(() => {
    const germanCode = '49';
    if (mobilCountry.value) {
      return;
    }

    const prefillByCode = praxisstammdaten.land;
    const code = prefillByCode ? findCountryCallingCode(prefillByCode) : germanCode;

    mobilCountry.onChange(`+${code}`);
  }, []);

  const autocompleteCountryCallingCodes = countryCodes.map((country) => ({
    value: country.callingCode,
    text: `+${country.callingCode}`,
  }))

  const onSubmit = async (data) => {
    if (submitting || asyncValidating) return

    try {
      const newCustomer = await actions.patienten.createCustomer(data, false)
      if (newCustomer?.result && newCustomer.result[0]) {
        onChange(newCustomer.result[0])
      } else {
        onChange(null)
      }
      onRequestClose()
    } catch (err) {
      console.log(err)
      trackError(err)
    }
  }

  return (
    <Dialog open={open} onClose={onRequestClose} disableBackdropClick PaperComponent={StyledDialogPaper}>
      <DialogTitle>Patient neu anlegen</DialogTitle>
      <DialogContent>
        <StyledCustomerFormSmall onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="container">
            <FormControl component="fieldset">
              <RadioGroup {...geschlecht} aria-label="Geschlecht" name="geschlecht" row={true}>
                <FormControlLabel value="female" control={<Radio />} label="weiblich" />
                <FormControlLabel value="male" control={<Radio />} label="männlich" />
                <FormControlLabel value="trans" control={<Radio />} label="divers" />
              </RadioGroup>
              {!!geschlecht.touched && !!geschlecht.error ? (
                <FormHelperText error> {geschlecht.error}</FormHelperText>
              ) : null}
            </FormControl>
            <InputField
              {...nachname}
              label="Nachname *"
              helperText={!!nachname.touched && !!nachname.error ? nachname.error : ''}
              error={!!nachname.touched && !!nachname.error}
              InputProps={{ autoComplete: 'off' }}
              onKeyUp={() => actions.form.clearAsyncErrors()}
            />
            <InputField
              {...vorname}
              label="Vorname (*)"
              helperText={!!vorname.touched && !!vorname.error ? vorname.error : ''}
              error={!!vorname.touched && !!vorname.error}
              onKeyUp={() => actions.form.clearAsyncErrors()}
            />
            <DateField
              {...geburtsdatum}
              label="Geburtsdatum"
              disableFuture
              helperText={!!geburtsdatum.touched && !!geburtsdatum.error ? geburtsdatum.error : ''}
              error={!!geburtsdatum.touched && !!geburtsdatum.error}
              onChange={(date) => {
                geburtsdatum.onChange(date)
                geburtsdatum.onBlur(date)
              }}
              onKeyUp={() => actions.form.clearAsyncErrors()}
            />
            <InputField
              {...telefon}
              label={callReminder.value ? 'Telefon *' : 'Telefon (*)'}
              InputProps={{ autoComplete: 'off' }}
              helperText={!!telefon.touched && !!telefon.invalid && !!telefon.error ? telefon.error : ''}
              error={!!telefon.touched && !!telefon.invalid && !!telefon.error}
            />
            <InputField
              {...mobil}
              label={smsReminder.value ? 'Mobil *' : 'Mobil (*)'}
              InputProps={{ autoComplete: 'off' }}
              helperText={!!mobil.touched && !!mobil.invalid && !!mobil.error ? mobil.error : ''}
              error={!!mobil.touched && !!mobil.invalid && !!mobil.error}
              onKeyUp={() => actions.form.clearAsyncErrors()}
            />
            <AutocompleteField
              {...mobilCountry}
              label={`${smsReminder.value || callReminder.value ? 'Ländervorwahl *' : 'Ländervorwahl (*)'}`}
              autoComplete={'new-password'}
              options={autocompleteCountryCallingCodes}
              error={!!mobilCountry.error}
              helperText={!!mobilCountry.error && mobilCountry.error}
              onChange={(val: string) => {
                const currentCountry = countryCodes.find((country) => country.callingCode === String(val))
                if (currentCountry) {
                  mobilCountry.onChange(currentCountry.callingCode)
                } else {
                  mobilCountry.onChange(null)
                }
              }}
            />
            <InputField
              {...email}
              label={emailErinnerung.value ? 'E-Mail *' : 'E-Mail (*)'}
              helperText={!!email.touched && !!email.error ? email.error : ''}
              error={!!email.touched && !!email.error}
              InputProps={{ autoComplete: 'off' }}
            />
            <CheckboxField
              {...smsReminder}
              label="Terminerinnerung per SMS erwünscht?"
              onChange={(e) => {
                actions.form.clearAsyncErrors()
                smsReminder.onChange(e)
                smsReminder.onBlur(e)
              }}
            />
            <CheckboxField
              {...callReminder}
              label="Terminerinnerung per Festnetz-Anruf erwünscht?"
              onChange={(e) => {
                actions.form.clearAsyncErrors()
                callReminder.onChange(e)
                callReminder.onBlur(e)
              }}
            />
            <CheckboxField {...emailErinnerung} label="Terminerinnerung per E-Mail erwünscht?" />
            <div>
              <Button variant="contained" type="button" onClick={onRequestClose} color="secondary">
                Abbrechen
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={busy || submitting}
                color="secondary"
                style={{ float: 'right' }}
              >
                Patient anlegen
              </Button>
            </div>
          </div>
        </StyledCustomerFormSmall>
      </DialogContent>
    </Dialog>
  )
}

const validate = (values, props) => {
  const requiredFields = ['nachname', 'geschlecht']
  const errors: KeyValue<string> = {}
  const { emailErinnerung } = values

  if (emailErinnerung) {
    requiredFields.push('email')
  }

  requiredFields.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!'
    }
  })

  if (values.email && !errors.email && !isEmail(values.email)) {
    errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein!'
  }

  if (values.smsReminder) {
    if (!values.mobilCountry) {
      errors.mobilCountry = 'Bitte ausfüllen!'
    }
    if (!values.mobil) {
      errors.mobil = 'Bitte ausfüllen!'
    }
  }

  if (values.callReminder) {
    if (!values.mobilCountry) {
      errors.mobilCountry = 'Bitte ausfüllen!'
    }
    if (!values.telefon) {
      errors.telefon = 'Bitte ausfüllen!'
    }
  }

  return {
    ...errors,
    ...validateDateFieldNotInFuture(values, props, ['geburtsdatum']),
  }
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  praxisstammdaten: praxisstammdatenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    patienten: bindActionCreators(patientenActions, dispatch),
    sms: bindActionCreators(smsActions, dispatch),
    form: {
      clearAsyncErrors: () => dispatch(stopAsyncValidation('customerFormSmall', { clear: true })),
    },
  },
})

const CustomerFormSmallWithRedux = reduxForm(
  {
    form: 'customerFormSmall',
    validate,
    asyncValidate: asyncValidateCustomer,
    fields,
    asyncBlurFields: ['vorname', 'nachname', 'geburtsdatum', 'mobil', 'mobilCountry', 'smsReminder', 'callReminder'],
  },
  mapStateToProps,
  mapDispatchToProps,
)(CustomerFormSmall)

export default (props) => {
  const params = useParams()
  return <CustomerFormSmallWithRedux params={params} {...props} />
}
