import { Check as CheckIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { ContextMenuAnamnese } from '../../components/MenuAnamnese'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { sortByDateFieldDescending } from '../../shared/utils/dates'

import TableBody from '../../components/TableBody/TableBody'
import TableHeader from '../../components/TableHeader/TableHeader'
import TableRow from '../../components/TableRow/TableRow'
import { StyledAnamnesisListView } from './StyledAnamnesisListView'

interface Props {
  anamnesisIds?: number[],
  anamnesis?,
  customer?,
  people?,
  dialogActions?
}

const AnamnesisListView: FC<Props> = ({ anamnesisIds = [], anamnesis, customer, people, dialogActions }) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [pointerLocation, setPointerLocation] = useState<any>({ mouseX: null, mouseY: null })
  const [selectedAnamnesis, setSelectedAnamnesis] = useState<Anamnesis | null>(null)

  const toggleShowAll = useCallback(() => {
    setShowAll(!showAll)
  }, [setShowAll, showAll])

  const handleItemClick = useCallback(
    (e, { id }) => {
      e.persist()
      const anamnese = anamnesis[id]
      setSelectedAnamnesis(anamnese)
      setPointerLocation({ mouseX: e.clientX, mouseY: e.clientY })
    },
    [setSelectedAnamnesis, setPointerLocation, anamnesis],
  )

  const closeContextMenu = useCallback(() => {
    setPointerLocation({ mouseX: null, mouseY: null })
    setSelectedAnamnesis(null)
  }, [setPointerLocation, setSelectedAnamnesis ])

  const anamnesenDiesesPatienten = useMemo(() => anamnesisIds
    .map((id) => anamnesis[id])
    .filter((anamnese) => anamnese && anamnese.patient === customer.id)
    .sort(sortByDateFieldDescending('createdAt')), [anamnesisIds])

  let rowsRendered = 0

  const tableColumns = [
    {
      label: 'Datum',
      className: 'columnHeader'
    },
    {
      label: 'Diab.',
      className: 'columnHeader'
    },
    {
      label: 'Angelegt von',
      className: 'columnHeader'
    },
    {
      label: 'Abgeschlossen',
      className: 'columnHeader'
    },
  ];

  return (
    <>
        <StyledAnamnesisListView>
          <TableHeader cols={tableColumns} />
          <TableBody>
            {anamnesenDiesesPatienten.map(({ abgeschlossen, id, createdAt, modifiedBy }) => {
              rowsRendered++

              if (!showAll && rowsRendered > 1) {
                return null
              }

              const patientAnamnesenIds = Object.keys(anamnesis)
                .filter((key) => anamnesis[key].patient === customer.id)
                .map(id => Number(id));

              let diabetesTyp = null
              let diabetes = null
              if (patientAnamnesenIds.length > 0) {
                const letzteAnamnese = anamnesis[Math.max(...patientAnamnesenIds)]
                diabetesTyp = letzteAnamnese.dfTyp
                diabetes = letzteAnamnese.dfDiabetiker
              }

              let diabetesText = ''
              if (diabetes === false) {
                diabetesText = 'Nein'
              } else if (diabetesTyp === 'typ1') {
                diabetesText = 'Typ 1'
              } else if (diabetesTyp === 'typ2') {
                diabetesText = 'Typ 2'
              } else if (diabetes === true) {
                diabetesText = 'Ja'
              }

              return (
                <TableRow
                  key={id}
                  id={id}
                  className="TableRow"
                  onTdClick={handleItemClick}
                  cols={[
                    {
                      data: format(createdAt, GERMAN_DATE_SHORT_YEAR_FNS),
                      className: 'columnContent'
                    },
                    {
                      data: diabetesText,
                      className: 'columnContent'
                    },
                    {
                      data: people[modifiedBy] && [
                          people[modifiedBy].vorname, people[modifiedBy].nachname
                        ].filter(Boolean).join(' '),
                      className: 'columnContent'
                    },
                    {
                      data: abgeschlossen ? <CheckIcon /> : '',
                      className: 'columnContent'
                    },
                  ]}
                >
                </TableRow>
              )
            })}
            {anamnesenDiesesPatienten.length == 0 && (
              <TableRow
                className="TableRow"
                cols={[
                  {
                    data: 'bisher keine',
                    className: "columnContent showAll empty",
                    colSpan: 4,
                  },
                ]}
              />
            )}
            {anamnesenDiesesPatienten.length > 1 && (
              <TableRow
                className="TableRow"
                onTdClick={toggleShowAll}
                cols={[
                  {
                    data: showAll ? 'nur letzte anzeigen' : 'frühere anzeigen',
                    className: "columnContent showAll",
                    colSpan: 4,
                  },
                ]}
              />
            )}
          </TableBody>
      </StyledAnamnesisListView>
      <ContextMenuAnamnese
        anamnese={selectedAnamnesis}
        pointerLocation={pointerLocation}
        handleClose={closeContextMenu}
        dialogActions={dialogActions}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  anamnesisIds: state.ids.anamnesen,
})

export default connect(mapStateToProps)(memo(AnamnesisListView))
