import { SvgIcon, type SvgIconProps } from '@material-ui/core'

const PododeskIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 72.8 37.3"
    enableBackground="new 0 0 72.8 37.3"
    {...props}
  >
    <path d="M17.8,25.9l-1.1,0.9c-0.9,0.6-1.9,0.9-3,0.8c-0.5-0.1-1-0.1-1.4-0.2L0.9,25.5v-2.4l11.6,2c1.4,0.2,1.9,0.4,2.6-0.1l0.6-0.5 l0.4-0.3c0.8-0.6,2.2-0.1,2.1,0.9C18.2,25.4,17.9,25.8,17.8,25.9z"></path>
    <path d="M17.8,30.2l-1.1,0.9c-0.9,0.6-1.9,0.9-3,0.8c-0.5-0.1-1-0.1-1.4-0.2L1,29.8v-2.4l11.6,2c1.4,0.2,1.9,0.4,2.6-0.1l0.6-0.5 l0.4-0.3c0.8-0.6,2.2-0.1,2.1,0.9C18.2,29.8,17.9,30.1,17.8,30.2z"></path>
    <path d="M22.1,26.7l11.6,2l10,1.7c1.5,0.3,2.5,0.3,3-0.1l3.8-3l6.1-4.8L72.5,9.9v3L53.8,27.6L48.1,32c-0.7,0.5-1.9,0.7-3,0.7 c-0.5,0-1,0-1.4-0.1l-15-2.6l-6.9-1c-1.3-0.2-2.2-0.8-2.5-1.4c-0.1-0.2-0.5-1.2,0.3-2c0.3-0.3,0.8-0.6,1.5-0.2 c0.3,0.2,0.5,0.6,0.2,1.1"></path>
    <path d="M22.1,31.2l11.6,2l10,1.7c1.5,0.3,2.5,0.3,3-0.1l3.8-3l6.1-4.8l15.9-12.6v3L53.8,32.1l-5.7,4.4c-0.7,0.5-1.9,0.7-3,0.7 c-0.5,0-1,0-1.4-0.1l-15-2.6l-6.9-1c-1.3-0.2-2.2-0.8-2.5-1.4c-0.1-0.2-0.5-1.2,0.3-2c0.3-0.3,0.8-0.6,1.5-0.2 c0.3,0.2,0.5,0.6,0.2,1.1"></path>
    <path d="M52.7,4.5l-0.9-0.1L29.5,0.6c-1.4-0.2-2.9,0.1-4,1L0.9,20.7l12.9,2.2c0.8,0.1,1.6-0.1,2.2-0.6l12.8-10l12,2 c1.4,0.2,2.8-0.1,3.9-1L54,6.1C54.7,5.5,54.2,4.8,52.7,4.5z"></path>
    <path d="M20.7,24.1l0.8,0.1L43.8,28c1.4,0.2,2.9-0.1,4-1L72.3,7.9L59.4,5.7c-0.8-0.1-1.6,0.1-2.2,0.6l-12.8,10l-12-2 c-1.4-0.2-2.8,0.1-3.9,1l-9.3,7.2C18.7,23.2,19.2,23.9,20.7,24.1z"></path>
  </SvgIcon>
)

export default PododeskIcon
