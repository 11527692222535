import styled from 'styled-components';

export const StyledPrettyTime = styled.span`
  display: inline;

  .hours {
    font-family: inherit;
  }

  .minutes {
    vertical-align: super;
    font-size: xx-small;
  }
`;
