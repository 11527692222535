import { Button, Dialog, DialogActions, DialogContent, Paper } from '@material-ui/core'
import { ChangeEvent, FC, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showNotification } from '../../actions/notification'
import Cropper from '../../components/Cropper/Cropper'
import { IMAGE_MIME_TYPES } from '../../constants/misc'
import { dataURLfromArrayBuffer } from '../../shared/utils/dataFormats'
import { readFile } from '../../shared/utils/file'

interface DefaultCrop {
  aspect?: number
  width?: number
  x?: number
  y?: number
}

const defaultCrop: DefaultCrop = {
  aspect: 1,
  width: 20,
  x: 7,
  y: 10,
}

interface Props {
  onChange: (data: any) => void,
  value?: string,
  actions?: any
}

const LogoCropper: FC<Props> = ({onChange, value, actions}) => {

  const [newFile, setNewFile] = useState<any>(null)
  const [cropDialogOpen, setCropDialogOpen] = useState<boolean>(false)
  const [crop, setCrop] = useState<DefaultCrop | boolean>(defaultCrop)

  const inputRef = useRef<HTMLInputElement>(null)
  const cropperRef = useRef<Cropper>(null)

  const clearFileInput = (): void => {
    inputRef.current && (inputRef.current.value = '');
  }

  const handleFiles = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files: FileList = ((target as HTMLInputElement)?.files) as FileList;
    if(!files || files.length <= 0) {
      clearFileInput();
      return;
    }

    const file = files.item(0);
    if(!file) {
      clearFileInput();
      return;
    }

    if(!IMAGE_MIME_TYPES.find(item => item === file.type)) {
      actions.showNotification('Wrong file type');
      clearFileInput();
      return;
    }

    try {
      const readFileResult = await readFile(file);
      if(readFileResult) {
        setNewFile(dataURLfromArrayBuffer(readFileResult, file.type))
        setCropDialogOpen(true)
      }
    } catch (error) {
      console.log(error)
      actions.showNotification('Error load');
      setCropDialogOpen(false);
    }
    clearFileInput();
  }

  const closeCropDialog = () => {
    setCropDialogOpen(false)
    setCrop(defaultCrop)
  }

  const saveCrop = () => {
    const logoSize = 256

    const croppedFile = cropperRef.current?.cropper?.getCroppedCanvas({
        width: logoSize,
        height: logoSize,
      })
      .toDataURL('image/png')

    closeCropDialog()

    onChange && onChange(croppedFile)
  }

  const deleteImage = () => {
    onChange && onChange(null)
  }

  return (<>
    <Paper
      style={{
        padding: 30,
        width: 400,
        marginBottom: '15px',
        justifySelf: 'center'
      }}
    >
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        accept={IMAGE_MIME_TYPES.join(',')}
        onChange={handleFiles}
      />

      {value ? (
        <img src={value} style={{ border: '1px solid black', width: '100%', marginBottom: '15px' }} />
      ) : (
        <div style={{ height: 90 }}>
          <span>kein Logo gewählt</span>
          {/* no logo chosen */}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginTop: '5px'
        }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={deleteImage}
          disabled={!value}
        >
          Logo löschen {/* Delete logo */}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => inputRef.current?.click()}>
          Logo wählen {/* Choose logo */}
        </Button>
      </div>
    </Paper>

    <Dialog disableBackdropClick open={cropDialogOpen}>
      <DialogContent>
        <span style={{ paddingBottom: '15px', display: 'block' }}>
          Bitte wählen Sie mit der Maus den Ausschnitt aus Ihrem Bild, den Sie als Praxislogo verwenden möchten.
        </span>
        <Cropper
          ref={cropperRef}
          src={newFile}
          aspectRatio={1}
          onChange={() => {
            setCrop(true)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ marginRight: '10px'}}
          variant="text"
          color="secondary"
          onClick={closeCropDialog}>
          Abbrechen {/* Abort */}
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={saveCrop}
          disabled={!crop}>
          Übernehmen {/* Take */}
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showNotification: bindActionCreators(showNotification, dispatch),
  },
})

export default connect(null, mapDispatchToProps)(LogoCropper)
