import { FC, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as autocompleteActions from '../../actions/autocomplete'
import InputField from '../../shared/components/InputField/InputField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import absagegruende from './absagegruende'

export const fields = ['absagegrund', 'absagedatum', 'abgewandertZu', 'termin', 'notizen']

interface Props {
  autocomplete: any,
  actions: any,
  handleSubmit: any,
  fields: any
}

const TerminAbsageForm: FC<Props> = ({
  autocomplete,
  actions,
  handleSubmit,
  fields: { absagegrund, abgewandertZu, notizen }
}) => {
  useEffect(() => {
    actions.autocomplete.loadAutocomplete({
      patienten: ['abgewandertZu', 'bisherigePodologie'],
    })
  }, [])

  const [praxisAutocomplete, setPraxisAutocomplete] = useState<Array<AutocompleteOption>>([])

  useEffect(() => {
    const newPraxisAutocomplete: Array<AutocompleteOption> = Array.from(
      new Set([
        ...(autocomplete?.patienten?.abgewandertZu ?? []),
        ...(autocomplete?.patienten?.bisherigePodologie ?? [])
      ])
    ).map(value => ({text: value, value}));

    setPraxisAutocomplete(newPraxisAutocomplete)
  }, [autocomplete])

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <SelectField
        {...absagegrund}
        fullWidth
        label="Absagegrund"
        error={absagegrund.touched && !!absagegrund.error}
        helperText={absagegrund.touched && !!absagegrund.error && absagegrund.error}
        options={absagegruende.map((grund) => {
          return {text: grund.text, value: grund.id}
        })}
      />

      {/* if absagegrund value 1 or 4 - show Notiz */}
      {
        [1, 4].indexOf(absagegrund.value) !== -1 && (
            <InputField  {...notizen} multiline label="Notiz" />
          )
      }

      {/*if absagegrund value 2 - show autocomplete */}
      {absagegrund.value === 2 && (
        <InputField
          {...abgewandertZu}
          label="neue Praxis"
          error={!!abgewandertZu.touched && !!abgewandertZu.error}
          helperText={!!abgewandertZu.touched && !!abgewandertZu.error? abgewandertZu.error: ''}
        />
      )}
    </form>
  )
}

const validate = (values) => {
  const errors: KeyValue<string> = {}

  if (!values.absagegrund) {
    errors.absagegrund = 'Bitte wählen Sie einen Absagegrund!'
  }

  if (values.absagegrund === 2 && !values.abgewandertZu) {
    errors.abgewandertZu = 'Bitte nennen Sie die neue Praxis!'
  }

  return errors
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  autocomplete: state.autocomplete,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    autocomplete: bindActionCreators(autocompleteActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'terminAbsageForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(TerminAbsageForm)
