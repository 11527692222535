import styled from 'styled-components'

export const StyledCredentialsPage = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;

    .credentialsIconHeading {
      width: 100%;
    }

    .credentialsPaperContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${theme.spacing(8)}px;
      grid-auto-rows: min-content;
      padding: ${theme.spacing(4)}px ${theme.spacing(8)}px;

      ${theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
      }
    }
  `}
`
