import escapeRegExp from 'lodash/escapeRegExp'

export function filterEntity(entityArray, searchText, searchFields) {
  if (!searchText) {
    return []
  }
  const escapedSearchText = escapeRegExp(searchText.toLowerCase().trim())
  const searchExpression = new RegExp(`^.*${escapedSearchText}.*$`)
  return entityArray.filter((entityElement) => {
    const fieldsToSearchIn = searchFields.map((field) => entityElement[field])
    const textToSearchIn = fieldsToSearchIn.join(' ').toLowerCase()
    return searchExpression.test(textToSearchIn)
  })
}

export function filterText(entityArray, searchText, searchFields) {
  if (!searchText) {
    return []
  }

  return entityArray.filter((entityElement) => {
    // const fieldsToSearchIn = searchFields.map((field) => entityElement[ field ])
    // const textToSearchIn = fieldsToSearchIn.join(' ').toLowerCase()
    // return searchExpression.test(textToSearchIn)
    const fieldsToSearchIn = searchFields.map((field) => {
      if (entityElement[field]) return entityElement[field].replace(/\n/g, ' ').replace(/\s\s+/gi, ' ')
    })
    const textToSearchIn = fieldsToSearchIn.join(' ').toLowerCase()
    //return searchExpression.test(textToSearchIn)
    return textToSearchIn.indexOf(searchText.toLowerCase()) !== -1
  })
}
export function getMatchingSearchFields(entityObject, searchText, searchFields, searchFieldsToHide) {
  if (!searchText) {
    return []
  }
  const fieldsToSearch = searchFields.filter((field) => searchFieldsToHide.indexOf(field) === -1)
  // const escapedSearchText = escapeRegExp(searchText.toLowerCase().trim())
  // const searchExpression = new RegExp(`^.*${escapedSearchText}.*$`)
  // const matchingSearchfields = Object.keys(entityObject)
  //   .map((key) => {
  //     return { key, value: entityObject[ key ] }
  //   })
  //   .filter((field) => {
  //     if (fieldsToSearch.indexOf(field.key) === -1 || !field.value) {
  //       return false
  //     }
  //     return searchExpression.test(field.value.toLowerCase().trim())
  //   })
  // const matchingSearchFieldsAsString = []
  // for (const field of matchingSearchfields) {
  //   matchingSearchFieldsAsString.push(`${field.key.split('').map((char, idx) => idx === 0 ? char.toUpperCase() : char).join('')}: ${field.value}`)
  // }
  // return matchingSearchFieldsAsString

  let searchString = ''
  for (let length = fieldsToSearch.length, i = 0; i < length; i++) {
    const searchStore = entityObject[fieldsToSearch[i]] || ''
    if (
      searchStore.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 &&
      searchFieldsToHide.indexOf(fieldsToSearch[i]) === -1
    ) {
      searchString = `${fieldsToSearch[i].charAt(0).toUpperCase() + fieldsToSearch[i].slice(1)}: ${
        entityObject[fieldsToSearch[i]]
      }`
      //searchString = entityObject[fieldsToSearch[i]];
      break
    }
  }
  return searchString.replace(/([A-Z])/g, ' $1').trim()
  // const foundString = fieldsToSearch.filter((item)=>{
  // })
}

export function autoCompletePatientenFilter(searchText, key) {
  // Dieser Filter sorgt dafür dass in Nachname und Vorname gesucht wird
  // Die Strings müssen vom ersten Buchstaben an übereinstimmen.
  // key Format: 'Nachname, Vorname *Geburtsdatum'
  const keyArr = key.split(' *')
  const textToSearch = keyArr[0].replace(',', '')
  return textToSearch.toLowerCase().indexOf(searchText.toLowerCase().replace(',', '')) === 0
}
