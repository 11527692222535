import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export const AddDateDialogStyled = styled(Dialog)`
${({isSnackbarOpen }: {isSnackbarOpen: boolean }) => `
  .MuiDialog-container {
      height:  ${isSnackbarOpen ? '85%;' : '100%;'}
      mask-image: ${isSnackbarOpen ? 'linear-gradient(180deg, #fff 97%, #0000);' : 'none;'} 
  }
`}
`;
