/* Dan Abramovs great example from (http://rackt.org/redux/docs/recipes/ReducingBoilerplate.html),
 * to be used in action creators like this one:
 *
 export function addComment(postId, message) {
 return {
 types: ['ADD_COMMENT_REQUEST', 'ADD_COMMENT_SUCCESS', 'ADD_COMMENT_FAILURE'],
 callAPI: () => fetch(`http://myapi.com/posts/${postId}/comments`, {
 method: 'post',
 headers: {
 'Accept': 'application/json',
 'Content-Type': 'application/json'
 },
 body: JSON.stringify({ message })
 }),
 payload: { postId, message },
 };
 }

 */
import { Navigate } from 'react-router-dom'
import { apiServerHashSelector } from '../../selectors/selectors'
import { sCredentials } from '../../shared/utils/auth'
import { getApiUrl, getAuthUrl } from '../../utils/auth'
import { logout } from '../modules/auth'
import { userManager } from '../../containers/AuthProvider/utils'

// TODO rename requires auth - they are calls that also require apiServerSelected as well as auth
export default function callAPIMiddleware({ dispatch, getState }) {
  return function curryCallAPI(next) {
    return async function doCallAPI(action) {
      const { types, callAPI, requiresAuth = false, authServer = false, payload = {} } = action

      if (!types) {
        // Normal action: pass it on
        return next(action)
      }

      if (!Array.isArray(types) || types.length !== 3 || !types.every((type) => typeof type === 'string')) {
        throw new Error('Expected an array of three string types.')
      }

      if (typeof callAPI !== 'function') {
        throw new Error('Expected callAPI to be a function.')
      }
      const [requestType, successType, failureType] = types

      const state = getState()

      console.log('requiresAuth during callAPI: ', requiresAuth)
      console.log('payload during callAPI: ', payload)
      console.log('requestType during callAPI: ', requestType)

      console.log('state during callAPI: ', state)

      const loggedIn = !!sCredentials()
      console.log('loggedIn during callAPI: ', loggedIn)
      const serverHash = apiServerHashSelector(state)
      console.log('serverHash during callAPI: ', serverHash)

      if (requiresAuth && !loggedIn) {
        console.log('requiresAuth and not logged in')
        console.log('trying silent signin')
        
        try {
          
          await userManager.signinSilent()
        } catch (err) {
          console.log('Silent signin failed, doing signinRedirect instead')
          console.log('setting login redirect url in callAPIMiddleware: ', window.location.pathname)
          window.localStorage.setItem('login_redirect_url', window.location.pathname)
          await userManager.signinRedirect()
        }
        // TODO try renegotiate token if it expired
        // dispatch(logout())
      }
      if (!authServer && !serverHash) {
        console.log('!authServer and !serverHash')
        console.log(types)
        throw new Error('Trying to talk to API-Server without specifying which one.')
      }
      console.log('both conditions passed')
      // TODO temp fix
      const auth0Credentials = requiresAuth ? sCredentials(state) : undefined

      console.log('auth0Credentials during callAPI: ', auth0Credentials)

      // wait for state change so user sees the new state
      await dispatch(
        Object.assign({}, payload, {
          type: requestType,
        }),
      )

      const apiUrl = getApiUrl(apiServerHashSelector(state))
      console.log('apiUrl during callAPI: ', apiUrl)
      const authUrl = getAuthUrl()
      console.log('authUrl during callAPI: ', authUrl)
      const { selectedApiServer } = state.auth
      console.log('selectedApiServer during callAPI: ', selectedApiServer)

      // do api call
      try {
        console.log('callAPI try block')
        const response = await callAPI({
          apiUrl,
          authUrl,
          dispatch,
          getState,
          auth0Credentials,
          loggedIn: !!sCredentials(),
          serverHash,
        })

        console.log('response in callAPI: ', response)
        console.log('response status in callAPI: ', response?.status)
        console.log('typeof response.status in callAPI: ', typeof response?.status)

        if (response.status === 401 && loggedIn) {
          console.log('response status 401 and logged in')
          // TODO: If comment code in condition -> still on auth required pages
          window.localStorage.removeItem('access_token')
          window.sessionStorage.setItem('login_redirect_path', window.location.pathname)
          return <Navigate to="/login" />
        }

        console.log('response status not 401 and loggedin')

        if (!response.ok) {
          console.log('response not ok')
          throw new Error(response.statusText)
        }

        let json = false
        // 204 "No Content"
        if (response.status !== 204) {
          console.log('response status not 204')
          json = await response.json()
        }

        console.log('dispatching in callAPI')
        dispatch(
          Object.assign({}, payload, {
            response: response,
            json: json,
            type: successType,
            requiresAuth,
            selectedApiServer,
          }),
        )

        console.log('after dispatch in callAPI')

        return json
      } catch (error) {
        console.log(error)
        dispatch(
          Object.assign({}, payload, {
            error: error,
            type: failureType,
          }),
        )
      }
    }
  }
}
