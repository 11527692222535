import makeActionCreator from '../../shared/redux/utils/makeActionCreator'

const OPEN_DIALOG = 'pod001_frontend/formLeaveConfirmation/OPEN_DIALOG'
const CLOSE_DIALOG = 'pod001_frontend/formLeaveConfirmation/CLOSE_DIALOG'
export const CONTINUE_NAVIGATION = 'pod001_frontend/formLeaveConfirmation/CONTINUE_NAVIGATION'

const initialState = {
  dialogOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        dialogOpen: true,
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false,
      }
    default:
  }
  return state
}

export const openDialog = makeActionCreator(OPEN_DIALOG)
export const closeDialog = makeActionCreator(CLOSE_DIALOG)
export const continueNavigation = makeActionCreator(CONTINUE_NAVIGATION)
