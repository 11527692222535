import { Camera as CameraIcon, Lock as LockIcon } from '@material-ui/icons';
import { FC, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Notification from '../../components/Notification/Notification';
import CredentialsPage from '../../shared/components/CredentialsPage/CredentialsPage';
import StyledLink from '../../shared/components/StyledLink/StyledLink';
import globalNavigation from '../../utils/navigation';
import Avatar from '../Avatar/Avatar';
import Layout from '../Layout/Layout';
import { StyledProfil } from './StyledProfil';

const Profil: FC<{}> = (): JSX.Element =>  {
  const navigate = useNavigate()

  useEffect(() => {
    globalNavigation.navigate = navigate
  }, [])

  useEffect(() => {
    document['title'] = 'pododesk';
  }, [])

  return (<>
    <Layout>
      <StyledProfil>
        <div className="main">
          <div className="topBar">
            <StyledLink isRelative fullWidth to="passwort" startIcon={<LockIcon fontSize="inherit" />} borderEnd>
              Passwort/PIN
            </StyledLink>
            <StyledLink isRelative fullWidth to="avatar" startIcon={<CameraIcon fontSize="inherit" />} borderStart>
              Avatar
            </StyledLink>
          </div>
          <Routes>
            <Route path="/passwort" element={<CredentialsPage />} />
            <Route path="/avatar" element={<Avatar />} />
            <Route path="*" element={<Navigate replace to="./passwort" />} />
          </Routes>
        </div>
      </StyledProfil>
    </Layout>
    <Notification />
  </>)
}

export default Profil;
