import { Tabs } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { FC, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectDay } from '../../actions/kassenbuch'
import { DatePickerToolbar } from '../../shared/components/DatePickerToolbar'
import { StyledTab, tabA11yProps, TabPanel } from '../../shared/components/Tabs'
import TodoStack from '../TodoStack/TodoStack'
import { StyledKassenbuchSidebar } from './StyledKassenbuchSidebar'

const KassenbuchSidebar: FC<any> = ({ actions, selectedDay }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleDayClick = (date) => {
    actions.selectDay(date)
  }

  return (
    <StyledKassenbuchSidebar>
      <DatePicker
        variant="static"
        value={selectedDay}
        onChange={handleDayClick}
        ToolbarComponent={DatePickerToolbar} />

      <Tabs
        variant="fullWidth"
        indicatorColor="secondary"
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <StyledTab label="Todo" {...tabA11yProps(0)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <TodoStack />
      </TabPanel>
    </StyledKassenbuchSidebar>
  )
}

const mapStateToProps = (state) => ({
  selectedDay: state.kassenbuch.selectedDay,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ selectDay }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(KassenbuchSidebar)
