import styled from 'styled-components';

export const StyledPatientText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;

  &.text-icon-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .ascii-icon { 
      line-height: 1;
      margin-left: 7px;
      font-size: 14px;
      
      &.female, .mail {
        font-weight: 900;
      }
    }
  }

  .icon {
    margin: 0 3px;
    &.xs-icon {
      margin: 0 5px;
      width: 0.8em;
    }
  }
`;
