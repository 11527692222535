import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { format, isSameDay, parse } from 'date-fns'
import { cloneDeep, merge } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as patientenActions from '../../actions/customers'
import { GERMAN_DATE_LONG_YEAR_FNS } from '../../constants/dateFormats'
import { CardReaderContext } from '../../containers/OverlayContainer/CardReaderContext'
import FemaleIcon from '../../shared/components/Gender/female.svg'
import MaleIcon from '../../shared/components/Gender/male.svg'
import TransgenderIcon from '../../shared/components/Gender/transgender.svg'
import { StyledShowCardDataDialog } from './StyledShowCardDataDialog'
import { SortedCardData } from '../../utils/cardreader/CardReaderInstance'

interface Props {
  patienten: any
  actions: any
}

const genderIcons = {
  male: <MaleIcon fontSize="inherit" color="inherit" />,
  female: <FemaleIcon fontSize="inherit" color="inherit" />,
  trans: <TransgenderIcon fontSize="inherit" color="inherit" />,
}

const comparePatientDataToCardData = (patientData: Patient, cardData: SortedCardData) => {
  const clonedCardData = cloneDeep(cardData)

  // @ts-ignore
  delete clonedCardData.krankenkasse
  // @ts-ignore
  delete clonedCardData.land

  let isSameData = true
  for (const key in clonedCardData) {
    if (!isSameData) break

    if (key === 'geburtsdatum') {
      if (!!clonedCardData[key]) {
        if (!patientData[key]) {
          isSameData = false
        } else if (format(clonedCardData[key], 'yyyy-MM-dd') !== patientData[key]) {
          isSameData = false
        }
      }
    } else {
      if (!!clonedCardData[key] && clonedCardData[key] !== patientData[key]) {
        console.log('clonedCardData[key]: ', clonedCardData[key])
        console.log('patientData[key]: ', patientData[key])
        isSameData = false
      }
    }
  }

  console.log('isSameData result: ', isSameData)

  return isSameData
}

const mergePatientDataWithCardData = (patientData: Patient, cardData: SortedCardData) => {
  const clonedCardData = cloneDeep(cardData)
  const newPatientData = merge({}, patientData)

  // @ts-ignore
  delete clonedCardData.krankenkasse
  // @ts-ignore
  delete clonedCardData.land

  for (const key in clonedCardData) {
    if (!!clonedCardData[key]) newPatientData[key] = clonedCardData[key]
  }

  return newPatientData
}

const ShowCardDataDialog = ({ patienten, actions }: Props): JSX.Element => {
  const { cardData, resetCardData } = useContext(CardReaderContext)
  const { serverHash } = useParams()
  const navigate = useNavigate()

  const [patientExactMatch, setPatientExactMatch] = useState<Patient | null>(null)
  const [patientsPossibleMatch, setPatientsPossibleMatch] = useState<Patient[] | null>(null)
  const [noMatchingPatients, setNoMatchingPatients] = useState<boolean>(false)
  const handleDialogClose = (): void => {
    resetCardData()
  }

  const getCustomerForData = async () => {
    const res = await actions.patienten.findCustomers(cardData?.versichertennummer, ['versichertennummer'])
    console.log('actions.patienten.findCustomers(cardData?.versichertennummer, [versichertennummer]): ', res)

    if (!!res?.result?.length) {
      const patient = res.entities.patienten[res.result[0]]
      setNoMatchingPatients(false)
      setPatientsPossibleMatch(null)
      setPatientExactMatch(patient)
    } else {
      setPatientExactMatch(null)
      getPossibleCustomersForData()
    }
  }

  const getPossibleCustomersForData = async () => {
    const res = await actions.patienten.findCustomers(cardData?.nachname, ['nachname'])
    console.log('DEBUG actions.patienten.findCustomers(cardData?.nachname, [nachname]): ', res)

    if (!res?.result?.length) {
      console.log('DEBUG NO RESULT getPossibleCustomersForData')
      setPatientExactMatch(null)
      setPatientsPossibleMatch(null)
      setNoMatchingPatients(true)
    } else if (res?.result?.length === 1) {
      // if only one patient is returned from findCustomers, check if rest of data is matching
      const matchedPatient = res.entities.patienten[res.result[0]]
      console.log('DEBUG matchedPatient: ', matchedPatient)
      const isDataMatch =
        (matchedPatient.vorname ? matchedPatient.vorname === cardData?.vorname : true) &&
        (matchedPatient.geburtsdatum ? isSameDay(parse(matchedPatient.geburtsdatum, 'yyyy-MM-dd', new Date()), cardData?.geburtsdatum) : true)

        console.log('DEBUG isDataMatch: ', isDataMatch)

      if (isDataMatch) {
        setNoMatchingPatients(false)
        setPatientExactMatch(null)
        setPatientsPossibleMatch([matchedPatient])
      } else {
        setPatientExactMatch(null)
        setPatientsPossibleMatch(null)
        setNoMatchingPatients(true)
      }
    } else {
      const matchedPatients = res?.result?.map((p) => res?.entities?.patienten[p])
      console.log('DEBUG matchedPatients: ', matchedPatients)
      const filteredPatientsByFirstName = matchedPatients.filter((p) => !p.vorname || p.vorname === cardData?.vorname)
      console.log('DEBUG filteredPatientsByFirstName: ', filteredPatientsByFirstName)

      if (!filteredPatientsByFirstName.length) {
        setPatientExactMatch(null)
        setPatientsPossibleMatch(null)
        setNoMatchingPatients(true)
      } else {
        const filteredPatientsByBirthDate = filteredPatientsByFirstName.filter((p) => {
          if (p.geburtsdatum) return isSameDay(parse(p.geburtsdatum, 'yyyy-MM-dd', new Date()), cardData?.geburtsdatum)
          return !p.geburtsdatum
        })
        console.log('DEBUG filteredPatientsByBirthDate: ', filteredPatientsByBirthDate)

        if (!filteredPatientsByBirthDate.length) {
          setPatientExactMatch(null)
          setPatientsPossibleMatch(null)
          setNoMatchingPatients(true)
        } else {
          setPatientExactMatch(null)
          setPatientsPossibleMatch(filteredPatientsByBirthDate)
          setNoMatchingPatients(false)
        }
      }
    }
  }

  useEffect(() => {
    console.log('DEBUG patientExactMatch changed: ', patientExactMatch)
  }, [patientExactMatch])

  useEffect(() => {
    console.log('DEBUG patientsPossibleMatch changed: ', patientsPossibleMatch)
  }, [patientsPossibleMatch])

  useEffect(() => {
    console.log('DEBUG noMatchingPatients changed: ', noMatchingPatients)
  }, [noMatchingPatients])

  const handleCreateNewPatient = async (): Promise<void> => {
    if (!cardData?.versichertennummer) return

    const patientDefaults = {
      patientSeit: new Date(),
      titel: null,
      telefon: null,
      mobil: null,
      email: null,
      emailErinnerung: false,
      emailNewsletter: false,
      hausarzt: null,
      facharzt: null,
      empfehlung: null,
      bisherigePodologie: null,
      abgewandertZu: null,
      bild: null,
      verstorben: false,
      notizen: null,
      dateien: null,
      sozialeGemeinschaft: null,
      patientennummer: null,
      regularTherapist: null,
      versichertenstatus: null,
    }

    const dataToSubmit = merge(patientDefaults, cardData)

    // @ts-ignore
    dataToSubmit.krankenkasse = null

    await actions.patienten.createCustomer(dataToSubmit, true)

    handleDialogClose()

    // if (res?.result?.[0]) {
    //   handleDialogClose()
    //   navigate(`/${serverHash}/contacts/customers/${res.result[0]}`)
    // }
  }

  const handleUpdatePatient = async (e: React.MouseEvent): Promise<void> => {
    const patientId = Number(e?.currentTarget?.id)

    console.log('patientId: ', patientId)

    if (!patientId || Number.isNaN(patientId)) {
      console.error('Missing patientId!')
      return
    }

    if (!cardData) {
      console.error('Missing cardData!')
      return
    }

    if (!patientExactMatch && (!patientsPossibleMatch || !patientsPossibleMatch?.length)) {
      console.error('No patientExactMatch and patientsPossibleMatch given!')
      return
    }

    console.log('patientExactMatch: ', patientExactMatch)
    console.log('patientsPossibleMatch: ', patientsPossibleMatch)

    if (patientExactMatch !== null && patientExactMatch.id === patientId) {
      console.log('----- Start update patient exact match -----')
      const hasNoDataDifferences = comparePatientDataToCardData(patientExactMatch, cardData as SortedCardData)

      console.log('hasNoDataDifferences: ', hasNoDataDifferences)

      if (!hasNoDataDifferences) {
        console.log('----- difference in data found -----')
        const dataToSubmit = mergePatientDataWithCardData(patientExactMatch, cardData as SortedCardData)

        const res = await actions.patienten.updateCustomer(dataToSubmit, true)
        console.log('update patientExactMatch res: ', res)
      }

      console.log('----- Finish update patient exact match -----')

      navigate(`/${serverHash}/contacts/customers/${patientId}`)
    }

    if (!!patientsPossibleMatch?.length) {
      const possiblePatientSelected = patientsPossibleMatch.find((p) => p.id === patientId)

      if (!possiblePatientSelected) {
        console.error(`Could not find possibly matching patient with id ${patientId}!`)
        return
      } else {
        console.log('----- Start update patient possible match -----')
        const hasNoDataDifferences = comparePatientDataToCardData(possiblePatientSelected, cardData as SortedCardData)
  
        console.log('hasNoDataDifferences: ', hasNoDataDifferences)
  
        if (!hasNoDataDifferences) {
          console.log('----- difference in data found -----')
          const dataToSubmit = mergePatientDataWithCardData(possiblePatientSelected, cardData as SortedCardData)
  
          const res = await actions.patienten.updateCustomer(dataToSubmit, true)
          console.log('update patientsPossibleMatch res: ', res)
        }
        console.log('----- Finish update patient possible match -----')

        navigate(`/${serverHash}/contacts/customers/${patientId}`)
      }
    }


    handleDialogClose()
  }

  useEffect(() => {
    if (!!cardData) {
      getCustomerForData()
    }
  }, [cardData])

  return (
    <StyledShowCardDataDialog open={!!cardData}>
      <DialogTitle>Ausgelesene eGK-Daten</DialogTitle>
      <DialogContent className="dialogContent">
        <List>
          {!!patientExactMatch && (
            <ListItem
              id={patientExactMatch.id.toString()}
              button
              onClick={handleUpdatePatient}
            >
              <ListItemIcon>{genderIcons[patientExactMatch.geschlecht]}</ListItemIcon>
              <ListItemText
                primary={`${patientExactMatch.titel ? `${patientExactMatch.titel} ` : ''}${patientExactMatch.nachname}${
                  patientExactMatch.vorname ? `, ${patientExactMatch.vorname}` : ''
                }`}
                secondary={patientExactMatch.versichertennummer}
              />
            </ListItem>
          )}
          {!!patientsPossibleMatch && (
            <>
              <Typography variant="body2" paragraph>
                Es konnte kein Patient mit der gegebenen Versichertennr. gefunden werden. Handelt es sich um einen der
                folgenden Patienten?
              </Typography>
              {patientsPossibleMatch.map((p) => {
                return (
                  <ListItem
                    key={p.id}
                    id={p.id.toString()}
                    button
                    onClick={handleUpdatePatient}
                  >
                    <ListItemIcon>{genderIcons[p.geschlecht]}</ListItemIcon>
                    <ListItemText
                      primary={`${p.titel ? `${p.titel} ` : ''}${p.nachname}${p.vorname ? `, ${p.vorname}` : ''}`}
                      secondary={
                        p.geburtsdatum
                          ? format(parse(p.geburtsdatum, 'yyyy-MM-dd', new Date()), GERMAN_DATE_LONG_YEAR_FNS)
                          : ''
                      }
                    />
                  </ListItem>
                )
              })}
              <Typography variant="body2" paragraph>
                Wenn es sich um keinen der aufgelisteten Patienten handelt, können Sie eine neue Patienenkartei
                erstellen, indem Sie auf den Button "Neue Kartei erstellen" klicken.
              </Typography>
            </>
          )}
          {noMatchingPatients && (
            <Typography variant="body2" paragraph>
              Es konnten keine möglichen Patienten für die eingelesene Karte gefunden werden. Möchten Sie eine neue
              Patientenkartei erstellen?
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions className="dialogActions">
        {!!patientExactMatch && (
          <Button variant="contained" color="secondary" onClick={handleDialogClose}>
            Abbrechen
          </Button>
        )}
        {!!patientsPossibleMatch && (
          <>
            <Button variant="contained" color="secondary" onClick={handleCreateNewPatient}>
              Neue Kartei erstellen
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDialogClose}>
              Abbrechen
            </Button>
          </>
        )}
        {noMatchingPatients && (
          <>
            <Button variant="contained" color="secondary" onClick={handleCreateNewPatient}>
              Ja
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDialogClose}>
              Nein
            </Button>
          </>
        )}
      </DialogActions>
    </StyledShowCardDataDialog>
  )
}

const mapStateToProps = (state) => ({
  patienten: state.entities.patienten,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    patienten: bindActionCreators(patientenActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowCardDataDialog)
