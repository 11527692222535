import styled from 'styled-components';

export const StyledViewArzt = styled.div`
  ${({ theme }) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .circularProgress {
      align-self: center;
    }

    .editLink {
      text-decoration: none;
      color: ${theme.palette.secondary.main};

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;
