class GlobalNavigation {
  private static _navigate: any = null;

  set navigate(navigate) {
    GlobalNavigation._navigate = navigate;
  }
  get navigate() {
    return GlobalNavigation._navigate;
  }
}

export default new GlobalNavigation();
