import { Button, IconButton } from '@material-ui/core'
import {
  Add as AddIcon,
  Remove as RemoveIcon
} from '@material-ui/icons'
import { FC, useEffect, useState } from 'react'
import { StyledHNBListe } from './styles'
import { HNBListeAuffaelligkeiten, HNBListeLokalisierungen } from './utils'

import lodash from 'lodash'
import { AutocompleteField, MultipleAutocompleteField } from '../../shared/components/AutocompleteField'

interface Props {
  value?: Array<any>,
  onChange: (data: any) => void,
  lokalisierungen?: Array<any>, //localizations
  auffaelligkeiten?: Array<any> //abnormalities
}

const HNBListe: FC<Props> = ({
  value = [],
  onChange = (e?) => {},
  auffaelligkeiten = HNBListeAuffaelligkeiten,
  lokalisierungen = HNBListeLokalisierungen,
}) => {

  const [autocompleteAuffaelligkeiten, setAutocompleteAuffaelligkeiten] = useState<Array<AutocompleteOption>>([]);
  const [autocompleteLokalisierungen, setAutocompleteLokalisierungen] = useState<Array<AutocompleteOption>>([]);

  // set autocompleteAuffaelligkeiten
  useEffect(() => {
    const newAuffaelligkeiten: Array<AutocompleteOption> = (auffaelligkeiten || [])
      .map((item) => ({
        value: item,
        text: item
      }));
    setAutocompleteAuffaelligkeiten(newAuffaelligkeiten)
  }, [auffaelligkeiten])


  // set autocompleteLokalisierungen
  useEffect(() => {
    const newLokalisierungen: Array<AutocompleteOption> = (lokalisierungen || [])
      .map((item) => ({
        value: item.kuerzel,
        text: `${item.lokalisierung} (${item.kuerzel})`
      }));
    setAutocompleteLokalisierungen(newLokalisierungen)
  }, [lokalisierungen])

  const _handleAddClick = (fuss) => () => {
    const currentArray = lodash.cloneDeep(value);
    if (Array.isArray(currentArray)) {
      let newArray = [
        ...currentArray,
        { fuss, auffaelligkeit: null, lokalisierung: [] }
      ];
      onChange(newArray);
    } else {
      let newArray = [{ fuss, auffaelligkeit: null, lokalisierung: [] }];
      onChange(newArray)
    }
  }

  const _handleRemoveClick = (index) => () => {
    const currentArray = lodash.cloneDeep(value || []);
    let newArray = currentArray?.filter((item, itemIndex) => itemIndex !== index);
    onChange(newArray)
  }

  const _handleUpdateInputAuffaelligkeit = (index, searchText) => {
    const currentArray = lodash.cloneDeep(value || []);
    const itemByIndex = currentArray?.find((item, itemIndex) => itemIndex === index);
    if(itemByIndex) {
      itemByIndex['auffaelligkeit'] = searchText;
      currentArray[index] = itemByIndex;
    }
    onChange(currentArray);
  }
  const _handleUpdateInputLokalisierung = (index, searchText) => {
    const currentArray = lodash.cloneDeep(value || []);
    const itemByIndex = currentArray?.find((item, itemIndex) => itemIndex === index);
    if(itemByIndex) {
      itemByIndex['lokalisierung'] = searchText;
      currentArray[index] = itemByIndex;
    }
    onChange(currentArray);
  }

  const _renderFieldRow = (fuss) => (item, index) => {
    if (item.fuss !== fuss) {
      return null
    }
    return (
      <div key={index} className="fieldContainer"
        style={{
          display: 'flex',
          width: '100%',
          height: 'unset',
          marginBottom: '15px'
        }}>
        <AutocompleteField
          className="autoComplete"
          label="Auffälligkeit"
          options={autocompleteAuffaelligkeiten}
          value={item?.auffaelligkeit || ''}
          onChange={(value) => _handleUpdateInputAuffaelligkeit(index, value)}
        />
        {/* <Autocomplete
          className="autoComplete"
          fullWidth
          renderInput={(props) => <TextField variant="standard" label="Auffälligkeit" {...props} />}
          options={auffaelligkeiten || []}
          value={item?.auffaelligkeit || ''}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, item) => {
            return lodash.isEqual(option, item)
          }}
          onChange={(_, value) => _handleUpdateInputAuffaelligkeit(index, value)}
        /> */}
        &nbsp;&nbsp;
        <MultipleAutocompleteField
          disableCloseOnSelect
          className="autoComplete"
          label="Lokalisierung"
          options={autocompleteLokalisierungen}
          value={item?.lokalisierung || []}
          onChange={(value) => _handleUpdateInputLokalisierung(index, value)}
        />
        {/* <Autocomplete
          fullWidth
          renderInput={(props) => <TextField variant="standard" label="Lokalisierung" {...props} />}
          className="autoComplete"
          options={lokalisierungen || []}
          value={item?.lokalisierung || ''}
          getOptionLabel={(option) => option ? `${option.lokalisierung || null} (${option.kuerzel || null})`: ''}
          getOptionSelected={(option, item) => {
            return lodash.isEqual(option, item)
          }}
          onChange={(_, value) => _handleUpdateInputLokalisierung(index, value)}
        /> */}
        <IconButton style={{
          alignSelf: 'center'
        }} onClick={_handleRemoveClick(index)}>
          <RemoveIcon className="icon remove" />
        </IconButton>
      </div>
    )
  }

  return (
    <StyledHNBListe>
      <div className="column">
        {Array.isArray(value) ? value.map(_renderFieldRow('rechts')) : null}
        <Button
          variant="contained"
          className="button"
          startIcon={<AddIcon className="buttonIcon"/>}
          onClick={_handleAddClick('rechts')}>
          rechts
        </Button>
      </div>
      <div className="column">
        {Array.isArray(value) ? value.map(_renderFieldRow('links')) : null}
        <Button
          className="button"
          variant="contained"
          onClick={_handleAddClick('links')}
          startIcon={<AddIcon className="buttonIcon"/>}>
          links
        </Button>
      </div>
    </StyledHNBListe>
  )
}

export default HNBListe
