import styled from 'styled-components';

export const StyledCalendarColumn = styled.div`
  ${({ gridConfig, shadeColumn, allDayGapHeight }: {gridConfig: any, shadeColumn: any, allDayGapHeight: number}) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    .body {
      background-image: url(${gridConfig.gridBackground});
      background-repeat: repeat;
      background-color: ${shadeColumn ? '#f1f1f1' : 'transparent'};
      flex-grow: 1;
      position: relative;
    }

    .header {
      display: flex;
      flex-direction: row;
      position: relative;
      height: ${gridConfig.headerHeight}px;
      box-sizing: border-box;
      z-index: 10;
      pointerEvents: none;
    }

    .currentTime {
      borderTop: 1px solid red;
    }

    .allDayGap {
      height: ${allDayGapHeight}px;
    }
  `}
`;
