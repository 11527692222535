import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export function loadAbbreviations(): RequestConfig {
  console.log('loadAbbreviations called')
  return {
    types: [types.LOAD_ABBREVIATIONS, types.LOAD_ABBREVIATIONS_SUCCESS, types.LOAD_ABBREVIATIONS_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.anamnesiss.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/abbreviations`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function createAbbreviation(data): RequestConfig {
  return {
    types: [types.CREATE_ABBREVIATION, types.CREATE_ABBREVIATION_SUCCESS, types.CREATE_ABBREVIATION_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.abbreviations,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/abbreviations`
      const { ...other } = data

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function createMultipleAbbreviations(data): RequestConfig {
  return {
    types: [types.CREATE_ABBREVIATION, types.CREATE_ABBREVIATION_SUCCESS, types.CREATE_ABBREVIATION_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.abbreviations,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/abbreviations/multiple`
      const { ...other } = data

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function updateAbbreviation(data): RequestConfig {
  return {
    types: [types.UPDATE_ABBREVIATION, types.UPDATE_ABBREVIATION_SUCCESS, types.UPDATE_ABBREVIATION_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => !state.updating.abbreviations,

    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      const URL = `${apiUrl}/abbreviations/${id}`

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify(datesToStrings(other)),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

// TODO: delete functionality for abbreviations
export function deleteAbbreviation(data): RequestConfig {}
