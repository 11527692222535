import { isBefore, isEqual, isSameDay, setHours, setMinutes, setSeconds } from 'date-fns'
import * as R from 'ramda'

export const fields = [
  'id',
  'patient',
  'krankenkasse',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'ausstellungsdatum',
  'gebuehrenbefreit',
  'unfallUnfallfolgen',
  'folgeverordnung',
  'verordnungAusserhalbDesRegelfalls',
  'behandlungsbeginnSpaetestensAm',
  'hausbesuch',
  'therapiebericht',
  'heilmittel', // SIEHE 'Faktor für Heilmittel'!
  'verordnungsmenge',
  'minFrequenz',
  'maxFrequenz',
  'icd10Code',
  'icd10CodeOptional',
  'diagnoseMitLeitsymptomatikWesentlicheBefunde',
  'spezifizierungDerTherapieziele',
  'begruendungVerordnungAusserhalbRegelfall',
  'arzt',
  'sozialeGemeinschaft',
  'kmEinfacheFahrtZumPatienten',
  'zuzahlungErhalten',
  'behandlungAbgebrochen',
  'abgerechnet',
  'dateien',
  'versichertennummer',
  'versichertenstatus',
  'ikNummerKK',
  'urgent_treatment',
  'medicallyPrescribed',
  'socialFacility',
  'diagnosegruppe',
  'leitsymptomatik',
]

export const requiredFields = [
  'ausstellungsdatum',
  'behandlungsbeginnSpaetestensAm',
  'verordnungsmenge',
  'minFrequenz',
  'maxFrequenz',
  'icd10Code',
]

export const arniRequiredFields = [
  'versichertennummer',
  'versichertenstatus',
  'krankenkasse',
  'ikNummerKK',
  'arzt',
]

export const icd10ItemsNew = [
  {
    label: 'E10.74',
    value: 'E10.74',
  },
  {
    label: 'E10.75',
    value: 'E10.75',
  },
  {
    label: 'E11.74',
    value: 'E11.74',
  },
  {
    label: 'E11.75',
    value: 'E11.75',
  },
  {
    label: 'E12.74',
    value: 'E12.74',
  },
  {
    label: 'E12.75',
    value: 'E12.75',
  },
  {
    label: 'E13.74',
    value: 'E13.74',
  },
  {
    label: 'E13.75',
    value: 'E13.75',
  },
  {
    label: 'E14.74',
    value: 'E14.74',
  },
  {
    label: 'E14.75',
    value: 'E14.75',
  },
  {
    label: 'I79.2*',
    value: 'I79.2*',
  },
  {
    label: 'G21.1',
    value: 'G21.1',
  },
  {
    label: 'G59.0*',
    value: 'G59.0*',
  },
  {
    label: 'G60.0',
    value: 'G60.0',
  },
  {
    label: 'G60.1',
    value: 'G60.1',
  },
  {
    label: 'G60.2',
    value: 'G60.2',
  },
  {
    label: 'G60.3',
    value: 'G60.3',
  },
  {
    label: 'G60.8',
    value: 'G60.8',
  },
  {
    label: 'G60.9',
    value: 'G60.9',
  },
  {
    label: 'G61.0',
    value: 'G61.0',
  },
  {
    label: 'G61.1',
    value: 'G61.1',
  },
  {
    label: 'G61.8',
    value: 'G61.8',
  },
  {
    label: 'G61.9',
    value: 'G61.9',
  },
  {
    label: 'G62.0',
    value: 'G62.0',
  },
  {
    label: 'G62.1',
    value: 'G62.1',
  },
  {
    label: 'G62.2',
    value: 'G62.2',
  },
  {
    label: 'G62.80',
    value: 'G62.80',
  },
  {
    label: 'G62.81',
    value: 'G62.81',
  },
  {
    label: 'G62.82',
    value: 'G62.82',
  },
  {
    label: 'G62.83',
    value: 'G62.83',
  },
  {
    label: 'G62.84',
    value: 'G62.84',
  },
  {
    label: 'G62.85',
    value: 'G62.85',
  },
  {
    label: 'G62.86',
    value: 'G62.86',
  },
  {
    label: 'G62.87',
    value: 'G62.87',
  },
  {
    label: 'G62.88',
    value: 'G62.88',
  },
  {
    label: 'G62.9',
    value: 'G62.9',
  },
  {
    label: 'G63.0',
    value: 'G63.0',
  },
  {
    label: 'G63.1',
    value: 'G63.1',
  },
  {
    label: 'G63.2*',
    value: 'G63.2*',
  },
  {
    label: 'G63.3',
    value: 'G63.3',
  },
  {
    label: 'G63.4',
    value: 'G63.4',
  },
  {
    label: 'G63.5',
    value: 'G63.5',
  },
  {
    label: 'G63.6',
    value: 'G63.6',
  },
  {
    label: 'G63.8',
    value: 'G63.8',
  },
  {
    label: 'G64',
    value: 'G64',
  },
  {
    label: 'G82.00',
    value: 'G82.00',
  },
  {
    label: 'G82.01',
    value: 'G82.01',
  },
  {
    label: 'G82.02',
    value: 'G82.02',
  },
  {
    label: 'G82.03',
    value: 'G82.03',
  },
  {
    label: 'G82.09',
    value: 'G82.09',
  },
  {
    label: 'G82.10',
    value: 'G82.10',
  },
  {
    label: 'G82.11',
    value: 'G82.11',
  },
  {
    label: 'G82.12',
    value: 'G82.12',
  },
  {
    label: 'G82.13',
    value: 'G82.13',
  },
  {
    label: 'G82.19',
    value: 'G82.19',
  },
  {
    label: 'G82.20',
    value: 'G82.20',
  },
  {
    label: 'G82.21',
    value: 'G82.21',
  },
  {
    label: 'G82.22',
    value: 'G82.22',
  },
  {
    label: 'G82.23',
    value: 'G82.23',
  },
  {
    label: 'G82.29',
    value: 'G82.29',
  },
  {
    label: 'G82.30',
    value: 'G82.30',
  },
  {
    label: 'G82.31',
    value: 'G82.31',
  },
  {
    label: 'G82.32',
    value: 'G82.32',
  },
  {
    label: 'G82.33',
    value: 'G82.33',
  },
  {
    label: 'G82.39',
    value: 'G82.39',
  },
  {
    label: 'G82.40',
    value: 'G82.40',
  },
  {
    label: 'G82.41',
    value: 'G82.41',
  },
  {
    label: 'G82.42',
    value: 'G82.42',
  },
  {
    label: 'G82.43',
    value: 'G82.43',
  },
  {
    label: 'G82.49',
    value: 'G82.49',
  },
  {
    label: 'G82.50',
    value: 'G82.50',
  },
  {
    label: 'G82.51',
    value: 'G82.51',
  },
  {
    label: 'G82.52',
    value: 'G82.52',
  },
  {
    label: 'G82.53',
    value: 'G82.53',
  },
  {
    label: 'G82.59',
    value: 'G82.59',
  },
  {
    label: 'G82.60',
    value: 'G82.60',
  },
  {
    label: 'G82.61',
    value: 'G82.61',
  },
  {
    label: 'G82.62',
    value: 'G82.62',
  },
  {
    label: 'G82.63',
    value: 'G82.63',
  },
  {
    label: 'G82.64',
    value: 'G82.64',
  },
  {
    label: 'G82.65',
    value: 'G82.65',
  },
  {
    label: 'G82.66',
    value: 'G82.66',
  },
  {
    label: 'G82.67',
    value: 'G82.67',
  },
  {
    label: 'G82.69',
    value: 'G82.69',
  },
  {
    label: 'G82.19',
    value: 'G82.19',
  },
  {
    label: 'G99.0*',
    value: 'G99.0*',
  },
]

export const icd10ItemsByDiagnosis = {
  Df: [
    {
      label: 'E10.74',
      value: 'E10.74',
    },
    {
      label: 'E10.75',
      value: 'E10.75',
    },
    {
      label: 'E11.74',
      value: 'E11.74',
    },
    {
      label: 'E11.75',
      value: 'E11.75',
    },
    {
      label: 'E12.74',
      value: 'E12.74',
    },
    {
      label: 'E12.75',
      value: 'E12.75',
    },
    {
      label: 'E13.74',
      value: 'E13.74',
    },
    {
      label: 'E13.75',
      value: 'E13.75',
    },
    {
      label: 'E14.74',
      value: 'E14.74',
    },
    {
      label: 'E14.75',
      value: 'E14.75',
    },
    {
      label: 'G63.2',
      value: 'G63.2',
    },
    {
      label: 'I79.2*',
      value: 'I79.2*',
    },
  ],
  Nf: [
    {
      label: 'G21.1',
      value: 'G21.1',
    },
    {
      label: 'G59.0*',
      value: 'G59.0*',
    },
    {
      label: 'G60.0',
      value: 'G60.0',
    },
    {
      label: 'G60.1',
      value: 'G60.1',
    },
    {
      label: 'G60.2',
      value: 'G60.2',
    },
    {
      label: 'G60.3',
      value: 'G60.3',
    },
    {
      label: 'G60.8',
      value: 'G60.8',
    },
    {
      label: 'G60.9',
      value: 'G60.9',
    },
    {
      label: 'G61.0',
      value: 'G61.0',
    },
    {
      label: 'G61.1',
      value: 'G61.1',
    },
    {
      label: 'G61.8',
      value: 'G61.8',
    },
    {
      label: 'G61.9',
      value: 'G61.9',
    },
    {
      label: 'G62.0',
      value: 'G62.0',
    },
    {
      label: 'G62.1',
      value: 'G62.1',
    },
    {
      label: 'G62.2',
      value: 'G62.2',
    },
    {
      label: 'G62.80',
      value: 'G62.80',
    },
    {
      label: 'G62.81',
      value: 'G62.81',
    },
    {
      label: 'G62.82',
      value: 'G62.82',
    },
    {
      label: 'G62.83',
      value: 'G62.83',
    },
    {
      label: 'G62.84',
      value: 'G62.84',
    },
    {
      label: 'G62.85',
      value: 'G62.85',
    },
    {
      label: 'G62.86',
      value: 'G62.86',
    },
    {
      label: 'G62.87',
      value: 'G62.87',
    },
    {
      label: 'G62.88',
      value: 'G62.88',
    },
    {
      label: 'G62.9',
      value: 'G62.9',
    },
    {
      label: 'G63.0',
      value: 'G63.0',
    },
    {
      label: 'G63.1',
      value: 'G63.1',
    },
    {
      label: 'G63.2*',
      value: 'G63.2*',
    },
    {
      label: 'G63.3',
      value: 'G63.3',
    },
    {
      label: 'G63.4',
      value: 'G63.4',
    },
    {
      label: 'G63.5',
      value: 'G63.5',
    },
    {
      label: 'G63.6',
      value: 'G63.6',
    },
    {
      label: 'G63.8',
      value: 'G63.8',
    },
    {
      label: 'G64',
      value: 'G64',
    },
    {
      label: 'G99.0*',
      value: 'G99.0*',
    },
  ],
  Qf: [
    {
      label: 'G82.00',
      value: 'G82.00',
    },
    {
      label: 'G82.01',
      value: 'G82.01',
    },
    {
      label: 'G82.02',
      value: 'G82.02',
    },
    {
      label: 'G82.03',
      value: 'G82.03',
    },
    {
      label: 'G82.09',
      value: 'G82.09',
    },
    {
      label: 'G82.10',
      value: 'G82.10',
    },
    {
      label: 'G82.11',
      value: 'G82.11',
    },
    {
      label: 'G82.12',
      value: 'G82.12',
    },
    {
      label: 'G82.13',
      value: 'G82.13',
    },
    {
      label: 'G82.19',
      value: 'G82.19',
    },
    {
      label: 'G82.20',
      value: 'G82.20',
    },
    {
      label: 'G82.21',
      value: 'G82.21',
    },
    {
      label: 'G82.22',
      value: 'G82.22',
    },
    {
      label: 'G82.23',
      value: 'G82.23',
    },
    {
      label: 'G82.29',
      value: 'G82.29',
    },
    {
      label: 'G82.30',
      value: 'G82.30',
    },
    {
      label: 'G82.31',
      value: 'G82.31',
    },
    {
      label: 'G82.32',
      value: 'G82.32',
    },
    {
      label: 'G82.33',
      value: 'G82.33',
    },
    {
      label: 'G82.39',
      value: 'G82.39',
    },
    {
      label: 'G82.40',
      value: 'G82.40',
    },
    {
      label: 'G82.41',
      value: 'G82.41',
    },
    {
      label: 'G82.42',
      value: 'G82.42',
    },
    {
      label: 'G82.43',
      value: 'G82.43',
    },
    {
      label: 'G82.49',
      value: 'G82.49',
    },
    {
      label: 'G82.50',
      value: 'G82.50',
    },
    {
      label: 'G82.51',
      value: 'G82.51',
    },
    {
      label: 'G82.52',
      value: 'G82.52',
    },
    {
      label: 'G82.53',
      value: 'G82.53',
    },
    {
      label: 'G82.59',
      value: 'G82.59',
    },
    {
      label: 'G82.60',
      value: 'G82.60',
    },
    {
      label: 'G82.61',
      value: 'G82.61',
    },
    {
      label: 'G82.62',
      value: 'G82.62',
    },
    {
      label: 'G82.63',
      value: 'G82.63',
    },
    {
      label: 'G82.64',
      value: 'G82.64',
    },
    {
      label: 'G82.65',
      value: 'G82.65',
    },
    {
      label: 'G82.66',
      value: 'G82.66',
    },
    {
      label: 'G82.67',
      value: 'G82.67',
    },
    {
      label: 'G82.69',
      value: 'G82.69',
    },
    {
      label: 'G82.19',
      value: 'G82.19',
    },
  ],
}

export const icd10Items: AutocompleteOption[] = [
  {
    text: 'E10.74',
    value: 'E10.74',
  },
  {
    text: 'E10.75',
    value: 'E10.75',
  },
  {
    text: 'E11.74',
    value: 'E11.74',
  },
  {
    text: 'E11.75',
    value: 'E11.75',
  },
  {
    text: 'E12.74',
    value: 'E12.74',
  },
  {
    text: 'E12.75',
    value: 'E12.75',
  },
  {
    text: 'E13.74',
    value: 'E13.74',
  },
  {
    text: 'E13.75',
    value: 'E13.75',
  },
  {
    text: 'E14.74',
    value: 'E14.74',
  },
  {
    text: 'E14.75',
    value: 'E14.75',
  },
  {
    text: 'I79.2*',
    value: 'I79.2*',
  },
  {
    text: 'G59.0*',
    value: 'G59.0*',
  },
  {
    text: 'G60.0',
    value: 'G60.0',
  },
  {
    text: 'G60.1',
    value: 'G60.1',
  },
  {
    text: 'G60.2',
    value: 'G60.2',
  },
  {
    text: 'G60.3',
    value: 'G60.3',
  },
  {
    text: 'G60.8',
    value: 'G60.8',
  },
  {
    text: 'G60.9',
    value: 'G60.9',
  },
  {
    text: 'G61.0',
    value: 'G61.0',
  },
  {
    text: 'G61.1',
    value: 'G61.1',
  },
  {
    text: 'G61.8',
    value: 'G61.8',
  },
  {
    text: 'G61.9',
    value: 'G61.9',
  },
  {
    text: 'G62.0',
    value: 'G62.0',
  },
  {
    text: 'G62.1',
    value: 'G62.1',
  },
  {
    text: 'G62.2',
    value: 'G62.2',
  },
  {
    text: 'G62.80',
    value: 'G62.80',
  },
  {
    text: 'G62.88',
    value: 'G62.88',
  },
  {
    text: 'G62.9',
    value: 'G62.9',
  },
  {
    text: 'G63.0',
    value: 'G63.0',
  },
  {
    text: 'G63.1',
    value: 'G63.1',
  },
  {
    text: 'G63.2*',
    value: 'G63.2*',
  },
  {
    text: 'G63.3',
    value: 'G63.3',
  },
  {
    text: 'G63.4',
    value: 'G63.4',
  },
  {
    text: 'G63.5',
    value: 'G63.5',
  },
  {
    text: 'G63.6',
    value: 'G63.6',
  },
  {
    text: 'G63.8',
    value: 'G63.8',
  },
  {
    text: 'G64',
    value: 'G64',
  },
  {
    text: 'G82.00',
    value: 'G82.00',
  },
  {
    text: 'G82.01',
    value: 'G82.01',
  },
  {
    text: 'G82.02',
    value: 'G82.02',
  },
  {
    text: 'G82.03',
    value: 'G82.03',
  },
  {
    text: 'G82.09',
    value: 'G82.09',
  },
  {
    text: 'G82.10',
    value: 'G82.10',
  },
  {
    text: 'G82.11',
    value: 'G82.11',
  },
  {
    text: 'G82.12',
    value: 'G82.12',
  },
  {
    text: 'G82.13',
    value: 'G82.13',
  },
  {
    text: 'G82.20',
    value: 'G82.20',
  },
  {
    text: 'G82.21',
    value: 'G82.21',
  },
  {
    text: 'G82.22',
    value: 'G82.22',
  },
  {
    text: 'G82.23',
    value: 'G82.23',
  },
  {
    text: 'G82.29',
    value: 'G82.29',
  },
  {
    text: 'G82.30',
    value: 'G82.30',
  },
  {
    text: 'G82.31',
    value: 'G82.31',
  },
  {
    text: 'G82.32',
    value: 'G82.32',
  },
  {
    text: 'G82.33',
    value: 'G82.33',
  },
  {
    text: 'G82.39',
    value: 'G82.39',
  },
  {
    text: 'G82.40',
    value: 'G82.40',
  },
  {
    text: 'G82.41',
    value: 'G82.41',
  },
  {
    text: 'G82.42',
    value: 'G82.42',
  },
  {
    text: 'G82.43',
    value: 'G82.43',
  },
  {
    text: 'G82.49',
    value: 'G82.49',
  },
  {
    text: 'G82.50',
    value: 'G82.50',
  },
  {
    text: 'G82.51',
    value: 'G82.51',
  },
  {
    text: 'G82.52',
    value: 'G82.52',
  },
  {
    text: 'G82.53',
    value: 'G82.53',
  },
  {
    text: 'G82.59',
    value: 'G82.59',
  },
  {
    text: 'G82.60',
    value: 'G82.60',
  },
  {
    text: 'G82.61',
    value: 'G82.61',
  },
  {
    text: 'G82.62',
    value: 'G82.62',
  },
  {
    text: 'G82.63',
    value: 'G82.63',
  },
  {
    text: 'G82.64',
    value: 'G82.64',
  },
  {
    text: 'G82.65',
    value: 'G82.65',
  },
  {
    text: 'G82.66',
    value: 'G82.66',
  },
  {
    text: 'G82.67',
    value: 'G82.67',
  },
  {
    text: 'G82.69',
    value: 'G82.69',
  },
  {
    text: 'G82.19',
    value: 'G82.19',
  },
  {
    text: 'G99.0*',
    value: 'G99.0*',
  },
]

export const diagnosegruppeItems = [
  { value: 'Df', label: 'Df' },
  { value: 'Nf', label: 'Nf' },
  { value: 'Qf', label: 'Qf' },
]

export const nagelkorrekturDiagnosegruppeItems = [
  {
    value: 'UI1a',
    label: 'UI1a',
  },
  {
    value: 'UI2a',
    label: 'UI2a',
  },
]

export const nagelkorrekturBehandlungseinheiten = {
  UI1a: 8,
  UI2a: 4,
}

export const getBehandlungseinheitenForDiagnosegruppe = (diagnosegruppe) => {
  if (diagnosegruppe !== 'UI1a' && diagnosegruppe !== 'UI2a') {
    return null
  }

  return nagelkorrekturBehandlungseinheiten[diagnosegruppe]
}

export const leitsymptomatikItems = [
  { value: 'a', label: 'a' },
  { value: 'b', label: 'b' },
  { value: 'c', label: 'c' },
]

export const heilmittelItems = [
  { value: 78001, text: 'Hornhautabtragung' },
  { value: 78002, text: 'Hornhautabtragung' },
  { value: 78003, text: 'Hornhautabtragung' },
  { value: 78004, text: 'Nagelbearbeitung' },
  { value: 78005, text: 'Nagelbearbeitung' },
  { value: 78006, text: 'Nagelbearbeitung' },
  { value: 78007, text: 'Podologische Komplexbehandlung' },
  { value: 78008, text: 'Podologische Komplexbehandlung' },
  { value: 78009, text: 'Podologische Komplexbehandlung' },
]

export const behandlungsbeginnSpaetestensAmDays = 28
export const urgentTreatmentFactor = 14

export const validate = (values, props) => {
  const errors: KeyValue<string> = {}

  requiredFields.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  const requiredCheckBoxXor = ['gebuehrenbefreit']

  requiredCheckBoxXor.forEach((fieldName) => {
    if (values[fieldName] === null) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  // if (values.verordnungsmenge && values.folgeverordnung) {
  //   if (parseInt(values.verordnungsmenge, 10) > 6) {
  //     errors.verordnungsmenge = 'Folgeverordnungen dürfen maximal 6 Behandlungen beinhalten!'
  //   }
  // } else if (values.verordnungsmenge) {
  //   if (parseInt(values.verordnungsmenge, 10) > 6) {
  //     errors.verordnungsmenge = 'Erstverordnungen dürfen maximal 6 Behandlungen beinhalten!'
  //   }
  // }

  if (values.behandlungsbeginnSpaetestensAm && values.ausstellungsdatum) {
    if (
      isBefore(
        setHours(setMinutes(setSeconds(values.behandlungsbeginnSpaetestensAm, 0), 0), 0),
        setHours(setMinutes(setSeconds(values.ausstellungsdatum, 0), 0), 0),
      )
    ) {
      errors.behandlungsbeginnSpaetestensAm = 'Die Behandlung darf nicht vor dem Ausstellungsdatum beginnen!'
    }
  }

  if (values.minFrequenz && values.maxFrequenz && parseInt(values.minFrequenz, 10) > parseInt(values.maxFrequenz, 10)) {
    const minFreqError = 'min. Frequenz darf nicht größer als max. Frequenz sein!'
    const maxFreqError = 'max. Frequenz darf nicht kleiner als min. Frequenz sein!'
    if (props.form._active === 'minFrequenz') {
      errors.minFrequenz = minFreqError
    } else if (props.form._active === 'maxFrequenz') {
      errors.maxFrequenz = maxFreqError
    } else {
      errors.minFrequenz = minFreqError
      errors.maxFrequenz = maxFreqError
    }
  }
  /*
    if (values.hausbesuch && !values.kmEinfacheFahrtZumPatienten && values.kmEinfacheFahrtZumPatienten !== 0) {
      errors.kmEinfacheFahrtZumPatienten = 'Bitte ausfüllen.'
    }*/
  if (values.hausbesuch && !values.medicallyPrescribed && !values.socialFacility) {
    errors.socialFacility = 'Eines der Felder muss ausgewählt werden.'
  }

  if (!values?.diagnosegruppe || !values.diagnosegruppe.length) {
    errors.diagnosegruppe = 'Bitte ausfüllen.'
  }

  if (!values?.leitsymptomatik || !values.leitsymptomatik.length) {
    errors.leitsymptomatik = 'Bitte ausfüllen.'
  }

  if (
    R.any(
      (hvo) =>
        parseInt(props.hvoId) !== hvo.id &&
        isSameDay(hvo.ausstellungsdatum, values.ausstellungsdatum) &&
        isEqual(hvo.heilmittel, values.heilmittel),
    )(props.customerHVOs)
  ) {
    errors.ausstellungsdatum = 'Verordnung mit gleichem Ausstellungsdatum liegt bereits vor!'
  }

  if (
    values.versichertennummer &&
    !/^[A-Z]{1}[0-9]{9}$/.test(values.versichertennummer) &&
    values.krankenkasse !== 1 &&
    values.krankenkasse !== 15
  ) {
    errors.versichertennummer = 'Bitte gültige Versicherten-Nr. eingeben!'
  }

  if (values.icd10Code === values.icd10CodeOptional) {
    errors.icd10CodeOptional = 'Code bereits angegeben!'
  }

  if (props.isArniCustomer) {
    const arniErrors = validateArni(values)
    return { ...errors, ...arniErrors }
  }

  return errors
}

const validateArni = (values) => {
  const errors: KeyValue<string> = {}

  arniRequiredFields.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  if (values.versichertenstatus && values.versichertenstatus.length > 5) {
    errors.versichertenstatus = 'Max. 5 Zeichen erlaubt!'
  }

  return errors
}

interface ILink {
  label: string
  link: string
}

export const icdCodeLinksDiabetics: ILink[] = [
  {
    label: 'E10.74',
    link: 'http://www.icd-code.de/suche/icd/code/E10.-.html?sp=Se10.74',
  },
  {
    label: 'E10.75',
    link: 'http://www.icd-code.de/suche/icd/code/E10.-.html?sp=Se10.75',
  },
  {
    label: 'E11.74',
    link: 'http://www.icd-code.de/suche/icd/code/E11.-.html?sp=Se11.74',
  },
  {
    label: 'E11.75',
    link: 'http://www.icd-code.de/suche/icd/code/E11.-.html?sp=Se11.75',
  },
  {
    label: 'E12.74',
    link: 'http://www.icd-code.de/suche/icd/code/E12.-.html?sp=Se12.74',
  },
  {
    label: 'E12.75',
    link: 'http://www.icd-code.de/suche/icd/code/E12.-.html?sp=Se12.75',
  },
  {
    label: 'E13.74',
    link: 'http://www.icd-code.de/suche/icd/code/E13.-.html?sp=Se13.74',
  },
  {
    label: 'E13.75',
    link: 'http://www.icd-code.de/suche/icd/code/E13.-.html?sp=Se13.75',
  },
  {
    label: 'E14.74',
    link: 'http://www.icd-code.de/suche/icd/code/E14.-.html?sp=Se14.74',
  },
  {
    label: 'E14.75',
    link: 'http://www.icd-code.de/suche/icd/code/E14.-.html?sp=Se14.75',
  },
]

export const icdCodeLinksNeuropathy: ILink[] = [
  {
    label: 'G59.0*',
    link: 'http://www.icd-code.de/suche/icd/code/G59.-*.html?sp=Sg59.0',
  },
  {
    label: 'G63.2*',
    link: 'http://www.icd-code.de/suche/icd/code/G63.-*.html?sp=Sg63.2',
  },
]

export const diagnosisGroupOptions = [
  { value: 'Df', label: 'Df' },
  { value: 'Nf', label: 'Nf' },
  { value: 'Qf', label: 'Qf' },
]

export const cardinalSymptomOptions = [
  { value: 'a', label: 'a' },
  { value: 'b', label: 'b' },
  { value: 'c', label: 'c' },
]

export const remedyOptions = [
  { value: 78001, label: 'Hornhautabtragung' },
  { value: 78002, label: 'Hornhautabtragung' },
  { value: 78003, label: 'Hornhautabtragung' },
  { value: 78004, label: 'Nagelbearbeitung' },
  { value: 78005, label: 'Nagelbearbeitung' },
  { value: 78006, label: 'Nagelbearbeitung' },
  { value: 78007, label: 'Podologische Komplexbehandlung' },
  { value: 78008, label: 'Podologische Komplexbehandlung' },
  { value: 78009, label: 'Podologische Komplexbehandlung' },
]

export const nagelkorrekturLokalisierungOptions = [
  {
    value: 'L1',
    label: 'L1',
  },
  {
    value: 'L2',
    label: 'L2',
  },
  {
    value: 'L3',
    label: 'L3',
  },
  {
    value: 'L4',
    label: 'L4',
  },
  {
    value: 'L5',
    label: 'L5',
  },
  {
    value: 'R1',
    label: 'R1',
  },
  {
    value: 'R2',
    label: 'R2',
  },
  {
    value: 'R3',
    label: 'R3',
  },
  {
    value: 'R4',
    label: 'R4',
  },
  {
    value: 'R5',
    label: 'R5',
  },
]
