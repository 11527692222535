import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDialogPaper = styled(Paper)`
  ${({ theme }) => `
    background-color: #fff;
    width: 500px;

    ${theme.breakpoints.down('xs')} {
      width: 100vw;
    }
  `}
`;

export const StyledCustomerFormSmall = styled.form`
  ${({ theme }) => `
    .container {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(2)}px;
    }
  `}
`;
