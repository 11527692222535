import { Button } from '@material-ui/core'
import * as types from '../constants/actionTypes'
import { notifications as newNotifications } from '../redux/modules'
import { REGISTER_USER, REGISTER_USER_FAILURE, REGISTER_USER_SUCCESS } from '../redux/modules/auth'
import { MessageError, MessageSuccess } from '../shared/components/Message'
import { notifications as sharedNotifications } from '../shared/redux/modules'

const initialState = {
  message: null,
  busy: false,
  error: false,
  hidesOnLocationChange: false,
}

const HelpButton = <Button color="primary" onClick={() => window['fcWidget']?.open()}>Hilfe</Button>;

const defaultMessages = {
  CLEAR_NOTIFICATION: initialState,

  NOTIFY_REDIRECT_TERMIN_FINDEN: {
    message: <span>Automatische Weiterleitung von der &quot;Termin finden&quot; Ansicht aktiv.</span>,
    busy: false,
    error: false,
    action: <Button onClick={() => (window.location = '/settings/praxisstammdaten')}>Einstellung ändern</Button>,
  },

  CREATE_CUSTOMER: {
    message: 'Patientenkartei wird angelegt...',
    busy: true,
    error: false,
  },
  CREATE_CUSTOMER_SUCCESS: {
    message: (
      <MessageSuccess message={'Patientenkartei erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_CUSTOMER_FAILURE: {
    message: (
      <MessageError message={'Anlegen der Patientenkartei fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton,
  },

  UPDATE_CUSTOMER: {
    message: 'Patientenkartei wird aktualisiert...',
    busy: true,
    error: false,
  },
  UPDATE_CUSTOMER_SUCCESS: {
    message: (
      <MessageSuccess message={'Patientenkartei erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_CUSTOMER_FAILURE: {
    message: (
      <MessageError message={'Aktualisieren der Patientenkartei fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  CREATE_DOCTOR: {
    message: 'Ärzteakte wird angelegt...',
    busy: true,
    error: false,
  },
  CREATE_DOCTOR_SUCCESS: {
    message: (
      <MessageSuccess message={'Ärzteakte erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_DOCTOR_FAILURE: {
    message: (
      <MessageError message={'Anlegen der Ärzteakte fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  UPDATE_DOCTOR: { message: 'Ärzteakte wird aktualisiert...', busy: true, error: false },
  UPDATE_DOCTOR_SUCCESS: {
    message: (
      <MessageSuccess message={'Ärzteakte erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_DOCTOR_FAILURE: {
    message: (
      <MessageError message={'Aktualisieren der Ärzteakte fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  CREATE_DATE: { message: 'Termin wird angelegt...', busy: true, error: false },
  CREATE_DATE_SUCCESS: {
    message: (
      <MessageSuccess message={'Termin erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_DATE_FAILURE: {
    message: (
      <MessageError message={'Anlegen des Termins fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  UPDATE_DATE: { message: 'Termin wird aktualisiert...', busy: true, error: false },
  UPDATE_DATE_SUCCESS: {
    message: (
      <MessageSuccess message={'Termin erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_DATE_FAILURE: {
    message: (
      <MessageError message={'Aktualisierung des Termins fehlgeschlagen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  DEV_ACQUIRE_AUTHENTICATION: {
    message: 'login...',
    busy: true,
    error: false,
  },
  DEV_ACQUIRE_AUTHENTICATION_SUCCESS: {
    message: 'Anmeldung erfolgreich!',
    busy: false,
    error: false,
  },
  DEV_ACQUIRE_AUTHENTICATION_FAILURE: (state, action) => {
    switch (action.error.message) {
      case 'Unauthorized':
        return {
          message: 'Anmeldedaten ungültig: Bitte überprüfen Sie Benutzername/Passwort und versuchen es erneut!',
          busy: false,
          error: true,
          action: HelpButton
        }
      case 'Internal Server Error':
        return {
          message: (
            <MessageError message={'Server-Fehler: Bitte versuchen Sie es in einigen Minuten erneut!'} />
          ),
          busy: false,
          error: true,
          action: HelpButton
        }
      case 'Failed to fetch':
        return {
          message: (
            <MessageError message={'Verbindungsproblem: Keine/schlechte Internetverbindung!'} />
          ),
          busy: false,
          error: true,
          action: HelpButton
        }
      default:
        return {
          message: <span>Ein unbekannter Fehler ist aufgetreten. Bitte wiederholen!</span>,
          busy: false,
          error: true,
          action: HelpButton
        }
    }
  },

  UPDATE_PRAXISSTAMMDATEN: {
    message: 'Praxis-Einstellungen werden gespeichert...',
    busy: true,
    error: false,
  },
  UPDATE_PRAXISSTAMMDATEN_SUCCESS: {
    message: (
      <MessageSuccess message={'Praxis-Einstellungen erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_PRAXISSTAMMDATEN_FAILURE: {
    message: (
      <MessageError message={'Speichern der Praxis-Einstellungen fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  CREATE_THERAPIEBERICHT: { message: 'Therapiebericht wird gespeichert...', busy: true, error: false },
  CREATE_THERAPIEBERICHT_SUCCESS: {
    message: (
      <MessageSuccess message={'Therapiebericht erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_THERAPIEBERICHT_FAILURE: {
    message: (
      <MessageError message={'Speichern des Therapieberichts fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  CREATE_HEILMITTELVERORDNUNG: { message: 'Verordnung wird gespeichert...', busy: true, error: false },
  CREATE_HEILMITTELVERORDNUNG_SUCCESS: {
    message: (
      <MessageSuccess message={'Verordnung erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_HEILMITTELVERORDNUNG_FAILURE: {
    message: (
      <MessageError message={'Hinzufügen der Verordnung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  UPDATE_HEILMITTELVERORDNUNG: { message: 'Verordnung wird aktualisiert...', busy: true, error: false },
  UPDATE_HEILMITTELVERORDNUNG_SUCCESS: {
    message: (
      <MessageSuccess message={'Verordnung erfolgreich aktualisiert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_HEILMITTELVERORDNUNG_FAILURE: {
    message: (
      <MessageError message={'Aktualisieren der Verordnung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  SUBMIT_HVO: { message: 'Abrechnungsdaten werden übermittelt...', busy: true, error: false },
  SUBMIT_HVO_SUCCESS: {
    message: (
      <MessageSuccess message={'Daten erfolgreich übermittelt.'} />
    ),
    busy: false,
    error: false
  },
  SUBMIT_HVO_FAILURE: {
    message: (
      <MessageError message={'Übermittlung der Daten fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },


  CREATE_DOKUMENTATION: { message: 'Dokumentation wird gespeichert...', busy: true, error: false },
  CREATE_DOKUMENTATION_SUCCESS: {
    message: (
      <MessageSuccess message={'Dokumentation erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_DOKUMENTATION_FAILURE: {
    message: (
      <MessageError message={'Erstellen der Dokumentation fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  UPDATE_DOKUMENTATION: { message: 'Dokumentation wird aktualisiert...', busy: true, error: false },
  UPDATE_DOKUMENTATION_SUCCESS: {
    message: (
      <MessageSuccess message={'Dokumentation erfolgreich aktualisiert.'} />
    ),
    busy: false,
    error: false
  },
  UPDATE_DOKUMENTATION_FAILURE: {
    message: (
      <MessageError message={'Aktualisieren der Dokumentation fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [types.CREATE_DOKUMENTATIONSTEXT_FAILURE]: {
    message: 'Speichern des Dokumentationstexts fehlgeschlagen. Bitte wiederholen!',
    busy: false,
    error: true,
    action: HelpButton
  },

  CREATE_LEISTUNGPRIVAT: {
    message: 'Leistung wird gespeichert...',
    busy: true,
    error: false,
  },
  CREATE_LEISTUNGPRIVAT_SUCCESS: {
    message: (
      <MessageSuccess message={'Leistung erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  CREATE_LEISTUNGPRIVAT_FAILURE: {
    message: (
      <MessageError message={'Anlegen der Leistung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  UPDATE_LEISTUNGPRIVAT: { message: 'Leistung wird aktualisiert...', busy: true, error: false },
  UPDATE_LEISTUNGPRIVAT_SUCCESS: {
    message: (
      <MessageSuccess message={'Leistung erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  UPDATE_LEISTUNGPRIVAT_FAILURE: {
    message: (
      <MessageError message={'Aktualisieren der Leistung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  DELETE_LEISTUNGPRIVAT: { message: 'Leistung wird gelöscht...', busy: true, error: false },
  DELETE_LEISTUNGPRIVAT_SUCCESS: {
    message: (
      <MessageSuccess message={'Leistung erfolgreich gelöscht/ausgeblendet.'} />
    ),
    busy: false,
    error: false,
  },
  DELETE_LEISTUNGPRIVAT_FAILURE: {
    message: (
      <MessageError message={'Löschen der Leistung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [REGISTER_USER]: { message: 'Registrierung wird gespeichert...', busy: true, error: false },
  [REGISTER_USER_SUCCESS]: {
    message: (
      <MessageSuccess message={'Registrierung erfolgreich.'} />
    ),
    busy: false,
    error: false,
  },
  [REGISTER_USER_FAILURE]: (state, action) => {
    switch (action.error.message) {
      case 'Not Acceptable':
        return {
          message: 'E-Mail-Adresse ungültig! Ggfs. Support kontaktieren.',
          busy: false,
          error: true,
          action: HelpButton
        }
      default:
        return {
          message: (
            <MessageError message={'Registrierung fehlgeschlagen. Bitte Support kontaktieren!'} />
          ),
          busy: false,
          error: true,
          action: HelpButton
        }
    }
  },
  [types.UPDATE_USER]: { message: 'Mitarbeiterdaten werden aktualisiert...', busy: true, error: false },
  [types.UPDATE_USER_SUCCESS]: {
    message: (
      <MessageSuccess message={'Mitarbeiterdaten erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  [types.UPDATE_USER_FAILURE]: {
    message: (
      <MessageError message={'Aktualisierung der Mitarbeiterdaten fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [types.UPDATE_INSTANCE_USER]: { message: 'Mitarbeiterdaten werden aktualisiert...', busy: true, error: false },
  [types.UPDATE_INSTANCE_USER_SUCCESS]: {
    message: (
      <MessageSuccess message={'Mitarbeiterdaten erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  [types.UPDATE_INSTANCE_USER_FAILURE]: {
    message: (
      <MessageError message={'Aktualisierung der Mitarbeiterdaten fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [types.CREATE_PRAXIS]: { message: 'Praxis wird angelegt...', busy: true, error: false },
  [types.CREATE_PRAXIS_SUCCESS]: {
    message: (
      <MessageSuccess message={'Praxis erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  [types.CREATE_PRAXIS_FAILURE]: {
    message: (
      <MessageError message={'Anlegen der Praxis fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [types.CREATE_BUCHUNG]: { message: 'Kassenbucheintrag wird gespeichert...', busy: true, error: false },
  [types.CREATE_BUCHUNG_SUCCESS]: {
    message: (
      <MessageSuccess message={'Kassenbucheintrag erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  [types.CREATE_BUCHUNG_FAILURE]: {
    message: (
      <MessageError message={'Speichern des Kassenbucheintrags fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },

  [types.ZAHLUNG_ERFASSEN]: { message: 'Barzahlung wird erfasst...', busy: true, error: false },
  [types.ZAHLUNG_ERFASSEN_SUCCESS]: {
    message: (
      <MessageSuccess message={'Barzahlung erfolgreich erfasst.'} />
    ),
    busy: false,
    error: false,
  },
  [types.ZAHLUNG_ERFASSEN_FAILURE]: {
    message: (
      <MessageError message={'Speichern der Barzahlung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },
  [types.UPDATE_FILE]: { message: 'Foto-Bearbeitung wird gespeichert...', busy: true, error: false },
  [types.UPDATE_FILE_SUCCESS]: {
    message: (
      <MessageSuccess message={'Foto-Bearbeitung erfolgreich gespeichert.'} />
    ),
    busy: false,
    error: false,
  },
  [types.UPDATE_FILE_FAILURE]: {
    message: (
      <MessageError message={'Foto-Bearbeitung fehlgeschlagen. Bitte wiederholen!'} />
    ),
    busy: false,
    error: true,
    action: HelpButton
  },
  [types.UNDO_CANCEL_DATE]: { message: 'Termin wird wiederhergestellt...', busy: true, error: false },
  [types.UNDO_CANCEL_DATE_SUCCESS]: {
    message: (
      <MessageSuccess message={'Termin erfolgreich wiederhergestellt.'} />
    ),
    busy: false,
    error: false,
  },
  [types.UNDO_CANCEL_DATE_FAILURE]: {
    message: (
      <MessageError message={'Zeitraum bereits belegt, erstellen Sie einen neuen Termin!'} />
    ),
    busy: false,
    error: true,
  },
  ...sharedNotifications,
  ...newNotifications,
}

export default function notification(state = initialState, action = undefined) {
  const { notification: customMessage, type } = action;

  if (customMessage && typeof customMessage === 'string') {
    return {
      ...initialState,
      ...state,
      message: customMessage,
    }
  }

  if (
    customMessage &&
    typeof customMessage === 'object' &&
    customMessage.request &&
    customMessage.success &&
    customMessage.failure
  ) {
    if (type.split('_').indexOf('SUCCESS') !== -1) {
      return {
        ...initialState,
        ...state,
        message: customMessage.success,
        action: customMessage.action,
        busy: false,
        error: false,
        hidesOnLocationChange: Boolean(customMessage.hidesOnLocationChange),
        redirectOnSuccess: customMessage.redirectOnSuccess || false
      }
    }
    if (type.split('_').indexOf('FAILURE') !== -1) {
      return {
        ...initialState,
        ...state,
        message: customMessage.failure,
        busy: false,
        error: true,
      }
    }
    return {
      ...initialState,
      ...state,
      message: customMessage.request,
      busy: true,
      error: false,
      redirectOnSuccess: customMessage.redirectOnSuccess || false
    }
  } else if (
    customMessage &&
    typeof customMessage === 'object' &&
    customMessage.message &&
    typeof customMessage.busy === 'boolean' &&
    typeof customMessage.error === 'boolean'
  ) {
    return {
      ...initialState,
      ...state,
      ...customMessage,
      redirectOnSuccess: customMessage.redirectOnSuccess || false
    };
  }

  if (typeof defaultMessages[type] === 'object') {
    return {
      ...initialState,
      ...defaultMessages[type],
    }
  } else if (typeof defaultMessages[type] === 'function') {
    return {
      ...initialState,
      ...defaultMessages[type](state, action),
    }
  }

  if (type === '@@router/LOCATION_CHANGE' && state.hidesOnLocationChange === true) return initialState

  if (type === types.CLOSE_EDIT_TERMIN_DIALOG) return initialState

  return state
}
