/*
 * dieser Reducer speichert für ausgesuchte Entitäten, ob die erste erfolgreiche Load-Action schon passiert ist.
 * */
import * as types from '../constants/actionTypes'

export default function initialLoadComplete(
  state = {
    abbreviations: false,
    aerzte: false,
    anamnesen: false,
    patienten: false,
    todos: false,
    leistungenPrivat: false,
    users: false,
    praxisstammdaten: false,
    rooms: false,
    equipment: false,
  },
  action = undefined,
) {
  switch (action.type) {
    case types.LOAD_ABBREVIATIONS_SUCCESS:
      return {
        ...state,
        abbreviations: true,
      }
    case types.LOAD_ANAMNESIS_SUCCESS:
      return {
        ...state,
        anamnesen: true,
      }

    case types.LOAD_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: true,
      }

    case types.LOAD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: true,
      }

    case types.LOAD_CUSTOMER_SUCCESS:
    case types.LOAD_CUSTOMERS_SUCCESS:
    case types.LOAD_CUSTOMERS_ALPHABETICALLY_SUCCESS:
      return {
        ...state,
        patienten: true,
      }

    case types.LOAD_DOCTORS_SUCCESS:
      return {
        ...state,
        aerzte: true,
      }

    case types.LOAD_TODOS_SUCCESS:
      return {
        ...state,
        todos: true,
      }

    case types.LOAD_LEISTUNGENPRIVAT_SUCCESS:
      return {
        ...state,
        leistungenPrivat: true,
      }

    case types.LOAD_PEOPLE_SUCCESS:
      return {
        ...state,
        users: true,
      }

    case types.LOAD_PRAXISSTAMMDATEN_SUCCESS:
      return {
        ...state,
        praxisstammdaten: true,
      }

    default:
  }

  return state
}
