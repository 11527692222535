import styled from 'styled-components';

export const StyledDoctorForm = styled.form`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px ${theme.spacing(6)}px;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(3)}px;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${theme.spacing(6)}px;

      ${theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
      }
    }

    .formGroup {
      display: grid;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .streetGroup {
      display: grid;
      grid-template-columns: 1fr 0.25fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .addressGroup {
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .notesField {
      margin: ${theme.spacing(4)}px 0;
    }

    .submitButton {
      margin-top: 30px;
    }
  `}
`;
