import { Button, Divider } from '@material-ui/core';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import InputField from '../../shared/components/InputField/InputField';
import { SelectField } from '../../shared/components/SelectField/SelectField';
import { StyledDoctorForm } from './StyledDoctorForm';

export const fields = [
  'vorname',
  'nachname',
  'titel',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'fax',
  'mobil',
  'email',
  'notizen',
  'praxisbezeichnung',
  'lanr',
  'bsnr',
  'website',
];

const titles = [
  { value: 'Dr.', text: 'Dr.' },
  { value: 'Dr. med.', text: 'Dr. med.' },
  { value: 'Mag.', text: 'Mag.' },
  { value: 'Dipl. med.', text: 'Dipl. med.' },
  { value: 'Prof.', text: 'Prof.' },
  { value: 'Prof. Dr.', text: 'Prof. Dr.' },
  { value: 'Prof. Dr. med.', text: 'Prof. Dr. med.' },
  { value: NaN, text: String.fromCharCode(8212) }, // '-' symbol
];

const DoctorForm: FC<any> = ({
  handleSubmit,
  pristine,
  submitFailed,
  invalid,
  submitting,
  fields: {
    vorname,
    nachname,
    titel,
    strasse,
    hausnummer,
    postleitzahl,
    ort,
    telefon,
    fax,
    mobil,
    email,
    notizen,
    praxisbezeichnung,
    lanr,
    bsnr,
    website,
  },
  submitText = 'Arzt anlegen',
  // autocomplete,
  busy,
  // actions,
  viewOnly,
  aerzteFachgruppen = {},
  onSubmitFunc,
}) => {

  const [aerzteFachgruppe, setAerzteFachgruppe] = useState<AerzteFachgruppe | null>(null)
  useEffect(() => {
    let newAerzteFachgruppen = null
    if (lanr.value && lanr.value.length === 9 && aerzteFachgruppen) {
      newAerzteFachgruppen = aerzteFachgruppen[parseInt(lanr.value.slice(7, 9), 10)] || null
    }
    setAerzteFachgruppe(newAerzteFachgruppen)
  }, [lanr?.value, aerzteFachgruppen])

  // useEffect(() => {
  //   actions.autocomplete.loadAutocomplete({
  //     aerzte: ['strasse', 'postleitzahl', 'ort'],
  //     patienten: ['strasse', 'postleitzahl', 'ort'],
  //   })
  // }, [])

  // const [autocompleteStreet, setAutocompleteStreet] = useState<Array<AutocompleteOption>>([])
  // const [autocompleteZipCode, setAutocompleteZipCode] = useState<Array<AutocompleteOption>>([])
  // const [autocompleteCity, setAutocompleteCity] = useState<Array<AutocompleteOption>>([])

  // useEffect(() => {
    // let newAutocompleteStreet: Array<AutocompleteOption> = []
    // let newAutocompleteZipCode: Array<AutocompleteOption> = []
    // let newAutocompleteCity: Array<AutocompleteOption> = []

    // if(autocomplete.patienten?.strasse && autocomplete.aerzte?.strasse) {
    //   newAutocompleteStreet = [...autocomplete.patienten.strasse, ...autocomplete.aerzte.strasse].map(
    //     (value: string) => ({text: value, value})
    //   )
    // }
    // if(autocomplete.patienten?.postleitzahl && autocomplete.aerzte?.postleitzahl) {
    //   newAutocompleteZipCode = [...autocomplete.patienten.postleitzahl, ...autocomplete.aerzte.postleitzahl].map(
    //     (value: string) => ({text: value, value})
    //   )
    // }
  //   if(autocomplete.patienten?.ort && autocomplete.aerzte?.ort) {
  //     newAutocompleteCity = [...autocomplete.patienten.ort, ...autocomplete.aerzte.ort].map(
  //       (value: string) => ({text: value, value})
  //     )
  //   }

  //   setAutocompleteStreet(newAutocompleteStreet)
  //   setAutocompleteZipCode(newAutocompleteZipCode)
  //   setAutocompleteCity(newAutocompleteCity)
  // }, [autocomplete])

  const onSubmit = useCallback(async (data) => {
    if (submitting)
      return;
    await onSubmitFunc(data);
  }, [submitting, onSubmitFunc]);

  return (
    <StyledDoctorForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="container">
        <div className="formGroup">
          <SelectField {...titel} label="akad. Titel" disabled={viewOnly} options={titles} />
          <InputField
             {...nachname}
             label="Nachname *"
             error={nachname.touched && !!nachname.error}
             helperText={nachname.touched && !!nachname.error ? nachname.error : ''}
             readOnly={viewOnly}
          />
          <InputField
            {...vorname}
            label="Vorname"
            error={vorname.touched && !!vorname.error}
            helperText={vorname.touched && !!vorname.error ? vorname.error : ''}
            readOnly={viewOnly}
          />
          <InputField
            {...praxisbezeichnung}
            label="Praxisbezeichnung"
            readOnly={viewOnly}
          />
          <InputField
            {...lanr}
            label="Arzt-Nr. (LANR)"
            placeholder="von HVO oder Stempel"
            error={lanr.touched && !!lanr.error}
            helperText={lanr.touched && !!lanr.error ? lanr.error : ''}
            readOnly={viewOnly}
          />
          <InputField
            {...bsnr}
            label="Betriebsstätten-Nr. (BSNR)"
            placeholder="von HVO oder Stempel"
            error={bsnr.touched && !!bsnr.error}
            helperText={bsnr.touched && !!bsnr.error ? bsnr.error : ''}
            readOnly={viewOnly}
          />
          <InputField
            label="Fachrichtung (wird aus LANR ermittelt)"
            placeholder="keine manuelle Eingabe"
            value={aerzteFachgruppe?.name}
            readOnly={true}
          />
        </div>
        <div className="formGroup">
          <div className="streetGroup">
            <InputField
              {...strasse}
              disabled={viewOnly}
              readOnly={viewOnly}
              label="Straße"
              autoComplete="new-password"
              error={strasse.touched && !!strasse.error}
              helperText={strasse.touched && !!strasse.error ? strasse.error : ''}
            />
            {/* <AutocompleteField
              {...strasse}
              label="Straße"
              readOnly = {viewOnly}
              options={autocompleteStreet}
              freeSolo
              autoSelect
            /> */}
            <InputField
              {...hausnummer}
              disabled={viewOnly}
              readOnly={viewOnly}
              label="Nr."
              autoComplete="new-password"
              error={hausnummer.touched && !!hausnummer.error}
              helperText={hausnummer.touched && !!hausnummer.error ? hausnummer.error : ''}
            />
          </div>
          <div className="addressGroup">
            <InputField
              {...postleitzahl}
              disabled={viewOnly}
              readOnly={viewOnly}
              label="PLZ"
              autoComplete="new-password"
              error={postleitzahl.touched && !!postleitzahl.error}
              helperText={postleitzahl.touched && !!postleitzahl.error ? postleitzahl.error : ''}
            />
            {/* <AutocompleteField
              {...postleitzahl}
              label="PLZ"
              readOnly = {viewOnly}
              options={autocompleteZipCode}
              freeSolo
              autoSelect
            /> */}
            <InputField
              {...ort}
              disabled={viewOnly}
              readOnly={viewOnly}
              label="Ort"
              autoComplete="new-password"
              error={ort.touched && !!ort.error}
              helperText={ort.touched && !!ort.error ? ort.error : ''}
            />
            {/* <AutocompleteField
              {...ort}
              label="Ort"
              readOnly = {viewOnly}
              options={autocompleteCity}
              freeSolo
              autoSelect
            /> */}
          </div>
          <InputField
            {...telefon}
            label="Telefon"
            readOnly={viewOnly}
          />
          <InputField
            {...fax}
            label="Fax"
            readOnly={viewOnly}
          />
          <InputField
            {...mobil}
            label="Mobil"
            readOnly={viewOnly}
          />
          <InputField
            {...email}
            label="E-Mail"
            readOnly={viewOnly}
          />
          <InputField
            {...website}
            label="Website"
            readOnly={viewOnly}
          />
        </div>
      </div>

      <InputField
        {...notizen}
        className="notesField"
        label="Notizen"
        multiline
        rows={5}
        readOnly={viewOnly}
      />

      {!viewOnly && (<>
        <Divider />
        <Button
          className="submitButton"
          type="submit"
          variant="contained"
          color="secondary"
          disabled={busy || (invalid && submitFailed) || pristine || submitting}
          fullWidth
        >
          {submitText}
        </Button>
      </>)}
    </StyledDoctorForm>
  );
}

const validate = (values) => {
  const required = ['nachname'];

  const errors: KeyValue<string> = {};

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!';
    }
  });

  const aerzteNummern = ['lanr', 'bsnr'];

  aerzteNummern.forEach((fieldName) => {
    if (values[fieldName] && (values[fieldName].length !== 9 || !/^\d+$/.test(values[fieldName]))) {
      errors[fieldName] = 'Die ' + fieldName.toUpperCase() + ' muss aus genau neun Ziffern bestehen!';
    }
  });

  return errors;
};

const mapStateToProps = (state) => ({
  // autocomplete: state.autocomplete,
  busy: state.notification.busy,
  aerzteFachgruppen: state.aerzteFachgruppen,
});

const mapDispatchToProps = (dispatch) => ({
  // actions: {
  //   autocomplete: bindActionCreators(autocompleteActions, dispatch),
  // },
});

export default reduxForm(
  {
    form: 'doctorForm',
    validate,
    fields,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(DoctorForm));

export const ViewArztForm = reduxForm(
  {
    form: 'viewArztForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(DoctorForm));
