import {
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@material-ui/icons';
import { FC } from 'react';
import { StyledMessage } from './StyledMessage';

interface Props {
  message?: string | JSX.Element;
}

export const MessageSuccess: FC<Props> = ({message}) => {
  return (
    <StyledMessage>
      <CheckCircleOutlineIcon className="success"/>&nbsp;{message}
    </StyledMessage>
  ) 
}
