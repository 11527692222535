import { format, isValid } from 'date-fns'
import { default as de } from 'date-fns/locale/de'
import { FC, memo, useCallback } from 'react'
import {
  StyledCalendarColumnHeaderDate,
  StyledCalendarColumnHeaderDay,
  StyledCalendarColumnHeaderDayFreeDates,
  StyledCalendarColumnHeaderDaySmall,
  StyledCalendarColumnHeaderPerson,
  StyledCalendarColumnHeaderPersonSmall, StyledCalendarColumnHeaderRoom, StyledCalendarColumnHeaderWeek
} from './StyledCalendarColumnHeader'

import { Tooltip } from '@material-ui/core'
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { addDays } from 'date-fns'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as dateActions from '../../actions/dates'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'

interface Props {
  flavor: 'date' | 'day' | 'day_small' | 'day_freedates' | 'person' | 'person_small' | 'week' | 'room'
  person?: any
  room?: any
  date?: Date
  computedWidth?: number
  selectDay: any
  selectedDay: Date
}

const CalendarColumnHeader: FC<Props> = ({
  selectDay,
  selectedDay,
  flavor,
  person,
  date,
  computedWidth,
  room,
}): JSX.Element | null => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const today = new Date()
  const plus1day = addDays(selectedDay || today, 1)
  const minus1day = addDays(selectedDay || today, -1)

  const handleClick = useCallback(
    (date) => {
      selectDay(date)
      navigate(`/${serverHash}/calendar/day/${format(date, ISO8601DATE_FNS)}`)
    },
    [selectDay, navigate, serverHash],
  )

  switch (flavor) {
    case 'person':
      const avatar = person.avatar || person.avatarFallback

      return (
        <StyledCalendarColumnHeaderPerson person={person}>
          <span className={avatar ? 'flavorPersonName avatar' : 'flavorPersonName'}>
            <span>{`${person.vorname} ${person.nachname[0]}.`}</span>
            <img className="flavorPersonAvatar" src={avatar} />
          </span>
        </StyledCalendarColumnHeaderPerson>
      )

    case 'person_small':
      return (
        <StyledCalendarColumnHeaderPersonSmall person={person}>
          <span className="flavorPersonSmallName">
            <span>{person.vorname ? person.vorname[0] : ''}</span>
          </span>
        </StyledCalendarColumnHeaderPersonSmall>
      )

    case 'room':
      return (
        <StyledCalendarColumnHeaderRoom room={room}>
          <span className="flavorRoomName">
            <span>{room.abbreviation}</span>
          </span>
        </StyledCalendarColumnHeaderRoom>
      )

    case 'date':
      if (!date || typeof isValid !== 'function' || !isValid(date)) {
        return null
      }

      return (
        <StyledCalendarColumnHeaderDate>
          <Tooltip title="Tag zurück" placement="top">
            <ChevronLeftIcon
              onClick={() => handleClick(minus1day)}
              className="flavorArrowIcon flavorArrowIcon--left"
              color="inherit"
            />
          </Tooltip>

          <div className="flavorDateInfo">
            <span className="flavorDateDayName">{format(date, 'EEEE', { locale: de })}</span>
            <span className="flavorDateDayNum">{format(date, 'dd')}</span>
          </div>

          <Tooltip title="Tag vor" placement="top">
            <ChevronRightIcon
              onClick={() => handleClick(plus1day)}
              className="flavorArrowIcon flavorArrowIcon--right"
              color="inherit"
            />
          </Tooltip>
        </StyledCalendarColumnHeaderDate>
      )

    case 'week':
      if (!date || typeof isValid !== 'function' || !isValid(date)) {
        return null
      }

      return (
        <StyledCalendarColumnHeaderWeek>
          <span>
            KW{' '}
            {format(date, 'II', {
              weekStartsOn: 1, // (0 - Sunday, 1- Monday, ...).
            })}
          </span>
        </StyledCalendarColumnHeaderWeek>
      )

    case 'day':
      if (!date || typeof isValid !== 'function' || !isValid(date)) {
        return null
      }

      return (
        <StyledCalendarColumnHeaderDay>
          <span className="flavorDayText">
            {format(date, 'EEEE', { locale: de })} {format(date, 'dd')}.{format(date, 'MM')}.
          </span>
        </StyledCalendarColumnHeaderDay>
      )

    case 'day_small':
      if (!date || typeof isValid !== 'function' || !isValid(date)) {
        return null
      }

      return (
        <StyledCalendarColumnHeaderDaySmall>
          <span>
            {format(date, 'EEEEEE', { locale: de })} {format(date, 'dd')}
          </span>
        </StyledCalendarColumnHeaderDaySmall>
      )

    case 'day_freedates':
      if (!date || typeof isValid !== 'function' || !isValid(date)) {
        return null
      }

      return (
        <StyledCalendarColumnHeaderDayFreeDates computedWidth={computedWidth as number}>
          <span className="flavorDayFreeDatesText">
            {format(date, 'EEEE', { locale: de })} {format(date, 'dd')}.{format(date, 'MM')}.
          </span>
        </StyledCalendarColumnHeaderDayFreeDates>
      )

    default:
      return null
  }
}

const mapStateToProps = (state) => ({
  selectedDay: state.selectedDay,
})

const mapDispatchToProps = (dispatch) => ({
  selectDay: bindActionCreators(dateActions.selectDay, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalendarColumnHeader))
