import { Typography } from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon
} from '@material-ui/icons';
import lodash from 'lodash';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { connect } from 'react-redux';
import { sCredentialsDowngraded } from '../../../utils/auth';
import { ListStyledLink } from '../ListStyledLink';
import UpgradeCredentialsForm from '../UpgradeCredentialsForm/UpgradeCredentialsForm';
import { StyledPracticesList } from './StyledPracticesList';

interface Props {
  limit?: number,
  practices: Array<any>,
  currentPracticeId: number,
  credentialsDowngraded?: boolean | string
}

const PracticesList: FC<Props> = ({
  limit = 4,
  practices = [], // full list, including current practice
  currentPracticeId,
  credentialsDowngraded,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [practiceToOpen, setPracticeToOpen] = useState(null)

  const [practicesWithoutCurrent, setPracticesWithoutCurrent] = useState<Array<any>>([]);
  const [currentPracticesList, setCurrentPracticesList] = useState<Array<any>>([]);

  useEffect(() => {
    const data = lodash
      .cloneDeep(practices)
      .filter(practice => practice.id !== currentPracticeId);
    setPracticesWithoutCurrent(data)
  }, [practices, currentPracticeId])

  useEffect(() => {
    if(showAll) {
      setCurrentPracticesList(practicesWithoutCurrent)
    } else {
      setCurrentPracticesList(practicesWithoutCurrent.slice(0, limit))
    }
  }, [limit, practicesWithoutCurrent, showAll])

  const createHandlePracticeClick = useCallback((event, practice) => {
    if (!credentialsDowngraded) {
      window.location.assign(`/${practice.nameHash}/calendar`)
      // navigate(`/${practice.nameHash}/calendar`, {replace: true})
    } else {
      // This prevents ghost click.
      event.preventDefault()
      setPopoverOpen(true)
      setAnchorEl(event.currentTarget)
      setPracticeToOpen(practice)
    }
  }, [credentialsDowngraded, setPopoverOpen, setAnchorEl, setPracticeToOpen]);

  const handleRequestClose = useCallback(() => {
    setPopoverOpen(false)
  }, [setPopoverOpen]);

  const toggleShowAll = useCallback(() => {
    setShowAll(prevState => !prevState)
  }, [setShowAll]);

  return (
    <StyledPracticesList>
      {
        currentPracticesList.map((practice) => (
          <ListStyledLink
            className="practicesListStyledLink left"
            onClickFunc={(event) => createHandlePracticeClick(event, practice)}
            key={practice.id}>
              <div className="praxisListAvatar">
                <Jdenticon  size="25" value={
                  [practice?.name, practice?.nameHash, practice?.description].filter(Boolean).join('')
                } />
              </div>
              <div className="praxisListText">
                {
                  practice?.description ? (
                    <Typography variant="body1">
                      {practice.description}
                    </Typography>
                  ): null
                }
                {
                  practice?.praxisInhaber ? (
                    <Typography variant="caption">
                      {practice.praxisInhaber}
                    </Typography>
                  ): null
                }
              </div>
          </ListStyledLink>)
        )
      }
      { practicesWithoutCurrent.length > limit
        ? (
          <ListStyledLink
            className="practicesListStyledLink left practicesListStyledLink--more-less"
            onClickFunc={toggleShowAll}>
              <div className="practicesListStyledLink__icon">
                {showAll 
                  ? <ArrowDropUpIcon  />
                  : <ArrowDropDownIcon />}
              </div>
              <Typography variant="body1">
                {showAll ? 'weniger anzeigen' : 'alle anzeigen'}
              </Typography>
          </ListStyledLink>
        ): null
      }
      { popoverOpen &&
        <UpgradeCredentialsForm
          open={popoverOpen}
          anchorEl={anchorEl}
          onRequestClose={handleRequestClose}
          practiceToOpen={practiceToOpen}
        />
      }
    </StyledPracticesList>
  )
}

const mapStateToProps = (state) => ({
  credentialsDowngraded: sCredentialsDowngraded(state),
});

export default connect(mapStateToProps)(memo(PracticesList));
