import styled from 'styled-components';

export const StyledError404 = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    padding: ${theme.spacing.desktopGutterMore};

    .h2 {
      color: ${theme.palette.primary.dark};
      font-size: 40px;
    }
  `}
`;
