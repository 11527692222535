import { ListItemIcon } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { FC, useRef, useState } from 'react'
import MenuItemsHeilmittelverordnung from './MenuItemsHeilmittelverordnung'
import { StyledMenuHeilmittelverordnung } from './StyledMenuHeilmittelverordnung'

interface Props {
  selectedHeilmittelverordnung?: any,
  handleMenuClose?: () => void,
}

const IconMenuHeilmittelverordnung: FC<Props> = ({
  selectedHeilmittelverordnung = {},
  handleMenuClose,
}) => {

  const iconRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (event) => {
    event?.stopPropagation()
    setOpen(true)
  }

  return (<>
    <ListItemIcon
      style={{
        minWidth: 'unset',
        cursor: 'pointer'
      }}
      onClick={handleOpen}
      ref={iconRef}>
      <MoreVertIcon />
    </ListItemIcon>
    <StyledMenuHeilmittelverordnung
      PaperProps={{ className: 'paper' }}
      open={open}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorEl={iconRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableAutoFocus>
        <MenuItemsHeilmittelverordnung
          heilmittelverordnung={selectedHeilmittelverordnung}
          hvoView={false}
          calendarView={false}
          onCloseFunc={() => {
            handleClose()
            handleMenuClose && handleMenuClose()
          }}
        />
    </StyledMenuHeilmittelverordnung>
  </>)
}

export default IconMenuHeilmittelverordnung
