import { Build as BuildIcon } from '@material-ui/icons'
import { useState } from 'react'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import EquipmentSettingsForm from './EquipmentSettingsForm'
import EquipmentSettingsSidebar from './EquipmentSettingsSidebar'
import { EquipmentSettingsContainer } from './StyledEquipmentSettings'

export const EquipmentSettings = (): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const [selectedEquipment, setSelectedEquipment] = useState<number | null>(null)

  const handleAddNewClick = (): void => {
    setSelectedEquipment(null)
    setShowForm(true)
  }

  const handleExistingClick = (roomId): void => {
    console.log('exisiting clicked: ', roomId)
    setSelectedEquipment(roomId)
    setShowForm(true)
  }

  const handleCancelChanges = (): void => {
    setShowForm(false)
    setSelectedEquipment(null)
  }

  return (
    <>
      <IconHeading icon={<BuildIcon />} text="Ausstattung der Praxis" />
      <EquipmentSettingsContainer>
        <EquipmentSettingsSidebar
          selectedEquipment={selectedEquipment}
          onExistingClick={handleExistingClick}
          onAddNewClick={handleAddNewClick}
        />
        <EquipmentSettingsForm
          isOpen={showForm}
          selectedEquipment={selectedEquipment}
          onCancelChanges={handleCancelChanges}
        />
      </EquipmentSettingsContainer>
    </>
  )
}
