import { CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import { StyledDataLoading } from './StyledDataLoading';

interface Props {
  text: string | Element
}

const DataLoading: FC<Props> = ({text}) => (
  <StyledDataLoading>
    <div className="circularProgress">
      <CircularProgress size={100} thickness={7} />
      <div className="dataLoadingText">
        {text}
      </div>
    </div>
  </StyledDataLoading>
);

export default DataLoading;
