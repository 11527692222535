import styled from 'styled-components';

export const StyledPraxisstammdatenForm = styled.form`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px;

    .gridContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(8) * 2}px;
    }

    .formGroup {
      display: grid;
      grid-auto-rows: min-content;
      grid-row-gap: ${theme.spacing(4)}px;
    }

    .streetGroup {
      display: grid;
      grid-template-columns: 1fr 0.25fr;
      grid-column-gap: ${theme.spacing(8)}px;
    }

    .addressGroup {
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      grid-column-gap: ${theme.spacing(8)}px;
    }

    .icsSourcesContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(8) * 2}px;
      grid-row-gap: ${theme.spacing(8)}px;
    }

    .icsSource {
      display: grid;
      grid-row-gap: ${theme.spacing(4)}px;
    }

    .icsSourceButtonContainer {
      display: grid;
      grid-template-columns: repeat(2, 25%);
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .subHeader {
      font-weight: 500;
    }

    .supportLink {
      color: ${theme.palette.secondary.main};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .durationGridSettings {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: ${theme.spacing(8) * 2}px;
    }

    .calendarFeatures {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: ${theme.spacing(2)}px;
      margin-top: ${theme.spacing(4)}px;
    }
  `}
`;
