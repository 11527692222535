import * as types from '../constants/actionTypes'
import { isValid } from 'date-fns'

const initialState = {
  selectedDay: new Date(),
}

export default function (state = initialState, action = undefined) {
  switch (action.type) {
    case types.KASSENBUCH_SELECT_DAY:
      if (action.date && typeof isValid === 'function' && isValid(action.date)) {
        return {
          ...state,
          selectedDay: action.date,
        }
      }
      break
    default:
  }

  return state
}
