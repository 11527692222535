import auth0 from 'auth0-js'
import * as R from 'ramda'
import { startOfDay, addDays, addHours } from 'date-fns'

const www = window.location.href.indexOf('www') !== -1 && window.location.href.indexOf('www') < 10 ? 'www.' : ''
const http = window.location.href.indexOf('https://') === 0 ? 'https://' : 'http://'
const oldUrlFix =
  window.location.href.indexOf('pododesk-production.herokuapp') !== -1
    ? 'pododesk-production.herokuapp.com/startbereich'
    : __AUTH_REDIRECT__

export const WebAuth = new auth0.WebAuth({
  domain: __AUTH_DOMAIN__,
  clientID: __AUTH_CLIENT__,
  redirectUri: `${http}${www}${oldUrlFix}`,
  audience: __AUTH_AUDIENCE__,
  responseType: 'token',
  scope: 'openid',
})

// exported explicitly without passing variables for button onClicks
export const redirectAuth = () => {
  WebAuth.authorize()
}

// local check for validity
// move to selectors ?
export const isAuthenticated = (auth) => {
  const { expiresAt } = auth
  // TODO renew token
  return expiresAt - Date.now() > 0
}

export const authenticateLocally = async function (replaceRoute) {
  const { localStorage, sessionStorage } = window
  // try hash
  if (/access_token|error/.test(window.location.hash)) {
    try {
      const authResult = await new Promise((resolve, reject) => {
        WebAuth.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken) {
            resolve(authResult)
          } else {
            reject(err)
          }
        })
      })

      // clear hash
      history.pushState('', document.title, window.location.pathname)

      const expiresAt = addHours(addDays(startOfDay(new Date()), 1), 3).getTime()
      localStorage.setItem('access_token', authResult.accessToken)
      localStorage.setItem('expires_at', expiresAt)
      localStorage.removeItem('locked_instance')

      const redirectPath = sessionStorage.getItem('login_redirect_path')
      if (redirectPath) {
        sessionStorage.removeItem('login_redirect_path')
        await replaceRoute(redirectPath)
      }

      return {
        accessToken: authResult.accessToken,
        expiresAt,
      }
    } catch (err) {
      console.log(err)
      return undefined
    }
  }
  // try local
  const storageResult = {
    accessToken: localStorage.getItem('access_token'),
    expiresAt: localStorage.getItem('expires_at'),
  }
  if (R.reduce(R.and, true, Object.values(storageResult)) && isAuthenticated(storageResult)) {
    return {
      ...storageResult,
      lockedInstance: localStorage.getItem('locked_instance'),
    }
  }
}
