import { emphasize } from '@material-ui/core/styles/colorManipulator';
import styled from 'styled-components';

export const StyledItem = styled.div`
  ${({
    theme,
    gridConfig: { allDayItemHeight, allDayItemMargin },
    backgroundColor = theme.palette.highlight.main
  }) => `
    box-sizing: border-box;
    position: relative;
    display: block;
    cursor: pointer;
    font-size: 12px;
    flex: 1;
    margin-bottom: ${allDayItemMargin}px;
    height: ${allDayItemHeight}px;
    opacity: 1;
    border-right: 1px solid white;

    .isDragging: {
      opacity: 0.4;
    }

    .inner{
      box-sizing: border-box;
      position: absolute;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      background-color: ${backgroundColor};
      padding: 1px 5px 1px 5px;
      height:100%;
      transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
      :hover {
        background-color: ${emphasize(backgroundColor)};
      }
    }
    .wrapTooltipText{
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `
}`;
