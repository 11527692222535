import { emphasize, fade, Theme } from '@material-ui/core/styles'
import styled from 'styled-components'

const getBackGroundColor = (
  istOrganisatorisch: boolean,
  hausbesuch: boolean,
  vorbereitungsbedarf: boolean,
  absagegrund: string | null,
  beginn: Date,
  theme: Theme,
) => {
  let backGroundColor

  if (istOrganisatorisch) {
    backGroundColor = theme.palette.appointments.organizational
  } else if (!istOrganisatorisch && absagegrund === 'ohne Absage nicht erschienen') {
    backGroundColor = fade(theme.palette.primary.main, 0.4)
  } else if (!istOrganisatorisch && !hausbesuch && !vorbereitungsbedarf) {
    backGroundColor = beginn < new Date() ? fade(theme.palette.primary.main, 0.6) : theme.palette.primary.main
  } else if (!istOrganisatorisch && !hausbesuch && vorbereitungsbedarf) {
    backGroundColor =
      beginn < new Date() ? fade(theme.palette.appointments.preparations, 0.6) : theme.palette.appointments.preparations
  } else if (!istOrganisatorisch && hausbesuch) {
    backGroundColor =
      beginn < new Date() ? fade(theme.palette.appointments.houseCall, 0.6) : theme.palette.appointments.houseCall
  }

  return backGroundColor
}

const getBackGroundHoverColor = (
  istOrganisatorisch: boolean,
  hausbesuch: boolean,
  vorbereitungsbedarf: boolean,
  theme: Theme,
) => {
  let backGroundHoverColor

  if (istOrganisatorisch) {
    backGroundHoverColor = emphasize(theme.palette.appointments.organizational, 0.05)
  } else if (!istOrganisatorisch && !hausbesuch && !vorbereitungsbedarf) {
    backGroundHoverColor = theme.palette.primary.dark
  } else if (!istOrganisatorisch && !hausbesuch && vorbereitungsbedarf) {
    backGroundHoverColor = emphasize(theme.palette.appointments.preparations, 0.05)
  } else if (!istOrganisatorisch && hausbesuch) {
    backGroundHoverColor = emphasize(theme.palette.appointments.houseCall, 0.05)
  }

  return backGroundHoverColor
}

const getRootBGColor = (
  terminIstAusserhalbDerZeiten: boolean,
  terminIstAusserhalbDerArbeitszeiten: boolean,
  terminIstAusserhalbDerOeffnungszeiten: boolean,
) => {
  let rootBGColor

  if (terminIstAusserhalbDerZeiten) {
    if (terminIstAusserhalbDerArbeitszeiten) {
      rootBGColor = 'rgba(192, 192, 192, 0.4)'
    }
    if (terminIstAusserhalbDerOeffnungszeiten) {
      rootBGColor = 'rgba(128, 128, 128, 0.4)'
    }
  } else {
    rootBGColor = 'transparent'
  }

  return rootBGColor
}

interface Props {
  theme: Theme
  istOrganisatorisch: boolean
  hausbesuch: boolean
  vorbereitungsbedarf: boolean
  absagegrund: string | null
  beginn: Date
  terminIstAusserhalbDerZeiten: boolean
  terminIstAusserhalbDerOeffnungszeiten: boolean
  terminIstAusserhalbDerArbeitszeiten: boolean
  computedStyles: {
    height: number
    top: number
  }
  innerBottomHeight?: number
}

export const StyledCalendarDate = styled.div`
  ${({
    theme,
    istOrganisatorisch,
    hausbesuch,
    vorbereitungsbedarf,
    absagegrund,
    beginn,
    terminIstAusserhalbDerZeiten,
    terminIstAusserhalbDerOeffnungszeiten,
    terminIstAusserhalbDerArbeitszeiten,
    showAllFree,
    computedStyles,
    innerBottomHeight,
  }: Props) => `
  .calendarDateRoot {
    position: absolute;
    z-index: 4;
    box-sizing: border-box;
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    opacity: 1;
    background-color: ${getRootBGColor(
      terminIstAusserhalbDerZeiten,
      terminIstAusserhalbDerArbeitszeiten,
      terminIstAusserhalbDerOeffnungszeiten,
    )};
    height: ${computedStyles.height}px;
    top: ${computedStyles.top}px;

    &.isDragging: {
      opacity: 0.4;
    }

    &.smallPadding {
      padding-left: 1px;
      padding-right: 1px;
    }

    &.available {
      opacity: ${terminIstAusserhalbDerZeiten ? '1' : '0'};
      z-index: 2;

      &:hover {
        opacity: 1;
        // z-index: 3;
      }

      &.showAllFree {
        opacity: 1;
      }
    }
  }

  .calendarDateSmallPadding {
    padding-left: 1px;
    padding-right: 1px;
  }

  .calendarDateInner {
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 2px;
    height: 100%;
    background-color: ${getBackGroundColor(
      istOrganisatorisch,
      hausbesuch,
      vorbereitungsbedarf,
      absagegrund,
      beginn,
      theme,
    )};
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    border-color: #fff;
    border-width: 1px;
    border-top-style: solid;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    :hover {
      background-color: ${getBackGroundHoverColor(istOrganisatorisch, hausbesuch, vorbereitungsbedarf, theme)};
      border-color: ${getBackGroundColor(
        istOrganisatorisch,
        hausbesuch,
        vorbereitungsbedarf,
        absagegrund,
        beginn,
        theme,
      )};
    }
  }

  .calendarDateInnerAvailable {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${fade(theme.palette.primary.dark, 0.3)};
    opacity: ${terminIstAusserhalbDerZeiten ? '0' : '1'};

    &.isOver {
      background-color: #fff;
      opacity: 1
    }

    &:hover {
      opacity: 1;
    }

    &.extended {
      z-index: 100;
      top: -100%;
      pointerEvents: none;
      height: ${innerBottomHeight}px;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  .calendarDateAvailableIcon {
    transition: none;
    opacity: 1;
    margin: auto;
    font-size: 12px;

    &.showAllFree {
      opacity: 0;
    }

    &.isOver {
      opacity: 1;
    }
  }

  .calendarDateName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .calendarDateBeginn {
    padding-right: 3px;
  }

  .patientName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
   }

  .calendarDateDetails {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .calendarDateCurrentTime {
    position: absolute;
    z-index: 5;
    opacity: 0.5;
    width: 100%;
    border-top: 3px solid red;
    height: ${computedStyles.height}px;
    top: ${computedStyles.top}px;
  }

  .shortTermin {
    display: flex;

    div {
    width: auto;
    }
  }
`}
`
