import { ListItemIcon } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { FC, useRef, useState } from 'react'
import MenuItemsTermin from './MenuItemsTermin'
import { StyledMenuTermin } from './StyledMenuTermin'

interface Props {
  terminId?: number,
  handleMenuClose?: () => void,
  dialogActions?: any
}

const IconMenuTermin: FC<Props> = ({
  terminId,
  handleMenuClose,
  dialogActions = {}
}) => {

  const iconRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (event) => {
    event?.stopPropagation()
    setOpen(true)
  }

  return (<>
    <ListItemIcon
      style={{
        minWidth: 'unset',
        cursor: 'pointer'
      }}
      onClick={handleOpen}
      ref={iconRef}>
      <MoreVertIcon />
    </ListItemIcon>
    <StyledMenuTermin
      PaperProps={{ className: 'paper' }}
      open={open}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorEl={iconRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableAutoFocus>
        <MenuItemsTermin
          dialogActions={dialogActions}
          selectedTermin={terminId}
          hvoView={false}
          calendarView={false}
          onCloseFunc={() => {
            handleClose()
            handleMenuClose && handleMenuClose()
          }}
        />
    </StyledMenuTermin>
  </>)
}

export default IconMenuTermin
