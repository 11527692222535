import { Theme } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  theme: Theme;
  borderStart?: boolean;
  borderEnd?: boolean;
}

export const StyledLinkWrapper = styled.div<Props>`
  ${({ theme, borderStart, borderEnd }: { theme: Theme; borderStart?: boolean; borderEnd?: boolean }) => `
    width: 100%;
    white-space: nowrap;

    .button {
      min-width: 200px;
      border-left: ${borderStart ? 'solid 1px #fff' : 'none'};
      border-right: ${borderEnd ? 'solid 1px #fff' : 'none'};
      background-color: ${theme.palette.primary.main};
      border-radius: 0;
      text-transform: none;
      font-size: 14px;
      height: 32px;

      &.active {
        background-color: ${theme.palette.primary.dark};
      }

      &:hover {
        transition: background-color 0.5s ease-in-out;
        background-color: ${theme.palette.primary.dark};
      }
    }
  `}
`
