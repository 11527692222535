import { GERMAN_DATE_SHORT_YEAR_FNS } from '../constants/dateFormats'
import { format } from 'date-fns'

export function buildPatientString(patient, showGeburtsdatum = true) {
  if (!patient) return ''
  return `${patient.nachname && patient.nachname}${patient.vorname ? ', ' + patient.vorname : ''}${
    patient.verstorben ? ' \u2020' : ''
  }${patient.geburtsdatum && showGeburtsdatum ? ' *' + format(patient.geburtsdatum, GERMAN_DATE_SHORT_YEAR_FNS) : ''}${
    patient.abgewandertZu ? ' (' + patient.abgewandertZu + ')' : ''
  }`
}
