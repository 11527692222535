import { Theme } from '@material-ui/core'
import styled from 'styled-components'

export const StyledAvatar = styled.div`
  ${({ theme }: { theme: Theme }) => `
    .avatarPaperContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${theme.spacing(4)}px;
    }

    .avatarButtonContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(4)}px;
    }

    .avatarFileInput {
      display: none;
    }

    .avatarImgWrapper {
      margin-top: -${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
      display: flex;
      justify-content: center;
    }

    .avatarImg {
      border: solid 1px black;
      width: 100%;
    }

    .avatarPaper {
      padding: ${theme.spacing(4)}px;
      width: 400px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(4)}px;
      grid-auto-rows: min-content;
    }

    h2: {
      font-weight: 500;
    }

    .textArea {
      > h2 {
        margin: 0;
        font-weight: normal;
      }
    }
  `}
`
