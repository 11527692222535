import styled from 'styled-components';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: calc(100vh - 64px); */
  min-height: 100vh;

  >.container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
  }

  // @media (max-width: 406px) {
  //   >.layout-container {
  //     padding-top: 213px;
  //   }
  // }

  // @media (min-width: 407px) and (max-width: 545px) {
  //   >.layout-container {
  //     padding-top: 154px;
  //   }
  // }

  // @media (min-width: 546px) and (max-width: 900px) {
  //   >.layout-container {
  //     padding-top: 63px;
  //   }
  // }

  .MuiToolbar-root {
    min-height: 0 !important;
  }

  .content {
    display: flex;
    flex-grow: 1;
    // min-height: calc(100vh - 64px);
    overflow-y: auto;
    /* height: 100%; */
    /* height: calc(100vh - 64px); */
  }

  .main-content-wrapper {
    overflow-y: visible;
    // overflow-x: auto;
  }
`;
