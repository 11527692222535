import styled from 'styled-components';

export const StyledDoctorsList = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    .main {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding-bottom: 10px;
    }

    .topBarAlphabetFilter {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      height: unset !important;
    }

    .topBarIconButton {
      width: 60px;
      height: 46px;
      z-index: 1;
      border-radius: 0;
    }

    .topBarButtonWrapper {
      background-color: ${theme.palette.primary.main};
      transition: background-color 0.3s ease-in-out;
      border: 1px solid #fff;
      border-top: none;
      border-bottom: none;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    .paper {
      margin-top: 50px;
      padding: 20px;
      max-width: 800px;
      width: 100%;
      align-self: center;
    }

    .circularProgress {
      align-self: center;
    }
  `}
`;
