import { TableContainer } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDoctorsListView = styled(TableContainer)`
  ${({ theme }) => `
    .tableHead {
      background-color: ${theme.palette.primary.dark};
    }

    .doctorsTableRow {
      cursor: pointer;
    }

    .bodyTableCell {
      border-bottom: solid 1px ${theme.palette.highlight.main};
      border-right: solid 1px ${theme.palette.highlight.main};
    }
  `}
`
