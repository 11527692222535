import styled from 'styled-components';

const spacerWidth = 45;
const spacerLineColor = '#c4c4c6';

export const StyledCalendarColumnSpacer = styled.div`
  ${({ gridConfig, allDayGapHeight }: { gridConfig: any; allDayGapHeight: number }) => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: visible;
    width: ${spacerWidth}px;

    .spacerHeader {
      overflow: visible;
      height: ${gridConfig.headerHeight + allDayGapHeight}px;
      width: 45px;
      box-sizing: border-box;

      &.line {
        padding-top: ${spacerWidth / 3}px;
        padding-bottom: ${spacerWidth / 3}px;
      }
    }

    .spacerBody {
      margin-top: -4px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 45px;
    }

    .spacerLine {
      margin-right: auto;
      margin-left: auto;
      width: 0;
      height: 100%;
      flex-grow: 1;
      border-left: 1px solid ${spacerLineColor};
    }

    .spacerMinute {
      position: relative;
      text-align: center;
      font-size: 8px;

      &.row {
        height: ${gridConfig.gridRowHeight}px;
      }
    }

    .spacerHour {
      position: relative;
      text-align: center;
      font-size: 8px;

      &.row {
        height: ${gridConfig.gridRowHeight}px;
      }

      .spacerText {
        font-size: 12px;
      }
    }

    .spacerText {
      display: inline-block;
      margin-top: -4px;
      padding: 1px;
      background-color: #fff;
    }

    .spacerBigHour {
      position: absolute;
      top: -5px;
      left: 0;
      display: block;
      background: #fff;
      font-size: 12px;
    }
  `}
`;
