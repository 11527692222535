import { isWithinInterval, parseISO } from 'date-fns'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { checkHVOConditions } from '../../utils/validation'
import { filterUngueltigeZeiten, getOeffnungsZeiten, istAusserhalbDerZeiten } from '../../utils/zeiten'
import { sAllDayTermineOhneAbgesagte } from '../Calendar/selectors'
import { getIntersectedAllDayAppointments } from '../TerminForm/selectors'

const getArbeitsZeiten = createSelector(
  (_, props) => props.target && props.target.therapeut,
  (state) => state.entities.users,
  (userId, users) => {
    const user = users[userId]
    return user && user.arbeitszeiten ? filterUngueltigeZeiten(user.arbeitszeiten) : []
  },
)

export const istTerminAusserhalbDerOeffnungszeiten = createSelector(
  (_, props) => props.target && props.target.beginn,
  (_, props) => props.target && props.target.ende,
  getOeffnungsZeiten,
  istAusserhalbDerZeiten,
)

export const istTerminAusserhalbDerArbeitszeiten = createSelector(
  (_, props) => props.target && props.target.beginn,
  (_, props) => props.target && props.target.ende,
  getArbeitsZeiten,
  istAusserhalbDerZeiten,
)

const sHeilmittelverordnungen = (state) => state.entities.heilmittelverordnungen
const sTermine = (state) => state.entities.termine
const sTerminHVOId = (_, props) => (props.date ? props.date.heilmittelverordnung : undefined)
const sTerminBeginn = (_, props) => (props.target ? props.target.beginn : undefined)
const sTerminId = (_, props) => (props.date ? props.date.id : undefined)
const sTerminLeistungen = (_, props) => props.date ? props.date.leistungen : undefined

export const sHVOConditions = createSelector(
  sHeilmittelverordnungen,
  sTermine,
  sTerminHVOId,
  sTerminBeginn,
  sTerminId,
  sTerminLeistungen,
  (hvos, termine, terminHVOId, terminBeginn, terminId, terminLeistungen) =>
    checkHVOConditions({ hvos, termine, terminHVOId, terminBeginn, terminId, terminLeistungen }),
)

export const sIcsSources = createSelector(praxisstammdatenSelector, (praxisstammdaten) =>
  praxisstammdaten ? praxisstammdaten.icsSources : [],
)

const sEvents = createSelector(
  (state: StoreState) => state.entities.events,
  (events) => events || {},
)

export const sEvent = createSelector(
  sIcsSources,
  sEvents,
  (_, props) => props.target,
  (icsSources, events, target) => {
    if (!target) {
      return null
    }

    let result = null

    Object.keys(events).some((eventId) => {
      const event = events[eventId]

      if (isWithinInterval(target.beginn, { start: parseISO(event.startDate), end: parseISO(event.endDate) })) {
        const ics = icsSources.find((ics) => {
          return event.icsSourceId === ics.id
        })

        if (ics) {
          result = {
            ...event,
            ...ics,
          }

          return true
        }
      }
    })

    return result
  },
)

const sTargetFromProps = createSelector((_, props) => props.target, R.defaultTo({}))

export const sIntersectedAllDayAppointments = createSelector(
  sTargetFromProps,
  sAllDayTermineOhneAbgesagte,
  getIntersectedAllDayAppointments,
)
