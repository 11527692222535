import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FC } from 'react'
import { connect } from 'react-redux'
import { ActionCreatorsMapObject, bindActionCreators } from 'redux'
import { closeDialog, continueNavigation } from '../../redux/modules/formLeaveConfirmation'

interface Props {
  actions: ActionCreatorsMapObject
  isOpen: boolean
}

const FormLeaveConfirmationDialog: FC<Props> = ({ actions, isOpen }): JSX.Element => (
  <Dialog disableBackdropClick aria-modal open={isOpen}>
    <DialogTitle>Ungesicherte Änderungen</DialogTitle>
    <DialogContent>Wollen Sie Ihre Änderungen wirklich verwerfen?</DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={actions.continueNavigation}>
        Ja, weiter!
      </Button>
      <Button variant="contained" color="secondary" onClick={actions.closeDialog}>
        Abbrechen
      </Button>
    </DialogActions>
  </Dialog>
)

/* This was commented out because FormLeaveConfirmationDialog is not
   implmented without redux yet

const mapStateToProps = (state) => ({
  open: state.formLeaveConfirmation.dialogOpen,
}) */

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ closeDialog, continueNavigation }, dispatch),
})

export default connect(null, mapDispatchToProps)(FormLeaveConfirmationDialog)
