import { Theme } from '@material-ui/core/styles';
import styled from 'styled-components'

export const StyledDisabledQuickSearch = styled.div`
  height: 100%;
  position: relative;
`

export const StyledQuickSearch = styled.div`
  ${({theme}: {theme: Theme}) => `
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 200px;
    position: relative;

    .list {
      outline: none;
    }

    .listItem {
      cursor: pointer;
      &--active {
        background-color: ${theme.palette.primary.main};
      }
    }
    
    .subHeader {
      color: #666;
      background-color: #fff !important;
    }
    .popover {
      overflow-y: auto;
      min-width: 350px;
      outline: none;
    }

    .listContainer {
      outline: none;
      > button {
        margin-left: 16px;
      }
    }
  `}
`
