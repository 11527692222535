/*
“This software (code) is copyright by Worldline Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied,
including without limitation, any warranties of merchantability or fitness for a particular purpose.
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Worldline Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen.
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Worldline Healthcare GmbH
 *
 *  description:                  Usefull tools
 *
 *  history:
 *  *.2023 tpe                    created
 */

import { gunzipSync } from "fflate";

export class Tools {

    // Helper function to convert number to two byte array with unsigned 8 byte integers
    public static convertToTwoByte(value: number): Uint8Array {
        if (value < 0 || value > 65535) {
            throw new Error("ERROR: value out of range. " + this.convertToTwoByte.name);
        } else {
            return new Uint8Array([value >> 8, value]);
        }
    }

    // Helper function to convert number to one byte array with unsigned 8 byte integers
    public static convertToOneByte(value: number): Uint8Array {
        if (value < 0 || value > 255) {
            throw new Error("ERROR: value out of range. " + this.convertToOneByte.name);
        } else {
            return new Uint8Array([value]);
        }
    }

    public static inflateGzip(gzip_data: Uint8Array): string {
        if (gzip_data[0] === 0x1F && gzip_data[1] === 0x8B) {
            const gzip_output = gunzipSync(gzip_data);
            return new TextDecoder("windows-1252").decode(gzip_output);
        } else {
            console.log("Error: No gzip file");
            throw new Error("No gzip file");
        }
    }

    public static convertToLength(array: Uint8Array): number {
        const length = array.length;
        const buffer = Buffer.from(array);
        const result = buffer.readUIntBE(0, length);
        return result;
    }

    public static printBufferHex(printArray: Uint8Array): string {
        const a = [];
        // Convert raw data bytes to hex values just for the sake of showing something.
        // In the "real" world, you"d use data.getUint8, data.getUint16 or even
        // TextDecoder to process raw data bytes.
        for (let i = 0; i < printArray.byteLength; i++) {
            a.push(":" + ("00" + printArray[i].toString(16)).slice(-2));
        }
        const hexString = "0x" + a.join("");
        return hexString;
    }

    // Function returns true if both arrays a and b are equal.
    public static equals(a: Uint8Array, b: Uint8Array): boolean {
        return a.length === b.length && a.every((v, i) => v === b[i]);
    }

}
