import { createSelector } from 'reselect'

export const sGetInitialValues = createSelector(
  (_, props) => props.params.customer,
  (state) => state.entities.patienten,
  (patientIdStr, patienten) => {
    const patientId = parseInt(patientIdStr, 10)
    return patienten && patienten[patientId] ? patienten[patientId] : { dateien: [] }
  },
)
