import MenuItemsTermin from './MenuItemsTermin'
import { StyledMenuTermin } from './StyledMenuTermin'

const ContextMenuTermin = ({
  hvoView,
  calendarView,
  selectedTermin,
  pointerLocation: { mouseX, mouseY },
  onCloseFunc,
  dialogActions,
}) => {
  return (
    <StyledMenuTermin
      open={mouseX !== null && mouseY !== null}
      onClose={onCloseFunc}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}
    >
      <MenuItemsTermin
        dialogActions={dialogActions}
        selectedTermin={selectedTermin}
        hvoView={hvoView}
        calendarView={calendarView}
        onCloseFunc={onCloseFunc}
      />
    </StyledMenuTermin>
  )
}

export default ContextMenuTermin
