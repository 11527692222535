import { AppBar } from '@material-ui/core';
import styled from 'styled-components';

export const StyledHeader = styled(AppBar)`
  ${({ theme }) => `
    background-color: #fff;
    border-bottom: 1px solid ${theme.palette.primary.main};
    position: sticky;
    height: 64px !important;
    width: 100%;

    .headerButton {
      margin-right: ${theme.spacing(2)}px;
    }

    .tools {
      display: flex;
      justify-content: flex-end;
      background-color: #fff;
      position: relative;
      margin-left: auto;

      padding-right: 25px;
      padding-bottom: 4px;
      border-bottom: 1px solid ${theme.palette.primary.main};

      ${theme.breakpoints.up('sm')} {
        margin-left: ${theme.spacing(1)}px;
        width: auto;
      }
    }
  `}
`;
