/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                 KVK TVL to XML converter wrapper.
 *
 *  history:
 *  *.2021 tpe                   created
 */

import "../../c.js";

/**
 * Convert TLV to XML file
 */
 export function convertTLVtoXML(tlvData: Uint8Array): string {

    const getXMLBufferSize = Module.cwrap('ttxGetXMLBufferSize', 'number');

    const tlvDataSize = tlvData.length;
    const tlvDataPointer = (Module as any)._malloc(tlvDataSize);
    Module.writeArrayToMemory(tlvData, tlvDataPointer);

    const xmlTextBufferSize = getXMLBufferSize();
    const xmlTextPointer = (Module as any)._malloc(xmlTextBufferSize);

    const status = Module.ccall('ttxConvertTLVtoXML', 
    'number', 
    ['number', 'number', 'number', 'number', 'number'], 
    [tlvDataPointer, tlvDataSize, xmlTextPointer, xmlTextBufferSize]);

    if (status != 2) {
      throw new Error("Error: Converting KVK-TLV to XML" + convertTLVtoXML.name);
    }

    const xmlText = new Uint8Array(Module.HEAPU8.buffer, xmlTextPointer, xmlTextBufferSize);
    let xmlString = new TextDecoder("utf-8").decode(xmlText);
    
    xmlString = xmlString.substr(0, xmlString.indexOf('\0', 0));

    return xmlString;
 }
 