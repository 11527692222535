import { isAfter } from 'date-fns'

export function sortBycreatedAtDescending(a, b) {
  if (isAfter(a.createdAt, b.createdAt)) {
    return -1
  }
  if (isAfter(b.createdAt, a.createdAt)) {
    return 1
  }
  return 0
}
