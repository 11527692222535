import styled from 'styled-components';

export const StyledCalendar = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .button {
    min-width: 100px;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100vw - 350px - 60px);
    min-width: 400px;
  }

  .mainContainer {
    overflow: auto;
  }

  .topBar {
    display: flex;
    flex-direction: row;
    height: 32px;
    flex-shrink: 0;
  }

  .inner {
    overflow: hidden;
    flex-grow: 1;
  }

  .sideBar {
    width: 310px;
  }

  @media (max-width: 906px) {
    .long-link {
      font-size: 12px;
      letter-spacing: -0.8px;
      line-height: 13px;
    }
  }
`
