import { IconButton, SvgIcon, Toolbar, Tooltip } from '@material-ui/core'
import {
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  Menu as MenuIcon,
  People as PeopleIcon,
  PieChart as PieChartIcon,
  Settings as SettingsIcon,
  CreditCard as CreditCardIcon,
} from '@material-ui/icons'
import { format } from 'date-fns'
import { useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as datesActions from '../../actions/dates'
import SearchBar from '../../components/SearchBar'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import PododeskLogo from '../../shared/components/PododeskLogo/PododeskLogo'
import { START_PAGE_PATH } from '../../shared/utils/constants'
import { StyledHeader } from './StyledHeader'
import { ContextMenuCardReader } from '../../components/MenuCardReader/ContextMenuCardReader'
import { CardReaderContext } from '../OverlayContainer/CardReaderContext'
import { isWebBluetoothEnabled } from '../../utils/helpers'
import PododeskIcon from '../../shared/components/PododeskIcon/PododeskIcon'

interface Props {
  dialogActions?: any
  onMenuToggle: () => void
  showToolBar?: boolean
  actions?: any
  isStartPage: boolean
  isPaymentPage: boolean
}

const Header = ({
  dialogActions = {},
  onMenuToggle: handleToggleMenu,
  showToolBar,
  actions,
  isStartPage,
  isPaymentPage,
}: Props): JSX.Element => {
  const { isBluetoothConnected } = useContext(CardReaderContext)

  const [contextMenuCardReaderAnchorEl, setContextMenuCardReaderAnchorEl] = useState<null | HTMLElement>(null)
  const { serverHash } = useParams()

  const todayDate = format(new Date(), ISO8601DATE_FNS)

  const cardTerminalButtonColor = isBluetoothConnected ? 'forestgreen' : 'firebrick'

  const reloadTarget = useMemo(() => {
    if (isStartPage || isPaymentPage) {
      return `/${START_PAGE_PATH}`
    }
    return `/${serverHash}/calendar/day/${todayDate}`
  }, [isStartPage, isPaymentPage, serverHash, todayDate])

  const reloadPage = () => {
    actions.selectDay(todayDate)
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  const openContextMenuCardReader = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContextMenuCardReaderAnchorEl(event.currentTarget)
  }

  return (
    <>
      <StyledHeader elevation={0}>
        <Toolbar>
          <IconButton
            className="headerButton"
            color="secondary"
            aria-label="Menü öffnen"
            edge="start"
            onClick={handleToggleMenu}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Link to={reloadTarget} onClick={reloadPage}>
            <PododeskLogo style={{ height: 40 }} />
          </Link>
          <div style={{ flexGrow: 1 }}></div>
          <div className="tools">
            {showToolBar && (
              <>
                <Tooltip title="Hinzufügen/Neu anlegen">
                  <IconButton component={Link} to={`/${serverHash}/add-new`} className="headerButton" color="secondary">
                    <AddIcon fontSize="large" />
                  </IconButton>
                </Tooltip>

                <SearchBar dialogActions={dialogActions} />

                <Tooltip title="Kartenlesegerät">
                  <IconButton
                    className="headerButton"
                    style={isWebBluetoothEnabled() ? { color: cardTerminalButtonColor } : {}}
                    onClick={openContextMenuCardReader}
                    disabled={!isWebBluetoothEnabled() || !window?.terminalRuntimeInitialized}
                  >
                    <CreditCardIcon fontSize="large" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Kontakte">
                  <IconButton
                    component={Link}
                    to={`/${serverHash}/contacts`}
                    className="headerButton"
                    color="secondary"
                  >
                    <PeopleIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Kalender">
                  <IconButton
                    component={Link}
                    to={`/${serverHash}/calendar`}
                    className="headerButton"
                    color="secondary"
                  >
                    <CalendarTodayIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Berichte">
                  <IconButton
                    component={Link}
                    to={`/${serverHash}/berichte`}
                    className="headerButton"
                    color="secondary"
                  >
                    <PieChartIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Einstellungen">
                  <IconButton component={Link} to={`/${serverHash}/settings`} color="secondary">
                    <SettingsIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="pododesk-Onlineshop">
              <IconButton component={Link} to="https://pododesk.de/fuexc_unidy/start" color="secondary" edge="end">
                <PododeskIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </StyledHeader>
      <Toolbar />
      <ContextMenuCardReader
        anchorEl={contextMenuCardReaderAnchorEl}
        onCloseFunc={() => setContextMenuCardReaderAnchorEl(null)}
        bluetoothConnected={isBluetoothConnected}
        dialogActions={dialogActions}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(datesActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Header)
