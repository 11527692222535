import styled from 'styled-components';
import Background from './pododesk_imagemood_02.jpg';

export const StyledBrowserCheck = styled.div`
  ${({ theme }) => `
    background-color: #fff;
    background-image: url(${Background});
    background-size: cover;
    height: 100%;
    padding-top: 22px;

    .message {
      padding: 30px;
      width: 300px;
      background-color: #fff;
      margin: 0 auto;
    }

    .heading {
      color: ${theme.palette.primary.main};
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      display: inline-block;
    }

    .link {
      color: ${theme.palette.primary.dark};
    }

    .logo {
      height: 43px;
    }

    .ul {
      padding-left: 20px;
    }
  `}
`;
