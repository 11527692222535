export const ISO8601DATE = 'YYYY-MM-DD'
export const ISO8601DATE_FNS = 'yyyy-MM-dd'
export const ISO8601DATETIME = 'YYYY-MM-DDTHH:mm:ss' // eslint-disable-line no-useless-escape
export const ISO8601DATETIME_FNS = "yyyy-MM-dd'T'HH:mm:ss"

export const GERMAN_DATE_SHORT_YEAR = 'DD.MM.YY'
export const GERMAN_DATE_SHORT_YEAR_FNS = 'dd.MM.yy'
export const GERMAN_DATE_NO_YEAR = 'DD.MM.'
export const GERMAN_DATE_NO_YEAR_FNS = 'dd.MM'
export const GERMAN_DATE_LONG_YEAR = 'DD.MM.YYYY'
export const GERMAN_DATE_LONG_YEAR_FNS = 'dd.MM.yyyy'
export const GERMAN_DATE_TIME = 'DD.MM.YY HH:mm'
export const GERMAN_DATE_TIME_FNS = 'dd.MM.yy HH:mm'
export const GERMAN_DATE_LONG_DAY = 'dd. DD.MM.YY'
export const GERMAN_DATE_LONG_DAY_FNS = 'EEEEEE. dd.MM.yy'
export const GERMAN_DATE_TIME_SHORT_DAY = 'dd. DD.MM.YY HH:mm'
export const GERMAN_DATE_TIME_SHORT_DAY_FNS = 'EEEEEE. dd.MM.yy HH:mm'

export const TIME = 'HH:mm'
