import { SvgIcon, type SvgIconProps } from '@material-ui/core'

export const FacebookIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="21.785" height="21.651" viewBox="0 0 21.785 21.651" {...props}>
    <g id="RUCK_facebook" transform="translate(10.893 10.825)">
      <path
        id="RUCK_facebook-2"
        data-name="RUCK_facebook"
        d="M21.785,10.89A10.892,10.892,0,1,0,9.191,21.651V14.039H6.424V10.89H9.191v-2.4c0-2.73,1.624-4.237,4.114-4.237a16.762,16.762,0,0,1,2.436.212V7.143H14.367a1.574,1.574,0,0,0-1.775,1.7V10.89h3.021l-.483,3.149H12.6v7.612a10.9,10.9,0,0,0,9.19-10.76Z"
        transform="translate(-10.893 -10.825)"
      />
    </g>
  </SvgIcon>
)
