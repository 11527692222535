import {
  ErrorOutline as ErrorOutlineIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { loadTerminAndOpenEditTerminDialog } from '../../actions/dates';

const WrapWithBoundAction = connect(
  () => ({}),
  (dispatch, props) => ({
    boundAction: (data) => dispatch(props.action(data)),
  }),
)(({ children, boundAction }) => children(boundAction))

export const API_ERROR_CLIENT_DATA_OUTDATED = 'pod001_frontend/api/API_ERROR_CLIENT_DATA_OUTDATED'

export const API_ERROR_ACTIONTYPE_MISSING = 'pod001_frontend/api/API_ERROR_ACTIONTYPE_MISSING'

export const apiErrorsMap = {
  CLIENT_DATA_OUTDATED: API_ERROR_CLIENT_DATA_OUTDATED,
}

const clientDataOutdatedMsg = (state, action) => {
  if (action.entityType === 'termine') {
    return {
      message: (
        <span>
          <ErrorOutlineIcon style={{color: 'red'}} />&nbsp;Die Termindetails wurden zwischenzeitlich
          geändert, und können so nicht gespeichert werden.
          &nbsp;
          <WrapWithBoundAction action={loadTerminAndOpenEditTerminDialog}>
            {(boundAction) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  boundAction(action.id)
                }}
              >
                Termin anzeigen
              </a>
            )}
          </WrapWithBoundAction>
        </span>
      ),
      busy: false,
      error: false,
      hidesOnLocationChange: true,
    }
  }
  return state
}

export const notifications = {
  [API_ERROR_CLIENT_DATA_OUTDATED]: clientDataOutdatedMsg,
}
