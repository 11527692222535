import html2canvas from 'html2canvas'
import { debounce } from 'lodash'
import { FC, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import Notification from '../../components/Notification/Notification'
import AutoLockScrolling from './AutoLockScrolling'
import LockscreenForm from './LockscreenForm'
import { StyledLockscreen } from './StyledLockscreen'

const backdropFilter = 'blur(10px) grayscale(75%)'

interface Props {
  locked?: boolean
}

const Lockscreen: FC<Props> = ({
  locked,
  children
}) => {

  const { serverHash } = useParams()
  const appContainer = useRef<HTMLDivElement>(null)
  const canvasContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setTimeout(() => handleResize(), 0)
  }, [])
  
  const handleResize = debounce(() => locked && updateBackdrop(), 100, {
    leading: false,
    trailing: true,
  })

  const updateBackdrop = async () => {
    const canvas = appContainer?.current ? await html2canvas(appContainer.current) : null
    if(canvasContainer.current && canvas) {
      canvasContainer.current.innerHTML = ''
      canvas.style.filter = backdropFilter
      canvasContainer.current.appendChild(canvas)
    }
  }
  
  return (<>
    <StyledLockscreen locked={locked}>
      <div className="overlay">
        <div ref={canvasContainer} className="backgroundContainer">
        </div>
        {locked && serverHash 
          ? <LockscreenForm /> 
          : null}
      </div>
      <AutoLockScrolling lock={!!locked} />
      <div ref={appContainer} className='appContainer'>
        {children}
      </div>
    </StyledLockscreen>
    <Notification />
  </>)
}

const mapStateToProps = (state) => ({
  locked: state.lockscreen.locked,
})

export default connect(mapStateToProps)(Lockscreen)
