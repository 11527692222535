import { FC } from 'react'
import QuickSearch from '../../containers/QuickSearch'
import { StyledSearchBar } from './StyledSearchBar'

const SearchBar: FC<Record<string, unknown>> = (props) => (
  <StyledSearchBar>
    <QuickSearch {...props} />
  </StyledSearchBar>
)

export default SearchBar
