import { Event as EventIcon } from '@material-ui/icons';
import { selectTerminProps } from '../../../actions/dates';

export default {
  displayName: (todo, props) => {
    const hvo = props.heilmittelverordnungen[todo.referenceId]
    if (!hvo) return ''
    const patient = props.patienten[hvo.patient]
    if (!patient) return ''
    return `Folgetermin vereinbaren für ${[patient.vorname, patient.nachname].filter(Boolean).join(' ')}`
  },
  displayNameAggregate: () => <span>Folgetermine vereinbaren</span>,
  selectState: (state) => ({
    heilmittelverordnungen: state.entities.heilmittelverordnungen,
    patienten: state.entities.patienten,
  }),
  action: (todo, props) => () => {
    const hvo = props.heilmittelverordnungen[todo.referenceId]

    props.dispatch(
      selectTerminProps({
        heilmittelverordnung: hvo.id,
        patient: hvo.patient,
        leistungen: [
          {
            istKKLeistung: true,
            positionsnummer: hvo.heilmittel,
          },
        ],
      }),
    )
    props.navigate(`/${props.serverHash}/calendar/free`)
  },
  icon: <EventIcon /> // 'ion-android-calendar'
}
