/*
 * Zeiträume, für die erfolgreich Termine geladen wurden, merken
 * */
import { format } from 'date-fns'
import { ISO8601DATE_FNS } from '../constants/dateFormats'
import * as types from '../constants/actionTypes'

const initialState = {}

export default function termineVonBisGeladen(state = initialState, action = undefined) {
  const { type, startDate, endDate } = action
  switch (type) {
    case types.LOAD_DATES_SUCCESS:
      if (!startDate || !endDate) {
        return state
      }
      return {
        ...state,
        [`${format(startDate, ISO8601DATE_FNS)}${format(endDate, ISO8601DATE_FNS)}`]: true,
      }

    // load all dates +events again since now the ics sources could have changed.
    case types.UPDATE_PRAXISSTAMMDATEN_SUCCESS:
      return initialState

    default:
  }

  return state
}
