import { ListItemIcon, MenuItem } from '@material-ui/core'
import {
  Event as EventIcon
} from '@material-ui/icons'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledListItemText } from './StyledListItemText'

const MenuItemsTherapeut: FC<any> = ({ 
  therapeutId, onCloseFunc, 
  }) => {
  const { serverHash } = useParams()
  const navigate = useNavigate()

  const handleMenuCalendarWeekClick = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/calendar/week/${id}`)
  }

  return (<div>
    <MenuItem onClick={(event) => handleMenuCalendarWeekClick(event, therapeutId)}>
      <ListItemIcon>
        <EventIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="zur Wochenansicht" /> {/* to the week view */}
    </MenuItem>
  </div>)
}

export default MenuItemsTherapeut
