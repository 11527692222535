import { userManager } from '../../containers/AuthProvider/utils'
import { restoreAuth, logout } from './auth'

export const CROSS_TAB_ACTION = 'pod001_frontend/auth/CROSS_TAB_ACTION'

const LS_ACTION_KEY = 'pododesk_crosstab_action'
const { localStorage } = window

export const startReceivingLocalStorageEvents = () => (dispatch) => {
  window.addEventListener('storage', function (event) {
    switch (event.key) {
      case LS_ACTION_KEY:
        if (event.newValue === 'null') return

        let action
        try {
          action = JSON.parse(event.newValue)
        } catch (e) {
          console.log(e)
          action = {
            error: 'Error parsing json message',
          }
        }

        dispatch({
          type: CROSS_TAB_ACTION,
          action,
        })
        break

      default:
    }

    if (event?.key?.startsWith('oidc.user') && !event.newValue) {
      userManager.removeUser()
      userManager.signoutRedirect()
    }
  })
}

export function sendCrossTabAction(action) {
  localStorage.setItem(LS_ACTION_KEY, JSON.stringify(action))
  localStorage.setItem(LS_ACTION_KEY, null)
}
