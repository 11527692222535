import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { StyledReadCardDataDialog } from './StyledReadCardDataDialog'
import { useContext, useState } from 'react'
import { format } from 'date-fns'
import { CardReaderContext } from '../../containers/OverlayContainer/CardReaderContext'

interface Props {
  open: boolean
  onCloseFunc: (...params) => any
}

export const ReadCardDataDialog = ({ open, onCloseFunc }: Props): JSX.Element => {
  const {
    isCardReaderActive,
    readCardData,
  } = useContext(CardReaderContext)

  const handleReadCardData = async (): Promise<void> => {
    console.log('handleReadCardData called')
    await readCardData()

    onCloseFunc()
  }

  const handleDialogClose = (): void => {
    onCloseFunc()
  }

  return (
    <StyledReadCardDataDialog open={open}>
      <DialogTitle>eGK-Daten auslesen</DialogTitle>
      <DialogContent className="dialogContent">
        {!isCardReaderActive && (
          <>
            <Typography variant="body2" paragraph>
              Um die Daten der eGK auszulesen, führen Sie diese nun bitte in das Gerät ein und klicken anschließend auf
              den Button "Daten auslesen".
            </Typography>
            <Typography variant="body2">
              Sollten Fragen oder Probleme aufkommen, wenden Sie sich gerne jederzeit per Chat oder{' '}
              <Link className="link" to="mailto:support@pododesk.de">
                E-Mail
              </Link>{' '}
              an uns!
            </Typography>
          </>
        )}
        {isCardReaderActive && (
          <Typography variant="body2" paragraph>
            Daten werden ausgelesen...
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialogActions">
      <Button
          variant="contained"
          type="button"
          color="secondary"
          onClick={handleReadCardData}
          disabled={isCardReaderActive}
        >
          Daten auslesen
        </Button>
        {!isCardReaderActive && (
          <Button
            variant="contained"
            type="button"
            color="secondary"
            onClick={handleDialogClose}
            disabled={isCardReaderActive}
          >
            Abbrechen
          </Button>
        )}
      </DialogActions>
    </StyledReadCardDataDialog>
  )
}
