import { Button, IconButton } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { addWeeks, isBefore } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as patientenActions from '../../actions/customers'
import * as datesActions from '../../actions/dates'
import { selectTerminProps, undoCancelDate } from '../../actions/dates'
import * as storeDialogActions from '../../actions/dialogs'
import * as heilmittelverordnungenActions from '../../actions/heilmittelverordnungen'
import { clearNotification, showNotification } from '../../actions/notification'
import { theme } from '../../assets/theme'
import ControlledAutocompleteField from '../../shared/components/AutocompleteField/ControlledAutocompleteField'
import { ControlledDateField } from '../../shared/components/DateField/ControlledDateField'
import { MessageSuccess } from '../../shared/components/Message'
import { ControlledMultipleAutocompleteField } from '../../shared/components/AutocompleteField/ControlledMultipleAutocompleteField'

interface Props {
  heilmittelverordnungen?: any
  termine?: any
  patienten?: any
  leistungenKK?: any
  actions?: any
}

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${theme.spacing(3)}px;
  padding: 0;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
`

const InnerListItem = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1.75fr) 1fr;
  grid-gap: ${theme.spacing(3)}px;
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const sortByDateAsc = (a, b) => {
  if (isBefore(a.beginn, b.beginn)) return -1
  if (isBefore(b.beginn, a.beginn)) return 1
  return 0
}

const HVOServicesAppointmentsList = ({
  heilmittelverordnungen,
  termine,
  patienten,
  leistungenKK,
  actions,
}: Props): JSX.Element => {
  const [showNewField, setShowNewField] = useState<boolean>(false)
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<any>(null)
  // const hvo = heilmittelverordnungen.find((hvo) => hvo.id === hvoId)
  const { heilmittelverordnung, serverHash } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const hvo = heilmittelverordnungen[heilmittelverordnung as unknown as number]

  const nagelkorrekturLeistungen = useMemo(
    () =>
      Object.keys(leistungenKK)
        .map((key) => leistungenKK[key])
        .filter((leistung) => !!leistung.nagelkorrektur)
        .map((leistung) => ({
          value: { positionsnummer: leistung.positionsnummer, istKKLeistung: true },
          label: `${leistung.positionsnummer} | ${leistung.beschreibung}`,
        })),
    [leistungenKK],
  )
  const hvoTermine = useMemo(
    () =>
      Object.keys(termine)
        .map((key) => termine[key])
        .filter((termin) => termin.heilmittelverordnung == heilmittelverordnung),
    [termine, heilmittelverordnung],
  )
  const hvoTermineNotCancelled = useMemo(() => {
    const filtered = hvoTermine.filter((termin) => termin.absagegrund === null || termin.absagegrund === '')
    filtered.sort(sortByDateAsc)
    return filtered
  }, [hvoTermine])

  const calculateRemainingNumOfDates = (): number => {
    let numOfDatesWithPrescribedService = 0
    let addAnpassungBilateralCount = false

    const allLeistungen = Object.keys(leistungenKK).map((key) => leistungenKK[key])

    const filteredTermine = hvoTermineNotCancelled.filter((t) => !!t.leistungen?.some((l) => l.istKKLeistung))

    const filteredTermineWithPrescribedService = filteredTermine.filter((t) => {
      let hasPrescribedService = false

      t.leistungen.forEach((l) => {
        const fullLeistung = allLeistungen.find((ll) => ll.positionsnummer === l.positionsnummer)

        if (fullLeistung?.prescribedService) {
          hasPrescribedService = true
        }
      })

      return hasPrescribedService
    })

    filteredTermineWithPrescribedService.forEach((t) => {
      t.leistungen.forEach((l) => {
        if (l.positionsnummer === 78210) {
          if (!addAnpassungBilateralCount) {
            numOfDatesWithPrescribedService += 1
            addAnpassungBilateralCount = true
          }
        } else {
          // if prescribed service and not positionsnummer 78210 add 1 to numofdateswithprescribedservice
          const fullLeistung = allLeistungen.find((ll) => ll.positionsnummer === l.positionsnummer)

          if (fullLeistung?.prescribedService && fullLeistung?.positionsnummer !== 78210) {
            numOfDatesWithPrescribedService += 1
          }
        }
      })
    })

    return hvo.verordnungsmenge - numOfDatesWithPrescribedService
  }

  /* const remainingNumOfDates =
    hvo.verordnungsmenge -
      hvoTermineNotCancelled.filter((termin) =>
        !!termin.leistungen[0]?.positionsnummer
          ? Object.keys(leistungenKK)
              .map((key) => leistungenKK[key])
              .find((leistung) => leistung.positionsnummer === termin.leistungen[0].positionsnummer)?.prescribedService
          : [],
      )?.length ?? '–' */

  const defaultFormValues = useMemo(() => {
    const result = {}

    // if (!!hvoTermineNotCancelled?.length) {
    //   hvoTermineNotCancelled.forEach((termin, idx) => {
    //     result[idx + 1] = {
    //       date: termin.beginn,
    //       service: termin.leistungen?.filter((l) => l.istKKLeistung && !!l.positionsnummer) ?? [],
    //     }
    //   })
    // }

    if (!!hvoTermineNotCancelled?.length) {
      hvoTermineNotCancelled.forEach((termin, idx) => {
        const servicesArr = []

        if (termin?.leistungen?.length) {
          termin.leistungen.forEach((l) => {
            if (l.istKKLeistung && !!l.positionsnummer) {
              servicesArr.push({ positionsnummer: l.positionsnummer, istKKLeistung: l.istKKLeistung })
            }
          })
        }

        result[idx + 1] = {
          date: termin.beginn,
          service: servicesArr,
        }
      })
    }

    return result
  }, [hvoTermineNotCancelled])

  useDeepCompareEffect(() => {
    const result = {}

    if (!!hvoTermineNotCancelled?.length) {
      hvoTermineNotCancelled.forEach((termin, idx) => {
        const servicesArr = []

        if (termin?.leistungen?.length) {
          termin.leistungen.forEach((l) => {
            if (l.istKKLeistung && !!l.positionsnummer) {
              servicesArr.push({ positionsnummer: l.positionsnummer, istKKLeistung: l.istKKLeistung })
            }
          })
        }

        result[idx + 1] = {
          date: termin.beginn,
          service: servicesArr,
        }
      })
    }

    resetForm({
      services: result,
    })
  }, [hvoTermineNotCancelled])

  const {
    control,
    formState: { errors },
    watch,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      services: defaultFormValues,
    },
  })

  const services = watch('services')

  const closeMenu = () => {
    setSubMenuOpen(false)
    setSubMenuAnchorEl(null)
  }

  const openSubMenu = (event) => {
    setSubMenuOpen(true)
    setSubMenuAnchorEl(event.currentTarget)
  }

  const closeSubMenu = () => {
    setSubMenuOpen(false)
    setSubMenuAnchorEl(null)
  }

  const handleEditClick = (termin): void => {
    actions.dialogs.openEditTerminDialog(termin)
  }

  const handleAddNewClick = (): void => {
    setShowNewField(true)
  }

  const handleRemoveNewClick = (): void => {
    setShowNewField(false)
  }

  const handleCancelAppointment = (termin) => {
    actions.selectTerminPropAction({})
    navigate(termin?.url || `/${serverHash}/calendar/free`)

    actions.clearNotificationAction()

    if (termin?.absagegrund === null) {
      const cancelledDate = Object.assign({}, termin)
      delete cancelledDate.url
      actions.undoCancelDateAction(cancelledDate)
    }

    setTimeout(() => {
      actions.showNotification({
        message: <MessageSuccess message="Terminvereinbarung abgebrochen." />,
        busy: false,
        error: false,
        timer: 3000,
      })
    }, 0)
  }

  const handleDateClick = (termin) => {
    let beginnValue = new Date()
    if (!!termin?.beginn) {
      beginnValue = termin.beginn
    }
    termin.begruendungFrequenzueberschreitung = null
    actions.termine.selectTerminProps(termin)
    const heilmittelverordnung = termin.heilmittelverordnung && heilmittelverordnungen[termin.heilmittelverordnung]
    const patient = patienten[termin.patient]
    let frequenz = 0
    if (heilmittelverordnung) {
      frequenz = heilmittelverordnung.minFrequenz
    } else if (patient.frequenz) {
      frequenz = patient.frequenz
    }
    const dateFolgeTermin = addWeeks(beginnValue, frequenz)
    actions.termine.selectDay(dateFolgeTermin)
    actions.showNotification({
      action: (
        <Button variant="contained" color="primary" onClick={() => handleCancelAppointment(termin)}>
          Abbrechen
        </Button>
      ),
      message: <MessageSuccess message="Bitte wählen Sie einen Termin für die Behandlung.&nbsp;" />,
      busy: false,
      error: false,
      timer: 150000,
      hidesOnLocationChange: true,
    })
    navigate(`/${serverHash}/calendar/free`)
  }

  const getAllowedValues = (count: number, hvoTermine?: any[]): number[] => {
    if (count === 1) return [78100, 78110]

    let containsErstbefundung = true

    if (hvoTermine?.length) {
      containsErstbefundung = hvoTermine.some((t) =>
        t.leistungen.some((l) => l.positionsnummer === 78100 || l.positionsnummer === 78110),
      )
    }

    if (count === 3) {
      return [
        78520,
        ...(containsErstbefundung ? [] : [78100, 78110]),
        ...Object.keys(leistungenKK)
          .map((key) => leistungenKK[key])
          .filter(
            (leistung) =>
              !!leistung.nagelkorrektur && leistung.positionsnummer !== 78100 && leistung.positionsnummer !== 78110,
          )
          .map((leistung) => leistung.positionsnummer),
      ]
    }

    const nagelkorrekturLeistungenValues = Object.values(nagelkorrekturLeistungen)
      .map((leistung) => leistung.value.positionsnummer)

    if (containsErstbefundung) {
      console.log('TEST: ', nagelkorrekturLeistungenValues.filter((num) => num !== 78100 && num !== 78110))
      return nagelkorrekturLeistungenValues.filter((num) => num !== 78100 && num !== 78110)
    }

    return nagelkorrekturLeistungenValues
  }

  return (
    <>
      <span style={{ fontSize: '1rem' }}>Termine:</span>
      <List>
        {!hvoTermineNotCancelled?.length && (
          <ListItem>
            <InnerListItem>
              <ControlledMultipleAutocompleteField
                fullWidth
                name="services.1.service"
                control={control}
                options={nagelkorrekturLeistungen}
                label="Leistungen"
                rules={{ required: true }}
                error={!!errors?.services && errors.services['1']?.service}
                helperText={errors?.services && errors.services['1']?.service?.message}
                disabled={!!services['1']?.date}
                allowedValues={!hvo.folgeverordnung ? getAllowedValues(1) : getAllowedValues(2)}
                style={{ maxWidth: '100%' }}
              />
              <DateWrapper>
                <ControlledDateField
                  name="services.1.date"
                  control={control}
                  label="Termin"
                  rules={{ required: true }}
                  error={!!errors?.services && !!errors?.services['1']?.date}
                  helperText={errors.services && errors.services['1']?.date?.message}
                  disabled={!services['1']?.service || !!services['1']?.date}
                  onClick={() => {
                    handleDateClick({
                      heilmittelverordnung,
                      patient: hvo.patient,
                      leistungen: services['1']?.service?.map((service) => ({
                        positionsnummer: service?.positionsnummer,
                        istKKLeistung: true,
                      }))
                    })
                  }}
                  hideCalendarIcon
                  readOnly
                  disableDefault
                />
              </DateWrapper>
            </InnerListItem>
          </ListItem>
        )}
        {hvoTermineNotCancelled.map((termin, idx) => {
          return (
            <ListItem key={idx}>
              <InnerListItem>
                <ControlledMultipleAutocompleteField
                  fullWidth
                  control={control}
                  disabled={!!services && !!services[idx + 1]?.date}
                  error={!!errors.services?.[idx + 1]?.service}
                  helperText={errors.services?.[idx + 1]?.service?.message}
                  label="Leistungen"
                  name={`services.${idx + 1}.service`}
                  options={nagelkorrekturLeistungen}
                  rules={{ required: 'Bitte wählen Sie eine Leistung aus.' }}
                  allowedValues={idx === 0 && !hvo.folgeverordnung ? getAllowedValues(1) : getAllowedValues(2)}
                  defaultValue={termin.leistungen.find((leistung) => leistung.istKKLeistung)?.positionsnummer}
                  style={{ maxWidth: '100%' }}
                />
                <DateWrapper>
                  <ControlledDateField
                    control={control}
                    disabled={!!services && (!services[idx + 1]?.service || !!services[idx + 1]?.date)}
                    error={!!errors.services?.[idx + 1]?.date}
                    helperText={errors.services?.[idx + 1]?.date?.message}
                    label="Termin"
                    name={`services.${idx + 1}.date`}
                    rules={{ required: 'Bitte wählen Sie einen Termin aus.' }}
                    onClick={() => {
                      handleDateClick({
                        heilmittelverordnung,
                        patient: hvo.patient,
                        leistungen: services[idx + 1]?.service?.map((service) => ({
                          positionsnummer: service?.positionsnummer,
                          istKKLeistung: true,
                        }))
                      })
                    }}
                    hideCalendarIcon
                    readOnly
                    defaultValue={termin.beginn}
                  />
                  <IconButton onClick={() => handleEditClick(termin)}>
                    <EditIcon />
                  </IconButton>
                </DateWrapper>
              </InnerListItem>
              {!!services &&
                !!services[idx + 1]?.service &&
                !!services[idx + 1]?.date &&
                calculateRemainingNumOfDates() > 0 &&
                idx === hvoTermineNotCancelled.length - 1 &&
                !showNewField && (
                  <IconButton onClick={handleAddNewClick}>
                    <AddIcon />
                  </IconButton>
                )}
            </ListItem>
          )
        })}
        {showNewField && (
          <ListItem>
            <InnerListItem>
              <ControlledMultipleAutocompleteField
                fullWidth
                name={`services.${hvoTermineNotCancelled.length + 1}.service`}
                control={control}
                options={nagelkorrekturLeistungen}
                label="Leistungen"
                rules={{ required: true }}
                error={!!errors.services && errors.services[hvoTermineNotCancelled.length + 1]?.service}
                helperText={errors.services && errors.services[hvoTermineNotCancelled.length + 1]?.service?.message}
                disabled={!!services[hvoTermineNotCancelled.length + 1]?.date}
                allowedValues={
                  calculateRemainingNumOfDates() === 1
                    ? getAllowedValues(3, hvoTermineNotCancelled)
                    : getAllowedValues(2, hvoTermineNotCancelled)
                }
                style={{ maxWidth: '100%' }}
              />
              <DateWrapper>
                <ControlledDateField
                  name={`services.${hvoTermineNotCancelled.length + 1}.date`}
                  control={control}
                  label="Termin"
                  rules={{ required: true }}
                  error={!!errors.services && errors.services[hvoTermineNotCancelled.length + 1]?.date}
                  helperText={errors.services && errors.services[hvoTermineNotCancelled.length + 1]?.date?.message}
                  disabled={
                    !services[hvoTermineNotCancelled.length + 1]?.service ||
                    !!services[hvoTermineNotCancelled.length + 1]?.date
                  }
                  onClick={() => {
                    handleDateClick({
                      heilmittelverordnung,
                      patient: hvo.patient,
                      leistungen: services[hvoTermineNotCancelled.length + 1]?.service?.map((service) => ({
                        positionsnummer: service?.positionsnummer,
                        istKKLeistung: true,
                      })),
                    })
                  }}
                  hideCalendarIcon
                  readOnly
                  disableDefault
                />
              </DateWrapper>
            </InnerListItem>
            <IconButton edge="end" onClick={handleRemoveNewClick}>
              <RemoveIcon fontSize="inherit" />
            </IconButton>
          </ListItem>
        )}
      </List>
      <span style={{ fontSize: '1rem', fontWeight: 500, marginTop: 20 }}>
        Anzahl verbrauchter Behandlungseinheiten: {hvo.verordnungsmenge - calculateRemainingNumOfDates()}
      </span>
    </>
  )
}

const mapStateToProps = (state) => ({
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  termine: state.entities.termine,
  patienten: state.entities.patienten,
  leistungenKK: state.leistungenKK,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    termine: bindActionCreators(datesActions, dispatch),
    patienten: bindActionCreators(patientenActions, dispatch),
    dialogs: bindActionCreators(storeDialogActions, dispatch),
    heilmittelverordnungen: bindActionCreators(heilmittelverordnungenActions, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
    clearNotificationAction: bindActionCreators(clearNotification, dispatch),
    undoCancelDateAction: bindActionCreators(undoCancelDate, dispatch),
    selectTerminPropAction: bindActionCreators(selectTerminProps, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(HVOServicesAppointmentsList)
