import styled from 'styled-components';

export const StyledCustomerForm = styled.form`
  ${({ theme }) => `
    padding: ${theme.spacing(3)}px;

    .fieldContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${theme.spacing(6)}px;

      ${theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${theme.spacing(3)}px;
      }

      ${theme.breakpoints.down('xs')} {
        padding: ${theme.spacing(3)}px;
        grid-template-columns: 1fr;
      }
    }

    .fieldGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: ${theme.spacing(3)}px;
      grid-row-gap: ${theme.spacing(4)}px;
      grid-auto-rows: min-content;
    }

    .streetGroup {
      display: grid;
      grid-template-columns: 1fr 0.25fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .addressGroup {
      display: grid;
      grid-template-columns: 0.25fr 1fr;
      grid-column-gap: ${theme.spacing(3)}px;
    }

    .checkboxGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(2)}px;
    }

    .notesGroup {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${theme.spacing(3)}px;
    }

    .supportLink {
      color: ${theme.palette.secondary.main};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .formDivider {
      background-color: ${theme.palette.secondary.main};
      margin: ${theme.spacing(4)}px 0;
    }
  `}
`;
