import MenuItemsHeilmittelverordnung from './MenuItemsHeilmittelverordnung';
import { StyledMenuHeilmittelverordnung } from './StyledMenuHeilmittelverordnung';

const ContextMenuHeilmittelverordnung = ({
  selectedHeilmittelverordnung,
  pointerLocation: { mouseX, mouseY },
  onCloseFunc,
}) => {
  return (
    <StyledMenuHeilmittelverordnung
      open={mouseX !== null && mouseY !== null}
      onClose={onCloseFunc}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <MenuItemsHeilmittelverordnung
        heilmittelverordnung={selectedHeilmittelverordnung}
        onCloseFunc={onCloseFunc}
      />
    </StyledMenuHeilmittelverordnung>
  );
};

export default ContextMenuHeilmittelverordnung;
