import { FC } from 'react'

interface FemaleIconProps {
  fontSize?: 'default' | 'small' | 'large' | 'inherit' | undefined
  color?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary' | undefined
  className?: string
}

export const FemaleIcon: FC<FemaleIconProps> = ({ fontSize, color, className }) => (
  // <SvgIcon className={className} component={Female} fontSize={fontSize} color={color} />
  <span className="ascii-icon female">{'\u2640'}</span>
)
