import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { RequestConfig } from './interface'

export function loadDoctors(): RequestConfig {
  return {
    types: [types.LOAD_DOCTORS, types.LOAD_DOCTORS_SUCCESS, types.LOAD_DOCTORS_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.dates.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/aerzte`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export const selectDoctor = makeActionCreator(types.SELECT_DOCTOR, 'doctor')
export const filterDoctorsLastNameBeginsWith = makeActionCreator(types.FILTER_DOCTORS_LASTNAME_BEGINS_WITH, 'char')

export function createDoctor(data): RequestConfig {
  return {
    types: [types.CREATE_DOCTOR, types.CREATE_DOCTOR_SUCCESS, types.CREATE_DOCTOR_FAILURE],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/aerzte`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}

export function updateDoctor(data, id): RequestConfig {
  return {
    types: [types.UPDATE_DOCTOR, types.UPDATE_DOCTOR_SUCCESS, types.UPDATE_DOCTOR_FAILURE],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/aerzte/${id}`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: data,
  }
}
