import { Button, Dialog, DialogActions, DialogContent, Paper } from '@material-ui/core'
import { Camera as CameraIcon } from '@material-ui/icons'
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showNotification } from '../../actions/notification'
import * as userActions from '../../actions/people'
import Cropper from '../../components/Cropper/Cropper'
import { IMAGE_MIME_TYPES } from '../../constants/misc'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { apiServerHashSelector } from '../../shared/utils/auth'
import { arrayBufferFromBase64, dataURLfromArrayBuffer, hexStringFromArrayBuffer } from '../../shared/utils/dataFormats'
import { readFile } from '../../shared/utils/file'
import { sCurrentUser } from '../../shared/utils/users'
import { StyledAvatar } from './StyledAvatar'

interface DefaultCrop {
  aspect?: number
  width?: number
  x?: number
  y?: number
}

const defaultCrop: DefaultCrop = {
  aspect: 1,
  width: 20,
  x: 7,
  y: 10,
}

const Avatar = ({ actions, user, isInstanceSelected }) => {
  const [newFile, setNewFile] = useState<any>(null)
  const [cropDialogOpen, setCropDialogOpen] = useState<boolean>(false)
  const [crop, setCrop] = useState<DefaultCrop | boolean>(defaultCrop)

  const [avatar, setAvatar] = useState<string>('')

  useEffect(() => {
    let newAvatar = '';
    if(user) {
      newAvatar = user.avatar ?? user.avatarFallback ?? ''
    }
    setAvatar(newAvatar)
  }, [user])

  const inputRef = useRef<HTMLInputElement>(null)
  const cropperRef = useRef<Cropper>(null)

  const clearFileInput = (): void => {
    inputRef.current && (inputRef.current.value = '');
  }

  const handleFiles = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files: FileList = ((target as HTMLInputElement)?.files) as FileList;
    if(!files || files.length <= 0) {
      clearFileInput();
      return;
    }

    const file = files.item(0);
    if(!file) {
      clearFileInput();
      return;
    }

    if(!IMAGE_MIME_TYPES.find(item => item === file.type)) {
      actions.showNotification('Wrong file type');
      clearFileInput();
      return;
    }

    try {
      const readFileResult = await readFile(file);
      if(readFileResult) {
        setNewFile(dataURLfromArrayBuffer(readFileResult, file.type))
        setCropDialogOpen(true)
      }
    } catch (error) {
      console.log(error)
      actions.showNotification('Error load');
      setCropDialogOpen(false);
    }
    clearFileInput();
  }

  const closeCropDialog = useCallback(() => {
    setCropDialogOpen(false)
    setCrop(defaultCrop)
  }, [setCropDialogOpen, setCrop])

  const saveCrop = useCallback(async () => {
    const avatarSize = 256

    const croppedFile = cropperRef.current?.cropper?.getCroppedCanvas({
        width: avatarSize,
        height: avatarSize,
      })
      .toDataURL('image/png')

    closeCropDialog()
    const arrayBuffer = arrayBufferFromBase64(croppedFile?.slice(22))
    const hexStringForPG = hexStringFromArrayBuffer(arrayBuffer)
    const userData = user
    await actions.users.updateUser({
      ...userData,
      avatar: hexStringForPG,
    })
    if (isInstanceSelected) {
      await actions.users.loadPeople()
    }
  }, [cropperRef, closeCropDialog, actions.users.updateUser, isInstanceSelected, actions.users.loadPeople])

  const deleteAvatar = useCallback(async () => {
    const userData = user
    await actions.users.updateUser({
      ...userData,
      avatar: null,
    })
    if (isInstanceSelected) {
      await actions.users.loadPeople()
    }
  }, [user, actions.users.updateUser, isInstanceSelected, actions.users.loadPeople])


  return (
    <StyledAvatar>
      <IconHeading icon={<CameraIcon />} text="Avatar hochladen" />
      <div className="avatarPaperContainer">
        <Paper className="avatarPaper" square elevation={3}>
          <div className="textArea">
            <h2>Persönliches Avatar festlegen</h2>
            <p>
              Dieses wird im Sperrbildschirm von pododesk und ggfs. über Ihrer Kalenderspalte in der Praxis angezeigt.
            </p>
          </div>
          <input
            className="avatarFileInput"
            ref={inputRef}
            type="file"
            accept={IMAGE_MIME_TYPES.join(',')}
            onChange={handleFiles}
          />
          <div className="avatarImgWrapper">
            <img className="avatarImg" src={avatar} />
          </div>
          <div className="avatarButtonContainer">
            <Button size="small" fullWidth variant="contained" color="secondary" onClick={deleteAvatar} disabled={!user?.avatar}>
              Avatar löschen
            </Button>
            <Button size="small" fullWidth variant="contained" color="secondary" onClick={() => inputRef.current?.click()}>
              Avatar hochladen
            </Button>
          </div>
        </Paper>
      </div>
      <Dialog disableBackdropClick open={cropDialogOpen}>
        <DialogContent>
          <span style={{ paddingBottom: '15px', display: 'block' }}>
            Bitte wählen Sie mit der Maus den Ausschnitt aus Ihrem Bild, den Sie als Profilbild verwenden möchten.
          </span>
          <Cropper
            ref={cropperRef}
            src={newFile}
            aspectRatio={1}
            onChange={() => {
              setCrop(true)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: '10px'}}
            variant="text"
            color="secondary"
            onClick={closeCropDialog}>
            Abbrechen {/* Abort */}
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={saveCrop}
            disabled={!crop}>
            Speichern {/* save */}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledAvatar>
  )
}

const mapStateToProps = (state) => ({
  user: sCurrentUser(state),
  isInstanceSelected: !!apiServerHashSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    users: bindActionCreators(userActions, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Avatar))
