import styled from 'styled-components';

export const StyledTableHeader = styled.thead`
${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    th {
      font-weight: normal;
    }
  `}
`;
