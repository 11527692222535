import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AF01, AF02, AF03, AF04, AF05, AF06, AF07, AF08, AF09, AnamnesisSidebar } from '../../components/AnamnesisForm'
import Empty from '../Empty/Empty'
import { AnamnesisContainer } from './AnamnesisContainer'

interface Props {
  dialogActions?: any
}

export const AnamnesisRoutes: FC<Props> = (props) => (
  <Routes>
    <Route path="/" element={<AnamnesisContainer {...props} form={<Empty />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/01" element={<AnamnesisContainer {...props} form={<AF01 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/02" element={<AnamnesisContainer {...props} form={<AF02 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/03" element={<AnamnesisContainer {...props} form={<AF03 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/04" element={<AnamnesisContainer {...props} form={<AF04 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/05" element={<AnamnesisContainer {...props} form={<AF05 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/06" element={<AnamnesisContainer {...props} form={<AF06 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/07" element={<AnamnesisContainer {...props} form={<AF07 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/08" element={<AnamnesisContainer {...props} form={<AF08 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="/09" element={<AnamnesisContainer {...props} form={<AF09 />} sidebar={<AnamnesisSidebar/>} />} />
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
)
