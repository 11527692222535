import { Card } from '@material-ui/core';
import styled from 'styled-components';

export const StyledUpgradeCredentialsForm = styled(Card)`
  align-self: center;
  margin: 0 auto;

  .cardText{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .cardActions{
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  .button{
    margin-top: 0;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
  }
`
