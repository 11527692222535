import { ListItemIcon, MenuItem } from '@material-ui/core'
import { FC } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as storeDialogActions from '../../actions/dialogs'
import { sBuchungHatTermin, sTerminZuBuchung } from './selectors'

import {
  EuroSymbol as EuroSymbolIcon, Person as PersonIcon, Visibility as VisibilityIcon
} from '@material-ui/icons'
import { StyledListItemText } from './StyledListItemText'

const MenuItemsBuchung: FC<any> = ({
  onCloseFunc,
  buchung,
  termin,
  buchungHatTermin,
  actions,
  dialogActions,
 }) => {

  const navigate = useNavigate()
  const { serverHash } = useParams()

  const openTerminAbrechnenDialog = (termin) => {
    onCloseFunc()
    dialogActions?.openTerminAbrechnenDialog(termin)
  }

  const openEditDateDialog = (termin) => {
    onCloseFunc()
    actions.dialogs.openEditTerminDialog(termin)
  }

  const viewPatient = (termin) => {
    onCloseFunc()
    navigate(`/${serverHash}/contacts/customers/${termin.patient}`)
  }

  if(!buchungHatTermin) {
    return null;
  }

  return (<div>
    <MenuItem onClick={() => openTerminAbrechnenDialog(termin)}>
      <ListItemIcon>
        <EuroSymbolIcon color="secondary" /> {/* ion-social-euro */}
      </ListItemIcon>
      <StyledListItemText primary="Zahlung anzeigen" />
      {/* View payment */}
    </MenuItem>

    <MenuItem onClick={() => openEditDateDialog(termin)}>
      <ListItemIcon>
        <VisibilityIcon color="secondary" />
      </ListItemIcon>
      <StyledListItemText primary="Termin anzeigen" />
      {/* Show appointment */}
    </MenuItem>

    <MenuItem onClick={() => viewPatient(termin)}>
      <ListItemIcon>
        <PersonIcon color="secondary" /> {/* ion-person */}
      </ListItemIcon>
      <StyledListItemText primary="Patientenkartei anzeigen" />
      {/* View patient  */}
    </MenuItem>
  </div>)
}

const mapStateToProps = (state, props) => ({
  buchungHatTermin: sBuchungHatTermin(state, props),
  termin: sTerminZuBuchung(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dialogs: bindActionCreators(storeDialogActions, dispatch),
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsBuchung)
