import { Menu } from '@material-ui/core'
import styled from 'styled-components'
import { MenuItemsCardReader } from './MenuItemsCardReader'

const MenuContainer = styled(Menu)`
  ${({ theme }) => `
    .paper {
      background-color: ${theme.palette.highlight.main};
      color: ${theme.palette.secondary.main};
    }
  `}
`

interface Props {
  anchorEl: HTMLElement | null
  onCloseFunc: () => any
  dialogActions: any
}

export const ContextMenuCardReader = ({
  anchorEl,
  onCloseFunc,
  dialogActions,
}: Props): JSX.Element => (
    <MenuContainer
      open={!!anchorEl}
      onClose={onCloseFunc}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      PaperProps={{ className: 'paper' }}
    >
      <MenuItemsCardReader
        onCloseFunc={onCloseFunc}
        dialogActions={dialogActions}
      />
    </MenuContainer>
  )
