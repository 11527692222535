import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FC, memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as anamnesenActions from '../../actions/anamnesis';
import { StyledFinishAnamneseDialog } from './StyledFinishAnamneseDialog';

interface Props {
  actions: any,
  closeDialog: () => void,
  open?: boolean,
  anamnese?: any
}

const FinishAnamneseDialog: FC<Props> = ({
  actions,
  closeDialog,
  open = false,
  anamnese
}) => {

  const finishAnamnese = useCallback(async () => {
    await actions.anamnesen.finishAnamnese(anamnese);
    closeDialog();
  }, [actions.anamnesen.finishAnamnese, anamnese, closeDialog]);

  return (
    <StyledFinishAnamneseDialog open={open} onClose={closeDialog}>
      <DialogTitle>Anamnese abschließen</DialogTitle>
      <DialogContent>
        Durch das Abschließen wird der dokumentierte Gesundheitszustand gespeichert.
        <br />
        Sie können jederzeit eine neue Version der Anamnese erstellen und die jetzigen Angaben verändern.
        <br />
        Alte Versionen sind später wieder aufrufbar.
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Zurück
        </Button>
        <Button className="finishButton" variant="contained" color="secondary" onClick={finishAnamnese}>
          Abschließen
        </Button>
      </DialogActions>
    </StyledFinishAnamneseDialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    anamnesen: bindActionCreators(anamnesenActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(memo(FinishAnamneseDialog));
