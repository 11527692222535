import debounce from 'lodash.debounce'
import { START_TICKS } from './time'
import { apiServerHashSelector } from '../../selectors/selectors'

const initalState = {
  lastActivity: null,
}

const localStorage = window.localStorage

export const UPDATE_USER_ACTIVITY = 'pod001_frontend/userActivity/UPDATE_USER_ACTIVITY'

export default function (state = initalState, action) {
  switch (action.type) {
    case START_TICKS:
      return {
        ...state,
        lastActivity: action.time,
      }
    case UPDATE_USER_ACTIVITY:
      return {
        ...state,
        lastActivity: action.time,
      }
    default:
  }
  return state
}

export const updateUserActivity = (time) => ({
  type: UPDATE_USER_ACTIVITY,
  time,
})

const activityEvents = [
  'mousemove',
  'mousedown',
  'mousewheel',
  'DOMMouseScroll',
  'touchmove',
  'MSPointerMove',
  'keypress',
]

export const startUpdateUserActivity = () => (dispatch, getState) => {
  const state = getState()

  const apiServerName = apiServerHashSelector(state)
  const activityKey = `last_active_${apiServerName}`

  const activityListener = debounce(
    () => {
      if (document.visibilityState === 'hidden') {
        return
      }
      const action = updateUserActivity(new Date())
      localStorage.setItem(activityKey, action.time)
      dispatch(action)
    },
    1000,
    {
      maxWait: 5000,
      leading: true,
      trailing: false,
    },
  )

  activityEvents.forEach((event) => document.addEventListener(event, activityListener))

  window.addEventListener('storage', function (event) {
    if (event.key !== activityKey) {
      return
    }
    dispatch(updateUserActivity(new Date(event.newValue)))
  })
}
