/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  This module holds the state of the card reader 
 *                                and is in charge of:
 *                                  - initializing the card reader.
 *                                  - hold the state of the card reader.
 *                                Source for this class is:
 *                                  - The CT-API Interface (see CT-API specification 1.1.1)
 *                                  - The iOS-App CTAPI.swift (CTorgKit\ctorg\CTAPI.swift)
 *
 *  history:
 *  *.2021 bwe                    created
 *  *.2021 tpe                    some cleanup
 */

import { T1_host_if } from "./t1_host_interface";
import { Sad } from "./t1_host_interface";
import { Dad } from "./t1_host_interface";
import { TerminalData } from "./t1_host_interface";
import { T1_Return_Code } from "./t1_host_interface";

// Function returns true if both arrays a and b are equal.
const equals = (a: Uint8Array, b: Uint8Array) =>
  a.length === b.length &&
  a.every((v, i) => v === b[i]);


enum TerminalState {
  start,      // initial state
  reset_ct,   // reset_ct() was called
  ct_ready    // reset_ct() was successful
}

export class CardTerminal {
  private reader_state: TerminalState = TerminalState.start;
  private sad: Sad;
  private t1_host_if: T1_host_if;

  constructor(sad: Sad, t1_host_if: T1_host_if) {
    this.sad = sad;
    this.t1_host_if = t1_host_if;
    console.log("Card_terminal created.");
  }

  public async init(): Promise<void> {
    
    // Change state: wait for response
    this.reader_state = TerminalState.reset_ct;

    // Send command 
    try{
      await this.reset_ct().then( () => {
        this.reader_state = TerminalState.ct_ready;
      });
    }catch(e){
      this.reader_state = TerminalState.start;
      throw e;
    }
  }

  public async reset_ct(): Promise<void> {


    const RESET_CT_CMD_ARRAY = new Uint8Array([0x20, 0x11, 0x00, 0x00, 0x00]);  // B1-Kommando
    const t1_reset_ct: TerminalData = new TerminalData(Dad.ct, this.sad, RESET_CT_CMD_ARRAY);


    // Send the command and pass a callback function as lambda so that the this pointer is preserved.
    const [rxData, return_code] = await this.t1_host_if.send_async(
      t1_reset_ct,
      "reset_ct"  
    );

    this.eval_response_from_reset_ct(rxData, return_code);
  }

  eval_response_from_reset_ct(
    rxData: Uint8Array,
    return_code: number
  ): void {
    
    if(return_code !== T1_Return_Code.OK){
      console.error("reset_ct return_code=", return_code);
      throw new Error('t1_send_return_code ' + this.eval_response_from_reset_ct.name);
    }else{/*no error from t1*/}

    const expected_len = 2;
    if(rxData.length < expected_len)
    {
      console.error("reset_ct rxData.length=", rxData.length);
      throw new Error('t1_response_length ' + this.eval_response_from_reset_ct.name);
    }else{/*no length error*/}
    const status_code = new Uint8Array([rxData[rxData.length-2], rxData[rxData.length-1]])

    const expected_response = new Uint8Array([0x90, 0x00]); 
    const response_in_mobile_mode = new Uint8Array([0x95, 0x00]); 

    if(equals(expected_response, status_code)){
      return;
    }else if(equals(response_in_mobile_mode, rxData)){
      console.error("Error: Card reader is in 'Mobilmodus'.")
      throw new Error('card_reader_betriebsart');
    }else{
      console.error("Error: Received response from reset_ct was not the expected one.")
      throw new Error('t1_unknown_response_code');
    }

  }
  
}
