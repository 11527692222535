import * as types from '../constants/actionTypes'

export default function changelog(
  state = {
    allChangelogs: [],
    lastSeen: null,
  },
  action = undefined,
) {
  const { type } = action

  switch (type) {
    case types.LOAD_CHANGELOGS_FAILURE:
      return {
        ...state,
        allChangelogs: [],
        lastSeen: state?.lastSeen || null,
      }

    case types.LOAD_CHANGELOGS_SUCCESS:
      return {
        ...state,
        allChangelogs: action.json,
        lastSeen: state?.lastSeen || null,
      }

    case types.LOAD_CHANGELOG_LAST_SEEN: {
      return {
        ...state,
        loadingChangelogLastSeen: true,
        allChangelogs: state?.allChangelogs || [],
        lastSeen: state?.lastSeen || null,
      }
    }

    case types.LOAD_CHANGELOG_LAST_SEEN_FAILURE:
      return {
        ...state,
        loadingChangelogLastSeen: false,
        allChangelogs: state?.allChangelogs || [],
        lastSeen: null,
      }

    case types.LOAD_CHANGELOG_LAST_SEEN_SUCCESS:
      return {
        ...state,
        loadingChangelogLastSeen: false,
        allChangelogs: state?.allChangelogs || [],
        lastSeen: action.json.lastSeen,
      }

    case types.UPDATE_CHANGELOG_LAST_SEEN_SUCCESS:
      return {
        ...state,
        allChangelogs: state?.allChangelogs || [],
        lastSeen: action.json.lastSeen,
      }

    case types.LOAD_LATEST_CHANGELOG_SUCCESS: {
      const clonedChangelogs = state.allChangelogs?.slice() ?? []
      const latestChangelogIndex = clonedChangelogs.findIndex((cl) => cl.id === action.json.id)

      if (latestChangelogIndex !== -1) {
        clonedChangelogs[latestChangelogIndex] = action.json
      } else {
        clonedChangelogs.push(action.json)
      }

      return {
        ...state,
        allChangelogs: clonedChangelogs,
        lastSeen: state?.lastSeen || null,
      }
    }

    case types.LOAD_CHANGELOG_SUCCESS: {
      const clonedChangelogs = state.allChangelogs?.slice() ?? []
      const changelogIndex = state.allChangelogs.findIndex((cl) => cl.id === action.json.id)

      if (changelogIndex !== -1) {
        clonedChangelogs[changelogIndex] = action.json
      } else {
        clonedChangelogs.push(action.json)
      }

      return {
        ...state,
        allChangelogs: clonedChangelogs,
        lastSeen: state?.lastSeen || null,
      }
    }

    default:
  }

  return state
}
