import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Kassenbuch from '../../containers/Kassenbuch/Kassenbuch';
import { StyledBerichteIndex } from './StyledBerichteIndex';

interface Props {
  dialogActions?: any
}

const BerichteIndex: FC<Props> = (props) => (
  <StyledBerichteIndex>
    <div className="main">
      <Routes>
        <Route path="/kassenbuch/:zeitraum" element={<Kassenbuch {...props}/>} />
        <Route path="*" element={<Navigate replace to="./kassenbuch/tag" />} />
      </Routes>
    </div>
  </StyledBerichteIndex>
);

export default BerichteIndex;
