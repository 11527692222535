import { PeopleAlt as PeopleAltIcon } from '@material-ui/icons';

export default {
  displayName: (todo, props) => {
    const patient = props.patienten[todo.referenceId]
    if (!patient) return ''
    return `Patientenkartei von ${[patient.vorname, patient.nachname].filter(Boolean).join(' ')} vervollständigen`
  },
  displayNameAggregate: () => <span>Patientenkarteien vervollständigen</span>,
  selectState: (state) => ({ patienten: state.entities.patienten }),
  action: (todo, props) => () => (
    props.navigate(`/${props.serverHash}/contacts/customers/${todo.referenceId}/edit`)
  ),
  icon: <PeopleAltIcon />//'ion-person-stalker',
}
