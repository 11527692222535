import * as R from 'ramda'
import { list } from '../../utils/functions'
import { negativeInteger } from './negativeInteger'

export const terminAbrechnenFormSumme = R.compose(
  R.sum,
  R.map(R.compose(R.defaultTo(0), R.ifElse(R.compose(R.equals('Object'), R.type), R.prop('verguetung'), R.identity))),
  R.flatten,
  R.props(['rezeptgebuehr', 'leistungen', 'produkte_und_sonstiges', 'rabatt']),
  R.over(R.lensPath(['rabatt']), negativeInteger),
  R.nth(2),
  list,
)
