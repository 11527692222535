import {
  Accordion,
  AccordionDetails,
  AccordionSummary, CircularProgress, ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'
import { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loadTodos } from '../../actions/todos'
import { loadTodosData } from "../../actions/todosData"
import {
  selectStateForTodos, selectTodosArray, selectTodosByType,
  selectTodoTypeNames
} from './selectors'
import { StyledTodoStack } from './StyledTodoStack'
import { typesObj } from './todo-types'

interface Props {
  actions?: any,
  todosByType?: any,
  todoTypeNames?: Array<any>,
  initialLoadComplete?: boolean,
  isTodosDataLoading?: any,
}

const TodoStack: FC<Props> = ({
                                actions,
                                todosByType,
                                todoTypeNames,
                                initialLoadComplete,
                                isTodosDataLoading,
                                ...props
                              }) => {
  const {serverHash} = useParams()
  const navigate = useNavigate()
  const [isTodosDataLoaded, setIsTodosDataLoaded] = useState({
    dokumentation_erfassen: false,
    anamnese_erstellen: false,
    folgetermin_vereinbaren: false,
    vervollstaendigung_patientenkartei: false,
    rezept_abrechnen: false,
    therapiebericht_erstellen: false,
  });

  useEffect(() => {
    if (!initialLoadComplete) {
      actions.loadTodos()
    }
  }, [initialLoadComplete])

  useEffect(() => {
    const refreshInterval = setInterval(
      () => actions.loadTodos(),
      1000 * 60 * 15
    )
    return () => {
      refreshInterval && clearInterval(refreshInterval)
    }
  }, [])

  const getTodosData = (type, todos) => {
    if (!isTodosDataLoaded[type]) {
      actions.loadTodosData(type, todos);
      setIsTodosDataLoaded({...isTodosDataLoaded, [type]: true})
    }
  }

  const renderTodo = (todo, type) => {
    if (!todo) {
      return null
    }

    const withoutAction = todo.done //(todo.done && todo.type == 'folgetermin_vereinbaren')
    return (
      <ListItem
        key={todo.id}
        className={`${withoutAction ? '' : 'itemActive'}`}
        onClick={
          withoutAction
            ? () => {}
            : type.action(todo, {...props, serverHash, navigate})
        }>
        <ListItemIcon>
          {withoutAction
            ? <CheckCircleIcon />  // ion-checkmark
            : type.icon
          }
        </ListItemIcon>
        <ListItemText primary={type.displayName(todo, props)}/>
      </ListItem>
    )
  }

  return initialLoadComplete ? (
    <StyledTodoStack>
      {todoTypeNames &&
      todosByType &&
      todoTypeNames.map((typeName, index) => {
        const type = typesObj[typeName]
        const todos = todosByType[typeName]
        const todosNotDone = todos.filter(({done}) => done !== true)
        const remaining = todosNotDone.length

        return (
          <Accordion
            key={index}
            disabled={!(todos.length > 0)}
            className="accordion"
            elevation={0}
            TransitionProps={{unmountOnExit: true, mountOnEnter: true}}
          >
            <AccordionSummary
              className="accordionSummary"
              aria-label={`accordion${index}a-content`}
              expandIcon={<ExpandMoreIcon/>}
              onClick={() => getTodosData(typeName, props.todosArray)}
            >
              <Typography variant="body1" paragraph>
                <strong style={{marginRight: 6}}>{remaining}</strong>
                {type.displayNameAggregate()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              className="accordionDetails">
              {isTodosDataLoading[typeName] ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CircularProgress size={50} thickness={7}/>
                  </div>)
                : (todos.map((todo) => renderTodo(todo, type)))
              }
            </AccordionDetails>
          </Accordion>
        )
      })
      }
    </StyledTodoStack>
  ) : null
}

const mapStateToProps = (state) => ({
  todosByType: selectTodosByType(state),
  todoTypeNames: selectTodoTypeNames(state),
  todosArray: selectTodosArray(state),
  initialLoadComplete: state.initialLoadComplete.todos,
  isTodosDataLoading: state.todosData.isTodosDataLoading,
  ...selectStateForTodos(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators({loadTodos, loadTodosData}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TodoStack)
