// Work in progress for replacing redux-form with react-hook-form

import { Button, Divider, Paper } from '@material-ui/core'
import { addDays, differenceInDays, format, isSameDay } from 'date-fns'
import { cloneDeep, initial } from 'lodash'
import { memo, useEffect, useMemo, useState } from 'react'
import { FieldValues, FormProvider, useForm, UseFormClearErrors, UseFormSetError } from 'react-hook-form'
import { connect } from 'react-redux'
import { GERMAN_DATE_LONG_YEAR_FNS } from '../../constants/dateFormats'
import FileUploadWithLightboxRHF from '../../containers/FileUploadWithLightbox/FileUploadWithLightboxRHF'
import {
  kuerzelKrankenkassenSelector,
  praxisstammdatenSelector,
  sortedDoctorsSelector,
} from '../../selectors/selectors'
import ControlledAutocompleteField from '../../shared/components/AutocompleteField/ControlledAutocompleteField'
import ControlledCheckboxField from '../../shared/components/CheckboxField/ControlledCheckboxField'
import { ControlledDateField } from '../../shared/components/DateField/ControlledDateField'
import FormDivider from '../../shared/components/FormDivider/FormDivider'
import ControlledInputField from '../../shared/components/InputField/ControlledInputField'
import { ControlledRadioButtonGroup } from '../../shared/components/RadioButtonGroup/ControlledRadioButtonGroup'
import ControlledSelectField from '../../shared/components/SelectField/ControlledSelectField'
import { getErrorMessage } from '../../shared/utils/errorMessages'
import { sCurrentUser } from '../../shared/utils/users'
import { sHVOUser } from './selectors'
import {
  ButtonContainer,
  HeadingRed,
  HeadingRedInner,
  LinkParagraph,
  LinkSeparator,
  StyledHVOForm,
} from './StyledHVOForm'
import {
  behandlungsbeginnSpaetestensAmDays,
  cardinalSymptomOptions,
  diagnosisGroupOptions,
  getBehandlungseinheitenForDiagnosegruppe,
  icd10Items,
  icd10ItemsByDiagnosis,
  icd10ItemsNew,
  icdCodeLinksDiabetics,
  icdCodeLinksNeuropathy,
  nagelkorrekturLokalisierungOptions,
  remedyOptions,
  urgentTreatmentFactor,
} from './utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import HVOServicesAppointmentsList from './HVOServicesAppointmentsList'
import { DevTool as FormDevTool } from '@hookform/devtools'
import { isLocalDev } from '../../utils/helpers'
import { clear } from '../../redux/modules/auth'

interface FormData {
  patient: number // integer id
  krankenkasse: number // integer id
  otherInsurance: string
  strasse: string
  hausnummer: string
  postleitzahl: string
  ort: string
  ausstellungsdatum: Date
  gebuehrenbefreit: boolean
  behandlungsbeginnSpaetestensAm: Date
  hausbesuch: boolean
  therapiebericht: boolean
  heilmittel: number
  verordnungsmenge: number // integer
  minFrequenz: number // integer
  maxFrequenz: number // integer
  icd10Code: string
  icd10CodeOptional?: string
  diagnoseMitLeitsymptomatikWesentlicheBefunde?: string
  spezifizierungDerTherapieziele?: string
  arzt: number // integer id
  dateien: any[]
  versichertennummer: string
  versichertenstatus: string
  ikNummerKK: string
  urgent_treatment: boolean
  medicallyPrescribed: boolean
  socialFacility: boolean
  diagnosegruppe: string
  leitsymptomatik: string
  nagelkorrektur: boolean
  nagelkorrekturLokalisierung: string
  nagelkorrekturIndikation: string
}

const normalizeFields = (data) => {
  const clonedData = cloneDeep(data)
  const fields = Object.keys(clonedData)

  for (const field of fields) {
    if (clonedData[field] === '') {
      clonedData[field] = null
    }
  }

  return clonedData
}

const isValidDate = (value: any) => {
  if (!!value && isNaN(value.getTime())) {
    return 'Bitte gültiges Datum eingeben!'
  }

  return true
}

const validateHausbesuchInput = (
  hausbesuch: boolean,
  medicallyPrescribed: boolean,
  socialFacility: boolean,
  setError: UseFormSetError<FormData>,
  clearErrors: UseFormClearErrors<FormData>,
): true | string => {
  if (hausbesuch && !medicallyPrescribed && !socialFacility) {
    setError('hausbesuch', {
      type: 'validate',
      message: 'Bitte wählen Sie einen Grund für den Hausbesuch.',
    })
    return true
  } else {
    clearErrors('hausbesuch')
    return true
  }
}

const HVOForm = ({
  customer,
  doctorsAutocompleteItems,
  krankenkassenSelect,
  submitText = 'HVO speichern',
  customerHVOs,
  hvoId,
  currentUser,
  editMode,
  isArniCustomer,
  onSubmitFunc,
  initialValues,
  viewOnly,
}) => {
  const [ausstellungsdatumWarn, setAusstellungsdatumWarn] = useState<string>('')
  const [icd10CodeCategoryWarning, setIcd10CodeCategoryWarning] = useState<JSX.Element | null>(null)
  const [icd10CodeOptionalCategoryWarning, setIcd10CodeOptionalCategoryWarning] = useState<JSX.Element | null>(null)
  const today = useMemo(() => new Date(), [])

  const defaultValues = useMemo(() => {
    if (editMode || viewOnly) {
      return {
        ...initialValues,
        vorname: customer?.vorname,
        nachname: customer?.nachname,
        diagnosegruppe: editMode
          ? initialValues.diagnosegruppe || 'Df'
          : viewOnly && !initialValues.diagnosegruppe && !initialValues.leitsymptomatik
          ? 'Df'
          : initialValues.diagnosegruppe,
        leitsymptomatik: editMode
          ? initialValues.leitsymptomatik || 'c'
          : viewOnly && !initialValues.diagnosegruppe && !initialValues.leitsymptomatik
          ? 'c'
          : initialValues.leitsymptomatik,
        nagelkorrektur: initialValues?.nagelkorrektur || false,
        nagelkorrekturLokalisierung: initialValues?.nagelkorrekturLokalisierung || null,
        nagelkorrekturIndikation: initialValues?.nagelkorrekturIndikation || null,
      }
    }

    return {
      patient: customer.id,
      vorname: customer.vorname,
      nachname: customer.nachname,
      krankenkasse: customer.krankenkasse ?? '',
      otherInsurance: customer.otherInsurance,
      strasse: customer.strasse,
      hausnummer: customer.hausnummer,
      postleitzahl: customer.postleitzahl,
      ort: customer.ort,
      ausstellungsdatum: today,
      gebuehrenbefreit: false,
      folgeverordnung: null,
      verordnungAusserhalbDesRegelfalls: null,
      behandlungsbeginnSpaetestensAm: addDays(today, behandlungsbeginnSpaetestensAmDays),
      hausbesuch: false,
      medicallyPrescribed: false,
      socialFacility: false,
      therapiebericht: false,
      heilmittel: '',
      verordnungsmenge: '',
      minFrequenz: 4,
      maxFrequenz: 6,
      icd10Code: null,
      icd10CodeOptional: null,
      diagnoseMitLeitsymptomatikWesentlicheBefunde: null,
      spezifizierungDerTherapieziele: null,
      begruendungVerordnungAusserhalbRegelfall: null,
      arzt: customer.hausarzt,
      sozialeGemeinschaft: null,
      kmEinfacheFahrtZumPatienten: null,
      zuzahlungErhalten: null,
      behandlungAbgebrochen: null,
      abgerechnet: null,
      dateien: [],
      versichertennummer: customer.versichertennummer || null,
      versichertenstatus: customer.versichertenstatus || null,
      ikNummerKK: customer.ikNummerKK || null,
      urgent_treatment: false,
      diagnosegruppe: null,
      leitsymptomatik: null,
      nagelkorrektur: false,
      nagelkorrekturLokalisierung: null,
      nagelkorrekturIndikation: null,
    }
  }, [editMode, viewOnly, initialValues, customer])

  const formMethods = useForm<FormData>({
    defaultValues,
    shouldFocusError: true,
  })

  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = formMethods

  useEffect(() => {
    if (viewOnly) {
      reset({
        ...defaultValues,
        strasse: defaultValues?.strasse ?? '',
        hausnummer: defaultValues?.hausnummer ?? '',
        postleitzahl: defaultValues?.postleitzahl ?? '',
        ort: defaultValues?.ort ?? '',
        versichertennummer: defaultValues?.versichertennummer ?? '',
        versichertenstatus: defaultValues?.versichertenstatus ?? '',
        ikNummerKK: defaultValues?.ikNummerKK ?? '',
      })
    }
  }, [defaultValues, viewOnly])

  const urgent_treatment = watch('urgent_treatment')
  const ausstellungsdatum = watch('ausstellungsdatum')
  const heilmittel = watch('heilmittel')
  const leitsymptomatik = watch('leitsymptomatik')
  const diagnosegruppe = watch('diagnosegruppe')
  const hausbesuch = watch('hausbesuch')
  const nagelkorrektur = watch('nagelkorrektur')
  const nagelkorrekturLokalisierung = watch('nagelkorrekturLokalisierung')
  const nagelkorrekturIndikation = watch('nagelkorrekturIndikation')
  const healthInsurance = watch('krankenkasse')
  const medicallyPrescribed = watch('medicallyPrescribed')
  const socialFacility = watch('socialFacility')

  const startOfTreatmentAtLatestDays = useMemo(
    () => (urgent_treatment ? urgentTreatmentFactor : behandlungsbeginnSpaetestensAmDays),
    [urgent_treatment],
  )

  useDeepCompareEffect(() => {
    if (!!ausstellungsdatum) {
      const otherCustomerHVOs = customerHVOs.filter((hvo) => hvo.id !== hvoId)

      if (!nagelkorrektur && heilmittel) {
        const sameDay = otherCustomerHVOs.find(
          (hvo) => isSameDay(hvo.ausstellungsdatum, ausstellungsdatum) && hvo.heilmittel === heilmittel,
        )

        if (sameDay) {
          setError('ausstellungsdatum', {
            message: 'HVO mit gleichem Ausstellungsdatum liegt bereits vor!',
          })
        } else {
          clearErrors('ausstellungsdatum')

          const similarDates = otherCustomerHVOs.filter(
            (hvo) =>
              Math.abs(differenceInDays(hvo.ausstellungsdatum, ausstellungsdatum)) < 21 &&
              hvo.heilmittel === heilmittel,
          )

          if (similarDates.length > 1) {
            setAusstellungsdatumWarn('HVO mit ähnlichem Ausstellungsdatum liegt bereits vor!')
          } else if (!!similarDates.length) {
            setAusstellungsdatumWarn(
              `HVO mit ähnlichem Ausstellungsdatum (${format(
                similarDates[0].ausstellungsdatum,
                GERMAN_DATE_LONG_YEAR_FNS,
              )}) liegt bereits vor!`,
            )
          } else {
            setAusstellungsdatumWarn('')
          }
        }
      }

      if (!!nagelkorrektur && !!nagelkorrekturLokalisierung) {
        const otherCustomerHVOsNagelkorrektur = otherCustomerHVOs.filter(
          (hvo) => hvo.nagelkorrektur && hvo.nagelkorrekturLokalisierung === nagelkorrekturLokalisierung,
        )

        if (otherCustomerHVOsNagelkorrektur?.length) {
          const sameDay = otherCustomerHVOsNagelkorrektur.find((hvo) =>
            isSameDay(hvo.ausstellungsdatum, ausstellungsdatum),
          )

          if (sameDay) {
            setError('ausstellungsdatum', {
              message: 'HVO mit gleichem Ausstellungsdatum und gleicher Lokalisierung liegt bereits vor!',
            })
          } else {
            clearErrors('ausstellungsdatum')

            const similarDates = otherCustomerHVOsNagelkorrektur.filter(
              (hvo) => Math.abs(differenceInDays(hvo.ausstellungsdatum, ausstellungsdatum)) < 21,
            )

            if (similarDates.length > 1) {
              setAusstellungsdatumWarn('HVO mit ähnlichem Ausstellungsdatum liegt bereits vor!')
            } else if (!!similarDates.length) {
              setAusstellungsdatumWarn(
                `HVO mit ähnlichem Ausstellungsdatum (${format(
                  similarDates[0].ausstellungsdatum,
                  GERMAN_DATE_LONG_YEAR_FNS,
                )}) liegt bereits vor!`,
              )
            } else {
              setAusstellungsdatumWarn('')
            }
          }
        } else {
          clearErrors('ausstellungsdatum')
        }
      }
    }
  }, [ausstellungsdatum, customerHVOs, hvoId, heilmittel, nagelkorrektur, nagelkorrekturLokalisierung])

  useEffect(() => {
    if (!!leitsymptomatik && !!diagnosegruppe) {
      let value: string | number = ''

      if (leitsymptomatik === 'a') {
        if (diagnosegruppe === 'Df') {
          value = 78001
        } else if (diagnosegruppe === 'Nf') {
          value = 78002
        } else if (diagnosegruppe === 'Qf') {
          value = 78003
        }
      } else if (leitsymptomatik === 'b') {
        if (diagnosegruppe === 'Df') {
          value = 78004
        } else if (diagnosegruppe === 'Nf') {
          value = 78005
        } else if (diagnosegruppe === 'Qf') {
          value = 78006
        }
      } else if (leitsymptomatik === 'c') {
        if (diagnosegruppe === 'Df') {
          value = 78007
        } else if (diagnosegruppe === 'Nf') {
          value = 78008
        } else if (diagnosegruppe === 'Qf') {
          value = 78009
        }
      }

      setValue('heilmittel', value as number)
    }
  }, [leitsymptomatik, diagnosegruppe])

  useEffect(() => {
    if (healthInsurance !== 16) {
      setValue('otherInsurance', null)
    }
  }, [healthInsurance])

  const checkIcd10CodeCategory = (): void => {
    if (!nagelkorrektur) {
      const fieldValues = getValues(['diagnosegruppe', 'icd10Code', 'icd10CodeOptional'])

      if (!fieldValues?.[0]) {
        // No diagnosegruppe selected yet
        setIcd10CodeCategoryWarning(null)
        setIcd10CodeOptionalCategoryWarning(null)
      } else {
        if (fieldValues?.[1]) {
          const codeExistsInCategory = icd10ItemsByDiagnosis[fieldValues[0] as 'Nf' | 'Qf' | 'Df'].some(
            (code) => code.value === fieldValues[1],
          )
          if (!codeExistsInCategory) {
            setIcd10CodeCategoryWarning(
              <span style={{ color: 'red' }}>
                Dieser ICD10-Code ist nicht für diese Diagnosegruppe kategorisiert! Die HVO kann ggfs. trotzdem angelegt
                werden.
              </span>,
            )
          } else {
            setIcd10CodeCategoryWarning(null)
          }
        } else {
          setIcd10CodeCategoryWarning(null)
        }

        if (fieldValues?.[2]) {
          const codeExistsInCategory = icd10ItemsByDiagnosis[fieldValues[0] as 'Nf' | 'Qf' | 'Df'].some(
            (code) => code.value === fieldValues[2],
          )
          if (!codeExistsInCategory) {
            setIcd10CodeOptionalCategoryWarning(
              <span style={{ color: 'red' }}>
                Dieser ICD10-Code ist nicht für diese Diagnosegruppe kategorisiert! Die HVO kann ggfs. trotzdem angelegt
                werden.
              </span>,
            )
          } else {
            setIcd10CodeOptionalCategoryWarning(null)
          }
        } else {
          setIcd10CodeOptionalCategoryWarning(null)
        }
      }
    } else {
      setIcd10CodeCategoryWarning(null)
      setIcd10CodeOptionalCategoryWarning(null)
      return
    }
  }

  const handleIcd10Change = (
    _: unknown,
    __: unknown,
    reason: 'select-option' | 'clear' | 'blur',
    name: 'icd10Code' | 'icd10CodeOptional',
  ): void => {
    if (reason === 'clear' || reason === 'blur') {
      checkIcd10CodeCategory()
    }
  }

  const onSubmit = async (data: FormData) => {
    if (data.nagelkorrektur) {
      data.icd10Code = 'L60.0'
    }

    const normalizedData = normalizeFields(data)

    await onSubmitFunc(normalizedData)
  }

  const isRequiredSign = isArniCustomer ? ' (*)' : ''

  return (
    <FormProvider {...formMethods}>
      <StyledHVOForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="patientInfo">
          <div className="patientInfoGroup">
            <ControlledInputField control={control} name="vorname" label="Vorname" readOnly />
            <ControlledInputField control={control} name="nachname" label="Nachname" readOnly />
            <ControlledInputField
              control={control}
              name="versichertennummer"
              label={`Versichertennummer${isRequiredSign}`}
              error={!!errors?.versichertennummer}
              helperText={errors?.versichertennummer?.message}
              readOnly={viewOnly}
              rules={{
                validate: {
                  isRequired: (value) => {
                    if (isArniCustomer && !value) {
                      return 'Bitte Versichertennummer eingeben!'
                    }

                    return true
                  },
                  isValid: (value) => {
                    const krankenkassenValue = getValues('krankenkasse')
                    const isPrivateKK = !krankenkassenValue || krankenkassenValue === 1 || krankenkassenValue === 15

                    if (!isPrivateKK && !!value && !/^[A-Z]{0,1}[0-9]{8,9}$/.test(value)) {
                      return 'Bitte gültige Versicherten-Nr. eingeben!'
                    }

                    return true
                  },
                },
              }}
            />
            <ControlledInputField
              control={control}
              name="versichertenstatus"
              label={`Versichertenstatus${isRequiredSign}`}
              error={!!errors?.versichertenstatus}
              helperText={errors?.versichertenstatus?.message}
              readOnly={viewOnly}
              rules={{
                validate: {
                  isRequired: (value) => {
                    if (isArniCustomer && !value) {
                      return 'Bitte Versichertenstatus auswählen!'
                    }

                    return true
                  },
                },
              }}
            />
          </div>

          <div className="patientInfoGroup">
            <div className="streetGroup">
              <ControlledInputField
                control={control}
                name="strasse"
                label="Straße"
                InputProps={{ autoComplete: 'nope' }}
                readOnly={viewOnly}
                error={!!errors?.strasse}
                helperText={errors?.strasse?.message}
              />
              <ControlledInputField
                control={control}
                label="Haus-Nr."
                name="hausnummer"
                InputProps={{ autoComplete: 'nope' }}
                readOnly={viewOnly}
                error={!!errors?.hausnummer}
                helperText={errors?.hausnummer?.message}
              />
            </div>
            <div className="addressGroup">
              <ControlledInputField
                control={control}
                name="postleitzahl"
                label="PLZ"
                InputProps={{ autoComplete: 'nope' }}
                readOnly={viewOnly}
                error={!!errors?.postleitzahl}
                helperText={errors?.postleitzahl?.message}
              />
              <ControlledInputField
                control={control}
                name="ort"
                label="Ort"
                InputProps={{ autoComplete: 'nope' }}
                readOnly={viewOnly}
                error={!!errors?.ort}
                helperText={errors?.ort?.message}
              />
            </div>
          </div>

          <div className="patientInfoGroup">
            <ControlledSelectField
              fullWidth
              label={`Krankenkasse${isRequiredSign}`}
              control={control}
              name="krankenkasse"
              options={krankenkassenSelect.map((k) => ({ value: k.value, label: k.text }))}
              error={!!errors?.krankenkasse}
              helperText={errors?.krankenkasse?.message}
              readOnly={viewOnly}
              rules={{
                validate: {
                  isRequired: (value) => {
                    if (isArniCustomer && !value) {
                      return 'Bitte Krankenkasse auswählen!'
                    }

                    return true
                  },
                },
              }}
              onChange={() => {
                trigger('versichertennummer')
              }}
            />

            {healthInsurance === 16 && (
              <ControlledInputField
                control={control}
                label={`Sonstige Kostenträger${isRequiredSign}`}
                name="otherInsurance"
                error={!!errors?.otherInsurance}
                helperText={errors?.otherInsurance?.message}
                readOnly={viewOnly}
                rules={{
                  validate: {
                    isRequired: (value) => {
                      if (isArniCustomer && !value) {
                        return 'Bitte einen Kostenträger eingeben!'
                      }

                      if (healthInsurance === 16 && !value) {
                        return 'Bitte einen Kostenträger eingeben!'
                      }

                      return true
                    },
                  },
                }}
              />
            )}

            <ControlledInputField
              control={control}
              label={`Kostenträgerkennung${isRequiredSign}`}
              name="ikNummerKK"
              error={!!errors?.ikNummerKK}
              helperText={errors?.ikNummerKK?.message}
              readOnly={viewOnly}
              rules={{
                validate: {
                  isRequired: (value) => {
                    if (isArniCustomer && !value) {
                      return 'Bitte Kostenträgerkennung eingeben!'
                    }

                    return true
                  },
                  isValid: (value) => {
                    if (isArniCustomer && !!value && !/^[0-9]{9}$/.test(value)) {
                      return 'Bitte gültige Kostenträgerkennung eingeben!'
                    }

                    return true
                  },
                },
              }}
            />
            <ControlledAutocompleteField
              control={control}
              label={`Arzt${isRequiredSign}`}
              name="arzt"
              options={doctorsAutocompleteItems.map((item) => ({ value: item.value, label: item.text }))}
              error={!!errors?.arzt}
              helperText={errors?.arzt?.message}
              readOnly={viewOnly}
              rules={{
                validate: {
                  isRequired: (value) => {
                    if (isArniCustomer && !value) {
                      return 'Bitte Arzt auswählen!'
                    }

                    return true
                  },
                },
              }}
            />
          </div>
        </div>

        <FormDivider />

        <div className="checkboxArea">
          <div className="checkboxGroup">
            <ControlledCheckboxField
              className="report"
              control={control}
              name="nagelkorrektur"
              label="Nagelkorrektur"
              error={!!errors?.nagelkorrektur}
              helperText={errors?.nagelkorrektur?.message}
              onChange={(checked) => {
                if (checked) {
                  setValue('icd10Code', 'L60.0')
                }

                if (!checked) {
                  setValue('icd10Code', !defaultValues?.nagelkorrektur ? defaultValues?.icd10Code : null)
                  setValue('nagelkorrektur', false)
                  setValue('nagelkorrekturLokalisierung', null)
                  setValue('nagelkorrekturIndikation', null)
                }
              }}
              readOnly={viewOnly}
            />
            {!!nagelkorrektur && (
              <>
                <ControlledCheckboxField
                  className="report"
                  control={control}
                  name="folgeverordnung"
                  label="Folgeverordnung"
                  error={!!errors?.nagelkorrektur}
                  helperText={errors?.nagelkorrektur?.message}
                  readOnly={viewOnly}
                />
                <ControlledCheckboxField
                  className="report"
                  control={control}
                  label="Dringlicher Behandlungsbedarf (14 Tage)"
                  name="urgent_treatment"
                  onChange={(checked) => {
                    const localDateFactor = checked ? urgentTreatmentFactor : behandlungsbeginnSpaetestensAmDays
                    setValue('behandlungsbeginnSpaetestensAm', addDays(ausstellungsdatum, localDateFactor))
                    trigger('ausstellungsdatum')
                  }}
                  error={!!errors?.urgent_treatment}
                  helperText={errors?.urgent_treatment?.message}
                  readOnly={viewOnly}
                />
                <ControlledCheckboxField
                  readOnly={viewOnly}
                  className="report"
                  control={control}
                  label="Gebührenbefreit"
                  name="gebuehrenbefreit"
                  error={!!errors?.gebuehrenbefreit}
                  helperText={errors?.gebuehrenbefreit?.message}
                />
                <ControlledCheckboxField
                  readOnly={viewOnly}
                  className="report"
                  control={control}
                  label="Therapiebericht"
                  name="therapiebericht"
                  error={!!errors?.therapiebericht}
                  helperText={errors?.therapiebericht?.message}
                />
                <ControlledCheckboxField
                  className="report"
                  control={control}
                  label="Hausbesuch"
                  name="hausbesuch"
                  readOnly={viewOnly}
                  onChange={(checked) => {
                    if (!checked) {
                      setValue('medicallyPrescribed', false)
                      setValue('socialFacility', false)
                    }
                  }}
                  error={!!errors?.hausbesuch}
                  helperText={errors?.hausbesuch?.message}
                  rules={{
                    validate: () =>
                      validateHausbesuchInput(hausbesuch, medicallyPrescribed, socialFacility, setError, clearErrors),
                  }}
                />
                {!!hausbesuch && (
                  <div style={{ marginLeft: 30, display: 'grid', gridTemplateColumns: '1fr', gridGap: 8 }}>
                    <ControlledCheckboxField
                      readOnly={viewOnly}
                      className="report"
                      control={control}
                      label="ärztlich verordnet, inkl. Wegegeld"
                      name="medicallyPrescribed"
                      onChange={(checked) => checked && setValue('socialFacility', false)}
                      error={!!errors?.medicallyPrescribed}
                      helperText={errors?.medicallyPrescribed?.message}
                      rules={{
                        validate: () =>
                          validateHausbesuchInput(
                            hausbesuch,
                            medicallyPrescribed,
                            socialFacility,
                            setError,
                            clearErrors,
                          ),
                      }}
                    />
                    <ControlledCheckboxField
                      readOnly={viewOnly}
                      className="report"
                      control={control}
                      label="in sozialer Einrichtung, inkl. Wegegeld"
                      name="socialFacility"
                      onChange={(checked) => checked && setValue('medicallyPrescribed', false)}
                      error={!!errors?.socialFacility}
                      helperText={errors?.socialFacility?.message}
                      rules={{
                        validate: () =>
                          validateHausbesuchInput(
                            hausbesuch,
                            medicallyPrescribed,
                            socialFacility,
                            setError,
                            clearErrors,
                          ),
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <FormDivider />

        {!!nagelkorrektur && (
          <>
            <Paper style={{ padding: 12 }}>
              <h3>Hilfeartikel zur Nagelkorrektur</h3>
              <LinkParagraph>
                <a
                  href="https://pododesk.freshdesk.com/a/solutions/articles/17000128294"
                  target="_blank"
                  rel="noreferrer"
                >
                  Erklärung der HPNR und Regelleistungszeiten bei der Nagelkorrekturspange
                </a>
              </LinkParagraph>
              <LinkParagraph>
                <a
                  href="https://pododesk.freshdesk.com/a/solutions/articles/17000128295"
                  target="_blank"
                  rel="noreferrer"
                >
                  Anleitung zur Eingabe einer HVO zur Nagelkorrekturspange
                </a>
              </LinkParagraph>
            </Paper>

            <FormDivider />
          </>
        )}

        <div className="dateGroup">
          <ControlledDateField
            readOnly={viewOnly}
            control={control}
            rules={{
              required: getErrorMessage('required'),
              validate: {
                isNotFuture: (value) => {
                  if (!!value && !isSameDay(value, today) && value > today) {
                    return 'Ausstellungsdatum darf nicht in der Zukunft liegen!'
                  }

                  return true
                },
                // check if value is valid date
                isValidDate,
                otherHVOsSameDay: (value) => {
                  if (!!value) {
                    const otherCustomerHVOs = customerHVOs.filter((hvo) => hvo.id !== hvoId)

                    if (!nagelkorrektur && heilmittel) {
                      const sameDay = otherCustomerHVOs.find(
                        (hvo) => isSameDay(hvo.ausstellungsdatum, ausstellungsdatum) && hvo.heilmittel === heilmittel,
                      )

                      if (sameDay) {
                        return 'HVO mit gleichem Ausstellungsdatum liegt bereits vor!'
                      } else {
                        return true
                      }
                    }

                    if (!!nagelkorrektur && !!nagelkorrekturLokalisierung) {
                      const otherCustomerHVOsNagelkorrektur = otherCustomerHVOs.filter(
                        (hvo) => hvo.nagelkorrektur && hvo.nagelkorrekturLokalisierung === nagelkorrekturLokalisierung,
                      )

                      if (otherCustomerHVOsNagelkorrektur?.length) {
                        const sameDay = otherCustomerHVOsNagelkorrektur.find((hvo) =>
                          isSameDay(hvo.ausstellungsdatum, ausstellungsdatum),
                        )

                        if (sameDay) {
                          return 'HVO mit gleichem Ausstellungsdatum und gleicher Lokalisierung liegt bereits vor!'
                        } else {
                          return true
                        }
                      }
                    }
                  }

                  return true
                },
              },
            }}
            disableFuture
            label="Ausstellungsdatum"
            name="ausstellungsdatum"
            error={!!ausstellungsdatumWarn || !!errors?.ausstellungsdatum}
            helperText={ausstellungsdatumWarn || errors?.ausstellungsdatum?.message}
            onChange={(date) => {
              !!date && setValue('behandlungsbeginnSpaetestensAm', addDays(date, startOfTreatmentAtLatestDays))
            }}
          />

          <ControlledDateField
            readOnly={viewOnly}
            control={control}
            rules={{
              required: getErrorMessage('required'),
              validate: {
                isValidDate,
              },
            }}
            name="behandlungsbeginnSpaetestensAm"
            label="Behandlungsbeginn spätestens am"
            error={!!errors?.behandlungsbeginnSpaetestensAm}
            helperText={errors?.behandlungsbeginnSpaetestensAm?.message}
          />
        </div>

        <FormDivider />

        {!!nagelkorrektur ? (
          <>
            <div className="checkboxArea">
              <div className="checkboxGroup">
                <ControlledRadioButtonGroup
                  readOnly={viewOnly}
                  control={control}
                  name="nagelkorrekturLokalisierung"
                  label="Lokalisierung"
                  options={nagelkorrekturLokalisierungOptions}
                  error={!!errors?.nagelkorrekturLokalisierung}
                  helperText={errors?.nagelkorrekturLokalisierung?.message}
                  rules={{ required: getErrorMessage('required') }}
                />
                <ControlledRadioButtonGroup
                  readOnly={viewOnly}
                  control={control}
                  name="nagelkorrekturIndikation"
                  label="Indikationsschlüssel"
                  options={[
                    { label: 'UI1a', value: 'UI1a' },
                    { label: 'UI2a', value: 'UI2a' },
                  ]}
                  error={!!errors?.nagelkorrekturIndikation}
                  helperText={errors?.nagelkorrekturIndikation?.message}
                  rules={{ required: getErrorMessage('required') }}
                  onChange={(value) => {
                    setValue('diagnosegruppe', value.substring(0, 3))
                    setValue('leitsymptomatik', value.substring(3, 4))
                    setValue('verordnungsmenge', getBehandlungseinheitenForDiagnosegruppe(value))
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <ControlledInputField
                readOnly={viewOnly}
                control={control}
                name="verordnungsmenge"
                label="Behandlungseinheiten"
                rules={{
                  required: getErrorMessage('required'),
                  validate: (value) => {
                    if (!value) return true

                    const numValue = Number(value)

                    if (isNaN(numValue)) return 'Bitte geben Sie eine gültige Zahl ein'

                    if (numValue < 1) return 'Bitte geben Sie eine Zahl größer 0 ein'

                    if (!!nagelkorrekturIndikation) {
                      const num = getBehandlungseinheitenForDiagnosegruppe(nagelkorrekturIndikation)

                      if (numValue > num) return `Die maximale Anzahl an Behandlungseinheiten beträgt ${num}`
                    }
                  },
                }}
                error={!!errors?.verordnungsmenge}
                helperText={errors?.verordnungsmenge?.message}
              />
            </div>
            <div className="nagelkorrekturInfo">
              <span style={{ fontSize: '1rem' }}>ICD-10 Code: L60.0</span>
            </div>

            {!!viewOnly && (
              <>
                <FormDivider />
                <HVOServicesAppointmentsList />
                <FormDivider />
              </>
            )}
          </>
        ) : (
          <>
            <div className="checkboxArea">
              <div className="checkboxGroup">
                <ControlledCheckboxField
                  readOnly={viewOnly}
                  className="report"
                  control={control}
                  label="Gebührenbefreit"
                  name="gebuehrenbefreit"
                  error={!!errors?.gebuehrenbefreit}
                  helperText={errors?.gebuehrenbefreit?.message}
                />
                <ControlledCheckboxField
                  readOnly={viewOnly}
                  className="report"
                  control={control}
                  label="Therapiebericht"
                  name="therapiebericht"
                  error={!!errors?.therapiebericht}
                  helperText={errors?.therapiebericht?.message}
                />
              </div>
              <div className="checkboxGroup">
                <ControlledCheckboxField
                  className="report"
                  control={control}
                  label="Hausbesuch"
                  name="hausbesuch"
                  readOnly={viewOnly}
                  onChange={(checked) => {
                    if (!checked) {
                      setValue('medicallyPrescribed', false)
                      setValue('socialFacility', false)
                    }
                  }}
                  error={!!errors?.hausbesuch}
                  helperText={errors?.hausbesuch?.message}
                  rules={{
                    validate: () =>
                      validateHausbesuchInput(hausbesuch, medicallyPrescribed, socialFacility, setError, clearErrors),
                  }}
                />
                {!!hausbesuch && (
                  <div style={{ marginLeft: 30, display: 'grid', gridTemplateColumns: '1fr', gridGap: 8 }}>
                    <ControlledCheckboxField
                      readOnly={viewOnly}
                      className="report"
                      control={control}
                      label="ärztlich verordnet, inkl. Wegegeld"
                      name="medicallyPrescribed"
                      onChange={(checked) => checked && setValue('socialFacility', false)}
                      error={!!errors?.medicallyPrescribed}
                      helperText={errors?.medicallyPrescribed?.message}
                      rules={{
                        validate: () =>
                          validateHausbesuchInput(
                            hausbesuch,
                            medicallyPrescribed,
                            socialFacility,
                            setError,
                            clearErrors,
                          ),
                      }}
                    />
                    <ControlledCheckboxField
                      readOnly={viewOnly}
                      className="report"
                      control={control}
                      label="in sozialer Einrichtung, inkl. Wegegeld"
                      name="socialFacility"
                      onChange={(checked) => checked && setValue('medicallyPrescribed', false)}
                      error={!!errors?.socialFacility}
                      helperText={errors?.socialFacility?.message}
                      rules={{
                        validate: () =>
                          validateHausbesuchInput(
                            hausbesuch,
                            medicallyPrescribed,
                            socialFacility,
                            setError,
                            clearErrors,
                          ),
                      }}
                    />
                  </div>
                )}
                <ControlledCheckboxField
                  readOnly={viewOnly}
                  className="report"
                  control={control}
                  label="Dringlicher Behandlungsbedarf (14 Tage)"
                  name="urgent_treatment"
                  onChange={(checked) => {
                    const localDateFactor = checked ? urgentTreatmentFactor : behandlungsbeginnSpaetestensAmDays
                    setValue('behandlungsbeginnSpaetestensAm', addDays(ausstellungsdatum, localDateFactor))
                  }}
                  error={!!errors?.urgent_treatment}
                  helperText={errors?.urgent_treatment?.message}
                />
              </div>
            </div>

            <FormDivider />

            <div className="hvoInfo">
              <ControlledRadioButtonGroup
                readOnly={viewOnly}
                control={control}
                name="diagnosegruppe"
                label="Diagnosegruppe"
                rules={{ required: getErrorMessage('required') }}
                error={!!errors?.diagnosegruppe}
                helperText={errors?.diagnosegruppe?.message}
                options={diagnosisGroupOptions}
                onChange={checkIcd10CodeCategory}
              />
              <ControlledRadioButtonGroup
                readOnly={viewOnly}
                control={control}
                name="leitsymptomatik"
                options={cardinalSymptomOptions}
                label="Leitsymptomatik"
                rules={{ required: getErrorMessage('required') }}
                error={!!errors?.leitsymptomatik}
                helperText={errors?.leitsymptomatik?.message}
              />
              <ControlledSelectField
                readOnly
                control={control}
                fullWidth
                name="heilmittel"
                label="Heilmittel"
                disabled
                helperText={
                  errors?.heilmittel?.message ?? heilmittel?.indikationsschluessel
                    ? `Indikationsschlüssel: ${heilmittel?.indikationsschluessel}`
                    : ''
                }
                error={!!errors?.heilmittel}
                options={remedyOptions}
              />
              <ControlledInputField
                readOnly={viewOnly}
                control={control}
                label="min. Frequenz"
                rules={{ required: getErrorMessage('required') }}
                name="minFrequenz"
                error={!!errors?.minFrequenz}
                helperText={errors?.minFrequenz?.message}
              />
              <ControlledInputField
                readOnly={viewOnly}
                control={control}
                label="max. Frequenz"
                rules={{ required: getErrorMessage('required') }}
                name="maxFrequenz"
                error={!!errors?.maxFrequenz}
                helperText={errors?.maxFrequenz?.message}
              />
            </div>

            <div className="icdGroup">
              <div className="icdInputGroup">
                <ControlledInputField
                  readOnly={viewOnly}
                  control={control}
                  name="verordnungsmenge"
                  label="Behandlungseinheiten"
                  rules={{
                    required: getErrorMessage('required'),
                    // Commented out until further notice because of pending change request analysis
                    // validate: (value) => {
                    //   if (!value) return true

                    //   const numValue = Number(value)

                    //   if (isNaN(numValue)) return 'Bitte geben Sie eine gültige Zahl ein'

                    //   if (numValue < 3) return 'Die minimale Anzahl an Behandlungseinheiten beträgt 3'

                    //   if (numValue > 6) return 'Die maximale Anzahl an Behandlungseinheiten beträgt 6'
                    // },
                  }}
                  error={!!errors?.verordnungsmenge}
                  helperText={errors?.verordnungsmenge?.message}
                />
                <ControlledAutocompleteField
                  readOnly={viewOnly}
                  control={control}
                  name="icd10Code"
                  label="ICD-10 Code"
                  rules={{ required: getErrorMessage('required') }}
                  options={
                    ['Nf', 'Df', 'Qf'].includes(diagnosegruppe) ? icd10ItemsByDiagnosis[diagnosegruppe] : icd10ItemsNew
                  }
                  blurOnSelect
                  error={!!errors?.icd10Code}
                  helperText={errors?.icd10Code?.message ?? icd10CodeCategoryWarning ?? ''}
                  freeSolo
                  autoSelect
                  onChange={(e, value, reason) => handleIcd10Change(e, value, reason, 'icd10Code')}
                />
                <ControlledAutocompleteField
                  readOnly={viewOnly}
                  control={control}
                  name="icd10CodeOptional"
                  label="ICD-10 Code"
                  options={
                    ['Nf', 'Df', 'Qf'].includes(diagnosegruppe) ? icd10ItemsByDiagnosis[diagnosegruppe] : icd10ItemsNew
                  }
                  blurOnSelect
                  error={!!errors?.icd10CodeOptional}
                  helperText={errors?.icd10CodeOptional?.message ?? icd10CodeOptionalCategoryWarning ?? ''}
                  freeSolo
                  autoSelect
                  onChange={(e, value, reason) => handleIcd10Change(e, value, reason, 'icd10Code')}
                />
                <ControlledInputField
                  readOnly={viewOnly}
                  control={control}
                  name="diagnoseMitLeitsymptomatikWesentlicheBefunde"
                  label="Freitext patientenindividuelle Leitsymptomatik"
                  error={!!errors?.diagnoseMitLeitsymptomatikWesentlicheBefunde}
                  helperText={errors?.diagnoseMitLeitsymptomatikWesentlicheBefunde?.message}
                  multiline
                  minRows={7}
                  maxRows={7}
                />
              </div>
              <Paper square elevation={1} className="infoPaper">
                <p>
                  <strong>Zwingende Voraussetzung um Absetzungen zu vermeiden:</strong>
                </p>
                <p>
                  Bei der <strong>Diagnosegruppe DF</strong> ist die Verordnungsfähigkeit des Diabetischen Fußsyndrom
                  (DFS) mit Angiopathie (ohne Neuropathie) weggefallen.
                  <br />
                  Hier ist nur noch das Diabetische Fußsyndrom (DFS) <strong>mit Neuropathie</strong> (auch ohne
                  Angiopathie) verordnungsfähig.
                  <br />
                  Die Diagnose kann als Freitext auch durch die ICD-10-Codes ersetzt werden:
                  <br />
                </p>
                <h4>
                  <strong>Diabetisches Fußsyndrom:</strong>
                </h4>
                <p>
                  {icdCodeLinksDiabetics.map(({ label, link }, index) => (
                    <>
                      <a href={link} target="_blank" rel="noreferrer" key={label}>
                        {label}
                      </a>
                      {(index + 1) % 2 ? <LinkSeparator>|</LinkSeparator> : <br />}
                    </>
                  ))}
                </p>
                <h4>
                  <strong>Neuropathie:</strong>
                </h4>
                <p>
                  {icdCodeLinksNeuropathy.map(({ label, link }, index) => (
                    <>
                      <a href={link} target="_blank" rel="noreferrer" key={label}>
                        {label}
                      </a>
                      {(index + 1) % 2 ? <LinkSeparator>|</LinkSeparator> : <br />}
                    </>
                  ))}
                </p>
              </Paper>
            </div>
          </>
        )}

        {!!nagelkorrektur && !!editMode && <FormDivider />}

        <ControlledInputField
          readOnly={viewOnly}
          control={control}
          name="spezifizierungDerTherapieziele"
          label="ggfs. Therapieziele / weitere med. Befunde und Hinweise"
          placeholder="Nur notwendig, wenn sie sich nicht aus der Diagnose und Leitsymptomatik ergeben"
          error={!!errors?.spezifizierungDerTherapieziele}
          helperText={errors?.spezifizierungDerTherapieziele?.message}
          multiline
          minRows={5}
          maxRows={5}
        />

        {!viewOnly && (
          <>
            <FormDivider />

            <FileUploadWithLightboxRHF
              control={control}
              name="dateien"
              assignTo={{ id: hvoId, entity: 'heilmittelverordnungen' }}
              style={{ paddingBottom: '15px' }}
            />

            <Divider />
            <ButtonContainer>
              <Button variant="contained" color="secondary" fullWidth type="submit" disabled={isSubmitting}>
                {submitText}
              </Button>
            </ButtonContainer>
          </>
        )}
      </StyledHVOForm>
      {isLocalDev() && <FormDevTool control={control} />}
    </FormProvider>
  )
}

const mapStateToProps = (state, props) => ({
  busy: state.notification.busy,
  krankenkassenSelect: kuerzelKrankenkassenSelector(state),
  doctorsAutocompleteItems: sortedDoctorsSelector(state),
  customerHVOs: sHVOUser(props.customer)(state),
  currentUser: sCurrentUser(state),
  isArniCustomer: praxisstammdatenSelector(state).arniCustomer,
})

export default connect(mapStateToProps)(HVOForm)

export const ViewHVOForm = connect(mapStateToProps)(memo(HVOForm))
