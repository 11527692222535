import styled from 'styled-components'

export const StyledCheckboxList = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .checkbox{
      width: auto;
    }
  `}
`;
