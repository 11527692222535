import { Dialog, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import styled from 'styled-components'
import AddKassenbucheintragForm from '../AddKassenbucheintragForm/AddKassenbucheintragForm'

const StyledPaper = styled(Paper)`
  height: max-content;
  width: 600px;
  background-color: '#fff';
`

const AddKassenbucheintragDialog = ({ onRequestClose, open }) => (
  <Dialog disableBackdropClick open={open} onClose={onRequestClose} PaperComponent={StyledPaper}>
    <DialogTitle>Kassenbucheintrag erfassen</DialogTitle>
    <DialogContent>
      <AddKassenbucheintragForm onRequestClose={onRequestClose} />
    </DialogContent>
  </Dialog>
)

export default AddKassenbucheintragDialog
