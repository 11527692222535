/* eslint camelcase: 0 */
export default function bundeslaender(
  state = {
    1: { id: 1, kuerzel: 'BW', name: 'Baden-Württemberg' },
    2: { id: 2, kuerzel: 'BY', name: 'Bayern' },
    3: { id: 3, kuerzel: 'BE', name: 'Berlin' },
    4: { id: 4, kuerzel: 'BB', name: 'Brandenburg' },
    5: { id: 5, kuerzel: 'HB', name: 'Bremen' },
    6: { id: 6, kuerzel: 'HH', name: 'Hamburg' },
    7: { id: 7, kuerzel: 'HE', name: 'Hessen' },
    8: { id: 8, kuerzel: 'MV', name: 'Mecklenburg-Vorpommern' },
    9: { id: 9, kuerzel: 'NI', name: 'Niedersachsen' },
    10: { id: 10, kuerzel: 'NW', name: 'Nordrhein-Westfalen' },
    11: { id: 11, kuerzel: 'RP', name: 'Rheinland-Pfalz' },
    12: { id: 12, kuerzel: 'SL', name: 'Saarland' },
    13: { id: 13, kuerzel: 'SN', name: 'Sachsen' },
    14: { id: 14, kuerzel: 'ST', name: 'Sachsen-Anhalt' },
    15: { id: 15, kuerzel: 'SH', name: 'Schleswig-Holstein' },
    16: { id: 16, kuerzel: 'TH', name: 'Thüringen' },
  },
) {
  return state
}
