import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import { RequestConfig } from './interface'

export function loadImage(dateiId, width = 400, height = 600): RequestConfig {
  return {
    types: [types.LOAD_IMAGE, types.LOAD_IMAGE_SUCCESS, types.LOAD_IMAGE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (state): boolean => {
      const datei = state.entities.dateien[dateiId]
      if (datei && datei.resized) {
        return !datei.resized[`${width}x${height}`]
      }
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dateien/${dateiId}/${width}/${height}`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

// In dem Array sizes können größen angegeben werden, die zusätzlich zum thumbnail geladen werden. Format: { width: 640, height: 480 }
export function loadFiles(files, options = { thumbnailWidth: 128, thumbnailHeight: 128, sizes: [] }): RequestConfig {
  return {
    types: [types.LOAD_FILES, types.LOAD_FILES_SUCCESS, types.LOAD_FILES_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.dates.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/dateien/multiple`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ dateien: files, options }),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function uploadFile({
  file: { size, type, name, data, bezeichnung },
  options,
  progressCb,
  successCb,
  failCb,
  assignTo,
}): RequestConfig {
  return {
    types: [types.UPLOAD_FILE, types.UPLOAD_FILE_SUCCESS, types.UPLOAD_FILE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): Promise<unknown> => {
      /*
       *  do XMLhttpRequest stuff here and return a promise
       *
       * */
      return new Promise((resolve, reject) => {
        const request = new window.XMLHttpRequest()
        const URL = apiUrl + '/dateien'
        const method = 'POST'

        request.upload.addEventListener('progress', handleProgress(progressCb))
        request.addEventListener('load', handleLoad({ request, resolve, reject, successCb, failCb }))
        request.addEventListener('error', handleFailure({ request, reject, failCb }))
        request.addEventListener('abort', handleFailure({ request, reject, failCb }))

        request.open(method, URL)
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        request.setRequestHeader('X-Auth', auth0Credentials)

        request.send(
          JSON.stringify({
            datei: {
              name,
              contentType: type,
              groesse: size,
              inhalt: data,
              bezeichnung,
            },
            options: { ...options },
            assignTo,
          }),
        )
        // abort after 30 sec because of heroku limits
        setTimeout(() => request.abort(), 30000)
      })
    },
    payload: { file: { name, type, size, data } },
  }
}

export function updateFile({ file: { id, size, type, name, data, bezeichnung }, options }): RequestConfig {
  return {
    types: [types.UPDATE_FILE, types.UPDATE_FILE_SUCCESS, types.UPDATE_FILE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = apiUrl + '/dateien/' + id

      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            datei: {
              name,
              contentType: type,
              groesse: size,
              inhalt: data,
              bezeichnung,
            },
            options: { ...options },
          }),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { file: { name, type, size, data } },
  }
}

const handleLoad = ({ resolve, reject, successCb, failCb, request }) => (): void => {
  /* daten aus event rausholen und resolve mit
   { ok: true, json: () => json_daten } .... aufrufen damits wie fetch() aussieht! */
  if (request.readyState === request.DONE) {
    if (request.status === 201) {
      const response = JSON.parse(request.responseText)
      resolve({
        ok: true,
        json: () => response,
        status: request.status,
        statusText: request.status,
      })
      if (successCb) {
        const file = response.entities.dateien[response.result[0]]
        successCb(file)
      }
    } else {
      reject({
        ok: false,
        status: request.status,
        statusText: request.status,
      })
      if (failCb) {
        failCb({
          ok: false,
          status: request.status,
          statusText: request.status,
        })
      }
    }
  }
}

const handleFailure = ({ request, reject, failCb }) => (): void => {
  reject({
    ok: false,
    status: request.status,
    statusText: request.status,
  })
  if (failCb) {
    failCb({
      ok: false,
      status: request.status,
      statusText: request.status,
    })
  }
}

const handleProgress = (progressCb) => (event): void => {
  if (!progressCb) {
    return
  }
  if (event.lengthComputable) {
    const percentComplete = (event.loaded / event.total) * 100
    progressCb(percentComplete)
  } else {
    progressCb(false)
  }
}
