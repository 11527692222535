import { FC, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import OverlayContainer from '../OverlayContainer/OverlayContainer'
import Profil from '../Profil/Profil'
import Startbereich from '../Startbereich/Startbereich'
import { PrivateRoute } from '../PrivateRoute'
import { LoginCallback } from '../../components/LoginCallback'

export const AppStart: FC<{}> = (): JSX.Element => {
  // Below block was commented out for testing of performance concerns because this triggers
  // loading of all the data at once. So far I could not find any problems with leaving this commented out
  // so this should be able to be removed

  // const actions = bindActionCreators(
  //   {
  //     loadAnamnesen,
  //     loadDate,
  //     loadDates,
  //     loadCustomer,
  //     loadCustomers,
  //     loadCustomersAlphabetically,
  //     loadDoctors,
  //     loadDokumentationen,
  //     loadHeilmittelverordnung,
  //     loadLeistungenPrivat,
  //     loadPeople,
  //     loadPraxisstammdaten,
  //     selectTherapeut,
  //   },
  //   store.dispatch,
  // )

  // function onEnterTherapeutansicht(nextState): void {
  //   actions.selectTherapeut(nextState.params.person)
  // }
  useEffect(() => {
    document['title'] = 'pododesk'
  }, [])

  return (
    <Routes>
        <Route path="" element={<Navigate replace to="startbereich" />} />
        <Route path="callback" element={<LoginCallback />} />
        <Route path="startbereich" element={<PrivateRoute><Startbereich /></PrivateRoute>} />
        <Route path="startbereich/profil/*" element={<PrivateRoute><Profil /></PrivateRoute>} />
        <Route path=":serverHash/*" element={<PrivateRoute><OverlayContainer /></PrivateRoute>} />
    </Routes>
  )
}
