import { Menu } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContextMenuTherapiebericht = styled(Menu)`
  ${({ theme }) => `
    .paper {
      background-color: ${theme.palette.highlight.main};
      color: ${theme.palette.secondary.main};
    }
  `}
`;
