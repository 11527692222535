import * as R from 'ramda'
import { createSelector } from 'reselect'
import {
  isBefore as fnsIsBefore,
  startOfDay,
  startOfMonth,
  isSameDay,
  isSameWeek,
  isSameMonth,
  startOfWeek,
} from 'date-fns'

const sKassenbuch = (state) => state.entities.kassenbuch

export const sKassenbuchArray = createSelector(
  sKassenbuch,
  R.compose(R.sortBy(R.compose(milliSeconds, R.prop('createdAt'))), R.values),
)

const sZeitraum = (_, props) => props.zeitraum

export const sSelectedDay = (state) => state.kassenbuch.selectedDay

export const zeitraumMapping = {
  tag: 'day',
  woche: 'week',
  monat: 'month',
}

export const sLetzteVorherigeBuchung = createSelector(
  sKassenbuchArray,
  sZeitraum,
  sSelectedDay,
  (kassenbuch, zeitraum, selectedDay) =>
    R.compose(
      R.last,
      R.filter(
        R.compose(
          isBefore(
            'day',
            zeitraumMapping[zeitraum] === 'day'
              ? startOfDay(selectedDay)
              : zeitraumMapping[zeitraum] === 'week'
              ? startOfWeek(selectedDay, { weekStartsOn: 1 })
              : startOfMonth(selectedDay),
          ),
          R.prop('createdAt'),
        ),
      ),
    )(kassenbuch),
)

export const sKassenbuchZeitraum = createSelector(
  sKassenbuchArray,
  sZeitraum,
  sSelectedDay,
  (kassenbuch, zeitraum, selectedDay) =>
    R.filter(R.compose(isSame(zeitraumMapping[zeitraum], selectedDay), R.prop('createdAt')))(kassenbuch),
)

const sKorrekturbuchungenParts = createSelector(
  sKassenbuchZeitraum,
  R.partition(R.propEq('buchungstyp', 'korrekturbuchung')),
)

export const sKorrekturbuchungen = createSelector(sKorrekturbuchungenParts, R.head)
export const sKassenbuchOhneKorrekturbuchungen = createSelector(sKorrekturbuchungenParts, R.last)

// isSame :: String -> Moment -> Moment -> Boolean
const isSame = R.curry((granularity, dateA, dateB) =>
  granularity === 'day'
    ? isSameDay(dateA, dateB)
    : granularity === 'week'
    ? isSameWeek(dateA, dateB, { weekStartsOn: 1 })
    : isSameMonth(dateA, dateB),
)

const isBefore = R.curry((_, dateA, dateB) => fnsIsBefore(dateB, dateA))

export const sAeltesteBuchung = createSelector(sKassenbuchZeitraum, R.head)

export const sJuengsteBuchung = createSelector(sKassenbuchZeitraum, R.last)

// milliSeconds :: Moment -> Integer
function milliSeconds(date) {
  return date.valueOf()
}

const sZahlungen = (state) => state.entities.zahlungen

const sZahlungspositionen = (state) => state.entities.zahlungspositionen
const sZahlungspositionenArray = createSelector(sZahlungspositionen, R.values)

const sZahlungIdsMitTermin = createSelector(
  sZahlungspositionenArray,
  R.compose(R.uniq, R.map(R.prop('zahlung')), R.filter(R.compose(R.not, R.isNil, R.prop('termin')))),
)

const sZahlungenMitTermin = createSelector(sZahlungIdsMitTermin, sZahlungen, (zahlungIdsMitTermin, zahlungen) => {
  return R.map((id) => zahlungen[id], zahlungIdsMitTermin)
})

export const sBuchungIdsMitTermin = createSelector(sZahlungenMitTermin, R.compose(R.uniq, R.map(R.prop('buchung'))))
