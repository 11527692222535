import { Button, Paper } from '@material-ui/core';
import { AccessTime as AccessTimeIcon } from '@material-ui/icons';
import { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import * as userActions from '../../actions/people';
import Zeiten from '../../components/Zeiten/Zeiten';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { sCurrentUserApi } from '../../shared/utils/users';
import { StyledArbeitszeiten } from './StyledArbeitszeiten';

const fields = [
  'id',
  'authUserId',
  'vorname',
  'nachname',
  'email',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'mobil',
  'praxisbezeichnung',
  'funktionInPraxis',
  'geschlecht',
  'wieVonPododeskErfahren',
  'agbAngenommen',
  'datenschutzbedingungenGelesen',
  'farbe',
  'istTherapeut',
  'arbeitszeiten',
  'disabled',
];

const Arbeitszeiten = ({ actions, handleSubmit, fields }): JSX.Element => {
  const onSubmit = useCallback(async (data) => {
    const { users } = actions;
    await users.updateInstanceUser(data);
  }, [actions]);

  return (
    <StyledArbeitszeiten onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <IconHeading icon={<AccessTimeIcon />} text="Meine Arbeitszeiten in dieser Praxis" />
      <Paper className="paper">
        <h2>Meine Arbeitszeiten</h2>
        <Zeiten {...fields.arbeitszeiten} />
        <Button className="submitButton" variant="contained" type="submit" color="secondary" fullWidth>
          Arbeitszeiten speichern
        </Button>
      </Paper>
    </StyledArbeitszeiten>
  );
};

const validate = (values) => {
  const errors: KeyValue<string> = {};

  const required: Array<string> = [];

  required.forEach((fieldName) => {
    if (!values[fieldName] && values[fieldName] !== 0) {
      errors[fieldName] = 'Bitte ausfüllen.';
    }
  });

  return errors;
};

const mapStateToProps = (state) => {
  const currentUser = sCurrentUserApi(state);
  const initialValues = {
    ...currentUser,
    arbeitszeiten: currentUser.arbeitszeiten ?? [
      {
        tag: 'mon',
        zeiten: [
          {
            von: '',
            bis: '',
          },
        ],
      },
    ],
  };

  return {
    currentUser,
    initialValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    users: bindActionCreators(userActions, dispatch),
  },
});

export default reduxForm(
  {
    form: 'arbeitszeitenForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(Arbeitszeiten);
