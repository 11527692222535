import styled from 'styled-components';

export const StyledTodoStack = styled.div`
  ${({ theme }) => `
    .MuiListItemIcon-root {
      min-width: unset;
      margin-right: 15px;
    }

    .itemActive {
      cursor: pointer;
      transition: 0.5s background-color;
      &:hover {
        background-color: rgba(${theme.palette.text.primaryRgb}, 0.3);
      }
    }

    .accordion {
      width: inherit;
      margin: 0;
      &.Mui-expanded {
        margin: 0;
      }
      &.Mui-disabled {
        cursor: not-allowed;
        background-color: rgba(${theme.palette.text.primaryRgb}, 0.1);
      }
    }
    .accordionSummary {
      background-color: ${theme.palette.primary.main};
      padding: 0 12px;

      .MuiAccordionSummary-content {
        &.Mui-disabled {
          margin: inherit;
        }
      }
    }
    .accordionSummaryText {
      margin-left: ${theme.spacing(2)}px;
    }
    .accordionDetails {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  `}
`;
