import MenuItemsAnamnese from './MenuItemsAnamnese'
import { StyledContextMenuAnamnese } from './StyledContextMenuAnamnese'

const ContextMenuAnamnese = ({
  pointerLocation: { mouseX, mouseY },
  handleClose,
  anamnese,
  dialogActions,
}) => {

  return (
    <StyledContextMenuAnamnese
      keepMounted
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <MenuItemsAnamnese
        dialogActions={dialogActions}
        anamnese={anamnese}
        onCloseFunc={handleClose} />
    </StyledContextMenuAnamnese>
  )
}

export default ContextMenuAnamnese
