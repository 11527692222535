import * as R from 'ramda'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import newReducers from '../redux/modules'
import aerzteFachgruppen from './aerzteFachgruppen'
import autocomplete from './autocomplete'
import bundeslaender from './bundeslaender'
import customersFilter from './customersFilter'
import dialogs from './dialogs'
import doctorsFilter from './doctorsFilter'
import entities from './entities'
import * as normalizers from './form-normalizers/index'
import forms from './forms'
import ids from './idsRewrite'
import initialLoadComplete from './initialLoadComplete'
import todosData from './todosData'
import kassenbuch from './kassenbuch'
import krankenkassen from './krankenkassen'
import leistungenKK from './leistungenKK'
import loading from './loading'
import notification from './notification'
import selectedDay from './selectedDay'
import selectedHeilmittelverordnung from './selectedHeilmittelverordnung'
import selectedPatient from './selectedPatient'
import selectedPerson from './selectedPerson'
import selectedTerminProps from './selectedTerminProps'
import selectedTherapeut from './selectedTherapeut'
import termineVonBisGeladen from './termineVonBisGeladen'
import totalReferences from './totalReferences'
import totalSearchResults from './totalSearchResults'
import updating from './updating'
import changelog from './changelog'
import { reduxLimitActionsToReducers } from './utils/redux-limit-actions-to-reducers'
import verguetungKK from './verguetungKK'

const combineWithIgnore = R.compose(
  combineReducers,
  reduxLimitActionsToReducers([
    {
      actionPredicate: (type) => /^redux-form/.test(type),
      reducerKeys: ['form'],
    },
  ]),
)

const appReducer = combineWithIgnore({
  aerzteFachgruppen,
  autocomplete,
  bundeslaender,
  changelog,
  customersFilter,
  dialogs,
  doctorsFilter,
  entities,
  form: formReducer.plugin(forms).normalize({
    hvoForm: {
      verordnungsmenge: normalizers.wholeNumber,
      minFrequenz: normalizers.wholeNumber,
      maxFrequenz: normalizers.wholeNumber,
      kmEinfacheFahrtZumPatienten: normalizers.wholeNumber,
    },
    leistungenPrivatForm: {
      regelbehandlungszeitMin: normalizers.wholeNumber,
      regelbehandlungszeitMax: normalizers.wholeNumber,
      mehrwertsteuer: normalizers.wholeNumber,
      verguetung: normalizers.currencyInCents
    },
    terminAbrechnenForm: {
      rezeptgebuehr: R.compose(normalizers.positiveInteger, normalizers.integerBounds, normalizers.currencyInCents),
      rabatt: R.compose(normalizers.negativeInteger, normalizers.integerBounds, normalizers.currencyInCents),
      summe: normalizers.terminAbrechnenFormSumme,
      'leistungen[]': R.ifElse(
        R.isNil,
        R.identity,
        R.map(
          R.over(
            R.lensPath(['value', 'verguetung']),
            R.compose(normalizers.integerBounds, normalizers.currencyInCents, normalizers.currencyInCents),
          ),
        ),
      ),
      produkte_und_sonstiges: R.compose(normalizers.positiveInteger, normalizers.integerBounds, normalizers.currencyInCents),
      belegnummer: R.compose(R.defaultTo('')),
    },
    viewTerminAbrechnenForm: {
      summe: normalizers.terminAbrechnenFormSumme,
    },
  }),
  ids,
  initialLoadComplete,
  
  todosData,
  kassenbuch,
  krankenkassen,
  leistungenKK,
  notification,
  selectedDay,
  selectedPerson,
  selectedPatient,
  selectedHeilmittelverordnung,
  selectedTherapeut,
  selectedTerminProps,
  termineVonBisGeladen,
  loading,
  updating,
  verguetungKK,
  totalReferences,
  totalSearchResults,
  ...newReducers,
})

/*
 * Anwendungszustand bei Logout löschen
 *
 * hier wird der router state mit der login route überschrieben,
 * weil es sonst nachdem man /change-password/5a220044f4b7431e016b83ac1935f4d8190c6e42
 * aufgerufen, das passwort geändert, sich eingeloggt und wieder ausgeloggt hat,
 * zu dem Problem kam, dass der router irgendwie diese erste URL aus der history geholt hat.
 * */

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
