import { fetchSecure } from '../shared/utils/auth'
import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { datesToStrings } from '../shared/utils/dates'
import { apiServerHashSelector } from '../selectors/selectors'
import { RequestConfig } from './interface'

export function loadPeople(): RequestConfig {
  return {
    types: [types.LOAD_PEOPLE, types.LOAD_PEOPLE_SUCCESS, types.LOAD_PEOPLE_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }) => {
      const URL = `${apiUrl}/users`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export const selectPerson = makeActionCreator(types.SELECT_PERSON, 'person')
export const selectTherapeut = makeActionCreator(types.SELECT_THERAPEUT, 'therapeut')

/*
 * nur zum Updaten Instanz-spezifischer Spalten benutzen!!!
 * andere werden beim Anmelden überschrieben und müssen über den Anmeldeserver aktualisiert werden!
 * */
export function updateInstanceUser(data): RequestConfig {
  return {
    types: [types.UPDATE_INSTANCE_USER, types.UPDATE_INSTANCE_USER_SUCCESS, types.UPDATE_INSTANCE_USER_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      const URL = `${apiUrl}/users/${id}`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(datesToStrings(other)),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function updateUser(data): RequestConfig {
  return {
    types: [types.UPDATE_USER, types.UPDATE_USER_SUCCESS, types.UPDATE_USER_FAILURE],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ authUrl, apiUrl, auth0Credentials, getState }): void => {
      const { ...other } = data // eslint-disable-line no-unused-vars
      const URL = apiServerHashSelector(getState()) ? `${apiUrl}/updateAuthUser` : `${authUrl}/updateAuthUser`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(datesToStrings(other)),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { data },
  }
}

export function getICalendarToken(userId): RequestConfig {
  return {
    types: [types.GET_ICALENDAR_TOKEN, types.GET_ICALENDAR_TOKEN_SUCCESS, types.GET_ICALENDAR_TOKEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/users/iCalendarToken/${userId}`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function resetICalendarToken(userId): RequestConfig {
  return {
    types: [types.RESET_ICALENDAR_TOKEN, types.RESET_ICALENDAR_TOKEN_SUCCESS, types.RESET_ICALENDAR_TOKEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/users/iCalendarToken/reset/${userId}`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function deleteICalendarToken(userId): RequestConfig {
  return {
    types: [types.DELETE_ICALENDAR_TOKEN, types.DELETE_ICALENDAR_TOKEN_SUCCESS, types.DELETE_ICALENDAR_TOKEN_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/users/iCalendarToken/${userId}`
      return fetchSecure(
        URL,
        {
          method: 'DELETE',
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}
