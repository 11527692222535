import { RequestConfig } from "./interface";
import * as types from "../constants/actionTypes";
import { apiServerHashSelector } from "../selectors/selectors";
import { fetchSecure } from "../shared/utils/auth";

export function getDebitoorAPI(): RequestConfig {
  return {
    types: [types.GET_DEBITOOR_API, types.GET_DEBITOOR_API_COMPLETED, types.GET_DEBITOOR_API_FAILED],
    requiresAuth: true,
    authServer: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ authUrl, apiUrl, auth0Credentials, getState }): void => {
      const URL = apiServerHashSelector(getState()) ? `${apiUrl}/kassenbuch/debitoor-url` : `${authUrl}/kassenbuch/debitoor-url`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
  }
}
