import * as R from 'ramda'
import { createSelector } from 'reselect'
import todoTypes from './todo-types'

const todos = (state) => state.entities.todos

export const selectTodosArray = createSelector(todos, (todosObj) =>
  todosObj && todosObj.length !== 0
    ? Object.values(todosObj).sort((a, b) => {
        if (a.createdAt > b.createdAt) return +1
        if (a.createdAt < b.createdAt) return -1
        return 0
      })
    : [],
)

const selectTodoTypes = () => todoTypes

// Reihenfolge der Todotypen wie in todo-types/index.js
export const selectTodoTypeNames = createSelector(selectTodoTypes, (todoTypes) =>
  Array.isArray(todoTypes) ? todoTypes.map((todoType) => todoType.name) : [],
)

export const selectTodosByType = createSelector(selectTodoTypeNames, selectTodosArray, (types, items) => {
  const result =
    !types || !items
      ? []
      : R.zipObj(
          types,
          R.map((type) => R.filter(R.propEq('type', type), items), types),
        )
  return result
})

const argumentsList = (...args) => args

export const selectStateForTodos = createSelector(
  ...todoTypes.map(R.prop('selectState')),
  R.compose(R.mergeAll, argumentsList),
)
