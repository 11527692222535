import { FC, useEffect, useState } from 'react'

let lockingCounter: number = 0

interface Props {
  lock: boolean
}

const AutoLockScrolling: FC<Props> = ({lock}) => {

  const [locked, setLocked] = useState<boolean>(false) // force to only lock/unlock once
  const [originalBodyOverflow, setOriginalBodyOverflow] = useState<string | null>(null)

  const preventScrolling = () => {
    if (locked === true) {
      return
    }
    lockingCounter = lockingCounter + 1;
    setLocked(true)

    // only lock the first time the component is mounted.
    if (lockingCounter === 1) {
      const body = document.getElementById('body')
      if(body) {
        setOriginalBodyOverflow(body.style.overflow)
        body.style.overflow = 'hidden'
      }
    }
  }

  const allowScrolling = () => {
    if (locked === true) {
      lockingCounter = lockingCounter - 1
      setLocked(false)
    }

    if (lockingCounter === 0 && originalBodyOverflow !== null) {
      const body = document.getElementById('body')
      if(body) {
        body.style.overflow = originalBodyOverflow || ''
      }
      setOriginalBodyOverflow(null)
    }
  }

  useEffect(() => {
    lock 
      ? preventScrolling()
      : allowScrolling()
    return () => {
      allowScrolling()
    }
  }, [lock])

  return null
}

export default AutoLockScrolling
