import { Lock as LockIcon } from '@material-ui/icons'
import { ChangePasswordLoggedIn } from '../ChangePasswordLoggedIn'
import { ChangePin } from '../ChangePin'
import IconHeading from '../IconHeading/IconHeading'
import { StyledCredentialsPage } from './StyledCredentialsPage'

const CredentialsPage = (): JSX.Element => (
  <StyledCredentialsPage>
    <IconHeading className="credentialsIconHeading" icon={<LockIcon />} text="Passwort/PIN ändern" />
    <div className="credentialsPaperContainer">
      <ChangePasswordLoggedIn />
      <ChangePin />
    </div>
  </StyledCredentialsPage>
)

export default CredentialsPage
