import { FC } from 'react'

interface Props {
  style?: any
  className?: string
}

const PododeskLogo: FC<Props> = ({ style, className }) => (
  <svg
    className={className}
    style={style}
    version="1.1"
    id="PododeskLogo_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 822 170.1"
    enableBackground="new 0 0 822 170.1"
  >
    <g>
      <path
        fill="#00A0CB"
        d="M295.9,123.2c-2.6,0-4.9-0.2-6.9-0.7c-2-0.5-3.8-1.1-5.3-1.9c-1.5-0.7-2.8-1.5-3.9-2.4
		c-1.1-0.9-1.9-1.7-2.6-2.4v26.4c0,0.9-0.3,1.7-1,2.4c-0.7,0.7-1.4,1-2.4,1h-9.4c-0.9,0-1.7-0.3-2.4-1c-0.7-0.7-1-1.4-1-2.4V60.8
		c0-0.9,0.3-1.7,1-2.4c0.7-0.7,1.4-1,2.4-1h9.4c0.9,0,1.7,0.3,2.4,1c0.7,0.7,1,1.4,1,2.4v2.9c0.7-0.7,1.5-1.5,2.6-2.4
		c1.1-0.9,2.4-1.7,3.9-2.4c1.5-0.7,3.3-1.4,5.3-1.9c2-0.5,4.3-0.7,6.9-0.7c4.1,0,7.9,0.8,11.2,2.4c3.3,1.6,6.2,3.8,8.6,6.4
		c2.4,2.7,4.2,5.8,5.6,9.3c1.4,3.5,2.1,7.2,2.3,11c0.1,1.2,0.1,2.7,0.1,4.3c0,1.7,0,3.1-0.1,4.3c-0.2,3.8-0.9,7.5-2.3,11
		c-1.4,3.5-3.2,6.6-5.6,9.3c-2.4,2.7-5.2,4.8-8.6,6.4C303.8,122.4,300.1,123.2,295.9,123.2z M307.5,93.2c0.2-2.3,0.2-4.6,0-6.9
		c-0.5-5.4-2.2-9.2-5-11.6c-2.8-2.4-6.2-3.5-10.3-3.5c-4.1,0-7.5,1.2-10.2,3.6c-2.8,2.4-4.4,5.8-4.8,10.3c-0.2,1.2-0.2,2.8-0.2,4.7
		c0,1.9,0.1,3.5,0.2,4.7c0.4,4.3,2,7.7,4.8,10.2c2.8,2.5,6.2,3.7,10.2,3.7c4,0,7.5-1.2,10.3-3.5C305.3,102.4,307,98.5,307.5,93.2z"
      />
      <path
        fill="#00A0CB"
        d="M366.2,56.2c4.5,0,8.7,0.7,12.3,2.1c3.7,1.4,6.9,3.3,9.5,5.8c2.7,2.5,4.8,5.4,6.4,8.7
		c1.6,3.3,2.5,6.9,2.8,10.7c0.1,0.7,0.1,1.7,0.1,2.9c0,1.2,0,2.3,0,3.5c0,1.2,0,2.3,0,3.4c0,1.1,0,2-0.1,2.8
		c-0.3,3.8-1.3,7.4-2.9,10.7c-1.6,3.3-3.7,6.2-6.4,8.6c-2.7,2.4-5.9,4.4-9.5,5.8c-3.7,1.4-7.8,2.1-12.3,2.1c-4.5,0-8.7-0.7-12.3-2.1
		c-3.7-1.4-6.9-3.3-9.5-5.8c-2.7-2.4-4.8-5.3-6.4-8.6c-1.6-3.3-2.5-6.9-2.9-10.7c-0.1-0.7-0.1-1.7-0.2-2.8c0-1.1-0.1-2.3-0.1-3.4
		c0-1.2,0-2.3,0.1-3.5c0-1.2,0.1-2.1,0.2-2.9c0.2-3.8,1.2-7.4,2.8-10.7c1.6-3.3,3.8-6.2,6.4-8.7c2.7-2.5,5.9-4.4,9.5-5.8
		C357.6,56.9,361.7,56.2,366.2,56.2z M381.2,84.1c-0.2-2.6-0.8-4.7-1.8-6.4c-1-1.7-2.1-3-3.5-4c-1.4-1-2.9-1.7-4.6-2
		c-1.7-0.4-3.3-0.6-5.1-0.6s-3.4,0.2-5.1,0.6c-1.7,0.4-3.2,1.1-4.6,2c-1.4,1-2.6,2.3-3.5,4c-1,1.7-1.5,3.8-1.8,6.4
		c-0.1,0.7-0.1,1.5-0.2,2.5c0,1-0.1,2-0.1,3.1c0,1.1,0,2.1,0.1,3.2c0,1,0.1,1.8,0.2,2.4c0.2,2.6,0.8,4.7,1.8,6.4
		c0.9,1.7,2.1,3,3.5,4c1.4,1,2.9,1.7,4.6,2c1.7,0.4,3.3,0.6,5.1,0.6s3.4-0.2,5.1-0.6c1.7-0.4,3.2-1.1,4.6-2c1.4-1,2.6-2.3,3.5-4
		c0.9-1.7,1.5-3.8,1.8-6.4c0.1-0.6,0.1-1.4,0.1-2.4c0-1,0-2.1,0-3.2c0-1.1,0-2.1,0-3.1C381.3,85.6,381.3,84.8,381.2,84.1z"
      />
      <path
        fill="#00A0CB"
        d="M436.4,56.2c2.6,0,4.9,0.2,6.9,0.7c2,0.5,3.8,1.1,5.3,1.9c1.5,0.7,2.8,1.5,3.9,2.4c1.1,0.9,1.9,1.7,2.6,2.4
		V37.3c0-0.9,0.3-1.7,1-2.4c0.7-0.7,1.4-1,2.4-1h9.4c0.9,0,1.7,0.3,2.4,1c0.7,0.7,1,1.4,1,2.4v81.3c0,0.9-0.3,1.7-1,2.4
		c-0.7,0.7-1.4,1-2.4,1h-9.4c-0.9,0-1.7-0.3-2.4-1c-0.7-0.7-1-1.4-1-2.4v-2.9c-0.7,0.7-1.5,1.5-2.6,2.4c-1.1,0.9-2.4,1.7-3.9,2.4
		c-1.5,0.7-3.3,1.4-5.3,1.9c-2,0.5-4.3,0.7-6.9,0.7c-4.1,0-7.9-0.8-11.2-2.4c-3.3-1.6-6.2-3.8-8.6-6.4c-2.4-2.7-4.2-5.8-5.6-9.3
		c-1.4-3.5-2.1-7.2-2.3-11c-0.1-1.2-0.1-2.7-0.1-4.3c0-1.7,0-3.1,0.1-4.3c0.2-3.8,0.9-7.5,2.3-11c1.4-3.5,3.2-6.6,5.6-9.3
		c2.4-2.7,5.2-4.8,8.6-6.4C428.5,57,432.2,56.2,436.4,56.2z M424.8,86.2c-0.2,2.3-0.2,4.6,0,6.9c0.5,5.4,2.1,9.2,5,11.6
		c2.8,2.4,6.2,3.5,10.3,3.5c4,0,7.5-1.2,10.2-3.6c2.8-2.4,4.4-5.8,4.8-10.3c0.2-1.2,0.2-2.8,0.2-4.7c0-1.9-0.1-3.5-0.2-4.7
		c-0.4-4.3-2-7.7-4.8-10.2c-2.8-2.5-6.2-3.7-10.2-3.7c-4,0-7.5,1.2-10.3,3.5C427,77,425.3,80.9,424.8,86.2z"
      />
      <path
        fill="#00A0CB"
        d="M516.7,56.2c4.5,0,8.7,0.7,12.3,2.1c3.7,1.4,6.9,3.3,9.5,5.8c2.7,2.5,4.8,5.4,6.4,8.7
		c1.6,3.3,2.5,6.9,2.8,10.7c0.1,0.7,0.1,1.7,0.1,2.9c0,1.2,0,2.3,0,3.5c0,1.2,0,2.3,0,3.4c0,1.1,0,2-0.1,2.8
		c-0.3,3.8-1.3,7.4-2.9,10.7c-1.6,3.3-3.7,6.2-6.4,8.6c-2.7,2.4-5.9,4.4-9.5,5.8c-3.7,1.4-7.8,2.1-12.3,2.1c-4.5,0-8.7-0.7-12.3-2.1
		c-3.7-1.4-6.9-3.3-9.5-5.8c-2.7-2.4-4.8-5.3-6.4-8.6c-1.6-3.3-2.5-6.9-2.9-10.7c-0.1-0.7-0.1-1.7-0.2-2.8c0-1.1-0.1-2.3-0.1-3.4
		c0-1.2,0-2.3,0.1-3.5c0-1.2,0.1-2.1,0.2-2.9c0.2-3.8,1.2-7.4,2.8-10.7c1.6-3.3,3.8-6.2,6.4-8.7c2.7-2.5,5.9-4.4,9.5-5.8
		C508,56.9,512.1,56.2,516.7,56.2z M531.7,84.1c-0.2-2.6-0.8-4.7-1.8-6.4c-1-1.7-2.1-3-3.5-4c-1.4-1-2.9-1.7-4.6-2
		c-1.7-0.4-3.3-0.6-5.1-0.6c-1.7,0-3.4,0.2-5.1,0.6c-1.7,0.4-3.2,1.1-4.6,2c-1.4,1-2.6,2.3-3.5,4c-1,1.7-1.5,3.8-1.8,6.4
		c-0.1,0.7-0.1,1.5-0.2,2.5c0,1-0.1,2-0.1,3.1c0,1.1,0,2.1,0.1,3.2c0,1,0.1,1.8,0.2,2.4c0.2,2.6,0.8,4.7,1.8,6.4
		c0.9,1.7,2.1,3,3.5,4c1.4,1,2.9,1.7,4.6,2c1.7,0.4,3.3,0.6,5.1,0.6c1.7,0,3.4-0.2,5.1-0.6c1.7-0.4,3.2-1.1,4.6-2
		c1.4-1,2.6-2.3,3.5-4c0.9-1.7,1.5-3.8,1.8-6.4c0.1-0.6,0.1-1.4,0.1-2.4c0-1,0-2.1,0-3.2c0-1.1,0-2.1,0-3.1
		C531.8,85.6,531.8,84.8,531.7,84.1z"
      />
      <path
        fill="#00A0CB"
        d="M586.9,56.2c5.2,0,9.5,1,12.8,2.9c3.3,1.9,6,4.2,8.1,6.9V36.6c0-1.8,0.9-2.7,2.7-2.7h2.1
		c1.8,0,2.7,0.9,2.7,2.7v82.6c0,1.8-0.9,2.7-2.7,2.7h-2.1c-1.8,0-2.7-0.9-2.7-2.7v-5.8c-2.1,2.8-4.8,5.1-8.1,7
		c-3.3,1.9-7.6,2.8-12.8,2.8c-4.9,0-9-0.9-12.3-2.7c-3.3-1.8-6-4.1-8.1-6.9c-2.1-2.9-3.6-6.1-4.6-9.6c-1-3.6-1.5-7.1-1.6-10.8
		c-0.1-2.3-0.1-4.6,0-6.9c0.1-3.6,0.6-7.2,1.6-10.8c1-3.6,2.5-6.8,4.6-9.6c2.1-2.9,4.8-5.2,8.1-6.9C577.9,57.1,582,56.2,586.9,56.2z
		 M607.7,94.2c0.1-1.2,0.1-2.9,0.1-4.8c0-2,0-3.6-0.1-4.8c-0.1-2.5-0.5-5-1.3-7.5c-0.8-2.5-2-4.8-3.7-6.8c-1.7-2-3.7-3.6-6.2-4.9
		c-2.5-1.3-5.5-1.9-8.9-1.9c-3.6,0-6.7,0.6-9.1,1.9c-2.4,1.3-4.4,3-6,5.1c-1.5,2.1-2.7,4.5-3.4,7.3c-0.7,2.7-1.2,5.6-1.2,8.6
		c-0.1,2.3-0.1,4.6,0,6.9c0.1,3,0.5,5.8,1.2,8.6c0.7,2.8,1.9,5.2,3.4,7.3c1.5,2.1,3.5,3.8,6,5c2.4,1.2,5.5,1.9,9.1,1.9
		c3.6,0,6.6-0.7,9-2c2.5-1.3,4.5-3,6.1-5.1c1.6-2.1,2.8-4.4,3.6-7C607.2,99.3,607.6,96.7,607.7,94.2z"
      />
      <path
        fill="#00A0CB"
        d="M632.2,84.7c0.3-4.2,1.2-8.1,2.5-11.6c1.4-3.5,3.2-6.5,5.5-9c2.3-2.5,5-4.4,8.2-5.8c3.2-1.4,6.9-2.1,10.9-2.1
		c8.7,0,15.4,2.8,20.2,8.5c4.8,5.7,7.2,13.5,7.2,23.5v1.9c0,0.8-0.3,1.5-0.8,2c-0.5,0.5-1.2,0.7-2,0.7h-44.1V94
		c0.1,2.8,0.6,5.5,1.5,8.2c0.9,2.6,2.2,5,3.9,7c1.7,2,3.7,3.7,6.1,4.9c2.4,1.2,5.1,1.9,8.1,1.9c2.6,0,5-0.3,6.9-1
		c2-0.7,3.7-1.4,5.1-2.3c1.4-0.9,2.5-1.8,3.4-2.7c0.9-0.9,1.5-1.6,1.8-2.1c0.7-1.1,1.3-1.7,1.7-1.9c0.4-0.2,1.1-0.3,2-0.3h2
		c0.7,0,1.4,0.2,1.9,0.7c0.5,0.5,0.8,1.1,0.7,1.8c-0.1,1.2-0.7,2.6-1.9,4.3c-1.2,1.7-2.9,3.4-5.1,5c-2.2,1.6-4.9,2.9-8,4
		c-3.1,1.1-6.7,1.7-10.5,1.7c-4,0-7.6-0.7-10.8-2c-3.2-1.4-6-3.3-8.3-5.8c-2.3-2.5-4.2-5.5-5.5-9c-1.4-3.5-2.2-7.4-2.5-11.6
		c-0.1-1.2-0.1-2.9-0.1-5C632.1,87.6,632.1,86,632.2,84.7z M639.8,85.9h39.4v-0.5c0-3.1-0.5-5.9-1.4-8.6c-0.9-2.7-2.2-5-3.9-7
		c-1.7-2-3.8-3.5-6.3-4.6c-2.5-1.1-5.2-1.7-8.3-1.7c-3.1,0-5.9,0.6-8.3,1.7c-2.4,1.1-4.4,2.7-6.1,4.6c-1.7,2-3,4.3-3.8,7
		c-0.9,2.7-1.3,5.6-1.3,8.6V85.9z"
      />
      <path
        fill="#00A0CB"
        d="M740.7,104.7c0-1.7-0.4-3.2-1.1-4.3c-0.7-1.2-1.9-2.2-3.5-3.2c-1.6-0.9-3.8-1.8-6.4-2.6
		c-2.7-0.8-6-1.6-9.9-2.5c-4-0.9-7.2-2-9.7-3.2c-2.5-1.2-4.4-2.6-5.8-4.1c-1.4-1.5-2.4-3.2-2.9-5c-0.5-1.8-0.7-3.7-0.7-5.6
		c0-2.1,0.5-4.3,1.4-6.4c0.9-2.1,2.3-4.1,4.2-5.8c1.9-1.7,4.2-3.1,6.9-4.2c2.8-1.1,6-1.6,9.7-1.6c4,0,7.5,0.5,10.4,1.6
		c2.9,1.1,5.2,2.4,7,3.8c1.8,1.5,3.2,3,4,4.5c0.9,1.5,1.3,2.7,1.4,3.7c0.1,0.8-0.2,1.5-0.7,2c-0.6,0.5-1.2,0.7-2,0.7h-1.9
		c-1.2,0-2.1-0.5-2.7-1.6c-0.7-1-1.4-1.9-2.1-2.9c-0.7-0.9-1.7-1.7-2.9-2.4c-1.2-0.7-2.6-1.3-4.3-1.7c-1.7-0.4-3.8-0.6-6.3-0.6
		c-5,0-8.6,1-11,3c-2.4,2-3.6,4.6-3.6,7.7c0,1.4,0.2,2.6,0.7,3.7c0.5,1.1,1.4,2.1,2.9,3c1.4,1,3.3,1.8,5.8,2.7
		c2.4,0.8,5.6,1.7,9.6,2.6c4,0.9,7.3,1.9,10,3.1c2.7,1.2,4.8,2.5,6.4,4c1.6,1.5,2.8,3.2,3.5,5.1c0.7,1.9,1.1,4,1.1,6.3
		c0,2.5-0.5,4.8-1.6,7.1c-1.1,2.2-2.6,4.2-4.7,5.9c-2.1,1.7-4.6,3-7.7,4c-3.1,1-6.6,1.5-10.7,1.5c-4.4,0-8.1-0.6-11.2-1.7
		c-3.1-1.2-5.6-2.5-7.6-4.1c-2-1.6-3.5-3.2-4.4-4.8c-1-1.6-1.5-2.8-1.5-3.7c-0.1-0.9,0.2-1.6,0.9-2c0.7-0.5,1.3-0.7,1.9-0.7h1.9
		c0.5,0,0.9,0.1,1.3,0.2c0.4,0.2,0.8,0.6,1.4,1.4c0.7,1.1,1.6,2.1,2.6,3.1c1,1,2.2,1.9,3.5,2.6c1.4,0.7,3,1.3,4.8,1.8
		c1.8,0.5,4,0.7,6.4,0.7c5,0,9-0.9,12.3-2.9C739.1,111.2,740.7,108.4,740.7,104.7z"
      />
      <path
        fill="#00A0CB"
        d="M776.5,86.2L811,117c1.1,1,1.6,1.8,1.6,2.5c0,1.7-0.8,2.5-2.5,2.5h-2c-1.2,0-2.1-0.1-2.7-0.3
		c-0.5-0.2-1.4-0.8-2.5-1.8l-31.1-27.6v27c0,1.8-0.9,2.7-2.7,2.7H767c-1.8,0-2.7-0.9-2.7-2.7V36.6c0-1.8,0.9-2.7,2.7-2.7h2.1
		c1.8,0,2.7,0.9,2.7,2.7v43.9l27.6-20.9c1.2-0.9,2-1.5,2.5-1.7c0.5-0.2,1.4-0.4,2.7-0.4h2.4c1.7,0,2.5,0.8,2.5,2.5
		c0,0.3-0.1,0.7-0.3,1.2c-0.2,0.5-0.6,0.9-1.3,1.3L776.5,86.2z"
      />
    </g>
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#00A0CB"
                d="M56.9,124.1l-3.6,2.9c-2.1,1.6-5.7,2.3-9.2,2.3c-1.5,0-2.9-0.1-4.2-0.3l-34.2-5.8V119l34.9,5.9
						c4.1,0.7,8.8,0,10.3-1.1l1.8-1.4l1.9-1.5c0.9-0.7,2.2-0.5,2.8,0.4C57.9,122.1,57.8,123.4,56.9,124.1z"
              />
              <path
                fill="#D6E290"
                d="M222.6,80.2v4.9l-74,58.1c-2,1.6-5.7,2.3-9.2,2.3c-1.5,0-2.9-0.1-4.2-0.3l-66.5-11.3
						c-3.8-0.7-6.2-2.1-6.7-4.2c-0.1-0.6-0.3-2.3,1.5-3.7c0.9-0.7,2.1-0.5,2.8,0.3c0.2,0.3,0.3,0.7,0.4,1c0.1,0.5-0.1,1.1-0.4,1.5
						c0.5,0.3,1.5,0.7,3.1,1l66.5,11.3c4.1,0.7,8.8,0,10.3-1.1l29.2-22.9L222.6,80.2z"
              />
              <path
                fill="#00A0CB"
                d="M56.9,111.2l-3.6,2.8c-2,1.6-5.7,2.3-9.2,2.3c-1.5,0-2.9-0.1-4.2-0.3l-34.3-5.9V106l35,6
						c4.1,0.7,8.8,0,10.3-1.1l1.8-1.5l1.8-1.4c0.9-0.7,2.2-0.5,2.8,0.3C57.9,109.2,57.8,110.5,56.9,111.2z"
              />
              <path
                fill="#D6E290"
                d="M222.6,67.1v5L166,116.8l-17.3,13.5c-2.1,1.6-5.7,2.3-9.2,2.3c-1.5,0-2.9-0.1-4.2-0.3l-45.4-7.7l-21-3.5
						c-3.8-0.7-6.2-2.2-6.7-4.2c-0.2-0.6-0.3-2.3,1.5-3.7c0.9-0.7,2.1-0.5,2.8,0.3c0.2,0.3,0.3,0.7,0.4,1c0.1,0.5-0.1,1.1-0.4,1.5
						c0.5,0.3,1.5,0.7,3.1,1l35.4,6l31,5.3c4.1,0.7,8.8,0,10.3-1.1l12.2-9.5l17-13.4L222.6,67.1z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            fill="#00A0CB"
            d="M167,49.6c0,0.4-0.3,0.8-0.8,1.2L136,74.3c0,0-0.1,0-0.1,0.1c-0.3,0.2-0.7,0.4-1.2,0.6
				c-0.1,0-0.3,0.1-0.4,0.2c-0.5,0.2-0.9,0.3-1.5,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.6,0.1-1.3,0.2-2,0.3c-0.2,0-0.4,0-0.5,0
				c-0.6,0.1-1.2,0.1-1.8,0.1c-0.1,0-0.3,0-0.4,0c-1.2,0-2.5-0.1-3.7-0.3L89.8,70l-38.2,30.4c-2.2,1.6-5.2,2.2-8.4,1.6L5.4,95.6
				l42.1-32.8l3.8-2.9l30.1-23.4c0.3-0.3,0.8-0.5,1.3-0.7c0.1-0.1,0.3-0.1,0.4-0.2c0.5-0.2,1-0.3,1.5-0.4c0.1,0,0.2-0.1,0.2-0.1
				c0.6-0.1,1.3-0.2,2-0.3c0.2,0,0.4,0,0.5,0c0.6-0.1,1.2-0.1,1.8-0.1c0.1,0,0.3,0,0.4,0c1.2,0,2.5,0.1,3.7,0.3l68.6,11.7
				c1.1,0.2,2.1,0.5,2.9,0.8c0.8,0.3,1.4,0.7,1.8,1.1C166.8,48.7,167,49.2,167,49.6z"
          />
          <path fill="#3DB076" d="M42.6,66.2" />
        </g>
      </g>
      <path fill="none" stroke="#A086BD" strokeMiterlimit={10} d="M148.2,141.3" />
      <path fill="none" stroke="#5DBCAF" strokeWidth={2.1451} strokeMiterlimit={10} d="M5.6,99.5" />
      <path fill="none" stroke="#5DBCAF" strokeWidth={2.1451} strokeMiterlimit={10} d="M117.1,118.4" />
      <path fill="none" stroke="#A086BD" strokeMiterlimit={10} d="M130.7,138.3" />
    </g>
    <path
      fill="#D6E18F"
      d="M62.4,102.7c0-0.4,0.3-0.8,0.8-1.2L93.1,78c0,0,0.1,0,0.1-0.1c0.3-0.2,0.7-0.4,1.2-0.6c0.1,0,0.3-0.1,0.4-0.2
	c0.4-0.2,0.9-0.3,1.5-0.4c0.1,0,0.2-0.1,0.2-0.1c0.6-0.1,1.3-0.2,2-0.3c0.2,0,0.4,0,0.5,0c0.6-0.1,1.2-0.1,1.8-0.1
	c0.1,0,0.3,0,0.4,0c1.2,0,2.5,0.1,3.7,0.3l34.1,5.9L176.9,52c2.2-1.6,5.2-2.2,8.3-1.6l37.6,6.4l-41.8,32.8l-3.7,2.9l-29.9,23.4
	c-0.3,0.3-0.8,0.5-1.3,0.7c-0.1,0.1-0.3,0.1-0.4,0.2c-0.4,0.2-0.9,0.3-1.5,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.1-1.3,0.2-2,0.3
	c-0.2,0-0.3,0-0.5,0c-0.6,0.1-1.2,0.1-1.8,0.1c-0.1,0-0.3,0-0.4,0c-1.2,0-2.5-0.1-3.7-0.3l-68.2-11.7c-1.1-0.2-2.1-0.5-2.8-0.8
	c-0.8-0.3-1.4-0.7-1.7-1.1C62.5,103.6,62.3,103.1,62.4,102.7z"
    />
  </svg>
)

export default PododeskLogo
