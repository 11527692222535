import * as R from 'ramda';
import { Component, createRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { connect as connectRedux } from 'react-redux';
import { bindActionCreators } from 'redux';

/* eslint no-nested-ternary:0 */

import { Tooltip } from '@material-ui/core';

import PatientText from '../../components/PatientText';
import PrettyTime from '../../components/PrettyTime';

import * as datesActions from '../../actions/dates';
import * as dialogsActions from '../../actions/dialogs';
import * as itemTypes from '../../constants/itemTypes';

import { Add as AddIcon, AvTimer as AvTimerIcon, Schedule as ScheduleIcon } from '@material-ui/icons';
import { addMinutes, differenceInMinutes, format, getHours, getMinutes, isAfter, isBefore } from 'date-fns';
import { praxisstammdatenSelector } from '../../selectors/selectors';
import {
  abgesagteTermineEntfernen,
  buildHVOTerminCountString,
  buildLeistungenString,
  buildVorbereitungenString,
  checkBedarfVorbereitung
} from '../../utils/dates';
import { mkBrTags } from '../../utils/string';
import {
  istTerminAusserhalbDerArbeitszeiten, istTerminAusserhalbDerOeffnungszeiten,
  istTerminAusserhalbDerZeiten
} from './selectors';
import { StyledCalendarDate } from './StyledCalendarDate';
import { buildHvoStringVerordnungsmengeVerbraucht } from '../../utils/heilmittelverordnungen';

interface Props {
}

interface State {
  isHovered: boolean,
  tooltipX: number,
  tooltipY: number
}

class CalendarDate extends Component<Props, State> {
  // static propTypes = {
  //   termin: PropTypes.shape({
  //     id: PropTypes.number,
  //     hausbesuch: PropTypes.bool,
  //     hvoWirdNachgereicht: PropTypes.bool,
  //     isFreeDate: PropTypes.bool,
  //     beginn: PropTypes.object,
  //     patient: PropTypes.number,
  //     ende: PropTypes.object,
  //     flavor: PropTypes.oneOf([
  //       'currentTime',
  //       'withtext',
  //       'notext',
  //       'notext_smallpadding',
  //       'fadeplus',
  //       'fadeplus_smallpadding',
  //     ]),
  //     therapeut: PropTypes.number,
  //     istOrganisatorisch: PropTypes.bool,
  //     titelOrganisatorischerTermin: PropTypes.string,
  //     notizen: PropTypes.string,
  //     leistungen: PropTypes.array,
  //   }),
  //   dates: PropTypes.object,
  //   connectDragSource: PropTypes.func,
  //   connectDropTarget: PropTypes.func,
  //   patienten: PropTypes.object,
  //   gridConfig: PropTypes.object.isRequired,
  //   isDragging: PropTypes.bool,
  //   isOver: PropTypes.bool,
  //   leistungenKK: PropTypes.object.isRequired,
  //   leistungenPrivat: PropTypes.object.isRequired,
  //   showAllFree: PropTypes.bool,
  //   onClick: PropTypes.func,
  //   users: PropTypes.object,
  //   praxisstammdaten: PropTypes.object,
  //   terminIstAusserhalbDerZeiten: PropTypes.bool,
  //   terminIstAusserhalbDerOeffnungszeiten: PropTypes.bool,
  //   terminIstAusserhalbDerArbeitszeiten: PropTypes.bool,
  //   dialogActions: PropTypes.object
  // };

  // static contextTypes = {
  //   muiTheme: PropTypes.object,
  // };

  static defaultProps = {
    flavor: 'withtext',
  };

  public tooltipPopperRef;

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      tooltipX: 0,
      tooltipY: 0
    };
    this.tooltipPopperRef = createRef();
  }

  _handleMouseMove = (event) => {
    this.setState({
      tooltipX: event.clientX,
      tooltipY: event.clientY
    });
    if (this.tooltipPopperRef.current != null) {
      this.tooltipPopperRef.current.update();
    }
  };

  _handleClick = (ev) => {
    const {
      termin: { id, beginn, ende, therapeut, room },
      onClick,
      dates,
    } = this.props;
    if (!onClick) {
      return;
    }
    let date;
    if (id) {
      date = dates[id];
    } else {
      date = { beginn, ende, therapeut, room };
    }
    onClick(ev, date);
  };

  _connectDnD = (nodes, { connectDropTarget, connectDragSource, termin: { isFreeDate } }) => {
    if (!this.props.connectDropTarget && !this.props.connectDragSource) {
      return nodes;
    }
    if (isFreeDate) {
      return connectDropTarget(nodes);
    }
    return connectDragSource(nodes);
  };

  _getCalendarDateEntry = (entry) => {
    return entry? `${entry} ` : '';
  }

  render() {
    const {
      termin: {
        id: terminId,
        beginn,
        patient: patientId,
        ende,
        notizen,
        flavor,
        istOrganisatorisch,
        titelOrganisatorischerTermin,
        leistungen,
        hausbesuch,
        hvoWirdNachgereicht,
        absagegrund,
        therapeut: therapeutId,
        room: roomId,
        heilmittelverordnung,
      },
      patienten,
      gridConfig: { firstHour, gridRowMinutes, gridRowHeight },
      isDragging,
      isOver,
      roomView,
      showAllFree,
      leistungenKK,
      leistungenPrivat,
      users,
      rooms,
      praxisstammdaten,
      terminIstAusserhalbDerZeiten,
      terminIstAusserhalbDerOeffnungszeiten,
      terminIstAusserhalbDerArbeitszeiten,
      heilmittelverordnungen,
      termine,
    } = this.props;

    const patient = patienten[patientId];

    const vorbereitungsnotizen = leistungen
      ? buildVorbereitungenString(leistungen, leistungenKK, leistungenPrivat)
      : '';
    const vorbereitungsbedarf = leistungen
      ? checkBedarfVorbereitung(leistungen, leistungenKK, leistungenPrivat)
      : false;

    const therapeut = users[therapeutId];
    const room = roomId ? rooms[roomId] : null;

    const beginHours = getHours(beginn);
    const beginMinutes = getMinutes(beginn);
    const endHours = getHours(ende);
    const endMinutes = getMinutes(ende);
    const duration = differenceInMinutes(ende, beginn);

    const rowsPerHour = 60 / gridRowMinutes;
    const pixelsPerHour = rowsPerHour * gridRowHeight;
    const pixelsPerMinute = gridRowHeight / gridRowMinutes;

    const topPixels = (beginHours - firstHour) * pixelsPerHour + beginMinutes * pixelsPerMinute;
    const bottomPixels = (endHours - firstHour) * pixelsPerHour + endMinutes * pixelsPerMinute;
    const height = bottomPixels - topPixels;

    let standardBehandlungsdauer;
    if (praxisstammdaten) {
      standardBehandlungsdauer = praxisstammdaten.standardBehandlungsdauer;
    }

    const blockDuration = height / pixelsPerMinute;
    const innerBottomHeight = (standardBehandlungsdauer - blockDuration) * pixelsPerMinute;

    const computedStyles = {
      height: height,
      top: topPixels,
    };

    const hvoText = hvoWirdNachgereicht ? 'HVO fehlt! ' : '';
    const hausbesuchIcon = hausbesuch ? '\u2302 ' : '';

    const hvoTerminCountText = !!heilmittelverordnung && !!heilmittelverordnungen[heilmittelverordnung] && !!termine ? buildHVOTerminCountString(heilmittelverordnungen[heilmittelverordnung], termine, terminId, leistungenKK) : ''

    let smallPadding = false;

    let result: JSX.Element | null = null;

    switch (flavor) {
      default:
      case 'withtext':
        result = (
          <StyledCalendarDate
            istOrganisatorisch={istOrganisatorisch}
            hausbesuch={hausbesuch}
            vorbereitungsbedarf={vorbereitungsbedarf}
            absagegrund={absagegrund}
            beginn={beginn}
            terminIstAusserhalbDerZeiten={terminIstAusserhalbDerZeiten}
            terminIstAusserhalbDerOeffnungszeiten={terminIstAusserhalbDerOeffnungszeiten}
            terminIstAusserhalbDerArbeitszeiten={terminIstAusserhalbDerArbeitszeiten}
            computedStyles={computedStyles}
          >
              <Tooltip
                enterDelay={200}
                placement="top"
                title={
                  <span>
                    {istOrganisatorisch ? (
                      <span>
                        {titelOrganisatorischerTermin}&nbsp;
                        {notizen ? (
                          <span>
                            <br />{mkBrTags(notizen)}
                          </span>
                        ) : null}
                      </span>
                    ) : (
                      <PatientText patient={patient} />
                    )}
                  </span>
                }
                PopperProps={{
                  style: { marginBottom: -10 },
                  popperRef: this.tooltipPopperRef,
                  anchorEl: {
                    getBoundingClientRect: () => ({
                      top: this.state.tooltipY, //this.tooltipContainerRef.current.getBoundingClientRect().top,
                      left: this.state.tooltipX,
                      right: this.state.tooltipX,
                      bottom: this.state.tooltipY, //this.tooltipContainerRef.current.getBoundingClientRect().bottom,
                      width: 1,
                      height: 1,
                    }),
                    clientHeight: 1,
                    clientWidth: 1
                  },
                }}
              >
              <div
                onMouseMove={this._handleMouseMove}
                className={`calendarDateRoot ${isDragging ? "isDragging" : ""}`}
                key="root">
                {this._connectDnD(
                  // <div className={`calendarDateInner ${duration <= 25 ? 'shortTermin' : ''}`} onClick={this._handleClick} key="inner">
                  <div className={`calendarDateInner`} onClick={this._handleClick} key="inner">
                    <div className="calendarDateName">
                      <PrettyTime time={beginn} className="calendarDateBeginn" />
                        {istOrganisatorisch ? (
                          <span>{titelOrganisatorischerTermin}</span>
                        ) : (
                          <PatientText
                            className='patientName'
                            patient={patient}
                            noShowAppointment={absagegrund === 'ohne Absage nicht erschienen'}
                            hvoTerminCountText={hvoTerminCountText}
                          />
                        )}
                    </div>
                    {!!roomView && <div className="calendarDateDetails">Therapeut: {therapeut.vorname} {therapeut.nachname}</div>}
                    {!!room && !roomView && <div className="calendarDateDetails">Raum: {room.abbreviation}</div>}
                    {vorbereitungsbedarf && vorbereitungsnotizen && (
                      <div className="calendarDateDetails">{`Vorbereitungen: ${vorbereitungsnotizen}`}</div>
                    )}
                    <div className="calendarDateDetails">
                      {this._getCalendarDateEntry(hausbesuchIcon)}
                      {this._getCalendarDateEntry(hvoText)}
                      {buildLeistungenString(leistungen, leistungenKK, leistungenPrivat)}
                    </div>
                    <span>{mkBrTags(notizen)}</span>
                  </div>,
                  this.props,
                )}
              </div>
            </Tooltip>
          </StyledCalendarDate>
        );
        break;

      case 'notext_smallpadding':
        smallPadding = true;
      // falls through
      case 'notext':
        result = (
          <StyledCalendarDate
            istOrganisatorisch={istOrganisatorisch}
            hausbesuch={hausbesuch}
            vorbereitungsbedarf={vorbereitungsbedarf}
            absagegrund={absagegrund}
            beginn={beginn}
            terminIstAusserhalbDerZeiten={terminIstAusserhalbDerZeiten}
            terminIstAusserhalbDerOeffnungszeiten={terminIstAusserhalbDerOeffnungszeiten}
            terminIstAusserhalbDerArbeitszeiten={terminIstAusserhalbDerArbeitszeiten}
            showAllFree={showAllFree}
            computedStyles={computedStyles}
          >
            <div className={`calendarDateRoot ${smallPadding ? 'smallPadding' : ''} ${isDragging ? "isDragging" : ""}`} key="root">
              {this._connectDnD(
                <div className="calendarDateInner" onClick={this._handleClick} key="inner" />,
                this.props,
              )}
            </div>
          </StyledCalendarDate>
        );
        break;

      case 'fadeplus_smallpadding':
        smallPadding = true;
      // falls through
      case 'fadeplus':
        result = (
          <StyledCalendarDate
            istOrganisatorisch={istOrganisatorisch}
            hausbesuch={hausbesuch}
            vorbereitungsbedarf={vorbereitungsbedarf}
            absagegrund={absagegrund}
            beginn={beginn}
            terminIstAusserhalbDerZeiten={terminIstAusserhalbDerZeiten}
            terminIstAusserhalbDerOeffnungszeiten={terminIstAusserhalbDerOeffnungszeiten}
            terminIstAusserhalbDerArbeitszeiten={terminIstAusserhalbDerArbeitszeiten}
            showAllFree={showAllFree}
            computedStyles={computedStyles}
            innerBottomHeight={innerBottomHeight}
          >
          <Tooltip
            enterDelay={200}
            placement="top"
            title={
              <span style={{ fontSize: 14 }}>
                <ScheduleIcon style={{ color: '#fff', verticalAlign: 'middle' }} fontSize="inherit" />&nbsp;
                {format(beginn, 'HH:mm')}&nbsp;
                <AvTimerIcon style={{ color: '#fff', verticalAlign: 'middle' }} fontSize="inherit" />&nbsp;
                {format(addMinutes(beginn, standardBehandlungsdauer), 'HH:mm')}&nbsp;
                {`${therapeut ? `${therapeut.vorname} ` : ''}${therapeut ? `${therapeut.nachname.slice(0, 1)}.` : ''}`}
              </span>
            }
            PopperProps={{
              style: { marginBottom: -10 },
              popperRef: this.tooltipPopperRef,
              anchorEl: {
                getBoundingClientRect: () => ({
                  top: this.state.tooltipY, //this.tooltipContainerRef.current.getBoundingClientRect().top,
                  left: this.state.tooltipX,
                  right: this.state.tooltipX,
                  bottom: this.state.tooltipY, //this.tooltipContainerRef.current.getBoundingClientRect().bottom,
                  width: 1,
                  height: 1,
                }),
                clientHeight: 1,
                clientWidth: 1
              },
            }}
            >
            <div
              onMouseMove={this._handleMouseMove}
              className={`calendarDateRoot available ${smallPadding ? 'smallPadding' : ''} ${isDragging ? "isDragging" : ""} ${showAllFree ? "showAllFree" : ""}`}
              onMouseEnter={() => this.setState({ isHovered: true })}
              onMouseLeave={() => this.setState({ isHovered: false })}
              onClick={this._handleClick}>
              {this._connectDnD(
                <div className={`calendarDateInnerAvailable ${isOver? 'isOver': ''}`} key="inner">
                  <div
                    style={{
                      transition: 'none',
                      opacity: showAllFree ? (isOver ? 1 : 0) : 1,
                      margin: 'auto',
                      fontSize: '12px',
                    }}
                  >
                    <AddIcon fontSize="small" className={`${isOver ? 'isOver' : ''} ${showAllFree ? "showAllFree" : ''}`}/>
                  </div>
                </div>,
                this.props,
              )}
              <div
                className={`calendarDateInnerAvailable extended ${isOver? 'isOver': ''}`}
                style={{ opacity: this.state.isHovered ? 1 : 0 }}
                key="innerBottom">
              </div>
            </div>
          </Tooltip>
          </StyledCalendarDate>
        );
        break;

      case 'currentTime':
        result = (
          <StyledCalendarDate computedStyles={computedStyles}>
            <div className="calendarDateCurrentTime" key="root" />
          </StyledCalendarDate>
        );
        break;
    }

    return result;
  }
}

const dateSource = {
  beginDrag(props) {
    return {
      id: props.termin.id,
    };
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }

    const draggedItem = monitor.getItem();
    const dropResult = monitor.getDropResult();

    const date = props.dates[draggedItem.id];

    const termineArr = Object.keys(props.dates).map((key) => props.dates[key]);
    const termineOhneAbgesagte = abgesagteTermineEntfernen(termineArr);

    const nextDate = getSubsequentDate(dropResult, termineOhneAbgesagte);
    const currentDuration = differenceInMinutes(date.ende, date.beginn);

    const needToShortenDuration =
      nextDate && nextDate.id !== date.id && isBefore(nextDate.beginn, addMinutes(dropResult.beginn, currentDuration));

    if (needToShortenDuration) {
      const newDuration = Math.abs(differenceInMinutes(nextDate.beginn, dropResult.beginn));
      props.dialogActions?.openMoveDateDialog({
        date,
        target: {
          ...dropResult,
          ende: addMinutes(dropResult.beginn, newDuration),
        },
        shorterDuration: true
      } as MoveDate);
    } else {
      props.dialogActions?.openMoveDateDialog({
        date,
        target: {
          ...dropResult,
          ende: addMinutes(dropResult.beginn, currentDuration),
        },
        shorterDuration: false
      } as MoveDate);
    }
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const dateTarget = {
  drop(props, monitor) {
    return {
      beginn: props.termin.beginn,
      ende: props.termin.ende,
      therapeut: props.termin.therapeut,
      room: props.termin.room,
    };
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    showAllFree: monitor.canDrop(),
  };
}

/*
 * reselect memoization funktioniert bei Komponenten, die öfter als 1x instanziert werden nur,
 * wenn jede Instanz ihre eigenen Selektoren hat.
 * mit createMapStateToProps wird von react-redux für jede Instanz eine mapStateToProps Funktion erzeugt.
 * */
function createMapStateToProps() {
  const sIstTerminAusserhalbDerOeffnungszeiten = istTerminAusserhalbDerOeffnungszeiten();
  const sIstTerminAusserhalbDerArbeitszeiten = istTerminAusserhalbDerArbeitszeiten();
  const sIstTerminAusserhalbDerZeiten = istTerminAusserhalbDerZeiten(
    sIstTerminAusserhalbDerOeffnungszeiten,
    sIstTerminAusserhalbDerArbeitszeiten,
  );
  return (state, props) => ({
    dates: state.entities.termine,
    patienten: state.entities.patienten,
    leistungenKK: state.leistungenKK,
    leistungenPrivat: state.entities.leistungenPrivat,
    users: state.entities.users,
    rooms: state.entities.rooms,
    termine: state.entities.termine,
    heilmittelverordnungen: state.entities.heilmittelverordnungen,
    praxisstammdaten: praxisstammdatenSelector(state),
    terminIstAusserhalbDerOeffnungszeiten: sIstTerminAusserhalbDerOeffnungszeiten(state, props),
    terminIstAusserhalbDerArbeitszeiten: sIstTerminAusserhalbDerArbeitszeiten(state, props),
    terminIstAusserhalbDerZeiten: sIstTerminAusserhalbDerZeiten(state, props),
  });
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dates: bindActionCreators(datesActions, dispatch),
    dialogs: bindActionCreators(dialogsActions, dispatch),
  }
})

export default R.pipe(
  DragSource(itemTypes.DATE, dateSource, collectSource),
  DropTarget(itemTypes.DATE, dateTarget, collectTarget),
  connectRedux(createMapStateToProps, mapDispatchToProps),
)(CalendarDate);

export const CalendarDateNoDND = R.pipe(connectRedux(createMapStateToProps, mapDispatchToProps))(CalendarDate);

export function getSubsequentDate(date, dates) {
  const filtered = dates.filter((dt) => dt.therapeut === date.therapeut && isAfter(dt.beginn, date.beginn));

  const result = filtered.length
    ? filtered.reduce((previousValue, currentValue, currentIndex, array) => {
        return isBefore(currentValue.beginn, previousValue.beginn) ? currentValue : previousValue;
      })
    : false;

  if (result && isAfter(result.beginn, date.beginn)) {
    return result;
  }
  return false;
}
