export interface IQuestionCountItem {
  name: string
  unanswered: number
  answered: number
}

export interface IQuestionCount {
  [key: string]: IQuestionCountItem
}

export interface IAnswer {
  answered: number
  unanswered: number
}

export const anamneseQuestions = {
  ik: {
    name: 'ik',
    children: {
      ikHepatitis: { name: 'ikHepatitis' },
      ikHIV: { name: 'ikHIV' },
      ikMRSA: { name: 'ikMRSA' },
    },
  },
  dfDiabetiker: {
    name: 'dfDiabetiker',
    children: {
      dfTyp: { name: 'dfTyp' },
      dfDiabetikerSeitWann: { name: 'dfDiabetikerSeitWann' },
      dfInsulin: { name: 'dfInsulin' },
      dfZuckertabletten: { name: 'dfZuckertabletten' },
      dfHba1cBekannt: {
        name: 'dfHba1cBekannt',
        children: {
          dfHba1cWert: { name: 'dfHba1cWert' },
          dfHba1cDatum: { name: 'dfHba1cDatum' },
        },
      },
      dfDiabetischePolyneuropathie: {
        name: 'dfDiabetischePolyneuropathie',
        children: {
          dfBeschwerden: { name: 'dfBeschwerden' },
        },
      },
      dfDiabetischeRetinopathie: { name: 'dfDiabetischeRetinopathie' },
      dfDiabetischeNephropathie: { name: 'dfDiabetischeNephropathie' },
    },
  },
  dsBekanntOderAnzeichen: {
    name: 'dsBekanntOderAnzeichen',
    children: {
      dsPAVK: { name: 'dsPAVK' },
      dsAndere: { name: 'dsAndere' },
      dsBeschwerden: { name: 'dsBeschwerden' },
    },
  },
  kk: {
    name: 'kk',
    children: {
      kkKrampfadern: { name: 'kkKrampfadern' },
      kkKompressionsstruempfe: { name: 'kkKompressionsstruempfe' },
      kkOedeme: { name: 'kkOedeme' },
    },
  },
  kmaKrankheitenMedikamente: {
    name: 'kmaKrankheitenMedikamente',
    children: {
      kmaBluter: {
        name: 'kmaBluter',
        children: {
          kmaBluterMedikament: { name: 'kmaBluterMedikament' },
        },
      },
      kmaEingeschraenktesImmunsystemChemo: { name: 'kmaEingeschraenktesImmunsystemChemo' },
      kmaPsoriasis: { name: 'kmaPsoriasis' },
      kmaNeurodermitis: { name: 'kmaNeurodermitis' },
      kmaAthritisArthroseRheumaGichtOsteoporose: { name: 'kmaAthritisArthroseRheumaGichtOsteoporose' },
      kmaBluthochdruck: { name: 'kmaBluthochdruck' },
      kmaHerzerkrankung: { name: 'kmaHerzerkrankung' },
      kmaSonstigeErkrankungen: { name: 'kmaSonstigeErkrankungen' },
      kmaSonstigeMedikamente: { name: 'kmaSonstigeMedikamente' },
    },
  },
  kmaAllergien: { name: 'kmaAllergien' },
  seEinlagen: {
    name: 'seEinlagen',
    children: {
      seEinlagenArt: { name: 'seEinlagenArt' },
      seEinlagenAngefertigtAm: { name: 'seEinlagenAngefertigtAm' },
    },
  },
  seSchuhe: {
    name: 'seSchuhe',
    children: {
      seSchuheArt: { name: 'seSchuheArt' },
    },
  },
  nsErhoben: {
    name: 'nsErhoben',
    children: {
      nsMallMedRechts: { name: 'nsMallMedRechts' },
      nsMallMedLinks: { name: 'nsMallMedLinks' },
      nsHalluxRechts: { name: 'nsHalluxRechts' },
      nsHalluxLinks: { name: 'nsHalluxLinks' },
      nsApexRechts: { name: 'nsApexRechts' },
      nsApexLinks: { name: 'nsApexLinks' },
      nsWaermeKaelteEmpfinden: { name: 'nsWaermeKaelteEmpfinden' },
      nsMonofilament: { name: 'nsMonofilament' },
    },
  },
  deformitaeten: {
    name: 'deformitaeten',
    children: {
      deformitaetenHalluxValgus: { name: 'deformitaetenHalluxValgus' },
      deformitaetenSenkspreizfuss: { name: 'deformitaetenSenkspreizfuss' },
      deformitaetenDigitusMalleus: { name: 'deformitaetenDigitusMalleus' },
      deformitaetenDigitusFlexus: { name: 'deformitaetenDigitusFlexus' },
    },
  },
  hnAuffaelligkeiten: {
    name: 'hnAuffaelligkeiten',
    children: {
      hnAuffaelligkeitenUnguisConvolutus: { name: 'hnAuffaelligkeitenUnguisConvolutus' },
      hnAuffaelligkeitenUnguisIncarnatus: { name: 'hnAuffaelligkeitenUnguisIncarnatus' },
      hnAuffaelligkeitenWeitere: { name: 'hnAuffaelligkeitenWeitere' },
    },
  },
  hnBisherigePflegeUndPilzmittel: { name: 'hnBisherigePflegeUndPilzmittel' },
}

export const anamneseQuestionsMap = {
  '01': anamneseQuestions.ik,
  '02': anamneseQuestions.dfDiabetiker,
  '03': anamneseQuestions.dsBekanntOderAnzeichen,
  '04': anamneseQuestions.kk,
  '05': {
    a: anamneseQuestions.kmaKrankheitenMedikamente,
    b: anamneseQuestions.kmaAllergien,
  },
  '06': {
    a: anamneseQuestions.seEinlagen,
    b: anamneseQuestions.seSchuhe,
  },
  '07': anamneseQuestions.nsErhoben,
  '08': anamneseQuestions.deformitaeten,
  '09': {
    a: anamneseQuestions.hnAuffaelligkeiten,
    b: anamneseQuestions.hnBisherigePflegeUndPilzmittel,
  },
}

export const sidebarListItems = [
  {
    key: '01',
    name: 'Infektionskrankheiten', // Infectious diseases
  },
  {
    key: '02',
    name: 'Diabetes & Folgeerkrankungen', // Diabetes & secondary diseases
  },
  {
    key: '03',
    name: 'Durchblutungsstörungen', // Circulatory disorders
  },
  {
    key: '04',
    name: 'Krampfadern & Kompressionsstrümpfe', // Varicose veins & compression stockings
  },
  {
    key: '05',
    name: 'Krankheiten, Medikamente & Allergien', // Diseases, Medicines & Allergies
  },
  {
    key: '06',
    name: 'Schuhe & Einlagen', // Shoes & insoles
  },
  {
    key: '07',
    name: 'Neurologischer Status', // Neurological status
  },
  {
    key: '08',
    name: 'Deformitäten', // Deformities
  },
  {
    key: '09',
    name: 'Haut & Nagelbefund', // Skin & nail findings
  },
]

export const baseObject = {
  ik: null,
  ikHepatitis: null,
  ikHIV: null,
  ikMRSA: null,
  ikKommentar: null,

  dfDiabetiker: null,
  dfTyp: null,
  dfDiabetikerSeitWann: null,
  dfInsulin: null,
  dfZuckertabletten: null,
  dfHba1cBekannt: null,
  dfHba1cWert: null,
  dfHba1cDatum: null,
  dfDiabetischePolyneuropathie: null,
  dfBeschwerden: null,
  dfDiabetischeRetinopathie: null,
  dfDiabetischeNephropathie: null,
  dfKommentar: null,
  dsBekanntOderAnzeichen: null,
  dsPAVK: null,
  dsAndere: null,
  dsBeschwerden: null,
  dsKommentar: null,

  kk: null,
  kkKrampfadern: null,
  kkKompressionsstruempfe: null,
  kkOedeme: null,
  kkKommentar: null,

  kmaKrankheitenMedikamente: null,
  kmaBluter: null,
  kmaBluterMedikament: null,
  kmaEingeschraenktesImmunsystemChemo: null,
  kmaPsoriasis: null,
  kmaNeurodermitis: null,
  kmaAthritisArthroseRheumaGichtOsteoporose: null,
  kmaBluthochdruck: null,
  kmaHerzerkrankung: null,
  kmaSonstigeErkrankungen: null,
  kmaSonstigeMedikamente: null,
  kmaKrankheitenMedikamenteKommentar: null,
  kmaAllergien: null,
  kmaAllergienKommentar: null,

  seEinlagen: null,
  seEinlagenArt: null,
  seEinlagenAngefertigtAm: null,
  seEinlagenKommentar: null,
  seSchuhe: null,
  seSchuheArt: null,
  seSchuheKommentar: null,

  nsErhoben: null,
  nsMallMedRechts: null,
  nsMallMedLinks: null,
  nsHalluxRechts: null,
  nsHalluxLinks: null,
  nsApexRechts: null,
  nsApexLinks: null,
  nsWaermeKaelteEmpfinden: null,
  nsMonofilament: null,
  nsKommentar: null,

  deformitaeten: null,
  deformitaetenHalluxValgus: null,
  deformitaetenSenkspreizfuss: null,
  deformitaetenDigitusMalleus: null,
  deformitaetenDigitusFlexus: null,
  deformitaetenKommentar: null,

  hnAuffaelligkeiten: null,
  hnAuffaelligkeitenUnguisConvolutus: null,
  hnAuffaelligkeitenUnguisIncarnatus: null,
  hnAuffaelligkeitenWeitere: null,
  hnAuffaelligkeitenKommentar: null,
  hnBisherigePflegeUndPilzmittel: null,
  hnBisherigePflegeUndPilzmittelKommentar: null,

  patient: null,
  modifiedBy: null,
  abgeschlossen: null,
}

export const fields = [
  // AF01
  'ik',
  'ikHepatitis',
  'ikHIV',
  'ikMRSA',
  'ikKommentar',
  // AF02
  'dfDiabetiker',
  'dfTyp',
  'dfDiabetikerSeitWann',
  'dfInsulin',
  'dfZuckertabletten',
  'dfHba1cBekannt',
  'dfHba1cWert',
  'dfHba1cDatum',
  'dfDiabetischePolyneuropathie',
  'dfBeschwerden',
  'dfDiabetischeRetinopathie',
  'dfDiabetischeNephropathie',
  'dfKommentar',
  // AF03
  'dsBekanntOderAnzeichen',
  'dsPAVK',
  'dsAndere',
  'dsBeschwerden',
  'dsKommentar',
  // AF04
  'kk',
  'kkKrampfadern',
  'kkKompressionsstruempfe',
  'kkOedeme',
  'kkKommentar',
  // AF05
  'kmaKrankheitenMedikamente',
  'kmaBluter',
  'kmaBluterMedikament',
  'kmaEingeschraenktesImmunsystemChemo',
  'kmaPsoriasis',
  'kmaNeurodermitis',
  'kmaAthritisArthroseRheumaGichtOsteoporose',
  'kmaBluthochdruck',
  'kmaHerzerkrankung',
  'kmaSonstigeErkrankungen',
  'kmaSonstigeMedikamente',
  'kmaKrankheitenMedikamenteKommentar',
  'kmaAllergien',
  'kmaAllergienKommentar',
  // AF06
  'seEinlagen',
  'seEinlagenArt',
  'seEinlagenAngefertigtAm',
  'seEinlagenKommentar',
  'seSchuhe',
  'seSchuheArt',
  'seSchuheKommentar',
  // AF07
  'nsErhoben',
  'nsMallMedLinks',
  'nsMallMedRechts',
  'nsHalluxLinks',
  'nsHalluxRechts',
  'nsApexLinks',
  'nsApexRechts',
  'nsWaermeKaelteEmpfinden',
  'nsMonofilament',
  'nsKommentar',
  // AF08
  'deformitaeten',
  'deformitaetenHalluxValgus',
  'deformitaetenSenkspreizfuss',
  'deformitaetenDigitusMalleus',
  'deformitaetenDigitusFlexus',
  'deformitaetenKommentar',
  // AF09
  'hnAuffaelligkeiten',
  'hnAuffaelligkeitenUnguisConvolutus',
  'hnAuffaelligkeitenUnguisIncarnatus',
  'hnAuffaelligkeitenWeitere',
  'hnAuffaelligkeitenKommentar',
  'hnBisherigePflegeUndPilzmittel',
  'hnBisherigePflegeUndPilzmittelKommentar',

  'patient',
  'abgeschlossen',
  'id',
]

export const HNBListeLokalisierungen = [
  { lokalisierung: 'apex 1', kuerzel: 'A1', erklaerung: 'Zehenspitze' },
  { lokalisierung: 'apex 2', kuerzel: 'A2', erklaerung: 'Zehenspitze' },
  { lokalisierung: 'apex 3', kuerzel: 'A3', erklaerung: 'Zehenspitze' },
  { lokalisierung: 'apex 4', kuerzel: 'A4', erklaerung: 'Zehenspitze' },
  { lokalisierung: 'apex 5', kuerzel: 'A5', erklaerung: 'Zehenspitze' },
  { lokalisierung: 'digitus 1 plantar', kuerzel: 'D1P', erklaerung: 'Zeh 1 (hallux) unten' },
  { lokalisierung: 'digitus 1 dorsal', kuerzel: 'D1D', erklaerung: 'Zeh 1 (hallux) oben' },
  { lokalisierung: 'digitus 1 lateral', kuerzel: 'D1L', erklaerung: 'Zeh 1 (hallux) außen' },
  { lokalisierung: 'digitus 1 medial', kuerzel: 'D1M', erklaerung: 'Zeh 1 (hallux) innen' },
  { lokalisierung: 'digitus 2 plantar', kuerzel: 'D2P', erklaerung: 'Zeh 2 unten' },
  { lokalisierung: 'digitus 2 dorsal', kuerzel: 'D2D', erklaerung: 'Zeh 2 oben' },
  { lokalisierung: 'digitus 2 lateral', kuerzel: 'D2L', erklaerung: 'Zeh 2 außen' },
  { lokalisierung: 'digitus 2 medial', kuerzel: 'D2M', erklaerung: 'Zeh 2 innen' },
  { lokalisierung: 'digitus 3 plantar', kuerzel: 'D3P', erklaerung: 'Zeh 3 unten' },
  { lokalisierung: 'digitus 3 dorsal', kuerzel: 'D3D', erklaerung: 'Zeh 3 oben' },
  { lokalisierung: 'digitus 3 lateral', kuerzel: 'D3L', erklaerung: 'Zeh 3 außen' },
  { lokalisierung: 'digitus 3 medial', kuerzel: 'D3M', erklaerung: 'Zeh 3 innen' },
  { lokalisierung: 'digitus 4 plantar', kuerzel: 'D4P', erklaerung: 'Zeh 4 unten' },
  { lokalisierung: 'digitus 4 dorsal', kuerzel: 'D4D', erklaerung: 'Zeh 4 oben' },
  { lokalisierung: 'digitus 4 lateral', kuerzel: 'D4L', erklaerung: 'Zeh 4 außen' },
  { lokalisierung: 'digitus 4 medial', kuerzel: 'D4M', erklaerung: 'Zeh 4 innen' },
  { lokalisierung: 'digitus 5 plantar', kuerzel: 'D5P', erklaerung: 'Zeh 5 (digitus minimus) unten' },
  { lokalisierung: 'digitus 5 dorsal', kuerzel: 'D5D', erklaerung: 'Zeh 5 (digitus minimus) oben' },
  { lokalisierung: 'digitus 5 lateral', kuerzel: 'D5L', erklaerung: 'Zeh 5 (digitus minimus) außen' },
  { lokalisierung: 'digitus 5 medial', kuerzel: 'D5M', erklaerung: 'Zeh 5 (digitus minimus) innen' },
  { lokalisierung: 'interdigital 1/2', kuerzel: 'I12', erklaerung: 'Zehenzwischenraum' },
  { lokalisierung: 'interdigital 2/3', kuerzel: 'I23', erklaerung: 'Zehenzwischenraum' },
  { lokalisierung: 'interdigital 3/4', kuerzel: 'I34', erklaerung: 'Zehenzwischenraum' },
  { lokalisierung: 'interdigital 4/5', kuerzel: 'I45', erklaerung: 'Zehenzwischenraum' },
  { lokalisierung: 'interdigital 1/5', kuerzel: 'I15', erklaerung: 'alle Zehenzwischenräume' },
  { lokalisierung: 'MFK 1', kuerzel: 'M1', erklaerung: 'Mittelfußköpfchen' },
  { lokalisierung: 'MFK 1 medial', kuerzel: 'M1M', erklaerung: 'Mittelfußköpfchen 1 innen' },
  { lokalisierung: 'MFK 2', kuerzel: 'M2', erklaerung: 'Mittelfußköpfchen' },
  { lokalisierung: 'MFK 3', kuerzel: 'M3', erklaerung: 'Mittelfußköpfchen' },
  { lokalisierung: 'MFK 4', kuerzel: 'M4', erklaerung: 'Mittelfußköpfchen' },
  { lokalisierung: 'MFK 5', kuerzel: 'M5', erklaerung: 'Mittelfußköpfchen' },
  { lokalisierung: 'MFK 5 lateral', kuerzel: 'M5L', erklaerung: 'Mittelfußköpfchen 5 außen' },
  { lokalisierung: 'MT 5 lateral', kuerzel: 'MT5L', erklaerung: 'Metatarsalknochen außen' },
  { lokalisierung: 'Fußgewölbe', kuerzel: 'F', erklaerung: 'mitte und außen' },
  { lokalisierung: 'calcaneus plantar', kuerzel: 'CP', erklaerung: 'Ferse unten' },
  { lokalisierung: 'calcaneus dorsal', kuerzel: 'CD', erklaerung: 'Ferse oben' },
  { lokalisierung: 'calcaneus lateral', kuerzel: 'CL', erklaerung: 'Ferse außen' },
  { lokalisierung: 'calcaneus medial', kuerzel: 'CM', erklaerung: 'Ferse innen' },
];

export const HNBListeAuffaelligkeiten = [
  'schuppige/trockene Haut',
  'Hyperkeratose (Hornhaut)',
  'Clavus (Hühnerauge)',
  'Rhagade (Schrunden)',
  'Verdacht auf Mykose',
  'Mykose (Pilz)',
  'Verrucae (Warze)',
  'Läsion (Wunde)',
];
