/*
“This software (code) is copyright by Ingenico Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied, 
including without limitation, any warranties of merchantability or fitness for a particular purpose. 
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Ingenico Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen. 
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Ingenico-Healthcare GmbH
 *
 *  description:                  Sate machine for VSD reading.
 *
 *  history:
 *  *.2021 tpe                    created
 */

import { CardReader_VSD } from "./card_reader_VSD";
import { T1_host_if, TerminalData/*, printBufferHex*/ } from "./t1_host_interface";
import { Sad } from "./t1_host_interface";
import { Dad } from "./t1_host_interface";
import {T1_Return_Code} from "./t1_host_interface";
import { Care_commands } from "./care_commands";
import { CardReader_KVK } from "./card_reader_KVK";
import { CardReader_eGK } from "./card_reader_eGK";

// define stm states
export enum Read_VSD_state {
  start,                // initial state
  request_icc,          // request_icc() was called.
  request_icc_ready,    // request_icc() was successful.
  read_VSD,             // read VSD was requested
  read_VSD_done,        // read VSD was done
  eject_icc,            // eject_icc() was called
  eject_icc_ready       // eject_icc() was successful
}

// define stm states
export enum Card_Type { eGK, KVK, NA }

enum CardRequestState_Type { success, timeout, cancel, already_done, error }

// Function returns true if both arrays a and b are equal.
const equals = (a: Uint8Array, b: Uint8Array) =>
  a.length === b.length &&
  a.every((v, i) => v === b[i]);

// define statemachine 
export class Read_VSD_stm {

  private state: Read_VSD_state = Read_VSD_state.start;  // The actual state
  private cardReader_VSD: CardReader_VSD;
  private t1_host_if: T1_host_if;

  private dad: Dad; // destination address of 'integrated circuit card' (ICC)
  private sad: Sad; // source address of host

  private cardType: Card_Type;

  constructor (cardReader_VSD: CardReader_VSD, t1_host_if: T1_host_if, dad: Dad, sad: Sad) {
    this.cardReader_VSD = cardReader_VSD;
    this.t1_host_if = t1_host_if;
    this.state = Read_VSD_state.start;
    this.dad = dad;
    this.sad = sad;
    this.cardType = Card_Type.NA;
    console.log("Read_VSD_stm created.");
  }
  
  // define stm operations
  public async request_icc(): Promise<void> {
    if(this.state === Read_VSD_state.start)
    {
      this.state = Read_VSD_state.request_icc;
       
      const seconds = 60;
      
      for(let i = 0; i <= seconds; i = i + 1) {

        if ( i === seconds) {
          throw new Error("request_icc_timeout " + this.request_icc.name);
        }
      
        const [rxData, return_code] = await this.t1_host_if.send_async(
          new TerminalData(Dad.ct, this.sad, Care_commands.request_icc(new Uint8Array([0x01]))), 
          "request_icc"
        );

        let state = CardRequestState_Type.timeout;

        state = this.evaluate_response_from_request_icc(rxData, return_code);

        if (state !== CardRequestState_Type.timeout)
          break;

      }

      this.state = Read_VSD_state.request_icc_ready;
      
    }else{
      console.error(this.request_icc.name +" state != start")
      throw new Error("read_VSD_wrong_state " + this.request_icc.name);
    }
  }

  public async read_Data(): Promise<string> {

    let xmlText = "";

    if(this.state === Read_VSD_state.request_icc_ready)
    {
      // change state
      this.state = Read_VSD_state.read_VSD;

      switch (this.cardType) {

        case Card_Type.KVK: {

          const cardReader_KVK = new CardReader_KVK(this.dad, this.t1_host_if);

          xmlText = await cardReader_KVK.read_KVK();

          break;
        }

        case Card_Type.eGK: {

          const cardReader_eGK = new CardReader_eGK(this.dad, this.t1_host_if);

          xmlText = await cardReader_eGK.read_eGK();

          break;
        }

        default:
          break;

      }

      this.state = Read_VSD_state.read_VSD_done;

    }else{
      console.error(this.read_Data.name +" state != masterfile_selected")
      throw new Error("read_VSD_KVK_wrong_state " + this.read_Data.name);
    }
    
    return xmlText;
  }

  public async eject_icc(): Promise<void> {

    if(this.state === Read_VSD_state.read_VSD_done) {

      this.state = Read_VSD_state.eject_icc;

      const seconds = 60;
      
      for(let i = 0; i <= seconds; i = i + 1) {

        if ( i === seconds) {
          throw new Error("request_icc_timeout " + this.request_icc.name);
        }
      
        // send command eject_icc with 60s waiting time, 60 -> 0x3C
        const [rxData, return_code] = await this.t1_host_if.send_async(
          new TerminalData(Dad.ct, this.sad, Care_commands.eject_icc(new Uint8Array([0x01]))), 
          "eject_icc"
        );

        let state = CardRequestState_Type.timeout;

        state = this.evaluate_response_from_eject_icc(rxData, return_code);

        if (state !== CardRequestState_Type.timeout)
          break;

      }

      this.state = Read_VSD_state.eject_icc_ready;
      
    }else{
      console.error(this.eject_icc.name +" state != start")
      throw new Error("read_VSD_wrong_state " + this.eject_icc.name);
    }
  }

  evaluate_response_from_request_icc(rxData: Uint8Array, return_code: number): CardRequestState_Type {
    console.log("evaluate_response_from_request_icc()");

    if(return_code !== T1_Return_Code.OK){
      console.error("request_icc return_code=", return_code);
      throw new Error("t1_send_return_code " + this.evaluate_response_from_request_icc.name);
    }else{/*no error from t1*/}

    const expected_len = 2;
    if(rxData.length < expected_len)
    {
      console.error("request_icc rxData.length=", rxData.length);
      throw new Error("t1_response_length " + this.evaluate_response_from_request_icc.name);
    }else{/*no length error*/}
    
    const expected_response_KVK = new Uint8Array([0x90, 0x00]);
    const expected_response_eGK = new Uint8Array([0x90, 0x01]);
    const timeout_response = new Uint8Array([0x62, 0x00]);        // The card was not inserted within time or the bluetooth adapter did not transmit the message.
    const cancel_response = new Uint8Array([0x64, 0x01]);         // Cancel key pressed on care
    const already_done_response = new Uint8Array([0x62, 0x01]);   // Card already activated

    if(   equals(expected_response_KVK, rxData) ){
      this.cardType = Card_Type.KVK;
      return CardRequestState_Type.success;
    }
    else if(   equals(expected_response_eGK, rxData) ){
      this.cardType = Card_Type.eGK;
      return CardRequestState_Type.success;
    }
    else if(equals(rxData, timeout_response)){
      return CardRequestState_Type.timeout;
    }
    else if(equals(rxData, cancel_response)){
      return CardRequestState_Type.cancel;
    }
    else if(equals(rxData, already_done_response)){
      return CardRequestState_Type.already_done;
    }
    else{
      console.error("Error: Received response from request_icc was not the expected one.");
      throw new Error("t1_unknown_response_code " + this.evaluate_response_from_request_icc.name);
    }
  }

  evaluate_response_from_eject_icc(rxData: Uint8Array, return_code: number): CardRequestState_Type {
    console.log("evaluate_response_from_eject_icc()");

    if(return_code !== T1_Return_Code.OK){
      console.error("eject_icc return_code=", return_code);
      throw new Error("t1_send_return_code " + this.evaluate_response_from_eject_icc.name);
    }else{/*no error from t1*/}

    const expected_len = 2;
    if(rxData.length < expected_len)
    {
      console.error("eject_icc rxData.length=", rxData.length);
      throw new Error("t1_response_length " + this.evaluate_response_from_eject_icc.name);
    }else{/*no length error*/}
    
    const expected_response = new Uint8Array([0x90, 0x01]); 
    const timeout_response = new Uint8Array([0x62, 0x00]);
    const low_battery_response = new Uint8Array([0x69, 0x00])

    if (equals(expected_response, rxData) || equals(low_battery_response, rxData)){
      return CardRequestState_Type.success;
    }else if(equals(rxData, timeout_response)){
      return CardRequestState_Type.timeout;
    }
    else{
      console.error("Error: Received response from eject_icc was not the expected one.");
      throw new Error("t1_unknown_response_code " + this.evaluate_response_from_eject_icc.name);
    }
  }

}
 