import { Button, Paper } from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as abbreviationsActions from '../../actions/abbreviations'
import ControlledInputField from '../../shared/components/InputField/ControlledInputField'
import { getErrorMessage } from '../../shared/utils/errorMessages'
import {
  AbbreviationSettingsFormButtonContainer,
  AbbreviationSettingsFormContainer,
  AbbreviationSettingsFormHeader,
  AbbreviationSettingsFormInner,
} from './StyledAbbreviationSettings'

interface FormData {
  abbreviation: string
  fullText: string
}

const defaultValues = {
  abbreviation: '',
  fullText: '',
}

interface Props {
  actions: any
  isOpen: boolean
  selectedAbbreviation: number | null
  abbreviations: any
  onCancelChanges: () => void
}

const AbbreviationSettingsForm = ({
  actions,
  isOpen = false,
  selectedAbbreviation,
  abbreviations = {},
  onCancelChanges,
}: Props): JSX.Element => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (isOpen) {
      if (!selectedAbbreviation) {
        resetForm(defaultValues)
      } else {
        const abbreviationsArray = Object.keys(abbreviations).map((key) => abbreviations[key])
        const selectedAbbreviationData = abbreviationsArray.find((e) => e.id === selectedAbbreviation)
        resetForm(selectedAbbreviationData)
      }
    }
  }, [isOpen, selectedAbbreviation, abbreviations])

  const onSubmit = async (data: FormData) => {
    console.log('data submitted: ', data)

    if (!!selectedAbbreviation) {
      await actions.abbreviations.updateAbbreviation(data)
    } else {
      await actions.abbreviations.createAbbreviation(data)
    }
  }

  const handlePreventSpace = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ' ') e.preventDefault()
  }

  const abbreviation = watch('abbreviation')

  return (
    <AbbreviationSettingsFormContainer elevation={2} square isOpen={isOpen}>
      <AbbreviationSettingsFormHeader>{abbreviation || 'Neuer Textbaustein'}</AbbreviationSettingsFormHeader>
      <AbbreviationSettingsFormInner autoComplete="off" lang="de" onSubmit={handleSubmit(onSubmit)}>
        <ControlledInputField
          fullWidth
          control={control}
          name="abbreviation"
          label="Abkürzung"
          onKeyDown={handlePreventSpace}
          rules={{
            required: getErrorMessage('required'),
            validate: {
              isUnique: (value) => {
                let result: string | true = true
                const abbreviationsArray = Object.keys(abbreviations).map((key) => abbreviations[key])

                console.log('abbreviationsArray: ', abbreviationsArray)
                console.log('value: ', value)

                if (value !== '') {
                  if (/\s/.test(value)) {
                    // String contains whitespace
                    result = 'Das Kürzel darf keine Leerzeichen enthalten'
                  }

                  if (selectedAbbreviation) {
                    // If selectedAbbreviation is not null, we need to filter out the selected on to check against all others
                    if (
                      abbreviationsArray
                        .filter((abbr) => abbr.id !== selectedAbbreviation)
                        .some((abbr) => RegExp(`^${value}`).test(abbr.abbreviation))
                    ) {
                      result = 'Es gibt bereits ein Kürzel, das mit dieser Zeichenfolge beginnt'
                    } else {
                      abbreviationsArray
                        .filter((abbr) => abbr.id !== selectedAbbreviation)
                        .forEach((abbr) => {
                          if (RegExp(`^${abbr.abbreviation}`).test(value)) {
                            result = 'Der Beginn dieses Kürzels ist bereits als eigenes Kürzel gespeichert'
                          }
                        })
                    }
                  }

                  if (!selectedAbbreviation) {
                    if (abbreviationsArray.some((abbr) => RegExp(`^${value}`).test(abbr.abbreviation))) {
                      result = 'Es gibt bereits ein Kürzel, das mit dieser Zeichenfolge beginnt'
                    } else {
                      abbreviationsArray.forEach((abbr) => {
                        if (RegExp(`^${abbr.abbreviation}`).test(value)) {
                          result = 'Der Beginn dieses Kürzels ist bereits als eigenes Kürzel gespeichert'
                        }
                      })
                    }
                  }
                }

                if (value !== '' && /\s/.test(value)) {
                  result = 'Das Kürzel darf keine Leerzeichen enthalten'
                }

                if (result === true && value !== '' && selectedAbbreviation) {
                  result = abbreviationsArray
                    .filter((abbr) => abbr.id !== selectedAbbreviation)
                    .some((abbr) => RegExp(`^${value}`).test(abbr.abbreviation))
                    ? 'Es gibt bereits ein Kürzel, das mit dieser Zeichenfolge beginnt'
                    : true
                }

                if (result === true && value !== '' && !selectedAbbreviation) {
                  abbreviationsArray.some((abbr) => RegExp(`^${value}`).test(abbr.abbreviation))
                    ? 'Es gibt bereits ein Kürzel, das mit dieser Zeichenfolge beginnt'
                    : true
                }

                return result
              },
            },
          }}
          error={!!errors?.abbreviation}
          helperText={errors?.abbreviation?.message}
        />
        <ControlledInputField
          fullWidth
          control={control}
          name="fullText"
          label="Textbaustein"
          rules={{ required: getErrorMessage('required') }}
          error={!!errors?.fullText}
          helperText={errors?.fullText?.message}
        />
        <AbbreviationSettingsFormButtonContainer>
          <Button variant="contained" color="secondary" type="submit" disabled={!isDirty}>
            Speichern
          </Button>
          {!!selectedAbbreviation && isDirty && (
            <Button variant="contained" type="button" onClick={onCancelChanges}>
              Änderungen verwerfen
            </Button>
          )}
        </AbbreviationSettingsFormButtonContainer>
      </AbbreviationSettingsFormInner>
    </AbbreviationSettingsFormContainer>
  )
}

const mapStateToProps = (state) => ({
  abbreviations: state.entities.abbreviations,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    abbreviations: bindActionCreators(abbreviationsActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AbbreviationSettingsForm)
