import { TableContainer } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCustomersListView = styled(TableContainer)`
  ${({ theme }) => `
    .tableHead {
      background-color: ${theme.palette.primary.dark};
      text-transform: uppercase;
    }

    .nextArrow {
      transform: rotate(180deg);
    }

    .appointmentSpan {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .customersTableRow {
      cursor: pointer;
    }

    .bodyTableCell {
      border-bottom: solid 1px ${theme.palette.highlight.main};
      border-right: solid 1px ${theme.palette.highlight.main};
    }
  `}
`;
