import { FC, useState } from 'react';
import { PhotoshopPicker } from 'react-color';
import { StyledColorField } from './StyledColorField';

interface Props {
  value?: any,
  initialValue?: any
  onChange?: (e?) => void
  labelText?: string
}

const ColorField: FC<Props> = ({value, initialValue, onChange, labelText}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [color, setColor] = useState<any>(value || initialValue);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
    onChange && onChange(color.hex);
  };

  return (
    <StyledColorField color={color}>
      <span className="labelText">{labelText}</span>
      <div className="swatch" onClick={handleClick}>
        <div className="swatchContent" />
      </div>
      {displayColorPicker && (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <PhotoshopPicker color={color} onChange={handleChange} />
        </div>
      )}
    </StyledColorField>
  );
};

export default ColorField;
