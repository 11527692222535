import MenuItemsArzt from './MenuItemsArzt'
import { StyledMenuArzt } from './StyledMenuArzt'

const ContextMenuArzt = ({ 
  pointerLocation: { mouseX, mouseY }, 
  handleClose, 
  doctorId 
}) => {

  return (
    <StyledMenuArzt
      keepMounted
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
      <MenuItemsArzt 
        doctorId={doctorId} 
        onCloseFunc={handleClose} />
    </StyledMenuArzt>
  )
}

export default ContextMenuArzt
