/* cross tab communication logic */
import { APP_INITIALIZE } from '../../constants/actionTypes'
import { sApiServerName } from '../../shared/utils/auth'
import { DOWNGRADE_CREDENTIALS, REMOVE_AUTHENTICATION } from '../modules/auth'
import { CROSS_TAB_ACTION, sendCrossTabAction, startReceivingLocalStorageEvents } from '../modules/crossTab'
import { LOCK_SCREEN, UNLOCK_SCREEN } from '../modules/lockscreen'

export default ({ dispatch, getState }) => (next) => (action) => {
  switch (action.type) {
    case APP_INITIALIZE:
      dispatch(startReceivingLocalStorageEvents())
      break
    default:
  }

  next(action)

  switch (action.type) {
    case LOCK_SCREEN:
      sendCrossTabAction({
        type: DOWNGRADE_CREDENTIALS,
        apiServerName: sApiServerName(getState()),
      })
      sendCrossTabAction({
        type: LOCK_SCREEN,
        apiServerName: sApiServerName(getState()),
      })
      break

    case UNLOCK_SCREEN:
      sendCrossTabAction({
        type: UNLOCK_SCREEN,
        apiServerName: sApiServerName(getState()),
      })
      break

    case REMOVE_AUTHENTICATION:
      !action.crosstab &&
        sendCrossTabAction({
          type: REMOVE_AUTHENTICATION,
        })
      break

    /*
     * actions sent from other tabs, use this sparingly !
     * */
    case CROSS_TAB_ACTION:
      // should be handled by invalidating credentials on server
      break

        //* LOCKSCREEN WAS NOT WORKING PROPERLY YET, SO THIS WAS COMMENTED OUT

    // switch (action.action.type) {
    //   /*
    //    * downgrade credentials in other practices
    //    * */
    //   case DOWNGRADE_CREDENTIALS:
    //     if (action.action.apiServerName !== sApiServerName(getState())) {
    //       dispatch(downgradeCredentials())
    //     }
    //     break

    //   case LOCK_SCREEN:
    //     if (action.action.apiServerName === sApiServerName(getState())) {
    //       dispatch(restoreLock(getState()))
    //     }
    //     break

    //   case UNLOCK_SCREEN:
    //     if (action.action.apiServerName === sApiServerName(getState())) {
    //       dispatch(restoreLock(getState()))
    //     }
    //     break

    //   case REMOVE_AUTHENTICATION:
    //     // dispatch({
    //     //   type: REMOVE_AUTHENTICATION,
    //     //   crosstab: true
    //     // })
    //     break

    //   default:
    // }
    // break

    default:
  }
}
