import { FC } from 'react'
import { StyledTableBody } from './StyledTableBody'

interface Props {
  style?: any, // override component styles
  className?: string,
  rows?: Array<any>,
}

const TableBody: FC<Props> = ({
  style = {}, className = '', rows = [], children
}) => {
  return (
    <StyledTableBody className={className} style={style}>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((col, columnIndex) => (
            <td key={columnIndex} style={col?.style || {}} className={col?.className || ''}>
              {col?.data}
            </td>
          ))}
        </tr>
      ))}
      {children}
    </StyledTableBody>
  )
}

export default TableBody
