/*
“This software (code) is copyright by Worldline Healthcare GmbH a Worldline brand and provided "as is".
In case of open or demo (sdk) code it is not for further sale.
It is subject to change without notice and with no warranties, whether expressed or implied,
including without limitation, any warranties of merchantability or fitness for a particular purpose.
All risks concerning the results and performance of this Software are assumed by the user.”

„Diese Software (Code) unterliegt dem Urheberrecht der Worldline Healthcare GmbH, einer Marke von Worldline,
und wird "wie besehen" zur Verfügung gestellt.
Im Falle von offenem oder Demo-Code (SDK) ist dieser nicht für den weiteren Verkauf vorgesehen.
Die Inhalte können ohne vorherige Ankündigung und ohne ausdrückliche oder stillschweigende Garantien geändert werden,
einschließlich, aber nicht beschränkt auf Garantien der Marktgängigkeit oder Eignung für einen bestimmten Zweck.
Alle Risiken in Bezug auf die Ergebnisse und die Leistung dieser Software werden vom Benutzer übernommen.“
*/

/*
 * Copyright (C) Worldline Healthcare GmbH
 *
 *  description:                  Command APDUs for the KVK.
 *
 *  history:
 *  *.2021 tpe                    created
 */

export class KVK_commands {
  // Select file with AID
  public static select_masterfile(): Uint8Array {
    return new Uint8Array([0x00, 0xa4, 0x04, 0x00, 0x06, 0xd2, 0x76, 0x00, 0x00, 0x01, 0x01])
  }

  // Read patient data
  public static read_Binary(): Uint8Array {
    return new Uint8Array([0x00, 0xb0, 0x00, 0x00, 0xff])
  }
}
