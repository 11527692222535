import styled from 'styled-components';
import { grey } from '@material-ui/core/colors'

export const StyledFileUpload = styled.div`
  ${({ theme }) => `
    min-width: 100px;
    margin: 10px 0;
    width: 100%;
    border: 1px dashed ${theme.palette.primary.dark};

    .fileUploadInput {
      display: none;
    }

    .divider {
      background-color: ${theme.palette.secondary.main};
      margin: 0 16px;
    }

    .uploadListItem {
      min-height: 70px;
      color: ${theme.palette.text.primary};
      cursor: pointer;

      &:hover {
        background-color: ${grey[200]};

        .addRemoveIcon {
          &.add {
            color: ${grey[200]};
          }
        }
      }
    }

    .uploadListItemError {
      color: rgb(244, 67, 54);
    }

    .uploadedListItem {
      cursor: pointer;
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &:hover {
        background-color: ${grey[200]};

        .addRemoveIcon {
          .remove {
            color: ${grey[200]};
          }
        }
      }
    }

    .uploadedPreview {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
    }

    .addRemoveIcon {
      border-radius: 50%;
      color: #fff;
      margin-left: auto;

      &.add {
        background-color: ${theme.palette.secondary.dark};
      }

      &.remove {
        background-color: ${theme.palette.secondary.main};
      }
    }

    .linearProgress {
      margin-top: 5px;
      width: 100%;
      border-radius: 4px;
    }
  `}
`;

export const StyledFileUploadMessage = styled.span`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `}
`;
