import Numeral from 'numeral'
import { DEFAULT_CURRENCY_FORMAT } from '../constants/currencyFormats'

export function buildLeistungPrivatString(leistungPrivat, currencySymbol) {
  const n = Numeral(leistungPrivat.verguetung / 100)
  const verguetungString = n.format(DEFAULT_CURRENCY_FORMAT)
  return `${leistungPrivat.kuerzel} | ${leistungPrivat.beschreibung} | ${verguetungString}${currencySymbol}`
}

export function sortByAbbreviation(a, b) {
  if (a.kuerzel < b.kuerzel) { // kuerzel - type number
    return -1
  }
  if (a.kuerzel > b.kuerzel) {
    return 1
  }
  return 0
}
