import { Button, ListItem, ListItemText, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { RoomSettingsSidebarList, SidebarContainer } from './StyledRoomSettings'

interface Props {
  actions: any
  onAddNewClick: () => void
  onExistingClick: (roomId: number) => void
  selectedRoom: number | null
  rooms: any
}

const floorTexts = {
  0: 'EG',
  1: '1. OG',
  2: '2. OG',
  3: '3. OG',
  4: '4. OG',
  5: '5. OG',
  6: '6. OG',
  7: '7. OG',
  8: '8. OG',
  9: '9. OG',
  10: '10. OG',
}

const buildSecondaryText = (room) => {
  if ((room?.floor === 0 || room?.floor > 0) && !!room?.equipment?.length) {
    return `${floorTexts[room.floor]} | ${room.equipment.length}x Ausstattung`
  }

  if ((room?.floor === 0 || room?.floor > 0) && !room?.equipment?.length) {
    return `${floorTexts[room.floor]} | Ausstattung fehlt`
  }

  if (!room?.floor && !!room?.equipment?.length) {
    return `${room.equipment.length}x Ausstattung`
  }

  return 'Ausstattung fehlt'
}

const RoomSettingsSidebar = ({ actions, onAddNewClick, onExistingClick, selectedRoom, rooms }: Props): JSX.Element => {
  const roomsArray = useMemo(() => {
    if (!rooms) return []

    return Object.keys(rooms).map((key) => rooms[key])
  }, [rooms])

  return (
    <SidebarContainer square elevation={2}>
      <Button color="secondary" variant="contained" style={{ marginBottom: 12 }} onClick={onAddNewClick} fullWidth>
        Neuen Raum hinzufügen
      </Button>
      <RoomSettingsSidebarList
        dense
        subheader={
          <Typography variant="h6" className="equipmentListHeader">
            aktive Behandlungsräume
          </Typography>
        }
      >
        {roomsArray.map(
          (room) =>
            !room.disabled && (
              <ListItem
                onClick={() => onExistingClick(room.id)}
                key={room.id}
                className={selectedRoom === room.id ? 'listItemActive' : 'listItem'}
              >
                <ListItemText
                  primary={`${room.abbreviation} | ${room.name}`}
                  secondary={buildSecondaryText(room)}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            ),
        )}
      </RoomSettingsSidebarList>
      <RoomSettingsSidebarList
        dense
        subheader={
          <Typography variant="h6" className="equipmentListHeader">
            deaktivierte Behandlungsräume
          </Typography>
        }
      >
        {roomsArray.map(
          (room) =>
            room.disabled && (
              <ListItem
                onClick={() => onExistingClick(room.id)}
                key={room.id}
                className={selectedRoom === room.id ? 'listItemActive' : 'listItem'}
              >
                <ListItemText primary={`${room.abbreviation} | ${room.name}`} secondary={buildSecondaryText(room)} />
              </ListItem>
            ),
        )}
      </RoomSettingsSidebarList>
    </SidebarContainer>
  )
}

const mapStateToProps = (state) => ({
  rooms: state.entities.rooms,
})

export default connect(mapStateToProps)(RoomSettingsSidebar)
