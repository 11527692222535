import * as types from '../constants/actionTypes'

export default function selectedTherapeut(state = 0, action = undefined) {
  switch (action.type) {
    case types.SELECT_THERAPEUT:
      if (action.therapeut) {
        return parseInt(action.therapeut, 10)
      }
      break
    default:
  }

  return state
}
