import styled from 'styled-components';

export const StyledViewHVO = styled.div`
  ${({ theme }) => `
    flex-grow: 1;

    .MuiInputBase-root.Mui-disabled {
      color: #000;
    }
    .MuiFormLabel-root.Mui-disabled {
      color: #5f6367;
    }

    .root {
      flex-grow: 1;
    }

    .rightColumn {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 100%;
      max-width: 700px;
    }

    .leftColumn {
      border-right: 1px solid ${theme.palette.primary.main};
      display: flex;
      flex-direction: column;
    }

    .narrowTextField {
      width: 50%;
    }

    .col {
      padding: '0 15px';
    }

    .toggle{
      padding: 10px;
    }

    .paperForm {
      margin: 20px;
      background: unset;
    }

    .line {
      margin-bottom: 20px;
    }

    .inline-fields {
      display: flex;
      flex-direction: row;
      gap: 15px;
      width: 100%;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }
  `}
`;
