import { createSelector } from 'reselect'
import * as R from 'ramda'
import { createSelectorN } from '../../utils/functions'

const sZahlungspositionen = (state) => state.entities.zahlungspositionen

const sTerminId = (_, props) => {

  if (!props.selectedTermin) {
    return null;
  }

  //TODO, check if selectedTermin can be an object and not ID at some cases. If not, always return selectedTermin
  return typeof props.selectedTermin === "number"? props.selectedTermin : props.selectedTermin.id
}

export const sZahlungenZuTermin = createSelectorN(
  sTerminId,
  sZahlungspositionen,
  R.compose(R.filter, R.propEq('termin')),
)

export const sTerminHatZahlung = createSelector(sZahlungenZuTermin, R.compose(R.not, R.isEmpty))
