import { addWeeks, format, isSameDay } from 'date-fns'
import { Fragment, memo, useCallback, useEffect } from 'react'
import { DragDropContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'

import * as datesActions from '../../actions/dates'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { sAllDayGapHeight, sDatesByRoom, sDatesByTherapist, sGridConfigDay, sTermineGeladenTag } from '../Calendar/selectors'
import CalendarColumn from '../CalendarColumn'
import CalendarColumnHeader from '../CalendarColumnHeader/CalendarColumnHeader'
import CalendarColumnSpacer from '../CalendarColumnSpacer/CalendarColumnSpacer'
import DataLoading from '../DataLoading/DataLoading'
import { StyledCalendarRoomsView } from './StyledCalendarRoomsView'

const CalendarRoomsView = ({
                           actions,
                           gridConfig,
                           onDateClick,
                           onFreeDateClick,
                           selectedDay,
                           termineGeladen,
                           allDayGapHeight,
                           datesByTherapist,
                           datesByRoom,
                           onIcsItemClick,
                           dialogActions,
                           selectedTerminProps,
                           notification
                         }) => {
  const navigate = useNavigate();
  const {serverHash} = useParams()

  const loadDates = (selectedDay) => {
    actions.loadDates({
      startDate: selectedDay,
      endDate: selectedDay,
    })
  }

  useEffect(() => {
    loadDates(selectedDay)
  }, [selectedDay])

  useEffect(() => {
    if (typeof window !== 'undefined') actions.locationChange(window.location.pathname)
  }, [])

  const today = new Date()
  const plus1w = selectedDay ? addWeeks(selectedDay, 1) : addWeeks(today, 1)
  const plus4w = selectedDay ? addWeeks(selectedDay, 4) : addWeeks(today, 4)
  const plus6w = selectedDay ? addWeeks(selectedDay, 6) : addWeeks(today, 6)

  const handleTapBarClick = useCallback((date) => {
    actions.selectDay(date)
    navigate(`/${serverHash}/calendar/rooms/${format(date, ISO8601DATE_FNS)}`)
  }, [serverHash])

  const isSnackbarOpen = !!notification?.message;

  return (
    <StyledCalendarRoomsView isSnackbarOpen={isSnackbarOpen}>
      <div className="dayViewTopBar">
        <StyledLink
          onClickFunc={() => {
            handleTapBarClick(today)
          }}
          fullWidth
          borderEnd
        >
          Heute
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            handleTapBarClick(plus1w)
          }}
          fullWidth
          borderEnd
        >
          +1 Woche
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            handleTapBarClick(plus4w)
          }}
          fullWidth
          borderEnd
        >
          +4 Wochen
        </StyledLink>
        <StyledLink
          onClickFunc={() => {
            handleTapBarClick(plus6w)
          }}
          fullWidth
        >
          +6 Wochen
        </StyledLink>
      </div>
      <div className="mainContainer">
        <div className="dayViewMain">
          <CalendarColumnSpacer flavor="timesBig" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}>
            <CalendarColumnHeader flavor="date" date={selectedDay}/>
          </CalendarColumnSpacer>
          <CalendarColumnSpacer flavor="line" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}/>
          {termineGeladen ? (
            datesByRoom.map(({room, dates}, index) => (
              <Fragment key={index}>
                <CalendarColumn
                  gridConfig={gridConfig}
                  locked={false}
                  columnDay={selectedDay}
                  onDateClick={onDateClick}
                  onFreeDateClick={onFreeDateClick}
                  room={room}
                  markCurrentTime={isSameDay(selectedDay, new Date())}
                  allDayGapHeight={allDayGapHeight}
                  dates={dates}
                  onIcsItemClick={onIcsItemClick}
                  dialogActions={dialogActions}
                >
                  <CalendarColumnHeader flavor="room" room={room} date={selectedDay}/>
                </CalendarColumn>
                {index < datesByRoom.length - 1 && (
                  <CalendarColumnSpacer flavor="times" gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}/>
                )}
              </Fragment>
            ))
          ) : (
            <DataLoading text="lade Termine..."/>
          )}
        </div>
      </div>
    </StyledCalendarRoomsView>
  )
}

const mapStateToProps = (state) => ({
  gridConfig: sGridConfigDay(state),
  datesByTherapist: sDatesByTherapist(state),
  datesByRoom: sDatesByRoom(state),
  customers: state.entities.patienten,
  selectedDay: state.selectedDay,
  termineGeladen: sTermineGeladenTag(state),
  allDayGapHeight: sAllDayGapHeight(state),
  selectedTerminProps: state.selectedTerminProps,
  notification: state.notification,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(datesActions, dispatch),
})

const ConnectedCalendarRoomsView = connect(mapStateToProps, mapDispatchToProps)(CalendarRoomsView)

export default DragDropContext(HTML5Backend)(memo(ConnectedCalendarRoomsView))
