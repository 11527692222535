import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core'
import { Delete as DeleteIcon, ShoppingCart as ShoppingCartIcon } from '@material-ui/icons'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as leistungenPrivatActions from '../../actions/leistungenPrivat'
import CurrencyField from '../../components/CurrencyField/CurrencyField'
import { MultipleAutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import InputField from '../../shared/components/InputField'
import { sortByAbbreviation } from '../../utils/leistungenPrivat'
import { StyledLeistungenPrivatForm } from './StyledLeistungenPrivatForm'
import { praxisstammdatenSelector } from '../../selectors/selectors'

export const fields = [
  'id',
  'kuerzel',
  'beschreibung',
  'regelbehandlungszeitMin',
  'regelbehandlungszeitMax',
  'vorbereitungsbedarf',
  'vorbereitungsnotiz',
  'mehrwertsteuer',
  'verguetung',
  'deleted',
  'equipment',
]

const initValues = {
  kuerzel: '',
  beschreibung: '',
  regelbehandlungszeitMin: '',
  regelbehandlungszeitMax: '',
  vorbereitungsbedarf: false,
  vorbereitungsnotiz: '',
  mehrwertsteuer: 19,
  verguetung: 0,
  deleted: false,
  equipment: [],
}

const LeistungenPrivatForm = ({
  actions,
  leistungenPrivat,
  leistungenPrivatIds,
  equipment,
  handleSubmit,
  fields,
  initializeForm,
  praxisstammdaten,
}) => {
  const params = useParams()

  const loadLeistungen = async () => {
    await actions.leistungenPrivat.loadLeistungenPrivat()
    if (params.leistungPrivat) {
      initializeForm(leistungenPrivat[params.leistungPrivat])
    }
  }

  useEffect(() => {
    loadLeistungen()
  }, [params])

  const handleDeleteLeistungPrivat = useCallback(
    (id) => () => {
      actions.leistungenPrivat.deleteLeistungPrivat(id)
      console.log(`Leistung ${id} wurde gelöscht!`)
    },
    [actions.leistungenPrivat.deleteLeistungPrivat],
  )

  const handleInitializeFormwithLeistungPrivat = useCallback(
    (id) => () => {
      initializeForm(leistungenPrivat[id])
    },
    [initializeForm, leistungenPrivat],
  )

  const onSubmit = useCallback(
    async (data) => {
      console.log('submitting', data)

      if (data.id) {
        await actions.leistungenPrivat.updateLeistungPrivat(data)
      } else {
        await actions.leistungenPrivat.createLeistungPrivat(data)
      }

      initializeForm(initValues)
    },
    [actions.leistungenPrivat.updateLeistungPrivat, actions.leistungenPrivat.createLeistungPrivat, initializeForm],
  )

  const {
    kuerzel,
    beschreibung,
    regelbehandlungszeitMin,
    regelbehandlungszeitMax,
    vorbereitungsbedarf,
    vorbereitungsnotiz,
    mehrwertsteuer,
    verguetung,
  } = fields

  const mwstInput = useRef<HTMLInputElement>()

  const equipmentArray: Array<AutocompleteOption> = Object.keys(equipment)
    .map((key) => equipment[key])
    .map((e) => ({
      text: `${e.abbreviation} | ${e.name}`,
      value: e.id,
      unselectable: false,
    }))

  return (
    <>
      <IconHeading icon={<ShoppingCartIcon />} text="Privatleistungen der Praxis" />
      <StyledLeistungenPrivatForm>
        <div className="listContainer">
          <Paper square elevation={2}>
            <List
              subheader={
                <Typography className="listHeader" variant="h6">
                  <div>Angebotene Leistungen</div>
                </Typography>
              }
            >
              <ListSubheader>Können beliebigen Terminen zugeordnet werden</ListSubheader>
              {leistungenPrivatIds
                .filter((key) => leistungenPrivat[key] && !leistungenPrivat[key].deleted)
                .sort(sortByAbbreviation)
                .map((key) => (
                  <ListItem button onClick={handleInitializeFormwithLeistungPrivat(key)} key={key}>
                    <ListItemText
                      primary={
                        leistungenPrivat[key] &&
                        `${leistungenPrivat[key].kuerzel} | ${leistungenPrivat[key].beschreibung}`
                      }
                      secondary={
                        leistungenPrivat[key] &&
                        (leistungenPrivat[key].verguetung / 100).toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + (praxisstammdaten.currency === 'EUR' ? ' €' : ' CHF')
                      }
                    />
                    <ListItemIcon>
                      <IconButton onClick={handleDeleteLeistungPrivat(key)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
            </List>
          </Paper>
          <Paper square elevation={2}>
            <List
              subheader={
                <Typography className="listHeader" variant="h6">
                  <div>Gelöschte/ausgeblendete Leistungen</div>
                </Typography>
              }
            >
              <ListSubheader>
                Stehen für Zuordnung zu Terminen nicht mehr zur Auswahl (zum Reaktivieren bitte{' '}
                <a onClick={() => window['fcWidget']?.open()}>Support</a> kontaktieren)
              </ListSubheader>
              {leistungenPrivatIds
                .filter((key) => leistungenPrivat[key].deleted)
                .sort(sortByAbbreviation)
                .map((key) => (
                  <ListItem button onClick={handleInitializeFormwithLeistungPrivat(key)} key={key}>
                    <ListItemText
                      primary={
                        leistungenPrivat[key] &&
                        `${leistungenPrivat[key].kuerzel} | ${leistungenPrivat[key].beschreibung}`
                      }
                      secondary={
                        leistungenPrivat[key] &&
                        (leistungenPrivat[key].verguetung / 100).toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + (praxisstammdaten.currency === 'EUR' ? ' €' : ' CHF')
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Paper>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Paper square elevation={2} className="formContainer">
            <div>
              <Typography variant="h5">Leistungsdetails</Typography>
              <Typography variant="h6">Bezeichnung und Preis der Leistung</Typography>
            </div>

            <InputField
              {...kuerzel}
              label="Kürzel *"
              placeholder="wird in Kalenderübersicht angezeigt"
              error={kuerzel.touched && !!kuerzel.error}
              helperText={kuerzel.touched && !!kuerzel.error ? kuerzel.error : ''}
            />
            <InputField
              {...beschreibung}
              label="Beschreibung *"
              placeholder="kann bei Zuordnung durchsucht werden"
              error={beschreibung.touched && !!beschreibung.error}
              helperText={beschreibung.touched && !!beschreibung.error ? beschreibung.error : ''}
            />
            <InputField
              {...regelbehandlungszeitMin}
              type="number"
              label="min. Regelbehandlungszeit"
              placeholder="Nur zur Info, wird aktuell nicht automatisch für Terminlänge berücksichtigt"
              error={regelbehandlungszeitMin.touched && !!regelbehandlungszeitMin.error}
              helperText={
                regelbehandlungszeitMin.touched && !!regelbehandlungszeitMin.error ? regelbehandlungszeitMin.error : ''
              }
              // value={regelbehandlungszeitMin.value === null ? '' : regelbehandlungszeitMin.value}
            />
            <InputField
              label="max. Regelbehandlungszeit"
              placeholder="Nur zur Info, wird aktuell nicht automatisch für Terminlänge berücksichtigt"
              error={regelbehandlungszeitMax.touched && !!regelbehandlungszeitMax.error}
              helperText={
                regelbehandlungszeitMax.touched && !!regelbehandlungszeitMax.error && regelbehandlungszeitMax.error
              }
              value={regelbehandlungszeitMax.value === null ? '' : regelbehandlungszeitMax.value}
            />
            <div>
              <CheckboxField {...vorbereitungsbedarf} label="Vorbereitungsbedarf" />
              {vorbereitungsbedarf.value && (
                <InputField
                  {...vorbereitungsnotiz}
                  label="Vorbereitungsnotiz"
                  value={vorbereitungsnotiz.value === null ? '' : vorbereitungsnotiz.value}
                />
              )}
            </div>
            <MultipleAutocompleteField
              {...fields.equipment}
              fullWidth
              name="equipment"
              label="Ausstattung"
              options={equipmentArray}
            />
            <InputField
              {...mehrwertsteuer}
              fullWidth
              label="Mehrwertsteuer in %"
              error={mehrwertsteuer.touched && !!mehrwertsteuer.error}
              helperText={mehrwertsteuer.touched && !!mehrwertsteuer.error && mehrwertsteuer.error}
              ref={mwstInput}
              onFocus={(e) => {
                mwstInput.current?.select()
                mehrwertsteuer.onFocus(e)
              }}
              value={mehrwertsteuer.value === null ? '' : mehrwertsteuer.value}
            />
            <CurrencyField
              {...verguetung}
              fullWidth
              label="Brutto Vergütung"
              error={verguetung.touched && !!verguetung.error}
              helperText={verguetung.touched && !!verguetung.error && verguetung.error}
              endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
            />
          </Paper>
          <div className="buttonContainer">
            <Button className="button" variant="contained" type="submit">
              Leistung speichern
            </Button>
            <Button className="button" variant="contained" onClick={() => initializeForm(initValues)}>
              Neue Leistung
            </Button>
          </div>
        </form>
      </StyledLeistungenPrivatForm>
    </>
  )
}

const validate = (values, props) => {
  const errors: KeyValue<string> = {}

  const required = ['kuerzel', 'beschreibung', 'mehrwertsteuer', 'verguetung']

  required.forEach((fieldName) => {
    if (!values[fieldName] && values[fieldName] !== 0) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  // Making sure that kuerzel and beschreibung sum is not greater than 252 chars
  const kuerzelLength = (values['kuerzel'] && values['kuerzel'].length) || 0
  const beschreibungLength = (values['beschreibung'] && values['beschreibung'].length) || 0
  if (kuerzelLength + beschreibungLength > 252) {
    if (kuerzelLength > 0) {
      errors['kuerzel'] =
        'Kürzel und Beschreibung einer Leistung dürfen zusammen max. 252 Zeichen umfassen. Bitte Eingabe kürzen.'
    }
    if (beschreibungLength > 0) {
      errors['beschreibung'] =
        'Kürzel und Beschreibung einer Leistung dürfen zusammen max. 252 Zeichen umfassen. Bitte Eingabe kürzen.'
    }
  }

  const vergebeneKuerzel = props.leistungenPrivatIds
    .filter((key) => {
      return key !== values.id
    })
    .map((key) => {
      return props.leistungenPrivat[key].kuerzel.trim().toLowerCase()
    })
    .concat(['dfa1', 'dfa2', 'dfb1', 'dfb2', 'dfc1', 'dfc2'])

  if (values.kuerzel && vergebeneKuerzel.indexOf(values.kuerzel.trim().toLowerCase()) !== -1) {
    errors.kuerzel = 'Kürzel bereits vergeben!'
  }

  return errors
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  leistungenPrivat: state.entities.leistungenPrivat,
  leistungenPrivatIds: state.ids.leistungenPrivat,
  initialValues: initValues,
  equipment: state.entities.equipment,
  praxisstammdaten: praxisstammdatenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    leistungenPrivat: bindActionCreators(leistungenPrivatActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'leistungenPrivatForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(LeistungenPrivatForm))
