import styled from 'styled-components';

export const StyledContacts = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .topBar {
    display: flex;
    flex-direction: row;
    height: 32px;
    flex-shrink: 0;
  }
`;
