import MenuItemsBuchung from './MenuItemsBuchung'
import { StyledContextMenuBuchung } from './StyledContextMenuBuchung'

const ContextMenuBuchung = ({
  pointerLocation: { mouseX, mouseY },
  handleClose,
  buchung,
  buchungTermin,
  dialogActions,
}) => {
  return (
    <StyledContextMenuBuchung
      keepMounted
      open={mouseX !== null && mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={mouseX !== null && mouseY !== null ? { top: mouseY, left: mouseX } : undefined}
      PaperProps={{ className: 'paper' }}>
        <MenuItemsBuchung
          buchung={buchung}
          termin={buchungTermin}
          onCloseFunc={handleClose}
          dialogActions={dialogActions}
        />
    </StyledContextMenuBuchung>
  )
 }

 export default ContextMenuBuchung
