import { isValid, parseISO } from 'date-fns'
import * as types from '../constants/actionTypes'

export default function selectedDay(state = new Date(), action = undefined) {
  switch (action.type) {
    case types.SELECT_DAY:
      if (action.date && typeof isValid === 'function' && isValid(action.date)) {
        return action.date
      }
      break

    case '@@router/LOCATION_CHANGE':
      if (action.payload && action.payload.pathname && action.payload.pathname.indexOf('/calendar/day/') !== -1) {
        const parts = action.payload.pathname.split('/')
        const date = parts[parts.length - 1]
        const mDate = parseISO(date)
        if (isValid(mDate)) {
          return mDate
        }
      }
      break

    default:
  }

  return state
}
