import { FC } from 'react';

interface Props {
  onClickFunc: (e?) => void,
  className: string
}

export const ListStyledLink: FC<Props> = ({ children, onClickFunc, className }) => (
  <div onClick={onClickFunc} className={className}>
    {children}
  </div>
);
