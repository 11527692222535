import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as patientenActions from '../../actions/customers'
import * as terminActions from '../../actions/dates'
import * as dialogActions from '../../actions/dialogs'
import { loadDoctors } from '../../actions/doctors'
import { loadLeistungenPrivat } from '../../actions/leistungenPrivat'
import { loadRooms } from '../../actions/rooms'
import { loadEquipment } from '../../actions/equipment'
import { loadAbbreviations } from '../../actions/abbreviations'
import { showNotification } from '../../actions/notification'
import { loadPeople } from '../../actions/people'
import { loadPraxisstammdaten } from '../../actions/praxisstammdaten'
import DataLoading from '../../components/DataLoading/DataLoading'
import EditDateDialog from '../../components/EditDateDialog/EditDateDialog'
import FormLeaveConfirmationDialog from '../../components/FormLeaveConfirmationDialog/FormLeaveConfirmationDialog'
import Notification from '../../components/Notification/Notification'
import { dataLoadedSelector, praxisstammdatenSelector } from '../../selectors/selectors'
import TerminAbrechnenDialog from '../TerminAbrechnenDialog/TerminAbrechnenDialog'
import TerminAbsageDialog from '../TerminAbsageDialog/TerminAbsageDialog'
import { OverlayRoutes } from './OverlayRoutes'

import globalNavigation from '../../utils/navigation'
import FinishAnamneseDialog from '../FinishAnamneseDialog/FinishAnamneseDialog'

import { Button } from '@material-ui/core'
import { sTerminFindenDeaktiviert } from '../../components/Calendar/selectors'
import { MessageError } from '../../shared/components/Message'
import { sCurrentUserApi } from '../../shared/utils/users'
import Layout from '../Layout/Layout'
import Lockscreen from '../Lockscreen/Lockscreen'
import { CardTerminalTest, ReadCardDataDialog } from '../../components/ReadCardDataDialog/ReadCardDataDialog'
import { set } from 'lodash'
import {
  BT_connection_state,
  Orga_care_WebBluetooth_interface,
} from '../../utils/cardreader/orga_930_care/web_bluetooth_interface'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useInterval } from '../../utils/hooks'
import Read_VSD_UserInterface from '../../utils/cardreader'
import { CardTerminal } from '../../utils/cardreader/card_reader/card_terminal'
import { CardReader_VSD } from '../../utils/cardreader/card_reader/card_reader_VSD'
import { Dad, Sad } from '../../utils/cardreader/card_reader/t1_host_interface'
import { CardReaderInstance, SortedCardData } from '../../utils/cardreader/CardReaderInstance'
import ShowCardDataDialog from '../../components/ShowCardDataDialog/ShowCardDataDialog'
import { isWebBluetoothEnabled } from '../../utils/helpers'
import { CardReaderContext, CardReaderContextProvider } from './CardReaderContext'

interface EditDate {
  editDate: {
    date: Date
    open: boolean
  }
}

interface Props {
  actions: Actions
  dialogs: EditDate
  patienten: Patient
  dataLoaded: number

  terminFindenDeaktiviert: boolean
  currentUser: any
  selectedTerminProps: any
  users: Users
  praxisstammdaten: Praxisstammdaten
}

const OverlayContainer: FC<Props> = ({
  actions,
  dialogs,
  patienten,
  dataLoaded,

  terminFindenDeaktiviert,
  currentUser,
  selectedTerminProps,
  users,
  praxisstammdaten,
}): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { serverHash } = useParams()

  useEffect(() => {
    globalNavigation.navigate = navigate
  }, [])

  useEffect(() => {
    document['title'] = praxisstammdaten?.firmenbezeichnung ?? 'pododesk'
  }, [praxisstammdaten])

  useEffect(() => {
    if (terminFindenDeaktiviert && location.pathname.indexOf(`calendar/free`) !== -1) {
      const userWhosCalendarToOpen = currentUser.istTherapeut
        ? currentUser
        : Object.keys(users)
            .map((key) => users[key])
            .find((user) =>
              selectedTerminProps.therapeut ? user.id === selectedTerminProps.therapeut : user.istTherapeut,
            ) || null
      // @ts-ignore
      actions.showNotification({
        message: <span>Automatische Weiterleitung von der &quot;Termin finden&quot; Ansicht aktiv.</span>,
        busy: false,
        error: false,
        action: (
          <Button
            onClick={() => {
              navigate(`/${serverHash}/settings/praxisstammdaten`, { replace: true })
            }}
          >
            Einstellung ändern
          </Button>
        ),
      })
      userWhosCalendarToOpen && navigate(`/${serverHash}/calendar/week/${userWhosCalendarToOpen.id}`)
    }
  }, [location, serverHash, navigate, actions, terminFindenDeaktiviert, currentUser, selectedTerminProps, users])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFormLeaveConfirmationDialogOpen, setIsFormLeaveConfirmationDialogOpen] = useState<boolean>(false) // eslint-disable-line @typescript-eslint/no-unused-vars

  const [selectedTerminAbsage, setSelectedTerminAbsage] = useState<any | null>(null)
  const [terminAbsageDialogOpen, setTerminAbsageDialogOpen] = useState<boolean>(false)

  const [isBluetoothConnected, setIsBluetoothConnected] = useState<boolean>(false)
  const [isReadCardDataDialogOpen, setIsReadCardDataDialogOpen] = useState<boolean>(false)
  const [isCardReaderActive, setIsCardReaderActive] = useState<boolean>(false)
  const [currentCardData, setCurrentCardData] = useState<SortedCardData | null>(null)

  const openTerminAbsageDialog = (termin) => {
    setSelectedTerminAbsage(termin)
    setTerminAbsageDialogOpen(true)
  }
  const closeTerminAbsageDialog = () => {
    setSelectedTerminAbsage(null)
    setTerminAbsageDialogOpen(false)
  }

  const openReadCardDataDialog = (): void => {
    setIsReadCardDataDialogOpen(true)
  }

  const closeReadCardDataDialog = (): void => {
    setIsReadCardDataDialogOpen(false)
  }

  const closeShowCardDataDialog = (): void => {
    setCurrentCardData(null)
  }

  // const bluetoothInterface = useMemo(() => new Orga_care_WebBluetooth_interface(disconnectBluetoothInterface), [])

  const [selectedTerminAbrechnen, setSelectedTerminAbrechnen] = useState<any | null>(null)
  const [terminAbrechnenDialogOpen, setTerminAbrechnenDialogOpen] = useState<boolean>(false)
  const openTerminAbrechnenDialog = (termin) => {
    setSelectedTerminAbrechnen(termin)
    setTerminAbrechnenDialogOpen(true)
  }
  const closeTerminAbrechnenDialog = () => {
    setSelectedTerminAbrechnen(null)
    setTerminAbrechnenDialogOpen(false)
  }

  const [selectedFinishAnamnese, setSelectedFinishAnamnese] = useState<any | null>(null)
  const [finishAnamneseDialogOpen, setFinishAnamneseDialogOpen] = useState<boolean>(false)
  const openFinishAnamneseDialog = (anamnese) => {
    setSelectedFinishAnamnese(anamnese)
    setFinishAnamneseDialogOpen(true)
  }
  const closeFinishAnamneseDialog = useCallback(() => {
    setSelectedFinishAnamnese(null)
    setFinishAnamneseDialogOpen(false)
  }, [setSelectedFinishAnamnese, setFinishAnamneseDialogOpen])

  const loadData = async (loadActions) => {
    await Promise.all(Object.values(loadActions).map((v: FixMeLater) => v()))
    setIsLoading(false)
  }

  useEffect(() => {
    try {
      loadData(actions.loadActions)
    } catch (err) {
      console.error(err)
      actions.showNotification(
        <MessageError
          message={
            <span>
              Fehler beim Laden. Bitte <a href="mailto:support@pododesk.de">support@pododesk.de</a> kontaktieren!
            </span>
          }
        />,
      )
      setIsLoading(false)
    }
  }, [serverHash, actions])

  return (
    <Lockscreen>
      <CardReaderContextProvider>
        <Layout
          dialogActions={{
            openTerminAbsageDialog,
            openTerminAbrechnenDialog,
            openFinishAnamneseDialog,
            openReadCardDataDialog,
            closeReadCardDataDialog,
          }}
          practiceView
        >
          {!isLoading && dataLoaded ? (
            <OverlayRoutes
              dialogActions={{
                openTerminAbsageDialog,
                openTerminAbrechnenDialog,
                openFinishAnamneseDialog,
              }}
            />
          ) : isLoading && !dataLoaded ? (
            <span>Error</span>
          ) : (
            <DataLoading text="lade Anwendung..." />
          )}

          <TerminAbsageDialog
            terminActions={actions.termine}
            patientenActions={actions.patienten}
            patienten={patienten}
            closeDialog={closeTerminAbsageDialog}
            open={terminAbsageDialogOpen}
            termin={selectedTerminAbsage}
          />

          <EditDateDialog
            closeDialog={actions.dialogs.closeEditTerminDialog}
            terminActions={actions.termine}
            patientenActions={actions.patienten}
            {...dialogs.editDate}
          />
          <TerminAbrechnenDialog
            closeDialog={closeTerminAbrechnenDialog}
            open={terminAbrechnenDialogOpen}
            termin={selectedTerminAbrechnen}
          />

          <FinishAnamneseDialog
            closeDialog={closeFinishAnamneseDialog}
            open={finishAnamneseDialogOpen}
            anamnese={selectedFinishAnamnese}
          />

          <FormLeaveConfirmationDialog isOpen={isFormLeaveConfirmationDialogOpen} />

          <ReadCardDataDialog
            open={isReadCardDataDialogOpen}
            onCloseFunc={closeReadCardDataDialog}
          />

          <ShowCardDataDialog />

          <Notification />
        </Layout>
      </CardReaderContextProvider>
    </Lockscreen>
  )
}

const mapStateToProps = (state) => ({
  dialogs: state.dialogs,
  patienten: state.entities.patienten,
  dataLoaded: dataLoadedSelector(state),
  terminFindenDeaktiviert: sTerminFindenDeaktiviert(state),
  currentUser: sCurrentUserApi(state),
  selectedTerminProps: state.selectedTerminProps,
  users: state.entities.users,
  praxisstammdaten: praxisstammdatenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showNotification: bindActionCreators(showNotification, dispatch),
    termine: bindActionCreators(terminActions, dispatch),
    dialogs: bindActionCreators(dialogActions, dispatch),
    patienten: bindActionCreators(patientenActions, dispatch),
    loadActions: bindActionCreators(
      {
        loadDoctors,
        loadLeistungenPrivat,
        loadPeople,
        loadPraxisstammdaten,
        loadRooms,
        loadEquipment,
        loadAbbreviations,
      },
      dispatch,
    ),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(OverlayContainer))
